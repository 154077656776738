import React, { useEffect, useState } from "react";
import { ImmutableArray } from "../../misc/ImmutableArray";
import { API } from "../../tg/Codegen/API/APISchema";
import { Popover } from "../ui/Popover";
import {
    FixedSizeGrid,
    GridOnScrollProps,
    VariableSizeGrid,
} from "react-window";
import { Sticker, StickerSetThumb } from "../ui/Sticker";
import { tg } from "../../App";
import { map } from "rxjs";
import { RecentStickersIcon } from "../ui/icons/RecentStickers";
import { TLInt } from "../../tg/TL/Types/TLInt";
import { LRUCache } from "../../misc/LRUCache";
import BezierEasing from "bezier-easing";

interface Props {
    open: boolean;
    onClose: () => void;
    anchorEl: HTMLElement | null;
    onClick: (sticker: API.Document) => void;
}

interface State {
    activeSet: number;
    stickers: ImmutableArray<StickerSet>;
}

export class ChatStickersPopover extends React.Component<Props, State> {
    refGrid: FixedSizeGrid | null = null;
    refStrip: VariableSizeGrid | null = null;
    scrollTop = 0;

    state: State = {
        activeSet: 0,
        stickers: new ImmutableArray(),
    };

    onScroll(params: GridOnScrollProps) {
        const heights = this.state.stickers.map(
            (set) =>
                (set.stickers.length * (stickerSize + gutterSize)) / columnCount
        );
        let set = 0;
        for (let total = 0; set < heights.length; set++) {
            total += heights.at(set) as number;
            if (params.scrollTop < total) {
                break;
            }
        }

        this.setState({
            activeSet: set,
        });

        this.scrollTop = params.scrollTop;
    }

    scrollTo(index: number) {
        const heights = this.state.stickers.map(
            (set) =>
                (set.stickers.length * (stickerSize + gutterSize)) / columnCount
        );
        let total = 0;
        for (let set = 0; set < index; set++) {
            total += heights.at(set) as number;
        }

        const easing = BezierEasing(0.23, 1, 0.32, 1);
        for (let i = 0; i <= 1.0; i += 0.0001) {
            requestAnimationFrame(() => {
                setTimeout(() => {
                    const offset =
                        this.scrollTop +
                        total * easing(i) -
                        this.scrollTop * easing(i);

                    this.refGrid?.scrollTo({
                        scrollTop: Math.round(offset),
                    });
                }, 50);
            });
        }
    }

    itemKey(params: {
        columnIndex: number;
        rowIndex: number;
    }): number | string {
        const sticker = getSticker(
            params.columnIndex,
            params.rowIndex,
            this.state.stickers
        );
        const setIndex = sticker.setIndex.toString();
        const stickerIndex = sticker.stickerIndex.toString();
        const stickerId = sticker.sticker?.id.value.toString() || "";
        const key = setIndex + "_" + stickerIndex + "_" + stickerId;

        return key;
    }

    shouldComponentUpdate(
        nextProps: Readonly<Props>,
        nextState: Readonly<State>
    ): boolean {
        if (nextProps.open !== this.props.open) {
            return true;
        }
        if (nextProps.onClose !== this.props.onClose) {
            return true;
        }
        if (nextProps.anchorEl !== this.props.anchorEl) {
            return true;
        }
        if (nextProps.onClick !== this.props.onClick) {
            return true;
        }
        if (nextState.activeSet !== this.state.activeSet) {
            return true;
        }
        if (nextState.stickers !== this.state.stickers) {
            return true;
        }

        return false;
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>
    ): void {
        if (prevState.activeSet !== this.state.activeSet) {
            this.refStrip?.scrollToItem({
                align: "center",
                columnIndex: this.state.activeSet,
            });
        }
    }

    componentDidMount() {
        tg.getAllStickers()
            .pipe(
                map((sets) =>
                    sets.map((set) => ({
                        id: set.set.id.value.toNumber(),
                        thumb: new API.InputStickerSetThumb(
                            new API.InputStickerSetID(
                                set.set.id,
                                set.set.accessHash
                            ),
                            set.set.thumbVersion || new TLInt(0)
                        ),
                        thumbDcId: set.set.thumbDcId,
                        vectorThumb: set.set.thumbs?.items.find(
                            (item) => item.type.string === "j"
                        ) as API.PhotoPathSize,
                        animated: !!set.set.animated,
                        title: set.set.title.string,
                        stickers: set.documents.items.filter(
                            (doc) => doc instanceof API.Document
                        ) as Array<API.Document | undefined>,
                    }))
                ),
                map((sets) =>
                    sets.map((set: StickerSet) => ({
                        ...set,
                        stickers: set.stickers.concat(
                            dummyStickerData(set.stickers.length)
                        ),
                    }))
                )
            )
            .subscribe((stickers) => {
                this.setState((state) => {
                    return {
                        stickers: state.stickers.push(...stickers),
                    };
                });
            });

        tg.getRecentStickers()
            .pipe(
                map(
                    (set) =>
                        new Array({
                            id: 0,
                            title: "recent",
                            animated: false,
                            stickers: set as Array<API.Document | undefined>,
                        })
                ),
                map((sets) =>
                    sets.map((set: StickerSet) => ({
                        ...set,
                        stickers: set.stickers.concat(
                            dummyStickerData(set.stickers.length)
                        ),
                    }))
                )
            )
            .subscribe((stickers) => {
                this.setState((state) => {
                    return {
                        stickers: state.stickers.prepend(...stickers),
                    };
                });
            });
    }

    render() {
        let stickersCount = 0;
        for (const stickerSet of this.state.stickers) {
            stickersCount += stickerSet.stickers.length;
        }

        return (
            <Popover
                open={this.props.open}
                anchorEl={this.props.anchorEl}
                origin={["right", "bottom"]}
                onRequestClose={this.props.onClose}
            >
                <div
                    onMouseLeave={this.props.onClose}
                    style={{
                        overflow: "hidden",
                        display: "block",
                        width: popupWidth,
                        height: popupHeight,
                    }}
                >
                    <FixedSizeGrid
                        key={"grid"}
                        ref={(ref) => (this.refGrid = ref)}
                        width={popupWidth}
                        height={popupHeight}
                        columnCount={columnCount}
                        columnWidth={stickerSize + gutterSize}
                        rowCount={Math.ceil(stickersCount / columnCount)}
                        rowHeight={stickerSize + gutterSize}
                        onScroll={this.onScroll.bind(this)}
                        overscanColumnCount={0}
                        overscanRowCount={0}
                        itemKey={this.itemKey.bind(this)}
                    >
                        {(params: {
                            columnIndex: number;
                            rowIndex: number;
                            style: React.CSSProperties;
                        }) => (
                            <PopoverCell
                                columnIndex={params.columnIndex}
                                rowIndex={params.rowIndex}
                                style={params.style}
                                stickers={this.state.stickers}
                                onClick={this.props.onClick.bind(this)}
                            ></PopoverCell>
                        )}
                    </FixedSizeGrid>
                    <style type="text/css">{stickerSetStripNoScrollbar}</style>
                    <VariableSizeGrid
                        ref={(ref) => (this.refStrip = ref)}
                        width={popupWidth}
                        height={stickerSetsStripHeight}
                        rowCount={1}
                        columnCount={this.state.stickers.length}
                        rowHeight={() => stickerSetsStripHeight}
                        columnWidth={(index: number) => {
                            if (index === this.state.stickers.length - 1) {
                                return 32 + gutterSize * 2;
                            }
                            return 32 + gutterSize;
                        }}
                        overscanRowCount={0}
                        overscanColumnCount={0}
                        style={{
                            outline: "none",
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
                            background: "#fff",
                        }}
                        className={"sticker-sets-strip"}
                    >
                        {(params: {
                            columnIndex: number;
                            rowIndex: number;
                            style: React.CSSProperties;
                        }) => (
                            <StripCell
                                columnIndex={params.columnIndex}
                                rowIndex={params.rowIndex}
                                style={params.style}
                                stickers={this.state.stickers}
                                activeSet={this.state.activeSet}
                                onClick={(index) => this.scrollTo(index)}
                            ></StripCell>
                        )}
                    </VariableSizeGrid>
                </div>
            </Popover>
        );
    }
}

interface StickerSet {
    readonly id: number;
    readonly thumb?: API.InputStickerSetThumb;
    readonly thumbDcId?: TLInt;
    readonly vectorThumb?: API.PhotoPathSize;
    readonly animated: boolean;
    readonly title: string;
    readonly stickers: Array<API.Document | undefined>;
}

const stickerSetsStripHeight = 44;
const stickerSetStripNoScrollbar = `
.sticker-sets-strip::-webkit-scrollbar {
    display: none;
}
`;

const popupWidth = 256;
const popupHeight = 212;
const columnCount = 3;
const stickerSize = 64;
const gutterSize = 16;

const StripCell = (props: {
    columnIndex: number;
    rowIndex: number;
    style: React.CSSProperties;
    stickers: ImmutableArray<StickerSet>;
    activeSet: number;
    onClick: (index: number) => void;
}) => {
    const [element, setElement] = useState(<></>);
    useEffect(() => {
        if (props.columnIndex === 0 && props.rowIndex === 0) {
            setElement(
                <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                    <RecentStickersIcon />
                </div>
            );
        } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const set = props.stickers.at(props.columnIndex)!;
            if (set.thumb && set.thumbDcId) {
                setElement(
                    <StickerSetThumb
                        id={props.columnIndex}
                        key={props.columnIndex}
                        size={26}
                        file={stickerSetThumbsCache.get(
                            new TLInt(props.columnIndex)
                        )}
                        animated={set.animated}
                        vectorThumb={set.vectorThumb}
                    ></StickerSetThumb>
                );

                tg.getStickerSetThumb(set.thumb, set.thumbDcId).subscribe(
                    (file) => {
                        stickerSetThumbsCache.put(
                            new TLInt(props.columnIndex),
                            file
                        );
                        setElement(
                            <StickerSetThumb
                                id={props.columnIndex}
                                key={props.columnIndex}
                                size={26}
                                file={file}
                                animated={set.animated}
                                vectorThumb={set.vectorThumb}
                            ></StickerSetThumb>
                        );
                    }
                );
            } else {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const sticker = set.stickers.at(0)!;

                setElement(
                    <Sticker
                        key={sticker.id.value.toNumber()}
                        size={26}
                        sticker={sticker}
                    />
                );
            }
        }
    }, [props.stickers]);

    return (
        <div
            style={{
                ...props.style,
                left: +(props.style.left || 0) + 8,
                cursor: "pointer",
            }}
            onClick={() => props.onClick(props.columnIndex)}
        >
            <div
                style={{
                    width: 32,
                    height: 32,
                    padding: 3,
                    boxSizing: "border-box",
                    background:
                        props.activeSet === props.columnIndex
                            ? "rgba(0, 0, 0, 0.08)"
                            : "transparent",
                    transition: "background 200ms ease",
                    borderRadius: 4,
                    marginTop: 6,
                    border: "none",
                }}
            >
                {element}
            </div>
        </div>
    );
};

const stickerSetThumbsCache = new LRUCache<TLInt, API.upload.File>(16);

const PopoverCell = (props: {
    columnIndex: number;
    rowIndex: number;
    style: React.CSSProperties;
    stickers: ImmutableArray<StickerSet>;
    onClick: (sticker: API.Document) => void;
}) => {
    const sticker = getSticker(
        props.columnIndex,
        props.rowIndex,
        props.stickers
    );
    let element: JSX.Element;
    if (sticker.sticker instanceof API.Document) {
        element = (
            <Sticker
                size={stickerSize}
                sticker={sticker.sticker}
                animDelay={200}
            />
        );
    } else {
        element = dummySticker;
    }

    return (
        <div
            onClick={() => {
                if (props.onClick && sticker.sticker instanceof API.Document) {
                    props.onClick(sticker.sticker);
                }
            }}
            style={{
                ...props.style,
                userSelect: "none",
                cursor: element === dummySticker ? "default" : "pointer",
                width: stickerSize,
                height: stickerSize,
                top: +(props.style.top || 0) + 16,
                left: +(props.style.left || 0) + 16,
            }}
        >
            {element}
        </div>
    );

    // <div style={props.style}>
    //     Item {props.rowIndex},{props.columnIndex}
    // </div>;
};

const getSticker = (
    columnIndex: number,
    rowIndex: number,
    stickers: ImmutableArray<StickerSet>
) => {
    const index = rowIndex * columnCount + columnIndex;

    let group = 0;
    let total = 0;
    const lengths = stickers.map(
        (set) => set.stickers.length
    ) as ImmutableArray<number>;
    for (const length of lengths) {
        total += length;
        if (index < total) {
            total -= length;
            break;
        }
        group++;
    }
    let indexWithinGroup: number;
    if (total === 0) {
        indexWithinGroup = index;
    } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        indexWithinGroup = (index - total) % lengths.at(group)!;
    }

    return {
        setIndex: group,
        stickerIndex: indexWithinGroup,
        sticker: stickers.at(group)?.stickers[indexWithinGroup],
    };
};

const dummySticker: JSX.Element = <span />;

const dummyStickerData = (setLength: number) => {
    return Array.from(Array(padding(setLength, columnCount)), () => undefined);
};

const padding = (num: number, multiple: number) => {
    return (multiple - (num % multiple)) % multiple;
};
