import { ByteStream } from "../../DataStructures/ByteStream";
import { TLSerializable } from "../Interfaces/TLSerializable";
import { TLInt } from "./TLInt";

export class TLFlags implements TLSerializable {
    static deserialized(data: ByteStream): TLFlags | undefined {
        const int = TLInt.deserialized(data);
        if (!int) return undefined;

        return new TLFlags(int.value);
    }

    private _value: number;

    serialized(): Uint8Array {
        return new TLInt(this._value).serialized();
    }

    set(offset: number) {
        this._value |= offset;
    }

    unset(offset: number) {
        this._value &= ~offset;
    }

    get value() {
        return this._value;
    }

    constructor(value = 0) {
        this._value = value;
    }
}
