import styled, { css } from "styled-components";
import { TG } from "../../tg/TG";
import { Avatar } from "../ui/Avatar";
import {
    Peer,
    PeerChannel,
    PeerChat,
    PeerUser,
} from "../../tg/Convenience/Peer";
import { CheckmarkIcon } from "../ui/icons/Checkmark";
import { CheckmarkDoneIcon } from "../ui/icons/CheckmarkDone";
import { API } from "../../tg/Codegen/API/APISchema";
import { previewStrippedJpeg } from "../../tg/Files/Preview";
import { ReactNode, memo, useEffect, useMemo, useState } from "react";
import { ThemeContext, tg } from "../../App";
import { LRUCache } from "../../misc/LRUCache";
import { areEqual } from "react-window";
import { ImmutableArray } from "../../misc/ImmutableArray";
import { Update } from "../../tg/Updates/Update";

export function ChatsListItem(props: {
    chat: TG.Chat;
    last?: boolean;
    selected: boolean;
    aboveSelected: boolean;
    typings: ImmutableArray<Update.UserTyping>;
}) {
    let Indicator: ReactNode;
    const read = props.chat.topRead;
    if (typeof read === "undefined") {
        Indicator = <Gap />;
    } else if (read) {
        Indicator = <CheckmarkDoneIcon />;
    } else {
        Indicator = <CheckmarkIcon />;
    }

    let photo: API.UserProfilePhotoType | undefined;
    let preview: Uint8Array;
    if (props.chat.peer instanceof PeerUser) {
        photo = props.chat.peer.user?.photo;
        if (photo instanceof API.UserProfilePhoto && photo.strippedThumb) {
            preview = previewStrippedJpeg(photo.strippedThumb.bytes);
        }
    } else if (props.chat.peer instanceof PeerChat) {
        photo = props.chat.peer.chat?.photo;
        if (photo instanceof API.ChatPhoto && photo.strippedThumb) {
            preview = previewStrippedJpeg(photo.strippedThumb.bytes);
        }
    } else if (props.chat.peer instanceof PeerChannel) {
        photo = props.chat.peer.channel?.photo;
        if (photo instanceof API.ChatPhoto && photo.strippedThumb) {
            preview = previewStrippedJpeg(photo.strippedThumb.bytes);
        }
    }

    const [image, setImage] = useState<Uint8Array | undefined>(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        cache.get(props.chat.peer!)
    );
    useEffect(() => {
        if (
            props.chat.peer &&
            (photo instanceof API.UserProfilePhoto ||
                photo instanceof API.ChatPhoto)
        ) {
            tg.getPeerPhoto(
                props.chat.peer,
                photo.photoId,
                photo.dcId
            ).subscribe((file) => {
                if (typeof image === "undefined") {
                    setImage(file.bytes.bytes);
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    cache.put(props.chat.peer!, file.bytes.bytes);
                }
            });
        }
    }, []);

    const [selected, setSelected] = useState(
        lastSelected?.hashValue === props.chat.peer?.hashValue
    );
    useEffect(() => {
        setSelected(props.selected);
        if (props.selected) {
            setLastSelected(props.chat.peer);
        }
    }, [props.selected, props.aboveSelected]);

    let author: ReactNode | undefined = undefined;
    if (
        props.chat.peer instanceof PeerChat &&
        props.chat.topMessage.from instanceof API.User &&
        props.typings.length === 0
    ) {
        const from = props.chat.topMessage.from;
        author = (
            <Row>
                <Author $selected={selected}>{from.firstName?.string}</Author>
            </Row>
        );
    }
    let unreadCount: number | string = props.chat.unreadCount;
    if (unreadCount > 99) {
        unreadCount = "99+";
    }

    const chat = useMemo(() => {
        return props.chat;
    }, [props.chat]);

    const typings = useMemo(() => {
        return props.typings;
    }, [props.typings]);

    let messageContent: ReactNode | string;
    if (typings.length > 0) {
        messageContent = (
            <em>
                {typings
                    .map((value) => value.user.firstName?.string || "")
                    .join() + " typing"}{" "}
                &hellip;
            </em>
        );
    } else {
        messageContent = chat.topMessage.toString();
    }

    return (
        <ThemeContext.Consumer>
            {(theme) => (
                <RootWrap>
                    <Root $selected={selected} $background={theme.primaryColor}>
                        <AvatarContainer>
                            <MemoAvatar
                                chat={chat}
                                preview={preview}
                                image={image}
                                selected={selected}
                                primaryColor={theme.primaryColor}
                            />
                        </AvatarContainer>
                        <Details $selected={selected}>
                            <Row>
                                <Title $selected={selected}>{chat.title}</Title>
                                <Sent $selected={selected}>{Indicator}</Sent>
                                <Date $selected={selected}>
                                    {chat.topReadableDate}
                                </Date>
                            </Row>
                            {author}
                            <Row>
                                <Message
                                    $oneLine={!!author}
                                    $unreadCount={!!chat.unreadCount}
                                >
                                    {messageContent}
                                </Message>
                                {chat.unreadCount > 0 && (
                                    <UnreadCount
                                        $background={theme.primaryColor}
                                        $selected={selected}
                                    >
                                        {unreadCount}
                                    </UnreadCount>
                                )}
                            </Row>
                        </Details>
                    </Root>
                    <Divider
                        $hide={selected || props.aboveSelected || props.last}
                    />
                </RootWrap>
            )}
        </ThemeContext.Consumer>
    );
}

const cache = new LRUCache<Peer, Uint8Array>(16);

// Cache last selected to avoid flickering and animation replays
// during selection of new chat peers.
let lastSelected: Peer | undefined = undefined;
const setLastSelected = (peer?: Peer) => {
    lastSelected = peer;
};

const MemoAvatar = memo(
    (props: {
        chat: TG.Chat;
        preview: Uint8Array;
        image: Uint8Array | undefined;
        selected: boolean;
        primaryColor: string;
    }) => {
        return (
            <Avatar
                id={props.chat.id}
                name={props.chat.title}
                deleted={props.chat.deleted}
                user={props.chat.peer instanceof PeerUser}
                size={40}
                preview={props.preview}
                image={props.image}
                online={
                    props.chat.peer instanceof PeerUser &&
                    props.chat.peer.user?.status instanceof API.UserStatusOnline
                }
                onlineBorderColor={props.selected && props.primaryColor}
            />
        );
    },
    areEqual
);

const RootWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 90px;
`;

const Root = styled.div<{ $selected: boolean; $background: string }>`
    position: relative;
    height: calc(100% - 8px);
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 4px 8px;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

        background-image: radial-gradient(
            circle,
            ${(props) => props.$background} 10%,
            transparent 10%
        );
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(0, 0);
        opacity: 0;
    }

    ${(props) =>
        props.$selected &&
        css`
            &:after {
                transform: scale(10, 10);
                opacity: 1;
            }
        `}
`;

const AvatarContainer = styled.div`
    position: absolute;
    left: 14px;
    top: 12px;
`;

const Details = styled.div<{ $selected: boolean }>`
    position: absolute;
    top: 10px;
    right: 8px;
    bottom: 10px;
    left: 72px;
    display: flex;
    flex-direction: column;
    color: rgba(117, 117, 117, 1);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$selected &&
        css`
            color: rgba(255, 255, 255, 0.87);
        `}
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const Author = styled.span<{ $selected: boolean }>`
    margin: 2px 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    text-overflow: ellipsis;
    line-break: anywhere;
    overflow: hidden;
    white-space: nowrap;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$selected &&
        css`
            color: rgba(255, 255, 255, 0.87);
        `}
`;

const Title = styled.span<{ $selected: boolean }>`
    color: rgba(0, 0, 0, 0.87);
    font-size: 18px;
    font-weight: 500;
    height: 24px;
    text-overflow: ellipsis;
    line-break: anywhere;
    overflow: hidden;
    white-space: nowrap;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$selected &&
        css`
            color: rgba(255, 255, 255, 0.87);
        `}
`;

const Gap = styled.div`
    width: 0;
    height: 0;
`;

const Sent = styled.div<{ $selected: boolean }>`
    width: 16px;
    height: 16px;
    margin: 0 5px 3px auto;
    padding-left: 8px;
    align-self: center;
    flex-shrink: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    svg path {
        stroke-width: 48px;
        color: rgba(61, 200, 161, 1);

        ${(props) =>
            props.$selected &&
            css`
                color: rgba(255, 255, 255, 1);
            `}
    }
`;

const Date = styled.span<{ $selected: boolean }>`
    font-size: 12px;
    height: 20px;
    color: rgba(0, 0, 0, 0.54);
    flex-shrink: 0;
    align-self: end;
    margin-right: 4px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$selected &&
        css`
            color: rgba(255, 255, 255, 0.87);
        `}
`;

const Divider = styled.hr<{ $hide?: boolean }>`
    position: absolute;
    bottom: 0;
    right: 16px;
    left: 80px;
    height: 1px;
    margin: 0;
    background: rgba(0, 0, 0, 0.1);
    border: none;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$hide &&
        css`
            opacity: 0;
        `}
`;

const Message = styled.div<{ $oneLine: boolean; $unreadCount: boolean }>`
    line-height: 18px;
    height: ${(props) => (props.$oneLine ? "18px" : "36px")};
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${(props) => (props.$oneLine ? "1" : "2")};
    line-clamp: ${(props) => (props.$oneLine ? "1" : "2")};
    box-orient: vertical;
    margin-right: ${(props) => (props.$unreadCount ? "48px" : "0")};
`;

const UnreadCount = styled.div<{ $background: string; $selected: boolean }>`
    margin: 8px 2px 0 auto;
    font-size: 14px;
    background: ${(props) => props.$background};
    color: rgba(255, 255, 255, 0.87);
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 0 7px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$selected &&
        css`
            background: rgba(255, 255, 255, 0.87);
            color: ${props.$background};
        `}
`;
