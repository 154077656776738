import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { API } from "../../tg/Codegen/API/APISchema";
import { inlineVector } from "../../tg/Files/InlineVector";
import { TLString } from "../../tg/TL/Types/TLString";
import { tg } from "../../App";
import { Gzip } from "../../tg/Gzip/Gzip";
import lottie from "lottie-web";
import { LRUCache } from "../../misc/LRUCache";
import { TLLong } from "../../tg/TL/Types/TLLong";
import { TLInt } from "../../tg/TL/Types/TLInt";
import Long from "long";
import { HashMap } from "../../tg/DataStructures/HashMap/HashMap";

export function Sticker(props: {
    size: number;
    sticker: API.Document;
    animDelay?: number;
    previewDelay?: number;
    cacheCurrentFrame?: boolean;
    loop?: boolean;
}) {
    const [preview, setPreview] = useState(
        props.previewDelay ? <></> : previewCache.get(props.sticker.id)
    );
    const [image, setImage] = useState(imageCache.get(props.sticker.id));
    const [sticker, setSticker] = useState(stickerCache.get(props.sticker.id));
    const [animReady, setAnimReady] = useState(false);

    const animated =
        props.sticker.mimeType.string === "application/x-tgsticker";

    useEffect(() => {
        const timeout = setTimeout(() => {
            const thumbVector = props.sticker.thumbs?.items.find(
                (thumb) => thumb.type.string === "j"
            ) as API.PhotoPathSize | undefined;

            if (thumbVector) {
                const inline = inlineVector(thumbVector);
                setPreview(inline);
                previewCache.put(props.sticker.id, inline);
            }
        }, props.previewDelay);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        if (image) {
            imageCache.put(props.sticker.id, image);
            return;
        }

        if (sticker) {
            stickerCache.put(props.sticker.id, sticker);
            return;
        }

        let animTimeout: unknown;
        tg.getDocument(
            props.sticker.id,
            props.sticker.accessHash,
            props.sticker.fileReference,
            props.sticker.dcId,
            // Animated stickers need to be called with "x" size,
            // otherwise no document is returned.
            new TLString("x")
        ).subscribe((doc) => {
            if (props.sticker.mimeType.string === "image/webp") {
                const url = URL.createObjectURL(
                    new Blob([doc.bytes.bytes], { type: "image/webp" })
                );
                setImage(url);
                imageCache.put(props.sticker.id, url);
            } else if (
                props.sticker.mimeType.string === "application/x-tgsticker"
            ) {
                animTimeout = setTimeout(() => {
                    const decompressed = Gzip.decompress(doc.bytes.bytes);
                    if (!decompressed) {
                        return undefined;
                    }
                    const decoded = JSON.parse(
                        new TextDecoder().decode(decompressed)
                    );
                    setSticker(decoded);
                    stickerCache.put(props.sticker.id, decoded);
                }, (props.animDelay || 0) / 2);
            }
        });

        return () => {
            if (animTimeout) {
                clearTimeout(animTimeout as number);
            }
        };
    }, [props.sticker]);

    const previewVisible = (!animated && !image) || (animated && !animReady);
    const currentFrame = props.cacheCurrentFrame
        ? animCurrentFrameCache.get(props.sticker.id)
        : undefined;
    return (
        <Root $size={props.size}>
            <Preview $visible={previewVisible}>{preview}</Preview>
            {!!image && <Image $size={props.size} src={image}></Image>}
            {!!sticker && (
                <LottieAnimation
                    size={props.size}
                    animationData={sticker}
                    animationLoaded={() => setAnimReady(true)}
                    timeout={props.animDelay}
                    id={props.sticker.id}
                    startWithFrame={currentFrame}
                    loop={props.loop}
                ></LottieAnimation>
            )}
        </Root>
    );
}

export function StickerSetThumb(props: {
    size: number;
    animated: boolean;
    file?: API.upload.File;
    vectorThumb?: API.PhotoPathSize;
    id: number;
}) {
    const preview = props.vectorThumb ? inlineVector(props.vectorThumb) : "";
    const [image, setImage] = useState(
        thumbsImageCache.get(new TLInt(props.id))
    );
    const [sticker, setSticker] = useState(
        thumbsStickerCache.get(new TLInt(props.id))
    );

    useEffect(() => {
        if (!props.file) {
            return;
        }

        if (props.animated) {
            const decompressed = Gzip.decompress(props.file.bytes.bytes);
            if (!decompressed) {
                return undefined;
            }
            const decoded = JSON.parse(new TextDecoder().decode(decompressed));
            setSticker(decoded);
            thumbsStickerCache.put(new TLInt(props.id), decoded);
        } else {
            const url = URL.createObjectURL(
                new Blob([props.file.bytes.bytes], { type: "image/webp" })
            );
            setImage(url);
            thumbsImageCache.put(new TLInt(props.id), url);
        }
    }, [props.file]);

    const previewVisible =
        (!props.animated && !image) || (props.animated && !sticker);
    return (
        <Root $size={props.size}>
            <Preview $visible={previewVisible}>{preview}</Preview>
            {!!image && <Image $size={props.size} src={image}></Image>}
            {!!sticker && (
                <LottieAnimation
                    size={props.size}
                    animationData={sticker}
                    id={new TLLong(Long.fromNumber(props.id))}
                ></LottieAnimation>
            )}
        </Root>
    );
}

const thumbsImageCache = new LRUCache<TLInt, string>(16);
const thumbsStickerCache = new LRUCache<TLInt, string>(16);

const previewCache = new LRUCache<TLLong, JSX.Element>(32);
const imageCache = new LRUCache<TLLong, string>(32);
const stickerCache = new LRUCache<TLLong, string>(32);

const animCurrentFrameCache = new HashMap<TLLong, number>(32);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(lottie as any).useWebWorker(true);
lottie.setQuality("low");

const LottieAnimation = (props: {
    size: number;
    animationData: string;
    animationLoaded?: () => void;
    timeout?: number;
    id?: TLLong;
    startWithFrame?: number;
    loop?: boolean;
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [animReady, setAnimReady] = useState(false);

    let anim:
        | {
              destroy: () => void;
              frameRate: number;
              addEventListener: (
                  name: "data_ready" | "drawnFrame",
                  callback: (drawnFrame?: { currentTime: number }) => void
              ) => () => void;
              setSubframe(useSubFrames: boolean): void;
              goToAndPlay(value: number, isFrame?: boolean): void;
          }
        | undefined;

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (typeof anim !== "undefined") {
                return;
            }
            if (!canvasRef.current) {
                return;
            }

            anim = lottie.loadAnimation({
                renderer: "canvas",
                loop: props.loop ?? true,
                autoplay: true,
                animationData: props.animationData,
                rendererSettings: {
                    context: canvasRef.current.getContext("2d"),
                    progressiveLoad: true,
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any);
            anim.setSubframe(false);

            if (props.id) {
                const currentFrame = animCurrentFrameCache.get(props.id);
                if (currentFrame) {
                    anim.goToAndPlay(currentFrame, true);
                }

                anim.addEventListener(
                    "drawnFrame",
                    (drawnFrame?: { currentTime: number }) => {
                        animCurrentFrameCache.put(
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            props.id!,
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            drawnFrame!.currentTime
                        );
                    }
                );
            }

            anim.addEventListener("data_ready", () => {
                if (props.animationLoaded) {
                    props.animationLoaded?.();
                }
                setAnimReady(true);
            });
        }, props.timeout);

        return () => {
            clearTimeout(timeout);
            if (anim) {
                anim.destroy();
                anim = undefined;
            }
        };
    }, [props.animationData]);

    let pixelRatio = 1.0;
    if (devicePixelRatio === 2.0) {
        pixelRatio = 1.5;
    }

    return (
        <LottieCanvas
            $visible={animReady}
            $startWithFrame={!!props.startWithFrame}
            ref={canvasRef}
            width={props.size * pixelRatio}
            height={props.size * pixelRatio}
        ></LottieCanvas>
    );
};

const LottieCanvas = styled.canvas<{
    $visible: boolean;
    $startWithFrame: boolean;
}>`
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    z-index: 1;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    opacity: ${(props) => (props.$startWithFrame ? "1" : "0")};

    ${(props) =>
        props.$visible &&
        css`
            opacity: 1;
        `}
`;

const Root = styled.div<{ $size: number }>`
    width: ${(props) => props.$size}px;
    height: ${(props) => props.$size}px;
    position: relative;
`;

const Preview = styled.div<{ $visible?: boolean }>`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$visible &&
        css`
            opacity: 1;
        `}
`;

const Image = styled.img<{ $size: number }>`
    max-width: ${(props) => props.$size}px;
    max-height: ${(props) => props.$size}px;
`;
