import React from "react";
import { PeerChannel, PeerChat, PeerUser } from "../../tg/Convenience/Peer";
import { ImmutableArray } from "../../misc/ImmutableArray";
import { Update } from "../../tg/Updates/Update";
import styled, { css, keyframes } from "styled-components";
import { Avatar } from "../ui/Avatar";
import { TG } from "../../tg/TG";
import { API } from "../../tg/Codegen/API/APISchema";
import { previewStrippedJpeg } from "../../tg/Files/Preview";
import { tg } from "../../App";
import { ReadableStatus } from "../../tg/Convenience/ReadableStatus";
import { NetworkState } from "../../tg/Session/DataCenter";

interface Props {
    chat: TG.Chat | undefined;
    typings: ImmutableArray<Update.UserTyping> | undefined;
}

interface State {
    networkState: NetworkState;
    preview?: Uint8Array;
    image: Uint8Array | undefined;
}

export class ChatHeader extends React.Component<Props, State> {
    state: State = {
        networkState: NetworkState.idling,
        image: undefined,
    };

    getPreview() {
        let photo: API.UserProfilePhotoType | undefined;
        let preview: Uint8Array | undefined;
        if (this.props.chat?.peer instanceof PeerUser) {
            photo = this.props.chat.peer.user?.photo;
            if (photo instanceof API.UserProfilePhoto && photo.strippedThumb) {
                preview = previewStrippedJpeg(photo.strippedThumb.bytes);
            }
        } else if (this.props.chat?.peer instanceof PeerChat) {
            photo = this.props.chat.peer.chat?.photo;
            if (photo instanceof API.ChatPhoto && photo.strippedThumb) {
                preview = previewStrippedJpeg(photo.strippedThumb.bytes);
            }
        } else if (this.props.chat?.peer instanceof PeerChannel) {
            photo = this.props.chat?.peer.channel?.photo;
            if (photo instanceof API.ChatPhoto && photo.strippedThumb) {
                preview = previewStrippedJpeg(photo.strippedThumb.bytes);
            }
        }

        this.setState({
            preview: preview,
        });

        return photo;
    }

    setPhoto(photo: API.UserProfilePhotoType | API.ChatPhotoType | undefined) {
        if (
            this.props.chat?.peer &&
            (photo instanceof API.UserProfilePhoto ||
                photo instanceof API.ChatPhoto)
        ) {
            tg.getPeerPhoto(
                this.props.chat.peer,
                photo.photoId,
                photo.dcId
            ).subscribe((file) => {
                this.setState({
                    image: file.bytes.bytes,
                });
            });
        }
    }

    setAvatar() {
        const photo = this.getPreview();
        if (
            typeof photo === "undefined" ||
            photo instanceof API.ChatPhotoEmpty
        ) {
            this.setState({
                preview: undefined,
                image: undefined,
            });
        } else {
            this.setPhoto(photo);
        }
    }

    componentDidMount(): void {
        tg.state.subscribe((state) => {
            this.setState({
                networkState: state,
            });
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            typeof this.props.chat === "undefined" &&
            typeof prevProps.chat === "undefined"
        ) {
            return;
        }

        if (
            typeof this.props.chat !== "undefined" &&
            typeof prevProps.chat === "undefined"
        ) {
            this.setAvatar();
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (
            prevProps.chat?.peer &&
            !this.props.chat?.peer?.equals(prevProps.chat?.peer)
        ) {
            this.setAvatar();
        }
    }

    render() {
        return (
            <Root>
                <Avatar
                    id={this.props.chat?.id || 0}
                    name={this.props.chat?.title || ""}
                    deleted={this.props.chat?.deleted ?? false}
                    user={this.props.chat?.peer instanceof PeerUser}
                    size={40}
                    preview={this.state.preview}
                    image={this.state.image}
                />
                <Details>
                    {this.props.chat && <Title>{this.props.chat?.title}</Title>}
                    {this.props.chat?.peer && (
                        <Status>
                            <ReadableStatus
                                of={this.props.chat.peer}
                            ></ReadableStatus>
                        </Status>
                    )}
                </Details>
                <BusyContainer>
                    <Busy
                        $hide={this.state.networkState === NetworkState.idling}
                    >
                        <BusyProgressIncrease></BusyProgressIncrease>
                        <BusyProgressDecrease></BusyProgressDecrease>
                    </Busy>
                </BusyContainer>
            </Root>
        );
    }
}

const Root = styled.div`
    width: 100%;
    height: 60px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    position: relative;
    z-index: 1;
    backdrop-filter: blur(14px);
`;

const Details = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 12px;
`;

const Title = styled.span`
    color: rgba(0, 0, 0, 0.87);
    font-size: 18px;
    font-weight: 500;
`;

const Status = styled.span`
    line-height: 18px;
    font-size: 14px;
    color: rgba(117, 117, 117, 1);
`;

const progressIncreaseAnimation = keyframes`
    from {
        transform: translateX(-67%) scaleX(0.33);
    }
    to {
        transform: translateX(133%) scaleX(0.67);
    }
`;

const progressDecreaseAnimation = keyframes`
    from {
        transform: translateX(-133%) scaleX(1);
    }
    to {
        transform: translateX(100%) scaleX(0.5);
    }
`;

const BusyContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const Busy = styled.div<{ $hide: boolean }>`
    position: absolute;
    height: 4px;
    overflow-x: hidden;
    background: rgba(61, 129, 161, 0.4);
    left: 0;
    right: 0;
    top: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$hide &&
        css`
            height: 0px;
        `}
`;

const BusyProgressIncrease = styled.div`
    position: absolute;
    background: rgba(61, 129, 161, 1);
    width: 100%;
    height: 4px;
    left: 0;
    transform: translateX(-100%);

    animation-name: ${progressIncreaseAnimation};
    animation-duration: 3s;
    animation-delay: 200ms;
    animation-iteration-count: infinite;
`;

const BusyProgressDecrease = styled.div`
    position: absolute;
    background: rgba(61, 129, 161, 1);
    width: 100%;
    height: 4px;
    left: 0;
    transform: translateX(-100%);

    animation-name: ${progressDecreaseAnimation};
    animation-duration: 3s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
`;
