import { useEffect, useState } from "react";
import { tg } from "../../../../App";
import { Sticker } from "../../../ui/Sticker";
import { API } from "../../../../tg/Codegen/API/APISchema";
import styled from "styled-components";
import { TLLong } from "../../../../tg/TL/Types/TLLong";

type ReactionIconAttr = { emoji: string } | { docId: TLLong };

export const ReactionIcon = (props: ReactionIconAttr) => {
    const [document, setDocument] = useState<API.Document | undefined>();

    useEffect(() => {
        if ("emoji" in props) {
            tg.getReactionIcon(props.emoji).subscribe((doc) => {
                setDocument(doc);
            });
        } else if ("docId" in props) {
            throw new Error("not implemented");
        }
    }, []);

    return (
        <Root>
            {document && <Sticker size={20} sticker={document}></Sticker>}
        </Root>
    );
};

const Root = styled.div`
    width: 20px;
    height: 20px;
    margin: 0 4px;
`;
