import React from "react";
import styled, { css } from "styled-components";
import { ThemeContext } from "../../App";
import { SecureRandom } from "../../tg/SecureRandom/SecureRandom";
import { urlsafeBase64 } from "../../tg/Utils/Base64";

interface Props {
    fillWidth?: boolean;
    label: string;
    focus?: boolean;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    type: "text" | "password";
    error?: string;
    autocomplete?: string;
    onChange?: (value: string) => void;
}

interface State {
    focus: boolean;
}

export class Input extends React.Component<Props, State> {
    private ref: HTMLInputElement | null = null;
    private id = urlsafeBase64(SecureRandom.bytes(8));

    state = {
        focus: false,
    };

    componentDidUpdate() {
        // if (this.props.focus !== prevProps.focus) {
        if (this.props.focus) {
            this.ref?.focus();
        }
        // }
    }

    render() {
        return (
            <ThemeContext.Consumer>
                {(theme) => (
                    <Root $fillWidth={!!this.props.fillWidth}>
                        <Label
                            htmlFor={this.id}
                            $focus={this.state.focus}
                            $primaryColor={theme.primaryColor}
                            $disabled={this.props.disabled}
                        >
                            {this.props.label}
                        </Label>
                        <StyledInput
                            id={this.id}
                            type={this.props.type}
                            ref={(ref) => (this.ref = ref)}
                            onFocus={() => {
                                this.setState({ focus: true });
                            }}
                            onBlur={() => {
                                this.setState({
                                    focus: !!this.ref?.value,
                                });
                            }}
                            value={this.props.value}
                            onChange={(event) => {
                                if (this.props.onChange) {
                                    this.props.onChange(event.target.value);
                                }
                            }}
                            placeholder={this.props.placeholder}
                            disabled={this.props.disabled}
                            autoComplete={this.props.autocomplete}
                        ></StyledInput>
                        <Underline />
                        <UnderlineFocus
                            focus={this.state.focus}
                            primaryColor={theme.primaryColor}
                            disabled={this.props.disabled}
                            error={!!this.props.error}
                        />
                        <ErrorComponent>{this.props.error}</ErrorComponent>
                    </Root>
                )}
            </ThemeContext.Consumer>
        );
    }
}

const Root = styled.div<{ $fillWidth: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 185px;
    min-height: 72px;
    font-size: 16px;
    position: relative;

    ${(props) =>
        props.$fillWidth &&
        css`
            width: 100%;
        `};
`;

const StyledInput = styled.input`
    position: relative;
    width: 100%;
    height: 48px;
    margin: 0;
    padding: 0;
    margin-top: 16px;
    border: 0;
    outline: none;
    appearance: textfield;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: inherit;
    background: transparent;

    &:disabled {
        color: rgba(0, 0, 0, 0.48);
    }

    &::placeholder {
        color: transparent;
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    }

    &:focus::placeholder {
        color: rgba(0, 0, 0, 0.4);
    }
`;

const Label = styled.label<{
    $focus: boolean;
    $primaryColor: string;
    $disabled?: boolean;
}>`
    position: absolute;
    left: 0;
    top: 35px;
    font-size: inherit;
    color: rgba(0, 0, 0, 0.4);
    cursor: text;
    z-index: 1;
    pointer-events: none;
    user-select: none;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transform-origin: top left;

    ${(props) =>
        props.$focus &&
        css`
            transform: scale(0.85);
            top: 14px;
            color: ${props.$primaryColor};
        `};

    ${(props) =>
        props.$disabled &&
        css`
            color: rgba(0, 0, 0, 0.48);
        `}
`;

const Underline = styled.hr`
    position: absolute;
    left: 0;
    bottom: 12px;
    width: 100%;
    height: 0px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
`;

const UnderlineFocus = (props: {
    focus: boolean;
    primaryColor: string;
    disabled?: boolean;
    error?: boolean;
}) => {
    let color = props.primaryColor;
    if (props.error) {
        color = "rgba(181, 65, 65, 1)";
    }
    if (props.disabled) {
        color = "rgba(0, 0, 0, 0.48)";
    }

    return (
        <Underline
            style={{
                borderBottom: `2px solid ${color}`,
                transform:
                    props.focus || props.error ? "scaleX(1)" : "scaleX(0)",
            }}
        ></Underline>
    );
};

const ErrorComponent = styled.p`
    position: absolute;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: rgba(181, 65, 65, 1);
    left: 0;
    bottom: -8px;
`;
