import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import styled from "styled-components";
import { tg } from "../../App";
import { Peer } from "../../tg/Convenience/Peer";
import { TG } from "../../tg/TG";
import { MessageType } from "../../tg/Convenience/Message";
import { ImmutableArray } from "../../misc/ImmutableArray";
import { API } from "../../tg/Codegen/API/APISchema";
import { measureMedia } from "../../misc/MediaMeasurer";
import { gifMessageMaxSize } from "./MessageTypes/GifMessage";
import { gameMessagePreviewMaxSize } from "./MessageTypes/GameMessage";
import { ChatMessagesListItem } from "./ChatMessagesListItem";
import {
    photoMessageMaxHeight,
    photoMessageMaxWidth,
} from "./MessageTypes/PhotoMessage";
import { measureNode } from "../../misc/ReactNodeMeasurer";
import { textMessage } from "./MessageTypes/TextMessage";

interface Props {
    peer?: Peer;
    paddingBottom: number;
    chat: TG.Chat;
}

interface State {
    messages: ImmutableArray<TG.Message>;
    availableReactions?: API.messages.AvailableReactions;
}

export class ChatMessagesList extends React.Component<Props, State> {
    listRef: VariableSizeList | null = null;

    state: State = {
        messages: new ImmutableArray(),
    };

    rowHeight(index: number): number {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const msg = this.state.messages.at(index)!;
        let height = 0;
        if (msg.type === MessageType.Text) {
            let dims: { width: number | string; height: number | string } = {
                width: "auto",
                height: "auto",
            };
            if (msg.media instanceof API.MessageMediaPhoto) {
                const photo = (msg.media as API.MessageMediaPhoto)
                    .photo as API.Photo;
                dims = measureMedia(
                    photoMessageMaxWidth,
                    photoMessageMaxHeight,
                    photo.sizes.items
                );
                height += dims.height as number;
            }

            const size = measureNode(
                textMessage(msg, this.props.chat, { maxWidth: dims.width })
            );

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            // const size = measureText(msg.message!, {
            //     wordBreak: "break-word",
            //     hyphens: "auto",
            //     whiteSpace: "pre-wrap",
            //     fontSize: "14px",
            //     lineHeight: "16px",
            //     padding: `7px ${msg.out ? "24px" : "10px"} 5px 10px`,
            //     boxSizing: "border-box",
            //     borderRadius: "12px",
            //     maxWidth: "420px",
            // });
            height += size.height;
        } else if (msg.type === MessageType.Photo) {
            const photo = (msg.media as API.MessageMediaPhoto)
                .photo as API.Photo;
            photo.sizes.items;
            if (
                (msg.media as API.MessageMediaPhoto).photo instanceof API.Photo
            ) {
                height =
                    measureMedia(
                        photoMessageMaxWidth,
                        photoMessageMaxHeight,
                        photo.sizes.items
                    ).height + 4;
            }
        } else if (msg.type === MessageType.Sticker) {
            height = 160 + 8 + 8;
        } else if (msg.type === MessageType.Location) {
            height = 144;
        } else if (msg.type === MessageType.Venue) {
            height = 96;
        } else if (msg.type === MessageType.Contact) {
            height = 48;
        } else if (msg.type === MessageType.GIF) {
            height = measureMedia(
                gifMessageMaxSize,
                gifMessageMaxSize,
                (
                    (msg.media as API.MessageMediaDocument)
                        .document as API.Document
                ).thumbs?.items
            ).height;
        } else if (msg.type === MessageType.Video) {
            height = measureMedia(
                gifMessageMaxSize,
                gifMessageMaxSize,
                (
                    (msg.media as API.MessageMediaDocument)
                        .document as API.Document
                ).thumbs?.items
            ).height;
        } else if (msg.type === MessageType.Voice) {
            height = 48;
        } else if (msg.type === MessageType.Document) {
            height = 48;
        } else if (msg.type === MessageType.Game) {
            const game = (msg.media as API.MessageMediaGame).game;
            if (game.document instanceof API.Document) {
                height =
                    measureMedia(
                        gameMessagePreviewMaxSize,
                        gameMessagePreviewMaxSize,
                        game.document.thumbs?.items
                    ).height +
                    18 +
                    4;
            } else if (game.photo instanceof API.Photo) {
                height =
                    measureMedia(
                        gameMessagePreviewMaxSize,
                        gameMessagePreviewMaxSize,
                        game.photo.sizes.items
                    ).height +
                    18 +
                    4;
            }
        } else if (msg.type === MessageType.ChatEditPhoto) {
            height = 68;
        } else if (msg.type === MessageType.Call) {
            height = 48;
        }

        // height = height + 8;

        // height = Math.max(height + 28, 48);

        // Top message's (first from the bottom in the list)
        // height is greater to mimic padding
        if (index === 0) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            // height += spacing.desktopGutterLess!;
            height = height + 72;
        }

        if (
            index === this.state.messages.length - 1 &&
            this.state.messages.length > 1
        ) {
            height = height + this.props.paddingBottom;
        }

        return height;
    }

    componentDidMount() {
        tg.getAvailableReactions().subscribe((reactions) => {
            this.setState({
                availableReactions: reactions,
            });
        });
    }

    shouldComponentUpdate(
        nextProps: Readonly<Props>,
        nextState: Readonly<State>
    ): boolean {
        if (nextProps.peer) {
            if (!this.props.peer?.equals(nextProps.peer)) {
                return true;
            }
            if (typeof this.props.peer === "undefined") {
                return true;
            }
        }
        if (this.props.paddingBottom !== nextProps.paddingBottom) {
            return true;
        }
        if (this.state.messages !== nextState.messages) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (!this.props.peer) {
            return undefined;
        }

        if (!this.props.peer.equals(prevProps.peer as Peer)) {
            tg.getMessageHistory(this.props.peer).subscribe((messages) => {
                this.setState(
                    {
                        messages: new ImmutableArray(messages).reversed(),
                    },
                    () => {
                        this.listRef?.resetAfterIndex(0, true);
                        this.listRef?.scrollToItem(
                            this.state.messages.length - 1,
                            "end"
                        );
                    }
                );
            });
        }
    }

    itemKey(index: number): number | string {
        return this.state.messages.at(index)?.id || index;
    }

    render() {
        return (
            <Root>
                <AutoSizer>
                    {(params: { width: number; height: number }) => (
                        <VariableSizeList
                            ref={(ref) => (this.listRef = ref)}
                            width={params.width}
                            height={params.height}
                            itemCount={this.state.messages.length}
                            itemSize={(index) => this.rowHeight(index)}
                            itemKey={this.itemKey.bind(this)}
                        >
                            {(params: {
                                index: number;
                                style: React.CSSProperties;
                            }) => (
                                <Row
                                    index={params.index}
                                    style={params.style}
                                    message={
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        this.state.messages.at(params.index)!
                                    }
                                    chat={this.props.chat}
                                    first={params.index === 0}
                                    last={
                                        params.index ===
                                            this.state.messages.length - 1 &&
                                        this.state.messages.length > 1
                                    }
                                    paddingBottom={this.props.paddingBottom}
                                ></Row>
                            )}
                        </VariableSizeList>
                    )}
                </AutoSizer>
            </Root>
        );
    }
}

const Row = (props: {
    index: number;
    style: React.CSSProperties;
    message: TG.Message;
    chat: TG.Chat;
    first: boolean;
    last: boolean;
    paddingBottom: number;
}) => {
    let alignContent = undefined;
    if (props.first) {
        alignContent = "flex-end";
    } else if (props.last) {
        alignContent = "flex-start";
    }

    return (
        <div
            style={{
                ...props.style,
                display: "grid",
                alignContent: alignContent,
                paddingTop: props.last ? 4 : undefined,
            }}
        >
            <ChatMessagesListItem
                message={props.message}
                chat={props.chat}
            ></ChatMessagesListItem>
        </div>
    );
};

const Root = styled.div`
    width: 100%;
    flex-grow: 1;
`;
