export class ImmutableArray<T> {
    private readonly array: Array<T>;

    constructor(array: Array<T> = [], copy = true) {
        if (copy) {
            this.array = array.slice();
        } else {
            this.array = array;
        }
    }

    at(index: number): T | undefined {
        return this.array.at(index);
    }

    set(index: number, value: T): ImmutableArray<T> {
        const array = this.array.slice();
        const actualIndex = index < 0 ? array.length + index : index;
        if (actualIndex < 0 || actualIndex >= array.length) {
            throw new RangeError();
        }
        array[actualIndex] = value;

        return new ImmutableArray<T>(array, false);
    }

    push(...values: Array<T>) {
        const array = [...this.array, ...values];
        return new ImmutableArray<T>(array);
    }

    prepend(...values: Array<T>) {
        const array = this.array.slice();
        array.unshift(...values);
        return new ImmutableArray<T>(array, false);
    }

    findIndex(predicate: (value: T) => boolean | undefined) {
        return this.array.findIndex(predicate);
    }

    sorted(comparator?: (a: T, b: T) => number) {
        const array = this.array.slice().sort(comparator);
        return new ImmutableArray<T>(array);
    }

    reversed() {
        const array = this.array.slice().reverse();
        return new ImmutableArray<T>(array);
    }

    filter(predicate: (value: T, index: number, array: Array<T>) => boolean) {
        const array = this.array.slice().filter(predicate);
        return new ImmutableArray<T>(array);
    }

    map(func: (value: T, index: number, array: Array<T>) => unknown) {
        const array = this.array.slice().map(func);
        return new ImmutableArray(array);
    }

    join(separator?: string) {
        return this.array.slice().join(separator);
    }

    copy() {
        return new ImmutableArray(this.array);
    }

    get length() {
        return this.array.length;
    }

    *[Symbol.iterator]() {
        for (const item of this.array.values()) {
            yield item;
        }
    }
}
