import React from "react";
import styled, { css } from "styled-components";
import { ThemeContext } from "../../App";
import { DotsSpinner } from "./spinners/DotsSpinner";

interface Props {
    caption: string;
    disabled?: boolean;
    busy?: boolean;
    onClick: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

export class Button extends React.Component<Props, State> {
    render() {
        return (
            <ThemeContext.Consumer>
                {(theme) => (
                    <Root>
                        <RootButton
                            onClick={() => this.props.onClick()}
                            $bgColor={theme.primaryColor}
                            $busy={this.props.busy}
                            disabled={this.props.disabled}
                        >
                            {this.props.caption}
                        </RootButton>
                        {this.props.busy && <DotsSpinner></DotsSpinner>}
                    </Root>
                )}
            </ThemeContext.Consumer>
        );
    }
}

const Root = styled.div`
    position: relative;
`;

const RootButton = styled.button<{ $bgColor: string; $busy?: boolean }>`
    background: ${(props) => props.$bgColor};
    height: 32px;
    border: none;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0 16px;
    color: #fff;
    cursor: pointer;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    overflow: hidden;
    position: relative;
    user-select: none;

    ${(props) =>
        props.$busy &&
        css`
            color: transparent;
        `}

    &:disabled {
        pointer-events: none;
        background: rgba(0, 0, 0, 0.24);
        box-shadow: none;
    }

    &:hover {
        filter: brightness(1.25);
    }

    &:active {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 10px,
            rgba(0, 0, 0, 0.24) 0px 2px 5px;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: radial-gradient(circle, #000 10%, transparent 10%);
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(10, 10);
        opacity: 0;
        transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
            opacity 900ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    }

    &:active:after {
        transform: scale(5, 5);
        opacity: 0.2;
        transition: 0s;
    }
`;
