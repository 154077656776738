import React from "react";
import { Input } from "../ui/Input";
import styled from "styled-components";
import { ThemeContext, tg } from "../../App";
import { Button } from "../ui/Button";
import { TG } from "../../tg/TG";

interface Props {
    focus: boolean;
    required?: boolean;
}

interface State {
    password: string;
    hint?: string;
    focus?: boolean;
    busy?: boolean;
    error?: string;
}

export class AuthSlidePassword extends React.Component<Props, State> {
    state: State = {
        password: "",
        hint: "",
    };

    private click() {
        this.setState({
            busy: true,
        });
        tg.checkPassword(this.state.password).subscribe({
            error: (error: TG.Error) => {
                this.handleError(error);
                this.setState({
                    busy: false,
                    focus: true,
                });
            },
        });
    }

    private handleError(error: TG.Error) {
        const generic = "Something went wrong. Try again.";
        switch (error.type) {
            case TG.ErrorType.flood:
                this.setState({
                    error: "Too many wrong attempts. Try later.",
                });
                break;
            case TG.ErrorType.badRequest:
                if (error.details === "PASSWORD_HASH_INVALID") {
                    this.setState({
                        error: "Wrong password.",
                    });
                } else {
                    this.setState({
                        error: generic,
                    });
                }
                break;
            default:
                this.setState({
                    error: generic,
                });
                break;
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.required !== prevProps.required) {
            this.setState({
                focus: true,
            });

            tg.getPassword().subscribe((password) => {
                this.setState({
                    hint: password.hint?.string,
                });
            });
        }
    }

    render() {
        return (
            <RootPaper>
                <ThemeContext.Consumer>
                    {(theme) => (
                        <Title $color={theme.primaryColorDark}>
                            Two-step verification
                        </Title>
                    )}
                </ThemeContext.Consumer>
                <Subtitle>
                    Type in your additional password to proceed.
                </Subtitle>
                <form onSubmit={(event) => event.preventDefault()}>
                    <Input
                        type="password"
                        autocomplete="current-password"
                        label={"Password"}
                        fillWidth={true}
                        focus={this.state.focus}
                        value={this.state.password}
                        placeholder={
                            this.state.hint && `Hint: ${this.state.hint}`
                        }
                        onChange={(value) => this.setState({ password: value })}
                        disabled={this.state.busy}
                        error={this.state.error}
                    ></Input>
                    <ButtonContainer>
                        <Button
                            disabled={
                                this.state.password === "" || this.state.busy
                            }
                            caption={"Next"}
                            onClick={() => this.click()}
                            busy={this.state.busy}
                        ></Button>
                    </ButtonContainer>
                </form>
            </RootPaper>
        );
    }
}

const RootPaper = (props: { children: React.ReactNode }) => {
    return (
        <Root>
            <Paper>{props.children}</Paper>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
`;

const Paper = styled.div`
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    background: #fff;
    margin: 0 auto;
    border-radius: 4px;
    padding: 32px 36px;
    box-sizing: border-box;
`;

const Title = styled.h1<{ $color: string }>`
    margin: 0 0 12px 0;
    font-weight: 500;
    font-size: 24px;
    color: ${(props) => props.$color};
`;

const Subtitle = styled.p`
    margin: 7px 0;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    line-height: 1.25;
`;

const ButtonContainer = styled.div`
    margin: 24px 0 0 0;
    display: flex;
    justify-content: center;
`;
