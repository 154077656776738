export class TimeoutCache<T> {
    private _item: T | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private timeout: any;

    /**
     * @param invalidateIn milliseconds
     */
    constructor(readonly invalidateIn: number) {}

    set item(value: T | undefined) {
        this._item = value;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this._item = undefined;
        }, this.invalidateIn);
    }

    get item(): T | undefined {
        return this._item;
    }
}
