import React from "react";
import { AppConfig } from "./tg/AppConfig";
import { TG } from "./tg/TG";
import styled from "styled-components";
import { Main } from "./components/main/Main";
import { Subscription } from "rxjs";
import { Auth } from "./components/auth/Auth";
import moment from "moment";
import "moment/locale/de";

interface State {
    authorized?: boolean;
}

export class App extends React.Component<Record<string, never>, State> {
    state: State = {};
    authorizedSubscription?: Subscription;

    componentDidMount() {
        this.authorizedSubscription = tg.authorized.subscribe((authorized) => {
            this.setState({
                authorized: authorized,
            });
        });
    }

    shouldComponentUpdate(nextProps: Record<string, never>, nextState: State) {
        return nextState.authorized !== this.state.authorized;
    }

    componentWillUnmount() {
        this.authorizedSubscription?.unsubscribe();
    }

    render() {
        const Component = this.state.authorized ? Main : Auth;
        return (
            <ThemeContext.Provider value={palette}>
                <Root>
                    {typeof this.state.authorized !== "undefined" && (
                        <Component />
                    )}
                </Root>
            </ThemeContext.Provider>
        );
    }
}

const Root = styled.div`
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
        45deg,
        rgba(77, 160, 202, 1) 0%,
        rgba(82, 184, 176, 1) 100%
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const palette = {
    primaryColor: "rgba(61, 129, 161, 1)",
    primaryColorDark: "rgba(41, 86, 107, 1)",
};

export const ThemeContext = React.createContext(palette);

const appConfig = new AppConfig(
    "0.0.1",
    17622,
    "4c8d4f23ccabc463551f8594e7ec6355",
    [
        `-----BEGIN RSA PUBLIC KEY-----
    MIIBCgKCAQEAwVACPi9w23mF3tBkdZz+zwrzKOaaQdr01vAbU4E1pvkfj4sqDsm6
    lyDONS789sVoD/xCS9Y0hkkC3gtL1tSfTlgCMOOul9lcixlEKzwKENj1Yz/s7daS
    an9tqw3bfUV/nqgbhGX81v/+7RFAEd+RwFnK7a+XYl9sluzHRyVVaTTveB2GazTw
    Efzk2DWgkBluml8OREmvfraX3bkHZJTKX4EQSjBbbdJ2ZXIsRrYOXfaA+xayEGB+
    8hdlLmAjbCVfaigxX0CDqWeR1yFL9kwd9P0NsZRPsmoqVwMbMu7mStFai6aIhc3n
    Slv8kg9qv1m6XHVQY3PnEw+QQtqSIXklHwIDAQAB
    -----END RSA PUBLIC KEY-----`,
    ],
    2
);

export const tg = new TG(appConfig);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).tg = tg;

moment.locale(navigator.language);
