import React from "react";
import styled from "styled-components";
import { ChatsList } from "./ChatsList";
import { Chat } from "./Chat";
import { ImmutableArray } from "../../misc/ImmutableArray";
import { Update } from "../../tg/Updates/Update";
import { TG } from "../../tg/TG";

interface State {
    chat: TG.Chat | undefined;
    typings: ImmutableArray<Update.UserTyping> | undefined;
}

export class Main extends React.Component<Record<string, never>, State> {
    state: State = {
        chat: undefined,
        typings: undefined,
    };

    onChat(chat: TG.Chat) {
        this.setState({
            chat: chat,
        });
    }

    onTypings(typings: ImmutableArray<Update.UserTyping>) {
        this.setState({
            typings: typings,
        });
    }

    render() {
        return (
            <Root>
                <ChatsList
                    onChat={this.onChat.bind(this)}
                    onTypings={this.onTypings.bind(this)}
                ></ChatsList>
                <Chat
                    chat={this.state.chat}
                    typings={this.state.typings}
                ></Chat>
            </Root>
        );
    }
}

const Root = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`;
