import QRCodeStyling, { Options } from "qr-code-styling";
import React from "react";
import styled, { css } from "styled-components";
import { ClockAppSpinner } from "../ui/spinners/ClockAppSpinner";
import { urlsafeBase64 } from "../../tg/Utils/Base64";

interface Props {
    data: ArrayBuffer | undefined;
    size: number;
    foreground: string;
}

interface State {
    data: string;
}

export class QRCode extends React.Component<Props, State> {
    state = {
        data: "",
    };

    private updateQRdata() {
        const template = (code: Uint8Array) =>
            `tg://login?token=${urlsafeBase64(code)}`;

        let data;
        if (this.props.data) {
            data = new Uint8Array(this.props.data);
        } else {
            data = new Uint8Array(0);
        }

        const options: Partial<Options> = {
            width: this.props.size,
            height: this.props.size,
            type: "canvas",
            data: template(data),
            margin: 0,
            qrOptions: {
                typeNumber: 0,
                mode: "Byte",
                errorCorrectionLevel: "Q",
            },
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 1.0,
                margin: 0,
            },
            dotsOptions: {
                color: this.props.foreground,
                type: "rounded",
            },
            backgroundOptions: {
                color: "#ffffff",
            },
            cornersSquareOptions: {
                color: this.props.foreground,
                type: "extra-rounded",
            },
            cornersDotOptions: {
                color: this.props.foreground,
                type: "dot",
            },
        };

        const qrCode = new QRCodeStyling(options);
        (async () => {
            const raw = await qrCode.getRawData("png");
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const bytes = new Uint8Array(await raw!.arrayBuffer());
            const str = btoa(String.fromCodePoint(...bytes));
            this.setState({
                data: `data:image/png;base64,${str}`,
            });
        })();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.data !== prevProps.data) {
            this.updateQRdata();
        }
    }

    render() {
        return (
            <Root>
                {this.state.data !== "" && <QR src={this.state.data}></QR>}
                <LoaderContainer $active={this.state.data === ""}>
                    <ClockAppSpinner></ClockAppSpinner>
                </LoaderContainer>
            </Root>
        );
    }
}

const Root = styled.div`
    position: relative;
    width: 200px;
    height: 200px;
    padding: 10px 0;
`;

const QR = styled.img`
    width: 200px;
    height: 200px;
`;

const LoaderContainer = styled.div<{ $active?: boolean }>`
    position: absolute;
    width: 200px;
    height: 200px;
    top: 10px;
    left: 0;
    background: #fff;
    opacity: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$active &&
        css`
            opacity: 1;
        `};
`;
