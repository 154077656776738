import { Observable, filter, map, of, tap } from "rxjs";
import { AppConfig } from "../AppConfig";
import { HashMap } from "../DataStructures/HashMap/HashMap";
import { DataCenter } from "../Session/DataCenter";
import { PersistentStorage } from "../Storage/PersistentStorage";
import { TLInt } from "../TL/Types/TLInt";
import { API } from "../Codegen/API/APISchema";
import { TLLong } from "../TL/Types/TLLong";
import { FileDownloader } from "./FileDownloader";
import { TLObject } from "../TL/Interfaces/TLObject";
import { ByteStream } from "../DataStructures/ByteStream";
import { TLBytes } from "../TL/Types/TLBytes";
import { TLString } from "../TL/Types/TLString";
import { concat } from "../Utils/BytesConcat";

export class FileManager {
    private readonly dataCenters = new HashMap<TLInt, DataCenter>();
    private readonly downloaders = new HashMap<TLLong, FileDownloader>();

    constructor(
        private readonly dc: DataCenter,
        private readonly storage: PersistentStorage.Storage,
        protected readonly appConfig: AppConfig
    ) {}

    getPeerPhoto(
        peer: API.InputPeerType,
        photoId: TLLong,
        dcId: TLInt,
        big?: boolean
    ): Observable<API.upload.File> {
        const location = new API.InputPeerPhotoFileLocation(big, peer, photoId);

        const observable = new Observable<API.upload.File>((subscriber) => {
            this.storage.readFile(location.serialized()).subscribe((data) => {
                if (data) {
                    subscriber.next(
                        TLObject.deserialized(
                            new ByteStream(new Uint8Array(data))
                        )
                    );
                } else {
                    if (this.downloaders.get(photoId)) {
                        this.downloaders
                            .get(photoId)
                            ?.subscribers.push(subscriber);
                    } else {
                        this.getDC(dcId.value).subscribe((dc) => {
                            const downloader = new FileDownloader(
                                location,
                                dc,
                                [subscriber]
                            );
                            this.downloaders.put(photoId, downloader);
                            downloader.download();
                        });
                    }
                }
            });

            return () => {
                this.downloaders
                    .get(photoId)
                    ?.subscribers.forEach((sub) => sub.unsubscribe());
                subscriber.unsubscribe();
                this.downloaders.remove(photoId);
            };
        });

        return observable.pipe(
            tap((file) => {
                const complete =
                    file.bytes.bytes.byteLength !== FileDownloader.chunkSize;
                this.storage
                    .appendFile(
                        location.serialized(),
                        file.serialized(),
                        complete
                    )
                    .subscribe();
            }),
            filter(
                (file) =>
                    file.bytes.bytes.byteLength !== FileDownloader.chunkSize
            )
        );
    }

    getDocument(
        id: TLLong,
        accessHash: TLLong,
        fileReference: TLBytes,
        dcId: TLInt,
        thumbSize: TLString
    ): Observable<API.upload.File> {
        const location = new API.InputDocumentFileLocation(
            id,
            accessHash,
            fileReference,
            thumbSize
        );

        const key = new StorageKey(id, dcId, thumbSize);

        const observable = new Observable<API.upload.File>((subscriber) => {
            this.storage.readFile(key.serialized()).subscribe((data) => {
                if (data) {
                    subscriber.next(
                        TLObject.deserialized(
                            new ByteStream(new Uint8Array(data))
                        )
                    );
                } else {
                    if (this.downloaders.get(id)) {
                        this.downloaders.get(id)?.subscribers.push(subscriber);
                    } else {
                        this.getDC(dcId.value).subscribe((dc) => {
                            const downloader = new FileDownloader(
                                location,
                                dc,
                                [subscriber]
                            );
                            this.downloaders.put(id, downloader);
                            downloader.download();
                        });
                    }
                }
            });

            return () => {
                this.downloaders
                    .get(id)
                    ?.subscribers.forEach((sub) => sub.unsubscribe());
                subscriber.unsubscribe();
                this.downloaders.remove(id);
            };
        });

        return observable.pipe(
            tap((file) => {
                const complete =
                    file.bytes.bytes.byteLength !== FileDownloader.chunkSize;
                this.storage
                    .appendFile(key.serialized(), file.serialized(), complete)
                    .subscribe();
            }),
            filter(
                (file) =>
                    file.bytes.bytes.byteLength !== FileDownloader.chunkSize
            )
        );
    }

    getPhoto(
        id: TLLong,
        accessHash: TLLong,
        fileReference: TLBytes,
        thumbSize: TLString,
        dcId: TLInt
    ): Observable<API.upload.File> {
        const location = new API.InputPhotoFileLocation(
            id,
            accessHash,
            fileReference,
            thumbSize
        );

        const key = new StorageKey(id, dcId, thumbSize);

        const observable = new Observable<API.upload.File>((subscriber) => {
            this.storage.readFile(key.serialized()).subscribe((data) => {
                if (data) {
                    subscriber.next(
                        TLObject.deserialized(
                            new ByteStream(new Uint8Array(data))
                        )
                    );
                } else {
                    if (this.downloaders.get(id)) {
                        this.downloaders.get(id)?.subscribers.push(subscriber);
                    } else {
                        this.getDC(dcId.value).subscribe((dc) => {
                            const downloader = new FileDownloader(
                                location,
                                dc,
                                [subscriber]
                            );
                            this.downloaders.put(id, downloader);
                            downloader.download();
                        });
                    }
                }
            });

            return () => {
                this.downloaders
                    .get(id)
                    ?.subscribers.forEach((sub) => sub.unsubscribe());
                subscriber.unsubscribe();
                this.downloaders.remove(id);
            };
        });

        return observable.pipe(
            tap((file) => {
                const complete =
                    file.bytes.bytes.byteLength !== FileDownloader.chunkSize;
                this.storage
                    .appendFile(key.serialized(), file.serialized(), complete)
                    .subscribe();
            }),
            filter(
                (file) =>
                    file.bytes.bytes.byteLength !== FileDownloader.chunkSize
            )
        );
    }

    getStickerSetThumb(
        input: API.InputStickerSetThumb,
        dcId: TLInt
    ): Observable<API.upload.File> {
        const id = (input.stickerset as { id: TLLong }).id;

        const observable = new Observable<API.upload.File>((subscriber) => {
            this.storage.readFile(input.serialized()).subscribe((data) => {
                if (data) {
                    subscriber.next(
                        TLObject.deserialized(
                            new ByteStream(new Uint8Array(data))
                        )
                    );
                } else {
                    if (this.downloaders.get(id)) {
                        this.downloaders.get(id)?.subscribers.push(subscriber);
                    } else {
                        this.getDC(dcId.value).subscribe((dc) => {
                            const downloader = new FileDownloader(input, dc, [
                                subscriber,
                            ]);
                            this.downloaders.put(id, downloader);
                            downloader.download();
                        });
                    }
                }
            });

            return () => {
                this.downloaders
                    .get(id)
                    ?.subscribers.forEach((sub) => sub.unsubscribe());
                subscriber.unsubscribe();
                this.downloaders.remove(id);
            };
        });

        return observable.pipe(
            tap((file) => {
                const complete =
                    file.bytes.bytes.byteLength !== FileDownloader.chunkSize;
                this.storage
                    .appendFile(input.serialized(), file.serialized(), complete)
                    .subscribe();
            }),
            filter(
                (file) =>
                    file.bytes.bytes.byteLength !== FileDownloader.chunkSize
            )
        );
    }

    private getDC(dcId: number): Observable<DataCenter> {
        const id = new TLInt(dcId);
        if (this.dataCenters.get(id)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return of(this.dataCenters.get(id)!);
        }

        return this.dc.dcOptions.pipe(
            map((options) =>
                options.find(
                    (option) =>
                        (option.id.equals(id) &&
                            option.mediaOnly &&
                            !option.ipv6) ||
                        (option.id.equals(id) && !option.ipv6)
                )
            ),
            map((option) => option as API.DcOption),
            map((option) => {
                const dc = new DataCenter(
                    this.appConfig.version,
                    this.appConfig.apiId,
                    true
                );
                this.dataCenters.put(id, dc);

                dc.delegate = {
                    authorized: (userId, dcId, authKey) => {
                        this.storage.writeAuthorization({
                            dcId: dcId,
                            authKey: authKey.buffer,
                            main: 0,
                        });
                    },
                };

                this.initDC(dcId, option.id.value, dc);

                return dc;
            })
        );
    }

    private initDC(id: number, dcId: number, dc: DataCenter) {
        this.storage.readAuthorization(id).subscribe((auth) => {
            if (typeof auth === "undefined") {
                const exportAuth = new API.auth.ExportAuthorization(
                    new TLInt(id)
                );
                this.dc.call(exportAuth).subscribe((auth) => {
                    if (auth instanceof API.auth.ExportedAuthorization) {
                        const importAuth = new API.auth.ImportAuthorization(
                            auth.id,
                            auth.bytes
                        );
                        dc.call(importAuth).subscribe();
                    }
                });
            }
            dc.init(this.appConfig.rsaKeys, dcId, auth?.authKey);
        });
    }
}

class StorageKey extends TLObject {
    constructor(
        readonly id: TLLong,
        readonly dcId: TLInt,
        readonly thumbSize: TLString
    ) {
        super();
    }

    deserialized(data: ByteStream) {
        const id = TLLong.deserialized(data);
        if (!id) return undefined;

        const dcId = TLInt.deserialized(data);
        if (!dcId) return undefined;

        const thumbSize = TLString.deserialized(data);
        if (!thumbSize) return undefined;

        return new StorageKey(id, dcId, thumbSize);
    }

    serialized(): Uint8Array {
        const id = this.id.serialized();
        const dcId = this.dcId.serialized();
        const thumbSize = this.thumbSize.serialized();

        return concat(id, dcId, thumbSize);
    }
}
