import React from "react";
import styled, { css } from "styled-components";
import { SendIcon } from "../ui/icons/Send";
import { ThemeContext } from "../../App";
import { EmojiIcon } from "../ui/icons/Emoji";
import { ChatStickersPopover } from "./ChatStickersPopover";
import { API } from "../../tg/Codegen/API/APISchema";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
    onHeightChange: (height?: number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {
    message: string;
    stickersPopupOpen: boolean;
}

export class ChatFooter extends React.Component<Props, State> {
    ref: HTMLElement | null = null;
    inputRef: HTMLElement | null = null;
    stickersPopupAnchor: HTMLElement | null = null;

    state: State = {
        message: "",
        stickersPopupOpen: false,
    };

    submitSticker(sticker: API.Document) {
        console.log(sticker);
    }

    onEmojiClick(event: React.MouseEvent) {
        event.preventDefault();

        this.setState({
            stickersPopupOpen: true,
        });
    }

    onStickerPopupClose() {
        this.setState({
            stickersPopupOpen: false,
        });
    }

    inputOnChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ message: event.target.value });

        const ref = this.inputRef;
        if (ref) {
            ref.style.height = "auto";
            ref.style.height =
                Math.min(ref.scrollHeight, inputMaxHeight) + "px";

            this.props.onHeightChange(ref.clientHeight + 32);
        }
    }

    componentDidMount() {
        const height = this.ref?.clientHeight || 0;
        this.props.onHeightChange(height + 32);
    }

    shouldComponentUpdate(
        nextProps: Readonly<Props>,
        nextState: Readonly<State>
    ): boolean {
        if (this.props.onHeightChange !== nextProps.onHeightChange) {
            return true;
        }

        if (this.state.message !== nextState.message) {
            return true;
        }

        if (this.state.stickersPopupOpen !== nextState.stickersPopupOpen) {
            return true;
        }

        return false;
    }

    render() {
        return (
            <Root ref={(ref) => (this.ref = ref)}>
                <Input
                    ref={(ref) => (this.inputRef = ref)}
                    rows={1}
                    placeholder="Write a message..."
                    onChange={this.inputOnChange.bind(this)}
                    value={this.state.message}
                ></Input>
                <EmojiIconRoot
                    onClick={(event) => this.onEmojiClick(event)}
                    onMouseEnter={(event) => this.onEmojiClick(event)}
                    ref={(ref) => (this.stickersPopupAnchor = ref)}
                >
                    <EmojiIcon></EmojiIcon>
                </EmojiIconRoot>
                <ThemeContext.Consumer>
                    {(theme) => (
                        <SendIconRoot
                            $color={theme.primaryColor}
                            $active={this.state.message !== ""}
                        >
                            <SendIcon></SendIcon>
                        </SendIconRoot>
                    )}
                </ThemeContext.Consumer>
                <ChatStickersPopover
                    open={this.state.stickersPopupOpen}
                    onClose={this.onStickerPopupClose.bind(this)}
                    anchorEl={this.stickersPopupAnchor}
                    onClick={(sticker) => this.submitSticker(sticker)}
                />
            </Root>
        );
    }
}

const inputMaxHeight = 96;

const Root = styled.div`
    width: calc(100% - 38px);
    /* min-height: 48px; */
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    margin: 38px 19px;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    backdrop-filter: blur(14px);
`;

const Input = styled.textarea`
    width: auto;
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    line-height: 16px;
    padding: 16px 8px;
    box-sizing: border-box;
    min-height: 48px;
    max-height: 144px;
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    resize: none;
    overflow: auto;
`;

const EmojiIconRoot = styled.div`
    width: 24px;
    height: 24px;
    padding: 8px;
    fill: rgba(0, 0, 0, 0.54);
    margin-right: 4px;
`;

const SendIconRoot = styled.div<{ $color: string; $active: boolean }>`
    width: 24px;
    height: 24px;
    padding: 8px;
    cursor: pointer;
    fill: rgba(0, 0, 0, 0.3);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$active &&
        css`
            fill: ${props.$color};
        `}
`;
