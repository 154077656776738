import { renderToString } from "react-dom/server";

export const measureNode = (node: JSX.Element) => {
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.visibility = "hidden";
    container.style.left = "-9999px";
    container.style.top = "-9999px";
    container.style.width = "auto";
    container.style.height = "auto";
    container.style.pointerEvents = "none";
    container.style.userSelect = "none";
    document.body.appendChild(container);

    const child = document.createElement("div");
    child.innerHTML = renderToString(node);
    container.appendChild(child);

    const rect = container.getBoundingClientRect();

    document.body.removeChild(container);

    return {
        width: rect.width,
        height: rect.height,
    };
};
