import { ReactNode } from "react";
import styled from "styled-components";
import { CheckmarkDoneIcon } from "../../../ui/icons/CheckmarkDone";
import { CheckmarkIcon } from "../../../ui/icons/Checkmark";

export function SentCheckmark(props: { double?: boolean; white?: boolean }) {
    let indicator: ReactNode;
    if (props.double) {
        indicator = <CheckmarkDoneIcon />;
    } else {
        indicator = <CheckmarkIcon />;
    }

    return <Root $white={props.white}>{indicator}</Root>;
}

const Root = styled.div<{ $white?: boolean }>`
    width: 18px;
    height: 18px;

    svg path {
        stroke-width: 48px;
        color: ${(props) => (props.$white ? "#fff" : "rgba(61, 200, 161, 1)")};
    }
`;
