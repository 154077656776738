import {
    Observable,
    combineLatest,
    combineLatestAll,
    concatMap,
    map,
    merge,
    mergeAll,
    mergeMap,
    mergeWith,
    of,
    pipe,
    switchMap,
    tap,
} from "rxjs";
import { API } from "../Codegen/API/APISchema";
import { PersistentStorage } from "../Storage/PersistentStorage";
import { TG } from "../TG";
import { convenienceMessageFor } from "../Convenience/MessageFor";
import { Update } from "./Update";
import { PeerChat, PeerUser } from "../Convenience/Peer";

export namespace UpdatesHandler {
    export function newMessage(params: {
        update: API.UpdateNewMessage;
        storage: PersistentStorage.Storage;
        tg: TG;
    }): Observable<Update> {
        const msg = params.update.message;
        const peer = (msg as API.Message & API.MessageService).fromId;
        const out = (msg as API.Message & API.MessageService).out;
        let fromId = 0;
        if (peer instanceof API.PeerUser) {
            fromId = peer.userId.value.toNumber();
        } else if (peer instanceof API.PeerChat) {
            fromId = peer.chatId.value.toNumber();
        } else if (peer instanceof API.PeerChannel) {
            fromId = peer.channelId.value.toNumber();
        }

        return params.storage.writeMessages(msg).pipe(
            mergeMap(() => {
                if (fromId === 0 && out) {
                    return params.storage.readMyUserId();
                } else if (fromId === 0 && msg.peerId instanceof API.PeerUser) {
                    return of(msg.peerId.userId.value.toNumber());
                } else {
                    return of(fromId);
                }
            }),
            mergeMap((id) => {
                return params.storage.readUsers(id as number);
            }),
            map((users) => {
                return users.at(0);
            }),
            map((user) => {
                return new Update.NewMessage(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    convenienceMessageFor(msg, user)!
                );
            })
        );
    }

    export function messageId() {}

    export function deleteMessages(params: {
        update: API.UpdateDeleteMessages;
        storage: PersistentStorage.Storage;
        tg: TG;
    }): Observable<Update> {
        const msgIds = params.update.messages.items.map((item) => item.value);
        return params.storage.deleteMessages(...msgIds).pipe(
            map(() => {
                return new Update.DeleteMessages(msgIds);
            })
        );
    }

    export function userTyping(params: {
        update: API.UpdateUserTyping;
        storage: PersistentStorage.Storage;
        tg: TG;
    }): Observable<Update> {
        return params.storage
            .readUsers(params.update.userId.value.toNumber())
            .pipe(map((user) => user.at(0) as API.User))
            .pipe(
                map((user) => ({
                    user: user,
                    peer: new PeerUser(
                        params.update.userId.value.toNumber(),
                        user
                    ),
                }))
            )
            .pipe(
                map(
                    ({ user, peer }) =>
                        new Update.UserTyping(
                            peer,
                            user,
                            params.update.action,
                            performance.now() + 6000
                        )
                )
            );
    }

    export function chatUserTyping(params: {
        update: API.UpdateChatUserTyping;
        storage: PersistentStorage.Storage;
        tg: TG;
    }): Observable<Update> {
        const userId = (
            params.update.fromId as API.PeerUser
        ).userId.value.toNumber();
        const chatId = params.update.chatId.value.toNumber();

        return combineLatest([
            params.storage.readUsers(userId),
            params.storage.readChats(chatId),
        ])
            .pipe(
                map(([user, chat]) => ({
                    user: user.at(0) as API.User,
                    chat: chat.at(0) as API.Chat,
                }))
            )
            .pipe(
                map((map) => ({
                    user: map.user,
                    peer: new PeerChat(chatId, map.chat),
                }))
            )
            .pipe(
                map(
                    ({ user, peer }) =>
                        new Update.UserTyping(
                            peer,
                            user,
                            params.update.action,
                            performance.now() + 6000
                        )
                )
            );
    }

    export function chatParticipants() {}

    export function userStatus(params: {
        update: API.UpdateUserStatus;
        storage: PersistentStorage.Storage;
        tg: TG;
    }): Observable<Update> {
        return params.storage
            .readUsers(params.update.userId.value.toNumber())
            .pipe(
                map((users) => users.at(0) as API.User),
                map((user) =>
                    Object.assign(user, {
                        status: params.update.status,
                    })
                ),
                tap((user) => params.storage.writeUsers(user)),
                map((user) => new Update.User(user))
            );
    }

    export function username() {
        console.log("username");
    }

    export function newAuthorization() {}

    export function newEncryptedMessage() {}

    export function encryptedChatTyping() {}

    export function encryption() {}

    export function encryptedMessagesRead() {}

    export function chatParticipantAdd() {}

    export function chatParticipantDelete() {}

    export function dcOptions() {}

    export function notifySettings() {}

    export function serviceNotification() {}

    export function privacy() {}

    export function userPhone() {}

    export function readHistoryInbox(params: {
        update: API.UpdateReadHistoryInbox;
        storage: PersistentStorage.Storage;
        tg: TG;
    }): Observable<Update> {
        return params.storage.readDialogs(params.update.peer).pipe(
            map((dialogs) => dialogs[0]),
            map((dialog) => dialog as API.Dialog),
            map((dialog) => {
                return Object.assign(dialog, {
                    readInboxMaxId: params.update.maxId,
                });
            }),
            tap((dialog) => params.storage.writeDialogs(dialog)),
            map(() => {
                return new Update.ReadHistoryInbox(
                    params.update.peer,
                    params.update.maxId.value
                );
            })
        );
    }

    export function readHistoryOutbox(params: {
        update: API.UpdateReadHistoryOutbox;
        storage: PersistentStorage.Storage;
        tg: TG;
    }): Observable<Update> {
        return params.storage.readDialogs(params.update.peer).pipe(
            map((dialogs) => dialogs[0]),
            map((dialog) => dialog as API.Dialog),
            map((dialog) => {
                return Object.assign(dialog, {
                    readOutboxMaxId: params.update.maxId,
                });
            }),
            tap((dialog) => params.storage.writeDialogs(dialog)),
            map(() => {
                return new Update.ReadHistoryOutbox(
                    params.update.peer,
                    params.update.maxId.value
                );
            })
        );
    }

    export function webPage() {}

    export function readMessageContents() {}

    export function channelTooLong() {}

    export function channel(params: {
        update: API.UpdateChannel;
        storage: PersistentStorage.Storage;
        tg: TG;
    }): Observable<Update> {
        const channelId = params.update.channelId.value.toNumber();
        return params.storage.readChats(channelId).pipe(
            map((chats) => chats.at(0) as API.Channel),
            map((chat) => {
                return new Update.Channel(chat);
            })
        );
    }

    export function newChannelMessage() {}

    export function readChannelInbox() {}

    export function deleteChannelMessages() {}

    export function channelMessageViews() {}

    export function chatParticipantAdmin() {}

    export function newStickerSet() {}

    export function stickerSetsOrder() {}

    export function stickerSets() {}

    export function savedGifs() {}

    export function botInlineQuery() {}

    export function botInlineSend() {}

    export function editChannelMessage() {}

    export function botCallbackQuery() {}

    export function editMessage(params: {
        update: API.UpdateEditMessage;
        storage: PersistentStorage.Storage;
        tg: TG;
    }) {
        const msg = params.update.message;
        const peer = (msg as API.Message & API.MessageService).fromId;
        const out = (msg as API.Message & API.MessageService).out;
        let fromId = 0;
        if (peer instanceof API.PeerUser) {
            fromId = peer.userId.value.toNumber();
        } else if (peer instanceof API.PeerChat) {
            fromId = peer.chatId.value.toNumber();
        } else if (peer instanceof API.PeerChannel) {
            fromId = peer.channelId.value.toNumber();
        }

        return params.storage.writeMessages(msg).pipe(
            mergeMap(() => {
                if (fromId === 0 && out) {
                    return params.storage.readMyUserId();
                } else if (fromId === 0 && msg.peerId instanceof API.PeerUser) {
                    return of(msg.peerId.userId.value.toNumber());
                } else {
                    return of(fromId);
                }
            }),
            mergeMap((id) => {
                return params.storage.readUsers(id as number);
            }),
            map((users) => {
                return users.at(0);
            }),
            map((user) => {
                return new Update.EditMessage(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    convenienceMessageFor(msg, user)!
                );
            })
        );
    }

    export function inlineBotCallbackQuery() {}

    export function readChannelOutbox() {}

    export function draftMessage() {}

    export function readFeaturedStickers() {}

    export function recentStickers() {}

    export function config() {}

    export function ptsChanged() {}

    export function channelWebPage() {}

    export function dialogPinned() {}

    export function pinnedDialogs() {}

    export function botWebhookJson() {}

    export function botWebhookJsonQuery() {}

    export function botShippingQuery() {}

    export function botPrecheckoutQuery() {}

    export function phoneCall() {}

    export function langPackTooLong() {}

    export function langPack() {}

    export function favedStickers() {}

    export function channelReadMessagesContents() {}

    export function contactsReset() {}

    export function channelAvailableMessages() {}

    export function dialogUnreadMark() {}

    export function messagePoll() {}

    export function chatDefaultBannedRights() {}

    export function folderPeers() {}

    export function peerSettings() {}

    export function peerLocated() {}

    export function newScheduledMessage() {}

    export function deleteScheduledMessages() {}

    export function theme() {}

    export function geoLiveViewed() {}

    export function loginToken() {
        return of(new Update.LoginToken());
    }

    export function messagePollVote() {}

    export function dialogFilter() {}

    export function dialogFilterOrder() {}

    export function dialogFilters() {}

    export function phoneCallSignalingData() {}

    export function channelMessageForwards() {}

    export function readChannelDiscussionInbox() {}

    export function readChannelDiscussionOutbox() {}

    export function peerBlocked() {}

    export function channelUserTyping() {}

    export function pinnedMessages() {}

    export function pinnedChannelMessages() {}

    export function chat(params: {
        update: API.UpdateChat;
        storage: PersistentStorage.Storage;
        tg: TG;
    }): Observable<Update> {
        const chatId = params.update.chatId.value.toNumber();
        return params.storage.readChats(chatId).pipe(
            map((chats) => chats.at(0) as API.Chat),
            map((chat) => {
                return new Update.Chat(chat);
            })
        );
    }

    export function groupCallParticipants() {}

    export function groupCall() {}

    export function peerHistoryTTL() {}

    export function chatParticipant() {}

    export function channelParticipant() {}

    export function botStopped() {}

    export function groupCallConnection() {}

    export function botCommands() {}

    export function pendingJoinRequests() {}

    export function botChatInviteRequester() {}

    export function messageReactions(params: {
        update: API.UpdateMessageReactions;
        storage: PersistentStorage.Storage;
        tg: TG;
    }) {
        console.log("TODO");
    }

    export function attachMenuBots() {}

    export function webViewResultSent() {}

    export function botMenuButton() {}

    export function savedRingtones() {}

    export function transcribedAudio() {}

    export function readFeaturedEmojiStickers() {}

    export function userEmojiStatus() {}

    export function recentEmojiStatuses() {}

    export function recentReactions() {}

    export function moveStickerSetToTop() {}

    export function messageExtendedMedia() {}

    export function channelPinnedTopic() {}

    export function channelPinnedTopics() {}

    export function user(params: {
        update: API.UpdateUser;
        storage: PersistentStorage.Storage;
        tg: TG;
    }): Observable<Update> {
        const userId = params.update.userId.value.toNumber();
        return params.storage.readUsers(userId).pipe(
            map((users) => users.at(0) as API.User),
            map((user) => {
                return new Update.User(user);
            })
        );
    }

    export function autoSaveSettings() {}

    export function groupInvitePrivacyForbidden() {}

    export function story() {}

    export function readStories() {}

    export function storyId() {}

    export function storiesStealthMode() {}

    export function sentStoryReaction() {}
}
