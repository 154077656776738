export const urlsafeBase64 = (data: Uint8Array): string => {
    return base64(data)
        .replace(/=/g, "")
        .replace(/\+/g, "-")
        .replace(/\//g, "_");
};

export const base64 = (data: Uint8Array): string => {
    let result = "";
    for (let i = 0; i < data.length; i++) {
        result = result.concat(String.fromCharCode(data[i]));
    }
    return btoa(result);
};
