import runes from "runes";
import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import { TLInt } from "../../tg/TL/Types/TLInt";
import { LRUCache } from "../../misc/LRUCache";
import { inlineImage } from "../../tg/Files/Inline";

export function Avatar(props: {
    id: number;
    name: string;
    deleted: boolean;
    user: boolean;
    size: number;
    preview?: Uint8Array;
    image?: Uint8Array;
    online?: boolean;
    onlineBorderColor?: string | false;
}) {
    let color: string;
    if (props.deleted) {
        color = colorInactive;
    } else {
        color = hashColor(props.id);
    }

    let image: string;
    if (props.image) {
        image = inlineImage(props.image, "jpeg");
    } else if (props.preview) {
        image = inlineImage(props.preview, "jpeg");
    } else {
        image = transparentPixel;
    }

    let preview: boolean;
    if (props.image) {
        preview = false;
    } else {
        preview = !!props.preview;
    }

    const [online, setOnline] = useState(lastOnline.get(new TLInt(props.id)));
    useEffect(() => {
        setOnline(props.online ?? false);
        setLastOnline(props.id, props.online ?? false);
    }, [props.online]);

    return (
        <div>
            <Root
                $color={color}
                $emoji={isEmoji(props.name)}
                $size={props.size}
            >
                {initials(props.name, props.user)}
                <Image $preview={preview} src={image}></Image>
            </Root>
            <Online
                $visible={online}
                $borderColor={props.onlineBorderColor}
            ></Online>
        </div>
    );
}

// Cache last online stati to avoid flickering of the
// online status indicator
const lastOnline = new LRUCache<TLInt, boolean>(16);
const setLastOnline = (id: number, status: boolean) => {
    lastOnline.put(new TLInt(id), status);
};

const colors = [
    "rgb(221, 126, 107)",
    "rgb(234, 153, 153)",
    "rgb(249, 203, 156)",
    "rgb(255, 229, 153)",
    "rgb(182, 215, 168)",
    "rgb(162, 196, 201)",
];
const colorInactive = "#c0c7cd";

const hashColor = (x: number): string => {
    x = x + 0x9e3779b9 + (x << 6) + (x >> 2);

    return colors[x % colors.length];
};

const transparentPixel =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

const Root = styled.div<{ $color: string; $emoji: boolean; $size: number }>`
    position: relative;
    width: ${(props) => props.$size}px;
    height: ${(props) => props.$size}px;
    background: ${(props) => props.$color};
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;

    ${(props) =>
        props.$emoji &&
        css`
            color: #000;
        `}

    ${(props) =>
        props.$size < 40 &&
        css`
            font-size: 10px;
        `}
`;

const Image = styled.img<{ $preview: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;

    ${(props) =>
        props.$preview &&
        css`
            filter: blur(4px);
            width: 120%;
            height: 120%;
            left: -10%;
            top: -10%;
        `}
`;

const Online = styled.div<{
    $visible?: boolean;
    $selected?: boolean;
    $borderColor?: string | false;
}>`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background: rgba(61, 200, 161, 1);
    border-radius: 50%;
    border: 2px solid
        ${(props) => props.$borderColor || "rgba(255, 255, 255, 0.87)"};
    z-index: 1;
    opacity: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transform: scale(0);

    ${(props) =>
        props.$visible &&
        css`
            opacity: 1;
            transform: scale(1);
        `};
`;

const initials = (title: string, isUser: boolean): string => {
    if (!isUser) {
        return runes.substr(title, 0, 1);
    }

    const components = title.split(/\s/);
    if (components[0] === components[components.length - 1]) {
        return runes.substr(title, 0, 1);
    }

    return (
        runes.substr(components[0], 0, 1) + runes.substr(components[1], 0, 1)
    );
};

const isEmoji = (str: string): boolean => {
    return runes.substr(str, 0, 1) !== str.substring(0, 1);
};
