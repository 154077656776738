import { ByteStream } from "../../DataStructures/ByteStream";
import { concat } from "../../Utils/BytesConcat";
import { HashMap } from "../../DataStructures/HashMap/HashMap";

import { TLBytes } from "../../TL/Types/TLBytes";
import { TLDouble } from "../../TL/Types/TLDouble";
import { TLFlags } from "../../TL/Types/TLFlags";
import { TLInt } from "../../TL/Types/TLInt";
import { TLLong } from "../../TL/Types/TLLong";
import { TLString } from "../../TL/Types/TLString";
import { TLVector } from "../../TL/Types/TLVector";

import { TLFunction } from "../../TL/Interfaces/TLFunction";
import { TLObject } from "../../TL/Interfaces/TLObject";
import { TLSerializable } from "../../TL/Interfaces/TLSerializable";

export namespace API {
  export const layer = 165;
  export type BoolType = BoolFalse | BoolTrue;
  export type InputPeerType =
    | InputPeerEmpty
    | InputPeerSelf
    | InputPeerChat
    | InputPeerUser
    | InputPeerChannel
    | InputPeerUserFromMessage
    | InputPeerChannelFromMessage;
  export type InputUserType =
    | InputUserEmpty
    | InputUserSelf
    | InputUser
    | InputUserFromMessage;
  export type InputContactType = InputPhoneContact;
  export type InputFileType = InputFile | InputFileBig;
  export type InputMediaType =
    | InputMediaEmpty
    | InputMediaUploadedPhoto
    | InputMediaPhoto
    | InputMediaGeoPoint
    | InputMediaContact
    | InputMediaUploadedDocument
    | InputMediaDocument
    | InputMediaVenue
    | InputMediaPhotoExternal
    | InputMediaDocumentExternal
    | InputMediaGame
    | InputMediaInvoice
    | InputMediaGeoLive
    | InputMediaPoll
    | InputMediaDice
    | InputMediaStory;
  export type InputChatPhotoType =
    | InputChatPhotoEmpty
    | InputChatUploadedPhoto
    | InputChatPhoto;
  export type InputGeoPointType = InputGeoPointEmpty | InputGeoPoint;
  export type InputPhotoType = InputPhotoEmpty | InputPhoto;
  export type InputFileLocationType =
    | InputFileLocation
    | InputEncryptedFileLocation
    | InputDocumentFileLocation
    | InputSecureFileLocation
    | InputTakeoutFileLocation
    | InputPhotoFileLocation
    | InputPhotoLegacyFileLocation
    | InputPeerPhotoFileLocation
    | InputStickerSetThumb
    | InputGroupCallStream;
  export type PeerType = PeerUser | PeerChat | PeerChannel;
  export namespace storage {
    export type FileTypeType =
      | FileUnknown
      | FilePartial
      | FileJpeg
      | FileGif
      | FilePng
      | FilePdf
      | FileMp3
      | FileMov
      | FileMp4
      | FileWebp;
  }
  export type UserType = UserEmpty | User;
  export type UserProfilePhotoType = UserProfilePhotoEmpty | UserProfilePhoto;
  export type UserStatusType =
    | UserStatusEmpty
    | UserStatusOnline
    | UserStatusOffline
    | UserStatusRecently
    | UserStatusLastWeek
    | UserStatusLastMonth;
  export type ChatType =
    | ChatEmpty
    | Chat
    | ChatForbidden
    | Channel
    | ChannelForbidden;
  export type ChatFullType = ChatFull | ChannelFull;
  export type ChatParticipantType =
    | ChatParticipant
    | ChatParticipantCreator
    | ChatParticipantAdmin;
  export type ChatParticipantsType =
    | ChatParticipantsForbidden
    | ChatParticipants;
  export type ChatPhotoType = ChatPhotoEmpty | ChatPhoto;
  export type MessageType = MessageEmpty | Message | MessageService;
  export type MessageMediaType =
    | MessageMediaEmpty
    | MessageMediaPhoto
    | MessageMediaGeo
    | MessageMediaContact
    | MessageMediaUnsupported
    | MessageMediaDocument
    | MessageMediaWebPage
    | MessageMediaVenue
    | MessageMediaGame
    | MessageMediaInvoice
    | MessageMediaGeoLive
    | MessageMediaPoll
    | MessageMediaDice
    | MessageMediaStory;
  export type MessageActionType =
    | MessageActionEmpty
    | MessageActionChatCreate
    | MessageActionChatEditTitle
    | MessageActionChatEditPhoto
    | MessageActionChatDeletePhoto
    | MessageActionChatAddUser
    | MessageActionChatDeleteUser
    | MessageActionChatJoinedByLink
    | MessageActionChannelCreate
    | MessageActionChatMigrateTo
    | MessageActionChannelMigrateFrom
    | MessageActionPinMessage
    | MessageActionHistoryClear
    | MessageActionGameScore
    | MessageActionPaymentSentMe
    | MessageActionPaymentSent
    | MessageActionPhoneCall
    | MessageActionScreenshotTaken
    | MessageActionCustomAction
    | MessageActionBotAllowed
    | MessageActionSecureValuesSentMe
    | MessageActionSecureValuesSent
    | MessageActionContactSignUp
    | MessageActionGeoProximityReached
    | MessageActionGroupCall
    | MessageActionInviteToGroupCall
    | MessageActionSetMessagesTTL
    | MessageActionGroupCallScheduled
    | MessageActionSetChatTheme
    | MessageActionChatJoinedByRequest
    | MessageActionWebViewDataSentMe
    | MessageActionWebViewDataSent
    | MessageActionGiftPremium
    | MessageActionTopicCreate
    | MessageActionTopicEdit
    | MessageActionSuggestProfilePhoto
    | MessageActionRequestedPeer
    | MessageActionSetChatWallPaper
    | MessageActionSetSameChatWallPaper;
  export type DialogType = Dialog | DialogFolder;
  export type PhotoType = PhotoEmpty | Photo;
  export type PhotoSizeType =
    | PhotoSizeEmpty
    | PhotoSize
    | PhotoCachedSize
    | PhotoStrippedSize
    | PhotoSizeProgressive
    | PhotoPathSize;
  export type GeoPointType = GeoPointEmpty | GeoPoint;
  export namespace auth {
    export type SentCodeType = SentCode | SentCodeSuccess;
  }
  export namespace auth {
    export type AuthorizationType = Authorization | AuthorizationSignUpRequired;
  }
  export type InputNotifyPeerType =
    | InputNotifyPeer
    | InputNotifyUsers
    | InputNotifyChats
    | InputNotifyBroadcasts
    | InputNotifyForumTopic;
  export type WallPaperType = WallPaper | WallPaperNoFile;
  export type ReportReasonType =
    | InputReportReasonSpam
    | InputReportReasonViolence
    | InputReportReasonPornography
    | InputReportReasonChildAbuse
    | InputReportReasonOther
    | InputReportReasonCopyright
    | InputReportReasonGeoIrrelevant
    | InputReportReasonFake
    | InputReportReasonIllegalDrugs
    | InputReportReasonPersonalDetails;
  export namespace contacts {
    export type ContactsType = ContactsNotModified | Contacts;
  }
  export namespace contacts {
    export type BlockedType = Blocked | BlockedSlice;
  }
  export namespace messages {
    export type DialogsType = Dialogs | DialogsSlice | DialogsNotModified;
  }
  export namespace messages {
    export type MessagesType =
      | Messages
      | MessagesSlice
      | ChannelMessages
      | MessagesNotModified;
  }
  export namespace messages {
    export type ChatsType = Chats | ChatsSlice;
  }
  export type MessagesFilterType =
    | InputMessagesFilterEmpty
    | InputMessagesFilterPhotos
    | InputMessagesFilterVideo
    | InputMessagesFilterPhotoVideo
    | InputMessagesFilterDocument
    | InputMessagesFilterUrl
    | InputMessagesFilterGif
    | InputMessagesFilterVoice
    | InputMessagesFilterMusic
    | InputMessagesFilterChatPhotos
    | InputMessagesFilterPhoneCalls
    | InputMessagesFilterRoundVoice
    | InputMessagesFilterRoundVideo
    | InputMessagesFilterMyMentions
    | InputMessagesFilterGeo
    | InputMessagesFilterContacts
    | InputMessagesFilterPinned;
  export type UpdateType =
    | UpdateNewMessage
    | UpdateMessageID
    | UpdateDeleteMessages
    | UpdateUserTyping
    | UpdateChatUserTyping
    | UpdateChatParticipants
    | UpdateUserStatus
    | UpdateUserName
    | UpdateNewAuthorization
    | UpdateNewEncryptedMessage
    | UpdateEncryptedChatTyping
    | UpdateEncryption
    | UpdateEncryptedMessagesRead
    | UpdateChatParticipantAdd
    | UpdateChatParticipantDelete
    | UpdateDcOptions
    | UpdateNotifySettings
    | UpdateServiceNotification
    | UpdatePrivacy
    | UpdateUserPhone
    | UpdateReadHistoryInbox
    | UpdateReadHistoryOutbox
    | UpdateWebPage
    | UpdateReadMessagesContents
    | UpdateChannelTooLong
    | UpdateChannel
    | UpdateNewChannelMessage
    | UpdateReadChannelInbox
    | UpdateDeleteChannelMessages
    | UpdateChannelMessageViews
    | UpdateChatParticipantAdmin
    | UpdateNewStickerSet
    | UpdateStickerSetsOrder
    | UpdateStickerSets
    | UpdateSavedGifs
    | UpdateBotInlineQuery
    | UpdateBotInlineSend
    | UpdateEditChannelMessage
    | UpdateBotCallbackQuery
    | UpdateEditMessage
    | UpdateInlineBotCallbackQuery
    | UpdateReadChannelOutbox
    | UpdateDraftMessage
    | UpdateReadFeaturedStickers
    | UpdateRecentStickers
    | UpdateConfig
    | UpdatePtsChanged
    | UpdateChannelWebPage
    | UpdateDialogPinned
    | UpdatePinnedDialogs
    | UpdateBotWebhookJSON
    | UpdateBotWebhookJSONQuery
    | UpdateBotShippingQuery
    | UpdateBotPrecheckoutQuery
    | UpdatePhoneCall
    | UpdateLangPackTooLong
    | UpdateLangPack
    | UpdateFavedStickers
    | UpdateChannelReadMessagesContents
    | UpdateContactsReset
    | UpdateChannelAvailableMessages
    | UpdateDialogUnreadMark
    | UpdateMessagePoll
    | UpdateChatDefaultBannedRights
    | UpdateFolderPeers
    | UpdatePeerSettings
    | UpdatePeerLocated
    | UpdateNewScheduledMessage
    | UpdateDeleteScheduledMessages
    | UpdateTheme
    | UpdateGeoLiveViewed
    | UpdateLoginToken
    | UpdateMessagePollVote
    | UpdateDialogFilter
    | UpdateDialogFilterOrder
    | UpdateDialogFilters
    | UpdatePhoneCallSignalingData
    | UpdateChannelMessageForwards
    | UpdateReadChannelDiscussionInbox
    | UpdateReadChannelDiscussionOutbox
    | UpdatePeerBlocked
    | UpdateChannelUserTyping
    | UpdatePinnedMessages
    | UpdatePinnedChannelMessages
    | UpdateChat
    | UpdateGroupCallParticipants
    | UpdateGroupCall
    | UpdatePeerHistoryTTL
    | UpdateChatParticipant
    | UpdateChannelParticipant
    | UpdateBotStopped
    | UpdateGroupCallConnection
    | UpdateBotCommands
    | UpdatePendingJoinRequests
    | UpdateBotChatInviteRequester
    | UpdateMessageReactions
    | UpdateAttachMenuBots
    | UpdateWebViewResultSent
    | UpdateBotMenuButton
    | UpdateSavedRingtones
    | UpdateTranscribedAudio
    | UpdateReadFeaturedEmojiStickers
    | UpdateUserEmojiStatus
    | UpdateRecentEmojiStatuses
    | UpdateRecentReactions
    | UpdateMoveStickerSetToTop
    | UpdateMessageExtendedMedia
    | UpdateChannelPinnedTopic
    | UpdateChannelPinnedTopics
    | UpdateUser
    | UpdateAutoSaveSettings
    | UpdateGroupInvitePrivacyForbidden
    | UpdateStory
    | UpdateReadStories
    | UpdateStoryID
    | UpdateStoriesStealthMode
    | UpdateSentStoryReaction;
  export namespace updates {
    export type DifferenceType =
      | DifferenceEmpty
      | Difference
      | DifferenceSlice
      | DifferenceTooLong;
  }
  export type UpdatesType =
    | UpdatesTooLong
    | UpdateShortMessage
    | UpdateShortChatMessage
    | UpdateShort
    | UpdatesCombined
    | Updates
    | UpdateShortSentMessage;
  export namespace photos {
    export type PhotosType = Photos | PhotosSlice;
  }
  export namespace upload {
    export type FileType = File | FileCdnRedirect;
  }
  export namespace help {
    export type AppUpdateType = AppUpdate | NoAppUpdate;
  }
  export type EncryptedChatType =
    | EncryptedChatEmpty
    | EncryptedChatWaiting
    | EncryptedChatRequested
    | EncryptedChat
    | EncryptedChatDiscarded;
  export type EncryptedFileType = EncryptedFileEmpty | EncryptedFile;
  export type InputEncryptedFileType =
    | InputEncryptedFileEmpty
    | InputEncryptedFileUploaded
    | InputEncryptedFile
    | InputEncryptedFileBigUploaded;
  export type EncryptedMessageType = EncryptedMessage | EncryptedMessageService;
  export namespace messages {
    export type DhConfigType = DhConfigNotModified | DhConfig;
  }
  export namespace messages {
    export type SentEncryptedMessageType =
      | SentEncryptedMessage
      | SentEncryptedFile;
  }
  export type InputDocumentType = InputDocumentEmpty | InputDocument;
  export type DocumentType = DocumentEmpty | Document;
  export type NotifyPeerType =
    | NotifyPeer
    | NotifyUsers
    | NotifyChats
    | NotifyBroadcasts
    | NotifyForumTopic;
  export type SendMessageActionType =
    | SendMessageTypingAction
    | SendMessageCancelAction
    | SendMessageRecordVideoAction
    | SendMessageUploadVideoAction
    | SendMessageRecordAudioAction
    | SendMessageUploadAudioAction
    | SendMessageUploadPhotoAction
    | SendMessageUploadDocumentAction
    | SendMessageGeoLocationAction
    | SendMessageChooseContactAction
    | SendMessageGamePlayAction
    | SendMessageRecordRoundAction
    | SendMessageUploadRoundAction
    | SpeakingInGroupCallAction
    | SendMessageHistoryImportAction
    | SendMessageChooseStickerAction
    | SendMessageEmojiInteraction
    | SendMessageEmojiInteractionSeen;
  export type InputPrivacyKeyType =
    | InputPrivacyKeyStatusTimestamp
    | InputPrivacyKeyChatInvite
    | InputPrivacyKeyPhoneCall
    | InputPrivacyKeyPhoneP2P
    | InputPrivacyKeyForwards
    | InputPrivacyKeyProfilePhoto
    | InputPrivacyKeyPhoneNumber
    | InputPrivacyKeyAddedByPhone
    | InputPrivacyKeyVoiceMessages
    | InputPrivacyKeyAbout;
  export type PrivacyKeyType =
    | PrivacyKeyStatusTimestamp
    | PrivacyKeyChatInvite
    | PrivacyKeyPhoneCall
    | PrivacyKeyPhoneP2P
    | PrivacyKeyForwards
    | PrivacyKeyProfilePhoto
    | PrivacyKeyPhoneNumber
    | PrivacyKeyAddedByPhone
    | PrivacyKeyVoiceMessages
    | PrivacyKeyAbout;
  export type InputPrivacyRuleType =
    | InputPrivacyValueAllowContacts
    | InputPrivacyValueAllowAll
    | InputPrivacyValueAllowUsers
    | InputPrivacyValueDisallowContacts
    | InputPrivacyValueDisallowAll
    | InputPrivacyValueDisallowUsers
    | InputPrivacyValueAllowChatParticipants
    | InputPrivacyValueDisallowChatParticipants
    | InputPrivacyValueAllowCloseFriends;
  export type PrivacyRuleType =
    | PrivacyValueAllowContacts
    | PrivacyValueAllowAll
    | PrivacyValueAllowUsers
    | PrivacyValueDisallowContacts
    | PrivacyValueDisallowAll
    | PrivacyValueDisallowUsers
    | PrivacyValueAllowChatParticipants
    | PrivacyValueDisallowChatParticipants
    | PrivacyValueAllowCloseFriends;
  export type DocumentAttributeType =
    | DocumentAttributeImageSize
    | DocumentAttributeAnimated
    | DocumentAttributeSticker
    | DocumentAttributeVideo
    | DocumentAttributeAudio
    | DocumentAttributeFilename
    | DocumentAttributeHasStickers
    | DocumentAttributeCustomEmoji;
  export namespace messages {
    export type StickersType = StickersNotModified | Stickers;
  }
  export namespace messages {
    export type AllStickersType = AllStickersNotModified | AllStickers;
  }
  export type WebPageType =
    | WebPageEmpty
    | WebPagePending
    | WebPage
    | WebPageNotModified;
  export type ExportedChatInviteType =
    | ChatInviteExported
    | ChatInvitePublicJoinRequests;
  export type ChatInviteType = ChatInviteAlready | ChatInvite | ChatInvitePeek;
  export type InputStickerSetType =
    | InputStickerSetEmpty
    | InputStickerSetID
    | InputStickerSetShortName
    | InputStickerSetAnimatedEmoji
    | InputStickerSetDice
    | InputStickerSetAnimatedEmojiAnimations
    | InputStickerSetPremiumGifts
    | InputStickerSetEmojiGenericAnimations
    | InputStickerSetEmojiDefaultStatuses
    | InputStickerSetEmojiDefaultTopicIcons;
  export namespace messages {
    export type StickerSetType = StickerSet | StickerSetNotModified;
  }
  export type KeyboardButtonType =
    | KeyboardButton
    | KeyboardButtonUrl
    | KeyboardButtonCallback
    | KeyboardButtonRequestPhone
    | KeyboardButtonRequestGeoLocation
    | KeyboardButtonSwitchInline
    | KeyboardButtonGame
    | KeyboardButtonBuy
    | KeyboardButtonUrlAuth
    | InputKeyboardButtonUrlAuth
    | KeyboardButtonRequestPoll
    | InputKeyboardButtonUserProfile
    | KeyboardButtonUserProfile
    | KeyboardButtonWebView
    | KeyboardButtonSimpleWebView
    | KeyboardButtonRequestPeer;
  export type ReplyMarkupType =
    | ReplyKeyboardHide
    | ReplyKeyboardForceReply
    | ReplyKeyboardMarkup
    | ReplyInlineMarkup;
  export type MessageEntityType =
    | MessageEntityUnknown
    | MessageEntityMention
    | MessageEntityHashtag
    | MessageEntityBotCommand
    | MessageEntityUrl
    | MessageEntityEmail
    | MessageEntityBold
    | MessageEntityItalic
    | MessageEntityCode
    | MessageEntityPre
    | MessageEntityTextUrl
    | MessageEntityMentionName
    | InputMessageEntityMentionName
    | MessageEntityPhone
    | MessageEntityCashtag
    | MessageEntityUnderline
    | MessageEntityStrike
    | MessageEntityBlockquote
    | MessageEntityBankCard
    | MessageEntitySpoiler
    | MessageEntityCustomEmoji;
  export type InputChannelType =
    | InputChannelEmpty
    | InputChannel
    | InputChannelFromMessage;
  export namespace updates {
    export type ChannelDifferenceType =
      | ChannelDifferenceEmpty
      | ChannelDifferenceTooLong
      | ChannelDifference;
  }
  export type ChannelMessagesFilterType =
    | ChannelMessagesFilterEmpty
    | ChannelMessagesFilter;
  export type ChannelParticipantType =
    | ChannelParticipant
    | ChannelParticipantSelf
    | ChannelParticipantCreator
    | ChannelParticipantAdmin
    | ChannelParticipantBanned
    | ChannelParticipantLeft;
  export type ChannelParticipantsFilterType =
    | ChannelParticipantsRecent
    | ChannelParticipantsAdmins
    | ChannelParticipantsKicked
    | ChannelParticipantsBots
    | ChannelParticipantsBanned
    | ChannelParticipantsSearch
    | ChannelParticipantsContacts
    | ChannelParticipantsMentions;
  export namespace channels {
    export type ChannelParticipantsType =
      | ChannelParticipants
      | ChannelParticipantsNotModified;
  }
  export namespace messages {
    export type SavedGifsType = SavedGifsNotModified | SavedGifs;
  }
  export type InputBotInlineMessageType =
    | InputBotInlineMessageMediaAuto
    | InputBotInlineMessageText
    | InputBotInlineMessageMediaGeo
    | InputBotInlineMessageMediaVenue
    | InputBotInlineMessageMediaContact
    | InputBotInlineMessageGame
    | InputBotInlineMessageMediaInvoice;
  export type InputBotInlineResultType =
    | InputBotInlineResult
    | InputBotInlineResultPhoto
    | InputBotInlineResultDocument
    | InputBotInlineResultGame;
  export type BotInlineMessageType =
    | BotInlineMessageMediaAuto
    | BotInlineMessageText
    | BotInlineMessageMediaGeo
    | BotInlineMessageMediaVenue
    | BotInlineMessageMediaContact
    | BotInlineMessageMediaInvoice;
  export type BotInlineResultType = BotInlineResult | BotInlineMediaResult;
  export namespace auth {
    export type CodeTypeType =
      | CodeTypeSms
      | CodeTypeCall
      | CodeTypeFlashCall
      | CodeTypeMissedCall
      | CodeTypeFragmentSms;
  }
  export namespace auth {
    export type SentCodeTypeType =
      | SentCodeTypeApp
      | SentCodeTypeSms
      | SentCodeTypeCall
      | SentCodeTypeFlashCall
      | SentCodeTypeMissedCall
      | SentCodeTypeEmailCode
      | SentCodeTypeSetUpEmailRequired
      | SentCodeTypeFragmentSms
      | SentCodeTypeFirebaseSms;
  }
  export type InputBotInlineMessageIDType =
    | InputBotInlineMessageID
    | InputBotInlineMessageID64;
  export type TopPeerCategoryType =
    | TopPeerCategoryBotsPM
    | TopPeerCategoryBotsInline
    | TopPeerCategoryCorrespondents
    | TopPeerCategoryGroups
    | TopPeerCategoryChannels
    | TopPeerCategoryPhoneCalls
    | TopPeerCategoryForwardUsers
    | TopPeerCategoryForwardChats;
  export namespace contacts {
    export type TopPeersType =
      | TopPeersNotModified
      | TopPeers
      | TopPeersDisabled;
  }
  export type DraftMessageType = DraftMessageEmpty | DraftMessage;
  export namespace messages {
    export type FeaturedStickersType =
      | FeaturedStickersNotModified
      | FeaturedStickers;
  }
  export namespace messages {
    export type RecentStickersType = RecentStickersNotModified | RecentStickers;
  }
  export namespace messages {
    export type StickerSetInstallResultType =
      | StickerSetInstallResultSuccess
      | StickerSetInstallResultArchive;
  }
  export type StickerSetCoveredType =
    | StickerSetCovered
    | StickerSetMultiCovered
    | StickerSetFullCovered
    | StickerSetNoCovered;
  export type InputStickeredMediaType =
    | InputStickeredMediaPhoto
    | InputStickeredMediaDocument;
  export type InputGameType = InputGameID | InputGameShortName;
  export type RichTextType =
    | TextEmpty
    | TextPlain
    | TextBold
    | TextItalic
    | TextUnderline
    | TextStrike
    | TextFixed
    | TextUrl
    | TextEmail
    | TextConcat
    | TextSubscript
    | TextSuperscript
    | TextMarked
    | TextPhone
    | TextImage
    | TextAnchor;
  export type PageBlockType =
    | PageBlockUnsupported
    | PageBlockTitle
    | PageBlockSubtitle
    | PageBlockAuthorDate
    | PageBlockHeader
    | PageBlockSubheader
    | PageBlockParagraph
    | PageBlockPreformatted
    | PageBlockFooter
    | PageBlockDivider
    | PageBlockAnchor
    | PageBlockList
    | PageBlockBlockquote
    | PageBlockPullquote
    | PageBlockPhoto
    | PageBlockVideo
    | PageBlockCover
    | PageBlockEmbed
    | PageBlockEmbedPost
    | PageBlockCollage
    | PageBlockSlideshow
    | PageBlockChannel
    | PageBlockAudio
    | PageBlockKicker
    | PageBlockTable
    | PageBlockOrderedList
    | PageBlockDetails
    | PageBlockRelatedArticles
    | PageBlockMap;
  export type PhoneCallDiscardReasonType =
    | PhoneCallDiscardReasonMissed
    | PhoneCallDiscardReasonDisconnect
    | PhoneCallDiscardReasonHangup
    | PhoneCallDiscardReasonBusy;
  export type PaymentSavedCredentialsType = PaymentSavedCredentialsCard;
  export type WebDocumentType = WebDocument | WebDocumentNoProxy;
  export type InputWebFileLocationType =
    | InputWebFileLocation
    | InputWebFileGeoPointLocation
    | InputWebFileAudioAlbumThumbLocation;
  export namespace payments {
    export type PaymentResultType = PaymentResult | PaymentVerificationNeeded;
  }
  export type InputPaymentCredentialsType =
    | InputPaymentCredentialsSaved
    | InputPaymentCredentials
    | InputPaymentCredentialsApplePay
    | InputPaymentCredentialsGooglePay;
  export type PhoneCallType =
    | PhoneCallEmpty
    | PhoneCallWaiting
    | PhoneCallRequested
    | PhoneCallAccepted
    | PhoneCall
    | PhoneCallDiscarded;
  export type PhoneConnectionType = PhoneConnection | PhoneConnectionWebrtc;
  export namespace upload {
    export type CdnFileType = CdnFileReuploadNeeded | CdnFile;
  }
  export type LangPackStringType =
    | LangPackString
    | LangPackStringPluralized
    | LangPackStringDeleted;
  export type ChannelAdminLogEventActionType =
    | ChannelAdminLogEventActionChangeTitle
    | ChannelAdminLogEventActionChangeAbout
    | ChannelAdminLogEventActionChangeUsername
    | ChannelAdminLogEventActionChangePhoto
    | ChannelAdminLogEventActionToggleInvites
    | ChannelAdminLogEventActionToggleSignatures
    | ChannelAdminLogEventActionUpdatePinned
    | ChannelAdminLogEventActionEditMessage
    | ChannelAdminLogEventActionDeleteMessage
    | ChannelAdminLogEventActionParticipantJoin
    | ChannelAdminLogEventActionParticipantLeave
    | ChannelAdminLogEventActionParticipantInvite
    | ChannelAdminLogEventActionParticipantToggleBan
    | ChannelAdminLogEventActionParticipantToggleAdmin
    | ChannelAdminLogEventActionChangeStickerSet
    | ChannelAdminLogEventActionTogglePreHistoryHidden
    | ChannelAdminLogEventActionDefaultBannedRights
    | ChannelAdminLogEventActionStopPoll
    | ChannelAdminLogEventActionChangeLinkedChat
    | ChannelAdminLogEventActionChangeLocation
    | ChannelAdminLogEventActionToggleSlowMode
    | ChannelAdminLogEventActionStartGroupCall
    | ChannelAdminLogEventActionDiscardGroupCall
    | ChannelAdminLogEventActionParticipantMute
    | ChannelAdminLogEventActionParticipantUnmute
    | ChannelAdminLogEventActionToggleGroupCallSetting
    | ChannelAdminLogEventActionParticipantJoinByInvite
    | ChannelAdminLogEventActionExportedInviteDelete
    | ChannelAdminLogEventActionExportedInviteRevoke
    | ChannelAdminLogEventActionExportedInviteEdit
    | ChannelAdminLogEventActionParticipantVolume
    | ChannelAdminLogEventActionChangeHistoryTTL
    | ChannelAdminLogEventActionParticipantJoinByRequest
    | ChannelAdminLogEventActionToggleNoForwards
    | ChannelAdminLogEventActionSendMessage
    | ChannelAdminLogEventActionChangeAvailableReactions
    | ChannelAdminLogEventActionChangeUsernames
    | ChannelAdminLogEventActionToggleForum
    | ChannelAdminLogEventActionCreateTopic
    | ChannelAdminLogEventActionEditTopic
    | ChannelAdminLogEventActionDeleteTopic
    | ChannelAdminLogEventActionPinTopic
    | ChannelAdminLogEventActionToggleAntiSpam;
  export namespace messages {
    export type FavedStickersType = FavedStickersNotModified | FavedStickers;
  }
  export type RecentMeUrlType =
    | RecentMeUrlUnknown
    | RecentMeUrlUser
    | RecentMeUrlChat
    | RecentMeUrlChatInvite
    | RecentMeUrlStickerSet;
  export type InputMessageType =
    | InputMessageID
    | InputMessageReplyTo
    | InputMessagePinned
    | InputMessageCallbackQuery;
  export type InputDialogPeerType = InputDialogPeer | InputDialogPeerFolder;
  export type DialogPeerType = DialogPeer | DialogPeerFolder;
  export namespace messages {
    export type FoundStickerSetsType =
      | FoundStickerSetsNotModified
      | FoundStickerSets;
  }
  export namespace help {
    export type TermsOfServiceUpdateType =
      | TermsOfServiceUpdateEmpty
      | TermsOfServiceUpdate;
  }
  export type InputSecureFileType = InputSecureFileUploaded | InputSecureFile;
  export type SecureFileType = SecureFileEmpty | SecureFile;
  export type SecurePlainDataType = SecurePlainPhone | SecurePlainEmail;
  export type SecureValueTypeType =
    | SecureValueTypePersonalDetails
    | SecureValueTypePassport
    | SecureValueTypeDriverLicense
    | SecureValueTypeIdentityCard
    | SecureValueTypeInternalPassport
    | SecureValueTypeAddress
    | SecureValueTypeUtilityBill
    | SecureValueTypeBankStatement
    | SecureValueTypeRentalAgreement
    | SecureValueTypePassportRegistration
    | SecureValueTypeTemporaryRegistration
    | SecureValueTypePhone
    | SecureValueTypeEmail;
  export type SecureValueErrorType =
    | SecureValueErrorData
    | SecureValueErrorFrontSide
    | SecureValueErrorReverseSide
    | SecureValueErrorSelfie
    | SecureValueErrorFile
    | SecureValueErrorFiles
    | SecureValueError
    | SecureValueErrorTranslationFile
    | SecureValueErrorTranslationFiles;
  export namespace help {
    export type DeepLinkInfoType = DeepLinkInfoEmpty | DeepLinkInfo;
  }
  export type SavedContactType = SavedPhoneContact;
  export type PasswordKdfAlgoType =
    | PasswordKdfAlgoUnknown
    | PasswordKdfAlgoSHA256SHA256PBKDF2HMACSHA512iter100000SHA256ModPow;
  export type SecurePasswordKdfAlgoType =
    | SecurePasswordKdfAlgoUnknown
    | SecurePasswordKdfAlgoPBKDF2HMACSHA512iter100000
    | SecurePasswordKdfAlgoSHA512;
  export type InputCheckPasswordSRPType =
    | InputCheckPasswordEmpty
    | InputCheckPasswordSRP;
  export type SecureRequiredTypeType =
    | SecureRequiredType
    | SecureRequiredTypeOneOf;
  export namespace help {
    export type PassportConfigType = PassportConfigNotModified | PassportConfig;
  }
  export type JSONObjectValueType = JsonObjectValue;
  export type JSONValueType =
    | JsonNull
    | JsonBool
    | JsonNumber
    | JsonString
    | JsonArray
    | JsonObject;
  export type PageListItemType = PageListItemText | PageListItemBlocks;
  export type PageListOrderedItemType =
    | PageListOrderedItemText
    | PageListOrderedItemBlocks;
  export namespace help {
    export type UserInfoType = UserInfoEmpty | UserInfo;
  }
  export type InputWallPaperType =
    | InputWallPaper
    | InputWallPaperSlug
    | InputWallPaperNoFile;
  export namespace account {
    export type WallPapersType = WallPapersNotModified | WallPapers;
  }
  export type EmojiKeywordType = EmojiKeyword | EmojiKeywordDeleted;
  export type UrlAuthResultType =
    | UrlAuthResultRequest
    | UrlAuthResultAccepted
    | UrlAuthResultDefault;
  export type ChannelLocationType = ChannelLocationEmpty | ChannelLocation;
  export type PeerLocatedType = PeerLocated | PeerSelfLocated;
  export type InputThemeType = InputTheme | InputThemeSlug;
  export namespace account {
    export type ThemesType = ThemesNotModified | Themes;
  }
  export namespace auth {
    export type LoginTokenType =
      | LoginToken
      | LoginTokenMigrateTo
      | LoginTokenSuccess;
  }
  export type BaseThemeType =
    | BaseThemeClassic
    | BaseThemeDay
    | BaseThemeNight
    | BaseThemeTinted
    | BaseThemeArctic;
  export type WebPageAttributeType =
    | WebPageAttributeTheme
    | WebPageAttributeStory;
  export type DialogFilterType =
    | DialogFilter
    | DialogFilterDefault
    | DialogFilterChatlist;
  export type StatsGraphType = StatsGraphAsync | StatsGraphError | StatsGraph;
  export namespace help {
    export type PromoDataType = PromoDataEmpty | PromoData;
  }
  export type VideoSizeType =
    | VideoSize
    | VideoSizeEmojiMarkup
    | VideoSizeStickerMarkup;
  export namespace help {
    export type CountriesListType = CountriesListNotModified | CountriesList;
  }
  export type MessageReplyHeaderType =
    | MessageReplyHeader
    | MessageReplyStoryHeader;
  export type GroupCallType = GroupCallDiscarded | GroupCall;
  export type InlineQueryPeerTypeType =
    | InlineQueryPeerTypeSameBotPM
    | InlineQueryPeerTypePM
    | InlineQueryPeerTypeChat
    | InlineQueryPeerTypeMegagroup
    | InlineQueryPeerTypeBroadcast
    | InlineQueryPeerTypeBotPM;
  export namespace messages {
    export type ExportedChatInviteType =
      | ExportedChatInvite
      | ExportedChatInviteReplaced;
  }
  export type BotCommandScopeType =
    | BotCommandScopeDefault
    | BotCommandScopeUsers
    | BotCommandScopeChats
    | BotCommandScopeChatAdmins
    | BotCommandScopePeer
    | BotCommandScopePeerAdmins
    | BotCommandScopePeerUser;
  export namespace account {
    export type ResetPasswordResultType =
      | ResetPasswordFailedWait
      | ResetPasswordRequestedWait
      | ResetPasswordOk;
  }
  export namespace messages {
    export type SponsoredMessagesType =
      | SponsoredMessages
      | SponsoredMessagesEmpty;
  }
  export type SearchResultsPositionType = SearchResultPosition;
  export namespace messages {
    export type AvailableReactionsType =
      | AvailableReactionsNotModified
      | AvailableReactions;
  }
  export type AttachMenuBotsType = AttachMenuBotsNotModified | AttachMenuBots;
  export type WebViewResultType = WebViewResultUrl;
  export type SimpleWebViewResultType = SimpleWebViewResultUrl;
  export type BotMenuButtonType =
    | BotMenuButtonDefault
    | BotMenuButtonCommands
    | BotMenuButton;
  export namespace account {
    export type SavedRingtonesType = SavedRingtonesNotModified | SavedRingtones;
  }
  export type NotificationSoundType =
    | NotificationSoundDefault
    | NotificationSoundNone
    | NotificationSoundLocal
    | NotificationSoundRingtone;
  export namespace account {
    export type SavedRingtoneType = SavedRingtone | SavedRingtoneConverted;
  }
  export type AttachMenuPeerTypeType =
    | AttachMenuPeerTypeSameBotPM
    | AttachMenuPeerTypeBotPM
    | AttachMenuPeerTypePM
    | AttachMenuPeerTypeChat
    | AttachMenuPeerTypeBroadcast;
  export type InputInvoiceType = InputInvoiceMessage | InputInvoiceSlug;
  export type InputStorePaymentPurposeType =
    | InputStorePaymentPremiumSubscription
    | InputStorePaymentGiftPremium;
  export type EmojiStatusType =
    | EmojiStatusEmpty
    | EmojiStatus
    | EmojiStatusUntil;
  export namespace account {
    export type EmojiStatusesType = EmojiStatusesNotModified | EmojiStatuses;
  }
  export type ReactionType =
    | ReactionEmpty
    | ReactionEmoji
    | ReactionCustomEmoji;
  export type ChatReactionsType =
    | ChatReactionsNone
    | ChatReactionsAll
    | ChatReactionsSome;
  export namespace messages {
    export type ReactionsType = ReactionsNotModified | Reactions;
  }
  export type EmailVerifyPurposeType =
    | EmailVerifyPurposeLoginSetup
    | EmailVerifyPurposeLoginChange
    | EmailVerifyPurposePassport;
  export type EmailVerificationType =
    | EmailVerificationCode
    | EmailVerificationGoogle
    | EmailVerificationApple;
  export namespace account {
    export type EmailVerifiedType = EmailVerified | EmailVerifiedLogin;
  }
  export type MessageExtendedMediaType =
    | MessageExtendedMediaPreview
    | MessageExtendedMedia;
  export type ForumTopicType = ForumTopicDeleted | ForumTopic;
  export type RequestPeerTypeType =
    | RequestPeerTypeUser
    | RequestPeerTypeChat
    | RequestPeerTypeBroadcast;
  export type EmojiListType = EmojiListNotModified | EmojiList;
  export namespace messages {
    export type EmojiGroupsType = EmojiGroupsNotModified | EmojiGroups;
  }
  export namespace messages {
    export type TranslatedTextType = TranslateResult;
  }
  export namespace help {
    export type AppConfigType = AppConfigNotModified | AppConfig;
  }
  export type InputBotAppType = InputBotAppID | InputBotAppShortName;
  export type BotAppType = BotAppNotModified | BotApp;
  export type AppWebViewResultType = AppWebViewResultUrl;
  export type InputChatlistType = InputChatlistDialogFilter;
  export namespace chatlists {
    export type ChatlistInviteType = ChatlistInviteAlready | ChatlistInvite;
  }
  export type MessagePeerVoteType =
    | MessagePeerVote
    | MessagePeerVoteInputOption
    | MessagePeerVoteMultiple;
  export type StoryItemType = StoryItemDeleted | StoryItemSkipped | StoryItem;
  export namespace stories {
    export type AllStoriesType = AllStoriesNotModified | AllStories;
  }
  export type InputReplyToType = InputReplyToMessage | InputReplyToStory;
  export type MediaAreaType =
    | MediaAreaVenue
    | InputMediaAreaVenue
    | MediaAreaGeoPoint
    | MediaAreaSuggestedReaction;
  export namespace stories {
    export type CanApplyBoostResultType =
      | CanApplyBoostOk
      | CanApplyBoostReplace;
  }

  export class BoolFalse implements TLObject {
    static readonly cons = new TLInt(0xbc799737);

    static deserialized(_data: ByteStream): BoolFalse | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BoolFalse.cons)) return undefined;

      return new BoolFalse();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BoolFalse.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BoolTrue implements TLObject {
    static readonly cons = new TLInt(0x997275b5);

    static deserialized(_data: ByteStream): BoolTrue | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BoolTrue.cons)) return undefined;

      return new BoolTrue();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BoolTrue.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class True implements TLObject {
    static readonly cons = new TLInt(0x3fedd339);

    static deserialized(_data: ByteStream): True | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(True.cons)) return undefined;

      return new True();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [True.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class Error implements TLObject {
    static readonly cons = new TLInt(0xc4b9f9bb);

    static deserialized(_data: ByteStream): Error | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Error.cons)) return undefined;

      const code = TLInt.deserialized(_data);
      if (!code) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new Error(code, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Error.cons];

      _data.push(this.code);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly code: TLInt, readonly text: TLString) {}
  }

  export class Null implements TLObject {
    static readonly cons = new TLInt(0x56730bcc);

    static deserialized(_data: ByteStream): Null | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Null.cons)) return undefined;

      return new Null();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Null.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPeerEmpty implements TLObject {
    static readonly cons = new TLInt(0x7f3b18ea);

    static deserialized(_data: ByteStream): InputPeerEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPeerEmpty.cons))
        return undefined;

      return new InputPeerEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPeerEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPeerSelf implements TLObject {
    static readonly cons = new TLInt(0x7da07ec9);

    static deserialized(_data: ByteStream): InputPeerSelf | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPeerSelf.cons))
        return undefined;

      return new InputPeerSelf();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPeerSelf.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPeerChat implements TLObject {
    static readonly cons = new TLInt(0x35a95cb9);

    static deserialized(_data: ByteStream): InputPeerChat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPeerChat.cons))
        return undefined;

      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;

      return new InputPeerChat(chatId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPeerChat.cons];

      _data.push(this.chatId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chatId: TLLong) {}
  }

  export class InputPeerUser implements TLObject {
    static readonly cons = new TLInt(0xdde8a54c);

    static deserialized(_data: ByteStream): InputPeerUser | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPeerUser.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputPeerUser(userId, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPeerUser.cons];

      _data.push(this.userId);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputPeerChannel implements TLObject {
    static readonly cons = new TLInt(0x27bcbbfc);

    static deserialized(_data: ByteStream): InputPeerChannel | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPeerChannel.cons))
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputPeerChannel(channelId, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPeerChannel.cons];

      _data.push(this.channelId);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly channelId: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputPeerUserFromMessage implements TLObject {
    static readonly cons = new TLInt(0xa87b0a1c);

    static deserialized(
      _data: ByteStream
    ): InputPeerUserFromMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPeerUserFromMessage.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;
      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;

      return new InputPeerUserFromMessage(peer, msgId, userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPeerUserFromMessage.cons];

      _data.push(this.peer);
      _data.push(this.msgId);
      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: InputPeerType,
      readonly msgId: TLInt,
      readonly userId: TLLong
    ) {}
  }

  export class InputPeerChannelFromMessage implements TLObject {
    static readonly cons = new TLInt(0xbd2a0840);

    static deserialized(
      _data: ByteStream
    ): InputPeerChannelFromMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPeerChannelFromMessage.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;
      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;
      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;

      return new InputPeerChannelFromMessage(peer, msgId, channelId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPeerChannelFromMessage.cons];

      _data.push(this.peer);
      _data.push(this.msgId);
      _data.push(this.channelId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: InputPeerType,
      readonly msgId: TLInt,
      readonly channelId: TLLong
    ) {}
  }

  export class InputUserEmpty implements TLObject {
    static readonly cons = new TLInt(0xb98886cf);

    static deserialized(_data: ByteStream): InputUserEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputUserEmpty.cons))
        return undefined;

      return new InputUserEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputUserEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputUserSelf implements TLObject {
    static readonly cons = new TLInt(0xf7c1b13f);

    static deserialized(_data: ByteStream): InputUserSelf | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputUserSelf.cons))
        return undefined;

      return new InputUserSelf();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputUserSelf.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputUser implements TLObject {
    static readonly cons = new TLInt(0xf21158c6);

    static deserialized(_data: ByteStream): InputUser | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputUser.cons)) return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputUser(userId, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputUser.cons];

      _data.push(this.userId);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputUserFromMessage implements TLObject {
    static readonly cons = new TLInt(0x1da448e2);

    static deserialized(_data: ByteStream): InputUserFromMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputUserFromMessage.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;
      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;

      return new InputUserFromMessage(peer, msgId, userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputUserFromMessage.cons];

      _data.push(this.peer);
      _data.push(this.msgId);
      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: InputPeerType,
      readonly msgId: TLInt,
      readonly userId: TLLong
    ) {}
  }

  export class InputPhoneContact implements TLObject {
    static readonly cons = new TLInt(0xf392b7f4);

    static deserialized(_data: ByteStream): InputPhoneContact | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPhoneContact.cons))
        return undefined;

      const clientId = TLLong.deserialized(_data);
      if (!clientId) return undefined;
      const phone = TLString.deserialized(_data);
      if (!phone) return undefined;
      const firstName = TLString.deserialized(_data);
      if (!firstName) return undefined;
      const lastName = TLString.deserialized(_data);
      if (!lastName) return undefined;

      return new InputPhoneContact(clientId, phone, firstName, lastName);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPhoneContact.cons];

      _data.push(this.clientId);
      _data.push(this.phone);
      _data.push(this.firstName);
      _data.push(this.lastName);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly clientId: TLLong,
      readonly phone: TLString,
      readonly firstName: TLString,
      readonly lastName: TLString
    ) {}
  }

  export class InputFile implements TLObject {
    static readonly cons = new TLInt(0xf52ff27f);

    static deserialized(_data: ByteStream): InputFile | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputFile.cons)) return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const parts = TLInt.deserialized(_data);
      if (!parts) return undefined;
      const name = TLString.deserialized(_data);
      if (!name) return undefined;
      const md5Checksum = TLString.deserialized(_data);
      if (!md5Checksum) return undefined;

      return new InputFile(id, parts, name, md5Checksum);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputFile.cons];

      _data.push(this.id);
      _data.push(this.parts);
      _data.push(this.name);
      _data.push(this.md5Checksum);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly parts: TLInt,
      readonly name: TLString,
      readonly md5Checksum: TLString
    ) {}
  }

  export class InputFileBig implements TLObject {
    static readonly cons = new TLInt(0xfa4f0bb5);

    static deserialized(_data: ByteStream): InputFileBig | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputFileBig.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const parts = TLInt.deserialized(_data);
      if (!parts) return undefined;
      const name = TLString.deserialized(_data);
      if (!name) return undefined;

      return new InputFileBig(id, parts, name);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputFileBig.cons];

      _data.push(this.id);
      _data.push(this.parts);
      _data.push(this.name);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly parts: TLInt,
      readonly name: TLString
    ) {}
  }

  export class InputMediaEmpty implements TLObject {
    static readonly cons = new TLInt(0x9664f57f);

    static deserialized(_data: ByteStream): InputMediaEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaEmpty.cons))
        return undefined;

      return new InputMediaEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMediaUploadedPhoto implements TLObject {
    static readonly cons = new TLInt(0x1e287d04);

    static deserialized(
      _data: ByteStream
    ): InputMediaUploadedPhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaUploadedPhoto.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const spoiler = (flags.value & 4) !== 0;
      const file = TLObject.deserialized(_data) as InputFileType;
      if (!file) return undefined;
      let stickers: TLVector<InputDocumentType> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<InputDocumentType>;
        if (!obj) return undefined;
        stickers = obj;
      }
      let ttlSeconds: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlSeconds = obj;
      }

      return new InputMediaUploadedPhoto(spoiler, file, stickers, ttlSeconds);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaUploadedPhoto.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.spoiler) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.file);
      if (this.stickers) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.stickers) _data.push(this.stickers);

      if (this.ttlSeconds) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.ttlSeconds) _data.push(this.ttlSeconds);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly spoiler: boolean | undefined,
      readonly file: InputFileType,
      readonly stickers: TLVector<InputDocumentType> | undefined,
      readonly ttlSeconds: TLInt | undefined
    ) {}
  }

  export class InputMediaPhoto implements TLObject {
    static readonly cons = new TLInt(0xb3ba0635);

    static deserialized(_data: ByteStream): InputMediaPhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaPhoto.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const spoiler = (flags.value & 2) !== 0;
      const id = TLObject.deserialized(_data) as InputPhotoType;
      if (!id) return undefined;
      let ttlSeconds: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlSeconds = obj;
      }

      return new InputMediaPhoto(spoiler, id, ttlSeconds);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaPhoto.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.spoiler) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.id);
      if (this.ttlSeconds) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.ttlSeconds) _data.push(this.ttlSeconds);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly spoiler: boolean | undefined,
      readonly id: InputPhotoType,
      readonly ttlSeconds: TLInt | undefined
    ) {}
  }

  export class InputMediaGeoPoint implements TLObject {
    static readonly cons = new TLInt(0xf9c44144);

    static deserialized(_data: ByteStream): InputMediaGeoPoint | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaGeoPoint.cons))
        return undefined;

      const geoPoint = TLObject.deserialized(_data) as InputGeoPointType;
      if (!geoPoint) return undefined;

      return new InputMediaGeoPoint(geoPoint);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaGeoPoint.cons];

      _data.push(this.geoPoint);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly geoPoint: InputGeoPointType) {}
  }

  export class InputMediaContact implements TLObject {
    static readonly cons = new TLInt(0xf8ab7dfb);

    static deserialized(_data: ByteStream): InputMediaContact | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaContact.cons))
        return undefined;

      const phoneNumber = TLString.deserialized(_data);
      if (!phoneNumber) return undefined;
      const firstName = TLString.deserialized(_data);
      if (!firstName) return undefined;
      const lastName = TLString.deserialized(_data);
      if (!lastName) return undefined;
      const vcard = TLString.deserialized(_data);
      if (!vcard) return undefined;

      return new InputMediaContact(phoneNumber, firstName, lastName, vcard);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaContact.cons];

      _data.push(this.phoneNumber);
      _data.push(this.firstName);
      _data.push(this.lastName);
      _data.push(this.vcard);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly phoneNumber: TLString,
      readonly firstName: TLString,
      readonly lastName: TLString,
      readonly vcard: TLString
    ) {}
  }

  export class InputMediaUploadedDocument implements TLObject {
    static readonly cons = new TLInt(0x5b38c6c1);

    static deserialized(
      _data: ByteStream
    ): InputMediaUploadedDocument | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaUploadedDocument.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const nosoundVideo = (flags.value & 8) !== 0;
      const forceFile = (flags.value & 16) !== 0;
      const spoiler = (flags.value & 32) !== 0;
      const file = TLObject.deserialized(_data) as InputFileType;
      if (!file) return undefined;
      let thumb: InputFileType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as InputFileType;
        if (!obj) return undefined;
        thumb = obj;
      }
      const mimeType = TLString.deserialized(_data);
      if (!mimeType) return undefined;
      const attributes = TLVector.deserialized(
        _data
      ) as TLVector<DocumentAttributeType>;
      if (!attributes) return undefined;
      let stickers: TLVector<InputDocumentType> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<InputDocumentType>;
        if (!obj) return undefined;
        stickers = obj;
      }
      let ttlSeconds: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlSeconds = obj;
      }

      return new InputMediaUploadedDocument(
        nosoundVideo,
        forceFile,
        spoiler,
        file,
        thumb,
        mimeType,
        attributes,
        stickers,
        ttlSeconds
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaUploadedDocument.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.nosoundVideo) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.forceFile) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.spoiler) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      _data.push(this.file);
      if (this.thumb) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.thumb) _data.push(this.thumb);

      _data.push(this.mimeType);
      _data.push(this.attributes);
      if (this.stickers) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.stickers) _data.push(this.stickers);

      if (this.ttlSeconds) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.ttlSeconds) _data.push(this.ttlSeconds);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly nosoundVideo: boolean | undefined,
      readonly forceFile: boolean | undefined,
      readonly spoiler: boolean | undefined,
      readonly file: InputFileType,
      readonly thumb: InputFileType | undefined,
      readonly mimeType: TLString,
      readonly attributes: TLVector<DocumentAttributeType>,
      readonly stickers: TLVector<InputDocumentType> | undefined,
      readonly ttlSeconds: TLInt | undefined
    ) {}
  }

  export class InputMediaDocument implements TLObject {
    static readonly cons = new TLInt(0x33473058);

    static deserialized(_data: ByteStream): InputMediaDocument | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaDocument.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const spoiler = (flags.value & 4) !== 0;
      const id = TLObject.deserialized(_data) as InputDocumentType;
      if (!id) return undefined;
      let ttlSeconds: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlSeconds = obj;
      }
      let query: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        query = obj;
      }

      return new InputMediaDocument(spoiler, id, ttlSeconds, query);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaDocument.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.spoiler) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.id);
      if (this.ttlSeconds) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.ttlSeconds) _data.push(this.ttlSeconds);

      if (this.query) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.query) _data.push(this.query);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly spoiler: boolean | undefined,
      readonly id: InputDocumentType,
      readonly ttlSeconds: TLInt | undefined,
      readonly query: TLString | undefined
    ) {}
  }

  export class InputMediaVenue implements TLObject {
    static readonly cons = new TLInt(0xc13d1c11);

    static deserialized(_data: ByteStream): InputMediaVenue | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaVenue.cons))
        return undefined;

      const geoPoint = TLObject.deserialized(_data) as InputGeoPointType;
      if (!geoPoint) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const address = TLString.deserialized(_data);
      if (!address) return undefined;
      const provider = TLString.deserialized(_data);
      if (!provider) return undefined;
      const venueId = TLString.deserialized(_data);
      if (!venueId) return undefined;
      const venueType = TLString.deserialized(_data);
      if (!venueType) return undefined;

      return new InputMediaVenue(
        geoPoint,
        title,
        address,
        provider,
        venueId,
        venueType
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaVenue.cons];

      _data.push(this.geoPoint);
      _data.push(this.title);
      _data.push(this.address);
      _data.push(this.provider);
      _data.push(this.venueId);
      _data.push(this.venueType);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly geoPoint: InputGeoPointType,
      readonly title: TLString,
      readonly address: TLString,
      readonly provider: TLString,
      readonly venueId: TLString,
      readonly venueType: TLString
    ) {}
  }

  export class InputMediaPhotoExternal implements TLObject {
    static readonly cons = new TLInt(0xe5bbfe1a);

    static deserialized(
      _data: ByteStream
    ): InputMediaPhotoExternal | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaPhotoExternal.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const spoiler = (flags.value & 2) !== 0;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      let ttlSeconds: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlSeconds = obj;
      }

      return new InputMediaPhotoExternal(spoiler, url, ttlSeconds);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaPhotoExternal.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.spoiler) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.url);
      if (this.ttlSeconds) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.ttlSeconds) _data.push(this.ttlSeconds);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly spoiler: boolean | undefined,
      readonly url: TLString,
      readonly ttlSeconds: TLInt | undefined
    ) {}
  }

  export class InputMediaDocumentExternal implements TLObject {
    static readonly cons = new TLInt(0xfb52dc99);

    static deserialized(
      _data: ByteStream
    ): InputMediaDocumentExternal | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaDocumentExternal.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const spoiler = (flags.value & 2) !== 0;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      let ttlSeconds: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlSeconds = obj;
      }

      return new InputMediaDocumentExternal(spoiler, url, ttlSeconds);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaDocumentExternal.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.spoiler) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.url);
      if (this.ttlSeconds) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.ttlSeconds) _data.push(this.ttlSeconds);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly spoiler: boolean | undefined,
      readonly url: TLString,
      readonly ttlSeconds: TLInt | undefined
    ) {}
  }

  export class InputMediaGame implements TLObject {
    static readonly cons = new TLInt(0xd33f43f3);

    static deserialized(_data: ByteStream): InputMediaGame | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaGame.cons))
        return undefined;

      const id = TLObject.deserialized(_data) as InputGameType;
      if (!id) return undefined;

      return new InputMediaGame(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaGame.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: InputGameType) {}
  }

  export class InputMediaInvoice implements TLObject {
    static readonly cons = new TLInt(0x8eb5a6d5);

    static deserialized(_data: ByteStream): InputMediaInvoice | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaInvoice.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const description = TLString.deserialized(_data);
      if (!description) return undefined;
      let photo: API.InputWebDocument | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as API.InputWebDocument;
        if (!obj) return undefined;
        photo = obj;
      }
      const invoice = API.Invoice.deserialized(_data);
      if (!invoice) return undefined;
      const payload = TLBytes.deserialized(_data);
      if (!payload) return undefined;
      const provider = TLString.deserialized(_data);
      if (!provider) return undefined;
      const providerData = API.DataJSON.deserialized(_data);
      if (!providerData) return undefined;
      let startParam: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        startParam = obj;
      }
      let extendedMedia: InputMediaType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as InputMediaType;
        if (!obj) return undefined;
        extendedMedia = obj;
      }

      return new InputMediaInvoice(
        title,
        description,
        photo,
        invoice,
        payload,
        provider,
        providerData,
        startParam,
        extendedMedia
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaInvoice.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.title);
      _data.push(this.description);
      if (this.photo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.photo) _data.push(this.photo);

      _data.push(this.invoice);
      _data.push(this.payload);
      _data.push(this.provider);
      _data.push(this.providerData);
      if (this.startParam) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.startParam) _data.push(this.startParam);

      if (this.extendedMedia) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.extendedMedia) _data.push(this.extendedMedia);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly title: TLString,
      readonly description: TLString,
      readonly photo: API.InputWebDocument | undefined,
      readonly invoice: API.Invoice,
      readonly payload: TLBytes,
      readonly provider: TLString,
      readonly providerData: API.DataJSON,
      readonly startParam: TLString | undefined,
      readonly extendedMedia: InputMediaType | undefined
    ) {}
  }

  export class InputMediaGeoLive implements TLObject {
    static readonly cons = new TLInt(0x971fa843);

    static deserialized(_data: ByteStream): InputMediaGeoLive | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaGeoLive.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const stopped = (flags.value & 1) !== 0;
      const geoPoint = TLObject.deserialized(_data) as InputGeoPointType;
      if (!geoPoint) return undefined;
      let heading: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        heading = obj;
      }
      let period: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        period = obj;
      }
      let proximityNotificationRadius: TLInt | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        proximityNotificationRadius = obj;
      }

      return new InputMediaGeoLive(
        stopped,
        geoPoint,
        heading,
        period,
        proximityNotificationRadius
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaGeoLive.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.stopped) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.geoPoint);
      if (this.heading) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.heading) _data.push(this.heading);

      if (this.period) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.period) _data.push(this.period);

      if (this.proximityNotificationRadius) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.proximityNotificationRadius)
        _data.push(this.proximityNotificationRadius);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly stopped: boolean | undefined,
      readonly geoPoint: InputGeoPointType,
      readonly heading: TLInt | undefined,
      readonly period: TLInt | undefined,
      readonly proximityNotificationRadius: TLInt | undefined
    ) {}
  }

  export class InputMediaPoll implements TLObject {
    static readonly cons = new TLInt(0xf94e5f1);

    static deserialized(_data: ByteStream): InputMediaPoll | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaPoll.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const poll = API.Poll.deserialized(_data);
      if (!poll) return undefined;
      let correctAnswers: TLVector<TLBytes> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data, TLBytes);
        if (!obj) return undefined;
        correctAnswers = obj;
      }
      let solution: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        solution = obj;
      }
      let solutionEntities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        solutionEntities = obj;
      }

      return new InputMediaPoll(
        poll,
        correctAnswers,
        solution,
        solutionEntities
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaPoll.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.poll);
      if (this.correctAnswers) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.correctAnswers) _data.push(this.correctAnswers);

      if (this.solution) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.solution) _data.push(this.solution);

      if (this.solutionEntities) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.solutionEntities) _data.push(this.solutionEntities);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly poll: API.Poll,
      readonly correctAnswers: TLVector<TLBytes> | undefined,
      readonly solution: TLString | undefined,
      readonly solutionEntities: TLVector<MessageEntityType> | undefined
    ) {}
  }

  export class InputMediaDice implements TLObject {
    static readonly cons = new TLInt(0xe66fbf7b);

    static deserialized(_data: ByteStream): InputMediaDice | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaDice.cons))
        return undefined;

      const emoticon = TLString.deserialized(_data);
      if (!emoticon) return undefined;

      return new InputMediaDice(emoticon);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaDice.cons];

      _data.push(this.emoticon);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly emoticon: TLString) {}
  }

  export class InputMediaStory implements TLObject {
    static readonly cons = new TLInt(0x89fdd778);

    static deserialized(_data: ByteStream): InputMediaStory | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaStory.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;

      return new InputMediaStory(peer, id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaStory.cons];

      _data.push(this.peer);
      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: InputPeerType, readonly id: TLInt) {}
  }

  export class InputChatPhotoEmpty implements TLObject {
    static readonly cons = new TLInt(0x1ca48f57);

    static deserialized(_data: ByteStream): InputChatPhotoEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputChatPhotoEmpty.cons))
        return undefined;

      return new InputChatPhotoEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputChatPhotoEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputChatUploadedPhoto implements TLObject {
    static readonly cons = new TLInt(0xbdcdaec0);

    static deserialized(_data: ByteStream): InputChatUploadedPhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputChatUploadedPhoto.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let file: InputFileType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as InputFileType;
        if (!obj) return undefined;
        file = obj;
      }
      let video: InputFileType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as InputFileType;
        if (!obj) return undefined;
        video = obj;
      }
      let videoStartTs: TLDouble | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLDouble.deserialized(_data);
        if (!obj) return undefined;
        videoStartTs = obj;
      }
      let videoEmojiMarkup: VideoSizeType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as VideoSizeType;
        if (!obj) return undefined;
        videoEmojiMarkup = obj;
      }

      return new InputChatUploadedPhoto(
        file,
        video,
        videoStartTs,
        videoEmojiMarkup
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputChatUploadedPhoto.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.file) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.file) _data.push(this.file);

      if (this.video) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.video) _data.push(this.video);

      if (this.videoStartTs) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.videoStartTs) _data.push(this.videoStartTs);

      if (this.videoEmojiMarkup) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.videoEmojiMarkup) _data.push(this.videoEmojiMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly file: InputFileType | undefined,
      readonly video: InputFileType | undefined,
      readonly videoStartTs: TLDouble | undefined,
      readonly videoEmojiMarkup: VideoSizeType | undefined
    ) {}
  }

  export class InputChatPhoto implements TLObject {
    static readonly cons = new TLInt(0x8953ad37);

    static deserialized(_data: ByteStream): InputChatPhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputChatPhoto.cons))
        return undefined;

      const id = TLObject.deserialized(_data) as InputPhotoType;
      if (!id) return undefined;

      return new InputChatPhoto(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputChatPhoto.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: InputPhotoType) {}
  }

  export class InputGeoPointEmpty implements TLObject {
    static readonly cons = new TLInt(0xe4c123d6);

    static deserialized(_data: ByteStream): InputGeoPointEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputGeoPointEmpty.cons))
        return undefined;

      return new InputGeoPointEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputGeoPointEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputGeoPoint implements TLObject {
    static readonly cons = new TLInt(0x48222faf);

    static deserialized(_data: ByteStream): InputGeoPoint | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputGeoPoint.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const lat = TLDouble.deserialized(_data);
      if (!lat) return undefined;
      const long = TLDouble.deserialized(_data);
      if (!long) return undefined;
      let accuracyRadius: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        accuracyRadius = obj;
      }

      return new InputGeoPoint(lat, long, accuracyRadius);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputGeoPoint.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.lat);
      _data.push(this.long);
      if (this.accuracyRadius) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.accuracyRadius) _data.push(this.accuracyRadius);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly lat: TLDouble,
      readonly long: TLDouble,
      readonly accuracyRadius: TLInt | undefined
    ) {}
  }

  export class InputPhotoEmpty implements TLObject {
    static readonly cons = new TLInt(0x1cd7bf0d);

    static deserialized(_data: ByteStream): InputPhotoEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPhotoEmpty.cons))
        return undefined;

      return new InputPhotoEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPhotoEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPhoto implements TLObject {
    static readonly cons = new TLInt(0x3bb3b94a);

    static deserialized(_data: ByteStream): InputPhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPhoto.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const fileReference = TLBytes.deserialized(_data);
      if (!fileReference) return undefined;

      return new InputPhoto(id, accessHash, fileReference);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPhoto.cons];

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.fileReference);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly fileReference: TLBytes
    ) {}
  }

  export class InputFileLocation implements TLObject {
    static readonly cons = new TLInt(0xdfdaabe1);

    static deserialized(_data: ByteStream): InputFileLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputFileLocation.cons))
        return undefined;

      const volumeId = TLLong.deserialized(_data);
      if (!volumeId) return undefined;
      const localId = TLInt.deserialized(_data);
      if (!localId) return undefined;
      const secret = TLLong.deserialized(_data);
      if (!secret) return undefined;
      const fileReference = TLBytes.deserialized(_data);
      if (!fileReference) return undefined;

      return new InputFileLocation(volumeId, localId, secret, fileReference);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputFileLocation.cons];

      _data.push(this.volumeId);
      _data.push(this.localId);
      _data.push(this.secret);
      _data.push(this.fileReference);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly volumeId: TLLong,
      readonly localId: TLInt,
      readonly secret: TLLong,
      readonly fileReference: TLBytes
    ) {}
  }

  export class InputEncryptedFileLocation implements TLObject {
    static readonly cons = new TLInt(0xf5235d55);

    static deserialized(
      _data: ByteStream
    ): InputEncryptedFileLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputEncryptedFileLocation.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputEncryptedFileLocation(id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputEncryptedFileLocation.cons];

      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputDocumentFileLocation implements TLObject {
    static readonly cons = new TLInt(0xbad07584);

    static deserialized(
      _data: ByteStream
    ): InputDocumentFileLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputDocumentFileLocation.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const fileReference = TLBytes.deserialized(_data);
      if (!fileReference) return undefined;
      const thumbSize = TLString.deserialized(_data);
      if (!thumbSize) return undefined;

      return new InputDocumentFileLocation(
        id,
        accessHash,
        fileReference,
        thumbSize
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputDocumentFileLocation.cons];

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.fileReference);
      _data.push(this.thumbSize);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly fileReference: TLBytes,
      readonly thumbSize: TLString
    ) {}
  }

  export class InputSecureFileLocation implements TLObject {
    static readonly cons = new TLInt(0xcbc7ee28);

    static deserialized(
      _data: ByteStream
    ): InputSecureFileLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputSecureFileLocation.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputSecureFileLocation(id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputSecureFileLocation.cons];

      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputTakeoutFileLocation implements TLObject {
    static readonly cons = new TLInt(0x29be5899);

    static deserialized(
      _data: ByteStream
    ): InputTakeoutFileLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputTakeoutFileLocation.cons))
        return undefined;

      return new InputTakeoutFileLocation();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputTakeoutFileLocation.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPhotoFileLocation implements TLObject {
    static readonly cons = new TLInt(0x40181ffe);

    static deserialized(_data: ByteStream): InputPhotoFileLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPhotoFileLocation.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const fileReference = TLBytes.deserialized(_data);
      if (!fileReference) return undefined;
      const thumbSize = TLString.deserialized(_data);
      if (!thumbSize) return undefined;

      return new InputPhotoFileLocation(
        id,
        accessHash,
        fileReference,
        thumbSize
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPhotoFileLocation.cons];

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.fileReference);
      _data.push(this.thumbSize);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly fileReference: TLBytes,
      readonly thumbSize: TLString
    ) {}
  }

  export class InputPhotoLegacyFileLocation implements TLObject {
    static readonly cons = new TLInt(0xd83466f3);

    static deserialized(
      _data: ByteStream
    ): InputPhotoLegacyFileLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPhotoLegacyFileLocation.cons)
      )
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const fileReference = TLBytes.deserialized(_data);
      if (!fileReference) return undefined;
      const volumeId = TLLong.deserialized(_data);
      if (!volumeId) return undefined;
      const localId = TLInt.deserialized(_data);
      if (!localId) return undefined;
      const secret = TLLong.deserialized(_data);
      if (!secret) return undefined;

      return new InputPhotoLegacyFileLocation(
        id,
        accessHash,
        fileReference,
        volumeId,
        localId,
        secret
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPhotoLegacyFileLocation.cons];

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.fileReference);
      _data.push(this.volumeId);
      _data.push(this.localId);
      _data.push(this.secret);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly fileReference: TLBytes,
      readonly volumeId: TLLong,
      readonly localId: TLInt,
      readonly secret: TLLong
    ) {}
  }

  export class InputPeerPhotoFileLocation implements TLObject {
    static readonly cons = new TLInt(0x37257e99);

    static deserialized(
      _data: ByteStream
    ): InputPeerPhotoFileLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPeerPhotoFileLocation.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const big = (flags.value & 1) !== 0;
      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;
      const photoId = TLLong.deserialized(_data);
      if (!photoId) return undefined;

      return new InputPeerPhotoFileLocation(big, peer, photoId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPeerPhotoFileLocation.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.big) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.peer);
      _data.push(this.photoId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly big: boolean | undefined,
      readonly peer: InputPeerType,
      readonly photoId: TLLong
    ) {}
  }

  export class InputStickerSetThumb implements TLObject {
    static readonly cons = new TLInt(0x9d84f3db);

    static deserialized(_data: ByteStream): InputStickerSetThumb | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputStickerSetThumb.cons))
        return undefined;

      const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
      if (!stickerset) return undefined;
      const thumbVersion = TLInt.deserialized(_data);
      if (!thumbVersion) return undefined;

      return new InputStickerSetThumb(stickerset, thumbVersion);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputStickerSetThumb.cons];

      _data.push(this.stickerset);
      _data.push(this.thumbVersion);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly stickerset: InputStickerSetType,
      readonly thumbVersion: TLInt
    ) {}
  }

  export class InputGroupCallStream implements TLObject {
    static readonly cons = new TLInt(0x598a92a);

    static deserialized(_data: ByteStream): InputGroupCallStream | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputGroupCallStream.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const call = API.InputGroupCall.deserialized(_data);
      if (!call) return undefined;
      const timeMs = TLLong.deserialized(_data);
      if (!timeMs) return undefined;
      const scale = TLInt.deserialized(_data);
      if (!scale) return undefined;
      let videoChannel: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        videoChannel = obj;
      }
      let videoQuality: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        videoQuality = obj;
      }

      return new InputGroupCallStream(
        call,
        timeMs,
        scale,
        videoChannel,
        videoQuality
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputGroupCallStream.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.call);
      _data.push(this.timeMs);
      _data.push(this.scale);
      if (this.videoChannel) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.videoChannel) _data.push(this.videoChannel);

      if (this.videoQuality) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.videoQuality) _data.push(this.videoQuality);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly call: API.InputGroupCall,
      readonly timeMs: TLLong,
      readonly scale: TLInt,
      readonly videoChannel: TLInt | undefined,
      readonly videoQuality: TLInt | undefined
    ) {}
  }

  export class PeerUser implements TLObject {
    static readonly cons = new TLInt(0x59511722);

    static deserialized(_data: ByteStream): PeerUser | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PeerUser.cons)) return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;

      return new PeerUser(userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PeerUser.cons];

      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong) {}
  }

  export class PeerChat implements TLObject {
    static readonly cons = new TLInt(0x36c6019a);

    static deserialized(_data: ByteStream): PeerChat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PeerChat.cons)) return undefined;

      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;

      return new PeerChat(chatId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PeerChat.cons];

      _data.push(this.chatId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chatId: TLLong) {}
  }

  export class PeerChannel implements TLObject {
    static readonly cons = new TLInt(0xa2a5371e);

    static deserialized(_data: ByteStream): PeerChannel | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PeerChannel.cons))
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;

      return new PeerChannel(channelId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PeerChannel.cons];

      _data.push(this.channelId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly channelId: TLLong) {}
  }

  export namespace storage {
    export class FileUnknown implements TLObject {
      static readonly cons = new TLInt(0xaa963b05);

      static deserialized(_data: ByteStream): FileUnknown | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FileUnknown.cons))
          return undefined;

        return new FileUnknown();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FileUnknown.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace storage {
    export class FilePartial implements TLObject {
      static readonly cons = new TLInt(0x40bc6f52);

      static deserialized(_data: ByteStream): FilePartial | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FilePartial.cons))
          return undefined;

        return new FilePartial();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FilePartial.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace storage {
    export class FileJpeg implements TLObject {
      static readonly cons = new TLInt(0x7efe0e);

      static deserialized(_data: ByteStream): FileJpeg | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FileJpeg.cons))
          return undefined;

        return new FileJpeg();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FileJpeg.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace storage {
    export class FileGif implements TLObject {
      static readonly cons = new TLInt(0xcae1aadf);

      static deserialized(_data: ByteStream): FileGif | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FileGif.cons)) return undefined;

        return new FileGif();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FileGif.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace storage {
    export class FilePng implements TLObject {
      static readonly cons = new TLInt(0xa4f63c0);

      static deserialized(_data: ByteStream): FilePng | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FilePng.cons)) return undefined;

        return new FilePng();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FilePng.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace storage {
    export class FilePdf implements TLObject {
      static readonly cons = new TLInt(0xae1e508d);

      static deserialized(_data: ByteStream): FilePdf | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FilePdf.cons)) return undefined;

        return new FilePdf();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FilePdf.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace storage {
    export class FileMp3 implements TLObject {
      static readonly cons = new TLInt(0x528a0677);

      static deserialized(_data: ByteStream): FileMp3 | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FileMp3.cons)) return undefined;

        return new FileMp3();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FileMp3.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace storage {
    export class FileMov implements TLObject {
      static readonly cons = new TLInt(0x4b09ebbc);

      static deserialized(_data: ByteStream): FileMov | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FileMov.cons)) return undefined;

        return new FileMov();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FileMov.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace storage {
    export class FileMp4 implements TLObject {
      static readonly cons = new TLInt(0xb3cea0e4);

      static deserialized(_data: ByteStream): FileMp4 | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FileMp4.cons)) return undefined;

        return new FileMp4();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FileMp4.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace storage {
    export class FileWebp implements TLObject {
      static readonly cons = new TLInt(0x1081464c);

      static deserialized(_data: ByteStream): FileWebp | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FileWebp.cons))
          return undefined;

        return new FileWebp();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FileWebp.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export class UserEmpty implements TLObject {
    static readonly cons = new TLInt(0xd3bc4b7a);

    static deserialized(_data: ByteStream): UserEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UserEmpty.cons)) return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;

      return new UserEmpty(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UserEmpty.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong) {}
  }

  export class User implements TLObject {
    static readonly cons = new TLInt(0xabb5f120);

    static deserialized(_data: ByteStream): User | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(User.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const self = (flags.value & 1024) !== 0;
      const contact = (flags.value & 2048) !== 0;
      const mutualContact = (flags.value & 4096) !== 0;
      const deleted = (flags.value & 8192) !== 0;
      const bot = (flags.value & 16384) !== 0;
      const botChatHistory = (flags.value & 32768) !== 0;
      const botNochats = (flags.value & 65536) !== 0;
      const verified = (flags.value & 131072) !== 0;
      const restricted = (flags.value & 262144) !== 0;
      const min = (flags.value & 1048576) !== 0;
      const botInlineGeo = (flags.value & 2097152) !== 0;
      const support = (flags.value & 8388608) !== 0;
      const scam = (flags.value & 16777216) !== 0;
      const applyMinPhoto = (flags.value & 33554432) !== 0;
      const fake = (flags.value & 67108864) !== 0;
      const botAttachMenu = (flags.value & 134217728) !== 0;
      const premium = (flags.value & 268435456) !== 0;
      const attachMenuEnabled = (flags.value & 536870912) !== 0;
      const flags2 = TLInt.deserialized(_data);
      if (!flags2) return undefined;
      const botCanEdit = (flags2.value & 2) !== 0;
      const closeFriend = (flags2.value & 4) !== 0;
      const storiesHidden = (flags2.value & 8) !== 0;
      const storiesUnavailable = (flags2.value & 16) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      let accessHash: TLLong | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        accessHash = obj;
      }
      let firstName: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        firstName = obj;
      }
      let lastName: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        lastName = obj;
      }
      let username: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        username = obj;
      }
      let phone: TLString | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        phone = obj;
      }
      let photo: UserProfilePhotoType | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLObject.deserialized(_data) as UserProfilePhotoType;
        if (!obj) return undefined;
        photo = obj;
      }
      let status: UserStatusType | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLObject.deserialized(_data) as UserStatusType;
        if (!obj) return undefined;
        status = obj;
      }
      let botInfoVersion: TLInt | undefined;
      if ((flags.value & 16384) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        botInfoVersion = obj;
      }
      let restrictionReason: TLVector<API.RestrictionReason> | undefined;
      if ((flags.value & 262144) !== 0) {
        const obj = TLVector.deserialized(_data, API.RestrictionReason);
        if (!obj) return undefined;
        restrictionReason = obj;
      }
      let botInlinePlaceholder: TLString | undefined;
      if ((flags.value & 524288) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        botInlinePlaceholder = obj;
      }
      let langCode: TLString | undefined;
      if ((flags.value & 4194304) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        langCode = obj;
      }
      let emojiStatus: EmojiStatusType | undefined;
      if ((flags.value & 1073741824) !== 0) {
        const obj = TLObject.deserialized(_data) as EmojiStatusType;
        if (!obj) return undefined;
        emojiStatus = obj;
      }
      let usernames: TLVector<API.Username> | undefined;
      if ((flags2.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data, API.Username);
        if (!obj) return undefined;
        usernames = obj;
      }
      let storiesMaxId: TLInt | undefined;
      if ((flags2.value & 32) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        storiesMaxId = obj;
      }

      return new User(
        self,
        contact,
        mutualContact,
        deleted,
        bot,
        botChatHistory,
        botNochats,
        verified,
        restricted,
        min,
        botInlineGeo,
        support,
        scam,
        applyMinPhoto,
        fake,
        botAttachMenu,
        premium,
        attachMenuEnabled,
        botCanEdit,
        closeFriend,
        storiesHidden,
        storiesUnavailable,
        id,
        accessHash,
        firstName,
        lastName,
        username,
        phone,
        photo,
        status,
        botInfoVersion,
        restrictionReason,
        botInlinePlaceholder,
        langCode,
        emojiStatus,
        usernames,
        storiesMaxId
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [User.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.self) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }

      if (this.contact) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }

      if (this.mutualContact) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }

      if (this.deleted) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }

      if (this.bot) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }

      if (this.botChatHistory) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }

      if (this.botNochats) {
        flags.set(65536);
      } else {
        flags.unset(65536);
      }

      if (this.verified) {
        flags.set(131072);
      } else {
        flags.unset(131072);
      }

      if (this.restricted) {
        flags.set(262144);
      } else {
        flags.unset(262144);
      }

      if (this.min) {
        flags.set(1048576);
      } else {
        flags.unset(1048576);
      }

      if (this.botInlineGeo) {
        flags.set(2097152);
      } else {
        flags.unset(2097152);
      }

      if (this.support) {
        flags.set(8388608);
      } else {
        flags.unset(8388608);
      }

      if (this.scam) {
        flags.set(16777216);
      } else {
        flags.unset(16777216);
      }

      if (this.applyMinPhoto) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }

      if (this.fake) {
        flags.set(67108864);
      } else {
        flags.unset(67108864);
      }

      if (this.botAttachMenu) {
        flags.set(134217728);
      } else {
        flags.unset(134217728);
      }

      if (this.premium) {
        flags.set(268435456);
      } else {
        flags.unset(268435456);
      }

      if (this.attachMenuEnabled) {
        flags.set(536870912);
      } else {
        flags.unset(536870912);
      }

      const flags2 = new TLFlags();
      _data.push(flags2);
      if (this.botCanEdit) {
        flags2.set(2);
      } else {
        flags2.unset(2);
      }

      if (this.closeFriend) {
        flags2.set(4);
      } else {
        flags2.unset(4);
      }

      if (this.storiesHidden) {
        flags2.set(8);
      } else {
        flags2.unset(8);
      }

      if (this.storiesUnavailable) {
        flags2.set(16);
      } else {
        flags2.unset(16);
      }

      _data.push(this.id);
      if (this.accessHash) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.accessHash) _data.push(this.accessHash);

      if (this.firstName) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.firstName) _data.push(this.firstName);

      if (this.lastName) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.lastName) _data.push(this.lastName);

      if (this.username) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.username) _data.push(this.username);

      if (this.phone) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.phone) _data.push(this.phone);

      if (this.photo) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.photo) _data.push(this.photo);

      if (this.status) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.status) _data.push(this.status);

      if (this.botInfoVersion) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }
      if (this.botInfoVersion) _data.push(this.botInfoVersion);

      if (this.restrictionReason) {
        flags.set(262144);
      } else {
        flags.unset(262144);
      }
      if (this.restrictionReason) _data.push(this.restrictionReason);

      if (this.botInlinePlaceholder) {
        flags.set(524288);
      } else {
        flags.unset(524288);
      }
      if (this.botInlinePlaceholder) _data.push(this.botInlinePlaceholder);

      if (this.langCode) {
        flags.set(4194304);
      } else {
        flags.unset(4194304);
      }
      if (this.langCode) _data.push(this.langCode);

      if (this.emojiStatus) {
        flags.set(1073741824);
      } else {
        flags.unset(1073741824);
      }
      if (this.emojiStatus) _data.push(this.emojiStatus);

      if (this.usernames) {
        flags2.set(1);
      } else {
        flags2.unset(1);
      }
      if (this.usernames) _data.push(this.usernames);

      if (this.storiesMaxId) {
        flags2.set(32);
      } else {
        flags2.unset(32);
      }
      if (this.storiesMaxId) _data.push(this.storiesMaxId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly self: boolean | undefined,
      readonly contact: boolean | undefined,
      readonly mutualContact: boolean | undefined,
      readonly deleted: boolean | undefined,
      readonly bot: boolean | undefined,
      readonly botChatHistory: boolean | undefined,
      readonly botNochats: boolean | undefined,
      readonly verified: boolean | undefined,
      readonly restricted: boolean | undefined,
      readonly min: boolean | undefined,
      readonly botInlineGeo: boolean | undefined,
      readonly support: boolean | undefined,
      readonly scam: boolean | undefined,
      readonly applyMinPhoto: boolean | undefined,
      readonly fake: boolean | undefined,
      readonly botAttachMenu: boolean | undefined,
      readonly premium: boolean | undefined,
      readonly attachMenuEnabled: boolean | undefined,
      readonly botCanEdit: boolean | undefined,
      readonly closeFriend: boolean | undefined,
      readonly storiesHidden: boolean | undefined,
      readonly storiesUnavailable: boolean | undefined,
      readonly id: TLLong,
      readonly accessHash: TLLong | undefined,
      readonly firstName: TLString | undefined,
      readonly lastName: TLString | undefined,
      readonly username: TLString | undefined,
      readonly phone: TLString | undefined,
      readonly photo: UserProfilePhotoType | undefined,
      readonly status: UserStatusType | undefined,
      readonly botInfoVersion: TLInt | undefined,
      readonly restrictionReason: TLVector<API.RestrictionReason> | undefined,
      readonly botInlinePlaceholder: TLString | undefined,
      readonly langCode: TLString | undefined,
      readonly emojiStatus: EmojiStatusType | undefined,
      readonly usernames: TLVector<API.Username> | undefined,
      readonly storiesMaxId: TLInt | undefined
    ) {}
  }

  export class UserProfilePhotoEmpty implements TLObject {
    static readonly cons = new TLInt(0x4f11bae1);

    static deserialized(_data: ByteStream): UserProfilePhotoEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UserProfilePhotoEmpty.cons))
        return undefined;

      return new UserProfilePhotoEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UserProfilePhotoEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UserProfilePhoto implements TLObject {
    static readonly cons = new TLInt(0x82d1f706);

    static deserialized(_data: ByteStream): UserProfilePhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UserProfilePhoto.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const hasVideo = (flags.value & 1) !== 0;
      const personal = (flags.value & 4) !== 0;
      const photoId = TLLong.deserialized(_data);
      if (!photoId) return undefined;
      let strippedThumb: TLBytes | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLBytes.deserialized(_data);
        if (!obj) return undefined;
        strippedThumb = obj;
      }
      const dcId = TLInt.deserialized(_data);
      if (!dcId) return undefined;

      return new UserProfilePhoto(
        hasVideo,
        personal,
        photoId,
        strippedThumb,
        dcId
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UserProfilePhoto.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.hasVideo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.personal) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.photoId);
      if (this.strippedThumb) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.strippedThumb) _data.push(this.strippedThumb);

      _data.push(this.dcId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly hasVideo: boolean | undefined,
      readonly personal: boolean | undefined,
      readonly photoId: TLLong,
      readonly strippedThumb: TLBytes | undefined,
      readonly dcId: TLInt
    ) {}
  }

  export class UserStatusEmpty implements TLObject {
    static readonly cons = new TLInt(0x9d05049);

    static deserialized(_data: ByteStream): UserStatusEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UserStatusEmpty.cons))
        return undefined;

      return new UserStatusEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UserStatusEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UserStatusOnline implements TLObject {
    static readonly cons = new TLInt(0xedb93949);

    static deserialized(_data: ByteStream): UserStatusOnline | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UserStatusOnline.cons))
        return undefined;

      const expires = TLInt.deserialized(_data);
      if (!expires) return undefined;

      return new UserStatusOnline(expires);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UserStatusOnline.cons];

      _data.push(this.expires);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly expires: TLInt) {}
  }

  export class UserStatusOffline implements TLObject {
    static readonly cons = new TLInt(0x8c703f);

    static deserialized(_data: ByteStream): UserStatusOffline | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UserStatusOffline.cons))
        return undefined;

      const wasOnline = TLInt.deserialized(_data);
      if (!wasOnline) return undefined;

      return new UserStatusOffline(wasOnline);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UserStatusOffline.cons];

      _data.push(this.wasOnline);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly wasOnline: TLInt) {}
  }

  export class UserStatusRecently implements TLObject {
    static readonly cons = new TLInt(0xe26f42f1);

    static deserialized(_data: ByteStream): UserStatusRecently | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UserStatusRecently.cons))
        return undefined;

      return new UserStatusRecently();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UserStatusRecently.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UserStatusLastWeek implements TLObject {
    static readonly cons = new TLInt(0x7bf09fc);

    static deserialized(_data: ByteStream): UserStatusLastWeek | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UserStatusLastWeek.cons))
        return undefined;

      return new UserStatusLastWeek();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UserStatusLastWeek.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UserStatusLastMonth implements TLObject {
    static readonly cons = new TLInt(0x77ebc742);

    static deserialized(_data: ByteStream): UserStatusLastMonth | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UserStatusLastMonth.cons))
        return undefined;

      return new UserStatusLastMonth();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UserStatusLastMonth.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChatEmpty implements TLObject {
    static readonly cons = new TLInt(0x29562865);

    static deserialized(_data: ByteStream): ChatEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatEmpty.cons)) return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;

      return new ChatEmpty(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatEmpty.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong) {}
  }

  export class Chat implements TLObject {
    static readonly cons = new TLInt(0x41cbf256);

    static deserialized(_data: ByteStream): Chat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Chat.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const creator = (flags.value & 1) !== 0;
      const left = (flags.value & 4) !== 0;
      const deactivated = (flags.value & 32) !== 0;
      const callActive = (flags.value & 8388608) !== 0;
      const callNotEmpty = (flags.value & 16777216) !== 0;
      const noforwards = (flags.value & 33554432) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const photo = TLObject.deserialized(_data) as ChatPhotoType;
      if (!photo) return undefined;
      const participantsCount = TLInt.deserialized(_data);
      if (!participantsCount) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const version = TLInt.deserialized(_data);
      if (!version) return undefined;
      let migratedTo: InputChannelType | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLObject.deserialized(_data) as InputChannelType;
        if (!obj) return undefined;
        migratedTo = obj;
      }
      let adminRights: API.ChatAdminRights | undefined;
      if ((flags.value & 16384) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ChatAdminRights;
        if (!obj) return undefined;
        adminRights = obj;
      }
      let defaultBannedRights: API.ChatBannedRights | undefined;
      if ((flags.value & 262144) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ChatBannedRights;
        if (!obj) return undefined;
        defaultBannedRights = obj;
      }

      return new Chat(
        creator,
        left,
        deactivated,
        callActive,
        callNotEmpty,
        noforwards,
        id,
        title,
        photo,
        participantsCount,
        date,
        version,
        migratedTo,
        adminRights,
        defaultBannedRights
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Chat.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.creator) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.left) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.deactivated) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.callActive) {
        flags.set(8388608);
      } else {
        flags.unset(8388608);
      }

      if (this.callNotEmpty) {
        flags.set(16777216);
      } else {
        flags.unset(16777216);
      }

      if (this.noforwards) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }

      _data.push(this.id);
      _data.push(this.title);
      _data.push(this.photo);
      _data.push(this.participantsCount);
      _data.push(this.date);
      _data.push(this.version);
      if (this.migratedTo) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.migratedTo) _data.push(this.migratedTo);

      if (this.adminRights) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }
      if (this.adminRights) _data.push(this.adminRights);

      if (this.defaultBannedRights) {
        flags.set(262144);
      } else {
        flags.unset(262144);
      }
      if (this.defaultBannedRights) _data.push(this.defaultBannedRights);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly creator: boolean | undefined,
      readonly left: boolean | undefined,
      readonly deactivated: boolean | undefined,
      readonly callActive: boolean | undefined,
      readonly callNotEmpty: boolean | undefined,
      readonly noforwards: boolean | undefined,
      readonly id: TLLong,
      readonly title: TLString,
      readonly photo: ChatPhotoType,
      readonly participantsCount: TLInt,
      readonly date: TLInt,
      readonly version: TLInt,
      readonly migratedTo: InputChannelType | undefined,
      readonly adminRights: API.ChatAdminRights | undefined,
      readonly defaultBannedRights: API.ChatBannedRights | undefined
    ) {}
  }

  export class ChatForbidden implements TLObject {
    static readonly cons = new TLInt(0x6592a1a7);

    static deserialized(_data: ByteStream): ChatForbidden | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatForbidden.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;

      return new ChatForbidden(id, title);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatForbidden.cons];

      _data.push(this.id);
      _data.push(this.title);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly title: TLString) {}
  }

  export class Channel implements TLObject {
    static readonly cons = new TLInt(0x94f592db);

    static deserialized(_data: ByteStream): Channel | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Channel.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const creator = (flags.value & 1) !== 0;
      const left = (flags.value & 4) !== 0;
      const broadcast = (flags.value & 32) !== 0;
      const verified = (flags.value & 128) !== 0;
      const megagroup = (flags.value & 256) !== 0;
      const restricted = (flags.value & 512) !== 0;
      const signatures = (flags.value & 2048) !== 0;
      const min = (flags.value & 4096) !== 0;
      const scam = (flags.value & 524288) !== 0;
      const hasLink = (flags.value & 1048576) !== 0;
      const hasGeo = (flags.value & 2097152) !== 0;
      const slowmodeEnabled = (flags.value & 4194304) !== 0;
      const callActive = (flags.value & 8388608) !== 0;
      const callNotEmpty = (flags.value & 16777216) !== 0;
      const fake = (flags.value & 33554432) !== 0;
      const gigagroup = (flags.value & 67108864) !== 0;
      const noforwards = (flags.value & 134217728) !== 0;
      const joinToSend = (flags.value & 268435456) !== 0;
      const joinRequest = (flags.value & 536870912) !== 0;
      const forum = (flags.value & 1073741824) !== 0;
      const flags2 = TLInt.deserialized(_data);
      if (!flags2) return undefined;
      const storiesHidden = (flags2.value & 2) !== 0;
      const storiesHiddenMin = (flags2.value & 4) !== 0;
      const storiesUnavailable = (flags2.value & 8) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      let accessHash: TLLong | undefined;
      if ((flags.value & 8192) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        accessHash = obj;
      }
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      let username: TLString | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        username = obj;
      }
      const photo = TLObject.deserialized(_data) as ChatPhotoType;
      if (!photo) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      let restrictionReason: TLVector<API.RestrictionReason> | undefined;
      if ((flags.value & 512) !== 0) {
        const obj = TLVector.deserialized(_data, API.RestrictionReason);
        if (!obj) return undefined;
        restrictionReason = obj;
      }
      let adminRights: API.ChatAdminRights | undefined;
      if ((flags.value & 16384) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ChatAdminRights;
        if (!obj) return undefined;
        adminRights = obj;
      }
      let bannedRights: API.ChatBannedRights | undefined;
      if ((flags.value & 32768) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ChatBannedRights;
        if (!obj) return undefined;
        bannedRights = obj;
      }
      let defaultBannedRights: API.ChatBannedRights | undefined;
      if ((flags.value & 262144) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ChatBannedRights;
        if (!obj) return undefined;
        defaultBannedRights = obj;
      }
      let participantsCount: TLInt | undefined;
      if ((flags.value & 131072) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        participantsCount = obj;
      }
      let usernames: TLVector<API.Username> | undefined;
      if ((flags2.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data, API.Username);
        if (!obj) return undefined;
        usernames = obj;
      }
      let storiesMaxId: TLInt | undefined;
      if ((flags2.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        storiesMaxId = obj;
      }

      return new Channel(
        creator,
        left,
        broadcast,
        verified,
        megagroup,
        restricted,
        signatures,
        min,
        scam,
        hasLink,
        hasGeo,
        slowmodeEnabled,
        callActive,
        callNotEmpty,
        fake,
        gigagroup,
        noforwards,
        joinToSend,
        joinRequest,
        forum,
        storiesHidden,
        storiesHiddenMin,
        storiesUnavailable,
        id,
        accessHash,
        title,
        username,
        photo,
        date,
        restrictionReason,
        adminRights,
        bannedRights,
        defaultBannedRights,
        participantsCount,
        usernames,
        storiesMaxId
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Channel.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.creator) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.left) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.broadcast) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.verified) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.megagroup) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      if (this.restricted) {
        flags.set(512);
      } else {
        flags.unset(512);
      }

      if (this.signatures) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }

      if (this.min) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }

      if (this.scam) {
        flags.set(524288);
      } else {
        flags.unset(524288);
      }

      if (this.hasLink) {
        flags.set(1048576);
      } else {
        flags.unset(1048576);
      }

      if (this.hasGeo) {
        flags.set(2097152);
      } else {
        flags.unset(2097152);
      }

      if (this.slowmodeEnabled) {
        flags.set(4194304);
      } else {
        flags.unset(4194304);
      }

      if (this.callActive) {
        flags.set(8388608);
      } else {
        flags.unset(8388608);
      }

      if (this.callNotEmpty) {
        flags.set(16777216);
      } else {
        flags.unset(16777216);
      }

      if (this.fake) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }

      if (this.gigagroup) {
        flags.set(67108864);
      } else {
        flags.unset(67108864);
      }

      if (this.noforwards) {
        flags.set(134217728);
      } else {
        flags.unset(134217728);
      }

      if (this.joinToSend) {
        flags.set(268435456);
      } else {
        flags.unset(268435456);
      }

      if (this.joinRequest) {
        flags.set(536870912);
      } else {
        flags.unset(536870912);
      }

      if (this.forum) {
        flags.set(1073741824);
      } else {
        flags.unset(1073741824);
      }

      const flags2 = new TLFlags();
      _data.push(flags2);
      if (this.storiesHidden) {
        flags2.set(2);
      } else {
        flags2.unset(2);
      }

      if (this.storiesHiddenMin) {
        flags2.set(4);
      } else {
        flags2.unset(4);
      }

      if (this.storiesUnavailable) {
        flags2.set(8);
      } else {
        flags2.unset(8);
      }

      _data.push(this.id);
      if (this.accessHash) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }
      if (this.accessHash) _data.push(this.accessHash);

      _data.push(this.title);
      if (this.username) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.username) _data.push(this.username);

      _data.push(this.photo);
      _data.push(this.date);
      if (this.restrictionReason) {
        flags.set(512);
      } else {
        flags.unset(512);
      }
      if (this.restrictionReason) _data.push(this.restrictionReason);

      if (this.adminRights) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }
      if (this.adminRights) _data.push(this.adminRights);

      if (this.bannedRights) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }
      if (this.bannedRights) _data.push(this.bannedRights);

      if (this.defaultBannedRights) {
        flags.set(262144);
      } else {
        flags.unset(262144);
      }
      if (this.defaultBannedRights) _data.push(this.defaultBannedRights);

      if (this.participantsCount) {
        flags.set(131072);
      } else {
        flags.unset(131072);
      }
      if (this.participantsCount) _data.push(this.participantsCount);

      if (this.usernames) {
        flags2.set(1);
      } else {
        flags2.unset(1);
      }
      if (this.usernames) _data.push(this.usernames);

      if (this.storiesMaxId) {
        flags2.set(16);
      } else {
        flags2.unset(16);
      }
      if (this.storiesMaxId) _data.push(this.storiesMaxId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly creator: boolean | undefined,
      readonly left: boolean | undefined,
      readonly broadcast: boolean | undefined,
      readonly verified: boolean | undefined,
      readonly megagroup: boolean | undefined,
      readonly restricted: boolean | undefined,
      readonly signatures: boolean | undefined,
      readonly min: boolean | undefined,
      readonly scam: boolean | undefined,
      readonly hasLink: boolean | undefined,
      readonly hasGeo: boolean | undefined,
      readonly slowmodeEnabled: boolean | undefined,
      readonly callActive: boolean | undefined,
      readonly callNotEmpty: boolean | undefined,
      readonly fake: boolean | undefined,
      readonly gigagroup: boolean | undefined,
      readonly noforwards: boolean | undefined,
      readonly joinToSend: boolean | undefined,
      readonly joinRequest: boolean | undefined,
      readonly forum: boolean | undefined,
      readonly storiesHidden: boolean | undefined,
      readonly storiesHiddenMin: boolean | undefined,
      readonly storiesUnavailable: boolean | undefined,
      readonly id: TLLong,
      readonly accessHash: TLLong | undefined,
      readonly title: TLString,
      readonly username: TLString | undefined,
      readonly photo: ChatPhotoType,
      readonly date: TLInt,
      readonly restrictionReason: TLVector<API.RestrictionReason> | undefined,
      readonly adminRights: API.ChatAdminRights | undefined,
      readonly bannedRights: API.ChatBannedRights | undefined,
      readonly defaultBannedRights: API.ChatBannedRights | undefined,
      readonly participantsCount: TLInt | undefined,
      readonly usernames: TLVector<API.Username> | undefined,
      readonly storiesMaxId: TLInt | undefined
    ) {}
  }

  export class ChannelForbidden implements TLObject {
    static readonly cons = new TLInt(0x17d493d5);

    static deserialized(_data: ByteStream): ChannelForbidden | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelForbidden.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const broadcast = (flags.value & 32) !== 0;
      const megagroup = (flags.value & 256) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      let untilDate: TLInt | undefined;
      if ((flags.value & 65536) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        untilDate = obj;
      }

      return new ChannelForbidden(
        broadcast,
        megagroup,
        id,
        accessHash,
        title,
        untilDate
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelForbidden.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.broadcast) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.megagroup) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.title);
      if (this.untilDate) {
        flags.set(65536);
      } else {
        flags.unset(65536);
      }
      if (this.untilDate) _data.push(this.untilDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly broadcast: boolean | undefined,
      readonly megagroup: boolean | undefined,
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly title: TLString,
      readonly untilDate: TLInt | undefined
    ) {}
  }

  export class ChatFull implements TLObject {
    static readonly cons = new TLInt(0xc9d31138);

    static deserialized(_data: ByteStream): ChatFull | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatFull.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const canSetUsername = (flags.value & 128) !== 0;
      const hasScheduled = (flags.value & 256) !== 0;
      const translationsDisabled = (flags.value & 524288) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const about = TLString.deserialized(_data);
      if (!about) return undefined;
      const participants = TLObject.deserialized(_data) as ChatParticipantsType;
      if (!participants) return undefined;
      let chatPhoto: PhotoType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as PhotoType;
        if (!obj) return undefined;
        chatPhoto = obj;
      }
      const notifySettings = API.PeerNotifySettings.deserialized(_data);
      if (!notifySettings) return undefined;
      let exportedInvite: ExportedChatInviteType | undefined;
      if ((flags.value & 8192) !== 0) {
        const obj = TLObject.deserialized(_data) as ExportedChatInviteType;
        if (!obj) return undefined;
        exportedInvite = obj;
      }
      let botInfo: TLVector<API.BotInfo> | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLVector.deserialized(_data, API.BotInfo);
        if (!obj) return undefined;
        botInfo = obj;
      }
      let pinnedMsgId: TLInt | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        pinnedMsgId = obj;
      }
      let folderId: TLInt | undefined;
      if ((flags.value & 2048) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        folderId = obj;
      }
      let call: API.InputGroupCall | undefined;
      if ((flags.value & 4096) !== 0) {
        const obj = TLObject.deserialized(_data) as API.InputGroupCall;
        if (!obj) return undefined;
        call = obj;
      }
      let ttlPeriod: TLInt | undefined;
      if ((flags.value & 16384) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlPeriod = obj;
      }
      let groupcallDefaultJoinAs: PeerType | undefined;
      if ((flags.value & 32768) !== 0) {
        const obj = TLObject.deserialized(_data) as PeerType;
        if (!obj) return undefined;
        groupcallDefaultJoinAs = obj;
      }
      let themeEmoticon: TLString | undefined;
      if ((flags.value & 65536) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        themeEmoticon = obj;
      }
      let requestsPending: TLInt | undefined;
      if ((flags.value & 131072) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        requestsPending = obj;
      }
      let recentRequesters: TLVector<TLLong> | undefined;
      if ((flags.value & 131072) !== 0) {
        const obj = TLVector.deserialized(_data, TLLong);
        if (!obj) return undefined;
        recentRequesters = obj;
      }
      let availableReactions: ChatReactionsType | undefined;
      if ((flags.value & 262144) !== 0) {
        const obj = TLObject.deserialized(_data) as ChatReactionsType;
        if (!obj) return undefined;
        availableReactions = obj;
      }

      return new ChatFull(
        canSetUsername,
        hasScheduled,
        translationsDisabled,
        id,
        about,
        participants,
        chatPhoto,
        notifySettings,
        exportedInvite,
        botInfo,
        pinnedMsgId,
        folderId,
        call,
        ttlPeriod,
        groupcallDefaultJoinAs,
        themeEmoticon,
        requestsPending,
        recentRequesters,
        availableReactions
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatFull.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.canSetUsername) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.hasScheduled) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      if (this.translationsDisabled) {
        flags.set(524288);
      } else {
        flags.unset(524288);
      }

      _data.push(this.id);
      _data.push(this.about);
      _data.push(this.participants);
      if (this.chatPhoto) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.chatPhoto) _data.push(this.chatPhoto);

      _data.push(this.notifySettings);
      if (this.exportedInvite) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }
      if (this.exportedInvite) _data.push(this.exportedInvite);

      if (this.botInfo) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.botInfo) _data.push(this.botInfo);

      if (this.pinnedMsgId) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.pinnedMsgId) _data.push(this.pinnedMsgId);

      if (this.folderId) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }
      if (this.folderId) _data.push(this.folderId);

      if (this.call) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }
      if (this.call) _data.push(this.call);

      if (this.ttlPeriod) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }
      if (this.ttlPeriod) _data.push(this.ttlPeriod);

      if (this.groupcallDefaultJoinAs) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }
      if (this.groupcallDefaultJoinAs) _data.push(this.groupcallDefaultJoinAs);

      if (this.themeEmoticon) {
        flags.set(65536);
      } else {
        flags.unset(65536);
      }
      if (this.themeEmoticon) _data.push(this.themeEmoticon);

      if (this.requestsPending) {
        flags.set(131072);
      } else {
        flags.unset(131072);
      }
      if (this.requestsPending) _data.push(this.requestsPending);

      if (this.recentRequesters) {
        flags.set(131072);
      } else {
        flags.unset(131072);
      }
      if (this.recentRequesters) _data.push(this.recentRequesters);

      if (this.availableReactions) {
        flags.set(262144);
      } else {
        flags.unset(262144);
      }
      if (this.availableReactions) _data.push(this.availableReactions);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly canSetUsername: boolean | undefined,
      readonly hasScheduled: boolean | undefined,
      readonly translationsDisabled: boolean | undefined,
      readonly id: TLLong,
      readonly about: TLString,
      readonly participants: ChatParticipantsType,
      readonly chatPhoto: PhotoType | undefined,
      readonly notifySettings: API.PeerNotifySettings,
      readonly exportedInvite: ExportedChatInviteType | undefined,
      readonly botInfo: TLVector<API.BotInfo> | undefined,
      readonly pinnedMsgId: TLInt | undefined,
      readonly folderId: TLInt | undefined,
      readonly call: API.InputGroupCall | undefined,
      readonly ttlPeriod: TLInt | undefined,
      readonly groupcallDefaultJoinAs: PeerType | undefined,
      readonly themeEmoticon: TLString | undefined,
      readonly requestsPending: TLInt | undefined,
      readonly recentRequesters: TLVector<TLLong> | undefined,
      readonly availableReactions: ChatReactionsType | undefined
    ) {}
  }

  export class ChannelFull implements TLObject {
    static readonly cons = new TLInt(0x723027bd);

    static deserialized(_data: ByteStream): ChannelFull | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelFull.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const canViewParticipants = (flags.value & 8) !== 0;
      const canSetUsername = (flags.value & 64) !== 0;
      const canSetStickers = (flags.value & 128) !== 0;
      const hiddenPrehistory = (flags.value & 1024) !== 0;
      const canSetLocation = (flags.value & 65536) !== 0;
      const hasScheduled = (flags.value & 524288) !== 0;
      const canViewStats = (flags.value & 1048576) !== 0;
      const blocked = (flags.value & 4194304) !== 0;
      const flags2 = TLInt.deserialized(_data);
      if (!flags2) return undefined;
      const canDeleteChannel = (flags2.value & 1) !== 0;
      const antispam = (flags2.value & 2) !== 0;
      const participantsHidden = (flags2.value & 4) !== 0;
      const translationsDisabled = (flags2.value & 8) !== 0;
      const storiesPinnedAvailable = (flags2.value & 32) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const about = TLString.deserialized(_data);
      if (!about) return undefined;
      let participantsCount: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        participantsCount = obj;
      }
      let adminsCount: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        adminsCount = obj;
      }
      let kickedCount: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        kickedCount = obj;
      }
      let bannedCount: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        bannedCount = obj;
      }
      let onlineCount: TLInt | undefined;
      if ((flags.value & 8192) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        onlineCount = obj;
      }
      const readInboxMaxId = TLInt.deserialized(_data);
      if (!readInboxMaxId) return undefined;
      const readOutboxMaxId = TLInt.deserialized(_data);
      if (!readOutboxMaxId) return undefined;
      const unreadCount = TLInt.deserialized(_data);
      if (!unreadCount) return undefined;
      const chatPhoto = TLObject.deserialized(_data) as PhotoType;
      if (!chatPhoto) return undefined;
      const notifySettings = API.PeerNotifySettings.deserialized(_data);
      if (!notifySettings) return undefined;
      let exportedInvite: ExportedChatInviteType | undefined;
      if ((flags.value & 8388608) !== 0) {
        const obj = TLObject.deserialized(_data) as ExportedChatInviteType;
        if (!obj) return undefined;
        exportedInvite = obj;
      }
      const botInfo = TLVector.deserialized(_data, API.BotInfo);
      if (!botInfo) return undefined;
      let migratedFromChatId: TLLong | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        migratedFromChatId = obj;
      }
      let migratedFromMaxId: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        migratedFromMaxId = obj;
      }
      let pinnedMsgId: TLInt | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        pinnedMsgId = obj;
      }
      let stickerset: API.StickerSet | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLObject.deserialized(_data) as API.StickerSet;
        if (!obj) return undefined;
        stickerset = obj;
      }
      let availableMinId: TLInt | undefined;
      if ((flags.value & 512) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        availableMinId = obj;
      }
      let folderId: TLInt | undefined;
      if ((flags.value & 2048) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        folderId = obj;
      }
      let linkedChatId: TLLong | undefined;
      if ((flags.value & 16384) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        linkedChatId = obj;
      }
      let location: ChannelLocationType | undefined;
      if ((flags.value & 32768) !== 0) {
        const obj = TLObject.deserialized(_data) as ChannelLocationType;
        if (!obj) return undefined;
        location = obj;
      }
      let slowmodeSeconds: TLInt | undefined;
      if ((flags.value & 131072) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        slowmodeSeconds = obj;
      }
      let slowmodeNextSendDate: TLInt | undefined;
      if ((flags.value & 262144) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        slowmodeNextSendDate = obj;
      }
      let statsDc: TLInt | undefined;
      if ((flags.value & 4096) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        statsDc = obj;
      }
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      let call: API.InputGroupCall | undefined;
      if ((flags.value & 2097152) !== 0) {
        const obj = TLObject.deserialized(_data) as API.InputGroupCall;
        if (!obj) return undefined;
        call = obj;
      }
      let ttlPeriod: TLInt | undefined;
      if ((flags.value & 16777216) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlPeriod = obj;
      }
      let pendingSuggestions: TLVector<TLString> | undefined;
      if ((flags.value & 33554432) !== 0) {
        const obj = TLVector.deserialized(_data, TLString);
        if (!obj) return undefined;
        pendingSuggestions = obj;
      }
      let groupcallDefaultJoinAs: PeerType | undefined;
      if ((flags.value & 67108864) !== 0) {
        const obj = TLObject.deserialized(_data) as PeerType;
        if (!obj) return undefined;
        groupcallDefaultJoinAs = obj;
      }
      let themeEmoticon: TLString | undefined;
      if ((flags.value & 134217728) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        themeEmoticon = obj;
      }
      let requestsPending: TLInt | undefined;
      if ((flags.value & 268435456) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        requestsPending = obj;
      }
      let recentRequesters: TLVector<TLLong> | undefined;
      if ((flags.value & 268435456) !== 0) {
        const obj = TLVector.deserialized(_data, TLLong);
        if (!obj) return undefined;
        recentRequesters = obj;
      }
      let defaultSendAs: PeerType | undefined;
      if ((flags.value & 536870912) !== 0) {
        const obj = TLObject.deserialized(_data) as PeerType;
        if (!obj) return undefined;
        defaultSendAs = obj;
      }
      let availableReactions: ChatReactionsType | undefined;
      if ((flags.value & 1073741824) !== 0) {
        const obj = TLObject.deserialized(_data) as ChatReactionsType;
        if (!obj) return undefined;
        availableReactions = obj;
      }
      let stories: API.PeerStories | undefined;
      if ((flags2.value & 16) !== 0) {
        const obj = TLObject.deserialized(_data) as API.PeerStories;
        if (!obj) return undefined;
        stories = obj;
      }

      return new ChannelFull(
        canViewParticipants,
        canSetUsername,
        canSetStickers,
        hiddenPrehistory,
        canSetLocation,
        hasScheduled,
        canViewStats,
        blocked,
        canDeleteChannel,
        antispam,
        participantsHidden,
        translationsDisabled,
        storiesPinnedAvailable,
        id,
        about,
        participantsCount,
        adminsCount,
        kickedCount,
        bannedCount,
        onlineCount,
        readInboxMaxId,
        readOutboxMaxId,
        unreadCount,
        chatPhoto,
        notifySettings,
        exportedInvite,
        botInfo,
        migratedFromChatId,
        migratedFromMaxId,
        pinnedMsgId,
        stickerset,
        availableMinId,
        folderId,
        linkedChatId,
        location,
        slowmodeSeconds,
        slowmodeNextSendDate,
        statsDc,
        pts,
        call,
        ttlPeriod,
        pendingSuggestions,
        groupcallDefaultJoinAs,
        themeEmoticon,
        requestsPending,
        recentRequesters,
        defaultSendAs,
        availableReactions,
        stories
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelFull.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.canViewParticipants) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.canSetUsername) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      if (this.canSetStickers) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.hiddenPrehistory) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }

      if (this.canSetLocation) {
        flags.set(65536);
      } else {
        flags.unset(65536);
      }

      if (this.hasScheduled) {
        flags.set(524288);
      } else {
        flags.unset(524288);
      }

      if (this.canViewStats) {
        flags.set(1048576);
      } else {
        flags.unset(1048576);
      }

      if (this.blocked) {
        flags.set(4194304);
      } else {
        flags.unset(4194304);
      }

      const flags2 = new TLFlags();
      _data.push(flags2);
      if (this.canDeleteChannel) {
        flags2.set(1);
      } else {
        flags2.unset(1);
      }

      if (this.antispam) {
        flags2.set(2);
      } else {
        flags2.unset(2);
      }

      if (this.participantsHidden) {
        flags2.set(4);
      } else {
        flags2.unset(4);
      }

      if (this.translationsDisabled) {
        flags2.set(8);
      } else {
        flags2.unset(8);
      }

      if (this.storiesPinnedAvailable) {
        flags2.set(32);
      } else {
        flags2.unset(32);
      }

      _data.push(this.id);
      _data.push(this.about);
      if (this.participantsCount) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.participantsCount) _data.push(this.participantsCount);

      if (this.adminsCount) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.adminsCount) _data.push(this.adminsCount);

      if (this.kickedCount) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.kickedCount) _data.push(this.kickedCount);

      if (this.bannedCount) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.bannedCount) _data.push(this.bannedCount);

      if (this.onlineCount) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }
      if (this.onlineCount) _data.push(this.onlineCount);

      _data.push(this.readInboxMaxId);
      _data.push(this.readOutboxMaxId);
      _data.push(this.unreadCount);
      _data.push(this.chatPhoto);
      _data.push(this.notifySettings);
      if (this.exportedInvite) {
        flags.set(8388608);
      } else {
        flags.unset(8388608);
      }
      if (this.exportedInvite) _data.push(this.exportedInvite);

      _data.push(this.botInfo);
      if (this.migratedFromChatId) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.migratedFromChatId) _data.push(this.migratedFromChatId);

      if (this.migratedFromMaxId) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.migratedFromMaxId) _data.push(this.migratedFromMaxId);

      if (this.pinnedMsgId) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.pinnedMsgId) _data.push(this.pinnedMsgId);

      if (this.stickerset) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.stickerset) _data.push(this.stickerset);

      if (this.availableMinId) {
        flags.set(512);
      } else {
        flags.unset(512);
      }
      if (this.availableMinId) _data.push(this.availableMinId);

      if (this.folderId) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }
      if (this.folderId) _data.push(this.folderId);

      if (this.linkedChatId) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }
      if (this.linkedChatId) _data.push(this.linkedChatId);

      if (this.location) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }
      if (this.location) _data.push(this.location);

      if (this.slowmodeSeconds) {
        flags.set(131072);
      } else {
        flags.unset(131072);
      }
      if (this.slowmodeSeconds) _data.push(this.slowmodeSeconds);

      if (this.slowmodeNextSendDate) {
        flags.set(262144);
      } else {
        flags.unset(262144);
      }
      if (this.slowmodeNextSendDate) _data.push(this.slowmodeNextSendDate);

      if (this.statsDc) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }
      if (this.statsDc) _data.push(this.statsDc);

      _data.push(this.pts);
      if (this.call) {
        flags.set(2097152);
      } else {
        flags.unset(2097152);
      }
      if (this.call) _data.push(this.call);

      if (this.ttlPeriod) {
        flags.set(16777216);
      } else {
        flags.unset(16777216);
      }
      if (this.ttlPeriod) _data.push(this.ttlPeriod);

      if (this.pendingSuggestions) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }
      if (this.pendingSuggestions) _data.push(this.pendingSuggestions);

      if (this.groupcallDefaultJoinAs) {
        flags.set(67108864);
      } else {
        flags.unset(67108864);
      }
      if (this.groupcallDefaultJoinAs) _data.push(this.groupcallDefaultJoinAs);

      if (this.themeEmoticon) {
        flags.set(134217728);
      } else {
        flags.unset(134217728);
      }
      if (this.themeEmoticon) _data.push(this.themeEmoticon);

      if (this.requestsPending) {
        flags.set(268435456);
      } else {
        flags.unset(268435456);
      }
      if (this.requestsPending) _data.push(this.requestsPending);

      if (this.recentRequesters) {
        flags.set(268435456);
      } else {
        flags.unset(268435456);
      }
      if (this.recentRequesters) _data.push(this.recentRequesters);

      if (this.defaultSendAs) {
        flags.set(536870912);
      } else {
        flags.unset(536870912);
      }
      if (this.defaultSendAs) _data.push(this.defaultSendAs);

      if (this.availableReactions) {
        flags.set(1073741824);
      } else {
        flags.unset(1073741824);
      }
      if (this.availableReactions) _data.push(this.availableReactions);

      if (this.stories) {
        flags2.set(16);
      } else {
        flags2.unset(16);
      }
      if (this.stories) _data.push(this.stories);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly canViewParticipants: boolean | undefined,
      readonly canSetUsername: boolean | undefined,
      readonly canSetStickers: boolean | undefined,
      readonly hiddenPrehistory: boolean | undefined,
      readonly canSetLocation: boolean | undefined,
      readonly hasScheduled: boolean | undefined,
      readonly canViewStats: boolean | undefined,
      readonly blocked: boolean | undefined,
      readonly canDeleteChannel: boolean | undefined,
      readonly antispam: boolean | undefined,
      readonly participantsHidden: boolean | undefined,
      readonly translationsDisabled: boolean | undefined,
      readonly storiesPinnedAvailable: boolean | undefined,
      readonly id: TLLong,
      readonly about: TLString,
      readonly participantsCount: TLInt | undefined,
      readonly adminsCount: TLInt | undefined,
      readonly kickedCount: TLInt | undefined,
      readonly bannedCount: TLInt | undefined,
      readonly onlineCount: TLInt | undefined,
      readonly readInboxMaxId: TLInt,
      readonly readOutboxMaxId: TLInt,
      readonly unreadCount: TLInt,
      readonly chatPhoto: PhotoType,
      readonly notifySettings: API.PeerNotifySettings,
      readonly exportedInvite: ExportedChatInviteType | undefined,
      readonly botInfo: TLVector<API.BotInfo>,
      readonly migratedFromChatId: TLLong | undefined,
      readonly migratedFromMaxId: TLInt | undefined,
      readonly pinnedMsgId: TLInt | undefined,
      readonly stickerset: API.StickerSet | undefined,
      readonly availableMinId: TLInt | undefined,
      readonly folderId: TLInt | undefined,
      readonly linkedChatId: TLLong | undefined,
      readonly location: ChannelLocationType | undefined,
      readonly slowmodeSeconds: TLInt | undefined,
      readonly slowmodeNextSendDate: TLInt | undefined,
      readonly statsDc: TLInt | undefined,
      readonly pts: TLInt,
      readonly call: API.InputGroupCall | undefined,
      readonly ttlPeriod: TLInt | undefined,
      readonly pendingSuggestions: TLVector<TLString> | undefined,
      readonly groupcallDefaultJoinAs: PeerType | undefined,
      readonly themeEmoticon: TLString | undefined,
      readonly requestsPending: TLInt | undefined,
      readonly recentRequesters: TLVector<TLLong> | undefined,
      readonly defaultSendAs: PeerType | undefined,
      readonly availableReactions: ChatReactionsType | undefined,
      readonly stories: API.PeerStories | undefined
    ) {}
  }

  export class ChatParticipant implements TLObject {
    static readonly cons = new TLInt(0xc02d4007);

    static deserialized(_data: ByteStream): ChatParticipant | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatParticipant.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const inviterId = TLLong.deserialized(_data);
      if (!inviterId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new ChatParticipant(userId, inviterId, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatParticipant.cons];

      _data.push(this.userId);
      _data.push(this.inviterId);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: TLLong,
      readonly inviterId: TLLong,
      readonly date: TLInt
    ) {}
  }

  export class ChatParticipantCreator implements TLObject {
    static readonly cons = new TLInt(0xe46bcee4);

    static deserialized(_data: ByteStream): ChatParticipantCreator | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatParticipantCreator.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;

      return new ChatParticipantCreator(userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatParticipantCreator.cons];

      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong) {}
  }

  export class ChatParticipantAdmin implements TLObject {
    static readonly cons = new TLInt(0xa0933f5b);

    static deserialized(_data: ByteStream): ChatParticipantAdmin | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatParticipantAdmin.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const inviterId = TLLong.deserialized(_data);
      if (!inviterId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new ChatParticipantAdmin(userId, inviterId, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatParticipantAdmin.cons];

      _data.push(this.userId);
      _data.push(this.inviterId);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: TLLong,
      readonly inviterId: TLLong,
      readonly date: TLInt
    ) {}
  }

  export class ChatParticipantsForbidden implements TLObject {
    static readonly cons = new TLInt(0x8763d3e1);

    static deserialized(
      _data: ByteStream
    ): ChatParticipantsForbidden | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatParticipantsForbidden.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;
      let selfParticipant: ChatParticipantType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as ChatParticipantType;
        if (!obj) return undefined;
        selfParticipant = obj;
      }

      return new ChatParticipantsForbidden(chatId, selfParticipant);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatParticipantsForbidden.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.chatId);
      if (this.selfParticipant) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.selfParticipant) _data.push(this.selfParticipant);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly chatId: TLLong,
      readonly selfParticipant: ChatParticipantType | undefined
    ) {}
  }

  export class ChatParticipants implements TLObject {
    static readonly cons = new TLInt(0x3cbc93f8);

    static deserialized(_data: ByteStream): ChatParticipants | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatParticipants.cons))
        return undefined;

      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;
      const participants = TLVector.deserialized(
        _data
      ) as TLVector<ChatParticipantType>;
      if (!participants) return undefined;
      const version = TLInt.deserialized(_data);
      if (!version) return undefined;

      return new ChatParticipants(chatId, participants, version);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatParticipants.cons];

      _data.push(this.chatId);
      _data.push(this.participants);
      _data.push(this.version);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly chatId: TLLong,
      readonly participants: TLVector<ChatParticipantType>,
      readonly version: TLInt
    ) {}
  }

  export class ChatPhotoEmpty implements TLObject {
    static readonly cons = new TLInt(0x37c1011c);

    static deserialized(_data: ByteStream): ChatPhotoEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatPhotoEmpty.cons))
        return undefined;

      return new ChatPhotoEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatPhotoEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChatPhoto implements TLObject {
    static readonly cons = new TLInt(0x1c6e1c11);

    static deserialized(_data: ByteStream): ChatPhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatPhoto.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const hasVideo = (flags.value & 1) !== 0;
      const photoId = TLLong.deserialized(_data);
      if (!photoId) return undefined;
      let strippedThumb: TLBytes | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLBytes.deserialized(_data);
        if (!obj) return undefined;
        strippedThumb = obj;
      }
      const dcId = TLInt.deserialized(_data);
      if (!dcId) return undefined;

      return new ChatPhoto(hasVideo, photoId, strippedThumb, dcId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatPhoto.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.hasVideo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.photoId);
      if (this.strippedThumb) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.strippedThumb) _data.push(this.strippedThumb);

      _data.push(this.dcId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly hasVideo: boolean | undefined,
      readonly photoId: TLLong,
      readonly strippedThumb: TLBytes | undefined,
      readonly dcId: TLInt
    ) {}
  }

  export class MessageEmpty implements TLObject {
    static readonly cons = new TLInt(0x90a6ca84);

    static deserialized(_data: ByteStream): MessageEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEmpty.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      let peerId: PeerType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as PeerType;
        if (!obj) return undefined;
        peerId = obj;
      }

      return new MessageEmpty(id, peerId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEmpty.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.id);
      if (this.peerId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.peerId) _data.push(this.peerId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLInt, readonly peerId: PeerType | undefined) {}
  }

  export class Message implements TLObject {
    static readonly cons = new TLInt(0x38116ee0);

    static deserialized(_data: ByteStream): Message | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Message.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const out = (flags.value & 2) !== 0;
      const mentioned = (flags.value & 16) !== 0;
      const mediaUnread = (flags.value & 32) !== 0;
      const silent = (flags.value & 8192) !== 0;
      const post = (flags.value & 16384) !== 0;
      const fromScheduled = (flags.value & 262144) !== 0;
      const legacy = (flags.value & 524288) !== 0;
      const editHide = (flags.value & 2097152) !== 0;
      const pinned = (flags.value & 16777216) !== 0;
      const noforwards = (flags.value & 67108864) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      let fromId: PeerType | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLObject.deserialized(_data) as PeerType;
        if (!obj) return undefined;
        fromId = obj;
      }
      const peerId = TLObject.deserialized(_data) as PeerType;
      if (!peerId) return undefined;
      let fwdFrom: API.MessageFwdHeader | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as API.MessageFwdHeader;
        if (!obj) return undefined;
        fwdFrom = obj;
      }
      let viaBotId: TLLong | undefined;
      if ((flags.value & 2048) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        viaBotId = obj;
      }
      let replyTo: MessageReplyHeaderType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as MessageReplyHeaderType;
        if (!obj) return undefined;
        replyTo = obj;
      }
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const message = TLString.deserialized(_data);
      if (!message) return undefined;
      let media: MessageMediaType | undefined;
      if ((flags.value & 512) !== 0) {
        const obj = TLObject.deserialized(_data) as MessageMediaType;
        if (!obj) return undefined;
        media = obj;
      }
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }
      let views: TLInt | undefined;
      if ((flags.value & 1024) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        views = obj;
      }
      let forwards: TLInt | undefined;
      if ((flags.value & 1024) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        forwards = obj;
      }
      let replies: API.MessageReplies | undefined;
      if ((flags.value & 8388608) !== 0) {
        const obj = TLObject.deserialized(_data) as API.MessageReplies;
        if (!obj) return undefined;
        replies = obj;
      }
      let editDate: TLInt | undefined;
      if ((flags.value & 32768) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        editDate = obj;
      }
      let postAuthor: TLString | undefined;
      if ((flags.value & 65536) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        postAuthor = obj;
      }
      let groupedId: TLLong | undefined;
      if ((flags.value & 131072) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        groupedId = obj;
      }
      let reactions: API.MessageReactions | undefined;
      if ((flags.value & 1048576) !== 0) {
        const obj = TLObject.deserialized(_data) as API.MessageReactions;
        if (!obj) return undefined;
        reactions = obj;
      }
      let restrictionReason: TLVector<API.RestrictionReason> | undefined;
      if ((flags.value & 4194304) !== 0) {
        const obj = TLVector.deserialized(_data, API.RestrictionReason);
        if (!obj) return undefined;
        restrictionReason = obj;
      }
      let ttlPeriod: TLInt | undefined;
      if ((flags.value & 33554432) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlPeriod = obj;
      }

      return new Message(
        out,
        mentioned,
        mediaUnread,
        silent,
        post,
        fromScheduled,
        legacy,
        editHide,
        pinned,
        noforwards,
        id,
        fromId,
        peerId,
        fwdFrom,
        viaBotId,
        replyTo,
        date,
        message,
        media,
        replyMarkup,
        entities,
        views,
        forwards,
        replies,
        editDate,
        postAuthor,
        groupedId,
        reactions,
        restrictionReason,
        ttlPeriod
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Message.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.out) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.mentioned) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.mediaUnread) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.silent) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }

      if (this.post) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }

      if (this.fromScheduled) {
        flags.set(262144);
      } else {
        flags.unset(262144);
      }

      if (this.legacy) {
        flags.set(524288);
      } else {
        flags.unset(524288);
      }

      if (this.editHide) {
        flags.set(2097152);
      } else {
        flags.unset(2097152);
      }

      if (this.pinned) {
        flags.set(16777216);
      } else {
        flags.unset(16777216);
      }

      if (this.noforwards) {
        flags.set(67108864);
      } else {
        flags.unset(67108864);
      }

      _data.push(this.id);
      if (this.fromId) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.fromId) _data.push(this.fromId);

      _data.push(this.peerId);
      if (this.fwdFrom) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.fwdFrom) _data.push(this.fwdFrom);

      if (this.viaBotId) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }
      if (this.viaBotId) _data.push(this.viaBotId);

      if (this.replyTo) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.replyTo) _data.push(this.replyTo);

      _data.push(this.date);
      _data.push(this.message);
      if (this.media) {
        flags.set(512);
      } else {
        flags.unset(512);
      }
      if (this.media) _data.push(this.media);

      if (this.replyMarkup) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      if (this.entities) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.entities) _data.push(this.entities);

      if (this.views) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }
      if (this.views) _data.push(this.views);

      if (this.forwards) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }
      if (this.forwards) _data.push(this.forwards);

      if (this.replies) {
        flags.set(8388608);
      } else {
        flags.unset(8388608);
      }
      if (this.replies) _data.push(this.replies);

      if (this.editDate) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }
      if (this.editDate) _data.push(this.editDate);

      if (this.postAuthor) {
        flags.set(65536);
      } else {
        flags.unset(65536);
      }
      if (this.postAuthor) _data.push(this.postAuthor);

      if (this.groupedId) {
        flags.set(131072);
      } else {
        flags.unset(131072);
      }
      if (this.groupedId) _data.push(this.groupedId);

      if (this.reactions) {
        flags.set(1048576);
      } else {
        flags.unset(1048576);
      }
      if (this.reactions) _data.push(this.reactions);

      if (this.restrictionReason) {
        flags.set(4194304);
      } else {
        flags.unset(4194304);
      }
      if (this.restrictionReason) _data.push(this.restrictionReason);

      if (this.ttlPeriod) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }
      if (this.ttlPeriod) _data.push(this.ttlPeriod);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly out: boolean | undefined,
      readonly mentioned: boolean | undefined,
      readonly mediaUnread: boolean | undefined,
      readonly silent: boolean | undefined,
      readonly post: boolean | undefined,
      readonly fromScheduled: boolean | undefined,
      readonly legacy: boolean | undefined,
      readonly editHide: boolean | undefined,
      readonly pinned: boolean | undefined,
      readonly noforwards: boolean | undefined,
      readonly id: TLInt,
      readonly fromId: PeerType | undefined,
      readonly peerId: PeerType,
      readonly fwdFrom: API.MessageFwdHeader | undefined,
      readonly viaBotId: TLLong | undefined,
      readonly replyTo: MessageReplyHeaderType | undefined,
      readonly date: TLInt,
      readonly message: TLString,
      readonly media: MessageMediaType | undefined,
      readonly replyMarkup: ReplyMarkupType | undefined,
      readonly entities: TLVector<MessageEntityType> | undefined,
      readonly views: TLInt | undefined,
      readonly forwards: TLInt | undefined,
      readonly replies: API.MessageReplies | undefined,
      readonly editDate: TLInt | undefined,
      readonly postAuthor: TLString | undefined,
      readonly groupedId: TLLong | undefined,
      readonly reactions: API.MessageReactions | undefined,
      readonly restrictionReason: TLVector<API.RestrictionReason> | undefined,
      readonly ttlPeriod: TLInt | undefined
    ) {}
  }

  export class MessageService implements TLObject {
    static readonly cons = new TLInt(0x2b085862);

    static deserialized(_data: ByteStream): MessageService | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageService.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const out = (flags.value & 2) !== 0;
      const mentioned = (flags.value & 16) !== 0;
      const mediaUnread = (flags.value & 32) !== 0;
      const silent = (flags.value & 8192) !== 0;
      const post = (flags.value & 16384) !== 0;
      const legacy = (flags.value & 524288) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      let fromId: PeerType | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLObject.deserialized(_data) as PeerType;
        if (!obj) return undefined;
        fromId = obj;
      }
      const peerId = TLObject.deserialized(_data) as PeerType;
      if (!peerId) return undefined;
      let replyTo: MessageReplyHeaderType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as MessageReplyHeaderType;
        if (!obj) return undefined;
        replyTo = obj;
      }
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const action = TLObject.deserialized(_data) as MessageActionType;
      if (!action) return undefined;
      let ttlPeriod: TLInt | undefined;
      if ((flags.value & 33554432) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlPeriod = obj;
      }

      return new MessageService(
        out,
        mentioned,
        mediaUnread,
        silent,
        post,
        legacy,
        id,
        fromId,
        peerId,
        replyTo,
        date,
        action,
        ttlPeriod
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageService.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.out) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.mentioned) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.mediaUnread) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.silent) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }

      if (this.post) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }

      if (this.legacy) {
        flags.set(524288);
      } else {
        flags.unset(524288);
      }

      _data.push(this.id);
      if (this.fromId) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.fromId) _data.push(this.fromId);

      _data.push(this.peerId);
      if (this.replyTo) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.replyTo) _data.push(this.replyTo);

      _data.push(this.date);
      _data.push(this.action);
      if (this.ttlPeriod) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }
      if (this.ttlPeriod) _data.push(this.ttlPeriod);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly out: boolean | undefined,
      readonly mentioned: boolean | undefined,
      readonly mediaUnread: boolean | undefined,
      readonly silent: boolean | undefined,
      readonly post: boolean | undefined,
      readonly legacy: boolean | undefined,
      readonly id: TLInt,
      readonly fromId: PeerType | undefined,
      readonly peerId: PeerType,
      readonly replyTo: MessageReplyHeaderType | undefined,
      readonly date: TLInt,
      readonly action: MessageActionType,
      readonly ttlPeriod: TLInt | undefined
    ) {}
  }

  export class MessageMediaEmpty implements TLObject {
    static readonly cons = new TLInt(0x3ded6320);

    static deserialized(_data: ByteStream): MessageMediaEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaEmpty.cons))
        return undefined;

      return new MessageMediaEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class MessageMediaPhoto implements TLObject {
    static readonly cons = new TLInt(0x695150d7);

    static deserialized(_data: ByteStream): MessageMediaPhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaPhoto.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const spoiler = (flags.value & 8) !== 0;
      let photo: PhotoType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as PhotoType;
        if (!obj) return undefined;
        photo = obj;
      }
      let ttlSeconds: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlSeconds = obj;
      }

      return new MessageMediaPhoto(spoiler, photo, ttlSeconds);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaPhoto.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.spoiler) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.photo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.photo) _data.push(this.photo);

      if (this.ttlSeconds) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.ttlSeconds) _data.push(this.ttlSeconds);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly spoiler: boolean | undefined,
      readonly photo: PhotoType | undefined,
      readonly ttlSeconds: TLInt | undefined
    ) {}
  }

  export class MessageMediaGeo implements TLObject {
    static readonly cons = new TLInt(0x56e0d474);

    static deserialized(_data: ByteStream): MessageMediaGeo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaGeo.cons))
        return undefined;

      const geo = TLObject.deserialized(_data) as GeoPointType;
      if (!geo) return undefined;

      return new MessageMediaGeo(geo);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaGeo.cons];

      _data.push(this.geo);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly geo: GeoPointType) {}
  }

  export class MessageMediaContact implements TLObject {
    static readonly cons = new TLInt(0x70322949);

    static deserialized(_data: ByteStream): MessageMediaContact | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaContact.cons))
        return undefined;

      const phoneNumber = TLString.deserialized(_data);
      if (!phoneNumber) return undefined;
      const firstName = TLString.deserialized(_data);
      if (!firstName) return undefined;
      const lastName = TLString.deserialized(_data);
      if (!lastName) return undefined;
      const vcard = TLString.deserialized(_data);
      if (!vcard) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;

      return new MessageMediaContact(
        phoneNumber,
        firstName,
        lastName,
        vcard,
        userId
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaContact.cons];

      _data.push(this.phoneNumber);
      _data.push(this.firstName);
      _data.push(this.lastName);
      _data.push(this.vcard);
      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly phoneNumber: TLString,
      readonly firstName: TLString,
      readonly lastName: TLString,
      readonly vcard: TLString,
      readonly userId: TLLong
    ) {}
  }

  export class MessageMediaUnsupported implements TLObject {
    static readonly cons = new TLInt(0x9f84f49e);

    static deserialized(
      _data: ByteStream
    ): MessageMediaUnsupported | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaUnsupported.cons))
        return undefined;

      return new MessageMediaUnsupported();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaUnsupported.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class MessageMediaDocument implements TLObject {
    static readonly cons = new TLInt(0x4cf4d72d);

    static deserialized(_data: ByteStream): MessageMediaDocument | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaDocument.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const nopremium = (flags.value & 8) !== 0;
      const spoiler = (flags.value & 16) !== 0;
      let document: DocumentType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as DocumentType;
        if (!obj) return undefined;
        document = obj;
      }
      let altDocument: DocumentType | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLObject.deserialized(_data) as DocumentType;
        if (!obj) return undefined;
        altDocument = obj;
      }
      let ttlSeconds: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlSeconds = obj;
      }

      return new MessageMediaDocument(
        nopremium,
        spoiler,
        document,
        altDocument,
        ttlSeconds
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaDocument.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.nopremium) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.spoiler) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.document) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.document) _data.push(this.document);

      if (this.altDocument) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.altDocument) _data.push(this.altDocument);

      if (this.ttlSeconds) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.ttlSeconds) _data.push(this.ttlSeconds);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly nopremium: boolean | undefined,
      readonly spoiler: boolean | undefined,
      readonly document: DocumentType | undefined,
      readonly altDocument: DocumentType | undefined,
      readonly ttlSeconds: TLInt | undefined
    ) {}
  }

  export class MessageMediaWebPage implements TLObject {
    static readonly cons = new TLInt(0xa32dd600);

    static deserialized(_data: ByteStream): MessageMediaWebPage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaWebPage.cons))
        return undefined;

      const webpage = TLObject.deserialized(_data) as WebPageType;
      if (!webpage) return undefined;

      return new MessageMediaWebPage(webpage);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaWebPage.cons];

      _data.push(this.webpage);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly webpage: WebPageType) {}
  }

  export class MessageMediaVenue implements TLObject {
    static readonly cons = new TLInt(0x2ec0533f);

    static deserialized(_data: ByteStream): MessageMediaVenue | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaVenue.cons))
        return undefined;

      const geo = TLObject.deserialized(_data) as GeoPointType;
      if (!geo) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const address = TLString.deserialized(_data);
      if (!address) return undefined;
      const provider = TLString.deserialized(_data);
      if (!provider) return undefined;
      const venueId = TLString.deserialized(_data);
      if (!venueId) return undefined;
      const venueType = TLString.deserialized(_data);
      if (!venueType) return undefined;

      return new MessageMediaVenue(
        geo,
        title,
        address,
        provider,
        venueId,
        venueType
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaVenue.cons];

      _data.push(this.geo);
      _data.push(this.title);
      _data.push(this.address);
      _data.push(this.provider);
      _data.push(this.venueId);
      _data.push(this.venueType);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly geo: GeoPointType,
      readonly title: TLString,
      readonly address: TLString,
      readonly provider: TLString,
      readonly venueId: TLString,
      readonly venueType: TLString
    ) {}
  }

  export class MessageMediaGame implements TLObject {
    static readonly cons = new TLInt(0xfdb19008);

    static deserialized(_data: ByteStream): MessageMediaGame | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaGame.cons))
        return undefined;

      const game = API.Game.deserialized(_data);
      if (!game) return undefined;

      return new MessageMediaGame(game);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaGame.cons];

      _data.push(this.game);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly game: API.Game) {}
  }

  export class MessageMediaInvoice implements TLObject {
    static readonly cons = new TLInt(0xf6a548d3);

    static deserialized(_data: ByteStream): MessageMediaInvoice | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaInvoice.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const shippingAddressRequested = (flags.value & 2) !== 0;
      const test = (flags.value & 8) !== 0;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const description = TLString.deserialized(_data);
      if (!description) return undefined;
      let photo: WebDocumentType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as WebDocumentType;
        if (!obj) return undefined;
        photo = obj;
      }
      let receiptMsgId: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        receiptMsgId = obj;
      }
      const currency = TLString.deserialized(_data);
      if (!currency) return undefined;
      const totalAmount = TLLong.deserialized(_data);
      if (!totalAmount) return undefined;
      const startParam = TLString.deserialized(_data);
      if (!startParam) return undefined;
      let extendedMedia: MessageExtendedMediaType | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLObject.deserialized(_data) as MessageExtendedMediaType;
        if (!obj) return undefined;
        extendedMedia = obj;
      }

      return new MessageMediaInvoice(
        shippingAddressRequested,
        test,
        title,
        description,
        photo,
        receiptMsgId,
        currency,
        totalAmount,
        startParam,
        extendedMedia
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaInvoice.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.shippingAddressRequested) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.test) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      _data.push(this.title);
      _data.push(this.description);
      if (this.photo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.photo) _data.push(this.photo);

      if (this.receiptMsgId) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.receiptMsgId) _data.push(this.receiptMsgId);

      _data.push(this.currency);
      _data.push(this.totalAmount);
      _data.push(this.startParam);
      if (this.extendedMedia) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.extendedMedia) _data.push(this.extendedMedia);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly shippingAddressRequested: boolean | undefined,
      readonly test: boolean | undefined,
      readonly title: TLString,
      readonly description: TLString,
      readonly photo: WebDocumentType | undefined,
      readonly receiptMsgId: TLInt | undefined,
      readonly currency: TLString,
      readonly totalAmount: TLLong,
      readonly startParam: TLString,
      readonly extendedMedia: MessageExtendedMediaType | undefined
    ) {}
  }

  export class MessageMediaGeoLive implements TLObject {
    static readonly cons = new TLInt(0xb940c666);

    static deserialized(_data: ByteStream): MessageMediaGeoLive | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaGeoLive.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const geo = TLObject.deserialized(_data) as GeoPointType;
      if (!geo) return undefined;
      let heading: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        heading = obj;
      }
      const period = TLInt.deserialized(_data);
      if (!period) return undefined;
      let proximityNotificationRadius: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        proximityNotificationRadius = obj;
      }

      return new MessageMediaGeoLive(
        geo,
        heading,
        period,
        proximityNotificationRadius
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaGeoLive.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.geo);
      if (this.heading) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.heading) _data.push(this.heading);

      _data.push(this.period);
      if (this.proximityNotificationRadius) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.proximityNotificationRadius)
        _data.push(this.proximityNotificationRadius);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly geo: GeoPointType,
      readonly heading: TLInt | undefined,
      readonly period: TLInt,
      readonly proximityNotificationRadius: TLInt | undefined
    ) {}
  }

  export class MessageMediaPoll implements TLObject {
    static readonly cons = new TLInt(0x4bd6e798);

    static deserialized(_data: ByteStream): MessageMediaPoll | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaPoll.cons))
        return undefined;

      const poll = API.Poll.deserialized(_data);
      if (!poll) return undefined;
      const results = API.PollResults.deserialized(_data);
      if (!results) return undefined;

      return new MessageMediaPoll(poll, results);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaPoll.cons];

      _data.push(this.poll);
      _data.push(this.results);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly poll: API.Poll, readonly results: API.PollResults) {}
  }

  export class MessageMediaDice implements TLObject {
    static readonly cons = new TLInt(0x3f7ee58b);

    static deserialized(_data: ByteStream): MessageMediaDice | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaDice.cons))
        return undefined;

      const value = TLInt.deserialized(_data);
      if (!value) return undefined;
      const emoticon = TLString.deserialized(_data);
      if (!emoticon) return undefined;

      return new MessageMediaDice(value, emoticon);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaDice.cons];

      _data.push(this.value);
      _data.push(this.emoticon);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly value: TLInt, readonly emoticon: TLString) {}
  }

  export class MessageMediaStory implements TLObject {
    static readonly cons = new TLInt(0x68cb6283);

    static deserialized(_data: ByteStream): MessageMediaStory | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageMediaStory.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const viaMention = (flags.value & 2) !== 0;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      let story: StoryItemType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as StoryItemType;
        if (!obj) return undefined;
        story = obj;
      }

      return new MessageMediaStory(viaMention, peer, id, story);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageMediaStory.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.viaMention) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.peer);
      _data.push(this.id);
      if (this.story) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.story) _data.push(this.story);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly viaMention: boolean | undefined,
      readonly peer: PeerType,
      readonly id: TLInt,
      readonly story: StoryItemType | undefined
    ) {}
  }

  export class MessageActionEmpty implements TLObject {
    static readonly cons = new TLInt(0xb6aef7b0);

    static deserialized(_data: ByteStream): MessageActionEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionEmpty.cons))
        return undefined;

      return new MessageActionEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class MessageActionChatCreate implements TLObject {
    static readonly cons = new TLInt(0xbd47cbad);

    static deserialized(
      _data: ByteStream
    ): MessageActionChatCreate | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionChatCreate.cons))
        return undefined;

      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const users = TLVector.deserialized(_data, TLLong);
      if (!users) return undefined;

      return new MessageActionChatCreate(title, users);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionChatCreate.cons];

      _data.push(this.title);
      _data.push(this.users);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly title: TLString, readonly users: TLVector<TLLong>) {}
  }

  export class MessageActionChatEditTitle implements TLObject {
    static readonly cons = new TLInt(0xb5a1ce5a);

    static deserialized(
      _data: ByteStream
    ): MessageActionChatEditTitle | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionChatEditTitle.cons))
        return undefined;

      const title = TLString.deserialized(_data);
      if (!title) return undefined;

      return new MessageActionChatEditTitle(title);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionChatEditTitle.cons];

      _data.push(this.title);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly title: TLString) {}
  }

  export class MessageActionChatEditPhoto implements TLObject {
    static readonly cons = new TLInt(0x7fcb13a8);

    static deserialized(
      _data: ByteStream
    ): MessageActionChatEditPhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionChatEditPhoto.cons))
        return undefined;

      const photo = TLObject.deserialized(_data) as PhotoType;
      if (!photo) return undefined;

      return new MessageActionChatEditPhoto(photo);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionChatEditPhoto.cons];

      _data.push(this.photo);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly photo: PhotoType) {}
  }

  export class MessageActionChatDeletePhoto implements TLObject {
    static readonly cons = new TLInt(0x95e3fbef);

    static deserialized(
      _data: ByteStream
    ): MessageActionChatDeletePhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionChatDeletePhoto.cons)
      )
        return undefined;

      return new MessageActionChatDeletePhoto();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionChatDeletePhoto.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class MessageActionChatAddUser implements TLObject {
    static readonly cons = new TLInt(0x15cefd00);

    static deserialized(
      _data: ByteStream
    ): MessageActionChatAddUser | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionChatAddUser.cons))
        return undefined;

      const users = TLVector.deserialized(_data, TLLong);
      if (!users) return undefined;

      return new MessageActionChatAddUser(users);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionChatAddUser.cons];

      _data.push(this.users);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly users: TLVector<TLLong>) {}
  }

  export class MessageActionChatDeleteUser implements TLObject {
    static readonly cons = new TLInt(0xa43f30cc);

    static deserialized(
      _data: ByteStream
    ): MessageActionChatDeleteUser | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionChatDeleteUser.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;

      return new MessageActionChatDeleteUser(userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionChatDeleteUser.cons];

      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong) {}
  }

  export class MessageActionChatJoinedByLink implements TLObject {
    static readonly cons = new TLInt(0x31224c3);

    static deserialized(
      _data: ByteStream
    ): MessageActionChatJoinedByLink | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionChatJoinedByLink.cons)
      )
        return undefined;

      const inviterId = TLLong.deserialized(_data);
      if (!inviterId) return undefined;

      return new MessageActionChatJoinedByLink(inviterId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionChatJoinedByLink.cons];

      _data.push(this.inviterId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly inviterId: TLLong) {}
  }

  export class MessageActionChannelCreate implements TLObject {
    static readonly cons = new TLInt(0x95d2ac92);

    static deserialized(
      _data: ByteStream
    ): MessageActionChannelCreate | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionChannelCreate.cons))
        return undefined;

      const title = TLString.deserialized(_data);
      if (!title) return undefined;

      return new MessageActionChannelCreate(title);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionChannelCreate.cons];

      _data.push(this.title);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly title: TLString) {}
  }

  export class MessageActionChatMigrateTo implements TLObject {
    static readonly cons = new TLInt(0xe1037f92);

    static deserialized(
      _data: ByteStream
    ): MessageActionChatMigrateTo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionChatMigrateTo.cons))
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;

      return new MessageActionChatMigrateTo(channelId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionChatMigrateTo.cons];

      _data.push(this.channelId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly channelId: TLLong) {}
  }

  export class MessageActionChannelMigrateFrom implements TLObject {
    static readonly cons = new TLInt(0xea3948e9);

    static deserialized(
      _data: ByteStream
    ): MessageActionChannelMigrateFrom | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionChannelMigrateFrom.cons)
      )
        return undefined;

      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;

      return new MessageActionChannelMigrateFrom(title, chatId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        MessageActionChannelMigrateFrom.cons,
      ];

      _data.push(this.title);
      _data.push(this.chatId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly title: TLString, readonly chatId: TLLong) {}
  }

  export class MessageActionPinMessage implements TLObject {
    static readonly cons = new TLInt(0x94bd38ed);

    static deserialized(
      _data: ByteStream
    ): MessageActionPinMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionPinMessage.cons))
        return undefined;

      return new MessageActionPinMessage();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionPinMessage.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class MessageActionHistoryClear implements TLObject {
    static readonly cons = new TLInt(0x9fbab604);

    static deserialized(
      _data: ByteStream
    ): MessageActionHistoryClear | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionHistoryClear.cons))
        return undefined;

      return new MessageActionHistoryClear();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionHistoryClear.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class MessageActionGameScore implements TLObject {
    static readonly cons = new TLInt(0x92a72876);

    static deserialized(_data: ByteStream): MessageActionGameScore | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionGameScore.cons))
        return undefined;

      const gameId = TLLong.deserialized(_data);
      if (!gameId) return undefined;
      const score = TLInt.deserialized(_data);
      if (!score) return undefined;

      return new MessageActionGameScore(gameId, score);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionGameScore.cons];

      _data.push(this.gameId);
      _data.push(this.score);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly gameId: TLLong, readonly score: TLInt) {}
  }

  export class MessageActionPaymentSentMe implements TLObject {
    static readonly cons = new TLInt(0x8f31b327);

    static deserialized(
      _data: ByteStream
    ): MessageActionPaymentSentMe | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionPaymentSentMe.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const recurringInit = (flags.value & 4) !== 0;
      const recurringUsed = (flags.value & 8) !== 0;
      const currency = TLString.deserialized(_data);
      if (!currency) return undefined;
      const totalAmount = TLLong.deserialized(_data);
      if (!totalAmount) return undefined;
      const payload = TLBytes.deserialized(_data);
      if (!payload) return undefined;
      let info: API.PaymentRequestedInfo | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as API.PaymentRequestedInfo;
        if (!obj) return undefined;
        info = obj;
      }
      let shippingOptionId: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        shippingOptionId = obj;
      }
      const charge = API.PaymentCharge.deserialized(_data);
      if (!charge) return undefined;

      return new MessageActionPaymentSentMe(
        recurringInit,
        recurringUsed,
        currency,
        totalAmount,
        payload,
        info,
        shippingOptionId,
        charge
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionPaymentSentMe.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.recurringInit) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.recurringUsed) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      _data.push(this.currency);
      _data.push(this.totalAmount);
      _data.push(this.payload);
      if (this.info) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.info) _data.push(this.info);

      if (this.shippingOptionId) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.shippingOptionId) _data.push(this.shippingOptionId);

      _data.push(this.charge);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly recurringInit: boolean | undefined,
      readonly recurringUsed: boolean | undefined,
      readonly currency: TLString,
      readonly totalAmount: TLLong,
      readonly payload: TLBytes,
      readonly info: API.PaymentRequestedInfo | undefined,
      readonly shippingOptionId: TLString | undefined,
      readonly charge: API.PaymentCharge
    ) {}
  }

  export class MessageActionPaymentSent implements TLObject {
    static readonly cons = new TLInt(0x96163f56);

    static deserialized(
      _data: ByteStream
    ): MessageActionPaymentSent | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionPaymentSent.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const recurringInit = (flags.value & 4) !== 0;
      const recurringUsed = (flags.value & 8) !== 0;
      const currency = TLString.deserialized(_data);
      if (!currency) return undefined;
      const totalAmount = TLLong.deserialized(_data);
      if (!totalAmount) return undefined;
      let invoiceSlug: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        invoiceSlug = obj;
      }

      return new MessageActionPaymentSent(
        recurringInit,
        recurringUsed,
        currency,
        totalAmount,
        invoiceSlug
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionPaymentSent.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.recurringInit) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.recurringUsed) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      _data.push(this.currency);
      _data.push(this.totalAmount);
      if (this.invoiceSlug) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.invoiceSlug) _data.push(this.invoiceSlug);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly recurringInit: boolean | undefined,
      readonly recurringUsed: boolean | undefined,
      readonly currency: TLString,
      readonly totalAmount: TLLong,
      readonly invoiceSlug: TLString | undefined
    ) {}
  }

  export class MessageActionPhoneCall implements TLObject {
    static readonly cons = new TLInt(0x80e11a7f);

    static deserialized(_data: ByteStream): MessageActionPhoneCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionPhoneCall.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const video = (flags.value & 4) !== 0;
      const callId = TLLong.deserialized(_data);
      if (!callId) return undefined;
      let reason: PhoneCallDiscardReasonType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as PhoneCallDiscardReasonType;
        if (!obj) return undefined;
        reason = obj;
      }
      let duration: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        duration = obj;
      }

      return new MessageActionPhoneCall(video, callId, reason, duration);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionPhoneCall.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.video) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.callId);
      if (this.reason) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.reason) _data.push(this.reason);

      if (this.duration) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.duration) _data.push(this.duration);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly video: boolean | undefined,
      readonly callId: TLLong,
      readonly reason: PhoneCallDiscardReasonType | undefined,
      readonly duration: TLInt | undefined
    ) {}
  }

  export class MessageActionScreenshotTaken implements TLObject {
    static readonly cons = new TLInt(0x4792929b);

    static deserialized(
      _data: ByteStream
    ): MessageActionScreenshotTaken | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionScreenshotTaken.cons)
      )
        return undefined;

      return new MessageActionScreenshotTaken();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionScreenshotTaken.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class MessageActionCustomAction implements TLObject {
    static readonly cons = new TLInt(0xfae69f56);

    static deserialized(
      _data: ByteStream
    ): MessageActionCustomAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionCustomAction.cons))
        return undefined;

      const message = TLString.deserialized(_data);
      if (!message) return undefined;

      return new MessageActionCustomAction(message);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionCustomAction.cons];

      _data.push(this.message);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly message: TLString) {}
  }

  export class MessageActionBotAllowed implements TLObject {
    static readonly cons = new TLInt(0xc516d679);

    static deserialized(
      _data: ByteStream
    ): MessageActionBotAllowed | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionBotAllowed.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const attachMenu = (flags.value & 2) !== 0;
      const fromRequest = (flags.value & 8) !== 0;
      let domain: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        domain = obj;
      }
      let app: BotAppType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as BotAppType;
        if (!obj) return undefined;
        app = obj;
      }

      return new MessageActionBotAllowed(attachMenu, fromRequest, domain, app);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionBotAllowed.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.attachMenu) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.fromRequest) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.domain) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.domain) _data.push(this.domain);

      if (this.app) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.app) _data.push(this.app);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly attachMenu: boolean | undefined,
      readonly fromRequest: boolean | undefined,
      readonly domain: TLString | undefined,
      readonly app: BotAppType | undefined
    ) {}
  }

  export class MessageActionSecureValuesSentMe implements TLObject {
    static readonly cons = new TLInt(0x1b287353);

    static deserialized(
      _data: ByteStream
    ): MessageActionSecureValuesSentMe | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionSecureValuesSentMe.cons)
      )
        return undefined;

      const values = TLVector.deserialized(_data, API.SecureValue);
      if (!values) return undefined;
      const credentials = API.SecureCredentialsEncrypted.deserialized(_data);
      if (!credentials) return undefined;

      return new MessageActionSecureValuesSentMe(values, credentials);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        MessageActionSecureValuesSentMe.cons,
      ];

      _data.push(this.values);
      _data.push(this.credentials);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly values: TLVector<API.SecureValue>,
      readonly credentials: API.SecureCredentialsEncrypted
    ) {}
  }

  export class MessageActionSecureValuesSent implements TLObject {
    static readonly cons = new TLInt(0xd95c6154);

    static deserialized(
      _data: ByteStream
    ): MessageActionSecureValuesSent | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionSecureValuesSent.cons)
      )
        return undefined;

      const types = TLVector.deserialized(
        _data
      ) as TLVector<SecureValueTypeType>;
      if (!types) return undefined;

      return new MessageActionSecureValuesSent(types);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionSecureValuesSent.cons];

      _data.push(this.types);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly types: TLVector<SecureValueTypeType>) {}
  }

  export class MessageActionContactSignUp implements TLObject {
    static readonly cons = new TLInt(0xf3f25f76);

    static deserialized(
      _data: ByteStream
    ): MessageActionContactSignUp | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionContactSignUp.cons))
        return undefined;

      return new MessageActionContactSignUp();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionContactSignUp.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class MessageActionGeoProximityReached implements TLObject {
    static readonly cons = new TLInt(0x98e0d697);

    static deserialized(
      _data: ByteStream
    ): MessageActionGeoProximityReached | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionGeoProximityReached.cons)
      )
        return undefined;

      const fromId = TLObject.deserialized(_data) as PeerType;
      if (!fromId) return undefined;
      const toId = TLObject.deserialized(_data) as PeerType;
      if (!toId) return undefined;
      const distance = TLInt.deserialized(_data);
      if (!distance) return undefined;

      return new MessageActionGeoProximityReached(fromId, toId, distance);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        MessageActionGeoProximityReached.cons,
      ];

      _data.push(this.fromId);
      _data.push(this.toId);
      _data.push(this.distance);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly fromId: PeerType,
      readonly toId: PeerType,
      readonly distance: TLInt
    ) {}
  }

  export class MessageActionGroupCall implements TLObject {
    static readonly cons = new TLInt(0x7a0d7f42);

    static deserialized(_data: ByteStream): MessageActionGroupCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionGroupCall.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const call = API.InputGroupCall.deserialized(_data);
      if (!call) return undefined;
      let duration: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        duration = obj;
      }

      return new MessageActionGroupCall(call, duration);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionGroupCall.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.call);
      if (this.duration) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.duration) _data.push(this.duration);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly call: API.InputGroupCall,
      readonly duration: TLInt | undefined
    ) {}
  }

  export class MessageActionInviteToGroupCall implements TLObject {
    static readonly cons = new TLInt(0x502f92f7);

    static deserialized(
      _data: ByteStream
    ): MessageActionInviteToGroupCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionInviteToGroupCall.cons)
      )
        return undefined;

      const call = API.InputGroupCall.deserialized(_data);
      if (!call) return undefined;
      const users = TLVector.deserialized(_data, TLLong);
      if (!users) return undefined;

      return new MessageActionInviteToGroupCall(call, users);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        MessageActionInviteToGroupCall.cons,
      ];

      _data.push(this.call);
      _data.push(this.users);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly call: API.InputGroupCall,
      readonly users: TLVector<TLLong>
    ) {}
  }

  export class MessageActionSetMessagesTTL implements TLObject {
    static readonly cons = new TLInt(0x3c134d7b);

    static deserialized(
      _data: ByteStream
    ): MessageActionSetMessagesTTL | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionSetMessagesTTL.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const period = TLInt.deserialized(_data);
      if (!period) return undefined;
      let autoSettingFrom: TLLong | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        autoSettingFrom = obj;
      }

      return new MessageActionSetMessagesTTL(period, autoSettingFrom);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionSetMessagesTTL.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.period);
      if (this.autoSettingFrom) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.autoSettingFrom) _data.push(this.autoSettingFrom);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly period: TLInt,
      readonly autoSettingFrom: TLLong | undefined
    ) {}
  }

  export class MessageActionGroupCallScheduled implements TLObject {
    static readonly cons = new TLInt(0xb3a07661);

    static deserialized(
      _data: ByteStream
    ): MessageActionGroupCallScheduled | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionGroupCallScheduled.cons)
      )
        return undefined;

      const call = API.InputGroupCall.deserialized(_data);
      if (!call) return undefined;
      const scheduleDate = TLInt.deserialized(_data);
      if (!scheduleDate) return undefined;

      return new MessageActionGroupCallScheduled(call, scheduleDate);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        MessageActionGroupCallScheduled.cons,
      ];

      _data.push(this.call);
      _data.push(this.scheduleDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly call: API.InputGroupCall,
      readonly scheduleDate: TLInt
    ) {}
  }

  export class MessageActionSetChatTheme implements TLObject {
    static readonly cons = new TLInt(0xaa786345);

    static deserialized(
      _data: ByteStream
    ): MessageActionSetChatTheme | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionSetChatTheme.cons))
        return undefined;

      const emoticon = TLString.deserialized(_data);
      if (!emoticon) return undefined;

      return new MessageActionSetChatTheme(emoticon);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionSetChatTheme.cons];

      _data.push(this.emoticon);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly emoticon: TLString) {}
  }

  export class MessageActionChatJoinedByRequest implements TLObject {
    static readonly cons = new TLInt(0xebbca3cb);

    static deserialized(
      _data: ByteStream
    ): MessageActionChatJoinedByRequest | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionChatJoinedByRequest.cons)
      )
        return undefined;

      return new MessageActionChatJoinedByRequest();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        MessageActionChatJoinedByRequest.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class MessageActionWebViewDataSentMe implements TLObject {
    static readonly cons = new TLInt(0x47dd8079);

    static deserialized(
      _data: ByteStream
    ): MessageActionWebViewDataSentMe | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionWebViewDataSentMe.cons)
      )
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const data = TLString.deserialized(_data);
      if (!data) return undefined;

      return new MessageActionWebViewDataSentMe(text, data);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        MessageActionWebViewDataSentMe.cons,
      ];

      _data.push(this.text);
      _data.push(this.data);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString, readonly data: TLString) {}
  }

  export class MessageActionWebViewDataSent implements TLObject {
    static readonly cons = new TLInt(0xb4c38cb5);

    static deserialized(
      _data: ByteStream
    ): MessageActionWebViewDataSent | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionWebViewDataSent.cons)
      )
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new MessageActionWebViewDataSent(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionWebViewDataSent.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString) {}
  }

  export class MessageActionGiftPremium implements TLObject {
    static readonly cons = new TLInt(0xc83d6aec);

    static deserialized(
      _data: ByteStream
    ): MessageActionGiftPremium | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionGiftPremium.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const currency = TLString.deserialized(_data);
      if (!currency) return undefined;
      const amount = TLLong.deserialized(_data);
      if (!amount) return undefined;
      const months = TLInt.deserialized(_data);
      if (!months) return undefined;
      let cryptoCurrency: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        cryptoCurrency = obj;
      }
      let cryptoAmount: TLLong | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        cryptoAmount = obj;
      }

      return new MessageActionGiftPremium(
        currency,
        amount,
        months,
        cryptoCurrency,
        cryptoAmount
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionGiftPremium.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.currency);
      _data.push(this.amount);
      _data.push(this.months);
      if (this.cryptoCurrency) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.cryptoCurrency) _data.push(this.cryptoCurrency);

      if (this.cryptoAmount) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.cryptoAmount) _data.push(this.cryptoAmount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly currency: TLString,
      readonly amount: TLLong,
      readonly months: TLInt,
      readonly cryptoCurrency: TLString | undefined,
      readonly cryptoAmount: TLLong | undefined
    ) {}
  }

  export class MessageActionTopicCreate implements TLObject {
    static readonly cons = new TLInt(0xd999256);

    static deserialized(
      _data: ByteStream
    ): MessageActionTopicCreate | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionTopicCreate.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const iconColor = TLInt.deserialized(_data);
      if (!iconColor) return undefined;
      let iconEmojiId: TLLong | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        iconEmojiId = obj;
      }

      return new MessageActionTopicCreate(title, iconColor, iconEmojiId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionTopicCreate.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.title);
      _data.push(this.iconColor);
      if (this.iconEmojiId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.iconEmojiId) _data.push(this.iconEmojiId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly title: TLString,
      readonly iconColor: TLInt,
      readonly iconEmojiId: TLLong | undefined
    ) {}
  }

  export class MessageActionTopicEdit implements TLObject {
    static readonly cons = new TLInt(0xc0944820);

    static deserialized(_data: ByteStream): MessageActionTopicEdit | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionTopicEdit.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let title: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        title = obj;
      }
      let iconEmojiId: TLLong | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        iconEmojiId = obj;
      }
      let closed: BoolType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        closed = obj;
      }
      let hidden: BoolType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        hidden = obj;
      }

      return new MessageActionTopicEdit(title, iconEmojiId, closed, hidden);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionTopicEdit.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.title) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.title) _data.push(this.title);

      if (this.iconEmojiId) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.iconEmojiId) _data.push(this.iconEmojiId);

      if (this.closed) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.closed) _data.push(this.closed);

      if (this.hidden) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.hidden) _data.push(this.hidden);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly title: TLString | undefined,
      readonly iconEmojiId: TLLong | undefined,
      readonly closed: BoolType | undefined,
      readonly hidden: BoolType | undefined
    ) {}
  }

  export class MessageActionSuggestProfilePhoto implements TLObject {
    static readonly cons = new TLInt(0x57de635e);

    static deserialized(
      _data: ByteStream
    ): MessageActionSuggestProfilePhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionSuggestProfilePhoto.cons)
      )
        return undefined;

      const photo = TLObject.deserialized(_data) as PhotoType;
      if (!photo) return undefined;

      return new MessageActionSuggestProfilePhoto(photo);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        MessageActionSuggestProfilePhoto.cons,
      ];

      _data.push(this.photo);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly photo: PhotoType) {}
  }

  export class MessageActionRequestedPeer implements TLObject {
    static readonly cons = new TLInt(0xfe77345d);

    static deserialized(
      _data: ByteStream
    ): MessageActionRequestedPeer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageActionRequestedPeer.cons))
        return undefined;

      const buttonId = TLInt.deserialized(_data);
      if (!buttonId) return undefined;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;

      return new MessageActionRequestedPeer(buttonId, peer);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionRequestedPeer.cons];

      _data.push(this.buttonId);
      _data.push(this.peer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly buttonId: TLInt, readonly peer: PeerType) {}
  }

  export class MessageActionSetChatWallPaper implements TLObject {
    static readonly cons = new TLInt(0xbc44a927);

    static deserialized(
      _data: ByteStream
    ): MessageActionSetChatWallPaper | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionSetChatWallPaper.cons)
      )
        return undefined;

      const wallpaper = TLObject.deserialized(_data) as WallPaperType;
      if (!wallpaper) return undefined;

      return new MessageActionSetChatWallPaper(wallpaper);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageActionSetChatWallPaper.cons];

      _data.push(this.wallpaper);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly wallpaper: WallPaperType) {}
  }

  export class MessageActionSetSameChatWallPaper implements TLObject {
    static readonly cons = new TLInt(0xc0787d6d);

    static deserialized(
      _data: ByteStream
    ): MessageActionSetSameChatWallPaper | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(MessageActionSetSameChatWallPaper.cons)
      )
        return undefined;

      const wallpaper = TLObject.deserialized(_data) as WallPaperType;
      if (!wallpaper) return undefined;

      return new MessageActionSetSameChatWallPaper(wallpaper);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        MessageActionSetSameChatWallPaper.cons,
      ];

      _data.push(this.wallpaper);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly wallpaper: WallPaperType) {}
  }

  export class Dialog implements TLObject {
    static readonly cons = new TLInt(0xd58a08c6);

    static deserialized(_data: ByteStream): Dialog | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Dialog.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const pinned = (flags.value & 4) !== 0;
      const unreadMark = (flags.value & 8) !== 0;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const topMessage = TLInt.deserialized(_data);
      if (!topMessage) return undefined;
      const readInboxMaxId = TLInt.deserialized(_data);
      if (!readInboxMaxId) return undefined;
      const readOutboxMaxId = TLInt.deserialized(_data);
      if (!readOutboxMaxId) return undefined;
      const unreadCount = TLInt.deserialized(_data);
      if (!unreadCount) return undefined;
      const unreadMentionsCount = TLInt.deserialized(_data);
      if (!unreadMentionsCount) return undefined;
      const unreadReactionsCount = TLInt.deserialized(_data);
      if (!unreadReactionsCount) return undefined;
      const notifySettings = API.PeerNotifySettings.deserialized(_data);
      if (!notifySettings) return undefined;
      let pts: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        pts = obj;
      }
      let draft: DraftMessageType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as DraftMessageType;
        if (!obj) return undefined;
        draft = obj;
      }
      let folderId: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        folderId = obj;
      }
      let ttlPeriod: TLInt | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlPeriod = obj;
      }

      return new Dialog(
        pinned,
        unreadMark,
        peer,
        topMessage,
        readInboxMaxId,
        readOutboxMaxId,
        unreadCount,
        unreadMentionsCount,
        unreadReactionsCount,
        notifySettings,
        pts,
        draft,
        folderId,
        ttlPeriod
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Dialog.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.pinned) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.unreadMark) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      _data.push(this.peer);
      _data.push(this.topMessage);
      _data.push(this.readInboxMaxId);
      _data.push(this.readOutboxMaxId);
      _data.push(this.unreadCount);
      _data.push(this.unreadMentionsCount);
      _data.push(this.unreadReactionsCount);
      _data.push(this.notifySettings);
      if (this.pts) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.pts) _data.push(this.pts);

      if (this.draft) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.draft) _data.push(this.draft);

      if (this.folderId) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.folderId) _data.push(this.folderId);

      if (this.ttlPeriod) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.ttlPeriod) _data.push(this.ttlPeriod);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly pinned: boolean | undefined,
      readonly unreadMark: boolean | undefined,
      readonly peer: PeerType,
      readonly topMessage: TLInt,
      readonly readInboxMaxId: TLInt,
      readonly readOutboxMaxId: TLInt,
      readonly unreadCount: TLInt,
      readonly unreadMentionsCount: TLInt,
      readonly unreadReactionsCount: TLInt,
      readonly notifySettings: API.PeerNotifySettings,
      readonly pts: TLInt | undefined,
      readonly draft: DraftMessageType | undefined,
      readonly folderId: TLInt | undefined,
      readonly ttlPeriod: TLInt | undefined
    ) {}
  }

  export class DialogFolder implements TLObject {
    static readonly cons = new TLInt(0x71bd134c);

    static deserialized(_data: ByteStream): DialogFolder | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DialogFolder.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const pinned = (flags.value & 4) !== 0;
      const folder = API.Folder.deserialized(_data);
      if (!folder) return undefined;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const topMessage = TLInt.deserialized(_data);
      if (!topMessage) return undefined;
      const unreadMutedPeersCount = TLInt.deserialized(_data);
      if (!unreadMutedPeersCount) return undefined;
      const unreadUnmutedPeersCount = TLInt.deserialized(_data);
      if (!unreadUnmutedPeersCount) return undefined;
      const unreadMutedMessagesCount = TLInt.deserialized(_data);
      if (!unreadMutedMessagesCount) return undefined;
      const unreadUnmutedMessagesCount = TLInt.deserialized(_data);
      if (!unreadUnmutedMessagesCount) return undefined;

      return new DialogFolder(
        pinned,
        folder,
        peer,
        topMessage,
        unreadMutedPeersCount,
        unreadUnmutedPeersCount,
        unreadMutedMessagesCount,
        unreadUnmutedMessagesCount
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DialogFolder.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.pinned) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.folder);
      _data.push(this.peer);
      _data.push(this.topMessage);
      _data.push(this.unreadMutedPeersCount);
      _data.push(this.unreadUnmutedPeersCount);
      _data.push(this.unreadMutedMessagesCount);
      _data.push(this.unreadUnmutedMessagesCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly pinned: boolean | undefined,
      readonly folder: API.Folder,
      readonly peer: PeerType,
      readonly topMessage: TLInt,
      readonly unreadMutedPeersCount: TLInt,
      readonly unreadUnmutedPeersCount: TLInt,
      readonly unreadMutedMessagesCount: TLInt,
      readonly unreadUnmutedMessagesCount: TLInt
    ) {}
  }

  export class PhotoEmpty implements TLObject {
    static readonly cons = new TLInt(0x2331b22d);

    static deserialized(_data: ByteStream): PhotoEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhotoEmpty.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;

      return new PhotoEmpty(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhotoEmpty.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong) {}
  }

  export class Photo implements TLObject {
    static readonly cons = new TLInt(0xfb197a65);

    static deserialized(_data: ByteStream): Photo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Photo.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const hasStickers = (flags.value & 1) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const fileReference = TLBytes.deserialized(_data);
      if (!fileReference) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const sizes = TLVector.deserialized(_data) as TLVector<PhotoSizeType>;
      if (!sizes) return undefined;
      let videoSizes: TLVector<VideoSizeType> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<VideoSizeType>;
        if (!obj) return undefined;
        videoSizes = obj;
      }
      const dcId = TLInt.deserialized(_data);
      if (!dcId) return undefined;

      return new Photo(
        hasStickers,
        id,
        accessHash,
        fileReference,
        date,
        sizes,
        videoSizes,
        dcId
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Photo.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.hasStickers) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.fileReference);
      _data.push(this.date);
      _data.push(this.sizes);
      if (this.videoSizes) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.videoSizes) _data.push(this.videoSizes);

      _data.push(this.dcId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly hasStickers: boolean | undefined,
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly fileReference: TLBytes,
      readonly date: TLInt,
      readonly sizes: TLVector<PhotoSizeType>,
      readonly videoSizes: TLVector<VideoSizeType> | undefined,
      readonly dcId: TLInt
    ) {}
  }

  export class PhotoSizeEmpty implements TLObject {
    static readonly cons = new TLInt(0xe17e23c);

    static deserialized(_data: ByteStream): PhotoSizeEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhotoSizeEmpty.cons))
        return undefined;

      const type = TLString.deserialized(_data);
      if (!type) return undefined;

      return new PhotoSizeEmpty(type);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhotoSizeEmpty.cons];

      _data.push(this.type);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly type: TLString) {}
  }

  export class PhotoSize implements TLObject {
    static readonly cons = new TLInt(0x75c78e60);

    static deserialized(_data: ByteStream): PhotoSize | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhotoSize.cons)) return undefined;

      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      const w = TLInt.deserialized(_data);
      if (!w) return undefined;
      const h = TLInt.deserialized(_data);
      if (!h) return undefined;
      const size = TLInt.deserialized(_data);
      if (!size) return undefined;

      return new PhotoSize(type, w, h, size);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhotoSize.cons];

      _data.push(this.type);
      _data.push(this.w);
      _data.push(this.h);
      _data.push(this.size);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: TLString,
      readonly w: TLInt,
      readonly h: TLInt,
      readonly size: TLInt
    ) {}
  }

  export class PhotoCachedSize implements TLObject {
    static readonly cons = new TLInt(0x21e1ad6);

    static deserialized(_data: ByteStream): PhotoCachedSize | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhotoCachedSize.cons))
        return undefined;

      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      const w = TLInt.deserialized(_data);
      if (!w) return undefined;
      const h = TLInt.deserialized(_data);
      if (!h) return undefined;
      const bytes = TLBytes.deserialized(_data);
      if (!bytes) return undefined;

      return new PhotoCachedSize(type, w, h, bytes);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhotoCachedSize.cons];

      _data.push(this.type);
      _data.push(this.w);
      _data.push(this.h);
      _data.push(this.bytes);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: TLString,
      readonly w: TLInt,
      readonly h: TLInt,
      readonly bytes: TLBytes
    ) {}
  }

  export class PhotoStrippedSize implements TLObject {
    static readonly cons = new TLInt(0xe0b0bc2e);

    static deserialized(_data: ByteStream): PhotoStrippedSize | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhotoStrippedSize.cons))
        return undefined;

      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      const bytes = TLBytes.deserialized(_data);
      if (!bytes) return undefined;

      return new PhotoStrippedSize(type, bytes);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhotoStrippedSize.cons];

      _data.push(this.type);
      _data.push(this.bytes);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly type: TLString, readonly bytes: TLBytes) {}
  }

  export class PhotoSizeProgressive implements TLObject {
    static readonly cons = new TLInt(0xfa3efb95);

    static deserialized(_data: ByteStream): PhotoSizeProgressive | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhotoSizeProgressive.cons))
        return undefined;

      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      const w = TLInt.deserialized(_data);
      if (!w) return undefined;
      const h = TLInt.deserialized(_data);
      if (!h) return undefined;
      const sizes = TLVector.deserialized(_data, TLInt);
      if (!sizes) return undefined;

      return new PhotoSizeProgressive(type, w, h, sizes);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhotoSizeProgressive.cons];

      _data.push(this.type);
      _data.push(this.w);
      _data.push(this.h);
      _data.push(this.sizes);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: TLString,
      readonly w: TLInt,
      readonly h: TLInt,
      readonly sizes: TLVector<TLInt>
    ) {}
  }

  export class PhotoPathSize implements TLObject {
    static readonly cons = new TLInt(0xd8214d41);

    static deserialized(_data: ByteStream): PhotoPathSize | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhotoPathSize.cons))
        return undefined;

      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      const bytes = TLBytes.deserialized(_data);
      if (!bytes) return undefined;

      return new PhotoPathSize(type, bytes);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhotoPathSize.cons];

      _data.push(this.type);
      _data.push(this.bytes);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly type: TLString, readonly bytes: TLBytes) {}
  }

  export class GeoPointEmpty implements TLObject {
    static readonly cons = new TLInt(0x1117dd5f);

    static deserialized(_data: ByteStream): GeoPointEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(GeoPointEmpty.cons))
        return undefined;

      return new GeoPointEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [GeoPointEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class GeoPoint implements TLObject {
    static readonly cons = new TLInt(0xb2a2f663);

    static deserialized(_data: ByteStream): GeoPoint | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(GeoPoint.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const long = TLDouble.deserialized(_data);
      if (!long) return undefined;
      const lat = TLDouble.deserialized(_data);
      if (!lat) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      let accuracyRadius: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        accuracyRadius = obj;
      }

      return new GeoPoint(long, lat, accessHash, accuracyRadius);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [GeoPoint.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.long);
      _data.push(this.lat);
      _data.push(this.accessHash);
      if (this.accuracyRadius) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.accuracyRadius) _data.push(this.accuracyRadius);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly long: TLDouble,
      readonly lat: TLDouble,
      readonly accessHash: TLLong,
      readonly accuracyRadius: TLInt | undefined
    ) {}
  }

  export namespace auth {
    export class SentCode implements TLObject {
      static readonly cons = new TLInt(0x5e002502);

      static deserialized(_data: ByteStream): SentCode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentCode.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const type = TLObject.deserialized(_data) as auth.SentCodeTypeType;
        if (!type) return undefined;
        const phoneCodeHash = TLString.deserialized(_data);
        if (!phoneCodeHash) return undefined;
        let nextType: auth.CodeTypeType | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as auth.CodeTypeType;
          if (!obj) return undefined;
          nextType = obj;
        }
        let timeout: TLInt | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          timeout = obj;
        }

        return new SentCode(type, phoneCodeHash, nextType, timeout);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentCode.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.type);
        _data.push(this.phoneCodeHash);
        if (this.nextType) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.nextType) _data.push(this.nextType);

        if (this.timeout) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.timeout) _data.push(this.timeout);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly type: auth.SentCodeTypeType,
        readonly phoneCodeHash: TLString,
        readonly nextType: auth.CodeTypeType | undefined,
        readonly timeout: TLInt | undefined
      ) {}
    }
  }

  export namespace auth {
    export class SentCodeSuccess implements TLObject {
      static readonly cons = new TLInt(0x2390fe44);

      static deserialized(_data: ByteStream): SentCodeSuccess | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentCodeSuccess.cons))
          return undefined;

        const authorization = TLObject.deserialized(
          _data
        ) as auth.AuthorizationType;
        if (!authorization) return undefined;

        return new SentCodeSuccess(authorization);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentCodeSuccess.cons];

        _data.push(this.authorization);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly authorization: auth.AuthorizationType) {}
    }
  }

  export namespace auth {
    export class Authorization implements TLObject {
      static readonly cons = new TLInt(0x2ea2c0d4);

      static deserialized(_data: ByteStream): Authorization | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Authorization.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const setupPasswordRequired = (flags.value & 2) !== 0;
        let otherwiseReloginDays: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          otherwiseReloginDays = obj;
        }
        let tmpSessions: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          tmpSessions = obj;
        }
        let futureAuthToken: TLBytes | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLBytes.deserialized(_data);
          if (!obj) return undefined;
          futureAuthToken = obj;
        }
        const user = TLObject.deserialized(_data) as UserType;
        if (!user) return undefined;

        return new Authorization(
          setupPasswordRequired,
          otherwiseReloginDays,
          tmpSessions,
          futureAuthToken,
          user
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Authorization.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.setupPasswordRequired) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.otherwiseReloginDays) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.otherwiseReloginDays) _data.push(this.otherwiseReloginDays);

        if (this.tmpSessions) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.tmpSessions) _data.push(this.tmpSessions);

        if (this.futureAuthToken) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.futureAuthToken) _data.push(this.futureAuthToken);

        _data.push(this.user);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly setupPasswordRequired: boolean | undefined,
        readonly otherwiseReloginDays: TLInt | undefined,
        readonly tmpSessions: TLInt | undefined,
        readonly futureAuthToken: TLBytes | undefined,
        readonly user: UserType
      ) {}
    }
  }

  export namespace auth {
    export class AuthorizationSignUpRequired implements TLObject {
      static readonly cons = new TLInt(0x44747e9a);

      static deserialized(
        _data: ByteStream
      ): AuthorizationSignUpRequired | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(AuthorizationSignUpRequired.cons)
        )
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let termsOfService: help.TermsOfService | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as help.TermsOfService;
          if (!obj) return undefined;
          termsOfService = obj;
        }

        return new AuthorizationSignUpRequired(termsOfService);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AuthorizationSignUpRequired.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.termsOfService) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.termsOfService) _data.push(this.termsOfService);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly termsOfService: help.TermsOfService | undefined) {}
    }
  }

  export namespace auth {
    export class ExportedAuthorization implements TLObject {
      static readonly cons = new TLInt(0xb434e2b8);

      static deserialized(
        _data: ByteStream
      ): ExportedAuthorization | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportedAuthorization.cons))
          return undefined;

        const id = TLLong.deserialized(_data);
        if (!id) return undefined;
        const bytes = TLBytes.deserialized(_data);
        if (!bytes) return undefined;

        return new ExportedAuthorization(id, bytes);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportedAuthorization.cons];

        _data.push(this.id);
        _data.push(this.bytes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLLong, readonly bytes: TLBytes) {}
    }
  }

  export class InputNotifyPeer implements TLObject {
    static readonly cons = new TLInt(0xb8bc5b0c);

    static deserialized(_data: ByteStream): InputNotifyPeer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputNotifyPeer.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;

      return new InputNotifyPeer(peer);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputNotifyPeer.cons];

      _data.push(this.peer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: InputPeerType) {}
  }

  export class InputNotifyUsers implements TLObject {
    static readonly cons = new TLInt(0x193b4417);

    static deserialized(_data: ByteStream): InputNotifyUsers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputNotifyUsers.cons))
        return undefined;

      return new InputNotifyUsers();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputNotifyUsers.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputNotifyChats implements TLObject {
    static readonly cons = new TLInt(0x4a95e84e);

    static deserialized(_data: ByteStream): InputNotifyChats | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputNotifyChats.cons))
        return undefined;

      return new InputNotifyChats();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputNotifyChats.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputNotifyBroadcasts implements TLObject {
    static readonly cons = new TLInt(0xb1db7c7e);

    static deserialized(_data: ByteStream): InputNotifyBroadcasts | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputNotifyBroadcasts.cons))
        return undefined;

      return new InputNotifyBroadcasts();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputNotifyBroadcasts.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputNotifyForumTopic implements TLObject {
    static readonly cons = new TLInt(0x5c467992);

    static deserialized(_data: ByteStream): InputNotifyForumTopic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputNotifyForumTopic.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;
      const topMsgId = TLInt.deserialized(_data);
      if (!topMsgId) return undefined;

      return new InputNotifyForumTopic(peer, topMsgId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputNotifyForumTopic.cons];

      _data.push(this.peer);
      _data.push(this.topMsgId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: InputPeerType, readonly topMsgId: TLInt) {}
  }

  export class InputPeerNotifySettings implements TLObject {
    static readonly cons = new TLInt(0xcacb6ae2);

    static deserialized(
      _data: ByteStream
    ): InputPeerNotifySettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPeerNotifySettings.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let showPreviews: BoolType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        showPreviews = obj;
      }
      let silent: BoolType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        silent = obj;
      }
      let muteUntil: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        muteUntil = obj;
      }
      let sound: NotificationSoundType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as NotificationSoundType;
        if (!obj) return undefined;
        sound = obj;
      }
      let storiesMuted: BoolType | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        storiesMuted = obj;
      }
      let storiesHideSender: BoolType | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        storiesHideSender = obj;
      }
      let storiesSound: NotificationSoundType | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLObject.deserialized(_data) as NotificationSoundType;
        if (!obj) return undefined;
        storiesSound = obj;
      }

      return new InputPeerNotifySettings(
        showPreviews,
        silent,
        muteUntil,
        sound,
        storiesMuted,
        storiesHideSender,
        storiesSound
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPeerNotifySettings.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.showPreviews) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.showPreviews) _data.push(this.showPreviews);

      if (this.silent) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.silent) _data.push(this.silent);

      if (this.muteUntil) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.muteUntil) _data.push(this.muteUntil);

      if (this.sound) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.sound) _data.push(this.sound);

      if (this.storiesMuted) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.storiesMuted) _data.push(this.storiesMuted);

      if (this.storiesHideSender) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.storiesHideSender) _data.push(this.storiesHideSender);

      if (this.storiesSound) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.storiesSound) _data.push(this.storiesSound);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly showPreviews: BoolType | undefined,
      readonly silent: BoolType | undefined,
      readonly muteUntil: TLInt | undefined,
      readonly sound: NotificationSoundType | undefined,
      readonly storiesMuted: BoolType | undefined,
      readonly storiesHideSender: BoolType | undefined,
      readonly storiesSound: NotificationSoundType | undefined
    ) {}
  }

  export class PeerNotifySettings implements TLObject {
    static readonly cons = new TLInt(0x99622c0c);

    static deserialized(_data: ByteStream): PeerNotifySettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PeerNotifySettings.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let showPreviews: BoolType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        showPreviews = obj;
      }
      let silent: BoolType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        silent = obj;
      }
      let muteUntil: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        muteUntil = obj;
      }
      let iosSound: NotificationSoundType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as NotificationSoundType;
        if (!obj) return undefined;
        iosSound = obj;
      }
      let androidSound: NotificationSoundType | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLObject.deserialized(_data) as NotificationSoundType;
        if (!obj) return undefined;
        androidSound = obj;
      }
      let otherSound: NotificationSoundType | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLObject.deserialized(_data) as NotificationSoundType;
        if (!obj) return undefined;
        otherSound = obj;
      }
      let storiesMuted: BoolType | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        storiesMuted = obj;
      }
      let storiesHideSender: BoolType | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        storiesHideSender = obj;
      }
      let storiesIosSound: NotificationSoundType | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLObject.deserialized(_data) as NotificationSoundType;
        if (!obj) return undefined;
        storiesIosSound = obj;
      }
      let storiesAndroidSound: NotificationSoundType | undefined;
      if ((flags.value & 512) !== 0) {
        const obj = TLObject.deserialized(_data) as NotificationSoundType;
        if (!obj) return undefined;
        storiesAndroidSound = obj;
      }
      let storiesOtherSound: NotificationSoundType | undefined;
      if ((flags.value & 1024) !== 0) {
        const obj = TLObject.deserialized(_data) as NotificationSoundType;
        if (!obj) return undefined;
        storiesOtherSound = obj;
      }

      return new PeerNotifySettings(
        showPreviews,
        silent,
        muteUntil,
        iosSound,
        androidSound,
        otherSound,
        storiesMuted,
        storiesHideSender,
        storiesIosSound,
        storiesAndroidSound,
        storiesOtherSound
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PeerNotifySettings.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.showPreviews) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.showPreviews) _data.push(this.showPreviews);

      if (this.silent) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.silent) _data.push(this.silent);

      if (this.muteUntil) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.muteUntil) _data.push(this.muteUntil);

      if (this.iosSound) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.iosSound) _data.push(this.iosSound);

      if (this.androidSound) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.androidSound) _data.push(this.androidSound);

      if (this.otherSound) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.otherSound) _data.push(this.otherSound);

      if (this.storiesMuted) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.storiesMuted) _data.push(this.storiesMuted);

      if (this.storiesHideSender) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.storiesHideSender) _data.push(this.storiesHideSender);

      if (this.storiesIosSound) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.storiesIosSound) _data.push(this.storiesIosSound);

      if (this.storiesAndroidSound) {
        flags.set(512);
      } else {
        flags.unset(512);
      }
      if (this.storiesAndroidSound) _data.push(this.storiesAndroidSound);

      if (this.storiesOtherSound) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }
      if (this.storiesOtherSound) _data.push(this.storiesOtherSound);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly showPreviews: BoolType | undefined,
      readonly silent: BoolType | undefined,
      readonly muteUntil: TLInt | undefined,
      readonly iosSound: NotificationSoundType | undefined,
      readonly androidSound: NotificationSoundType | undefined,
      readonly otherSound: NotificationSoundType | undefined,
      readonly storiesMuted: BoolType | undefined,
      readonly storiesHideSender: BoolType | undefined,
      readonly storiesIosSound: NotificationSoundType | undefined,
      readonly storiesAndroidSound: NotificationSoundType | undefined,
      readonly storiesOtherSound: NotificationSoundType | undefined
    ) {}
  }

  export class PeerSettings implements TLObject {
    static readonly cons = new TLInt(0xa518110d);

    static deserialized(_data: ByteStream): PeerSettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PeerSettings.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const reportSpam = (flags.value & 1) !== 0;
      const addContact = (flags.value & 2) !== 0;
      const blockContact = (flags.value & 4) !== 0;
      const shareContact = (flags.value & 8) !== 0;
      const needContactsException = (flags.value & 16) !== 0;
      const reportGeo = (flags.value & 32) !== 0;
      const autoarchived = (flags.value & 128) !== 0;
      const inviteMembers = (flags.value & 256) !== 0;
      const requestChatBroadcast = (flags.value & 1024) !== 0;
      let geoDistance: TLInt | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        geoDistance = obj;
      }
      let requestChatTitle: TLString | undefined;
      if ((flags.value & 512) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        requestChatTitle = obj;
      }
      let requestChatDate: TLInt | undefined;
      if ((flags.value & 512) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        requestChatDate = obj;
      }

      return new PeerSettings(
        reportSpam,
        addContact,
        blockContact,
        shareContact,
        needContactsException,
        reportGeo,
        autoarchived,
        inviteMembers,
        requestChatBroadcast,
        geoDistance,
        requestChatTitle,
        requestChatDate
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PeerSettings.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.reportSpam) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.addContact) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.blockContact) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.shareContact) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.needContactsException) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.reportGeo) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.autoarchived) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.inviteMembers) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      if (this.requestChatBroadcast) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }

      if (this.geoDistance) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.geoDistance) _data.push(this.geoDistance);

      if (this.requestChatTitle) {
        flags.set(512);
      } else {
        flags.unset(512);
      }
      if (this.requestChatTitle) _data.push(this.requestChatTitle);

      if (this.requestChatDate) {
        flags.set(512);
      } else {
        flags.unset(512);
      }
      if (this.requestChatDate) _data.push(this.requestChatDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly reportSpam: boolean | undefined,
      readonly addContact: boolean | undefined,
      readonly blockContact: boolean | undefined,
      readonly shareContact: boolean | undefined,
      readonly needContactsException: boolean | undefined,
      readonly reportGeo: boolean | undefined,
      readonly autoarchived: boolean | undefined,
      readonly inviteMembers: boolean | undefined,
      readonly requestChatBroadcast: boolean | undefined,
      readonly geoDistance: TLInt | undefined,
      readonly requestChatTitle: TLString | undefined,
      readonly requestChatDate: TLInt | undefined
    ) {}
  }

  export class WallPaper implements TLObject {
    static readonly cons = new TLInt(0xa437c3ed);

    static deserialized(_data: ByteStream): WallPaper | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WallPaper.cons)) return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const creator = (flags.value & 1) !== 0;
      const argDefault = (flags.value & 2) !== 0;
      const pattern = (flags.value & 8) !== 0;
      const dark = (flags.value & 16) !== 0;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const slug = TLString.deserialized(_data);
      if (!slug) return undefined;
      const document = TLObject.deserialized(_data) as DocumentType;
      if (!document) return undefined;
      let settings: API.WallPaperSettings | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as API.WallPaperSettings;
        if (!obj) return undefined;
        settings = obj;
      }

      return new WallPaper(
        id,
        creator,
        argDefault,
        pattern,
        dark,
        accessHash,
        slug,
        document,
        settings
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WallPaper.cons];

      _data.push(this.id);
      const flags = new TLFlags();
      _data.push(flags);
      if (this.creator) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.argDefault) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.pattern) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.dark) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      _data.push(this.accessHash);
      _data.push(this.slug);
      _data.push(this.document);
      if (this.settings) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.settings) _data.push(this.settings);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly creator: boolean | undefined,
      readonly argDefault: boolean | undefined,
      readonly pattern: boolean | undefined,
      readonly dark: boolean | undefined,
      readonly accessHash: TLLong,
      readonly slug: TLString,
      readonly document: DocumentType,
      readonly settings: API.WallPaperSettings | undefined
    ) {}
  }

  export class WallPaperNoFile implements TLObject {
    static readonly cons = new TLInt(0xe0804116);

    static deserialized(_data: ByteStream): WallPaperNoFile | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WallPaperNoFile.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const argDefault = (flags.value & 2) !== 0;
      const dark = (flags.value & 16) !== 0;
      let settings: API.WallPaperSettings | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as API.WallPaperSettings;
        if (!obj) return undefined;
        settings = obj;
      }

      return new WallPaperNoFile(id, argDefault, dark, settings);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WallPaperNoFile.cons];

      _data.push(this.id);
      const flags = new TLFlags();
      _data.push(flags);
      if (this.argDefault) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.dark) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.settings) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.settings) _data.push(this.settings);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly argDefault: boolean | undefined,
      readonly dark: boolean | undefined,
      readonly settings: API.WallPaperSettings | undefined
    ) {}
  }

  export class InputReportReasonSpam implements TLObject {
    static readonly cons = new TLInt(0x58dbcab8);

    static deserialized(_data: ByteStream): InputReportReasonSpam | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputReportReasonSpam.cons))
        return undefined;

      return new InputReportReasonSpam();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputReportReasonSpam.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputReportReasonViolence implements TLObject {
    static readonly cons = new TLInt(0x1e22c78d);

    static deserialized(
      _data: ByteStream
    ): InputReportReasonViolence | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputReportReasonViolence.cons))
        return undefined;

      return new InputReportReasonViolence();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputReportReasonViolence.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputReportReasonPornography implements TLObject {
    static readonly cons = new TLInt(0x2e59d922);

    static deserialized(
      _data: ByteStream
    ): InputReportReasonPornography | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputReportReasonPornography.cons)
      )
        return undefined;

      return new InputReportReasonPornography();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputReportReasonPornography.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputReportReasonChildAbuse implements TLObject {
    static readonly cons = new TLInt(0xadf44ee3);

    static deserialized(
      _data: ByteStream
    ): InputReportReasonChildAbuse | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputReportReasonChildAbuse.cons))
        return undefined;

      return new InputReportReasonChildAbuse();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputReportReasonChildAbuse.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputReportReasonOther implements TLObject {
    static readonly cons = new TLInt(0xc1e4a2b1);

    static deserialized(_data: ByteStream): InputReportReasonOther | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputReportReasonOther.cons))
        return undefined;

      return new InputReportReasonOther();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputReportReasonOther.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputReportReasonCopyright implements TLObject {
    static readonly cons = new TLInt(0x9b89f93a);

    static deserialized(
      _data: ByteStream
    ): InputReportReasonCopyright | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputReportReasonCopyright.cons))
        return undefined;

      return new InputReportReasonCopyright();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputReportReasonCopyright.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputReportReasonGeoIrrelevant implements TLObject {
    static readonly cons = new TLInt(0xdbd4feed);

    static deserialized(
      _data: ByteStream
    ): InputReportReasonGeoIrrelevant | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputReportReasonGeoIrrelevant.cons)
      )
        return undefined;

      return new InputReportReasonGeoIrrelevant();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputReportReasonGeoIrrelevant.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputReportReasonFake implements TLObject {
    static readonly cons = new TLInt(0xf5ddd6e7);

    static deserialized(_data: ByteStream): InputReportReasonFake | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputReportReasonFake.cons))
        return undefined;

      return new InputReportReasonFake();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputReportReasonFake.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputReportReasonIllegalDrugs implements TLObject {
    static readonly cons = new TLInt(0xa8eb2be);

    static deserialized(
      _data: ByteStream
    ): InputReportReasonIllegalDrugs | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputReportReasonIllegalDrugs.cons)
      )
        return undefined;

      return new InputReportReasonIllegalDrugs();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputReportReasonIllegalDrugs.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputReportReasonPersonalDetails implements TLObject {
    static readonly cons = new TLInt(0x9ec7863d);

    static deserialized(
      _data: ByteStream
    ): InputReportReasonPersonalDetails | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputReportReasonPersonalDetails.cons)
      )
        return undefined;

      return new InputReportReasonPersonalDetails();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputReportReasonPersonalDetails.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UserFull implements TLObject {
    static readonly cons = new TLInt(0xb9b12c6c);

    static deserialized(_data: ByteStream): UserFull | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UserFull.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const blocked = (flags.value & 1) !== 0;
      const phoneCallsAvailable = (flags.value & 16) !== 0;
      const phoneCallsPrivate = (flags.value & 32) !== 0;
      const canPinMessage = (flags.value & 128) !== 0;
      const hasScheduled = (flags.value & 4096) !== 0;
      const videoCallsAvailable = (flags.value & 8192) !== 0;
      const voiceMessagesForbidden = (flags.value & 1048576) !== 0;
      const translationsDisabled = (flags.value & 8388608) !== 0;
      const storiesPinnedAvailable = (flags.value & 67108864) !== 0;
      const blockedMyStoriesFrom = (flags.value & 134217728) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      let about: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        about = obj;
      }
      const settings = API.PeerSettings.deserialized(_data);
      if (!settings) return undefined;
      let personalPhoto: PhotoType | undefined;
      if ((flags.value & 2097152) !== 0) {
        const obj = TLObject.deserialized(_data) as PhotoType;
        if (!obj) return undefined;
        personalPhoto = obj;
      }
      let profilePhoto: PhotoType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as PhotoType;
        if (!obj) return undefined;
        profilePhoto = obj;
      }
      let fallbackPhoto: PhotoType | undefined;
      if ((flags.value & 4194304) !== 0) {
        const obj = TLObject.deserialized(_data) as PhotoType;
        if (!obj) return undefined;
        fallbackPhoto = obj;
      }
      const notifySettings = API.PeerNotifySettings.deserialized(_data);
      if (!notifySettings) return undefined;
      let botInfo: API.BotInfo | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as API.BotInfo;
        if (!obj) return undefined;
        botInfo = obj;
      }
      let pinnedMsgId: TLInt | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        pinnedMsgId = obj;
      }
      const commonChatsCount = TLInt.deserialized(_data);
      if (!commonChatsCount) return undefined;
      let folderId: TLInt | undefined;
      if ((flags.value & 2048) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        folderId = obj;
      }
      let ttlPeriod: TLInt | undefined;
      if ((flags.value & 16384) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlPeriod = obj;
      }
      let themeEmoticon: TLString | undefined;
      if ((flags.value & 32768) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        themeEmoticon = obj;
      }
      let privateForwardName: TLString | undefined;
      if ((flags.value & 65536) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        privateForwardName = obj;
      }
      let botGroupAdminRights: API.ChatAdminRights | undefined;
      if ((flags.value & 131072) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ChatAdminRights;
        if (!obj) return undefined;
        botGroupAdminRights = obj;
      }
      let botBroadcastAdminRights: API.ChatAdminRights | undefined;
      if ((flags.value & 262144) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ChatAdminRights;
        if (!obj) return undefined;
        botBroadcastAdminRights = obj;
      }
      let premiumGifts: TLVector<API.PremiumGiftOption> | undefined;
      if ((flags.value & 524288) !== 0) {
        const obj = TLVector.deserialized(_data, API.PremiumGiftOption);
        if (!obj) return undefined;
        premiumGifts = obj;
      }
      let wallpaper: WallPaperType | undefined;
      if ((flags.value & 16777216) !== 0) {
        const obj = TLObject.deserialized(_data) as WallPaperType;
        if (!obj) return undefined;
        wallpaper = obj;
      }
      let stories: API.PeerStories | undefined;
      if ((flags.value & 33554432) !== 0) {
        const obj = TLObject.deserialized(_data) as API.PeerStories;
        if (!obj) return undefined;
        stories = obj;
      }

      return new UserFull(
        blocked,
        phoneCallsAvailable,
        phoneCallsPrivate,
        canPinMessage,
        hasScheduled,
        videoCallsAvailable,
        voiceMessagesForbidden,
        translationsDisabled,
        storiesPinnedAvailable,
        blockedMyStoriesFrom,
        id,
        about,
        settings,
        personalPhoto,
        profilePhoto,
        fallbackPhoto,
        notifySettings,
        botInfo,
        pinnedMsgId,
        commonChatsCount,
        folderId,
        ttlPeriod,
        themeEmoticon,
        privateForwardName,
        botGroupAdminRights,
        botBroadcastAdminRights,
        premiumGifts,
        wallpaper,
        stories
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UserFull.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.blocked) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.phoneCallsAvailable) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.phoneCallsPrivate) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.canPinMessage) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.hasScheduled) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }

      if (this.videoCallsAvailable) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }

      if (this.voiceMessagesForbidden) {
        flags.set(1048576);
      } else {
        flags.unset(1048576);
      }

      if (this.translationsDisabled) {
        flags.set(8388608);
      } else {
        flags.unset(8388608);
      }

      if (this.storiesPinnedAvailable) {
        flags.set(67108864);
      } else {
        flags.unset(67108864);
      }

      if (this.blockedMyStoriesFrom) {
        flags.set(134217728);
      } else {
        flags.unset(134217728);
      }

      _data.push(this.id);
      if (this.about) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.about) _data.push(this.about);

      _data.push(this.settings);
      if (this.personalPhoto) {
        flags.set(2097152);
      } else {
        flags.unset(2097152);
      }
      if (this.personalPhoto) _data.push(this.personalPhoto);

      if (this.profilePhoto) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.profilePhoto) _data.push(this.profilePhoto);

      if (this.fallbackPhoto) {
        flags.set(4194304);
      } else {
        flags.unset(4194304);
      }
      if (this.fallbackPhoto) _data.push(this.fallbackPhoto);

      _data.push(this.notifySettings);
      if (this.botInfo) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.botInfo) _data.push(this.botInfo);

      if (this.pinnedMsgId) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.pinnedMsgId) _data.push(this.pinnedMsgId);

      _data.push(this.commonChatsCount);
      if (this.folderId) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }
      if (this.folderId) _data.push(this.folderId);

      if (this.ttlPeriod) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }
      if (this.ttlPeriod) _data.push(this.ttlPeriod);

      if (this.themeEmoticon) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }
      if (this.themeEmoticon) _data.push(this.themeEmoticon);

      if (this.privateForwardName) {
        flags.set(65536);
      } else {
        flags.unset(65536);
      }
      if (this.privateForwardName) _data.push(this.privateForwardName);

      if (this.botGroupAdminRights) {
        flags.set(131072);
      } else {
        flags.unset(131072);
      }
      if (this.botGroupAdminRights) _data.push(this.botGroupAdminRights);

      if (this.botBroadcastAdminRights) {
        flags.set(262144);
      } else {
        flags.unset(262144);
      }
      if (this.botBroadcastAdminRights)
        _data.push(this.botBroadcastAdminRights);

      if (this.premiumGifts) {
        flags.set(524288);
      } else {
        flags.unset(524288);
      }
      if (this.premiumGifts) _data.push(this.premiumGifts);

      if (this.wallpaper) {
        flags.set(16777216);
      } else {
        flags.unset(16777216);
      }
      if (this.wallpaper) _data.push(this.wallpaper);

      if (this.stories) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }
      if (this.stories) _data.push(this.stories);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly blocked: boolean | undefined,
      readonly phoneCallsAvailable: boolean | undefined,
      readonly phoneCallsPrivate: boolean | undefined,
      readonly canPinMessage: boolean | undefined,
      readonly hasScheduled: boolean | undefined,
      readonly videoCallsAvailable: boolean | undefined,
      readonly voiceMessagesForbidden: boolean | undefined,
      readonly translationsDisabled: boolean | undefined,
      readonly storiesPinnedAvailable: boolean | undefined,
      readonly blockedMyStoriesFrom: boolean | undefined,
      readonly id: TLLong,
      readonly about: TLString | undefined,
      readonly settings: API.PeerSettings,
      readonly personalPhoto: PhotoType | undefined,
      readonly profilePhoto: PhotoType | undefined,
      readonly fallbackPhoto: PhotoType | undefined,
      readonly notifySettings: API.PeerNotifySettings,
      readonly botInfo: API.BotInfo | undefined,
      readonly pinnedMsgId: TLInt | undefined,
      readonly commonChatsCount: TLInt,
      readonly folderId: TLInt | undefined,
      readonly ttlPeriod: TLInt | undefined,
      readonly themeEmoticon: TLString | undefined,
      readonly privateForwardName: TLString | undefined,
      readonly botGroupAdminRights: API.ChatAdminRights | undefined,
      readonly botBroadcastAdminRights: API.ChatAdminRights | undefined,
      readonly premiumGifts: TLVector<API.PremiumGiftOption> | undefined,
      readonly wallpaper: WallPaperType | undefined,
      readonly stories: API.PeerStories | undefined
    ) {}
  }

  export class Contact implements TLObject {
    static readonly cons = new TLInt(0x145ade0b);

    static deserialized(_data: ByteStream): Contact | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Contact.cons)) return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const mutual = TLObject.deserialized(_data) as BoolType;
      if (!mutual) return undefined;

      return new Contact(userId, mutual);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Contact.cons];

      _data.push(this.userId);
      _data.push(this.mutual);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly mutual: BoolType) {}
  }

  export class ImportedContact implements TLObject {
    static readonly cons = new TLInt(0xc13e3c50);

    static deserialized(_data: ByteStream): ImportedContact | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ImportedContact.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const clientId = TLLong.deserialized(_data);
      if (!clientId) return undefined;

      return new ImportedContact(userId, clientId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ImportedContact.cons];

      _data.push(this.userId);
      _data.push(this.clientId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly clientId: TLLong) {}
  }

  export class ContactStatus implements TLObject {
    static readonly cons = new TLInt(0x16d9703b);

    static deserialized(_data: ByteStream): ContactStatus | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ContactStatus.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const status = TLObject.deserialized(_data) as UserStatusType;
      if (!status) return undefined;

      return new ContactStatus(userId, status);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ContactStatus.cons];

      _data.push(this.userId);
      _data.push(this.status);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly status: UserStatusType) {}
  }

  export namespace contacts {
    export class ContactsNotModified implements TLObject {
      static readonly cons = new TLInt(0xb74ba9d2);

      static deserialized(_data: ByteStream): ContactsNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ContactsNotModified.cons))
          return undefined;

        return new ContactsNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ContactsNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace contacts {
    export class Contacts implements TLObject {
      static readonly cons = new TLInt(0xeae87e42);

      static deserialized(_data: ByteStream): Contacts | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Contacts.cons))
          return undefined;

        const contacts = TLVector.deserialized(_data, API.Contact);
        if (!contacts) return undefined;
        const savedCount = TLInt.deserialized(_data);
        if (!savedCount) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new Contacts(contacts, savedCount, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Contacts.cons];

        _data.push(this.contacts);
        _data.push(this.savedCount);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly contacts: TLVector<API.Contact>,
        readonly savedCount: TLInt,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace contacts {
    export class ImportedContacts implements TLObject {
      static readonly cons = new TLInt(0x77d01c3b);

      static deserialized(_data: ByteStream): ImportedContacts | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ImportedContacts.cons))
          return undefined;

        const imported = TLVector.deserialized(_data, API.ImportedContact);
        if (!imported) return undefined;
        const popularInvites = TLVector.deserialized(_data, API.PopularContact);
        if (!popularInvites) return undefined;
        const retryContacts = TLVector.deserialized(_data, TLLong);
        if (!retryContacts) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ImportedContacts(
          imported,
          popularInvites,
          retryContacts,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ImportedContacts.cons];

        _data.push(this.imported);
        _data.push(this.popularInvites);
        _data.push(this.retryContacts);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly imported: TLVector<API.ImportedContact>,
        readonly popularInvites: TLVector<API.PopularContact>,
        readonly retryContacts: TLVector<TLLong>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace contacts {
    export class Blocked implements TLObject {
      static readonly cons = new TLInt(0xade1591);

      static deserialized(_data: ByteStream): Blocked | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Blocked.cons)) return undefined;

        const blocked = TLVector.deserialized(_data, API.PeerBlocked);
        if (!blocked) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new Blocked(blocked, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Blocked.cons];

        _data.push(this.blocked);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly blocked: TLVector<API.PeerBlocked>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace contacts {
    export class BlockedSlice implements TLObject {
      static readonly cons = new TLInt(0xe1664194);

      static deserialized(_data: ByteStream): BlockedSlice | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(BlockedSlice.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const blocked = TLVector.deserialized(_data, API.PeerBlocked);
        if (!blocked) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new BlockedSlice(count, blocked, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [BlockedSlice.cons];

        _data.push(this.count);
        _data.push(this.blocked);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly blocked: TLVector<API.PeerBlocked>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class Dialogs implements TLObject {
      static readonly cons = new TLInt(0x15ba6c40);

      static deserialized(_data: ByteStream): Dialogs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Dialogs.cons)) return undefined;

        const dialogs = TLVector.deserialized(_data) as TLVector<DialogType>;
        if (!dialogs) return undefined;
        const messages = TLVector.deserialized(_data) as TLVector<MessageType>;
        if (!messages) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new Dialogs(dialogs, messages, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Dialogs.cons];

        _data.push(this.dialogs);
        _data.push(this.messages);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly dialogs: TLVector<DialogType>,
        readonly messages: TLVector<MessageType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class DialogsSlice implements TLObject {
      static readonly cons = new TLInt(0x71e094f3);

      static deserialized(_data: ByteStream): DialogsSlice | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DialogsSlice.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const dialogs = TLVector.deserialized(_data) as TLVector<DialogType>;
        if (!dialogs) return undefined;
        const messages = TLVector.deserialized(_data) as TLVector<MessageType>;
        if (!messages) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new DialogsSlice(count, dialogs, messages, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DialogsSlice.cons];

        _data.push(this.count);
        _data.push(this.dialogs);
        _data.push(this.messages);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly dialogs: TLVector<DialogType>,
        readonly messages: TLVector<MessageType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class DialogsNotModified implements TLObject {
      static readonly cons = new TLInt(0xf0e3e596);

      static deserialized(_data: ByteStream): DialogsNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DialogsNotModified.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;

        return new DialogsNotModified(count);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DialogsNotModified.cons];

        _data.push(this.count);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly count: TLInt) {}
    }
  }

  export namespace messages {
    export class Messages implements TLObject {
      static readonly cons = new TLInt(0x8c718e87);

      static deserialized(_data: ByteStream): Messages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Messages.cons))
          return undefined;

        const messages = TLVector.deserialized(_data) as TLVector<MessageType>;
        if (!messages) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new Messages(messages, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Messages.cons];

        _data.push(this.messages);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly messages: TLVector<MessageType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class MessagesSlice implements TLObject {
      static readonly cons = new TLInt(0x3a54685e);

      static deserialized(_data: ByteStream): MessagesSlice | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(MessagesSlice.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const inexact = (flags.value & 2) !== 0;
        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        let nextRate: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          nextRate = obj;
        }
        let offsetIdOffset: TLInt | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          offsetIdOffset = obj;
        }
        const messages = TLVector.deserialized(_data) as TLVector<MessageType>;
        if (!messages) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new MessagesSlice(
          inexact,
          count,
          nextRate,
          offsetIdOffset,
          messages,
          chats,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [MessagesSlice.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.inexact) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.count);
        if (this.nextRate) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.nextRate) _data.push(this.nextRate);

        if (this.offsetIdOffset) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.offsetIdOffset) _data.push(this.offsetIdOffset);

        _data.push(this.messages);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly inexact: boolean | undefined,
        readonly count: TLInt,
        readonly nextRate: TLInt | undefined,
        readonly offsetIdOffset: TLInt | undefined,
        readonly messages: TLVector<MessageType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class ChannelMessages implements TLObject {
      static readonly cons = new TLInt(0xc776ba4e);

      static deserialized(_data: ByteStream): ChannelMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChannelMessages.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const inexact = (flags.value & 2) !== 0;
        const pts = TLInt.deserialized(_data);
        if (!pts) return undefined;
        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        let offsetIdOffset: TLInt | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          offsetIdOffset = obj;
        }
        const messages = TLVector.deserialized(_data) as TLVector<MessageType>;
        if (!messages) return undefined;
        const topics = TLVector.deserialized(_data) as TLVector<ForumTopicType>;
        if (!topics) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ChannelMessages(
          inexact,
          pts,
          count,
          offsetIdOffset,
          messages,
          topics,
          chats,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChannelMessages.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.inexact) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.pts);
        _data.push(this.count);
        if (this.offsetIdOffset) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.offsetIdOffset) _data.push(this.offsetIdOffset);

        _data.push(this.messages);
        _data.push(this.topics);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly inexact: boolean | undefined,
        readonly pts: TLInt,
        readonly count: TLInt,
        readonly offsetIdOffset: TLInt | undefined,
        readonly messages: TLVector<MessageType>,
        readonly topics: TLVector<ForumTopicType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class MessagesNotModified implements TLObject {
      static readonly cons = new TLInt(0x74535f21);

      static deserialized(_data: ByteStream): MessagesNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(MessagesNotModified.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;

        return new MessagesNotModified(count);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [MessagesNotModified.cons];

        _data.push(this.count);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly count: TLInt) {}
    }
  }

  export namespace messages {
    export class Chats implements TLObject {
      static readonly cons = new TLInt(0x64ff9fd5);

      static deserialized(_data: ByteStream): Chats | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Chats.cons)) return undefined;

        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;

        return new Chats(chats);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Chats.cons];

        _data.push(this.chats);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly chats: TLVector<ChatType>) {}
    }
  }

  export namespace messages {
    export class ChatsSlice implements TLObject {
      static readonly cons = new TLInt(0x9cd81144);

      static deserialized(_data: ByteStream): ChatsSlice | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChatsSlice.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;

        return new ChatsSlice(count, chats);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChatsSlice.cons];

        _data.push(this.count);
        _data.push(this.chats);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly count: TLInt, readonly chats: TLVector<ChatType>) {}
    }
  }

  export namespace messages {
    export class ChatFull implements TLObject {
      static readonly cons = new TLInt(0xe5d7d19c);

      static deserialized(_data: ByteStream): ChatFull | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChatFull.cons))
          return undefined;

        const fullChat = TLObject.deserialized(_data) as ChatFullType;
        if (!fullChat) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ChatFull(fullChat, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChatFull.cons];

        _data.push(this.fullChat);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly fullChat: ChatFullType,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class AffectedHistory implements TLObject {
      static readonly cons = new TLInt(0xb45c69d1);

      static deserialized(_data: ByteStream): AffectedHistory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AffectedHistory.cons))
          return undefined;

        const pts = TLInt.deserialized(_data);
        if (!pts) return undefined;
        const ptsCount = TLInt.deserialized(_data);
        if (!ptsCount) return undefined;
        const offset = TLInt.deserialized(_data);
        if (!offset) return undefined;

        return new AffectedHistory(pts, ptsCount, offset);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AffectedHistory.cons];

        _data.push(this.pts);
        _data.push(this.ptsCount);
        _data.push(this.offset);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly pts: TLInt,
        readonly ptsCount: TLInt,
        readonly offset: TLInt
      ) {}
    }
  }

  export class InputMessagesFilterEmpty implements TLObject {
    static readonly cons = new TLInt(0x57e2f66c);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagesFilterEmpty.cons))
        return undefined;

      return new InputMessagesFilterEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterPhotos implements TLObject {
    static readonly cons = new TLInt(0x9609a51c);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterPhotos | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagesFilterPhotos.cons))
        return undefined;

      return new InputMessagesFilterPhotos();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterPhotos.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterVideo implements TLObject {
    static readonly cons = new TLInt(0x9fc00e65);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterVideo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagesFilterVideo.cons))
        return undefined;

      return new InputMessagesFilterVideo();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterVideo.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterPhotoVideo implements TLObject {
    static readonly cons = new TLInt(0x56e9f0e4);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterPhotoVideo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputMessagesFilterPhotoVideo.cons)
      )
        return undefined;

      return new InputMessagesFilterPhotoVideo();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterPhotoVideo.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterDocument implements TLObject {
    static readonly cons = new TLInt(0x9eddf188);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterDocument | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagesFilterDocument.cons))
        return undefined;

      return new InputMessagesFilterDocument();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterDocument.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterUrl implements TLObject {
    static readonly cons = new TLInt(0x7ef0dd87);

    static deserialized(_data: ByteStream): InputMessagesFilterUrl | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagesFilterUrl.cons))
        return undefined;

      return new InputMessagesFilterUrl();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterUrl.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterGif implements TLObject {
    static readonly cons = new TLInt(0xffc86587);

    static deserialized(_data: ByteStream): InputMessagesFilterGif | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagesFilterGif.cons))
        return undefined;

      return new InputMessagesFilterGif();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterGif.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterVoice implements TLObject {
    static readonly cons = new TLInt(0x50f5c392);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterVoice | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagesFilterVoice.cons))
        return undefined;

      return new InputMessagesFilterVoice();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterVoice.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterMusic implements TLObject {
    static readonly cons = new TLInt(0x3751b49e);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterMusic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagesFilterMusic.cons))
        return undefined;

      return new InputMessagesFilterMusic();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterMusic.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterChatPhotos implements TLObject {
    static readonly cons = new TLInt(0x3a20ecb8);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterChatPhotos | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputMessagesFilterChatPhotos.cons)
      )
        return undefined;

      return new InputMessagesFilterChatPhotos();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterChatPhotos.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterPhoneCalls implements TLObject {
    static readonly cons = new TLInt(0x80c99768);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterPhoneCalls | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputMessagesFilterPhoneCalls.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const missed = (flags.value & 1) !== 0;

      return new InputMessagesFilterPhoneCalls(missed);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterPhoneCalls.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.missed) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly missed: boolean | undefined) {}
  }

  export class InputMessagesFilterRoundVoice implements TLObject {
    static readonly cons = new TLInt(0x7a7c17a4);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterRoundVoice | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputMessagesFilterRoundVoice.cons)
      )
        return undefined;

      return new InputMessagesFilterRoundVoice();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterRoundVoice.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterRoundVideo implements TLObject {
    static readonly cons = new TLInt(0xb549da53);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterRoundVideo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputMessagesFilterRoundVideo.cons)
      )
        return undefined;

      return new InputMessagesFilterRoundVideo();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterRoundVideo.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterMyMentions implements TLObject {
    static readonly cons = new TLInt(0xc1f8e69a);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterMyMentions | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputMessagesFilterMyMentions.cons)
      )
        return undefined;

      return new InputMessagesFilterMyMentions();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterMyMentions.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterGeo implements TLObject {
    static readonly cons = new TLInt(0xe7026d0d);

    static deserialized(_data: ByteStream): InputMessagesFilterGeo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagesFilterGeo.cons))
        return undefined;

      return new InputMessagesFilterGeo();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterGeo.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterContacts implements TLObject {
    static readonly cons = new TLInt(0xe062db83);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterContacts | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagesFilterContacts.cons))
        return undefined;

      return new InputMessagesFilterContacts();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterContacts.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessagesFilterPinned implements TLObject {
    static readonly cons = new TLInt(0x1bb00451);

    static deserialized(
      _data: ByteStream
    ): InputMessagesFilterPinned | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagesFilterPinned.cons))
        return undefined;

      return new InputMessagesFilterPinned();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagesFilterPinned.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateNewMessage implements TLObject {
    static readonly cons = new TLInt(0x1f2b0afd);

    static deserialized(_data: ByteStream): UpdateNewMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateNewMessage.cons))
        return undefined;

      const message = TLObject.deserialized(_data) as MessageType;
      if (!message) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdateNewMessage(message, pts, ptsCount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateNewMessage.cons];

      _data.push(this.message);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly message: MessageType,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdateMessageID implements TLObject {
    static readonly cons = new TLInt(0x4e90bfd6);

    static deserialized(_data: ByteStream): UpdateMessageID | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateMessageID.cons))
        return undefined;

      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const randomId = TLLong.deserialized(_data);
      if (!randomId) return undefined;

      return new UpdateMessageID(id, randomId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateMessageID.cons];

      _data.push(this.id);
      _data.push(this.randomId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLInt, readonly randomId: TLLong) {}
  }

  export class UpdateDeleteMessages implements TLObject {
    static readonly cons = new TLInt(0xa20db0e5);

    static deserialized(_data: ByteStream): UpdateDeleteMessages | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateDeleteMessages.cons))
        return undefined;

      const messages = TLVector.deserialized(_data, TLInt);
      if (!messages) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdateDeleteMessages(messages, pts, ptsCount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateDeleteMessages.cons];

      _data.push(this.messages);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly messages: TLVector<TLInt>,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdateUserTyping implements TLObject {
    static readonly cons = new TLInt(0xc01e857f);

    static deserialized(_data: ByteStream): UpdateUserTyping | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateUserTyping.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const action = TLObject.deserialized(_data) as SendMessageActionType;
      if (!action) return undefined;

      return new UpdateUserTyping(userId, action);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateUserTyping.cons];

      _data.push(this.userId);
      _data.push(this.action);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: TLLong,
      readonly action: SendMessageActionType
    ) {}
  }

  export class UpdateChatUserTyping implements TLObject {
    static readonly cons = new TLInt(0x83487af0);

    static deserialized(_data: ByteStream): UpdateChatUserTyping | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChatUserTyping.cons))
        return undefined;

      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;
      const fromId = TLObject.deserialized(_data) as PeerType;
      if (!fromId) return undefined;
      const action = TLObject.deserialized(_data) as SendMessageActionType;
      if (!action) return undefined;

      return new UpdateChatUserTyping(chatId, fromId, action);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChatUserTyping.cons];

      _data.push(this.chatId);
      _data.push(this.fromId);
      _data.push(this.action);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly chatId: TLLong,
      readonly fromId: PeerType,
      readonly action: SendMessageActionType
    ) {}
  }

  export class UpdateChatParticipants implements TLObject {
    static readonly cons = new TLInt(0x7761198);

    static deserialized(_data: ByteStream): UpdateChatParticipants | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChatParticipants.cons))
        return undefined;

      const participants = TLObject.deserialized(_data) as ChatParticipantsType;
      if (!participants) return undefined;

      return new UpdateChatParticipants(participants);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChatParticipants.cons];

      _data.push(this.participants);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly participants: ChatParticipantsType) {}
  }

  export class UpdateUserStatus implements TLObject {
    static readonly cons = new TLInt(0xe5bdf8de);

    static deserialized(_data: ByteStream): UpdateUserStatus | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateUserStatus.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const status = TLObject.deserialized(_data) as UserStatusType;
      if (!status) return undefined;

      return new UpdateUserStatus(userId, status);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateUserStatus.cons];

      _data.push(this.userId);
      _data.push(this.status);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly status: UserStatusType) {}
  }

  export class UpdateUserName implements TLObject {
    static readonly cons = new TLInt(0xa7848924);

    static deserialized(_data: ByteStream): UpdateUserName | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateUserName.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const firstName = TLString.deserialized(_data);
      if (!firstName) return undefined;
      const lastName = TLString.deserialized(_data);
      if (!lastName) return undefined;
      const usernames = TLVector.deserialized(_data, API.Username);
      if (!usernames) return undefined;

      return new UpdateUserName(userId, firstName, lastName, usernames);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateUserName.cons];

      _data.push(this.userId);
      _data.push(this.firstName);
      _data.push(this.lastName);
      _data.push(this.usernames);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: TLLong,
      readonly firstName: TLString,
      readonly lastName: TLString,
      readonly usernames: TLVector<API.Username>
    ) {}
  }

  export class UpdateNewAuthorization implements TLObject {
    static readonly cons = new TLInt(0x8951abef);

    static deserialized(_data: ByteStream): UpdateNewAuthorization | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateNewAuthorization.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const unconfirmed = (flags.value & 1) !== 0;
      const hash = TLLong.deserialized(_data);
      if (!hash) return undefined;
      let date: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        date = obj;
      }
      let device: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        device = obj;
      }
      let location: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        location = obj;
      }

      return new UpdateNewAuthorization(
        unconfirmed,
        hash,
        date,
        device,
        location
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateNewAuthorization.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.unconfirmed) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.hash);
      if (this.date) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.date) _data.push(this.date);

      if (this.device) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.device) _data.push(this.device);

      if (this.location) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.location) _data.push(this.location);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly unconfirmed: boolean | undefined,
      readonly hash: TLLong,
      readonly date: TLInt | undefined,
      readonly device: TLString | undefined,
      readonly location: TLString | undefined
    ) {}
  }

  export class UpdateNewEncryptedMessage implements TLObject {
    static readonly cons = new TLInt(0x12bcbd9a);

    static deserialized(
      _data: ByteStream
    ): UpdateNewEncryptedMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateNewEncryptedMessage.cons))
        return undefined;

      const message = TLObject.deserialized(_data) as EncryptedMessageType;
      if (!message) return undefined;
      const qts = TLInt.deserialized(_data);
      if (!qts) return undefined;

      return new UpdateNewEncryptedMessage(message, qts);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateNewEncryptedMessage.cons];

      _data.push(this.message);
      _data.push(this.qts);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly message: EncryptedMessageType, readonly qts: TLInt) {}
  }

  export class UpdateEncryptedChatTyping implements TLObject {
    static readonly cons = new TLInt(0x1710f156);

    static deserialized(
      _data: ByteStream
    ): UpdateEncryptedChatTyping | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateEncryptedChatTyping.cons))
        return undefined;

      const chatId = TLInt.deserialized(_data);
      if (!chatId) return undefined;

      return new UpdateEncryptedChatTyping(chatId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateEncryptedChatTyping.cons];

      _data.push(this.chatId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chatId: TLInt) {}
  }

  export class UpdateEncryption implements TLObject {
    static readonly cons = new TLInt(0xb4a2e88d);

    static deserialized(_data: ByteStream): UpdateEncryption | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateEncryption.cons))
        return undefined;

      const chat = TLObject.deserialized(_data) as EncryptedChatType;
      if (!chat) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new UpdateEncryption(chat, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateEncryption.cons];

      _data.push(this.chat);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chat: EncryptedChatType, readonly date: TLInt) {}
  }

  export class UpdateEncryptedMessagesRead implements TLObject {
    static readonly cons = new TLInt(0x38fe25b7);

    static deserialized(
      _data: ByteStream
    ): UpdateEncryptedMessagesRead | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateEncryptedMessagesRead.cons))
        return undefined;

      const chatId = TLInt.deserialized(_data);
      if (!chatId) return undefined;
      const maxDate = TLInt.deserialized(_data);
      if (!maxDate) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new UpdateEncryptedMessagesRead(chatId, maxDate, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateEncryptedMessagesRead.cons];

      _data.push(this.chatId);
      _data.push(this.maxDate);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly chatId: TLInt,
      readonly maxDate: TLInt,
      readonly date: TLInt
    ) {}
  }

  export class UpdateChatParticipantAdd implements TLObject {
    static readonly cons = new TLInt(0x3dda5451);

    static deserialized(
      _data: ByteStream
    ): UpdateChatParticipantAdd | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChatParticipantAdd.cons))
        return undefined;

      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const inviterId = TLLong.deserialized(_data);
      if (!inviterId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const version = TLInt.deserialized(_data);
      if (!version) return undefined;

      return new UpdateChatParticipantAdd(
        chatId,
        userId,
        inviterId,
        date,
        version
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChatParticipantAdd.cons];

      _data.push(this.chatId);
      _data.push(this.userId);
      _data.push(this.inviterId);
      _data.push(this.date);
      _data.push(this.version);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly chatId: TLLong,
      readonly userId: TLLong,
      readonly inviterId: TLLong,
      readonly date: TLInt,
      readonly version: TLInt
    ) {}
  }

  export class UpdateChatParticipantDelete implements TLObject {
    static readonly cons = new TLInt(0xe32f3d77);

    static deserialized(
      _data: ByteStream
    ): UpdateChatParticipantDelete | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChatParticipantDelete.cons))
        return undefined;

      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const version = TLInt.deserialized(_data);
      if (!version) return undefined;

      return new UpdateChatParticipantDelete(chatId, userId, version);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChatParticipantDelete.cons];

      _data.push(this.chatId);
      _data.push(this.userId);
      _data.push(this.version);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly chatId: TLLong,
      readonly userId: TLLong,
      readonly version: TLInt
    ) {}
  }

  export class UpdateDcOptions implements TLObject {
    static readonly cons = new TLInt(0x8e5e9873);

    static deserialized(_data: ByteStream): UpdateDcOptions | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateDcOptions.cons))
        return undefined;

      const dcOptions = TLVector.deserialized(_data, API.DcOption);
      if (!dcOptions) return undefined;

      return new UpdateDcOptions(dcOptions);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateDcOptions.cons];

      _data.push(this.dcOptions);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly dcOptions: TLVector<API.DcOption>) {}
  }

  export class UpdateNotifySettings implements TLObject {
    static readonly cons = new TLInt(0xbec268ef);

    static deserialized(_data: ByteStream): UpdateNotifySettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateNotifySettings.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as NotifyPeerType;
      if (!peer) return undefined;
      const notifySettings = API.PeerNotifySettings.deserialized(_data);
      if (!notifySettings) return undefined;

      return new UpdateNotifySettings(peer, notifySettings);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateNotifySettings.cons];

      _data.push(this.peer);
      _data.push(this.notifySettings);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: NotifyPeerType,
      readonly notifySettings: API.PeerNotifySettings
    ) {}
  }

  export class UpdateServiceNotification implements TLObject {
    static readonly cons = new TLInt(0xebe46819);

    static deserialized(
      _data: ByteStream
    ): UpdateServiceNotification | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateServiceNotification.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const popup = (flags.value & 1) !== 0;
      let inboxDate: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        inboxDate = obj;
      }
      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      const message = TLString.deserialized(_data);
      if (!message) return undefined;
      const media = TLObject.deserialized(_data) as MessageMediaType;
      if (!media) return undefined;
      const entities = TLVector.deserialized(
        _data
      ) as TLVector<MessageEntityType>;
      if (!entities) return undefined;

      return new UpdateServiceNotification(
        popup,
        inboxDate,
        type,
        message,
        media,
        entities
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateServiceNotification.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.popup) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.inboxDate) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.inboxDate) _data.push(this.inboxDate);

      _data.push(this.type);
      _data.push(this.message);
      _data.push(this.media);
      _data.push(this.entities);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly popup: boolean | undefined,
      readonly inboxDate: TLInt | undefined,
      readonly type: TLString,
      readonly message: TLString,
      readonly media: MessageMediaType,
      readonly entities: TLVector<MessageEntityType>
    ) {}
  }

  export class UpdatePrivacy implements TLObject {
    static readonly cons = new TLInt(0xee3b272a);

    static deserialized(_data: ByteStream): UpdatePrivacy | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatePrivacy.cons))
        return undefined;

      const key = TLObject.deserialized(_data) as PrivacyKeyType;
      if (!key) return undefined;
      const rules = TLVector.deserialized(_data) as TLVector<PrivacyRuleType>;
      if (!rules) return undefined;

      return new UpdatePrivacy(key, rules);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePrivacy.cons];

      _data.push(this.key);
      _data.push(this.rules);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly key: PrivacyKeyType,
      readonly rules: TLVector<PrivacyRuleType>
    ) {}
  }

  export class UpdateUserPhone implements TLObject {
    static readonly cons = new TLInt(0x5492a13);

    static deserialized(_data: ByteStream): UpdateUserPhone | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateUserPhone.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const phone = TLString.deserialized(_data);
      if (!phone) return undefined;

      return new UpdateUserPhone(userId, phone);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateUserPhone.cons];

      _data.push(this.userId);
      _data.push(this.phone);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly phone: TLString) {}
  }

  export class UpdateReadHistoryInbox implements TLObject {
    static readonly cons = new TLInt(0x9c974fdf);

    static deserialized(_data: ByteStream): UpdateReadHistoryInbox | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateReadHistoryInbox.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let folderId: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        folderId = obj;
      }
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const maxId = TLInt.deserialized(_data);
      if (!maxId) return undefined;
      const stillUnreadCount = TLInt.deserialized(_data);
      if (!stillUnreadCount) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdateReadHistoryInbox(
        folderId,
        peer,
        maxId,
        stillUnreadCount,
        pts,
        ptsCount
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateReadHistoryInbox.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.folderId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.folderId) _data.push(this.folderId);

      _data.push(this.peer);
      _data.push(this.maxId);
      _data.push(this.stillUnreadCount);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly folderId: TLInt | undefined,
      readonly peer: PeerType,
      readonly maxId: TLInt,
      readonly stillUnreadCount: TLInt,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdateReadHistoryOutbox implements TLObject {
    static readonly cons = new TLInt(0x2f2f21bf);

    static deserialized(
      _data: ByteStream
    ): UpdateReadHistoryOutbox | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateReadHistoryOutbox.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const maxId = TLInt.deserialized(_data);
      if (!maxId) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdateReadHistoryOutbox(peer, maxId, pts, ptsCount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateReadHistoryOutbox.cons];

      _data.push(this.peer);
      _data.push(this.maxId);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly maxId: TLInt,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdateWebPage implements TLObject {
    static readonly cons = new TLInt(0x7f891213);

    static deserialized(_data: ByteStream): UpdateWebPage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateWebPage.cons))
        return undefined;

      const webpage = TLObject.deserialized(_data) as WebPageType;
      if (!webpage) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdateWebPage(webpage, pts, ptsCount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateWebPage.cons];

      _data.push(this.webpage);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly webpage: WebPageType,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdateReadMessagesContents implements TLObject {
    static readonly cons = new TLInt(0xf8227181);

    static deserialized(
      _data: ByteStream
    ): UpdateReadMessagesContents | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateReadMessagesContents.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const messages = TLVector.deserialized(_data, TLInt);
      if (!messages) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;
      let date: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        date = obj;
      }

      return new UpdateReadMessagesContents(messages, pts, ptsCount, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateReadMessagesContents.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.messages);
      _data.push(this.pts);
      _data.push(this.ptsCount);
      if (this.date) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.date) _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly messages: TLVector<TLInt>,
      readonly pts: TLInt,
      readonly ptsCount: TLInt,
      readonly date: TLInt | undefined
    ) {}
  }

  export class UpdateChannelTooLong implements TLObject {
    static readonly cons = new TLInt(0x108d941f);

    static deserialized(_data: ByteStream): UpdateChannelTooLong | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChannelTooLong.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      let pts: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        pts = obj;
      }

      return new UpdateChannelTooLong(channelId, pts);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChannelTooLong.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.channelId);
      if (this.pts) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.pts) _data.push(this.pts);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly channelId: TLLong, readonly pts: TLInt | undefined) {}
  }

  export class UpdateChannel implements TLObject {
    static readonly cons = new TLInt(0x635b4c09);

    static deserialized(_data: ByteStream): UpdateChannel | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChannel.cons))
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;

      return new UpdateChannel(channelId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChannel.cons];

      _data.push(this.channelId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly channelId: TLLong) {}
  }

  export class UpdateNewChannelMessage implements TLObject {
    static readonly cons = new TLInt(0x62ba04d9);

    static deserialized(
      _data: ByteStream
    ): UpdateNewChannelMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateNewChannelMessage.cons))
        return undefined;

      const message = TLObject.deserialized(_data) as MessageType;
      if (!message) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdateNewChannelMessage(message, pts, ptsCount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateNewChannelMessage.cons];

      _data.push(this.message);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly message: MessageType,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdateReadChannelInbox implements TLObject {
    static readonly cons = new TLInt(0x922e6e10);

    static deserialized(_data: ByteStream): UpdateReadChannelInbox | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateReadChannelInbox.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let folderId: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        folderId = obj;
      }
      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const maxId = TLInt.deserialized(_data);
      if (!maxId) return undefined;
      const stillUnreadCount = TLInt.deserialized(_data);
      if (!stillUnreadCount) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;

      return new UpdateReadChannelInbox(
        folderId,
        channelId,
        maxId,
        stillUnreadCount,
        pts
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateReadChannelInbox.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.folderId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.folderId) _data.push(this.folderId);

      _data.push(this.channelId);
      _data.push(this.maxId);
      _data.push(this.stillUnreadCount);
      _data.push(this.pts);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly folderId: TLInt | undefined,
      readonly channelId: TLLong,
      readonly maxId: TLInt,
      readonly stillUnreadCount: TLInt,
      readonly pts: TLInt
    ) {}
  }

  export class UpdateDeleteChannelMessages implements TLObject {
    static readonly cons = new TLInt(0xc32d5b12);

    static deserialized(
      _data: ByteStream
    ): UpdateDeleteChannelMessages | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateDeleteChannelMessages.cons))
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const messages = TLVector.deserialized(_data, TLInt);
      if (!messages) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdateDeleteChannelMessages(
        channelId,
        messages,
        pts,
        ptsCount
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateDeleteChannelMessages.cons];

      _data.push(this.channelId);
      _data.push(this.messages);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly channelId: TLLong,
      readonly messages: TLVector<TLInt>,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdateChannelMessageViews implements TLObject {
    static readonly cons = new TLInt(0xf226ac08);

    static deserialized(
      _data: ByteStream
    ): UpdateChannelMessageViews | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChannelMessageViews.cons))
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const views = TLInt.deserialized(_data);
      if (!views) return undefined;

      return new UpdateChannelMessageViews(channelId, id, views);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChannelMessageViews.cons];

      _data.push(this.channelId);
      _data.push(this.id);
      _data.push(this.views);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly channelId: TLLong,
      readonly id: TLInt,
      readonly views: TLInt
    ) {}
  }

  export class UpdateChatParticipantAdmin implements TLObject {
    static readonly cons = new TLInt(0xd7ca61a2);

    static deserialized(
      _data: ByteStream
    ): UpdateChatParticipantAdmin | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChatParticipantAdmin.cons))
        return undefined;

      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const isAdmin = TLObject.deserialized(_data) as BoolType;
      if (!isAdmin) return undefined;
      const version = TLInt.deserialized(_data);
      if (!version) return undefined;

      return new UpdateChatParticipantAdmin(chatId, userId, isAdmin, version);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChatParticipantAdmin.cons];

      _data.push(this.chatId);
      _data.push(this.userId);
      _data.push(this.isAdmin);
      _data.push(this.version);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly chatId: TLLong,
      readonly userId: TLLong,
      readonly isAdmin: BoolType,
      readonly version: TLInt
    ) {}
  }

  export class UpdateNewStickerSet implements TLObject {
    static readonly cons = new TLInt(0x688a30aa);

    static deserialized(_data: ByteStream): UpdateNewStickerSet | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateNewStickerSet.cons))
        return undefined;

      const stickerset = TLObject.deserialized(
        _data
      ) as messages.StickerSetType;
      if (!stickerset) return undefined;

      return new UpdateNewStickerSet(stickerset);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateNewStickerSet.cons];

      _data.push(this.stickerset);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly stickerset: messages.StickerSetType) {}
  }

  export class UpdateStickerSetsOrder implements TLObject {
    static readonly cons = new TLInt(0xbb2d201);

    static deserialized(_data: ByteStream): UpdateStickerSetsOrder | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateStickerSetsOrder.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const masks = (flags.value & 1) !== 0;
      const emojis = (flags.value & 2) !== 0;
      const order = TLVector.deserialized(_data, TLLong);
      if (!order) return undefined;

      return new UpdateStickerSetsOrder(masks, emojis, order);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateStickerSetsOrder.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.masks) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.emojis) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.order);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly masks: boolean | undefined,
      readonly emojis: boolean | undefined,
      readonly order: TLVector<TLLong>
    ) {}
  }

  export class UpdateStickerSets implements TLObject {
    static readonly cons = new TLInt(0x31c24808);

    static deserialized(_data: ByteStream): UpdateStickerSets | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateStickerSets.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const masks = (flags.value & 1) !== 0;
      const emojis = (flags.value & 2) !== 0;

      return new UpdateStickerSets(masks, emojis);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateStickerSets.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.masks) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.emojis) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly masks: boolean | undefined,
      readonly emojis: boolean | undefined
    ) {}
  }

  export class UpdateSavedGifs implements TLObject {
    static readonly cons = new TLInt(0x9375341e);

    static deserialized(_data: ByteStream): UpdateSavedGifs | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateSavedGifs.cons))
        return undefined;

      return new UpdateSavedGifs();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateSavedGifs.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateBotInlineQuery implements TLObject {
    static readonly cons = new TLInt(0x496f379c);

    static deserialized(_data: ByteStream): UpdateBotInlineQuery | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateBotInlineQuery.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const queryId = TLLong.deserialized(_data);
      if (!queryId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const query = TLString.deserialized(_data);
      if (!query) return undefined;
      let geo: GeoPointType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as GeoPointType;
        if (!obj) return undefined;
        geo = obj;
      }
      let peerType: InlineQueryPeerTypeType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as InlineQueryPeerTypeType;
        if (!obj) return undefined;
        peerType = obj;
      }
      const offset = TLString.deserialized(_data);
      if (!offset) return undefined;

      return new UpdateBotInlineQuery(
        queryId,
        userId,
        query,
        geo,
        peerType,
        offset
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateBotInlineQuery.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.queryId);
      _data.push(this.userId);
      _data.push(this.query);
      if (this.geo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.geo) _data.push(this.geo);

      if (this.peerType) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.peerType) _data.push(this.peerType);

      _data.push(this.offset);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly queryId: TLLong,
      readonly userId: TLLong,
      readonly query: TLString,
      readonly geo: GeoPointType | undefined,
      readonly peerType: InlineQueryPeerTypeType | undefined,
      readonly offset: TLString
    ) {}
  }

  export class UpdateBotInlineSend implements TLObject {
    static readonly cons = new TLInt(0x12f12a07);

    static deserialized(_data: ByteStream): UpdateBotInlineSend | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateBotInlineSend.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const query = TLString.deserialized(_data);
      if (!query) return undefined;
      let geo: GeoPointType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as GeoPointType;
        if (!obj) return undefined;
        geo = obj;
      }
      const id = TLString.deserialized(_data);
      if (!id) return undefined;
      let msgId: InputBotInlineMessageIDType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as InputBotInlineMessageIDType;
        if (!obj) return undefined;
        msgId = obj;
      }

      return new UpdateBotInlineSend(userId, query, geo, id, msgId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateBotInlineSend.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.userId);
      _data.push(this.query);
      if (this.geo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.geo) _data.push(this.geo);

      _data.push(this.id);
      if (this.msgId) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.msgId) _data.push(this.msgId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: TLLong,
      readonly query: TLString,
      readonly geo: GeoPointType | undefined,
      readonly id: TLString,
      readonly msgId: InputBotInlineMessageIDType | undefined
    ) {}
  }

  export class UpdateEditChannelMessage implements TLObject {
    static readonly cons = new TLInt(0x1b3f4df7);

    static deserialized(
      _data: ByteStream
    ): UpdateEditChannelMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateEditChannelMessage.cons))
        return undefined;

      const message = TLObject.deserialized(_data) as MessageType;
      if (!message) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdateEditChannelMessage(message, pts, ptsCount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateEditChannelMessage.cons];

      _data.push(this.message);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly message: MessageType,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdateBotCallbackQuery implements TLObject {
    static readonly cons = new TLInt(0xb9cfc48d);

    static deserialized(_data: ByteStream): UpdateBotCallbackQuery | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateBotCallbackQuery.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const queryId = TLLong.deserialized(_data);
      if (!queryId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;
      const chatInstance = TLLong.deserialized(_data);
      if (!chatInstance) return undefined;
      let data: TLBytes | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLBytes.deserialized(_data);
        if (!obj) return undefined;
        data = obj;
      }
      let gameShortName: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        gameShortName = obj;
      }

      return new UpdateBotCallbackQuery(
        queryId,
        userId,
        peer,
        msgId,
        chatInstance,
        data,
        gameShortName
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateBotCallbackQuery.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.queryId);
      _data.push(this.userId);
      _data.push(this.peer);
      _data.push(this.msgId);
      _data.push(this.chatInstance);
      if (this.data) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.data) _data.push(this.data);

      if (this.gameShortName) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.gameShortName) _data.push(this.gameShortName);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly queryId: TLLong,
      readonly userId: TLLong,
      readonly peer: PeerType,
      readonly msgId: TLInt,
      readonly chatInstance: TLLong,
      readonly data: TLBytes | undefined,
      readonly gameShortName: TLString | undefined
    ) {}
  }

  export class UpdateEditMessage implements TLObject {
    static readonly cons = new TLInt(0xe40370a3);

    static deserialized(_data: ByteStream): UpdateEditMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateEditMessage.cons))
        return undefined;

      const message = TLObject.deserialized(_data) as MessageType;
      if (!message) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdateEditMessage(message, pts, ptsCount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateEditMessage.cons];

      _data.push(this.message);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly message: MessageType,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdateInlineBotCallbackQuery implements TLObject {
    static readonly cons = new TLInt(0x691e9052);

    static deserialized(
      _data: ByteStream
    ): UpdateInlineBotCallbackQuery | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdateInlineBotCallbackQuery.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const queryId = TLLong.deserialized(_data);
      if (!queryId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const msgId = TLObject.deserialized(_data) as InputBotInlineMessageIDType;
      if (!msgId) return undefined;
      const chatInstance = TLLong.deserialized(_data);
      if (!chatInstance) return undefined;
      let data: TLBytes | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLBytes.deserialized(_data);
        if (!obj) return undefined;
        data = obj;
      }
      let gameShortName: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        gameShortName = obj;
      }

      return new UpdateInlineBotCallbackQuery(
        queryId,
        userId,
        msgId,
        chatInstance,
        data,
        gameShortName
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateInlineBotCallbackQuery.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.queryId);
      _data.push(this.userId);
      _data.push(this.msgId);
      _data.push(this.chatInstance);
      if (this.data) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.data) _data.push(this.data);

      if (this.gameShortName) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.gameShortName) _data.push(this.gameShortName);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly queryId: TLLong,
      readonly userId: TLLong,
      readonly msgId: InputBotInlineMessageIDType,
      readonly chatInstance: TLLong,
      readonly data: TLBytes | undefined,
      readonly gameShortName: TLString | undefined
    ) {}
  }

  export class UpdateReadChannelOutbox implements TLObject {
    static readonly cons = new TLInt(0xb75f99a9);

    static deserialized(
      _data: ByteStream
    ): UpdateReadChannelOutbox | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateReadChannelOutbox.cons))
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const maxId = TLInt.deserialized(_data);
      if (!maxId) return undefined;

      return new UpdateReadChannelOutbox(channelId, maxId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateReadChannelOutbox.cons];

      _data.push(this.channelId);
      _data.push(this.maxId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly channelId: TLLong, readonly maxId: TLInt) {}
  }

  export class UpdateDraftMessage implements TLObject {
    static readonly cons = new TLInt(0x1b49ec6d);

    static deserialized(_data: ByteStream): UpdateDraftMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateDraftMessage.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      let topMsgId: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        topMsgId = obj;
      }
      const draft = TLObject.deserialized(_data) as DraftMessageType;
      if (!draft) return undefined;

      return new UpdateDraftMessage(peer, topMsgId, draft);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateDraftMessage.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.peer);
      if (this.topMsgId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.topMsgId) _data.push(this.topMsgId);

      _data.push(this.draft);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly topMsgId: TLInt | undefined,
      readonly draft: DraftMessageType
    ) {}
  }

  export class UpdateReadFeaturedStickers implements TLObject {
    static readonly cons = new TLInt(0x571d2742);

    static deserialized(
      _data: ByteStream
    ): UpdateReadFeaturedStickers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateReadFeaturedStickers.cons))
        return undefined;

      return new UpdateReadFeaturedStickers();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateReadFeaturedStickers.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateRecentStickers implements TLObject {
    static readonly cons = new TLInt(0x9a422c20);

    static deserialized(_data: ByteStream): UpdateRecentStickers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateRecentStickers.cons))
        return undefined;

      return new UpdateRecentStickers();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateRecentStickers.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateConfig implements TLObject {
    static readonly cons = new TLInt(0xa229dd06);

    static deserialized(_data: ByteStream): UpdateConfig | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateConfig.cons))
        return undefined;

      return new UpdateConfig();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateConfig.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdatePtsChanged implements TLObject {
    static readonly cons = new TLInt(0x3354678f);

    static deserialized(_data: ByteStream): UpdatePtsChanged | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatePtsChanged.cons))
        return undefined;

      return new UpdatePtsChanged();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePtsChanged.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateChannelWebPage implements TLObject {
    static readonly cons = new TLInt(0x2f2ba99f);

    static deserialized(_data: ByteStream): UpdateChannelWebPage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChannelWebPage.cons))
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const webpage = TLObject.deserialized(_data) as WebPageType;
      if (!webpage) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdateChannelWebPage(channelId, webpage, pts, ptsCount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChannelWebPage.cons];

      _data.push(this.channelId);
      _data.push(this.webpage);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly channelId: TLLong,
      readonly webpage: WebPageType,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdateDialogPinned implements TLObject {
    static readonly cons = new TLInt(0x6e6fe51c);

    static deserialized(_data: ByteStream): UpdateDialogPinned | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateDialogPinned.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const pinned = (flags.value & 1) !== 0;
      let folderId: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        folderId = obj;
      }
      const peer = TLObject.deserialized(_data) as DialogPeerType;
      if (!peer) return undefined;

      return new UpdateDialogPinned(pinned, folderId, peer);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateDialogPinned.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.pinned) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.folderId) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.folderId) _data.push(this.folderId);

      _data.push(this.peer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly pinned: boolean | undefined,
      readonly folderId: TLInt | undefined,
      readonly peer: DialogPeerType
    ) {}
  }

  export class UpdatePinnedDialogs implements TLObject {
    static readonly cons = new TLInt(0xfa0f3ca2);

    static deserialized(_data: ByteStream): UpdatePinnedDialogs | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatePinnedDialogs.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let folderId: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        folderId = obj;
      }
      let order: TLVector<DialogPeerType> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<DialogPeerType>;
        if (!obj) return undefined;
        order = obj;
      }

      return new UpdatePinnedDialogs(folderId, order);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePinnedDialogs.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.folderId) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.folderId) _data.push(this.folderId);

      if (this.order) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.order) _data.push(this.order);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly folderId: TLInt | undefined,
      readonly order: TLVector<DialogPeerType> | undefined
    ) {}
  }

  export class UpdateBotWebhookJSON implements TLObject {
    static readonly cons = new TLInt(0x8317c0c3);

    static deserialized(_data: ByteStream): UpdateBotWebhookJSON | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateBotWebhookJSON.cons))
        return undefined;

      const data = API.DataJSON.deserialized(_data);
      if (!data) return undefined;

      return new UpdateBotWebhookJSON(data);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateBotWebhookJSON.cons];

      _data.push(this.data);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly data: API.DataJSON) {}
  }

  export class UpdateBotWebhookJSONQuery implements TLObject {
    static readonly cons = new TLInt(0x9b9240a6);

    static deserialized(
      _data: ByteStream
    ): UpdateBotWebhookJSONQuery | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateBotWebhookJSONQuery.cons))
        return undefined;

      const queryId = TLLong.deserialized(_data);
      if (!queryId) return undefined;
      const data = API.DataJSON.deserialized(_data);
      if (!data) return undefined;
      const timeout = TLInt.deserialized(_data);
      if (!timeout) return undefined;

      return new UpdateBotWebhookJSONQuery(queryId, data, timeout);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateBotWebhookJSONQuery.cons];

      _data.push(this.queryId);
      _data.push(this.data);
      _data.push(this.timeout);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly queryId: TLLong,
      readonly data: API.DataJSON,
      readonly timeout: TLInt
    ) {}
  }

  export class UpdateBotShippingQuery implements TLObject {
    static readonly cons = new TLInt(0xb5aefd7d);

    static deserialized(_data: ByteStream): UpdateBotShippingQuery | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateBotShippingQuery.cons))
        return undefined;

      const queryId = TLLong.deserialized(_data);
      if (!queryId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const payload = TLBytes.deserialized(_data);
      if (!payload) return undefined;
      const shippingAddress = API.PostAddress.deserialized(_data);
      if (!shippingAddress) return undefined;

      return new UpdateBotShippingQuery(
        queryId,
        userId,
        payload,
        shippingAddress
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateBotShippingQuery.cons];

      _data.push(this.queryId);
      _data.push(this.userId);
      _data.push(this.payload);
      _data.push(this.shippingAddress);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly queryId: TLLong,
      readonly userId: TLLong,
      readonly payload: TLBytes,
      readonly shippingAddress: API.PostAddress
    ) {}
  }

  export class UpdateBotPrecheckoutQuery implements TLObject {
    static readonly cons = new TLInt(0x8caa9a96);

    static deserialized(
      _data: ByteStream
    ): UpdateBotPrecheckoutQuery | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateBotPrecheckoutQuery.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const queryId = TLLong.deserialized(_data);
      if (!queryId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const payload = TLBytes.deserialized(_data);
      if (!payload) return undefined;
      let info: API.PaymentRequestedInfo | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as API.PaymentRequestedInfo;
        if (!obj) return undefined;
        info = obj;
      }
      let shippingOptionId: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        shippingOptionId = obj;
      }
      const currency = TLString.deserialized(_data);
      if (!currency) return undefined;
      const totalAmount = TLLong.deserialized(_data);
      if (!totalAmount) return undefined;

      return new UpdateBotPrecheckoutQuery(
        queryId,
        userId,
        payload,
        info,
        shippingOptionId,
        currency,
        totalAmount
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateBotPrecheckoutQuery.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.queryId);
      _data.push(this.userId);
      _data.push(this.payload);
      if (this.info) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.info) _data.push(this.info);

      if (this.shippingOptionId) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.shippingOptionId) _data.push(this.shippingOptionId);

      _data.push(this.currency);
      _data.push(this.totalAmount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly queryId: TLLong,
      readonly userId: TLLong,
      readonly payload: TLBytes,
      readonly info: API.PaymentRequestedInfo | undefined,
      readonly shippingOptionId: TLString | undefined,
      readonly currency: TLString,
      readonly totalAmount: TLLong
    ) {}
  }

  export class UpdatePhoneCall implements TLObject {
    static readonly cons = new TLInt(0xab0f6b1e);

    static deserialized(_data: ByteStream): UpdatePhoneCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatePhoneCall.cons))
        return undefined;

      const phoneCall = TLObject.deserialized(_data) as PhoneCallType;
      if (!phoneCall) return undefined;

      return new UpdatePhoneCall(phoneCall);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePhoneCall.cons];

      _data.push(this.phoneCall);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly phoneCall: PhoneCallType) {}
  }

  export class UpdateLangPackTooLong implements TLObject {
    static readonly cons = new TLInt(0x46560264);

    static deserialized(_data: ByteStream): UpdateLangPackTooLong | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateLangPackTooLong.cons))
        return undefined;

      const langCode = TLString.deserialized(_data);
      if (!langCode) return undefined;

      return new UpdateLangPackTooLong(langCode);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateLangPackTooLong.cons];

      _data.push(this.langCode);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly langCode: TLString) {}
  }

  export class UpdateLangPack implements TLObject {
    static readonly cons = new TLInt(0x56022f4d);

    static deserialized(_data: ByteStream): UpdateLangPack | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateLangPack.cons))
        return undefined;

      const difference = API.LangPackDifference.deserialized(_data);
      if (!difference) return undefined;

      return new UpdateLangPack(difference);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateLangPack.cons];

      _data.push(this.difference);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly difference: API.LangPackDifference) {}
  }

  export class UpdateFavedStickers implements TLObject {
    static readonly cons = new TLInt(0xe511996d);

    static deserialized(_data: ByteStream): UpdateFavedStickers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateFavedStickers.cons))
        return undefined;

      return new UpdateFavedStickers();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateFavedStickers.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateChannelReadMessagesContents implements TLObject {
    static readonly cons = new TLInt(0xea29055d);

    static deserialized(
      _data: ByteStream
    ): UpdateChannelReadMessagesContents | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdateChannelReadMessagesContents.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      let topMsgId: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        topMsgId = obj;
      }
      const messages = TLVector.deserialized(_data, TLInt);
      if (!messages) return undefined;

      return new UpdateChannelReadMessagesContents(
        channelId,
        topMsgId,
        messages
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        UpdateChannelReadMessagesContents.cons,
      ];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.channelId);
      if (this.topMsgId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.topMsgId) _data.push(this.topMsgId);

      _data.push(this.messages);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly channelId: TLLong,
      readonly topMsgId: TLInt | undefined,
      readonly messages: TLVector<TLInt>
    ) {}
  }

  export class UpdateContactsReset implements TLObject {
    static readonly cons = new TLInt(0x7084a7be);

    static deserialized(_data: ByteStream): UpdateContactsReset | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateContactsReset.cons))
        return undefined;

      return new UpdateContactsReset();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateContactsReset.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateChannelAvailableMessages implements TLObject {
    static readonly cons = new TLInt(0xb23fc698);

    static deserialized(
      _data: ByteStream
    ): UpdateChannelAvailableMessages | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdateChannelAvailableMessages.cons)
      )
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const availableMinId = TLInt.deserialized(_data);
      if (!availableMinId) return undefined;

      return new UpdateChannelAvailableMessages(channelId, availableMinId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        UpdateChannelAvailableMessages.cons,
      ];

      _data.push(this.channelId);
      _data.push(this.availableMinId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly channelId: TLLong, readonly availableMinId: TLInt) {}
  }

  export class UpdateDialogUnreadMark implements TLObject {
    static readonly cons = new TLInt(0xe16459c3);

    static deserialized(_data: ByteStream): UpdateDialogUnreadMark | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateDialogUnreadMark.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const unread = (flags.value & 1) !== 0;
      const peer = TLObject.deserialized(_data) as DialogPeerType;
      if (!peer) return undefined;

      return new UpdateDialogUnreadMark(unread, peer);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateDialogUnreadMark.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.unread) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.peer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly unread: boolean | undefined,
      readonly peer: DialogPeerType
    ) {}
  }

  export class UpdateMessagePoll implements TLObject {
    static readonly cons = new TLInt(0xaca1657b);

    static deserialized(_data: ByteStream): UpdateMessagePoll | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateMessagePoll.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const pollId = TLLong.deserialized(_data);
      if (!pollId) return undefined;
      let poll: API.Poll | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as API.Poll;
        if (!obj) return undefined;
        poll = obj;
      }
      const results = API.PollResults.deserialized(_data);
      if (!results) return undefined;

      return new UpdateMessagePoll(pollId, poll, results);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateMessagePoll.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.pollId);
      if (this.poll) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.poll) _data.push(this.poll);

      _data.push(this.results);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly pollId: TLLong,
      readonly poll: API.Poll | undefined,
      readonly results: API.PollResults
    ) {}
  }

  export class UpdateChatDefaultBannedRights implements TLObject {
    static readonly cons = new TLInt(0x54c01850);

    static deserialized(
      _data: ByteStream
    ): UpdateChatDefaultBannedRights | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdateChatDefaultBannedRights.cons)
      )
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const defaultBannedRights = API.ChatBannedRights.deserialized(_data);
      if (!defaultBannedRights) return undefined;
      const version = TLInt.deserialized(_data);
      if (!version) return undefined;

      return new UpdateChatDefaultBannedRights(
        peer,
        defaultBannedRights,
        version
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChatDefaultBannedRights.cons];

      _data.push(this.peer);
      _data.push(this.defaultBannedRights);
      _data.push(this.version);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly defaultBannedRights: API.ChatBannedRights,
      readonly version: TLInt
    ) {}
  }

  export class UpdateFolderPeers implements TLObject {
    static readonly cons = new TLInt(0x19360dc0);

    static deserialized(_data: ByteStream): UpdateFolderPeers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateFolderPeers.cons))
        return undefined;

      const folderPeers = TLVector.deserialized(_data, API.FolderPeer);
      if (!folderPeers) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdateFolderPeers(folderPeers, pts, ptsCount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateFolderPeers.cons];

      _data.push(this.folderPeers);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly folderPeers: TLVector<API.FolderPeer>,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdatePeerSettings implements TLObject {
    static readonly cons = new TLInt(0x6a7e7366);

    static deserialized(_data: ByteStream): UpdatePeerSettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatePeerSettings.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const settings = API.PeerSettings.deserialized(_data);
      if (!settings) return undefined;

      return new UpdatePeerSettings(peer, settings);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePeerSettings.cons];

      _data.push(this.peer);
      _data.push(this.settings);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType, readonly settings: API.PeerSettings) {}
  }

  export class UpdatePeerLocated implements TLObject {
    static readonly cons = new TLInt(0xb4afcfb0);

    static deserialized(_data: ByteStream): UpdatePeerLocated | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatePeerLocated.cons))
        return undefined;

      const peers = TLVector.deserialized(_data) as TLVector<PeerLocatedType>;
      if (!peers) return undefined;

      return new UpdatePeerLocated(peers);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePeerLocated.cons];

      _data.push(this.peers);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peers: TLVector<PeerLocatedType>) {}
  }

  export class UpdateNewScheduledMessage implements TLObject {
    static readonly cons = new TLInt(0x39a51dfb);

    static deserialized(
      _data: ByteStream
    ): UpdateNewScheduledMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateNewScheduledMessage.cons))
        return undefined;

      const message = TLObject.deserialized(_data) as MessageType;
      if (!message) return undefined;

      return new UpdateNewScheduledMessage(message);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateNewScheduledMessage.cons];

      _data.push(this.message);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly message: MessageType) {}
  }

  export class UpdateDeleteScheduledMessages implements TLObject {
    static readonly cons = new TLInt(0x90866cee);

    static deserialized(
      _data: ByteStream
    ): UpdateDeleteScheduledMessages | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdateDeleteScheduledMessages.cons)
      )
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const messages = TLVector.deserialized(_data, TLInt);
      if (!messages) return undefined;

      return new UpdateDeleteScheduledMessages(peer, messages);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateDeleteScheduledMessages.cons];

      _data.push(this.peer);
      _data.push(this.messages);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType, readonly messages: TLVector<TLInt>) {}
  }

  export class UpdateTheme implements TLObject {
    static readonly cons = new TLInt(0x8216fba3);

    static deserialized(_data: ByteStream): UpdateTheme | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateTheme.cons))
        return undefined;

      const theme = API.Theme.deserialized(_data);
      if (!theme) return undefined;

      return new UpdateTheme(theme);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateTheme.cons];

      _data.push(this.theme);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly theme: API.Theme) {}
  }

  export class UpdateGeoLiveViewed implements TLObject {
    static readonly cons = new TLInt(0x871fb939);

    static deserialized(_data: ByteStream): UpdateGeoLiveViewed | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateGeoLiveViewed.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;

      return new UpdateGeoLiveViewed(peer, msgId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateGeoLiveViewed.cons];

      _data.push(this.peer);
      _data.push(this.msgId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType, readonly msgId: TLInt) {}
  }

  export class UpdateLoginToken implements TLObject {
    static readonly cons = new TLInt(0x564fe691);

    static deserialized(_data: ByteStream): UpdateLoginToken | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateLoginToken.cons))
        return undefined;

      return new UpdateLoginToken();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateLoginToken.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateMessagePollVote implements TLObject {
    static readonly cons = new TLInt(0x24f40e77);

    static deserialized(_data: ByteStream): UpdateMessagePollVote | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateMessagePollVote.cons))
        return undefined;

      const pollId = TLLong.deserialized(_data);
      if (!pollId) return undefined;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const options = TLVector.deserialized(_data, TLBytes);
      if (!options) return undefined;
      const qts = TLInt.deserialized(_data);
      if (!qts) return undefined;

      return new UpdateMessagePollVote(pollId, peer, options, qts);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateMessagePollVote.cons];

      _data.push(this.pollId);
      _data.push(this.peer);
      _data.push(this.options);
      _data.push(this.qts);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly pollId: TLLong,
      readonly peer: PeerType,
      readonly options: TLVector<TLBytes>,
      readonly qts: TLInt
    ) {}
  }

  export class UpdateDialogFilter implements TLObject {
    static readonly cons = new TLInt(0x26ffde7d);

    static deserialized(_data: ByteStream): UpdateDialogFilter | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateDialogFilter.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      let filter: DialogFilterType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as DialogFilterType;
        if (!obj) return undefined;
        filter = obj;
      }

      return new UpdateDialogFilter(id, filter);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateDialogFilter.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.id);
      if (this.filter) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.filter) _data.push(this.filter);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLInt,
      readonly filter: DialogFilterType | undefined
    ) {}
  }

  export class UpdateDialogFilterOrder implements TLObject {
    static readonly cons = new TLInt(0xa5d72105);

    static deserialized(
      _data: ByteStream
    ): UpdateDialogFilterOrder | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateDialogFilterOrder.cons))
        return undefined;

      const order = TLVector.deserialized(_data, TLInt);
      if (!order) return undefined;

      return new UpdateDialogFilterOrder(order);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateDialogFilterOrder.cons];

      _data.push(this.order);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly order: TLVector<TLInt>) {}
  }

  export class UpdateDialogFilters implements TLObject {
    static readonly cons = new TLInt(0x3504914f);

    static deserialized(_data: ByteStream): UpdateDialogFilters | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateDialogFilters.cons))
        return undefined;

      return new UpdateDialogFilters();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateDialogFilters.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdatePhoneCallSignalingData implements TLObject {
    static readonly cons = new TLInt(0x2661bf09);

    static deserialized(
      _data: ByteStream
    ): UpdatePhoneCallSignalingData | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdatePhoneCallSignalingData.cons)
      )
        return undefined;

      const phoneCallId = TLLong.deserialized(_data);
      if (!phoneCallId) return undefined;
      const data = TLBytes.deserialized(_data);
      if (!data) return undefined;

      return new UpdatePhoneCallSignalingData(phoneCallId, data);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePhoneCallSignalingData.cons];

      _data.push(this.phoneCallId);
      _data.push(this.data);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly phoneCallId: TLLong, readonly data: TLBytes) {}
  }

  export class UpdateChannelMessageForwards implements TLObject {
    static readonly cons = new TLInt(0xd29a27f4);

    static deserialized(
      _data: ByteStream
    ): UpdateChannelMessageForwards | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdateChannelMessageForwards.cons)
      )
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const forwards = TLInt.deserialized(_data);
      if (!forwards) return undefined;

      return new UpdateChannelMessageForwards(channelId, id, forwards);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChannelMessageForwards.cons];

      _data.push(this.channelId);
      _data.push(this.id);
      _data.push(this.forwards);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly channelId: TLLong,
      readonly id: TLInt,
      readonly forwards: TLInt
    ) {}
  }

  export class UpdateReadChannelDiscussionInbox implements TLObject {
    static readonly cons = new TLInt(0xd6b19546);

    static deserialized(
      _data: ByteStream
    ): UpdateReadChannelDiscussionInbox | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdateReadChannelDiscussionInbox.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const topMsgId = TLInt.deserialized(_data);
      if (!topMsgId) return undefined;
      const readMaxId = TLInt.deserialized(_data);
      if (!readMaxId) return undefined;
      let broadcastId: TLLong | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        broadcastId = obj;
      }
      let broadcastPost: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        broadcastPost = obj;
      }

      return new UpdateReadChannelDiscussionInbox(
        channelId,
        topMsgId,
        readMaxId,
        broadcastId,
        broadcastPost
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        UpdateReadChannelDiscussionInbox.cons,
      ];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.channelId);
      _data.push(this.topMsgId);
      _data.push(this.readMaxId);
      if (this.broadcastId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.broadcastId) _data.push(this.broadcastId);

      if (this.broadcastPost) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.broadcastPost) _data.push(this.broadcastPost);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly channelId: TLLong,
      readonly topMsgId: TLInt,
      readonly readMaxId: TLInt,
      readonly broadcastId: TLLong | undefined,
      readonly broadcastPost: TLInt | undefined
    ) {}
  }

  export class UpdateReadChannelDiscussionOutbox implements TLObject {
    static readonly cons = new TLInt(0x695c9e7c);

    static deserialized(
      _data: ByteStream
    ): UpdateReadChannelDiscussionOutbox | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdateReadChannelDiscussionOutbox.cons)
      )
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const topMsgId = TLInt.deserialized(_data);
      if (!topMsgId) return undefined;
      const readMaxId = TLInt.deserialized(_data);
      if (!readMaxId) return undefined;

      return new UpdateReadChannelDiscussionOutbox(
        channelId,
        topMsgId,
        readMaxId
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        UpdateReadChannelDiscussionOutbox.cons,
      ];

      _data.push(this.channelId);
      _data.push(this.topMsgId);
      _data.push(this.readMaxId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly channelId: TLLong,
      readonly topMsgId: TLInt,
      readonly readMaxId: TLInt
    ) {}
  }

  export class UpdatePeerBlocked implements TLObject {
    static readonly cons = new TLInt(0xebe07752);

    static deserialized(_data: ByteStream): UpdatePeerBlocked | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatePeerBlocked.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const blocked = (flags.value & 1) !== 0;
      const blockedMyStoriesFrom = (flags.value & 2) !== 0;
      const peerId = TLObject.deserialized(_data) as PeerType;
      if (!peerId) return undefined;

      return new UpdatePeerBlocked(blocked, blockedMyStoriesFrom, peerId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePeerBlocked.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.blocked) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.blockedMyStoriesFrom) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.peerId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly blocked: boolean | undefined,
      readonly blockedMyStoriesFrom: boolean | undefined,
      readonly peerId: PeerType
    ) {}
  }

  export class UpdateChannelUserTyping implements TLObject {
    static readonly cons = new TLInt(0x8c88c923);

    static deserialized(
      _data: ByteStream
    ): UpdateChannelUserTyping | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChannelUserTyping.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      let topMsgId: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        topMsgId = obj;
      }
      const fromId = TLObject.deserialized(_data) as PeerType;
      if (!fromId) return undefined;
      const action = TLObject.deserialized(_data) as SendMessageActionType;
      if (!action) return undefined;

      return new UpdateChannelUserTyping(channelId, topMsgId, fromId, action);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChannelUserTyping.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.channelId);
      if (this.topMsgId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.topMsgId) _data.push(this.topMsgId);

      _data.push(this.fromId);
      _data.push(this.action);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly channelId: TLLong,
      readonly topMsgId: TLInt | undefined,
      readonly fromId: PeerType,
      readonly action: SendMessageActionType
    ) {}
  }

  export class UpdatePinnedMessages implements TLObject {
    static readonly cons = new TLInt(0xed85eab5);

    static deserialized(_data: ByteStream): UpdatePinnedMessages | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatePinnedMessages.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const pinned = (flags.value & 1) !== 0;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const messages = TLVector.deserialized(_data, TLInt);
      if (!messages) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdatePinnedMessages(pinned, peer, messages, pts, ptsCount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePinnedMessages.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.pinned) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.peer);
      _data.push(this.messages);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly pinned: boolean | undefined,
      readonly peer: PeerType,
      readonly messages: TLVector<TLInt>,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdatePinnedChannelMessages implements TLObject {
    static readonly cons = new TLInt(0x5bb98608);

    static deserialized(
      _data: ByteStream
    ): UpdatePinnedChannelMessages | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatePinnedChannelMessages.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const pinned = (flags.value & 1) !== 0;
      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const messages = TLVector.deserialized(_data, TLInt);
      if (!messages) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;

      return new UpdatePinnedChannelMessages(
        pinned,
        channelId,
        messages,
        pts,
        ptsCount
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePinnedChannelMessages.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.pinned) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.channelId);
      _data.push(this.messages);
      _data.push(this.pts);
      _data.push(this.ptsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly pinned: boolean | undefined,
      readonly channelId: TLLong,
      readonly messages: TLVector<TLInt>,
      readonly pts: TLInt,
      readonly ptsCount: TLInt
    ) {}
  }

  export class UpdateChat implements TLObject {
    static readonly cons = new TLInt(0xf89a6a4e);

    static deserialized(_data: ByteStream): UpdateChat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChat.cons))
        return undefined;

      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;

      return new UpdateChat(chatId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChat.cons];

      _data.push(this.chatId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chatId: TLLong) {}
  }

  export class UpdateGroupCallParticipants implements TLObject {
    static readonly cons = new TLInt(0xf2ebdb4e);

    static deserialized(
      _data: ByteStream
    ): UpdateGroupCallParticipants | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateGroupCallParticipants.cons))
        return undefined;

      const call = API.InputGroupCall.deserialized(_data);
      if (!call) return undefined;
      const participants = TLVector.deserialized(
        _data,
        API.GroupCallParticipant
      );
      if (!participants) return undefined;
      const version = TLInt.deserialized(_data);
      if (!version) return undefined;

      return new UpdateGroupCallParticipants(call, participants, version);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateGroupCallParticipants.cons];

      _data.push(this.call);
      _data.push(this.participants);
      _data.push(this.version);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly call: API.InputGroupCall,
      readonly participants: TLVector<API.GroupCallParticipant>,
      readonly version: TLInt
    ) {}
  }

  export class UpdateGroupCall implements TLObject {
    static readonly cons = new TLInt(0x14b24500);

    static deserialized(_data: ByteStream): UpdateGroupCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateGroupCall.cons))
        return undefined;

      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;
      const call = TLObject.deserialized(_data) as GroupCallType;
      if (!call) return undefined;

      return new UpdateGroupCall(chatId, call);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateGroupCall.cons];

      _data.push(this.chatId);
      _data.push(this.call);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chatId: TLLong, readonly call: GroupCallType) {}
  }

  export class UpdatePeerHistoryTTL implements TLObject {
    static readonly cons = new TLInt(0xbb9bb9a5);

    static deserialized(_data: ByteStream): UpdatePeerHistoryTTL | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatePeerHistoryTTL.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      let ttlPeriod: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlPeriod = obj;
      }

      return new UpdatePeerHistoryTTL(peer, ttlPeriod);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePeerHistoryTTL.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.peer);
      if (this.ttlPeriod) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.ttlPeriod) _data.push(this.ttlPeriod);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly ttlPeriod: TLInt | undefined
    ) {}
  }

  export class UpdateChatParticipant implements TLObject {
    static readonly cons = new TLInt(0xd087663a);

    static deserialized(_data: ByteStream): UpdateChatParticipant | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChatParticipant.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const actorId = TLLong.deserialized(_data);
      if (!actorId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      let prevParticipant: ChatParticipantType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as ChatParticipantType;
        if (!obj) return undefined;
        prevParticipant = obj;
      }
      let newParticipant: ChatParticipantType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as ChatParticipantType;
        if (!obj) return undefined;
        newParticipant = obj;
      }
      let invite: ExportedChatInviteType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ExportedChatInviteType;
        if (!obj) return undefined;
        invite = obj;
      }
      const qts = TLInt.deserialized(_data);
      if (!qts) return undefined;

      return new UpdateChatParticipant(
        chatId,
        date,
        actorId,
        userId,
        prevParticipant,
        newParticipant,
        invite,
        qts
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChatParticipant.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.chatId);
      _data.push(this.date);
      _data.push(this.actorId);
      _data.push(this.userId);
      if (this.prevParticipant) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.prevParticipant) _data.push(this.prevParticipant);

      if (this.newParticipant) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.newParticipant) _data.push(this.newParticipant);

      if (this.invite) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.invite) _data.push(this.invite);

      _data.push(this.qts);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly chatId: TLLong,
      readonly date: TLInt,
      readonly actorId: TLLong,
      readonly userId: TLLong,
      readonly prevParticipant: ChatParticipantType | undefined,
      readonly newParticipant: ChatParticipantType | undefined,
      readonly invite: ExportedChatInviteType | undefined,
      readonly qts: TLInt
    ) {}
  }

  export class UpdateChannelParticipant implements TLObject {
    static readonly cons = new TLInt(0x985d3abb);

    static deserialized(
      _data: ByteStream
    ): UpdateChannelParticipant | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChannelParticipant.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const viaChatlist = (flags.value & 8) !== 0;
      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const actorId = TLLong.deserialized(_data);
      if (!actorId) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      let prevParticipant: ChannelParticipantType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as ChannelParticipantType;
        if (!obj) return undefined;
        prevParticipant = obj;
      }
      let newParticipant: ChannelParticipantType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as ChannelParticipantType;
        if (!obj) return undefined;
        newParticipant = obj;
      }
      let invite: ExportedChatInviteType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ExportedChatInviteType;
        if (!obj) return undefined;
        invite = obj;
      }
      const qts = TLInt.deserialized(_data);
      if (!qts) return undefined;

      return new UpdateChannelParticipant(
        viaChatlist,
        channelId,
        date,
        actorId,
        userId,
        prevParticipant,
        newParticipant,
        invite,
        qts
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChannelParticipant.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.viaChatlist) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      _data.push(this.channelId);
      _data.push(this.date);
      _data.push(this.actorId);
      _data.push(this.userId);
      if (this.prevParticipant) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.prevParticipant) _data.push(this.prevParticipant);

      if (this.newParticipant) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.newParticipant) _data.push(this.newParticipant);

      if (this.invite) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.invite) _data.push(this.invite);

      _data.push(this.qts);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly viaChatlist: boolean | undefined,
      readonly channelId: TLLong,
      readonly date: TLInt,
      readonly actorId: TLLong,
      readonly userId: TLLong,
      readonly prevParticipant: ChannelParticipantType | undefined,
      readonly newParticipant: ChannelParticipantType | undefined,
      readonly invite: ExportedChatInviteType | undefined,
      readonly qts: TLInt
    ) {}
  }

  export class UpdateBotStopped implements TLObject {
    static readonly cons = new TLInt(0xc4870a49);

    static deserialized(_data: ByteStream): UpdateBotStopped | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateBotStopped.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const stopped = TLObject.deserialized(_data) as BoolType;
      if (!stopped) return undefined;
      const qts = TLInt.deserialized(_data);
      if (!qts) return undefined;

      return new UpdateBotStopped(userId, date, stopped, qts);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateBotStopped.cons];

      _data.push(this.userId);
      _data.push(this.date);
      _data.push(this.stopped);
      _data.push(this.qts);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: TLLong,
      readonly date: TLInt,
      readonly stopped: BoolType,
      readonly qts: TLInt
    ) {}
  }

  export class UpdateGroupCallConnection implements TLObject {
    static readonly cons = new TLInt(0xb783982);

    static deserialized(
      _data: ByteStream
    ): UpdateGroupCallConnection | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateGroupCallConnection.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const presentation = (flags.value & 1) !== 0;
      const params = API.DataJSON.deserialized(_data);
      if (!params) return undefined;

      return new UpdateGroupCallConnection(presentation, params);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateGroupCallConnection.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.presentation) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.params);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly presentation: boolean | undefined,
      readonly params: API.DataJSON
    ) {}
  }

  export class UpdateBotCommands implements TLObject {
    static readonly cons = new TLInt(0x4d712f2e);

    static deserialized(_data: ByteStream): UpdateBotCommands | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateBotCommands.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const botId = TLLong.deserialized(_data);
      if (!botId) return undefined;
      const commands = TLVector.deserialized(_data, API.BotCommand);
      if (!commands) return undefined;

      return new UpdateBotCommands(peer, botId, commands);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateBotCommands.cons];

      _data.push(this.peer);
      _data.push(this.botId);
      _data.push(this.commands);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly botId: TLLong,
      readonly commands: TLVector<API.BotCommand>
    ) {}
  }

  export class UpdatePendingJoinRequests implements TLObject {
    static readonly cons = new TLInt(0x7063c3db);

    static deserialized(
      _data: ByteStream
    ): UpdatePendingJoinRequests | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatePendingJoinRequests.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const requestsPending = TLInt.deserialized(_data);
      if (!requestsPending) return undefined;
      const recentRequesters = TLVector.deserialized(_data, TLLong);
      if (!recentRequesters) return undefined;

      return new UpdatePendingJoinRequests(
        peer,
        requestsPending,
        recentRequesters
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatePendingJoinRequests.cons];

      _data.push(this.peer);
      _data.push(this.requestsPending);
      _data.push(this.recentRequesters);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly requestsPending: TLInt,
      readonly recentRequesters: TLVector<TLLong>
    ) {}
  }

  export class UpdateBotChatInviteRequester implements TLObject {
    static readonly cons = new TLInt(0x11dfa986);

    static deserialized(
      _data: ByteStream
    ): UpdateBotChatInviteRequester | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdateBotChatInviteRequester.cons)
      )
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const about = TLString.deserialized(_data);
      if (!about) return undefined;
      const invite = TLObject.deserialized(_data) as ExportedChatInviteType;
      if (!invite) return undefined;
      const qts = TLInt.deserialized(_data);
      if (!qts) return undefined;

      return new UpdateBotChatInviteRequester(
        peer,
        date,
        userId,
        about,
        invite,
        qts
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateBotChatInviteRequester.cons];

      _data.push(this.peer);
      _data.push(this.date);
      _data.push(this.userId);
      _data.push(this.about);
      _data.push(this.invite);
      _data.push(this.qts);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly date: TLInt,
      readonly userId: TLLong,
      readonly about: TLString,
      readonly invite: ExportedChatInviteType,
      readonly qts: TLInt
    ) {}
  }

  export class UpdateMessageReactions implements TLObject {
    static readonly cons = new TLInt(0x5e1b3cb8);

    static deserialized(_data: ByteStream): UpdateMessageReactions | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateMessageReactions.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;
      let topMsgId: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        topMsgId = obj;
      }
      const reactions = API.MessageReactions.deserialized(_data);
      if (!reactions) return undefined;

      return new UpdateMessageReactions(peer, msgId, topMsgId, reactions);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateMessageReactions.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.peer);
      _data.push(this.msgId);
      if (this.topMsgId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.topMsgId) _data.push(this.topMsgId);

      _data.push(this.reactions);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly msgId: TLInt,
      readonly topMsgId: TLInt | undefined,
      readonly reactions: API.MessageReactions
    ) {}
  }

  export class UpdateAttachMenuBots implements TLObject {
    static readonly cons = new TLInt(0x17b7a20b);

    static deserialized(_data: ByteStream): UpdateAttachMenuBots | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateAttachMenuBots.cons))
        return undefined;

      return new UpdateAttachMenuBots();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateAttachMenuBots.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateWebViewResultSent implements TLObject {
    static readonly cons = new TLInt(0x1592b79d);

    static deserialized(
      _data: ByteStream
    ): UpdateWebViewResultSent | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateWebViewResultSent.cons))
        return undefined;

      const queryId = TLLong.deserialized(_data);
      if (!queryId) return undefined;

      return new UpdateWebViewResultSent(queryId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateWebViewResultSent.cons];

      _data.push(this.queryId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly queryId: TLLong) {}
  }

  export class UpdateBotMenuButton implements TLObject {
    static readonly cons = new TLInt(0x14b85813);

    static deserialized(_data: ByteStream): UpdateBotMenuButton | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateBotMenuButton.cons))
        return undefined;

      const botId = TLLong.deserialized(_data);
      if (!botId) return undefined;
      const button = TLObject.deserialized(_data) as BotMenuButtonType;
      if (!button) return undefined;

      return new UpdateBotMenuButton(botId, button);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateBotMenuButton.cons];

      _data.push(this.botId);
      _data.push(this.button);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly botId: TLLong, readonly button: BotMenuButtonType) {}
  }

  export class UpdateSavedRingtones implements TLObject {
    static readonly cons = new TLInt(0x74d8be99);

    static deserialized(_data: ByteStream): UpdateSavedRingtones | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateSavedRingtones.cons))
        return undefined;

      return new UpdateSavedRingtones();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateSavedRingtones.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateTranscribedAudio implements TLObject {
    static readonly cons = new TLInt(0x84cd5a);

    static deserialized(_data: ByteStream): UpdateTranscribedAudio | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateTranscribedAudio.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const pending = (flags.value & 1) !== 0;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;
      const transcriptionId = TLLong.deserialized(_data);
      if (!transcriptionId) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new UpdateTranscribedAudio(
        pending,
        peer,
        msgId,
        transcriptionId,
        text
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateTranscribedAudio.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.pending) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.peer);
      _data.push(this.msgId);
      _data.push(this.transcriptionId);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly pending: boolean | undefined,
      readonly peer: PeerType,
      readonly msgId: TLInt,
      readonly transcriptionId: TLLong,
      readonly text: TLString
    ) {}
  }

  export class UpdateReadFeaturedEmojiStickers implements TLObject {
    static readonly cons = new TLInt(0xfb4c496c);

    static deserialized(
      _data: ByteStream
    ): UpdateReadFeaturedEmojiStickers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdateReadFeaturedEmojiStickers.cons)
      )
        return undefined;

      return new UpdateReadFeaturedEmojiStickers();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        UpdateReadFeaturedEmojiStickers.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateUserEmojiStatus implements TLObject {
    static readonly cons = new TLInt(0x28373599);

    static deserialized(_data: ByteStream): UpdateUserEmojiStatus | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateUserEmojiStatus.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const emojiStatus = TLObject.deserialized(_data) as EmojiStatusType;
      if (!emojiStatus) return undefined;

      return new UpdateUserEmojiStatus(userId, emojiStatus);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateUserEmojiStatus.cons];

      _data.push(this.userId);
      _data.push(this.emojiStatus);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: TLLong,
      readonly emojiStatus: EmojiStatusType
    ) {}
  }

  export class UpdateRecentEmojiStatuses implements TLObject {
    static readonly cons = new TLInt(0x30f443db);

    static deserialized(
      _data: ByteStream
    ): UpdateRecentEmojiStatuses | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateRecentEmojiStatuses.cons))
        return undefined;

      return new UpdateRecentEmojiStatuses();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateRecentEmojiStatuses.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateRecentReactions implements TLObject {
    static readonly cons = new TLInt(0x6f7863f4);

    static deserialized(_data: ByteStream): UpdateRecentReactions | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateRecentReactions.cons))
        return undefined;

      return new UpdateRecentReactions();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateRecentReactions.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateMoveStickerSetToTop implements TLObject {
    static readonly cons = new TLInt(0x86fccf85);

    static deserialized(
      _data: ByteStream
    ): UpdateMoveStickerSetToTop | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateMoveStickerSetToTop.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const masks = (flags.value & 1) !== 0;
      const emojis = (flags.value & 2) !== 0;
      const stickerset = TLLong.deserialized(_data);
      if (!stickerset) return undefined;

      return new UpdateMoveStickerSetToTop(masks, emojis, stickerset);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateMoveStickerSetToTop.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.masks) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.emojis) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.stickerset);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly masks: boolean | undefined,
      readonly emojis: boolean | undefined,
      readonly stickerset: TLLong
    ) {}
  }

  export class UpdateMessageExtendedMedia implements TLObject {
    static readonly cons = new TLInt(0x5a73a98c);

    static deserialized(
      _data: ByteStream
    ): UpdateMessageExtendedMedia | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateMessageExtendedMedia.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;
      const extendedMedia = TLObject.deserialized(
        _data
      ) as MessageExtendedMediaType;
      if (!extendedMedia) return undefined;

      return new UpdateMessageExtendedMedia(peer, msgId, extendedMedia);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateMessageExtendedMedia.cons];

      _data.push(this.peer);
      _data.push(this.msgId);
      _data.push(this.extendedMedia);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly msgId: TLInt,
      readonly extendedMedia: MessageExtendedMediaType
    ) {}
  }

  export class UpdateChannelPinnedTopic implements TLObject {
    static readonly cons = new TLInt(0x192efbe3);

    static deserialized(
      _data: ByteStream
    ): UpdateChannelPinnedTopic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChannelPinnedTopic.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const pinned = (flags.value & 1) !== 0;
      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const topicId = TLInt.deserialized(_data);
      if (!topicId) return undefined;

      return new UpdateChannelPinnedTopic(pinned, channelId, topicId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChannelPinnedTopic.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.pinned) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.channelId);
      _data.push(this.topicId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly pinned: boolean | undefined,
      readonly channelId: TLLong,
      readonly topicId: TLInt
    ) {}
  }

  export class UpdateChannelPinnedTopics implements TLObject {
    static readonly cons = new TLInt(0xfe198602);

    static deserialized(
      _data: ByteStream
    ): UpdateChannelPinnedTopics | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateChannelPinnedTopics.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      let order: TLVector<TLInt> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data, TLInt);
        if (!obj) return undefined;
        order = obj;
      }

      return new UpdateChannelPinnedTopics(channelId, order);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateChannelPinnedTopics.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.channelId);
      if (this.order) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.order) _data.push(this.order);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly channelId: TLLong,
      readonly order: TLVector<TLInt> | undefined
    ) {}
  }

  export class UpdateUser implements TLObject {
    static readonly cons = new TLInt(0x20529438);

    static deserialized(_data: ByteStream): UpdateUser | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateUser.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;

      return new UpdateUser(userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateUser.cons];

      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong) {}
  }

  export class UpdateAutoSaveSettings implements TLObject {
    static readonly cons = new TLInt(0xec05b097);

    static deserialized(_data: ByteStream): UpdateAutoSaveSettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateAutoSaveSettings.cons))
        return undefined;

      return new UpdateAutoSaveSettings();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateAutoSaveSettings.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateGroupInvitePrivacyForbidden implements TLObject {
    static readonly cons = new TLInt(0xccf08ad6);

    static deserialized(
      _data: ByteStream
    ): UpdateGroupInvitePrivacyForbidden | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(UpdateGroupInvitePrivacyForbidden.cons)
      )
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;

      return new UpdateGroupInvitePrivacyForbidden(userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        UpdateGroupInvitePrivacyForbidden.cons,
      ];

      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong) {}
  }

  export class UpdateStory implements TLObject {
    static readonly cons = new TLInt(0x75b3b798);

    static deserialized(_data: ByteStream): UpdateStory | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateStory.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const story = TLObject.deserialized(_data) as StoryItemType;
      if (!story) return undefined;

      return new UpdateStory(peer, story);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateStory.cons];

      _data.push(this.peer);
      _data.push(this.story);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType, readonly story: StoryItemType) {}
  }

  export class UpdateReadStories implements TLObject {
    static readonly cons = new TLInt(0xf74e932b);

    static deserialized(_data: ByteStream): UpdateReadStories | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateReadStories.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const maxId = TLInt.deserialized(_data);
      if (!maxId) return undefined;

      return new UpdateReadStories(peer, maxId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateReadStories.cons];

      _data.push(this.peer);
      _data.push(this.maxId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType, readonly maxId: TLInt) {}
  }

  export class UpdateStoryID implements TLObject {
    static readonly cons = new TLInt(0x1bf335b9);

    static deserialized(_data: ByteStream): UpdateStoryID | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateStoryID.cons))
        return undefined;

      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const randomId = TLLong.deserialized(_data);
      if (!randomId) return undefined;

      return new UpdateStoryID(id, randomId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateStoryID.cons];

      _data.push(this.id);
      _data.push(this.randomId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLInt, readonly randomId: TLLong) {}
  }

  export class UpdateStoriesStealthMode implements TLObject {
    static readonly cons = new TLInt(0x2c084dc1);

    static deserialized(
      _data: ByteStream
    ): UpdateStoriesStealthMode | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateStoriesStealthMode.cons))
        return undefined;

      const stealthMode = API.StoriesStealthMode.deserialized(_data);
      if (!stealthMode) return undefined;

      return new UpdateStoriesStealthMode(stealthMode);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateStoriesStealthMode.cons];

      _data.push(this.stealthMode);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly stealthMode: API.StoriesStealthMode) {}
  }

  export class UpdateSentStoryReaction implements TLObject {
    static readonly cons = new TLInt(0x7d627683);

    static deserialized(
      _data: ByteStream
    ): UpdateSentStoryReaction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateSentStoryReaction.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const storyId = TLInt.deserialized(_data);
      if (!storyId) return undefined;
      const reaction = TLObject.deserialized(_data) as ReactionType;
      if (!reaction) return undefined;

      return new UpdateSentStoryReaction(peer, storyId, reaction);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateSentStoryReaction.cons];

      _data.push(this.peer);
      _data.push(this.storyId);
      _data.push(this.reaction);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly storyId: TLInt,
      readonly reaction: ReactionType
    ) {}
  }

  export namespace updates {
    export class State implements TLObject {
      static readonly cons = new TLInt(0xa56c2a3e);

      static deserialized(_data: ByteStream): State | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(State.cons)) return undefined;

        const pts = TLInt.deserialized(_data);
        if (!pts) return undefined;
        const qts = TLInt.deserialized(_data);
        if (!qts) return undefined;
        const date = TLInt.deserialized(_data);
        if (!date) return undefined;
        const seq = TLInt.deserialized(_data);
        if (!seq) return undefined;
        const unreadCount = TLInt.deserialized(_data);
        if (!unreadCount) return undefined;

        return new State(pts, qts, date, seq, unreadCount);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [State.cons];

        _data.push(this.pts);
        _data.push(this.qts);
        _data.push(this.date);
        _data.push(this.seq);
        _data.push(this.unreadCount);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly pts: TLInt,
        readonly qts: TLInt,
        readonly date: TLInt,
        readonly seq: TLInt,
        readonly unreadCount: TLInt
      ) {}
    }
  }

  export namespace updates {
    export class DifferenceEmpty implements TLObject {
      static readonly cons = new TLInt(0x5d75a138);

      static deserialized(_data: ByteStream): DifferenceEmpty | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DifferenceEmpty.cons))
          return undefined;

        const date = TLInt.deserialized(_data);
        if (!date) return undefined;
        const seq = TLInt.deserialized(_data);
        if (!seq) return undefined;

        return new DifferenceEmpty(date, seq);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DifferenceEmpty.cons];

        _data.push(this.date);
        _data.push(this.seq);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly date: TLInt, readonly seq: TLInt) {}
    }
  }

  export namespace updates {
    export class Difference implements TLObject {
      static readonly cons = new TLInt(0xf49ca0);

      static deserialized(_data: ByteStream): Difference | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Difference.cons))
          return undefined;

        const newMessages = TLVector.deserialized(
          _data
        ) as TLVector<MessageType>;
        if (!newMessages) return undefined;
        const newEncryptedMessages = TLVector.deserialized(
          _data
        ) as TLVector<EncryptedMessageType>;
        if (!newEncryptedMessages) return undefined;
        const otherUpdates = TLVector.deserialized(
          _data
        ) as TLVector<UpdateType>;
        if (!otherUpdates) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;
        const state = updates.State.deserialized(_data);
        if (!state) return undefined;

        return new Difference(
          newMessages,
          newEncryptedMessages,
          otherUpdates,
          chats,
          users,
          state
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Difference.cons];

        _data.push(this.newMessages);
        _data.push(this.newEncryptedMessages);
        _data.push(this.otherUpdates);
        _data.push(this.chats);
        _data.push(this.users);
        _data.push(this.state);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly newMessages: TLVector<MessageType>,
        readonly newEncryptedMessages: TLVector<EncryptedMessageType>,
        readonly otherUpdates: TLVector<UpdateType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>,
        readonly state: updates.State
      ) {}
    }
  }

  export namespace updates {
    export class DifferenceSlice implements TLObject {
      static readonly cons = new TLInt(0xa8fb1981);

      static deserialized(_data: ByteStream): DifferenceSlice | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DifferenceSlice.cons))
          return undefined;

        const newMessages = TLVector.deserialized(
          _data
        ) as TLVector<MessageType>;
        if (!newMessages) return undefined;
        const newEncryptedMessages = TLVector.deserialized(
          _data
        ) as TLVector<EncryptedMessageType>;
        if (!newEncryptedMessages) return undefined;
        const otherUpdates = TLVector.deserialized(
          _data
        ) as TLVector<UpdateType>;
        if (!otherUpdates) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;
        const intermediateState = updates.State.deserialized(_data);
        if (!intermediateState) return undefined;

        return new DifferenceSlice(
          newMessages,
          newEncryptedMessages,
          otherUpdates,
          chats,
          users,
          intermediateState
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DifferenceSlice.cons];

        _data.push(this.newMessages);
        _data.push(this.newEncryptedMessages);
        _data.push(this.otherUpdates);
        _data.push(this.chats);
        _data.push(this.users);
        _data.push(this.intermediateState);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly newMessages: TLVector<MessageType>,
        readonly newEncryptedMessages: TLVector<EncryptedMessageType>,
        readonly otherUpdates: TLVector<UpdateType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>,
        readonly intermediateState: updates.State
      ) {}
    }
  }

  export namespace updates {
    export class DifferenceTooLong implements TLObject {
      static readonly cons = new TLInt(0x4afe8f6d);

      static deserialized(_data: ByteStream): DifferenceTooLong | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DifferenceTooLong.cons))
          return undefined;

        const pts = TLInt.deserialized(_data);
        if (!pts) return undefined;

        return new DifferenceTooLong(pts);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DifferenceTooLong.cons];

        _data.push(this.pts);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly pts: TLInt) {}
    }
  }

  export class UpdatesTooLong implements TLObject {
    static readonly cons = new TLInt(0xe317af7e);

    static deserialized(_data: ByteStream): UpdatesTooLong | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatesTooLong.cons))
        return undefined;

      return new UpdatesTooLong();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatesTooLong.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class UpdateShortMessage implements TLObject {
    static readonly cons = new TLInt(0x313bc7f8);

    static deserialized(_data: ByteStream): UpdateShortMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateShortMessage.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const out = (flags.value & 2) !== 0;
      const mentioned = (flags.value & 16) !== 0;
      const mediaUnread = (flags.value & 32) !== 0;
      const silent = (flags.value & 8192) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const message = TLString.deserialized(_data);
      if (!message) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      let fwdFrom: API.MessageFwdHeader | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as API.MessageFwdHeader;
        if (!obj) return undefined;
        fwdFrom = obj;
      }
      let viaBotId: TLLong | undefined;
      if ((flags.value & 2048) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        viaBotId = obj;
      }
      let replyTo: MessageReplyHeaderType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as MessageReplyHeaderType;
        if (!obj) return undefined;
        replyTo = obj;
      }
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }
      let ttlPeriod: TLInt | undefined;
      if ((flags.value & 33554432) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlPeriod = obj;
      }

      return new UpdateShortMessage(
        out,
        mentioned,
        mediaUnread,
        silent,
        id,
        userId,
        message,
        pts,
        ptsCount,
        date,
        fwdFrom,
        viaBotId,
        replyTo,
        entities,
        ttlPeriod
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateShortMessage.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.out) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.mentioned) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.mediaUnread) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.silent) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }

      _data.push(this.id);
      _data.push(this.userId);
      _data.push(this.message);
      _data.push(this.pts);
      _data.push(this.ptsCount);
      _data.push(this.date);
      if (this.fwdFrom) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.fwdFrom) _data.push(this.fwdFrom);

      if (this.viaBotId) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }
      if (this.viaBotId) _data.push(this.viaBotId);

      if (this.replyTo) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.replyTo) _data.push(this.replyTo);

      if (this.entities) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.entities) _data.push(this.entities);

      if (this.ttlPeriod) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }
      if (this.ttlPeriod) _data.push(this.ttlPeriod);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly out: boolean | undefined,
      readonly mentioned: boolean | undefined,
      readonly mediaUnread: boolean | undefined,
      readonly silent: boolean | undefined,
      readonly id: TLInt,
      readonly userId: TLLong,
      readonly message: TLString,
      readonly pts: TLInt,
      readonly ptsCount: TLInt,
      readonly date: TLInt,
      readonly fwdFrom: API.MessageFwdHeader | undefined,
      readonly viaBotId: TLLong | undefined,
      readonly replyTo: MessageReplyHeaderType | undefined,
      readonly entities: TLVector<MessageEntityType> | undefined,
      readonly ttlPeriod: TLInt | undefined
    ) {}
  }

  export class UpdateShortChatMessage implements TLObject {
    static readonly cons = new TLInt(0x4d6deea5);

    static deserialized(_data: ByteStream): UpdateShortChatMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateShortChatMessage.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const out = (flags.value & 2) !== 0;
      const mentioned = (flags.value & 16) !== 0;
      const mediaUnread = (flags.value & 32) !== 0;
      const silent = (flags.value & 8192) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const fromId = TLLong.deserialized(_data);
      if (!fromId) return undefined;
      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;
      const message = TLString.deserialized(_data);
      if (!message) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      let fwdFrom: API.MessageFwdHeader | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as API.MessageFwdHeader;
        if (!obj) return undefined;
        fwdFrom = obj;
      }
      let viaBotId: TLLong | undefined;
      if ((flags.value & 2048) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        viaBotId = obj;
      }
      let replyTo: MessageReplyHeaderType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as MessageReplyHeaderType;
        if (!obj) return undefined;
        replyTo = obj;
      }
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }
      let ttlPeriod: TLInt | undefined;
      if ((flags.value & 33554432) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlPeriod = obj;
      }

      return new UpdateShortChatMessage(
        out,
        mentioned,
        mediaUnread,
        silent,
        id,
        fromId,
        chatId,
        message,
        pts,
        ptsCount,
        date,
        fwdFrom,
        viaBotId,
        replyTo,
        entities,
        ttlPeriod
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateShortChatMessage.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.out) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.mentioned) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.mediaUnread) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.silent) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }

      _data.push(this.id);
      _data.push(this.fromId);
      _data.push(this.chatId);
      _data.push(this.message);
      _data.push(this.pts);
      _data.push(this.ptsCount);
      _data.push(this.date);
      if (this.fwdFrom) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.fwdFrom) _data.push(this.fwdFrom);

      if (this.viaBotId) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }
      if (this.viaBotId) _data.push(this.viaBotId);

      if (this.replyTo) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.replyTo) _data.push(this.replyTo);

      if (this.entities) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.entities) _data.push(this.entities);

      if (this.ttlPeriod) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }
      if (this.ttlPeriod) _data.push(this.ttlPeriod);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly out: boolean | undefined,
      readonly mentioned: boolean | undefined,
      readonly mediaUnread: boolean | undefined,
      readonly silent: boolean | undefined,
      readonly id: TLInt,
      readonly fromId: TLLong,
      readonly chatId: TLLong,
      readonly message: TLString,
      readonly pts: TLInt,
      readonly ptsCount: TLInt,
      readonly date: TLInt,
      readonly fwdFrom: API.MessageFwdHeader | undefined,
      readonly viaBotId: TLLong | undefined,
      readonly replyTo: MessageReplyHeaderType | undefined,
      readonly entities: TLVector<MessageEntityType> | undefined,
      readonly ttlPeriod: TLInt | undefined
    ) {}
  }

  export class UpdateShort implements TLObject {
    static readonly cons = new TLInt(0x78d4dec1);

    static deserialized(_data: ByteStream): UpdateShort | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateShort.cons))
        return undefined;

      const update = TLObject.deserialized(_data) as UpdateType;
      if (!update) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new UpdateShort(update, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateShort.cons];

      _data.push(this.update);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly update: UpdateType, readonly date: TLInt) {}
  }

  export class UpdatesCombined implements TLObject {
    static readonly cons = new TLInt(0x725b04c3);

    static deserialized(_data: ByteStream): UpdatesCombined | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdatesCombined.cons))
        return undefined;

      const updates = TLVector.deserialized(_data) as TLVector<UpdateType>;
      if (!updates) return undefined;
      const users = TLVector.deserialized(_data) as TLVector<UserType>;
      if (!users) return undefined;
      const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
      if (!chats) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const seqStart = TLInt.deserialized(_data);
      if (!seqStart) return undefined;
      const seq = TLInt.deserialized(_data);
      if (!seq) return undefined;

      return new UpdatesCombined(updates, users, chats, date, seqStart, seq);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdatesCombined.cons];

      _data.push(this.updates);
      _data.push(this.users);
      _data.push(this.chats);
      _data.push(this.date);
      _data.push(this.seqStart);
      _data.push(this.seq);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly updates: TLVector<UpdateType>,
      readonly users: TLVector<UserType>,
      readonly chats: TLVector<ChatType>,
      readonly date: TLInt,
      readonly seqStart: TLInt,
      readonly seq: TLInt
    ) {}
  }

  export class Updates implements TLObject {
    static readonly cons = new TLInt(0x74ae4240);

    static deserialized(_data: ByteStream): Updates | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Updates.cons)) return undefined;

      const updates = TLVector.deserialized(_data) as TLVector<UpdateType>;
      if (!updates) return undefined;
      const users = TLVector.deserialized(_data) as TLVector<UserType>;
      if (!users) return undefined;
      const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
      if (!chats) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const seq = TLInt.deserialized(_data);
      if (!seq) return undefined;

      return new Updates(updates, users, chats, date, seq);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Updates.cons];

      _data.push(this.updates);
      _data.push(this.users);
      _data.push(this.chats);
      _data.push(this.date);
      _data.push(this.seq);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly updates: TLVector<UpdateType>,
      readonly users: TLVector<UserType>,
      readonly chats: TLVector<ChatType>,
      readonly date: TLInt,
      readonly seq: TLInt
    ) {}
  }

  export class UpdateShortSentMessage implements TLObject {
    static readonly cons = new TLInt(0x9015e101);

    static deserialized(_data: ByteStream): UpdateShortSentMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UpdateShortSentMessage.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const out = (flags.value & 2) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const pts = TLInt.deserialized(_data);
      if (!pts) return undefined;
      const ptsCount = TLInt.deserialized(_data);
      if (!ptsCount) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      let media: MessageMediaType | undefined;
      if ((flags.value & 512) !== 0) {
        const obj = TLObject.deserialized(_data) as MessageMediaType;
        if (!obj) return undefined;
        media = obj;
      }
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }
      let ttlPeriod: TLInt | undefined;
      if ((flags.value & 33554432) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        ttlPeriod = obj;
      }

      return new UpdateShortSentMessage(
        out,
        id,
        pts,
        ptsCount,
        date,
        media,
        entities,
        ttlPeriod
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UpdateShortSentMessage.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.out) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.id);
      _data.push(this.pts);
      _data.push(this.ptsCount);
      _data.push(this.date);
      if (this.media) {
        flags.set(512);
      } else {
        flags.unset(512);
      }
      if (this.media) _data.push(this.media);

      if (this.entities) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.entities) _data.push(this.entities);

      if (this.ttlPeriod) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }
      if (this.ttlPeriod) _data.push(this.ttlPeriod);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly out: boolean | undefined,
      readonly id: TLInt,
      readonly pts: TLInt,
      readonly ptsCount: TLInt,
      readonly date: TLInt,
      readonly media: MessageMediaType | undefined,
      readonly entities: TLVector<MessageEntityType> | undefined,
      readonly ttlPeriod: TLInt | undefined
    ) {}
  }

  export namespace photos {
    export class Photos implements TLObject {
      static readonly cons = new TLInt(0x8dca6aa5);

      static deserialized(_data: ByteStream): Photos | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Photos.cons)) return undefined;

        const photos = TLVector.deserialized(_data) as TLVector<PhotoType>;
        if (!photos) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new Photos(photos, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Photos.cons];

        _data.push(this.photos);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly photos: TLVector<PhotoType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace photos {
    export class PhotosSlice implements TLObject {
      static readonly cons = new TLInt(0x15051f54);

      static deserialized(_data: ByteStream): PhotosSlice | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PhotosSlice.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const photos = TLVector.deserialized(_data) as TLVector<PhotoType>;
        if (!photos) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new PhotosSlice(count, photos, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PhotosSlice.cons];

        _data.push(this.count);
        _data.push(this.photos);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly photos: TLVector<PhotoType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace photos {
    export class Photo implements TLObject {
      static readonly cons = new TLInt(0x20212ca8);

      static deserialized(_data: ByteStream): Photo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Photo.cons)) return undefined;

        const photo = TLObject.deserialized(_data) as PhotoType;
        if (!photo) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new Photo(photo, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Photo.cons];

        _data.push(this.photo);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly photo: PhotoType,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace upload {
    export class File implements TLObject {
      static readonly cons = new TLInt(0x96a18d5);

      static deserialized(_data: ByteStream): File | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(File.cons)) return undefined;

        const type = TLObject.deserialized(_data) as storage.FileTypeType;
        if (!type) return undefined;
        const mtime = TLInt.deserialized(_data);
        if (!mtime) return undefined;
        const bytes = TLBytes.deserialized(_data);
        if (!bytes) return undefined;

        return new File(type, mtime, bytes);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [File.cons];

        _data.push(this.type);
        _data.push(this.mtime);
        _data.push(this.bytes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly type: storage.FileTypeType,
        readonly mtime: TLInt,
        readonly bytes: TLBytes
      ) {}
    }
  }

  export namespace upload {
    export class FileCdnRedirect implements TLObject {
      static readonly cons = new TLInt(0xf18cda44);

      static deserialized(_data: ByteStream): FileCdnRedirect | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FileCdnRedirect.cons))
          return undefined;

        const dcId = TLInt.deserialized(_data);
        if (!dcId) return undefined;
        const fileToken = TLBytes.deserialized(_data);
        if (!fileToken) return undefined;
        const encryptionKey = TLBytes.deserialized(_data);
        if (!encryptionKey) return undefined;
        const encryptionIv = TLBytes.deserialized(_data);
        if (!encryptionIv) return undefined;
        const fileHashes = TLVector.deserialized(_data, API.FileHash);
        if (!fileHashes) return undefined;

        return new FileCdnRedirect(
          dcId,
          fileToken,
          encryptionKey,
          encryptionIv,
          fileHashes
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FileCdnRedirect.cons];

        _data.push(this.dcId);
        _data.push(this.fileToken);
        _data.push(this.encryptionKey);
        _data.push(this.encryptionIv);
        _data.push(this.fileHashes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly dcId: TLInt,
        readonly fileToken: TLBytes,
        readonly encryptionKey: TLBytes,
        readonly encryptionIv: TLBytes,
        readonly fileHashes: TLVector<API.FileHash>
      ) {}
    }
  }

  export class DcOption implements TLObject {
    static readonly cons = new TLInt(0x18b7a10d);

    static deserialized(_data: ByteStream): DcOption | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DcOption.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const ipv6 = (flags.value & 1) !== 0;
      const mediaOnly = (flags.value & 2) !== 0;
      const tcpoOnly = (flags.value & 4) !== 0;
      const cdn = (flags.value & 8) !== 0;
      const argStatic = (flags.value & 16) !== 0;
      const thisPortOnly = (flags.value & 32) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const ipAddress = TLString.deserialized(_data);
      if (!ipAddress) return undefined;
      const port = TLInt.deserialized(_data);
      if (!port) return undefined;
      let secret: TLBytes | undefined;
      if ((flags.value & 1024) !== 0) {
        const obj = TLBytes.deserialized(_data);
        if (!obj) return undefined;
        secret = obj;
      }

      return new DcOption(
        ipv6,
        mediaOnly,
        tcpoOnly,
        cdn,
        argStatic,
        thisPortOnly,
        id,
        ipAddress,
        port,
        secret
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DcOption.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.ipv6) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.mediaOnly) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.tcpoOnly) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.cdn) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.argStatic) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.thisPortOnly) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      _data.push(this.id);
      _data.push(this.ipAddress);
      _data.push(this.port);
      if (this.secret) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }
      if (this.secret) _data.push(this.secret);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly ipv6: boolean | undefined,
      readonly mediaOnly: boolean | undefined,
      readonly tcpoOnly: boolean | undefined,
      readonly cdn: boolean | undefined,
      readonly argStatic: boolean | undefined,
      readonly thisPortOnly: boolean | undefined,
      readonly id: TLInt,
      readonly ipAddress: TLString,
      readonly port: TLInt,
      readonly secret: TLBytes | undefined
    ) {}
  }

  export class Config implements TLObject {
    static readonly cons = new TLInt(0xcc1a241e);

    static deserialized(_data: ByteStream): Config | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Config.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const defaultP2pContacts = (flags.value & 8) !== 0;
      const preloadFeaturedStickers = (flags.value & 16) !== 0;
      const revokePmInbox = (flags.value & 64) !== 0;
      const blockedMode = (flags.value & 256) !== 0;
      const forceTryIpv6 = (flags.value & 16384) !== 0;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const expires = TLInt.deserialized(_data);
      if (!expires) return undefined;
      const testMode = TLObject.deserialized(_data) as BoolType;
      if (!testMode) return undefined;
      const thisDc = TLInt.deserialized(_data);
      if (!thisDc) return undefined;
      const dcOptions = TLVector.deserialized(_data, API.DcOption);
      if (!dcOptions) return undefined;
      const dcTxtDomainName = TLString.deserialized(_data);
      if (!dcTxtDomainName) return undefined;
      const chatSizeMax = TLInt.deserialized(_data);
      if (!chatSizeMax) return undefined;
      const megagroupSizeMax = TLInt.deserialized(_data);
      if (!megagroupSizeMax) return undefined;
      const forwardedCountMax = TLInt.deserialized(_data);
      if (!forwardedCountMax) return undefined;
      const onlineUpdatePeriodMs = TLInt.deserialized(_data);
      if (!onlineUpdatePeriodMs) return undefined;
      const offlineBlurTimeoutMs = TLInt.deserialized(_data);
      if (!offlineBlurTimeoutMs) return undefined;
      const offlineIdleTimeoutMs = TLInt.deserialized(_data);
      if (!offlineIdleTimeoutMs) return undefined;
      const onlineCloudTimeoutMs = TLInt.deserialized(_data);
      if (!onlineCloudTimeoutMs) return undefined;
      const notifyCloudDelayMs = TLInt.deserialized(_data);
      if (!notifyCloudDelayMs) return undefined;
      const notifyDefaultDelayMs = TLInt.deserialized(_data);
      if (!notifyDefaultDelayMs) return undefined;
      const pushChatPeriodMs = TLInt.deserialized(_data);
      if (!pushChatPeriodMs) return undefined;
      const pushChatLimit = TLInt.deserialized(_data);
      if (!pushChatLimit) return undefined;
      const editTimeLimit = TLInt.deserialized(_data);
      if (!editTimeLimit) return undefined;
      const revokeTimeLimit = TLInt.deserialized(_data);
      if (!revokeTimeLimit) return undefined;
      const revokePmTimeLimit = TLInt.deserialized(_data);
      if (!revokePmTimeLimit) return undefined;
      const ratingEDecay = TLInt.deserialized(_data);
      if (!ratingEDecay) return undefined;
      const stickersRecentLimit = TLInt.deserialized(_data);
      if (!stickersRecentLimit) return undefined;
      const channelsReadMediaPeriod = TLInt.deserialized(_data);
      if (!channelsReadMediaPeriod) return undefined;
      let tmpSessions: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        tmpSessions = obj;
      }
      const callReceiveTimeoutMs = TLInt.deserialized(_data);
      if (!callReceiveTimeoutMs) return undefined;
      const callRingTimeoutMs = TLInt.deserialized(_data);
      if (!callRingTimeoutMs) return undefined;
      const callConnectTimeoutMs = TLInt.deserialized(_data);
      if (!callConnectTimeoutMs) return undefined;
      const callPacketTimeoutMs = TLInt.deserialized(_data);
      if (!callPacketTimeoutMs) return undefined;
      const meUrlPrefix = TLString.deserialized(_data);
      if (!meUrlPrefix) return undefined;
      let autoupdateUrlPrefix: TLString | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        autoupdateUrlPrefix = obj;
      }
      let gifSearchUsername: TLString | undefined;
      if ((flags.value & 512) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        gifSearchUsername = obj;
      }
      let venueSearchUsername: TLString | undefined;
      if ((flags.value & 1024) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        venueSearchUsername = obj;
      }
      let imgSearchUsername: TLString | undefined;
      if ((flags.value & 2048) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        imgSearchUsername = obj;
      }
      let staticMapsProvider: TLString | undefined;
      if ((flags.value & 4096) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        staticMapsProvider = obj;
      }
      const captionLengthMax = TLInt.deserialized(_data);
      if (!captionLengthMax) return undefined;
      const messageLengthMax = TLInt.deserialized(_data);
      if (!messageLengthMax) return undefined;
      const webfileDcId = TLInt.deserialized(_data);
      if (!webfileDcId) return undefined;
      let suggestedLangCode: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        suggestedLangCode = obj;
      }
      let langPackVersion: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        langPackVersion = obj;
      }
      let baseLangPackVersion: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        baseLangPackVersion = obj;
      }
      let reactionsDefault: ReactionType | undefined;
      if ((flags.value & 32768) !== 0) {
        const obj = TLObject.deserialized(_data) as ReactionType;
        if (!obj) return undefined;
        reactionsDefault = obj;
      }
      let autologinToken: TLString | undefined;
      if ((flags.value & 65536) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        autologinToken = obj;
      }

      return new Config(
        defaultP2pContacts,
        preloadFeaturedStickers,
        revokePmInbox,
        blockedMode,
        forceTryIpv6,
        date,
        expires,
        testMode,
        thisDc,
        dcOptions,
        dcTxtDomainName,
        chatSizeMax,
        megagroupSizeMax,
        forwardedCountMax,
        onlineUpdatePeriodMs,
        offlineBlurTimeoutMs,
        offlineIdleTimeoutMs,
        onlineCloudTimeoutMs,
        notifyCloudDelayMs,
        notifyDefaultDelayMs,
        pushChatPeriodMs,
        pushChatLimit,
        editTimeLimit,
        revokeTimeLimit,
        revokePmTimeLimit,
        ratingEDecay,
        stickersRecentLimit,
        channelsReadMediaPeriod,
        tmpSessions,
        callReceiveTimeoutMs,
        callRingTimeoutMs,
        callConnectTimeoutMs,
        callPacketTimeoutMs,
        meUrlPrefix,
        autoupdateUrlPrefix,
        gifSearchUsername,
        venueSearchUsername,
        imgSearchUsername,
        staticMapsProvider,
        captionLengthMax,
        messageLengthMax,
        webfileDcId,
        suggestedLangCode,
        langPackVersion,
        baseLangPackVersion,
        reactionsDefault,
        autologinToken
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Config.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.defaultP2pContacts) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.preloadFeaturedStickers) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.revokePmInbox) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      if (this.blockedMode) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      if (this.forceTryIpv6) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }

      _data.push(this.date);
      _data.push(this.expires);
      _data.push(this.testMode);
      _data.push(this.thisDc);
      _data.push(this.dcOptions);
      _data.push(this.dcTxtDomainName);
      _data.push(this.chatSizeMax);
      _data.push(this.megagroupSizeMax);
      _data.push(this.forwardedCountMax);
      _data.push(this.onlineUpdatePeriodMs);
      _data.push(this.offlineBlurTimeoutMs);
      _data.push(this.offlineIdleTimeoutMs);
      _data.push(this.onlineCloudTimeoutMs);
      _data.push(this.notifyCloudDelayMs);
      _data.push(this.notifyDefaultDelayMs);
      _data.push(this.pushChatPeriodMs);
      _data.push(this.pushChatLimit);
      _data.push(this.editTimeLimit);
      _data.push(this.revokeTimeLimit);
      _data.push(this.revokePmTimeLimit);
      _data.push(this.ratingEDecay);
      _data.push(this.stickersRecentLimit);
      _data.push(this.channelsReadMediaPeriod);
      if (this.tmpSessions) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.tmpSessions) _data.push(this.tmpSessions);

      _data.push(this.callReceiveTimeoutMs);
      _data.push(this.callRingTimeoutMs);
      _data.push(this.callConnectTimeoutMs);
      _data.push(this.callPacketTimeoutMs);
      _data.push(this.meUrlPrefix);
      if (this.autoupdateUrlPrefix) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.autoupdateUrlPrefix) _data.push(this.autoupdateUrlPrefix);

      if (this.gifSearchUsername) {
        flags.set(512);
      } else {
        flags.unset(512);
      }
      if (this.gifSearchUsername) _data.push(this.gifSearchUsername);

      if (this.venueSearchUsername) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }
      if (this.venueSearchUsername) _data.push(this.venueSearchUsername);

      if (this.imgSearchUsername) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }
      if (this.imgSearchUsername) _data.push(this.imgSearchUsername);

      if (this.staticMapsProvider) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }
      if (this.staticMapsProvider) _data.push(this.staticMapsProvider);

      _data.push(this.captionLengthMax);
      _data.push(this.messageLengthMax);
      _data.push(this.webfileDcId);
      if (this.suggestedLangCode) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.suggestedLangCode) _data.push(this.suggestedLangCode);

      if (this.langPackVersion) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.langPackVersion) _data.push(this.langPackVersion);

      if (this.baseLangPackVersion) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.baseLangPackVersion) _data.push(this.baseLangPackVersion);

      if (this.reactionsDefault) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }
      if (this.reactionsDefault) _data.push(this.reactionsDefault);

      if (this.autologinToken) {
        flags.set(65536);
      } else {
        flags.unset(65536);
      }
      if (this.autologinToken) _data.push(this.autologinToken);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly defaultP2pContacts: boolean | undefined,
      readonly preloadFeaturedStickers: boolean | undefined,
      readonly revokePmInbox: boolean | undefined,
      readonly blockedMode: boolean | undefined,
      readonly forceTryIpv6: boolean | undefined,
      readonly date: TLInt,
      readonly expires: TLInt,
      readonly testMode: BoolType,
      readonly thisDc: TLInt,
      readonly dcOptions: TLVector<API.DcOption>,
      readonly dcTxtDomainName: TLString,
      readonly chatSizeMax: TLInt,
      readonly megagroupSizeMax: TLInt,
      readonly forwardedCountMax: TLInt,
      readonly onlineUpdatePeriodMs: TLInt,
      readonly offlineBlurTimeoutMs: TLInt,
      readonly offlineIdleTimeoutMs: TLInt,
      readonly onlineCloudTimeoutMs: TLInt,
      readonly notifyCloudDelayMs: TLInt,
      readonly notifyDefaultDelayMs: TLInt,
      readonly pushChatPeriodMs: TLInt,
      readonly pushChatLimit: TLInt,
      readonly editTimeLimit: TLInt,
      readonly revokeTimeLimit: TLInt,
      readonly revokePmTimeLimit: TLInt,
      readonly ratingEDecay: TLInt,
      readonly stickersRecentLimit: TLInt,
      readonly channelsReadMediaPeriod: TLInt,
      readonly tmpSessions: TLInt | undefined,
      readonly callReceiveTimeoutMs: TLInt,
      readonly callRingTimeoutMs: TLInt,
      readonly callConnectTimeoutMs: TLInt,
      readonly callPacketTimeoutMs: TLInt,
      readonly meUrlPrefix: TLString,
      readonly autoupdateUrlPrefix: TLString | undefined,
      readonly gifSearchUsername: TLString | undefined,
      readonly venueSearchUsername: TLString | undefined,
      readonly imgSearchUsername: TLString | undefined,
      readonly staticMapsProvider: TLString | undefined,
      readonly captionLengthMax: TLInt,
      readonly messageLengthMax: TLInt,
      readonly webfileDcId: TLInt,
      readonly suggestedLangCode: TLString | undefined,
      readonly langPackVersion: TLInt | undefined,
      readonly baseLangPackVersion: TLInt | undefined,
      readonly reactionsDefault: ReactionType | undefined,
      readonly autologinToken: TLString | undefined
    ) {}
  }

  export class NearestDc implements TLObject {
    static readonly cons = new TLInt(0x8e1a1775);

    static deserialized(_data: ByteStream): NearestDc | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(NearestDc.cons)) return undefined;

      const country = TLString.deserialized(_data);
      if (!country) return undefined;
      const thisDc = TLInt.deserialized(_data);
      if (!thisDc) return undefined;
      const nearestDc = TLInt.deserialized(_data);
      if (!nearestDc) return undefined;

      return new NearestDc(country, thisDc, nearestDc);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [NearestDc.cons];

      _data.push(this.country);
      _data.push(this.thisDc);
      _data.push(this.nearestDc);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly country: TLString,
      readonly thisDc: TLInt,
      readonly nearestDc: TLInt
    ) {}
  }

  export namespace help {
    export class AppUpdate implements TLObject {
      static readonly cons = new TLInt(0xccbbce30);

      static deserialized(_data: ByteStream): AppUpdate | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AppUpdate.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const canNotSkip = (flags.value & 1) !== 0;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;
        const version = TLString.deserialized(_data);
        if (!version) return undefined;
        const text = TLString.deserialized(_data);
        if (!text) return undefined;
        const entities = TLVector.deserialized(
          _data
        ) as TLVector<MessageEntityType>;
        if (!entities) return undefined;
        let document: DocumentType | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as DocumentType;
          if (!obj) return undefined;
          document = obj;
        }
        let url: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          url = obj;
        }
        let sticker: DocumentType | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLObject.deserialized(_data) as DocumentType;
          if (!obj) return undefined;
          sticker = obj;
        }

        return new AppUpdate(
          canNotSkip,
          id,
          version,
          text,
          entities,
          document,
          url,
          sticker
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AppUpdate.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.canNotSkip) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.id);
        _data.push(this.version);
        _data.push(this.text);
        _data.push(this.entities);
        if (this.document) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.document) _data.push(this.document);

        if (this.url) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.url) _data.push(this.url);

        if (this.sticker) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.sticker) _data.push(this.sticker);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly canNotSkip: boolean | undefined,
        readonly id: TLInt,
        readonly version: TLString,
        readonly text: TLString,
        readonly entities: TLVector<MessageEntityType>,
        readonly document: DocumentType | undefined,
        readonly url: TLString | undefined,
        readonly sticker: DocumentType | undefined
      ) {}
    }
  }

  export namespace help {
    export class NoAppUpdate implements TLObject {
      static readonly cons = new TLInt(0xc45a6536);

      static deserialized(_data: ByteStream): NoAppUpdate | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(NoAppUpdate.cons))
          return undefined;

        return new NoAppUpdate();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [NoAppUpdate.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class InviteText implements TLObject {
      static readonly cons = new TLInt(0x18cb9f78);

      static deserialized(_data: ByteStream): InviteText | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(InviteText.cons))
          return undefined;

        const message = TLString.deserialized(_data);
        if (!message) return undefined;

        return new InviteText(message);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [InviteText.cons];

        _data.push(this.message);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly message: TLString) {}
    }
  }

  export class EncryptedChatEmpty implements TLObject {
    static readonly cons = new TLInt(0xab7ec0a0);

    static deserialized(_data: ByteStream): EncryptedChatEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EncryptedChatEmpty.cons))
        return undefined;

      const id = TLInt.deserialized(_data);
      if (!id) return undefined;

      return new EncryptedChatEmpty(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EncryptedChatEmpty.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLInt) {}
  }

  export class EncryptedChatWaiting implements TLObject {
    static readonly cons = new TLInt(0x66b25953);

    static deserialized(_data: ByteStream): EncryptedChatWaiting | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EncryptedChatWaiting.cons))
        return undefined;

      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const adminId = TLLong.deserialized(_data);
      if (!adminId) return undefined;
      const participantId = TLLong.deserialized(_data);
      if (!participantId) return undefined;

      return new EncryptedChatWaiting(
        id,
        accessHash,
        date,
        adminId,
        participantId
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EncryptedChatWaiting.cons];

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.date);
      _data.push(this.adminId);
      _data.push(this.participantId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLInt,
      readonly accessHash: TLLong,
      readonly date: TLInt,
      readonly adminId: TLLong,
      readonly participantId: TLLong
    ) {}
  }

  export class EncryptedChatRequested implements TLObject {
    static readonly cons = new TLInt(0x48f1d94c);

    static deserialized(_data: ByteStream): EncryptedChatRequested | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EncryptedChatRequested.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let folderId: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        folderId = obj;
      }
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const adminId = TLLong.deserialized(_data);
      if (!adminId) return undefined;
      const participantId = TLLong.deserialized(_data);
      if (!participantId) return undefined;
      const gA = TLBytes.deserialized(_data);
      if (!gA) return undefined;

      return new EncryptedChatRequested(
        folderId,
        id,
        accessHash,
        date,
        adminId,
        participantId,
        gA
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EncryptedChatRequested.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.folderId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.folderId) _data.push(this.folderId);

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.date);
      _data.push(this.adminId);
      _data.push(this.participantId);
      _data.push(this.gA);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly folderId: TLInt | undefined,
      readonly id: TLInt,
      readonly accessHash: TLLong,
      readonly date: TLInt,
      readonly adminId: TLLong,
      readonly participantId: TLLong,
      readonly gA: TLBytes
    ) {}
  }

  export class EncryptedChat implements TLObject {
    static readonly cons = new TLInt(0x61f0d4c7);

    static deserialized(_data: ByteStream): EncryptedChat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EncryptedChat.cons))
        return undefined;

      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const adminId = TLLong.deserialized(_data);
      if (!adminId) return undefined;
      const participantId = TLLong.deserialized(_data);
      if (!participantId) return undefined;
      const gAOrB = TLBytes.deserialized(_data);
      if (!gAOrB) return undefined;
      const keyFingerprint = TLLong.deserialized(_data);
      if (!keyFingerprint) return undefined;

      return new EncryptedChat(
        id,
        accessHash,
        date,
        adminId,
        participantId,
        gAOrB,
        keyFingerprint
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EncryptedChat.cons];

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.date);
      _data.push(this.adminId);
      _data.push(this.participantId);
      _data.push(this.gAOrB);
      _data.push(this.keyFingerprint);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLInt,
      readonly accessHash: TLLong,
      readonly date: TLInt,
      readonly adminId: TLLong,
      readonly participantId: TLLong,
      readonly gAOrB: TLBytes,
      readonly keyFingerprint: TLLong
    ) {}
  }

  export class EncryptedChatDiscarded implements TLObject {
    static readonly cons = new TLInt(0x1e1c7c45);

    static deserialized(_data: ByteStream): EncryptedChatDiscarded | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EncryptedChatDiscarded.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const historyDeleted = (flags.value & 1) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;

      return new EncryptedChatDiscarded(historyDeleted, id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EncryptedChatDiscarded.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.historyDeleted) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly historyDeleted: boolean | undefined,
      readonly id: TLInt
    ) {}
  }

  export class InputEncryptedChat implements TLObject {
    static readonly cons = new TLInt(0xf141b5e1);

    static deserialized(_data: ByteStream): InputEncryptedChat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputEncryptedChat.cons))
        return undefined;

      const chatId = TLInt.deserialized(_data);
      if (!chatId) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputEncryptedChat(chatId, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputEncryptedChat.cons];

      _data.push(this.chatId);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chatId: TLInt, readonly accessHash: TLLong) {}
  }

  export class EncryptedFileEmpty implements TLObject {
    static readonly cons = new TLInt(0xc21f497e);

    static deserialized(_data: ByteStream): EncryptedFileEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EncryptedFileEmpty.cons))
        return undefined;

      return new EncryptedFileEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EncryptedFileEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class EncryptedFile implements TLObject {
    static readonly cons = new TLInt(0xa8008cd8);

    static deserialized(_data: ByteStream): EncryptedFile | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EncryptedFile.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const size = TLLong.deserialized(_data);
      if (!size) return undefined;
      const dcId = TLInt.deserialized(_data);
      if (!dcId) return undefined;
      const keyFingerprint = TLInt.deserialized(_data);
      if (!keyFingerprint) return undefined;

      return new EncryptedFile(id, accessHash, size, dcId, keyFingerprint);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EncryptedFile.cons];

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.size);
      _data.push(this.dcId);
      _data.push(this.keyFingerprint);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly size: TLLong,
      readonly dcId: TLInt,
      readonly keyFingerprint: TLInt
    ) {}
  }

  export class InputEncryptedFileEmpty implements TLObject {
    static readonly cons = new TLInt(0x1837c364);

    static deserialized(
      _data: ByteStream
    ): InputEncryptedFileEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputEncryptedFileEmpty.cons))
        return undefined;

      return new InputEncryptedFileEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputEncryptedFileEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputEncryptedFileUploaded implements TLObject {
    static readonly cons = new TLInt(0x64bd0306);

    static deserialized(
      _data: ByteStream
    ): InputEncryptedFileUploaded | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputEncryptedFileUploaded.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const parts = TLInt.deserialized(_data);
      if (!parts) return undefined;
      const md5Checksum = TLString.deserialized(_data);
      if (!md5Checksum) return undefined;
      const keyFingerprint = TLInt.deserialized(_data);
      if (!keyFingerprint) return undefined;

      return new InputEncryptedFileUploaded(
        id,
        parts,
        md5Checksum,
        keyFingerprint
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputEncryptedFileUploaded.cons];

      _data.push(this.id);
      _data.push(this.parts);
      _data.push(this.md5Checksum);
      _data.push(this.keyFingerprint);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly parts: TLInt,
      readonly md5Checksum: TLString,
      readonly keyFingerprint: TLInt
    ) {}
  }

  export class InputEncryptedFile implements TLObject {
    static readonly cons = new TLInt(0x5a17b5e5);

    static deserialized(_data: ByteStream): InputEncryptedFile | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputEncryptedFile.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputEncryptedFile(id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputEncryptedFile.cons];

      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputEncryptedFileBigUploaded implements TLObject {
    static readonly cons = new TLInt(0x2dc173c8);

    static deserialized(
      _data: ByteStream
    ): InputEncryptedFileBigUploaded | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputEncryptedFileBigUploaded.cons)
      )
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const parts = TLInt.deserialized(_data);
      if (!parts) return undefined;
      const keyFingerprint = TLInt.deserialized(_data);
      if (!keyFingerprint) return undefined;

      return new InputEncryptedFileBigUploaded(id, parts, keyFingerprint);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputEncryptedFileBigUploaded.cons];

      _data.push(this.id);
      _data.push(this.parts);
      _data.push(this.keyFingerprint);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly parts: TLInt,
      readonly keyFingerprint: TLInt
    ) {}
  }

  export class EncryptedMessage implements TLObject {
    static readonly cons = new TLInt(0xed18c118);

    static deserialized(_data: ByteStream): EncryptedMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EncryptedMessage.cons))
        return undefined;

      const randomId = TLLong.deserialized(_data);
      if (!randomId) return undefined;
      const chatId = TLInt.deserialized(_data);
      if (!chatId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const bytes = TLBytes.deserialized(_data);
      if (!bytes) return undefined;
      const file = TLObject.deserialized(_data) as EncryptedFileType;
      if (!file) return undefined;

      return new EncryptedMessage(randomId, chatId, date, bytes, file);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EncryptedMessage.cons];

      _data.push(this.randomId);
      _data.push(this.chatId);
      _data.push(this.date);
      _data.push(this.bytes);
      _data.push(this.file);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly randomId: TLLong,
      readonly chatId: TLInt,
      readonly date: TLInt,
      readonly bytes: TLBytes,
      readonly file: EncryptedFileType
    ) {}
  }

  export class EncryptedMessageService implements TLObject {
    static readonly cons = new TLInt(0x23734b06);

    static deserialized(
      _data: ByteStream
    ): EncryptedMessageService | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EncryptedMessageService.cons))
        return undefined;

      const randomId = TLLong.deserialized(_data);
      if (!randomId) return undefined;
      const chatId = TLInt.deserialized(_data);
      if (!chatId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const bytes = TLBytes.deserialized(_data);
      if (!bytes) return undefined;

      return new EncryptedMessageService(randomId, chatId, date, bytes);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EncryptedMessageService.cons];

      _data.push(this.randomId);
      _data.push(this.chatId);
      _data.push(this.date);
      _data.push(this.bytes);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly randomId: TLLong,
      readonly chatId: TLInt,
      readonly date: TLInt,
      readonly bytes: TLBytes
    ) {}
  }

  export namespace messages {
    export class DhConfigNotModified implements TLObject {
      static readonly cons = new TLInt(0xc0e24635);

      static deserialized(_data: ByteStream): DhConfigNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DhConfigNotModified.cons))
          return undefined;

        const random = TLBytes.deserialized(_data);
        if (!random) return undefined;

        return new DhConfigNotModified(random);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DhConfigNotModified.cons];

        _data.push(this.random);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly random: TLBytes) {}
    }
  }

  export namespace messages {
    export class DhConfig implements TLObject {
      static readonly cons = new TLInt(0x2c221edd);

      static deserialized(_data: ByteStream): DhConfig | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DhConfig.cons))
          return undefined;

        const g = TLInt.deserialized(_data);
        if (!g) return undefined;
        const p = TLBytes.deserialized(_data);
        if (!p) return undefined;
        const version = TLInt.deserialized(_data);
        if (!version) return undefined;
        const random = TLBytes.deserialized(_data);
        if (!random) return undefined;

        return new DhConfig(g, p, version, random);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DhConfig.cons];

        _data.push(this.g);
        _data.push(this.p);
        _data.push(this.version);
        _data.push(this.random);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly g: TLInt,
        readonly p: TLBytes,
        readonly version: TLInt,
        readonly random: TLBytes
      ) {}
    }
  }

  export namespace messages {
    export class SentEncryptedMessage implements TLObject {
      static readonly cons = new TLInt(0x560f8935);

      static deserialized(_data: ByteStream): SentEncryptedMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentEncryptedMessage.cons))
          return undefined;

        const date = TLInt.deserialized(_data);
        if (!date) return undefined;

        return new SentEncryptedMessage(date);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentEncryptedMessage.cons];

        _data.push(this.date);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly date: TLInt) {}
    }
  }

  export namespace messages {
    export class SentEncryptedFile implements TLObject {
      static readonly cons = new TLInt(0x9493ff32);

      static deserialized(_data: ByteStream): SentEncryptedFile | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentEncryptedFile.cons))
          return undefined;

        const date = TLInt.deserialized(_data);
        if (!date) return undefined;
        const file = TLObject.deserialized(_data) as EncryptedFileType;
        if (!file) return undefined;

        return new SentEncryptedFile(date, file);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentEncryptedFile.cons];

        _data.push(this.date);
        _data.push(this.file);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly date: TLInt, readonly file: EncryptedFileType) {}
    }
  }

  export class InputDocumentEmpty implements TLObject {
    static readonly cons = new TLInt(0x72f0eaae);

    static deserialized(_data: ByteStream): InputDocumentEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputDocumentEmpty.cons))
        return undefined;

      return new InputDocumentEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputDocumentEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputDocument implements TLObject {
    static readonly cons = new TLInt(0x1abfb575);

    static deserialized(_data: ByteStream): InputDocument | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputDocument.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const fileReference = TLBytes.deserialized(_data);
      if (!fileReference) return undefined;

      return new InputDocument(id, accessHash, fileReference);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputDocument.cons];

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.fileReference);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly fileReference: TLBytes
    ) {}
  }

  export class DocumentEmpty implements TLObject {
    static readonly cons = new TLInt(0x36f8c871);

    static deserialized(_data: ByteStream): DocumentEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DocumentEmpty.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;

      return new DocumentEmpty(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DocumentEmpty.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong) {}
  }

  export class Document implements TLObject {
    static readonly cons = new TLInt(0x8fd4c4d8);

    static deserialized(_data: ByteStream): Document | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Document.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const fileReference = TLBytes.deserialized(_data);
      if (!fileReference) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const mimeType = TLString.deserialized(_data);
      if (!mimeType) return undefined;
      const size = TLLong.deserialized(_data);
      if (!size) return undefined;
      let thumbs: TLVector<PhotoSizeType> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<PhotoSizeType>;
        if (!obj) return undefined;
        thumbs = obj;
      }
      let videoThumbs: TLVector<VideoSizeType> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<VideoSizeType>;
        if (!obj) return undefined;
        videoThumbs = obj;
      }
      const dcId = TLInt.deserialized(_data);
      if (!dcId) return undefined;
      const attributes = TLVector.deserialized(
        _data
      ) as TLVector<DocumentAttributeType>;
      if (!attributes) return undefined;

      return new Document(
        id,
        accessHash,
        fileReference,
        date,
        mimeType,
        size,
        thumbs,
        videoThumbs,
        dcId,
        attributes
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Document.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.fileReference);
      _data.push(this.date);
      _data.push(this.mimeType);
      _data.push(this.size);
      if (this.thumbs) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.thumbs) _data.push(this.thumbs);

      if (this.videoThumbs) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.videoThumbs) _data.push(this.videoThumbs);

      _data.push(this.dcId);
      _data.push(this.attributes);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly fileReference: TLBytes,
      readonly date: TLInt,
      readonly mimeType: TLString,
      readonly size: TLLong,
      readonly thumbs: TLVector<PhotoSizeType> | undefined,
      readonly videoThumbs: TLVector<VideoSizeType> | undefined,
      readonly dcId: TLInt,
      readonly attributes: TLVector<DocumentAttributeType>
    ) {}
  }

  export namespace help {
    export class Support implements TLObject {
      static readonly cons = new TLInt(0x17c6b5f6);

      static deserialized(_data: ByteStream): Support | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Support.cons)) return undefined;

        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const user = TLObject.deserialized(_data) as UserType;
        if (!user) return undefined;

        return new Support(phoneNumber, user);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Support.cons];

        _data.push(this.phoneNumber);
        _data.push(this.user);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly phoneNumber: TLString, readonly user: UserType) {}
    }
  }

  export class NotifyPeer implements TLObject {
    static readonly cons = new TLInt(0x9fd40bd8);

    static deserialized(_data: ByteStream): NotifyPeer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(NotifyPeer.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;

      return new NotifyPeer(peer);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [NotifyPeer.cons];

      _data.push(this.peer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType) {}
  }

  export class NotifyUsers implements TLObject {
    static readonly cons = new TLInt(0xb4c83b4c);

    static deserialized(_data: ByteStream): NotifyUsers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(NotifyUsers.cons))
        return undefined;

      return new NotifyUsers();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [NotifyUsers.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class NotifyChats implements TLObject {
    static readonly cons = new TLInt(0xc007cec3);

    static deserialized(_data: ByteStream): NotifyChats | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(NotifyChats.cons))
        return undefined;

      return new NotifyChats();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [NotifyChats.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class NotifyBroadcasts implements TLObject {
    static readonly cons = new TLInt(0xd612e8ef);

    static deserialized(_data: ByteStream): NotifyBroadcasts | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(NotifyBroadcasts.cons))
        return undefined;

      return new NotifyBroadcasts();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [NotifyBroadcasts.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class NotifyForumTopic implements TLObject {
    static readonly cons = new TLInt(0x226e6308);

    static deserialized(_data: ByteStream): NotifyForumTopic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(NotifyForumTopic.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const topMsgId = TLInt.deserialized(_data);
      if (!topMsgId) return undefined;

      return new NotifyForumTopic(peer, topMsgId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [NotifyForumTopic.cons];

      _data.push(this.peer);
      _data.push(this.topMsgId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType, readonly topMsgId: TLInt) {}
  }

  export class SendMessageTypingAction implements TLObject {
    static readonly cons = new TLInt(0x16bf744e);

    static deserialized(
      _data: ByteStream
    ): SendMessageTypingAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SendMessageTypingAction.cons))
        return undefined;

      return new SendMessageTypingAction();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageTypingAction.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SendMessageCancelAction implements TLObject {
    static readonly cons = new TLInt(0xfd5ec8f5);

    static deserialized(
      _data: ByteStream
    ): SendMessageCancelAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SendMessageCancelAction.cons))
        return undefined;

      return new SendMessageCancelAction();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageCancelAction.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SendMessageRecordVideoAction implements TLObject {
    static readonly cons = new TLInt(0xa187d66f);

    static deserialized(
      _data: ByteStream
    ): SendMessageRecordVideoAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageRecordVideoAction.cons)
      )
        return undefined;

      return new SendMessageRecordVideoAction();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageRecordVideoAction.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SendMessageUploadVideoAction implements TLObject {
    static readonly cons = new TLInt(0xe9763aec);

    static deserialized(
      _data: ByteStream
    ): SendMessageUploadVideoAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageUploadVideoAction.cons)
      )
        return undefined;

      const progress = TLInt.deserialized(_data);
      if (!progress) return undefined;

      return new SendMessageUploadVideoAction(progress);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageUploadVideoAction.cons];

      _data.push(this.progress);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly progress: TLInt) {}
  }

  export class SendMessageRecordAudioAction implements TLObject {
    static readonly cons = new TLInt(0xd52f73f7);

    static deserialized(
      _data: ByteStream
    ): SendMessageRecordAudioAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageRecordAudioAction.cons)
      )
        return undefined;

      return new SendMessageRecordAudioAction();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageRecordAudioAction.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SendMessageUploadAudioAction implements TLObject {
    static readonly cons = new TLInt(0xf351d7ab);

    static deserialized(
      _data: ByteStream
    ): SendMessageUploadAudioAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageUploadAudioAction.cons)
      )
        return undefined;

      const progress = TLInt.deserialized(_data);
      if (!progress) return undefined;

      return new SendMessageUploadAudioAction(progress);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageUploadAudioAction.cons];

      _data.push(this.progress);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly progress: TLInt) {}
  }

  export class SendMessageUploadPhotoAction implements TLObject {
    static readonly cons = new TLInt(0xd1d34a26);

    static deserialized(
      _data: ByteStream
    ): SendMessageUploadPhotoAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageUploadPhotoAction.cons)
      )
        return undefined;

      const progress = TLInt.deserialized(_data);
      if (!progress) return undefined;

      return new SendMessageUploadPhotoAction(progress);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageUploadPhotoAction.cons];

      _data.push(this.progress);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly progress: TLInt) {}
  }

  export class SendMessageUploadDocumentAction implements TLObject {
    static readonly cons = new TLInt(0xaa0cd9e4);

    static deserialized(
      _data: ByteStream
    ): SendMessageUploadDocumentAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageUploadDocumentAction.cons)
      )
        return undefined;

      const progress = TLInt.deserialized(_data);
      if (!progress) return undefined;

      return new SendMessageUploadDocumentAction(progress);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SendMessageUploadDocumentAction.cons,
      ];

      _data.push(this.progress);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly progress: TLInt) {}
  }

  export class SendMessageGeoLocationAction implements TLObject {
    static readonly cons = new TLInt(0x176f8ba1);

    static deserialized(
      _data: ByteStream
    ): SendMessageGeoLocationAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageGeoLocationAction.cons)
      )
        return undefined;

      return new SendMessageGeoLocationAction();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageGeoLocationAction.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SendMessageChooseContactAction implements TLObject {
    static readonly cons = new TLInt(0x628cbc6f);

    static deserialized(
      _data: ByteStream
    ): SendMessageChooseContactAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageChooseContactAction.cons)
      )
        return undefined;

      return new SendMessageChooseContactAction();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SendMessageChooseContactAction.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SendMessageGamePlayAction implements TLObject {
    static readonly cons = new TLInt(0xdd6a8f48);

    static deserialized(
      _data: ByteStream
    ): SendMessageGamePlayAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SendMessageGamePlayAction.cons))
        return undefined;

      return new SendMessageGamePlayAction();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageGamePlayAction.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SendMessageRecordRoundAction implements TLObject {
    static readonly cons = new TLInt(0x88f27fbc);

    static deserialized(
      _data: ByteStream
    ): SendMessageRecordRoundAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageRecordRoundAction.cons)
      )
        return undefined;

      return new SendMessageRecordRoundAction();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageRecordRoundAction.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SendMessageUploadRoundAction implements TLObject {
    static readonly cons = new TLInt(0x243e1c66);

    static deserialized(
      _data: ByteStream
    ): SendMessageUploadRoundAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageUploadRoundAction.cons)
      )
        return undefined;

      const progress = TLInt.deserialized(_data);
      if (!progress) return undefined;

      return new SendMessageUploadRoundAction(progress);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageUploadRoundAction.cons];

      _data.push(this.progress);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly progress: TLInt) {}
  }

  export class SpeakingInGroupCallAction implements TLObject {
    static readonly cons = new TLInt(0xd92c2285);

    static deserialized(
      _data: ByteStream
    ): SpeakingInGroupCallAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SpeakingInGroupCallAction.cons))
        return undefined;

      return new SpeakingInGroupCallAction();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SpeakingInGroupCallAction.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SendMessageHistoryImportAction implements TLObject {
    static readonly cons = new TLInt(0xdbda9246);

    static deserialized(
      _data: ByteStream
    ): SendMessageHistoryImportAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageHistoryImportAction.cons)
      )
        return undefined;

      const progress = TLInt.deserialized(_data);
      if (!progress) return undefined;

      return new SendMessageHistoryImportAction(progress);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SendMessageHistoryImportAction.cons,
      ];

      _data.push(this.progress);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly progress: TLInt) {}
  }

  export class SendMessageChooseStickerAction implements TLObject {
    static readonly cons = new TLInt(0xb05ac6b1);

    static deserialized(
      _data: ByteStream
    ): SendMessageChooseStickerAction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageChooseStickerAction.cons)
      )
        return undefined;

      return new SendMessageChooseStickerAction();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SendMessageChooseStickerAction.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SendMessageEmojiInteraction implements TLObject {
    static readonly cons = new TLInt(0x25972bcb);

    static deserialized(
      _data: ByteStream
    ): SendMessageEmojiInteraction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SendMessageEmojiInteraction.cons))
        return undefined;

      const emoticon = TLString.deserialized(_data);
      if (!emoticon) return undefined;
      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;
      const interaction = API.DataJSON.deserialized(_data);
      if (!interaction) return undefined;

      return new SendMessageEmojiInteraction(emoticon, msgId, interaction);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendMessageEmojiInteraction.cons];

      _data.push(this.emoticon);
      _data.push(this.msgId);
      _data.push(this.interaction);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly emoticon: TLString,
      readonly msgId: TLInt,
      readonly interaction: API.DataJSON
    ) {}
  }

  export class SendMessageEmojiInteractionSeen implements TLObject {
    static readonly cons = new TLInt(0xb665902e);

    static deserialized(
      _data: ByteStream
    ): SendMessageEmojiInteractionSeen | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SendMessageEmojiInteractionSeen.cons)
      )
        return undefined;

      const emoticon = TLString.deserialized(_data);
      if (!emoticon) return undefined;

      return new SendMessageEmojiInteractionSeen(emoticon);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SendMessageEmojiInteractionSeen.cons,
      ];

      _data.push(this.emoticon);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly emoticon: TLString) {}
  }

  export namespace contacts {
    export class Found implements TLObject {
      static readonly cons = new TLInt(0xb3134d9d);

      static deserialized(_data: ByteStream): Found | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Found.cons)) return undefined;

        const myResults = TLVector.deserialized(_data) as TLVector<PeerType>;
        if (!myResults) return undefined;
        const results = TLVector.deserialized(_data) as TLVector<PeerType>;
        if (!results) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new Found(myResults, results, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Found.cons];

        _data.push(this.myResults);
        _data.push(this.results);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly myResults: TLVector<PeerType>,
        readonly results: TLVector<PeerType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class InputPrivacyKeyStatusTimestamp implements TLObject {
    static readonly cons = new TLInt(0x4f96cb18);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyKeyStatusTimestamp | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPrivacyKeyStatusTimestamp.cons)
      )
        return undefined;

      return new InputPrivacyKeyStatusTimestamp();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputPrivacyKeyStatusTimestamp.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyKeyChatInvite implements TLObject {
    static readonly cons = new TLInt(0xbdfb0426);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyKeyChatInvite | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPrivacyKeyChatInvite.cons))
        return undefined;

      return new InputPrivacyKeyChatInvite();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyKeyChatInvite.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyKeyPhoneCall implements TLObject {
    static readonly cons = new TLInt(0xfabadc5f);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyKeyPhoneCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPrivacyKeyPhoneCall.cons))
        return undefined;

      return new InputPrivacyKeyPhoneCall();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyKeyPhoneCall.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyKeyPhoneP2P implements TLObject {
    static readonly cons = new TLInt(0xdb9e70d2);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyKeyPhoneP2P | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPrivacyKeyPhoneP2P.cons))
        return undefined;

      return new InputPrivacyKeyPhoneP2P();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyKeyPhoneP2P.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyKeyForwards implements TLObject {
    static readonly cons = new TLInt(0xa4dd4c08);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyKeyForwards | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPrivacyKeyForwards.cons))
        return undefined;

      return new InputPrivacyKeyForwards();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyKeyForwards.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyKeyProfilePhoto implements TLObject {
    static readonly cons = new TLInt(0x5719bacc);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyKeyProfilePhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPrivacyKeyProfilePhoto.cons))
        return undefined;

      return new InputPrivacyKeyProfilePhoto();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyKeyProfilePhoto.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyKeyPhoneNumber implements TLObject {
    static readonly cons = new TLInt(0x352dafa);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyKeyPhoneNumber | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPrivacyKeyPhoneNumber.cons))
        return undefined;

      return new InputPrivacyKeyPhoneNumber();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyKeyPhoneNumber.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyKeyAddedByPhone implements TLObject {
    static readonly cons = new TLInt(0xd1219bdd);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyKeyAddedByPhone | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPrivacyKeyAddedByPhone.cons))
        return undefined;

      return new InputPrivacyKeyAddedByPhone();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyKeyAddedByPhone.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyKeyVoiceMessages implements TLObject {
    static readonly cons = new TLInt(0xaee69d68);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyKeyVoiceMessages | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPrivacyKeyVoiceMessages.cons)
      )
        return undefined;

      return new InputPrivacyKeyVoiceMessages();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyKeyVoiceMessages.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyKeyAbout implements TLObject {
    static readonly cons = new TLInt(0x3823cc40);

    static deserialized(_data: ByteStream): InputPrivacyKeyAbout | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPrivacyKeyAbout.cons))
        return undefined;

      return new InputPrivacyKeyAbout();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyKeyAbout.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyKeyStatusTimestamp implements TLObject {
    static readonly cons = new TLInt(0xbc2eab30);

    static deserialized(
      _data: ByteStream
    ): PrivacyKeyStatusTimestamp | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyKeyStatusTimestamp.cons))
        return undefined;

      return new PrivacyKeyStatusTimestamp();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyKeyStatusTimestamp.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyKeyChatInvite implements TLObject {
    static readonly cons = new TLInt(0x500e6dfa);

    static deserialized(_data: ByteStream): PrivacyKeyChatInvite | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyKeyChatInvite.cons))
        return undefined;

      return new PrivacyKeyChatInvite();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyKeyChatInvite.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyKeyPhoneCall implements TLObject {
    static readonly cons = new TLInt(0x3d662b7b);

    static deserialized(_data: ByteStream): PrivacyKeyPhoneCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyKeyPhoneCall.cons))
        return undefined;

      return new PrivacyKeyPhoneCall();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyKeyPhoneCall.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyKeyPhoneP2P implements TLObject {
    static readonly cons = new TLInt(0x39491cc8);

    static deserialized(_data: ByteStream): PrivacyKeyPhoneP2P | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyKeyPhoneP2P.cons))
        return undefined;

      return new PrivacyKeyPhoneP2P();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyKeyPhoneP2P.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyKeyForwards implements TLObject {
    static readonly cons = new TLInt(0x69ec56a3);

    static deserialized(_data: ByteStream): PrivacyKeyForwards | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyKeyForwards.cons))
        return undefined;

      return new PrivacyKeyForwards();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyKeyForwards.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyKeyProfilePhoto implements TLObject {
    static readonly cons = new TLInt(0x96151fed);

    static deserialized(_data: ByteStream): PrivacyKeyProfilePhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyKeyProfilePhoto.cons))
        return undefined;

      return new PrivacyKeyProfilePhoto();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyKeyProfilePhoto.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyKeyPhoneNumber implements TLObject {
    static readonly cons = new TLInt(0xd19ae46d);

    static deserialized(_data: ByteStream): PrivacyKeyPhoneNumber | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyKeyPhoneNumber.cons))
        return undefined;

      return new PrivacyKeyPhoneNumber();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyKeyPhoneNumber.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyKeyAddedByPhone implements TLObject {
    static readonly cons = new TLInt(0x42ffd42b);

    static deserialized(_data: ByteStream): PrivacyKeyAddedByPhone | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyKeyAddedByPhone.cons))
        return undefined;

      return new PrivacyKeyAddedByPhone();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyKeyAddedByPhone.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyKeyVoiceMessages implements TLObject {
    static readonly cons = new TLInt(0x697f414);

    static deserialized(
      _data: ByteStream
    ): PrivacyKeyVoiceMessages | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyKeyVoiceMessages.cons))
        return undefined;

      return new PrivacyKeyVoiceMessages();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyKeyVoiceMessages.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyKeyAbout implements TLObject {
    static readonly cons = new TLInt(0xa486b761);

    static deserialized(_data: ByteStream): PrivacyKeyAbout | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyKeyAbout.cons))
        return undefined;

      return new PrivacyKeyAbout();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyKeyAbout.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyValueAllowContacts implements TLObject {
    static readonly cons = new TLInt(0xd09e07b);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyValueAllowContacts | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPrivacyValueAllowContacts.cons)
      )
        return undefined;

      return new InputPrivacyValueAllowContacts();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputPrivacyValueAllowContacts.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyValueAllowAll implements TLObject {
    static readonly cons = new TLInt(0x184b35ce);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyValueAllowAll | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPrivacyValueAllowAll.cons))
        return undefined;

      return new InputPrivacyValueAllowAll();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyValueAllowAll.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyValueAllowUsers implements TLObject {
    static readonly cons = new TLInt(0x131cc67f);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyValueAllowUsers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPrivacyValueAllowUsers.cons))
        return undefined;

      const users = TLVector.deserialized(_data) as TLVector<InputUserType>;
      if (!users) return undefined;

      return new InputPrivacyValueAllowUsers(users);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyValueAllowUsers.cons];

      _data.push(this.users);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly users: TLVector<InputUserType>) {}
  }

  export class InputPrivacyValueDisallowContacts implements TLObject {
    static readonly cons = new TLInt(0xba52007);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyValueDisallowContacts | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPrivacyValueDisallowContacts.cons)
      )
        return undefined;

      return new InputPrivacyValueDisallowContacts();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputPrivacyValueDisallowContacts.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyValueDisallowAll implements TLObject {
    static readonly cons = new TLInt(0xd66b66c9);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyValueDisallowAll | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPrivacyValueDisallowAll.cons)
      )
        return undefined;

      return new InputPrivacyValueDisallowAll();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPrivacyValueDisallowAll.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputPrivacyValueDisallowUsers implements TLObject {
    static readonly cons = new TLInt(0x90110467);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyValueDisallowUsers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPrivacyValueDisallowUsers.cons)
      )
        return undefined;

      const users = TLVector.deserialized(_data) as TLVector<InputUserType>;
      if (!users) return undefined;

      return new InputPrivacyValueDisallowUsers(users);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputPrivacyValueDisallowUsers.cons,
      ];

      _data.push(this.users);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly users: TLVector<InputUserType>) {}
  }

  export class InputPrivacyValueAllowChatParticipants implements TLObject {
    static readonly cons = new TLInt(0x840649cf);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyValueAllowChatParticipants | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPrivacyValueAllowChatParticipants.cons)
      )
        return undefined;

      const chats = TLVector.deserialized(_data, TLLong);
      if (!chats) return undefined;

      return new InputPrivacyValueAllowChatParticipants(chats);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputPrivacyValueAllowChatParticipants.cons,
      ];

      _data.push(this.chats);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chats: TLVector<TLLong>) {}
  }

  export class InputPrivacyValueDisallowChatParticipants implements TLObject {
    static readonly cons = new TLInt(0xe94f0f86);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyValueDisallowChatParticipants | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPrivacyValueDisallowChatParticipants.cons)
      )
        return undefined;

      const chats = TLVector.deserialized(_data, TLLong);
      if (!chats) return undefined;

      return new InputPrivacyValueDisallowChatParticipants(chats);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputPrivacyValueDisallowChatParticipants.cons,
      ];

      _data.push(this.chats);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chats: TLVector<TLLong>) {}
  }

  export class InputPrivacyValueAllowCloseFriends implements TLObject {
    static readonly cons = new TLInt(0x2f453e49);

    static deserialized(
      _data: ByteStream
    ): InputPrivacyValueAllowCloseFriends | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPrivacyValueAllowCloseFriends.cons)
      )
        return undefined;

      return new InputPrivacyValueAllowCloseFriends();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputPrivacyValueAllowCloseFriends.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyValueAllowContacts implements TLObject {
    static readonly cons = new TLInt(0xfffe1bac);

    static deserialized(
      _data: ByteStream
    ): PrivacyValueAllowContacts | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyValueAllowContacts.cons))
        return undefined;

      return new PrivacyValueAllowContacts();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyValueAllowContacts.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyValueAllowAll implements TLObject {
    static readonly cons = new TLInt(0x65427b82);

    static deserialized(_data: ByteStream): PrivacyValueAllowAll | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyValueAllowAll.cons))
        return undefined;

      return new PrivacyValueAllowAll();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyValueAllowAll.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyValueAllowUsers implements TLObject {
    static readonly cons = new TLInt(0xb8905fb2);

    static deserialized(_data: ByteStream): PrivacyValueAllowUsers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyValueAllowUsers.cons))
        return undefined;

      const users = TLVector.deserialized(_data, TLLong);
      if (!users) return undefined;

      return new PrivacyValueAllowUsers(users);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyValueAllowUsers.cons];

      _data.push(this.users);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly users: TLVector<TLLong>) {}
  }

  export class PrivacyValueDisallowContacts implements TLObject {
    static readonly cons = new TLInt(0xf888fa1a);

    static deserialized(
      _data: ByteStream
    ): PrivacyValueDisallowContacts | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(PrivacyValueDisallowContacts.cons)
      )
        return undefined;

      return new PrivacyValueDisallowContacts();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyValueDisallowContacts.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyValueDisallowAll implements TLObject {
    static readonly cons = new TLInt(0x8b73e763);

    static deserialized(
      _data: ByteStream
    ): PrivacyValueDisallowAll | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyValueDisallowAll.cons))
        return undefined;

      return new PrivacyValueDisallowAll();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyValueDisallowAll.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PrivacyValueDisallowUsers implements TLObject {
    static readonly cons = new TLInt(0xe4621141);

    static deserialized(
      _data: ByteStream
    ): PrivacyValueDisallowUsers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PrivacyValueDisallowUsers.cons))
        return undefined;

      const users = TLVector.deserialized(_data, TLLong);
      if (!users) return undefined;

      return new PrivacyValueDisallowUsers(users);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyValueDisallowUsers.cons];

      _data.push(this.users);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly users: TLVector<TLLong>) {}
  }

  export class PrivacyValueAllowChatParticipants implements TLObject {
    static readonly cons = new TLInt(0x6b134e8e);

    static deserialized(
      _data: ByteStream
    ): PrivacyValueAllowChatParticipants | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(PrivacyValueAllowChatParticipants.cons)
      )
        return undefined;

      const chats = TLVector.deserialized(_data, TLLong);
      if (!chats) return undefined;

      return new PrivacyValueAllowChatParticipants(chats);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        PrivacyValueAllowChatParticipants.cons,
      ];

      _data.push(this.chats);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chats: TLVector<TLLong>) {}
  }

  export class PrivacyValueDisallowChatParticipants implements TLObject {
    static readonly cons = new TLInt(0x41c87565);

    static deserialized(
      _data: ByteStream
    ): PrivacyValueDisallowChatParticipants | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(PrivacyValueDisallowChatParticipants.cons)
      )
        return undefined;

      const chats = TLVector.deserialized(_data, TLLong);
      if (!chats) return undefined;

      return new PrivacyValueDisallowChatParticipants(chats);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        PrivacyValueDisallowChatParticipants.cons,
      ];

      _data.push(this.chats);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chats: TLVector<TLLong>) {}
  }

  export class PrivacyValueAllowCloseFriends implements TLObject {
    static readonly cons = new TLInt(0xf7e8d89b);

    static deserialized(
      _data: ByteStream
    ): PrivacyValueAllowCloseFriends | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(PrivacyValueAllowCloseFriends.cons)
      )
        return undefined;

      return new PrivacyValueAllowCloseFriends();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PrivacyValueAllowCloseFriends.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export namespace account {
    export class PrivacyRules implements TLObject {
      static readonly cons = new TLInt(0x50a04e45);

      static deserialized(_data: ByteStream): PrivacyRules | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PrivacyRules.cons))
          return undefined;

        const rules = TLVector.deserialized(_data) as TLVector<PrivacyRuleType>;
        if (!rules) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new PrivacyRules(rules, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PrivacyRules.cons];

        _data.push(this.rules);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly rules: TLVector<PrivacyRuleType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class AccountDaysTTL implements TLObject {
    static readonly cons = new TLInt(0xb8d0afdf);

    static deserialized(_data: ByteStream): AccountDaysTTL | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AccountDaysTTL.cons))
        return undefined;

      const days = TLInt.deserialized(_data);
      if (!days) return undefined;

      return new AccountDaysTTL(days);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AccountDaysTTL.cons];

      _data.push(this.days);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly days: TLInt) {}
  }

  export class DocumentAttributeImageSize implements TLObject {
    static readonly cons = new TLInt(0x6c37c15c);

    static deserialized(
      _data: ByteStream
    ): DocumentAttributeImageSize | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DocumentAttributeImageSize.cons))
        return undefined;

      const w = TLInt.deserialized(_data);
      if (!w) return undefined;
      const h = TLInt.deserialized(_data);
      if (!h) return undefined;

      return new DocumentAttributeImageSize(w, h);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DocumentAttributeImageSize.cons];

      _data.push(this.w);
      _data.push(this.h);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly w: TLInt, readonly h: TLInt) {}
  }

  export class DocumentAttributeAnimated implements TLObject {
    static readonly cons = new TLInt(0x11b58939);

    static deserialized(
      _data: ByteStream
    ): DocumentAttributeAnimated | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DocumentAttributeAnimated.cons))
        return undefined;

      return new DocumentAttributeAnimated();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DocumentAttributeAnimated.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class DocumentAttributeSticker implements TLObject {
    static readonly cons = new TLInt(0x6319d612);

    static deserialized(
      _data: ByteStream
    ): DocumentAttributeSticker | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DocumentAttributeSticker.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const mask = (flags.value & 2) !== 0;
      const alt = TLString.deserialized(_data);
      if (!alt) return undefined;
      const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
      if (!stickerset) return undefined;
      let maskCoords: API.MaskCoords | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as API.MaskCoords;
        if (!obj) return undefined;
        maskCoords = obj;
      }

      return new DocumentAttributeSticker(mask, alt, stickerset, maskCoords);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DocumentAttributeSticker.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.mask) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.alt);
      _data.push(this.stickerset);
      if (this.maskCoords) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.maskCoords) _data.push(this.maskCoords);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly mask: boolean | undefined,
      readonly alt: TLString,
      readonly stickerset: InputStickerSetType,
      readonly maskCoords: API.MaskCoords | undefined
    ) {}
  }

  export class DocumentAttributeVideo implements TLObject {
    static readonly cons = new TLInt(0xd38ff1c2);

    static deserialized(_data: ByteStream): DocumentAttributeVideo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DocumentAttributeVideo.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const roundMessage = (flags.value & 1) !== 0;
      const supportsStreaming = (flags.value & 2) !== 0;
      const nosound = (flags.value & 8) !== 0;
      const duration = TLDouble.deserialized(_data);
      if (!duration) return undefined;
      const w = TLInt.deserialized(_data);
      if (!w) return undefined;
      const h = TLInt.deserialized(_data);
      if (!h) return undefined;
      let preloadPrefixSize: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        preloadPrefixSize = obj;
      }

      return new DocumentAttributeVideo(
        roundMessage,
        supportsStreaming,
        nosound,
        duration,
        w,
        h,
        preloadPrefixSize
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DocumentAttributeVideo.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.roundMessage) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.supportsStreaming) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.nosound) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      _data.push(this.duration);
      _data.push(this.w);
      _data.push(this.h);
      if (this.preloadPrefixSize) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.preloadPrefixSize) _data.push(this.preloadPrefixSize);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly roundMessage: boolean | undefined,
      readonly supportsStreaming: boolean | undefined,
      readonly nosound: boolean | undefined,
      readonly duration: TLDouble,
      readonly w: TLInt,
      readonly h: TLInt,
      readonly preloadPrefixSize: TLInt | undefined
    ) {}
  }

  export class DocumentAttributeAudio implements TLObject {
    static readonly cons = new TLInt(0x9852f9c6);

    static deserialized(_data: ByteStream): DocumentAttributeAudio | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DocumentAttributeAudio.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const voice = (flags.value & 1024) !== 0;
      const duration = TLInt.deserialized(_data);
      if (!duration) return undefined;
      let title: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        title = obj;
      }
      let performer: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        performer = obj;
      }
      let waveform: TLBytes | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLBytes.deserialized(_data);
        if (!obj) return undefined;
        waveform = obj;
      }

      return new DocumentAttributeAudio(
        voice,
        duration,
        title,
        performer,
        waveform
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DocumentAttributeAudio.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.voice) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }

      _data.push(this.duration);
      if (this.title) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.title) _data.push(this.title);

      if (this.performer) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.performer) _data.push(this.performer);

      if (this.waveform) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.waveform) _data.push(this.waveform);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly voice: boolean | undefined,
      readonly duration: TLInt,
      readonly title: TLString | undefined,
      readonly performer: TLString | undefined,
      readonly waveform: TLBytes | undefined
    ) {}
  }

  export class DocumentAttributeFilename implements TLObject {
    static readonly cons = new TLInt(0x15590068);

    static deserialized(
      _data: ByteStream
    ): DocumentAttributeFilename | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DocumentAttributeFilename.cons))
        return undefined;

      const fileName = TLString.deserialized(_data);
      if (!fileName) return undefined;

      return new DocumentAttributeFilename(fileName);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DocumentAttributeFilename.cons];

      _data.push(this.fileName);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly fileName: TLString) {}
  }

  export class DocumentAttributeHasStickers implements TLObject {
    static readonly cons = new TLInt(0x9801d2f7);

    static deserialized(
      _data: ByteStream
    ): DocumentAttributeHasStickers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(DocumentAttributeHasStickers.cons)
      )
        return undefined;

      return new DocumentAttributeHasStickers();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DocumentAttributeHasStickers.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class DocumentAttributeCustomEmoji implements TLObject {
    static readonly cons = new TLInt(0xfd149899);

    static deserialized(
      _data: ByteStream
    ): DocumentAttributeCustomEmoji | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(DocumentAttributeCustomEmoji.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const free = (flags.value & 1) !== 0;
      const textColor = (flags.value & 2) !== 0;
      const alt = TLString.deserialized(_data);
      if (!alt) return undefined;
      const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
      if (!stickerset) return undefined;

      return new DocumentAttributeCustomEmoji(free, textColor, alt, stickerset);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DocumentAttributeCustomEmoji.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.free) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.textColor) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.alt);
      _data.push(this.stickerset);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly free: boolean | undefined,
      readonly textColor: boolean | undefined,
      readonly alt: TLString,
      readonly stickerset: InputStickerSetType
    ) {}
  }

  export namespace messages {
    export class StickersNotModified implements TLObject {
      static readonly cons = new TLInt(0xf1749a22);

      static deserialized(_data: ByteStream): StickersNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(StickersNotModified.cons))
          return undefined;

        return new StickersNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [StickersNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class Stickers implements TLObject {
      static readonly cons = new TLInt(0x30a6ec7e);

      static deserialized(_data: ByteStream): Stickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Stickers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const stickers = TLVector.deserialized(_data) as TLVector<DocumentType>;
        if (!stickers) return undefined;

        return new Stickers(hash, stickers);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Stickers.cons];

        _data.push(this.hash);
        _data.push(this.stickers);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLLong,
        readonly stickers: TLVector<DocumentType>
      ) {}
    }
  }

  export class StickerPack implements TLObject {
    static readonly cons = new TLInt(0x12b299d4);

    static deserialized(_data: ByteStream): StickerPack | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StickerPack.cons))
        return undefined;

      const emoticon = TLString.deserialized(_data);
      if (!emoticon) return undefined;
      const documents = TLVector.deserialized(_data, TLLong);
      if (!documents) return undefined;

      return new StickerPack(emoticon, documents);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StickerPack.cons];

      _data.push(this.emoticon);
      _data.push(this.documents);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly emoticon: TLString,
      readonly documents: TLVector<TLLong>
    ) {}
  }

  export namespace messages {
    export class AllStickersNotModified implements TLObject {
      static readonly cons = new TLInt(0xe86602c3);

      static deserialized(
        _data: ByteStream
      ): AllStickersNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AllStickersNotModified.cons))
          return undefined;

        return new AllStickersNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AllStickersNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class AllStickers implements TLObject {
      static readonly cons = new TLInt(0xcdbbcebb);

      static deserialized(_data: ByteStream): AllStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AllStickers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const sets = TLVector.deserialized(_data, API.StickerSet);
        if (!sets) return undefined;

        return new AllStickers(hash, sets);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AllStickers.cons];

        _data.push(this.hash);
        _data.push(this.sets);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLLong,
        readonly sets: TLVector<API.StickerSet>
      ) {}
    }
  }

  export namespace messages {
    export class AffectedMessages implements TLObject {
      static readonly cons = new TLInt(0x84d19185);

      static deserialized(_data: ByteStream): AffectedMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AffectedMessages.cons))
          return undefined;

        const pts = TLInt.deserialized(_data);
        if (!pts) return undefined;
        const ptsCount = TLInt.deserialized(_data);
        if (!ptsCount) return undefined;

        return new AffectedMessages(pts, ptsCount);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AffectedMessages.cons];

        _data.push(this.pts);
        _data.push(this.ptsCount);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly pts: TLInt, readonly ptsCount: TLInt) {}
    }
  }

  export class WebPageEmpty implements TLObject {
    static readonly cons = new TLInt(0xeb1477e8);

    static deserialized(_data: ByteStream): WebPageEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WebPageEmpty.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;

      return new WebPageEmpty(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WebPageEmpty.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong) {}
  }

  export class WebPagePending implements TLObject {
    static readonly cons = new TLInt(0xc586da1c);

    static deserialized(_data: ByteStream): WebPagePending | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WebPagePending.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new WebPagePending(id, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WebPagePending.cons];

      _data.push(this.id);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly date: TLInt) {}
  }

  export class WebPage implements TLObject {
    static readonly cons = new TLInt(0xe89c45b2);

    static deserialized(_data: ByteStream): WebPage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WebPage.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const displayUrl = TLString.deserialized(_data);
      if (!displayUrl) return undefined;
      const hash = TLInt.deserialized(_data);
      if (!hash) return undefined;
      let type: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        type = obj;
      }
      let siteName: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        siteName = obj;
      }
      let title: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        title = obj;
      }
      let description: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        description = obj;
      }
      let photo: PhotoType | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLObject.deserialized(_data) as PhotoType;
        if (!obj) return undefined;
        photo = obj;
      }
      let embedUrl: TLString | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        embedUrl = obj;
      }
      let embedType: TLString | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        embedType = obj;
      }
      let embedWidth: TLInt | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        embedWidth = obj;
      }
      let embedHeight: TLInt | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        embedHeight = obj;
      }
      let duration: TLInt | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        duration = obj;
      }
      let author: TLString | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        author = obj;
      }
      let document: DocumentType | undefined;
      if ((flags.value & 512) !== 0) {
        const obj = TLObject.deserialized(_data) as DocumentType;
        if (!obj) return undefined;
        document = obj;
      }
      let cachedPage: API.Page | undefined;
      if ((flags.value & 1024) !== 0) {
        const obj = TLObject.deserialized(_data) as API.Page;
        if (!obj) return undefined;
        cachedPage = obj;
      }
      let attributes: TLVector<WebPageAttributeType> | undefined;
      if ((flags.value & 4096) !== 0) {
        const obj = TLVector.deserialized(
          _data
        ) as TLVector<WebPageAttributeType>;
        if (!obj) return undefined;
        attributes = obj;
      }

      return new WebPage(
        id,
        url,
        displayUrl,
        hash,
        type,
        siteName,
        title,
        description,
        photo,
        embedUrl,
        embedType,
        embedWidth,
        embedHeight,
        duration,
        author,
        document,
        cachedPage,
        attributes
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WebPage.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.id);
      _data.push(this.url);
      _data.push(this.displayUrl);
      _data.push(this.hash);
      if (this.type) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.type) _data.push(this.type);

      if (this.siteName) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.siteName) _data.push(this.siteName);

      if (this.title) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.title) _data.push(this.title);

      if (this.description) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.description) _data.push(this.description);

      if (this.photo) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.photo) _data.push(this.photo);

      if (this.embedUrl) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.embedUrl) _data.push(this.embedUrl);

      if (this.embedType) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.embedType) _data.push(this.embedType);

      if (this.embedWidth) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.embedWidth) _data.push(this.embedWidth);

      if (this.embedHeight) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.embedHeight) _data.push(this.embedHeight);

      if (this.duration) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.duration) _data.push(this.duration);

      if (this.author) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.author) _data.push(this.author);

      if (this.document) {
        flags.set(512);
      } else {
        flags.unset(512);
      }
      if (this.document) _data.push(this.document);

      if (this.cachedPage) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }
      if (this.cachedPage) _data.push(this.cachedPage);

      if (this.attributes) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }
      if (this.attributes) _data.push(this.attributes);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly url: TLString,
      readonly displayUrl: TLString,
      readonly hash: TLInt,
      readonly type: TLString | undefined,
      readonly siteName: TLString | undefined,
      readonly title: TLString | undefined,
      readonly description: TLString | undefined,
      readonly photo: PhotoType | undefined,
      readonly embedUrl: TLString | undefined,
      readonly embedType: TLString | undefined,
      readonly embedWidth: TLInt | undefined,
      readonly embedHeight: TLInt | undefined,
      readonly duration: TLInt | undefined,
      readonly author: TLString | undefined,
      readonly document: DocumentType | undefined,
      readonly cachedPage: API.Page | undefined,
      readonly attributes: TLVector<WebPageAttributeType> | undefined
    ) {}
  }

  export class WebPageNotModified implements TLObject {
    static readonly cons = new TLInt(0x7311ca11);

    static deserialized(_data: ByteStream): WebPageNotModified | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WebPageNotModified.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let cachedPageViews: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        cachedPageViews = obj;
      }

      return new WebPageNotModified(cachedPageViews);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WebPageNotModified.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.cachedPageViews) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.cachedPageViews) _data.push(this.cachedPageViews);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly cachedPageViews: TLInt | undefined) {}
  }

  export class Authorization implements TLObject {
    static readonly cons = new TLInt(0xad01d61d);

    static deserialized(_data: ByteStream): Authorization | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Authorization.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const current = (flags.value & 1) !== 0;
      const officialApp = (flags.value & 2) !== 0;
      const passwordPending = (flags.value & 4) !== 0;
      const encryptedRequestsDisabled = (flags.value & 8) !== 0;
      const callRequestsDisabled = (flags.value & 16) !== 0;
      const unconfirmed = (flags.value & 32) !== 0;
      const hash = TLLong.deserialized(_data);
      if (!hash) return undefined;
      const deviceModel = TLString.deserialized(_data);
      if (!deviceModel) return undefined;
      const platform = TLString.deserialized(_data);
      if (!platform) return undefined;
      const systemVersion = TLString.deserialized(_data);
      if (!systemVersion) return undefined;
      const apiId = TLInt.deserialized(_data);
      if (!apiId) return undefined;
      const appName = TLString.deserialized(_data);
      if (!appName) return undefined;
      const appVersion = TLString.deserialized(_data);
      if (!appVersion) return undefined;
      const dateCreated = TLInt.deserialized(_data);
      if (!dateCreated) return undefined;
      const dateActive = TLInt.deserialized(_data);
      if (!dateActive) return undefined;
      const ip = TLString.deserialized(_data);
      if (!ip) return undefined;
      const country = TLString.deserialized(_data);
      if (!country) return undefined;
      const region = TLString.deserialized(_data);
      if (!region) return undefined;

      return new Authorization(
        current,
        officialApp,
        passwordPending,
        encryptedRequestsDisabled,
        callRequestsDisabled,
        unconfirmed,
        hash,
        deviceModel,
        platform,
        systemVersion,
        apiId,
        appName,
        appVersion,
        dateCreated,
        dateActive,
        ip,
        country,
        region
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Authorization.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.current) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.officialApp) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.passwordPending) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.encryptedRequestsDisabled) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.callRequestsDisabled) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.unconfirmed) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      _data.push(this.hash);
      _data.push(this.deviceModel);
      _data.push(this.platform);
      _data.push(this.systemVersion);
      _data.push(this.apiId);
      _data.push(this.appName);
      _data.push(this.appVersion);
      _data.push(this.dateCreated);
      _data.push(this.dateActive);
      _data.push(this.ip);
      _data.push(this.country);
      _data.push(this.region);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly current: boolean | undefined,
      readonly officialApp: boolean | undefined,
      readonly passwordPending: boolean | undefined,
      readonly encryptedRequestsDisabled: boolean | undefined,
      readonly callRequestsDisabled: boolean | undefined,
      readonly unconfirmed: boolean | undefined,
      readonly hash: TLLong,
      readonly deviceModel: TLString,
      readonly platform: TLString,
      readonly systemVersion: TLString,
      readonly apiId: TLInt,
      readonly appName: TLString,
      readonly appVersion: TLString,
      readonly dateCreated: TLInt,
      readonly dateActive: TLInt,
      readonly ip: TLString,
      readonly country: TLString,
      readonly region: TLString
    ) {}
  }

  export namespace account {
    export class Authorizations implements TLObject {
      static readonly cons = new TLInt(0x4bff8ea0);

      static deserialized(_data: ByteStream): Authorizations | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Authorizations.cons))
          return undefined;

        const authorizationTtlDays = TLInt.deserialized(_data);
        if (!authorizationTtlDays) return undefined;
        const authorizations = TLVector.deserialized(_data, API.Authorization);
        if (!authorizations) return undefined;

        return new Authorizations(authorizationTtlDays, authorizations);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Authorizations.cons];

        _data.push(this.authorizationTtlDays);
        _data.push(this.authorizations);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly authorizationTtlDays: TLInt,
        readonly authorizations: TLVector<API.Authorization>
      ) {}
    }
  }

  export namespace account {
    export class Password implements TLObject {
      static readonly cons = new TLInt(0x957b50fb);

      static deserialized(_data: ByteStream): Password | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Password.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const hasRecovery = (flags.value & 1) !== 0;
        const hasSecureValues = (flags.value & 2) !== 0;
        const hasPassword = (flags.value & 4) !== 0;
        let currentAlgo: PasswordKdfAlgoType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as PasswordKdfAlgoType;
          if (!obj) return undefined;
          currentAlgo = obj;
        }
        let srpB: TLBytes | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLBytes.deserialized(_data);
          if (!obj) return undefined;
          srpB = obj;
        }
        let srpId: TLLong | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLLong.deserialized(_data);
          if (!obj) return undefined;
          srpId = obj;
        }
        let hint: TLString | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          hint = obj;
        }
        let emailUnconfirmedPattern: TLString | undefined;
        if ((flags.value & 16) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          emailUnconfirmedPattern = obj;
        }
        const newAlgo = TLObject.deserialized(_data) as PasswordKdfAlgoType;
        if (!newAlgo) return undefined;
        const newSecureAlgo = TLObject.deserialized(
          _data
        ) as SecurePasswordKdfAlgoType;
        if (!newSecureAlgo) return undefined;
        const secureRandom = TLBytes.deserialized(_data);
        if (!secureRandom) return undefined;
        let pendingResetDate: TLInt | undefined;
        if ((flags.value & 32) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          pendingResetDate = obj;
        }
        let loginEmailPattern: TLString | undefined;
        if ((flags.value & 64) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          loginEmailPattern = obj;
        }

        return new Password(
          hasRecovery,
          hasSecureValues,
          hasPassword,
          currentAlgo,
          srpB,
          srpId,
          hint,
          emailUnconfirmedPattern,
          newAlgo,
          newSecureAlgo,
          secureRandom,
          pendingResetDate,
          loginEmailPattern
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Password.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.hasRecovery) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.hasSecureValues) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.hasPassword) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        if (this.currentAlgo) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.currentAlgo) _data.push(this.currentAlgo);

        if (this.srpB) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.srpB) _data.push(this.srpB);

        if (this.srpId) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.srpId) _data.push(this.srpId);

        if (this.hint) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.hint) _data.push(this.hint);

        if (this.emailUnconfirmedPattern) {
          flags.set(16);
        } else {
          flags.unset(16);
        }
        if (this.emailUnconfirmedPattern)
          _data.push(this.emailUnconfirmedPattern);

        _data.push(this.newAlgo);
        _data.push(this.newSecureAlgo);
        _data.push(this.secureRandom);
        if (this.pendingResetDate) {
          flags.set(32);
        } else {
          flags.unset(32);
        }
        if (this.pendingResetDate) _data.push(this.pendingResetDate);

        if (this.loginEmailPattern) {
          flags.set(64);
        } else {
          flags.unset(64);
        }
        if (this.loginEmailPattern) _data.push(this.loginEmailPattern);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hasRecovery: boolean | undefined,
        readonly hasSecureValues: boolean | undefined,
        readonly hasPassword: boolean | undefined,
        readonly currentAlgo: PasswordKdfAlgoType | undefined,
        readonly srpB: TLBytes | undefined,
        readonly srpId: TLLong | undefined,
        readonly hint: TLString | undefined,
        readonly emailUnconfirmedPattern: TLString | undefined,
        readonly newAlgo: PasswordKdfAlgoType,
        readonly newSecureAlgo: SecurePasswordKdfAlgoType,
        readonly secureRandom: TLBytes,
        readonly pendingResetDate: TLInt | undefined,
        readonly loginEmailPattern: TLString | undefined
      ) {}
    }
  }

  export namespace account {
    export class PasswordSettings implements TLObject {
      static readonly cons = new TLInt(0x9a5c33e5);

      static deserialized(_data: ByteStream): PasswordSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PasswordSettings.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let email: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          email = obj;
        }
        let secureSettings: API.SecureSecretSettings | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as API.SecureSecretSettings;
          if (!obj) return undefined;
          secureSettings = obj;
        }

        return new PasswordSettings(email, secureSettings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PasswordSettings.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.email) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.email) _data.push(this.email);

        if (this.secureSettings) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.secureSettings) _data.push(this.secureSettings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly email: TLString | undefined,
        readonly secureSettings: API.SecureSecretSettings | undefined
      ) {}
    }
  }

  export namespace account {
    export class PasswordInputSettings implements TLObject {
      static readonly cons = new TLInt(0xc23727c9);

      static deserialized(
        _data: ByteStream
      ): PasswordInputSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PasswordInputSettings.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let newAlgo: PasswordKdfAlgoType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as PasswordKdfAlgoType;
          if (!obj) return undefined;
          newAlgo = obj;
        }
        let newPasswordHash: TLBytes | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLBytes.deserialized(_data);
          if (!obj) return undefined;
          newPasswordHash = obj;
        }
        let hint: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          hint = obj;
        }
        let email: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          email = obj;
        }
        let newSecureSettings: API.SecureSecretSettings | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as API.SecureSecretSettings;
          if (!obj) return undefined;
          newSecureSettings = obj;
        }

        return new PasswordInputSettings(
          newAlgo,
          newPasswordHash,
          hint,
          email,
          newSecureSettings
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PasswordInputSettings.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.newAlgo) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.newAlgo) _data.push(this.newAlgo);

        if (this.newPasswordHash) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.newPasswordHash) _data.push(this.newPasswordHash);

        if (this.hint) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.hint) _data.push(this.hint);

        if (this.email) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.email) _data.push(this.email);

        if (this.newSecureSettings) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.newSecureSettings) _data.push(this.newSecureSettings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly newAlgo: PasswordKdfAlgoType | undefined,
        readonly newPasswordHash: TLBytes | undefined,
        readonly hint: TLString | undefined,
        readonly email: TLString | undefined,
        readonly newSecureSettings: API.SecureSecretSettings | undefined
      ) {}
    }
  }

  export namespace auth {
    export class PasswordRecovery implements TLObject {
      static readonly cons = new TLInt(0x137948a5);

      static deserialized(_data: ByteStream): PasswordRecovery | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PasswordRecovery.cons))
          return undefined;

        const emailPattern = TLString.deserialized(_data);
        if (!emailPattern) return undefined;

        return new PasswordRecovery(emailPattern);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PasswordRecovery.cons];

        _data.push(this.emailPattern);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly emailPattern: TLString) {}
    }
  }

  export class ReceivedNotifyMessage implements TLObject {
    static readonly cons = new TLInt(0xa384b779);

    static deserialized(_data: ByteStream): ReceivedNotifyMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ReceivedNotifyMessage.cons))
        return undefined;

      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;

      return new ReceivedNotifyMessage(id, flags);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ReceivedNotifyMessage.cons];

      _data.push(this.id);
      _data.push(this.flags);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLInt, readonly flags: TLInt) {}
  }

  export class ChatInviteExported implements TLObject {
    static readonly cons = new TLInt(0xab4a819);

    static deserialized(_data: ByteStream): ChatInviteExported | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatInviteExported.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const revoked = (flags.value & 1) !== 0;
      const permanent = (flags.value & 32) !== 0;
      const requestNeeded = (flags.value & 64) !== 0;
      const link = TLString.deserialized(_data);
      if (!link) return undefined;
      const adminId = TLLong.deserialized(_data);
      if (!adminId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      let startDate: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        startDate = obj;
      }
      let expireDate: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        expireDate = obj;
      }
      let usageLimit: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        usageLimit = obj;
      }
      let usage: TLInt | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        usage = obj;
      }
      let requested: TLInt | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        requested = obj;
      }
      let title: TLString | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        title = obj;
      }

      return new ChatInviteExported(
        revoked,
        permanent,
        requestNeeded,
        link,
        adminId,
        date,
        startDate,
        expireDate,
        usageLimit,
        usage,
        requested,
        title
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatInviteExported.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.revoked) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.permanent) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.requestNeeded) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      _data.push(this.link);
      _data.push(this.adminId);
      _data.push(this.date);
      if (this.startDate) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.startDate) _data.push(this.startDate);

      if (this.expireDate) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.expireDate) _data.push(this.expireDate);

      if (this.usageLimit) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.usageLimit) _data.push(this.usageLimit);

      if (this.usage) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.usage) _data.push(this.usage);

      if (this.requested) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.requested) _data.push(this.requested);

      if (this.title) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.title) _data.push(this.title);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly revoked: boolean | undefined,
      readonly permanent: boolean | undefined,
      readonly requestNeeded: boolean | undefined,
      readonly link: TLString,
      readonly adminId: TLLong,
      readonly date: TLInt,
      readonly startDate: TLInt | undefined,
      readonly expireDate: TLInt | undefined,
      readonly usageLimit: TLInt | undefined,
      readonly usage: TLInt | undefined,
      readonly requested: TLInt | undefined,
      readonly title: TLString | undefined
    ) {}
  }

  export class ChatInvitePublicJoinRequests implements TLObject {
    static readonly cons = new TLInt(0xed107ab7);

    static deserialized(
      _data: ByteStream
    ): ChatInvitePublicJoinRequests | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChatInvitePublicJoinRequests.cons)
      )
        return undefined;

      return new ChatInvitePublicJoinRequests();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatInvitePublicJoinRequests.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChatInviteAlready implements TLObject {
    static readonly cons = new TLInt(0x5a686d7c);

    static deserialized(_data: ByteStream): ChatInviteAlready | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatInviteAlready.cons))
        return undefined;

      const chat = TLObject.deserialized(_data) as ChatType;
      if (!chat) return undefined;

      return new ChatInviteAlready(chat);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatInviteAlready.cons];

      _data.push(this.chat);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chat: ChatType) {}
  }

  export class ChatInvite implements TLObject {
    static readonly cons = new TLInt(0x300c44c1);

    static deserialized(_data: ByteStream): ChatInvite | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatInvite.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const channel = (flags.value & 1) !== 0;
      const broadcast = (flags.value & 2) !== 0;
      const argPublic = (flags.value & 4) !== 0;
      const megagroup = (flags.value & 8) !== 0;
      const requestNeeded = (flags.value & 64) !== 0;
      const verified = (flags.value & 128) !== 0;
      const scam = (flags.value & 256) !== 0;
      const fake = (flags.value & 512) !== 0;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      let about: TLString | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        about = obj;
      }
      const photo = TLObject.deserialized(_data) as PhotoType;
      if (!photo) return undefined;
      const participantsCount = TLInt.deserialized(_data);
      if (!participantsCount) return undefined;
      let participants: TLVector<UserType> | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!obj) return undefined;
        participants = obj;
      }

      return new ChatInvite(
        channel,
        broadcast,
        argPublic,
        megagroup,
        requestNeeded,
        verified,
        scam,
        fake,
        title,
        about,
        photo,
        participantsCount,
        participants
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatInvite.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.channel) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.broadcast) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.argPublic) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.megagroup) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.requestNeeded) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      if (this.verified) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.scam) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      if (this.fake) {
        flags.set(512);
      } else {
        flags.unset(512);
      }

      _data.push(this.title);
      if (this.about) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.about) _data.push(this.about);

      _data.push(this.photo);
      _data.push(this.participantsCount);
      if (this.participants) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.participants) _data.push(this.participants);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly channel: boolean | undefined,
      readonly broadcast: boolean | undefined,
      readonly argPublic: boolean | undefined,
      readonly megagroup: boolean | undefined,
      readonly requestNeeded: boolean | undefined,
      readonly verified: boolean | undefined,
      readonly scam: boolean | undefined,
      readonly fake: boolean | undefined,
      readonly title: TLString,
      readonly about: TLString | undefined,
      readonly photo: PhotoType,
      readonly participantsCount: TLInt,
      readonly participants: TLVector<UserType> | undefined
    ) {}
  }

  export class ChatInvitePeek implements TLObject {
    static readonly cons = new TLInt(0x61695cb0);

    static deserialized(_data: ByteStream): ChatInvitePeek | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatInvitePeek.cons))
        return undefined;

      const chat = TLObject.deserialized(_data) as ChatType;
      if (!chat) return undefined;
      const expires = TLInt.deserialized(_data);
      if (!expires) return undefined;

      return new ChatInvitePeek(chat, expires);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatInvitePeek.cons];

      _data.push(this.chat);
      _data.push(this.expires);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly chat: ChatType, readonly expires: TLInt) {}
  }

  export class InputStickerSetEmpty implements TLObject {
    static readonly cons = new TLInt(0xffb62b95);

    static deserialized(_data: ByteStream): InputStickerSetEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputStickerSetEmpty.cons))
        return undefined;

      return new InputStickerSetEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputStickerSetEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputStickerSetID implements TLObject {
    static readonly cons = new TLInt(0x9de7a269);

    static deserialized(_data: ByteStream): InputStickerSetID | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputStickerSetID.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputStickerSetID(id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputStickerSetID.cons];

      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputStickerSetShortName implements TLObject {
    static readonly cons = new TLInt(0x861cc8a0);

    static deserialized(
      _data: ByteStream
    ): InputStickerSetShortName | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputStickerSetShortName.cons))
        return undefined;

      const shortName = TLString.deserialized(_data);
      if (!shortName) return undefined;

      return new InputStickerSetShortName(shortName);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputStickerSetShortName.cons];

      _data.push(this.shortName);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly shortName: TLString) {}
  }

  export class InputStickerSetAnimatedEmoji implements TLObject {
    static readonly cons = new TLInt(0x28703c8);

    static deserialized(
      _data: ByteStream
    ): InputStickerSetAnimatedEmoji | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputStickerSetAnimatedEmoji.cons)
      )
        return undefined;

      return new InputStickerSetAnimatedEmoji();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputStickerSetAnimatedEmoji.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputStickerSetDice implements TLObject {
    static readonly cons = new TLInt(0xe67f520e);

    static deserialized(_data: ByteStream): InputStickerSetDice | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputStickerSetDice.cons))
        return undefined;

      const emoticon = TLString.deserialized(_data);
      if (!emoticon) return undefined;

      return new InputStickerSetDice(emoticon);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputStickerSetDice.cons];

      _data.push(this.emoticon);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly emoticon: TLString) {}
  }

  export class InputStickerSetAnimatedEmojiAnimations implements TLObject {
    static readonly cons = new TLInt(0xcde3739);

    static deserialized(
      _data: ByteStream
    ): InputStickerSetAnimatedEmojiAnimations | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputStickerSetAnimatedEmojiAnimations.cons)
      )
        return undefined;

      return new InputStickerSetAnimatedEmojiAnimations();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputStickerSetAnimatedEmojiAnimations.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputStickerSetPremiumGifts implements TLObject {
    static readonly cons = new TLInt(0xc88b3b02);

    static deserialized(
      _data: ByteStream
    ): InputStickerSetPremiumGifts | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputStickerSetPremiumGifts.cons))
        return undefined;

      return new InputStickerSetPremiumGifts();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputStickerSetPremiumGifts.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputStickerSetEmojiGenericAnimations implements TLObject {
    static readonly cons = new TLInt(0x4c4d4ce);

    static deserialized(
      _data: ByteStream
    ): InputStickerSetEmojiGenericAnimations | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputStickerSetEmojiGenericAnimations.cons)
      )
        return undefined;

      return new InputStickerSetEmojiGenericAnimations();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputStickerSetEmojiGenericAnimations.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputStickerSetEmojiDefaultStatuses implements TLObject {
    static readonly cons = new TLInt(0x29d0f5ee);

    static deserialized(
      _data: ByteStream
    ): InputStickerSetEmojiDefaultStatuses | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputStickerSetEmojiDefaultStatuses.cons)
      )
        return undefined;

      return new InputStickerSetEmojiDefaultStatuses();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputStickerSetEmojiDefaultStatuses.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputStickerSetEmojiDefaultTopicIcons implements TLObject {
    static readonly cons = new TLInt(0x44c1f8e9);

    static deserialized(
      _data: ByteStream
    ): InputStickerSetEmojiDefaultTopicIcons | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputStickerSetEmojiDefaultTopicIcons.cons)
      )
        return undefined;

      return new InputStickerSetEmojiDefaultTopicIcons();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputStickerSetEmojiDefaultTopicIcons.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class StickerSet implements TLObject {
    static readonly cons = new TLInt(0x2dd14edc);

    static deserialized(_data: ByteStream): StickerSet | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StickerSet.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const archived = (flags.value & 2) !== 0;
      const official = (flags.value & 4) !== 0;
      const masks = (flags.value & 8) !== 0;
      const animated = (flags.value & 32) !== 0;
      const videos = (flags.value & 64) !== 0;
      const emojis = (flags.value & 128) !== 0;
      let installedDate: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        installedDate = obj;
      }
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const shortName = TLString.deserialized(_data);
      if (!shortName) return undefined;
      let thumbs: TLVector<PhotoSizeType> | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<PhotoSizeType>;
        if (!obj) return undefined;
        thumbs = obj;
      }
      let thumbDcId: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        thumbDcId = obj;
      }
      let thumbVersion: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        thumbVersion = obj;
      }
      let thumbDocumentId: TLLong | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        thumbDocumentId = obj;
      }
      const count = TLInt.deserialized(_data);
      if (!count) return undefined;
      const hash = TLInt.deserialized(_data);
      if (!hash) return undefined;

      return new StickerSet(
        archived,
        official,
        masks,
        animated,
        videos,
        emojis,
        installedDate,
        id,
        accessHash,
        title,
        shortName,
        thumbs,
        thumbDcId,
        thumbVersion,
        thumbDocumentId,
        count,
        hash
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StickerSet.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.archived) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.official) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.masks) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.animated) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.videos) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      if (this.emojis) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.installedDate) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.installedDate) _data.push(this.installedDate);

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.title);
      _data.push(this.shortName);
      if (this.thumbs) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.thumbs) _data.push(this.thumbs);

      if (this.thumbDcId) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.thumbDcId) _data.push(this.thumbDcId);

      if (this.thumbVersion) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.thumbVersion) _data.push(this.thumbVersion);

      if (this.thumbDocumentId) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.thumbDocumentId) _data.push(this.thumbDocumentId);

      _data.push(this.count);
      _data.push(this.hash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly archived: boolean | undefined,
      readonly official: boolean | undefined,
      readonly masks: boolean | undefined,
      readonly animated: boolean | undefined,
      readonly videos: boolean | undefined,
      readonly emojis: boolean | undefined,
      readonly installedDate: TLInt | undefined,
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly title: TLString,
      readonly shortName: TLString,
      readonly thumbs: TLVector<PhotoSizeType> | undefined,
      readonly thumbDcId: TLInt | undefined,
      readonly thumbVersion: TLInt | undefined,
      readonly thumbDocumentId: TLLong | undefined,
      readonly count: TLInt,
      readonly hash: TLInt
    ) {}
  }

  export namespace messages {
    export class StickerSet implements TLObject {
      static readonly cons = new TLInt(0x6e153f16);

      static deserialized(_data: ByteStream): StickerSet | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(StickerSet.cons))
          return undefined;

        const set = API.StickerSet.deserialized(_data);
        if (!set) return undefined;
        const packs = TLVector.deserialized(_data, API.StickerPack);
        if (!packs) return undefined;
        const keywords = TLVector.deserialized(_data, API.StickerKeyword);
        if (!keywords) return undefined;
        const documents = TLVector.deserialized(
          _data
        ) as TLVector<DocumentType>;
        if (!documents) return undefined;

        return new StickerSet(set, packs, keywords, documents);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [StickerSet.cons];

        _data.push(this.set);
        _data.push(this.packs);
        _data.push(this.keywords);
        _data.push(this.documents);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly set: API.StickerSet,
        readonly packs: TLVector<API.StickerPack>,
        readonly keywords: TLVector<API.StickerKeyword>,
        readonly documents: TLVector<DocumentType>
      ) {}
    }
  }

  export namespace messages {
    export class StickerSetNotModified implements TLObject {
      static readonly cons = new TLInt(0xd3f924eb);

      static deserialized(
        _data: ByteStream
      ): StickerSetNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(StickerSetNotModified.cons))
          return undefined;

        return new StickerSetNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [StickerSetNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export class BotCommand implements TLObject {
    static readonly cons = new TLInt(0xc27ac8c7);

    static deserialized(_data: ByteStream): BotCommand | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotCommand.cons))
        return undefined;

      const command = TLString.deserialized(_data);
      if (!command) return undefined;
      const description = TLString.deserialized(_data);
      if (!description) return undefined;

      return new BotCommand(command, description);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotCommand.cons];

      _data.push(this.command);
      _data.push(this.description);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly command: TLString, readonly description: TLString) {}
  }

  export class BotInfo implements TLObject {
    static readonly cons = new TLInt(0x8f300b57);

    static deserialized(_data: ByteStream): BotInfo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotInfo.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let userId: TLLong | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        userId = obj;
      }
      let description: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        description = obj;
      }
      let descriptionPhoto: PhotoType | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLObject.deserialized(_data) as PhotoType;
        if (!obj) return undefined;
        descriptionPhoto = obj;
      }
      let descriptionDocument: DocumentType | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLObject.deserialized(_data) as DocumentType;
        if (!obj) return undefined;
        descriptionDocument = obj;
      }
      let commands: TLVector<API.BotCommand> | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLVector.deserialized(_data, API.BotCommand);
        if (!obj) return undefined;
        commands = obj;
      }
      let menuButton: BotMenuButtonType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as BotMenuButtonType;
        if (!obj) return undefined;
        menuButton = obj;
      }

      return new BotInfo(
        userId,
        description,
        descriptionPhoto,
        descriptionDocument,
        commands,
        menuButton
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotInfo.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.userId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.userId) _data.push(this.userId);

      if (this.description) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.description) _data.push(this.description);

      if (this.descriptionPhoto) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.descriptionPhoto) _data.push(this.descriptionPhoto);

      if (this.descriptionDocument) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.descriptionDocument) _data.push(this.descriptionDocument);

      if (this.commands) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.commands) _data.push(this.commands);

      if (this.menuButton) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.menuButton) _data.push(this.menuButton);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: TLLong | undefined,
      readonly description: TLString | undefined,
      readonly descriptionPhoto: PhotoType | undefined,
      readonly descriptionDocument: DocumentType | undefined,
      readonly commands: TLVector<API.BotCommand> | undefined,
      readonly menuButton: BotMenuButtonType | undefined
    ) {}
  }

  export class KeyboardButton implements TLObject {
    static readonly cons = new TLInt(0xa2fa4880);

    static deserialized(_data: ByteStream): KeyboardButton | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButton.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new KeyboardButton(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButton.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString) {}
  }

  export class KeyboardButtonUrl implements TLObject {
    static readonly cons = new TLInt(0x258aff05);

    static deserialized(_data: ByteStream): KeyboardButtonUrl | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonUrl.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new KeyboardButtonUrl(text, url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonUrl.cons];

      _data.push(this.text);
      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString, readonly url: TLString) {}
  }

  export class KeyboardButtonCallback implements TLObject {
    static readonly cons = new TLInt(0x35bbdb6b);

    static deserialized(_data: ByteStream): KeyboardButtonCallback | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonCallback.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const requiresPassword = (flags.value & 1) !== 0;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const data = TLBytes.deserialized(_data);
      if (!data) return undefined;

      return new KeyboardButtonCallback(requiresPassword, text, data);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonCallback.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.requiresPassword) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.text);
      _data.push(this.data);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly requiresPassword: boolean | undefined,
      readonly text: TLString,
      readonly data: TLBytes
    ) {}
  }

  export class KeyboardButtonRequestPhone implements TLObject {
    static readonly cons = new TLInt(0xb16a6c29);

    static deserialized(
      _data: ByteStream
    ): KeyboardButtonRequestPhone | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonRequestPhone.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new KeyboardButtonRequestPhone(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonRequestPhone.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString) {}
  }

  export class KeyboardButtonRequestGeoLocation implements TLObject {
    static readonly cons = new TLInt(0xfc796b3f);

    static deserialized(
      _data: ByteStream
    ): KeyboardButtonRequestGeoLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(KeyboardButtonRequestGeoLocation.cons)
      )
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new KeyboardButtonRequestGeoLocation(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        KeyboardButtonRequestGeoLocation.cons,
      ];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString) {}
  }

  export class KeyboardButtonSwitchInline implements TLObject {
    static readonly cons = new TLInt(0x93b9fbb5);

    static deserialized(
      _data: ByteStream
    ): KeyboardButtonSwitchInline | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonSwitchInline.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const samePeer = (flags.value & 1) !== 0;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const query = TLString.deserialized(_data);
      if (!query) return undefined;
      let peerTypes: TLVector<InlineQueryPeerTypeType> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(
          _data
        ) as TLVector<InlineQueryPeerTypeType>;
        if (!obj) return undefined;
        peerTypes = obj;
      }

      return new KeyboardButtonSwitchInline(samePeer, text, query, peerTypes);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonSwitchInline.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.samePeer) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.text);
      _data.push(this.query);
      if (this.peerTypes) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.peerTypes) _data.push(this.peerTypes);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly samePeer: boolean | undefined,
      readonly text: TLString,
      readonly query: TLString,
      readonly peerTypes: TLVector<InlineQueryPeerTypeType> | undefined
    ) {}
  }

  export class KeyboardButtonGame implements TLObject {
    static readonly cons = new TLInt(0x50f41ccf);

    static deserialized(_data: ByteStream): KeyboardButtonGame | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonGame.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new KeyboardButtonGame(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonGame.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString) {}
  }

  export class KeyboardButtonBuy implements TLObject {
    static readonly cons = new TLInt(0xafd93fbb);

    static deserialized(_data: ByteStream): KeyboardButtonBuy | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonBuy.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new KeyboardButtonBuy(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonBuy.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString) {}
  }

  export class KeyboardButtonUrlAuth implements TLObject {
    static readonly cons = new TLInt(0x10b78d29);

    static deserialized(_data: ByteStream): KeyboardButtonUrlAuth | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonUrlAuth.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      let fwdText: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        fwdText = obj;
      }
      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const buttonId = TLInt.deserialized(_data);
      if (!buttonId) return undefined;

      return new KeyboardButtonUrlAuth(text, fwdText, url, buttonId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonUrlAuth.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.text);
      if (this.fwdText) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.fwdText) _data.push(this.fwdText);

      _data.push(this.url);
      _data.push(this.buttonId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly text: TLString,
      readonly fwdText: TLString | undefined,
      readonly url: TLString,
      readonly buttonId: TLInt
    ) {}
  }

  export class InputKeyboardButtonUrlAuth implements TLObject {
    static readonly cons = new TLInt(0xd02e7fd4);

    static deserialized(
      _data: ByteStream
    ): InputKeyboardButtonUrlAuth | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputKeyboardButtonUrlAuth.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const requestWriteAccess = (flags.value & 1) !== 0;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      let fwdText: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        fwdText = obj;
      }
      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const bot = TLObject.deserialized(_data) as InputUserType;
      if (!bot) return undefined;

      return new InputKeyboardButtonUrlAuth(
        requestWriteAccess,
        text,
        fwdText,
        url,
        bot
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputKeyboardButtonUrlAuth.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.requestWriteAccess) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.text);
      if (this.fwdText) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.fwdText) _data.push(this.fwdText);

      _data.push(this.url);
      _data.push(this.bot);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly requestWriteAccess: boolean | undefined,
      readonly text: TLString,
      readonly fwdText: TLString | undefined,
      readonly url: TLString,
      readonly bot: InputUserType
    ) {}
  }

  export class KeyboardButtonRequestPoll implements TLObject {
    static readonly cons = new TLInt(0xbbc7515d);

    static deserialized(
      _data: ByteStream
    ): KeyboardButtonRequestPoll | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonRequestPoll.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let quiz: BoolType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        quiz = obj;
      }
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new KeyboardButtonRequestPoll(quiz, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonRequestPoll.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.quiz) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.quiz) _data.push(this.quiz);

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly quiz: BoolType | undefined, readonly text: TLString) {}
  }

  export class InputKeyboardButtonUserProfile implements TLObject {
    static readonly cons = new TLInt(0xe988037b);

    static deserialized(
      _data: ByteStream
    ): InputKeyboardButtonUserProfile | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputKeyboardButtonUserProfile.cons)
      )
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const userId = TLObject.deserialized(_data) as InputUserType;
      if (!userId) return undefined;

      return new InputKeyboardButtonUserProfile(text, userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputKeyboardButtonUserProfile.cons,
      ];

      _data.push(this.text);
      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString, readonly userId: InputUserType) {}
  }

  export class KeyboardButtonUserProfile implements TLObject {
    static readonly cons = new TLInt(0x308660c1);

    static deserialized(
      _data: ByteStream
    ): KeyboardButtonUserProfile | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonUserProfile.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;

      return new KeyboardButtonUserProfile(text, userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonUserProfile.cons];

      _data.push(this.text);
      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString, readonly userId: TLLong) {}
  }

  export class KeyboardButtonWebView implements TLObject {
    static readonly cons = new TLInt(0x13767230);

    static deserialized(_data: ByteStream): KeyboardButtonWebView | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonWebView.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new KeyboardButtonWebView(text, url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonWebView.cons];

      _data.push(this.text);
      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString, readonly url: TLString) {}
  }

  export class KeyboardButtonSimpleWebView implements TLObject {
    static readonly cons = new TLInt(0xa0c0505c);

    static deserialized(
      _data: ByteStream
    ): KeyboardButtonSimpleWebView | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonSimpleWebView.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new KeyboardButtonSimpleWebView(text, url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonSimpleWebView.cons];

      _data.push(this.text);
      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString, readonly url: TLString) {}
  }

  export class KeyboardButtonRequestPeer implements TLObject {
    static readonly cons = new TLInt(0xd0b468c);

    static deserialized(
      _data: ByteStream
    ): KeyboardButtonRequestPeer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonRequestPeer.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const buttonId = TLInt.deserialized(_data);
      if (!buttonId) return undefined;
      const peerType = TLObject.deserialized(_data) as RequestPeerTypeType;
      if (!peerType) return undefined;

      return new KeyboardButtonRequestPeer(text, buttonId, peerType);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonRequestPeer.cons];

      _data.push(this.text);
      _data.push(this.buttonId);
      _data.push(this.peerType);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly text: TLString,
      readonly buttonId: TLInt,
      readonly peerType: RequestPeerTypeType
    ) {}
  }

  export class KeyboardButtonRow implements TLObject {
    static readonly cons = new TLInt(0x77608b83);

    static deserialized(_data: ByteStream): KeyboardButtonRow | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(KeyboardButtonRow.cons))
        return undefined;

      const buttons = TLVector.deserialized(
        _data
      ) as TLVector<KeyboardButtonType>;
      if (!buttons) return undefined;

      return new KeyboardButtonRow(buttons);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [KeyboardButtonRow.cons];

      _data.push(this.buttons);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly buttons: TLVector<KeyboardButtonType>) {}
  }

  export class ReplyKeyboardHide implements TLObject {
    static readonly cons = new TLInt(0xa03e5b85);

    static deserialized(_data: ByteStream): ReplyKeyboardHide | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ReplyKeyboardHide.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const selective = (flags.value & 4) !== 0;

      return new ReplyKeyboardHide(selective);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ReplyKeyboardHide.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.selective) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly selective: boolean | undefined) {}
  }

  export class ReplyKeyboardForceReply implements TLObject {
    static readonly cons = new TLInt(0x86b40b08);

    static deserialized(
      _data: ByteStream
    ): ReplyKeyboardForceReply | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ReplyKeyboardForceReply.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const singleUse = (flags.value & 2) !== 0;
      const selective = (flags.value & 4) !== 0;
      let placeholder: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        placeholder = obj;
      }

      return new ReplyKeyboardForceReply(singleUse, selective, placeholder);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ReplyKeyboardForceReply.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.singleUse) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.selective) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.placeholder) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.placeholder) _data.push(this.placeholder);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly singleUse: boolean | undefined,
      readonly selective: boolean | undefined,
      readonly placeholder: TLString | undefined
    ) {}
  }

  export class ReplyKeyboardMarkup implements TLObject {
    static readonly cons = new TLInt(0x85dd99d1);

    static deserialized(_data: ByteStream): ReplyKeyboardMarkup | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ReplyKeyboardMarkup.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const resize = (flags.value & 1) !== 0;
      const singleUse = (flags.value & 2) !== 0;
      const selective = (flags.value & 4) !== 0;
      const persistent = (flags.value & 16) !== 0;
      const rows = TLVector.deserialized(_data, API.KeyboardButtonRow);
      if (!rows) return undefined;
      let placeholder: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        placeholder = obj;
      }

      return new ReplyKeyboardMarkup(
        resize,
        singleUse,
        selective,
        persistent,
        rows,
        placeholder
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ReplyKeyboardMarkup.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.resize) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.singleUse) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.selective) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.persistent) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      _data.push(this.rows);
      if (this.placeholder) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.placeholder) _data.push(this.placeholder);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly resize: boolean | undefined,
      readonly singleUse: boolean | undefined,
      readonly selective: boolean | undefined,
      readonly persistent: boolean | undefined,
      readonly rows: TLVector<API.KeyboardButtonRow>,
      readonly placeholder: TLString | undefined
    ) {}
  }

  export class ReplyInlineMarkup implements TLObject {
    static readonly cons = new TLInt(0x48a30254);

    static deserialized(_data: ByteStream): ReplyInlineMarkup | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ReplyInlineMarkup.cons))
        return undefined;

      const rows = TLVector.deserialized(_data, API.KeyboardButtonRow);
      if (!rows) return undefined;

      return new ReplyInlineMarkup(rows);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ReplyInlineMarkup.cons];

      _data.push(this.rows);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly rows: TLVector<API.KeyboardButtonRow>) {}
  }

  export class MessageEntityUnknown implements TLObject {
    static readonly cons = new TLInt(0xbb92ba95);

    static deserialized(_data: ByteStream): MessageEntityUnknown | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityUnknown.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityUnknown(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityUnknown.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityMention implements TLObject {
    static readonly cons = new TLInt(0xfa04579d);

    static deserialized(_data: ByteStream): MessageEntityMention | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityMention.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityMention(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityMention.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityHashtag implements TLObject {
    static readonly cons = new TLInt(0x6f635b0d);

    static deserialized(_data: ByteStream): MessageEntityHashtag | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityHashtag.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityHashtag(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityHashtag.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityBotCommand implements TLObject {
    static readonly cons = new TLInt(0x6cef8ac7);

    static deserialized(
      _data: ByteStream
    ): MessageEntityBotCommand | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityBotCommand.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityBotCommand(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityBotCommand.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityUrl implements TLObject {
    static readonly cons = new TLInt(0x6ed02538);

    static deserialized(_data: ByteStream): MessageEntityUrl | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityUrl.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityUrl(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityUrl.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityEmail implements TLObject {
    static readonly cons = new TLInt(0x64e475c2);

    static deserialized(_data: ByteStream): MessageEntityEmail | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityEmail.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityEmail(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityEmail.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityBold implements TLObject {
    static readonly cons = new TLInt(0xbd610bc9);

    static deserialized(_data: ByteStream): MessageEntityBold | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityBold.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityBold(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityBold.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityItalic implements TLObject {
    static readonly cons = new TLInt(0x826f8b60);

    static deserialized(_data: ByteStream): MessageEntityItalic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityItalic.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityItalic(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityItalic.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityCode implements TLObject {
    static readonly cons = new TLInt(0x28a20571);

    static deserialized(_data: ByteStream): MessageEntityCode | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityCode.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityCode(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityCode.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityPre implements TLObject {
    static readonly cons = new TLInt(0x73924be0);

    static deserialized(_data: ByteStream): MessageEntityPre | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityPre.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;
      const language = TLString.deserialized(_data);
      if (!language) return undefined;

      return new MessageEntityPre(offset, length, language);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityPre.cons];

      _data.push(this.offset);
      _data.push(this.length);
      _data.push(this.language);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly offset: TLInt,
      readonly length: TLInt,
      readonly language: TLString
    ) {}
  }

  export class MessageEntityTextUrl implements TLObject {
    static readonly cons = new TLInt(0x76a6d327);

    static deserialized(_data: ByteStream): MessageEntityTextUrl | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityTextUrl.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new MessageEntityTextUrl(offset, length, url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityTextUrl.cons];

      _data.push(this.offset);
      _data.push(this.length);
      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly offset: TLInt,
      readonly length: TLInt,
      readonly url: TLString
    ) {}
  }

  export class MessageEntityMentionName implements TLObject {
    static readonly cons = new TLInt(0xdc7b1140);

    static deserialized(
      _data: ByteStream
    ): MessageEntityMentionName | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityMentionName.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;

      return new MessageEntityMentionName(offset, length, userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityMentionName.cons];

      _data.push(this.offset);
      _data.push(this.length);
      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly offset: TLInt,
      readonly length: TLInt,
      readonly userId: TLLong
    ) {}
  }

  export class InputMessageEntityMentionName implements TLObject {
    static readonly cons = new TLInt(0x208e68c9);

    static deserialized(
      _data: ByteStream
    ): InputMessageEntityMentionName | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputMessageEntityMentionName.cons)
      )
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;
      const userId = TLObject.deserialized(_data) as InputUserType;
      if (!userId) return undefined;

      return new InputMessageEntityMentionName(offset, length, userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessageEntityMentionName.cons];

      _data.push(this.offset);
      _data.push(this.length);
      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly offset: TLInt,
      readonly length: TLInt,
      readonly userId: InputUserType
    ) {}
  }

  export class MessageEntityPhone implements TLObject {
    static readonly cons = new TLInt(0x9b69e34b);

    static deserialized(_data: ByteStream): MessageEntityPhone | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityPhone.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityPhone(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityPhone.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityCashtag implements TLObject {
    static readonly cons = new TLInt(0x4c4e743f);

    static deserialized(_data: ByteStream): MessageEntityCashtag | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityCashtag.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityCashtag(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityCashtag.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityUnderline implements TLObject {
    static readonly cons = new TLInt(0x9c4e7e8b);

    static deserialized(_data: ByteStream): MessageEntityUnderline | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityUnderline.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityUnderline(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityUnderline.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityStrike implements TLObject {
    static readonly cons = new TLInt(0xbf0693d4);

    static deserialized(_data: ByteStream): MessageEntityStrike | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityStrike.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityStrike(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityStrike.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityBlockquote implements TLObject {
    static readonly cons = new TLInt(0x20df5d0);

    static deserialized(
      _data: ByteStream
    ): MessageEntityBlockquote | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityBlockquote.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityBlockquote(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityBlockquote.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityBankCard implements TLObject {
    static readonly cons = new TLInt(0x761e6af4);

    static deserialized(_data: ByteStream): MessageEntityBankCard | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityBankCard.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntityBankCard(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityBankCard.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntitySpoiler implements TLObject {
    static readonly cons = new TLInt(0x32ca960f);

    static deserialized(_data: ByteStream): MessageEntitySpoiler | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntitySpoiler.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;

      return new MessageEntitySpoiler(offset, length);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntitySpoiler.cons];

      _data.push(this.offset);
      _data.push(this.length);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly offset: TLInt, readonly length: TLInt) {}
  }

  export class MessageEntityCustomEmoji implements TLObject {
    static readonly cons = new TLInt(0xc8cf05f8);

    static deserialized(
      _data: ByteStream
    ): MessageEntityCustomEmoji | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageEntityCustomEmoji.cons))
        return undefined;

      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;
      const length = TLInt.deserialized(_data);
      if (!length) return undefined;
      const documentId = TLLong.deserialized(_data);
      if (!documentId) return undefined;

      return new MessageEntityCustomEmoji(offset, length, documentId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageEntityCustomEmoji.cons];

      _data.push(this.offset);
      _data.push(this.length);
      _data.push(this.documentId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly offset: TLInt,
      readonly length: TLInt,
      readonly documentId: TLLong
    ) {}
  }

  export class InputChannelEmpty implements TLObject {
    static readonly cons = new TLInt(0xee8c1e86);

    static deserialized(_data: ByteStream): InputChannelEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputChannelEmpty.cons))
        return undefined;

      return new InputChannelEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputChannelEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputChannel implements TLObject {
    static readonly cons = new TLInt(0xf35aec28);

    static deserialized(_data: ByteStream): InputChannel | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputChannel.cons))
        return undefined;

      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputChannel(channelId, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputChannel.cons];

      _data.push(this.channelId);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly channelId: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputChannelFromMessage implements TLObject {
    static readonly cons = new TLInt(0x5b934f9d);

    static deserialized(
      _data: ByteStream
    ): InputChannelFromMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputChannelFromMessage.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;
      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;
      const channelId = TLLong.deserialized(_data);
      if (!channelId) return undefined;

      return new InputChannelFromMessage(peer, msgId, channelId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputChannelFromMessage.cons];

      _data.push(this.peer);
      _data.push(this.msgId);
      _data.push(this.channelId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: InputPeerType,
      readonly msgId: TLInt,
      readonly channelId: TLLong
    ) {}
  }

  export namespace contacts {
    export class ResolvedPeer implements TLObject {
      static readonly cons = new TLInt(0x7f077ad9);

      static deserialized(_data: ByteStream): ResolvedPeer | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResolvedPeer.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as PeerType;
        if (!peer) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ResolvedPeer(peer, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResolvedPeer.cons];

        _data.push(this.peer);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: PeerType,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class MessageRange implements TLObject {
    static readonly cons = new TLInt(0xae30253);

    static deserialized(_data: ByteStream): MessageRange | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageRange.cons))
        return undefined;

      const minId = TLInt.deserialized(_data);
      if (!minId) return undefined;
      const maxId = TLInt.deserialized(_data);
      if (!maxId) return undefined;

      return new MessageRange(minId, maxId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageRange.cons];

      _data.push(this.minId);
      _data.push(this.maxId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly minId: TLInt, readonly maxId: TLInt) {}
  }

  export namespace updates {
    export class ChannelDifferenceEmpty implements TLObject {
      static readonly cons = new TLInt(0x3e11affb);

      static deserialized(
        _data: ByteStream
      ): ChannelDifferenceEmpty | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChannelDifferenceEmpty.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const final = (flags.value & 1) !== 0;
        const pts = TLInt.deserialized(_data);
        if (!pts) return undefined;
        let timeout: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          timeout = obj;
        }

        return new ChannelDifferenceEmpty(final, pts, timeout);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChannelDifferenceEmpty.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.final) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.pts);
        if (this.timeout) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.timeout) _data.push(this.timeout);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly final: boolean | undefined,
        readonly pts: TLInt,
        readonly timeout: TLInt | undefined
      ) {}
    }
  }

  export namespace updates {
    export class ChannelDifferenceTooLong implements TLObject {
      static readonly cons = new TLInt(0xa4bcc6fe);

      static deserialized(
        _data: ByteStream
      ): ChannelDifferenceTooLong | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChannelDifferenceTooLong.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const final = (flags.value & 1) !== 0;
        let timeout: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          timeout = obj;
        }
        const dialog = TLObject.deserialized(_data) as DialogType;
        if (!dialog) return undefined;
        const messages = TLVector.deserialized(_data) as TLVector<MessageType>;
        if (!messages) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ChannelDifferenceTooLong(
          final,
          timeout,
          dialog,
          messages,
          chats,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChannelDifferenceTooLong.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.final) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.timeout) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.timeout) _data.push(this.timeout);

        _data.push(this.dialog);
        _data.push(this.messages);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly final: boolean | undefined,
        readonly timeout: TLInt | undefined,
        readonly dialog: DialogType,
        readonly messages: TLVector<MessageType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace updates {
    export class ChannelDifference implements TLObject {
      static readonly cons = new TLInt(0x2064674e);

      static deserialized(_data: ByteStream): ChannelDifference | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChannelDifference.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const final = (flags.value & 1) !== 0;
        const pts = TLInt.deserialized(_data);
        if (!pts) return undefined;
        let timeout: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          timeout = obj;
        }
        const newMessages = TLVector.deserialized(
          _data
        ) as TLVector<MessageType>;
        if (!newMessages) return undefined;
        const otherUpdates = TLVector.deserialized(
          _data
        ) as TLVector<UpdateType>;
        if (!otherUpdates) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ChannelDifference(
          final,
          pts,
          timeout,
          newMessages,
          otherUpdates,
          chats,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChannelDifference.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.final) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.pts);
        if (this.timeout) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.timeout) _data.push(this.timeout);

        _data.push(this.newMessages);
        _data.push(this.otherUpdates);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly final: boolean | undefined,
        readonly pts: TLInt,
        readonly timeout: TLInt | undefined,
        readonly newMessages: TLVector<MessageType>,
        readonly otherUpdates: TLVector<UpdateType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class ChannelMessagesFilterEmpty implements TLObject {
    static readonly cons = new TLInt(0x94d42ee7);

    static deserialized(
      _data: ByteStream
    ): ChannelMessagesFilterEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelMessagesFilterEmpty.cons))
        return undefined;

      return new ChannelMessagesFilterEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelMessagesFilterEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChannelMessagesFilter implements TLObject {
    static readonly cons = new TLInt(0xcd77d957);

    static deserialized(_data: ByteStream): ChannelMessagesFilter | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelMessagesFilter.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const excludeNewMessages = (flags.value & 2) !== 0;
      const ranges = TLVector.deserialized(_data, API.MessageRange);
      if (!ranges) return undefined;

      return new ChannelMessagesFilter(excludeNewMessages, ranges);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelMessagesFilter.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.excludeNewMessages) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.ranges);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly excludeNewMessages: boolean | undefined,
      readonly ranges: TLVector<API.MessageRange>
    ) {}
  }

  export class ChannelParticipant implements TLObject {
    static readonly cons = new TLInt(0xc00c07c0);

    static deserialized(_data: ByteStream): ChannelParticipant | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipant.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new ChannelParticipant(userId, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipant.cons];

      _data.push(this.userId);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly date: TLInt) {}
  }

  export class ChannelParticipantSelf implements TLObject {
    static readonly cons = new TLInt(0x35a8bfa7);

    static deserialized(_data: ByteStream): ChannelParticipantSelf | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantSelf.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const viaRequest = (flags.value & 1) !== 0;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const inviterId = TLLong.deserialized(_data);
      if (!inviterId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new ChannelParticipantSelf(viaRequest, userId, inviterId, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantSelf.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.viaRequest) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.userId);
      _data.push(this.inviterId);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly viaRequest: boolean | undefined,
      readonly userId: TLLong,
      readonly inviterId: TLLong,
      readonly date: TLInt
    ) {}
  }

  export class ChannelParticipantCreator implements TLObject {
    static readonly cons = new TLInt(0x2fe601d3);

    static deserialized(
      _data: ByteStream
    ): ChannelParticipantCreator | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantCreator.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const adminRights = API.ChatAdminRights.deserialized(_data);
      if (!adminRights) return undefined;
      let rank: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        rank = obj;
      }

      return new ChannelParticipantCreator(userId, adminRights, rank);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantCreator.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.userId);
      _data.push(this.adminRights);
      if (this.rank) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.rank) _data.push(this.rank);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: TLLong,
      readonly adminRights: API.ChatAdminRights,
      readonly rank: TLString | undefined
    ) {}
  }

  export class ChannelParticipantAdmin implements TLObject {
    static readonly cons = new TLInt(0x34c3bb53);

    static deserialized(
      _data: ByteStream
    ): ChannelParticipantAdmin | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantAdmin.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const canEdit = (flags.value & 1) !== 0;
      const self = (flags.value & 2) !== 0;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      let inviterId: TLLong | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        inviterId = obj;
      }
      const promotedBy = TLLong.deserialized(_data);
      if (!promotedBy) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const adminRights = API.ChatAdminRights.deserialized(_data);
      if (!adminRights) return undefined;
      let rank: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        rank = obj;
      }

      return new ChannelParticipantAdmin(
        canEdit,
        self,
        userId,
        inviterId,
        promotedBy,
        date,
        adminRights,
        rank
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantAdmin.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.canEdit) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.self) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.userId);
      if (this.inviterId) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.inviterId) _data.push(this.inviterId);

      _data.push(this.promotedBy);
      _data.push(this.date);
      _data.push(this.adminRights);
      if (this.rank) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.rank) _data.push(this.rank);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly canEdit: boolean | undefined,
      readonly self: boolean | undefined,
      readonly userId: TLLong,
      readonly inviterId: TLLong | undefined,
      readonly promotedBy: TLLong,
      readonly date: TLInt,
      readonly adminRights: API.ChatAdminRights,
      readonly rank: TLString | undefined
    ) {}
  }

  export class ChannelParticipantBanned implements TLObject {
    static readonly cons = new TLInt(0x6df8014e);

    static deserialized(
      _data: ByteStream
    ): ChannelParticipantBanned | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantBanned.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const left = (flags.value & 1) !== 0;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const kickedBy = TLLong.deserialized(_data);
      if (!kickedBy) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const bannedRights = API.ChatBannedRights.deserialized(_data);
      if (!bannedRights) return undefined;

      return new ChannelParticipantBanned(
        left,
        peer,
        kickedBy,
        date,
        bannedRights
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantBanned.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.left) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.peer);
      _data.push(this.kickedBy);
      _data.push(this.date);
      _data.push(this.bannedRights);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly left: boolean | undefined,
      readonly peer: PeerType,
      readonly kickedBy: TLLong,
      readonly date: TLInt,
      readonly bannedRights: API.ChatBannedRights
    ) {}
  }

  export class ChannelParticipantLeft implements TLObject {
    static readonly cons = new TLInt(0x1b03f006);

    static deserialized(_data: ByteStream): ChannelParticipantLeft | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantLeft.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;

      return new ChannelParticipantLeft(peer);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantLeft.cons];

      _data.push(this.peer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType) {}
  }

  export class ChannelParticipantsRecent implements TLObject {
    static readonly cons = new TLInt(0xde3f3c79);

    static deserialized(
      _data: ByteStream
    ): ChannelParticipantsRecent | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantsRecent.cons))
        return undefined;

      return new ChannelParticipantsRecent();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantsRecent.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChannelParticipantsAdmins implements TLObject {
    static readonly cons = new TLInt(0xb4608969);

    static deserialized(
      _data: ByteStream
    ): ChannelParticipantsAdmins | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantsAdmins.cons))
        return undefined;

      return new ChannelParticipantsAdmins();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantsAdmins.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChannelParticipantsKicked implements TLObject {
    static readonly cons = new TLInt(0xa3b54985);

    static deserialized(
      _data: ByteStream
    ): ChannelParticipantsKicked | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantsKicked.cons))
        return undefined;

      const q = TLString.deserialized(_data);
      if (!q) return undefined;

      return new ChannelParticipantsKicked(q);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantsKicked.cons];

      _data.push(this.q);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly q: TLString) {}
  }

  export class ChannelParticipantsBots implements TLObject {
    static readonly cons = new TLInt(0xb0d1865b);

    static deserialized(
      _data: ByteStream
    ): ChannelParticipantsBots | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantsBots.cons))
        return undefined;

      return new ChannelParticipantsBots();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantsBots.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChannelParticipantsBanned implements TLObject {
    static readonly cons = new TLInt(0x1427a5e1);

    static deserialized(
      _data: ByteStream
    ): ChannelParticipantsBanned | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantsBanned.cons))
        return undefined;

      const q = TLString.deserialized(_data);
      if (!q) return undefined;

      return new ChannelParticipantsBanned(q);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantsBanned.cons];

      _data.push(this.q);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly q: TLString) {}
  }

  export class ChannelParticipantsSearch implements TLObject {
    static readonly cons = new TLInt(0x656ac4b);

    static deserialized(
      _data: ByteStream
    ): ChannelParticipantsSearch | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantsSearch.cons))
        return undefined;

      const q = TLString.deserialized(_data);
      if (!q) return undefined;

      return new ChannelParticipantsSearch(q);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantsSearch.cons];

      _data.push(this.q);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly q: TLString) {}
  }

  export class ChannelParticipantsContacts implements TLObject {
    static readonly cons = new TLInt(0xbb6ae88d);

    static deserialized(
      _data: ByteStream
    ): ChannelParticipantsContacts | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantsContacts.cons))
        return undefined;

      const q = TLString.deserialized(_data);
      if (!q) return undefined;

      return new ChannelParticipantsContacts(q);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantsContacts.cons];

      _data.push(this.q);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly q: TLString) {}
  }

  export class ChannelParticipantsMentions implements TLObject {
    static readonly cons = new TLInt(0xe04b5ceb);

    static deserialized(
      _data: ByteStream
    ): ChannelParticipantsMentions | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelParticipantsMentions.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let q: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        q = obj;
      }
      let topMsgId: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        topMsgId = obj;
      }

      return new ChannelParticipantsMentions(q, topMsgId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelParticipantsMentions.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.q) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.q) _data.push(this.q);

      if (this.topMsgId) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.topMsgId) _data.push(this.topMsgId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly q: TLString | undefined,
      readonly topMsgId: TLInt | undefined
    ) {}
  }

  export namespace channels {
    export class ChannelParticipants implements TLObject {
      static readonly cons = new TLInt(0x9ab0feaf);

      static deserialized(_data: ByteStream): ChannelParticipants | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChannelParticipants.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const participants = TLVector.deserialized(
          _data
        ) as TLVector<ChannelParticipantType>;
        if (!participants) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ChannelParticipants(count, participants, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChannelParticipants.cons];

        _data.push(this.count);
        _data.push(this.participants);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly participants: TLVector<ChannelParticipantType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace channels {
    export class ChannelParticipantsNotModified implements TLObject {
      static readonly cons = new TLInt(0xf0173fe9);

      static deserialized(
        _data: ByteStream
      ): ChannelParticipantsNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(ChannelParticipantsNotModified.cons)
        )
          return undefined;

        return new ChannelParticipantsNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          ChannelParticipantsNotModified.cons,
        ];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace channels {
    export class ChannelParticipant implements TLObject {
      static readonly cons = new TLInt(0xdfb80317);

      static deserialized(_data: ByteStream): ChannelParticipant | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChannelParticipant.cons))
          return undefined;

        const participant = TLObject.deserialized(
          _data
        ) as ChannelParticipantType;
        if (!participant) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ChannelParticipant(participant, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChannelParticipant.cons];

        _data.push(this.participant);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly participant: ChannelParticipantType,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace help {
    export class TermsOfService implements TLObject {
      static readonly cons = new TLInt(0x780a0310);

      static deserialized(_data: ByteStream): TermsOfService | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TermsOfService.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const popup = (flags.value & 1) !== 0;
        const id = API.DataJSON.deserialized(_data);
        if (!id) return undefined;
        const text = TLString.deserialized(_data);
        if (!text) return undefined;
        const entities = TLVector.deserialized(
          _data
        ) as TLVector<MessageEntityType>;
        if (!entities) return undefined;
        let minAgeConfirm: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          minAgeConfirm = obj;
        }

        return new TermsOfService(popup, id, text, entities, minAgeConfirm);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TermsOfService.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.popup) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.id);
        _data.push(this.text);
        _data.push(this.entities);
        if (this.minAgeConfirm) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.minAgeConfirm) _data.push(this.minAgeConfirm);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly popup: boolean | undefined,
        readonly id: API.DataJSON,
        readonly text: TLString,
        readonly entities: TLVector<MessageEntityType>,
        readonly minAgeConfirm: TLInt | undefined
      ) {}
    }
  }

  export namespace messages {
    export class SavedGifsNotModified implements TLObject {
      static readonly cons = new TLInt(0xe8025ca2);

      static deserialized(_data: ByteStream): SavedGifsNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SavedGifsNotModified.cons))
          return undefined;

        return new SavedGifsNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SavedGifsNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class SavedGifs implements TLObject {
      static readonly cons = new TLInt(0x84a02a0d);

      static deserialized(_data: ByteStream): SavedGifs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SavedGifs.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const gifs = TLVector.deserialized(_data) as TLVector<DocumentType>;
        if (!gifs) return undefined;

        return new SavedGifs(hash, gifs);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SavedGifs.cons];

        _data.push(this.hash);
        _data.push(this.gifs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLLong,
        readonly gifs: TLVector<DocumentType>
      ) {}
    }
  }

  export class InputBotInlineMessageMediaAuto implements TLObject {
    static readonly cons = new TLInt(0x3380c786);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineMessageMediaAuto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputBotInlineMessageMediaAuto.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const message = TLString.deserialized(_data);
      if (!message) return undefined;
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new InputBotInlineMessageMediaAuto(message, entities, replyMarkup);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputBotInlineMessageMediaAuto.cons,
      ];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.message);
      if (this.entities) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.entities) _data.push(this.entities);

      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly message: TLString,
      readonly entities: TLVector<MessageEntityType> | undefined,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class InputBotInlineMessageText implements TLObject {
    static readonly cons = new TLInt(0x3dcd7a87);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineMessageText | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputBotInlineMessageText.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const noWebpage = (flags.value & 1) !== 0;
      const message = TLString.deserialized(_data);
      if (!message) return undefined;
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new InputBotInlineMessageText(
        noWebpage,
        message,
        entities,
        replyMarkup
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputBotInlineMessageText.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.noWebpage) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.message);
      if (this.entities) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.entities) _data.push(this.entities);

      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly noWebpage: boolean | undefined,
      readonly message: TLString,
      readonly entities: TLVector<MessageEntityType> | undefined,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class InputBotInlineMessageMediaGeo implements TLObject {
    static readonly cons = new TLInt(0x96929a85);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineMessageMediaGeo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputBotInlineMessageMediaGeo.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const geoPoint = TLObject.deserialized(_data) as InputGeoPointType;
      if (!geoPoint) return undefined;
      let heading: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        heading = obj;
      }
      let period: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        period = obj;
      }
      let proximityNotificationRadius: TLInt | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        proximityNotificationRadius = obj;
      }
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new InputBotInlineMessageMediaGeo(
        geoPoint,
        heading,
        period,
        proximityNotificationRadius,
        replyMarkup
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputBotInlineMessageMediaGeo.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.geoPoint);
      if (this.heading) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.heading) _data.push(this.heading);

      if (this.period) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.period) _data.push(this.period);

      if (this.proximityNotificationRadius) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.proximityNotificationRadius)
        _data.push(this.proximityNotificationRadius);

      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly geoPoint: InputGeoPointType,
      readonly heading: TLInt | undefined,
      readonly period: TLInt | undefined,
      readonly proximityNotificationRadius: TLInt | undefined,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class InputBotInlineMessageMediaVenue implements TLObject {
    static readonly cons = new TLInt(0x417bbf11);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineMessageMediaVenue | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputBotInlineMessageMediaVenue.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const geoPoint = TLObject.deserialized(_data) as InputGeoPointType;
      if (!geoPoint) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const address = TLString.deserialized(_data);
      if (!address) return undefined;
      const provider = TLString.deserialized(_data);
      if (!provider) return undefined;
      const venueId = TLString.deserialized(_data);
      if (!venueId) return undefined;
      const venueType = TLString.deserialized(_data);
      if (!venueType) return undefined;
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new InputBotInlineMessageMediaVenue(
        geoPoint,
        title,
        address,
        provider,
        venueId,
        venueType,
        replyMarkup
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputBotInlineMessageMediaVenue.cons,
      ];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.geoPoint);
      _data.push(this.title);
      _data.push(this.address);
      _data.push(this.provider);
      _data.push(this.venueId);
      _data.push(this.venueType);
      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly geoPoint: InputGeoPointType,
      readonly title: TLString,
      readonly address: TLString,
      readonly provider: TLString,
      readonly venueId: TLString,
      readonly venueType: TLString,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class InputBotInlineMessageMediaContact implements TLObject {
    static readonly cons = new TLInt(0xa6edbffd);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineMessageMediaContact | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputBotInlineMessageMediaContact.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const phoneNumber = TLString.deserialized(_data);
      if (!phoneNumber) return undefined;
      const firstName = TLString.deserialized(_data);
      if (!firstName) return undefined;
      const lastName = TLString.deserialized(_data);
      if (!lastName) return undefined;
      const vcard = TLString.deserialized(_data);
      if (!vcard) return undefined;
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new InputBotInlineMessageMediaContact(
        phoneNumber,
        firstName,
        lastName,
        vcard,
        replyMarkup
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputBotInlineMessageMediaContact.cons,
      ];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.phoneNumber);
      _data.push(this.firstName);
      _data.push(this.lastName);
      _data.push(this.vcard);
      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly phoneNumber: TLString,
      readonly firstName: TLString,
      readonly lastName: TLString,
      readonly vcard: TLString,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class InputBotInlineMessageGame implements TLObject {
    static readonly cons = new TLInt(0x4b425864);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineMessageGame | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputBotInlineMessageGame.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new InputBotInlineMessageGame(replyMarkup);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputBotInlineMessageGame.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly replyMarkup: ReplyMarkupType | undefined) {}
  }

  export class InputBotInlineMessageMediaInvoice implements TLObject {
    static readonly cons = new TLInt(0xd7e78225);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineMessageMediaInvoice | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputBotInlineMessageMediaInvoice.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const description = TLString.deserialized(_data);
      if (!description) return undefined;
      let photo: API.InputWebDocument | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as API.InputWebDocument;
        if (!obj) return undefined;
        photo = obj;
      }
      const invoice = API.Invoice.deserialized(_data);
      if (!invoice) return undefined;
      const payload = TLBytes.deserialized(_data);
      if (!payload) return undefined;
      const provider = TLString.deserialized(_data);
      if (!provider) return undefined;
      const providerData = API.DataJSON.deserialized(_data);
      if (!providerData) return undefined;
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new InputBotInlineMessageMediaInvoice(
        title,
        description,
        photo,
        invoice,
        payload,
        provider,
        providerData,
        replyMarkup
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputBotInlineMessageMediaInvoice.cons,
      ];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.title);
      _data.push(this.description);
      if (this.photo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.photo) _data.push(this.photo);

      _data.push(this.invoice);
      _data.push(this.payload);
      _data.push(this.provider);
      _data.push(this.providerData);
      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly title: TLString,
      readonly description: TLString,
      readonly photo: API.InputWebDocument | undefined,
      readonly invoice: API.Invoice,
      readonly payload: TLBytes,
      readonly provider: TLString,
      readonly providerData: API.DataJSON,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class InputBotInlineResult implements TLObject {
    static readonly cons = new TLInt(0x88bf9319);

    static deserialized(_data: ByteStream): InputBotInlineResult | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputBotInlineResult.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const id = TLString.deserialized(_data);
      if (!id) return undefined;
      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      let title: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        title = obj;
      }
      let description: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        description = obj;
      }
      let url: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        url = obj;
      }
      let thumb: API.InputWebDocument | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLObject.deserialized(_data) as API.InputWebDocument;
        if (!obj) return undefined;
        thumb = obj;
      }
      let content: API.InputWebDocument | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLObject.deserialized(_data) as API.InputWebDocument;
        if (!obj) return undefined;
        content = obj;
      }
      const sendMessage = TLObject.deserialized(
        _data
      ) as InputBotInlineMessageType;
      if (!sendMessage) return undefined;

      return new InputBotInlineResult(
        id,
        type,
        title,
        description,
        url,
        thumb,
        content,
        sendMessage
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputBotInlineResult.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.id);
      _data.push(this.type);
      if (this.title) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.title) _data.push(this.title);

      if (this.description) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.description) _data.push(this.description);

      if (this.url) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.url) _data.push(this.url);

      if (this.thumb) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.thumb) _data.push(this.thumb);

      if (this.content) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.content) _data.push(this.content);

      _data.push(this.sendMessage);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLString,
      readonly type: TLString,
      readonly title: TLString | undefined,
      readonly description: TLString | undefined,
      readonly url: TLString | undefined,
      readonly thumb: API.InputWebDocument | undefined,
      readonly content: API.InputWebDocument | undefined,
      readonly sendMessage: InputBotInlineMessageType
    ) {}
  }

  export class InputBotInlineResultPhoto implements TLObject {
    static readonly cons = new TLInt(0xa8d864a7);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineResultPhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputBotInlineResultPhoto.cons))
        return undefined;

      const id = TLString.deserialized(_data);
      if (!id) return undefined;
      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      const photo = TLObject.deserialized(_data) as InputPhotoType;
      if (!photo) return undefined;
      const sendMessage = TLObject.deserialized(
        _data
      ) as InputBotInlineMessageType;
      if (!sendMessage) return undefined;

      return new InputBotInlineResultPhoto(id, type, photo, sendMessage);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputBotInlineResultPhoto.cons];

      _data.push(this.id);
      _data.push(this.type);
      _data.push(this.photo);
      _data.push(this.sendMessage);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLString,
      readonly type: TLString,
      readonly photo: InputPhotoType,
      readonly sendMessage: InputBotInlineMessageType
    ) {}
  }

  export class InputBotInlineResultDocument implements TLObject {
    static readonly cons = new TLInt(0xfff8fdc4);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineResultDocument | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputBotInlineResultDocument.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const id = TLString.deserialized(_data);
      if (!id) return undefined;
      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      let title: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        title = obj;
      }
      let description: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        description = obj;
      }
      const document = TLObject.deserialized(_data) as InputDocumentType;
      if (!document) return undefined;
      const sendMessage = TLObject.deserialized(
        _data
      ) as InputBotInlineMessageType;
      if (!sendMessage) return undefined;

      return new InputBotInlineResultDocument(
        id,
        type,
        title,
        description,
        document,
        sendMessage
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputBotInlineResultDocument.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.id);
      _data.push(this.type);
      if (this.title) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.title) _data.push(this.title);

      if (this.description) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.description) _data.push(this.description);

      _data.push(this.document);
      _data.push(this.sendMessage);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLString,
      readonly type: TLString,
      readonly title: TLString | undefined,
      readonly description: TLString | undefined,
      readonly document: InputDocumentType,
      readonly sendMessage: InputBotInlineMessageType
    ) {}
  }

  export class InputBotInlineResultGame implements TLObject {
    static readonly cons = new TLInt(0x4fa417f2);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineResultGame | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputBotInlineResultGame.cons))
        return undefined;

      const id = TLString.deserialized(_data);
      if (!id) return undefined;
      const shortName = TLString.deserialized(_data);
      if (!shortName) return undefined;
      const sendMessage = TLObject.deserialized(
        _data
      ) as InputBotInlineMessageType;
      if (!sendMessage) return undefined;

      return new InputBotInlineResultGame(id, shortName, sendMessage);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputBotInlineResultGame.cons];

      _data.push(this.id);
      _data.push(this.shortName);
      _data.push(this.sendMessage);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLString,
      readonly shortName: TLString,
      readonly sendMessage: InputBotInlineMessageType
    ) {}
  }

  export class BotInlineMessageMediaAuto implements TLObject {
    static readonly cons = new TLInt(0x764cf810);

    static deserialized(
      _data: ByteStream
    ): BotInlineMessageMediaAuto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotInlineMessageMediaAuto.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const message = TLString.deserialized(_data);
      if (!message) return undefined;
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new BotInlineMessageMediaAuto(message, entities, replyMarkup);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotInlineMessageMediaAuto.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.message);
      if (this.entities) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.entities) _data.push(this.entities);

      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly message: TLString,
      readonly entities: TLVector<MessageEntityType> | undefined,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class BotInlineMessageText implements TLObject {
    static readonly cons = new TLInt(0x8c7f65e2);

    static deserialized(_data: ByteStream): BotInlineMessageText | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotInlineMessageText.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const noWebpage = (flags.value & 1) !== 0;
      const message = TLString.deserialized(_data);
      if (!message) return undefined;
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new BotInlineMessageText(
        noWebpage,
        message,
        entities,
        replyMarkup
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotInlineMessageText.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.noWebpage) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.message);
      if (this.entities) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.entities) _data.push(this.entities);

      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly noWebpage: boolean | undefined,
      readonly message: TLString,
      readonly entities: TLVector<MessageEntityType> | undefined,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class BotInlineMessageMediaGeo implements TLObject {
    static readonly cons = new TLInt(0x51846fd);

    static deserialized(
      _data: ByteStream
    ): BotInlineMessageMediaGeo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotInlineMessageMediaGeo.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const geo = TLObject.deserialized(_data) as GeoPointType;
      if (!geo) return undefined;
      let heading: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        heading = obj;
      }
      let period: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        period = obj;
      }
      let proximityNotificationRadius: TLInt | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        proximityNotificationRadius = obj;
      }
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new BotInlineMessageMediaGeo(
        geo,
        heading,
        period,
        proximityNotificationRadius,
        replyMarkup
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotInlineMessageMediaGeo.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.geo);
      if (this.heading) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.heading) _data.push(this.heading);

      if (this.period) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.period) _data.push(this.period);

      if (this.proximityNotificationRadius) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.proximityNotificationRadius)
        _data.push(this.proximityNotificationRadius);

      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly geo: GeoPointType,
      readonly heading: TLInt | undefined,
      readonly period: TLInt | undefined,
      readonly proximityNotificationRadius: TLInt | undefined,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class BotInlineMessageMediaVenue implements TLObject {
    static readonly cons = new TLInt(0x8a86659c);

    static deserialized(
      _data: ByteStream
    ): BotInlineMessageMediaVenue | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotInlineMessageMediaVenue.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const geo = TLObject.deserialized(_data) as GeoPointType;
      if (!geo) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const address = TLString.deserialized(_data);
      if (!address) return undefined;
      const provider = TLString.deserialized(_data);
      if (!provider) return undefined;
      const venueId = TLString.deserialized(_data);
      if (!venueId) return undefined;
      const venueType = TLString.deserialized(_data);
      if (!venueType) return undefined;
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new BotInlineMessageMediaVenue(
        geo,
        title,
        address,
        provider,
        venueId,
        venueType,
        replyMarkup
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotInlineMessageMediaVenue.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.geo);
      _data.push(this.title);
      _data.push(this.address);
      _data.push(this.provider);
      _data.push(this.venueId);
      _data.push(this.venueType);
      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly geo: GeoPointType,
      readonly title: TLString,
      readonly address: TLString,
      readonly provider: TLString,
      readonly venueId: TLString,
      readonly venueType: TLString,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class BotInlineMessageMediaContact implements TLObject {
    static readonly cons = new TLInt(0x18d1cdc2);

    static deserialized(
      _data: ByteStream
    ): BotInlineMessageMediaContact | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(BotInlineMessageMediaContact.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const phoneNumber = TLString.deserialized(_data);
      if (!phoneNumber) return undefined;
      const firstName = TLString.deserialized(_data);
      if (!firstName) return undefined;
      const lastName = TLString.deserialized(_data);
      if (!lastName) return undefined;
      const vcard = TLString.deserialized(_data);
      if (!vcard) return undefined;
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new BotInlineMessageMediaContact(
        phoneNumber,
        firstName,
        lastName,
        vcard,
        replyMarkup
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotInlineMessageMediaContact.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.phoneNumber);
      _data.push(this.firstName);
      _data.push(this.lastName);
      _data.push(this.vcard);
      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly phoneNumber: TLString,
      readonly firstName: TLString,
      readonly lastName: TLString,
      readonly vcard: TLString,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class BotInlineMessageMediaInvoice implements TLObject {
    static readonly cons = new TLInt(0x354a9b09);

    static deserialized(
      _data: ByteStream
    ): BotInlineMessageMediaInvoice | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(BotInlineMessageMediaInvoice.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const shippingAddressRequested = (flags.value & 2) !== 0;
      const test = (flags.value & 8) !== 0;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const description = TLString.deserialized(_data);
      if (!description) return undefined;
      let photo: WebDocumentType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as WebDocumentType;
        if (!obj) return undefined;
        photo = obj;
      }
      const currency = TLString.deserialized(_data);
      if (!currency) return undefined;
      const totalAmount = TLLong.deserialized(_data);
      if (!totalAmount) return undefined;
      let replyMarkup: ReplyMarkupType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReplyMarkupType;
        if (!obj) return undefined;
        replyMarkup = obj;
      }

      return new BotInlineMessageMediaInvoice(
        shippingAddressRequested,
        test,
        title,
        description,
        photo,
        currency,
        totalAmount,
        replyMarkup
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotInlineMessageMediaInvoice.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.shippingAddressRequested) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.test) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      _data.push(this.title);
      _data.push(this.description);
      if (this.photo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.photo) _data.push(this.photo);

      _data.push(this.currency);
      _data.push(this.totalAmount);
      if (this.replyMarkup) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replyMarkup) _data.push(this.replyMarkup);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly shippingAddressRequested: boolean | undefined,
      readonly test: boolean | undefined,
      readonly title: TLString,
      readonly description: TLString,
      readonly photo: WebDocumentType | undefined,
      readonly currency: TLString,
      readonly totalAmount: TLLong,
      readonly replyMarkup: ReplyMarkupType | undefined
    ) {}
  }

  export class BotInlineResult implements TLObject {
    static readonly cons = new TLInt(0x11965f3a);

    static deserialized(_data: ByteStream): BotInlineResult | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotInlineResult.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const id = TLString.deserialized(_data);
      if (!id) return undefined;
      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      let title: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        title = obj;
      }
      let description: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        description = obj;
      }
      let url: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        url = obj;
      }
      let thumb: WebDocumentType | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLObject.deserialized(_data) as WebDocumentType;
        if (!obj) return undefined;
        thumb = obj;
      }
      let content: WebDocumentType | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLObject.deserialized(_data) as WebDocumentType;
        if (!obj) return undefined;
        content = obj;
      }
      const sendMessage = TLObject.deserialized(_data) as BotInlineMessageType;
      if (!sendMessage) return undefined;

      return new BotInlineResult(
        id,
        type,
        title,
        description,
        url,
        thumb,
        content,
        sendMessage
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotInlineResult.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.id);
      _data.push(this.type);
      if (this.title) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.title) _data.push(this.title);

      if (this.description) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.description) _data.push(this.description);

      if (this.url) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.url) _data.push(this.url);

      if (this.thumb) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.thumb) _data.push(this.thumb);

      if (this.content) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.content) _data.push(this.content);

      _data.push(this.sendMessage);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLString,
      readonly type: TLString,
      readonly title: TLString | undefined,
      readonly description: TLString | undefined,
      readonly url: TLString | undefined,
      readonly thumb: WebDocumentType | undefined,
      readonly content: WebDocumentType | undefined,
      readonly sendMessage: BotInlineMessageType
    ) {}
  }

  export class BotInlineMediaResult implements TLObject {
    static readonly cons = new TLInt(0x17db940b);

    static deserialized(_data: ByteStream): BotInlineMediaResult | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotInlineMediaResult.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const id = TLString.deserialized(_data);
      if (!id) return undefined;
      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      let photo: PhotoType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as PhotoType;
        if (!obj) return undefined;
        photo = obj;
      }
      let document: DocumentType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as DocumentType;
        if (!obj) return undefined;
        document = obj;
      }
      let title: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        title = obj;
      }
      let description: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        description = obj;
      }
      const sendMessage = TLObject.deserialized(_data) as BotInlineMessageType;
      if (!sendMessage) return undefined;

      return new BotInlineMediaResult(
        id,
        type,
        photo,
        document,
        title,
        description,
        sendMessage
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotInlineMediaResult.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.id);
      _data.push(this.type);
      if (this.photo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.photo) _data.push(this.photo);

      if (this.document) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.document) _data.push(this.document);

      if (this.title) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.title) _data.push(this.title);

      if (this.description) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.description) _data.push(this.description);

      _data.push(this.sendMessage);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLString,
      readonly type: TLString,
      readonly photo: PhotoType | undefined,
      readonly document: DocumentType | undefined,
      readonly title: TLString | undefined,
      readonly description: TLString | undefined,
      readonly sendMessage: BotInlineMessageType
    ) {}
  }

  export namespace messages {
    export class BotResults implements TLObject {
      static readonly cons = new TLInt(0xe021f2f6);

      static deserialized(_data: ByteStream): BotResults | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(BotResults.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const gallery = (flags.value & 1) !== 0;
        const queryId = TLLong.deserialized(_data);
        if (!queryId) return undefined;
        let nextOffset: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          nextOffset = obj;
        }
        let switchPm: API.InlineBotSwitchPM | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as API.InlineBotSwitchPM;
          if (!obj) return undefined;
          switchPm = obj;
        }
        let switchWebview: API.InlineBotWebView | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLObject.deserialized(_data) as API.InlineBotWebView;
          if (!obj) return undefined;
          switchWebview = obj;
        }
        const results = TLVector.deserialized(
          _data
        ) as TLVector<BotInlineResultType>;
        if (!results) return undefined;
        const cacheTime = TLInt.deserialized(_data);
        if (!cacheTime) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new BotResults(
          gallery,
          queryId,
          nextOffset,
          switchPm,
          switchWebview,
          results,
          cacheTime,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [BotResults.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.gallery) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.queryId);
        if (this.nextOffset) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.nextOffset) _data.push(this.nextOffset);

        if (this.switchPm) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.switchPm) _data.push(this.switchPm);

        if (this.switchWebview) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.switchWebview) _data.push(this.switchWebview);

        _data.push(this.results);
        _data.push(this.cacheTime);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly gallery: boolean | undefined,
        readonly queryId: TLLong,
        readonly nextOffset: TLString | undefined,
        readonly switchPm: API.InlineBotSwitchPM | undefined,
        readonly switchWebview: API.InlineBotWebView | undefined,
        readonly results: TLVector<BotInlineResultType>,
        readonly cacheTime: TLInt,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class ExportedMessageLink implements TLObject {
    static readonly cons = new TLInt(0x5dab1af4);

    static deserialized(_data: ByteStream): ExportedMessageLink | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ExportedMessageLink.cons))
        return undefined;

      const link = TLString.deserialized(_data);
      if (!link) return undefined;
      const html = TLString.deserialized(_data);
      if (!html) return undefined;

      return new ExportedMessageLink(link, html);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ExportedMessageLink.cons];

      _data.push(this.link);
      _data.push(this.html);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly link: TLString, readonly html: TLString) {}
  }

  export class MessageFwdHeader implements TLObject {
    static readonly cons = new TLInt(0x5f777dce);

    static deserialized(_data: ByteStream): MessageFwdHeader | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageFwdHeader.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const imported = (flags.value & 128) !== 0;
      let fromId: PeerType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as PeerType;
        if (!obj) return undefined;
        fromId = obj;
      }
      let fromName: TLString | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        fromName = obj;
      }
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      let channelPost: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        channelPost = obj;
      }
      let postAuthor: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        postAuthor = obj;
      }
      let savedFromPeer: PeerType | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLObject.deserialized(_data) as PeerType;
        if (!obj) return undefined;
        savedFromPeer = obj;
      }
      let savedFromMsgId: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        savedFromMsgId = obj;
      }
      let psaType: TLString | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        psaType = obj;
      }

      return new MessageFwdHeader(
        imported,
        fromId,
        fromName,
        date,
        channelPost,
        postAuthor,
        savedFromPeer,
        savedFromMsgId,
        psaType
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageFwdHeader.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.imported) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.fromId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.fromId) _data.push(this.fromId);

      if (this.fromName) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.fromName) _data.push(this.fromName);

      _data.push(this.date);
      if (this.channelPost) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.channelPost) _data.push(this.channelPost);

      if (this.postAuthor) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.postAuthor) _data.push(this.postAuthor);

      if (this.savedFromPeer) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.savedFromPeer) _data.push(this.savedFromPeer);

      if (this.savedFromMsgId) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.savedFromMsgId) _data.push(this.savedFromMsgId);

      if (this.psaType) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.psaType) _data.push(this.psaType);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly imported: boolean | undefined,
      readonly fromId: PeerType | undefined,
      readonly fromName: TLString | undefined,
      readonly date: TLInt,
      readonly channelPost: TLInt | undefined,
      readonly postAuthor: TLString | undefined,
      readonly savedFromPeer: PeerType | undefined,
      readonly savedFromMsgId: TLInt | undefined,
      readonly psaType: TLString | undefined
    ) {}
  }

  export namespace auth {
    export class CodeTypeSms implements TLObject {
      static readonly cons = new TLInt(0x72a3158c);

      static deserialized(_data: ByteStream): CodeTypeSms | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CodeTypeSms.cons))
          return undefined;

        return new CodeTypeSms();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CodeTypeSms.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace auth {
    export class CodeTypeCall implements TLObject {
      static readonly cons = new TLInt(0x741cd3e3);

      static deserialized(_data: ByteStream): CodeTypeCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CodeTypeCall.cons))
          return undefined;

        return new CodeTypeCall();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CodeTypeCall.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace auth {
    export class CodeTypeFlashCall implements TLObject {
      static readonly cons = new TLInt(0x226ccefb);

      static deserialized(_data: ByteStream): CodeTypeFlashCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CodeTypeFlashCall.cons))
          return undefined;

        return new CodeTypeFlashCall();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CodeTypeFlashCall.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace auth {
    export class CodeTypeMissedCall implements TLObject {
      static readonly cons = new TLInt(0xd61ad6ee);

      static deserialized(_data: ByteStream): CodeTypeMissedCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CodeTypeMissedCall.cons))
          return undefined;

        return new CodeTypeMissedCall();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CodeTypeMissedCall.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace auth {
    export class CodeTypeFragmentSms implements TLObject {
      static readonly cons = new TLInt(0x6ed998c);

      static deserialized(_data: ByteStream): CodeTypeFragmentSms | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CodeTypeFragmentSms.cons))
          return undefined;

        return new CodeTypeFragmentSms();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CodeTypeFragmentSms.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace auth {
    export class SentCodeTypeApp implements TLObject {
      static readonly cons = new TLInt(0x3dbb5986);

      static deserialized(_data: ByteStream): SentCodeTypeApp | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentCodeTypeApp.cons))
          return undefined;

        const length = TLInt.deserialized(_data);
        if (!length) return undefined;

        return new SentCodeTypeApp(length);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentCodeTypeApp.cons];

        _data.push(this.length);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly length: TLInt) {}
    }
  }

  export namespace auth {
    export class SentCodeTypeSms implements TLObject {
      static readonly cons = new TLInt(0xc000bba2);

      static deserialized(_data: ByteStream): SentCodeTypeSms | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentCodeTypeSms.cons))
          return undefined;

        const length = TLInt.deserialized(_data);
        if (!length) return undefined;

        return new SentCodeTypeSms(length);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentCodeTypeSms.cons];

        _data.push(this.length);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly length: TLInt) {}
    }
  }

  export namespace auth {
    export class SentCodeTypeCall implements TLObject {
      static readonly cons = new TLInt(0x5353e5a7);

      static deserialized(_data: ByteStream): SentCodeTypeCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentCodeTypeCall.cons))
          return undefined;

        const length = TLInt.deserialized(_data);
        if (!length) return undefined;

        return new SentCodeTypeCall(length);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentCodeTypeCall.cons];

        _data.push(this.length);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly length: TLInt) {}
    }
  }

  export namespace auth {
    export class SentCodeTypeFlashCall implements TLObject {
      static readonly cons = new TLInt(0xab03c6d9);

      static deserialized(
        _data: ByteStream
      ): SentCodeTypeFlashCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentCodeTypeFlashCall.cons))
          return undefined;

        const pattern = TLString.deserialized(_data);
        if (!pattern) return undefined;

        return new SentCodeTypeFlashCall(pattern);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentCodeTypeFlashCall.cons];

        _data.push(this.pattern);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly pattern: TLString) {}
    }
  }

  export namespace auth {
    export class SentCodeTypeMissedCall implements TLObject {
      static readonly cons = new TLInt(0x82006484);

      static deserialized(
        _data: ByteStream
      ): SentCodeTypeMissedCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentCodeTypeMissedCall.cons))
          return undefined;

        const prefix = TLString.deserialized(_data);
        if (!prefix) return undefined;
        const length = TLInt.deserialized(_data);
        if (!length) return undefined;

        return new SentCodeTypeMissedCall(prefix, length);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentCodeTypeMissedCall.cons];

        _data.push(this.prefix);
        _data.push(this.length);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly prefix: TLString, readonly length: TLInt) {}
    }
  }

  export namespace auth {
    export class SentCodeTypeEmailCode implements TLObject {
      static readonly cons = new TLInt(0xf450f59b);

      static deserialized(
        _data: ByteStream
      ): SentCodeTypeEmailCode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentCodeTypeEmailCode.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const appleSigninAllowed = (flags.value & 1) !== 0;
        const googleSigninAllowed = (flags.value & 2) !== 0;
        const emailPattern = TLString.deserialized(_data);
        if (!emailPattern) return undefined;
        const length = TLInt.deserialized(_data);
        if (!length) return undefined;
        let resetAvailablePeriod: TLInt | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          resetAvailablePeriod = obj;
        }
        let resetPendingDate: TLInt | undefined;
        if ((flags.value & 16) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          resetPendingDate = obj;
        }

        return new SentCodeTypeEmailCode(
          appleSigninAllowed,
          googleSigninAllowed,
          emailPattern,
          length,
          resetAvailablePeriod,
          resetPendingDate
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentCodeTypeEmailCode.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.appleSigninAllowed) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.googleSigninAllowed) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.emailPattern);
        _data.push(this.length);
        if (this.resetAvailablePeriod) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.resetAvailablePeriod) _data.push(this.resetAvailablePeriod);

        if (this.resetPendingDate) {
          flags.set(16);
        } else {
          flags.unset(16);
        }
        if (this.resetPendingDate) _data.push(this.resetPendingDate);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly appleSigninAllowed: boolean | undefined,
        readonly googleSigninAllowed: boolean | undefined,
        readonly emailPattern: TLString,
        readonly length: TLInt,
        readonly resetAvailablePeriod: TLInt | undefined,
        readonly resetPendingDate: TLInt | undefined
      ) {}
    }
  }

  export namespace auth {
    export class SentCodeTypeSetUpEmailRequired implements TLObject {
      static readonly cons = new TLInt(0xa5491dea);

      static deserialized(
        _data: ByteStream
      ): SentCodeTypeSetUpEmailRequired | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(SentCodeTypeSetUpEmailRequired.cons)
        )
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const appleSigninAllowed = (flags.value & 1) !== 0;
        const googleSigninAllowed = (flags.value & 2) !== 0;

        return new SentCodeTypeSetUpEmailRequired(
          appleSigninAllowed,
          googleSigninAllowed
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          SentCodeTypeSetUpEmailRequired.cons,
        ];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.appleSigninAllowed) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.googleSigninAllowed) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly appleSigninAllowed: boolean | undefined,
        readonly googleSigninAllowed: boolean | undefined
      ) {}
    }
  }

  export namespace auth {
    export class SentCodeTypeFragmentSms implements TLObject {
      static readonly cons = new TLInt(0xd9565c39);

      static deserialized(
        _data: ByteStream
      ): SentCodeTypeFragmentSms | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentCodeTypeFragmentSms.cons))
          return undefined;

        const url = TLString.deserialized(_data);
        if (!url) return undefined;
        const length = TLInt.deserialized(_data);
        if (!length) return undefined;

        return new SentCodeTypeFragmentSms(url, length);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentCodeTypeFragmentSms.cons];

        _data.push(this.url);
        _data.push(this.length);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly url: TLString, readonly length: TLInt) {}
    }
  }

  export namespace auth {
    export class SentCodeTypeFirebaseSms implements TLObject {
      static readonly cons = new TLInt(0xe57b1432);

      static deserialized(
        _data: ByteStream
      ): SentCodeTypeFirebaseSms | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentCodeTypeFirebaseSms.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let nonce: TLBytes | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLBytes.deserialized(_data);
          if (!obj) return undefined;
          nonce = obj;
        }
        let receipt: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          receipt = obj;
        }
        let pushTimeout: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          pushTimeout = obj;
        }
        const length = TLInt.deserialized(_data);
        if (!length) return undefined;

        return new SentCodeTypeFirebaseSms(nonce, receipt, pushTimeout, length);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentCodeTypeFirebaseSms.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.nonce) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.nonce) _data.push(this.nonce);

        if (this.receipt) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.receipt) _data.push(this.receipt);

        if (this.pushTimeout) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.pushTimeout) _data.push(this.pushTimeout);

        _data.push(this.length);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly nonce: TLBytes | undefined,
        readonly receipt: TLString | undefined,
        readonly pushTimeout: TLInt | undefined,
        readonly length: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class BotCallbackAnswer implements TLObject {
      static readonly cons = new TLInt(0x36585ea4);

      static deserialized(_data: ByteStream): BotCallbackAnswer | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(BotCallbackAnswer.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const alert = (flags.value & 2) !== 0;
        const hasUrl = (flags.value & 8) !== 0;
        const nativeUi = (flags.value & 16) !== 0;
        let message: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          message = obj;
        }
        let url: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          url = obj;
        }
        const cacheTime = TLInt.deserialized(_data);
        if (!cacheTime) return undefined;

        return new BotCallbackAnswer(
          alert,
          hasUrl,
          nativeUi,
          message,
          url,
          cacheTime
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [BotCallbackAnswer.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.alert) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.hasUrl) {
          flags.set(8);
        } else {
          flags.unset(8);
        }

        if (this.nativeUi) {
          flags.set(16);
        } else {
          flags.unset(16);
        }

        if (this.message) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.message) _data.push(this.message);

        if (this.url) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.url) _data.push(this.url);

        _data.push(this.cacheTime);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly alert: boolean | undefined,
        readonly hasUrl: boolean | undefined,
        readonly nativeUi: boolean | undefined,
        readonly message: TLString | undefined,
        readonly url: TLString | undefined,
        readonly cacheTime: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class MessageEditData implements TLObject {
      static readonly cons = new TLInt(0x26b5dde6);

      static deserialized(_data: ByteStream): MessageEditData | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(MessageEditData.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const caption = (flags.value & 1) !== 0;

        return new MessageEditData(caption);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [MessageEditData.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.caption) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly caption: boolean | undefined) {}
    }
  }

  export class InputBotInlineMessageID implements TLObject {
    static readonly cons = new TLInt(0x890c3d89);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineMessageID | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputBotInlineMessageID.cons))
        return undefined;

      const dcId = TLInt.deserialized(_data);
      if (!dcId) return undefined;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputBotInlineMessageID(dcId, id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputBotInlineMessageID.cons];

      _data.push(this.dcId);
      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly dcId: TLInt,
      readonly id: TLLong,
      readonly accessHash: TLLong
    ) {}
  }

  export class InputBotInlineMessageID64 implements TLObject {
    static readonly cons = new TLInt(0xb6d915d7);

    static deserialized(
      _data: ByteStream
    ): InputBotInlineMessageID64 | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputBotInlineMessageID64.cons))
        return undefined;

      const dcId = TLInt.deserialized(_data);
      if (!dcId) return undefined;
      const ownerId = TLLong.deserialized(_data);
      if (!ownerId) return undefined;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputBotInlineMessageID64(dcId, ownerId, id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputBotInlineMessageID64.cons];

      _data.push(this.dcId);
      _data.push(this.ownerId);
      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly dcId: TLInt,
      readonly ownerId: TLLong,
      readonly id: TLInt,
      readonly accessHash: TLLong
    ) {}
  }

  export class InlineBotSwitchPM implements TLObject {
    static readonly cons = new TLInt(0x3c20629f);

    static deserialized(_data: ByteStream): InlineBotSwitchPM | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InlineBotSwitchPM.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const startParam = TLString.deserialized(_data);
      if (!startParam) return undefined;

      return new InlineBotSwitchPM(text, startParam);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InlineBotSwitchPM.cons];

      _data.push(this.text);
      _data.push(this.startParam);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString, readonly startParam: TLString) {}
  }

  export namespace messages {
    export class PeerDialogs implements TLObject {
      static readonly cons = new TLInt(0x3371c354);

      static deserialized(_data: ByteStream): PeerDialogs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PeerDialogs.cons))
          return undefined;

        const dialogs = TLVector.deserialized(_data) as TLVector<DialogType>;
        if (!dialogs) return undefined;
        const messages = TLVector.deserialized(_data) as TLVector<MessageType>;
        if (!messages) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;
        const state = updates.State.deserialized(_data);
        if (!state) return undefined;

        return new PeerDialogs(dialogs, messages, chats, users, state);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PeerDialogs.cons];

        _data.push(this.dialogs);
        _data.push(this.messages);
        _data.push(this.chats);
        _data.push(this.users);
        _data.push(this.state);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly dialogs: TLVector<DialogType>,
        readonly messages: TLVector<MessageType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>,
        readonly state: updates.State
      ) {}
    }
  }

  export class TopPeer implements TLObject {
    static readonly cons = new TLInt(0xedcdc05b);

    static deserialized(_data: ByteStream): TopPeer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TopPeer.cons)) return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const rating = TLDouble.deserialized(_data);
      if (!rating) return undefined;

      return new TopPeer(peer, rating);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TopPeer.cons];

      _data.push(this.peer);
      _data.push(this.rating);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType, readonly rating: TLDouble) {}
  }

  export class TopPeerCategoryBotsPM implements TLObject {
    static readonly cons = new TLInt(0xab661b5b);

    static deserialized(_data: ByteStream): TopPeerCategoryBotsPM | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TopPeerCategoryBotsPM.cons))
        return undefined;

      return new TopPeerCategoryBotsPM();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TopPeerCategoryBotsPM.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class TopPeerCategoryBotsInline implements TLObject {
    static readonly cons = new TLInt(0x148677e2);

    static deserialized(
      _data: ByteStream
    ): TopPeerCategoryBotsInline | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TopPeerCategoryBotsInline.cons))
        return undefined;

      return new TopPeerCategoryBotsInline();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TopPeerCategoryBotsInline.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class TopPeerCategoryCorrespondents implements TLObject {
    static readonly cons = new TLInt(0x637b7ed);

    static deserialized(
      _data: ByteStream
    ): TopPeerCategoryCorrespondents | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(TopPeerCategoryCorrespondents.cons)
      )
        return undefined;

      return new TopPeerCategoryCorrespondents();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TopPeerCategoryCorrespondents.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class TopPeerCategoryGroups implements TLObject {
    static readonly cons = new TLInt(0xbd17a14a);

    static deserialized(_data: ByteStream): TopPeerCategoryGroups | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TopPeerCategoryGroups.cons))
        return undefined;

      return new TopPeerCategoryGroups();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TopPeerCategoryGroups.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class TopPeerCategoryChannels implements TLObject {
    static readonly cons = new TLInt(0x161d9628);

    static deserialized(
      _data: ByteStream
    ): TopPeerCategoryChannels | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TopPeerCategoryChannels.cons))
        return undefined;

      return new TopPeerCategoryChannels();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TopPeerCategoryChannels.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class TopPeerCategoryPhoneCalls implements TLObject {
    static readonly cons = new TLInt(0x1e76a78c);

    static deserialized(
      _data: ByteStream
    ): TopPeerCategoryPhoneCalls | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TopPeerCategoryPhoneCalls.cons))
        return undefined;

      return new TopPeerCategoryPhoneCalls();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TopPeerCategoryPhoneCalls.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class TopPeerCategoryForwardUsers implements TLObject {
    static readonly cons = new TLInt(0xa8406ca9);

    static deserialized(
      _data: ByteStream
    ): TopPeerCategoryForwardUsers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TopPeerCategoryForwardUsers.cons))
        return undefined;

      return new TopPeerCategoryForwardUsers();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TopPeerCategoryForwardUsers.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class TopPeerCategoryForwardChats implements TLObject {
    static readonly cons = new TLInt(0xfbeec0f0);

    static deserialized(
      _data: ByteStream
    ): TopPeerCategoryForwardChats | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TopPeerCategoryForwardChats.cons))
        return undefined;

      return new TopPeerCategoryForwardChats();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TopPeerCategoryForwardChats.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class TopPeerCategoryPeers implements TLObject {
    static readonly cons = new TLInt(0xfb834291);

    static deserialized(_data: ByteStream): TopPeerCategoryPeers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TopPeerCategoryPeers.cons))
        return undefined;

      const category = TLObject.deserialized(_data) as TopPeerCategoryType;
      if (!category) return undefined;
      const count = TLInt.deserialized(_data);
      if (!count) return undefined;
      const peers = TLVector.deserialized(_data, API.TopPeer);
      if (!peers) return undefined;

      return new TopPeerCategoryPeers(category, count, peers);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TopPeerCategoryPeers.cons];

      _data.push(this.category);
      _data.push(this.count);
      _data.push(this.peers);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly category: TopPeerCategoryType,
      readonly count: TLInt,
      readonly peers: TLVector<API.TopPeer>
    ) {}
  }

  export namespace contacts {
    export class TopPeersNotModified implements TLObject {
      static readonly cons = new TLInt(0xde266ef5);

      static deserialized(_data: ByteStream): TopPeersNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TopPeersNotModified.cons))
          return undefined;

        return new TopPeersNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TopPeersNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace contacts {
    export class TopPeers implements TLObject {
      static readonly cons = new TLInt(0x70b772a8);

      static deserialized(_data: ByteStream): TopPeers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TopPeers.cons))
          return undefined;

        const categories = TLVector.deserialized(
          _data,
          API.TopPeerCategoryPeers
        );
        if (!categories) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new TopPeers(categories, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TopPeers.cons];

        _data.push(this.categories);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly categories: TLVector<API.TopPeerCategoryPeers>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace contacts {
    export class TopPeersDisabled implements TLObject {
      static readonly cons = new TLInt(0xb52c939d);

      static deserialized(_data: ByteStream): TopPeersDisabled | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TopPeersDisabled.cons))
          return undefined;

        return new TopPeersDisabled();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TopPeersDisabled.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export class DraftMessageEmpty implements TLObject {
    static readonly cons = new TLInt(0x1b0c841a);

    static deserialized(_data: ByteStream): DraftMessageEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DraftMessageEmpty.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let date: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        date = obj;
      }

      return new DraftMessageEmpty(date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DraftMessageEmpty.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.date) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.date) _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly date: TLInt | undefined) {}
  }

  export class DraftMessage implements TLObject {
    static readonly cons = new TLInt(0xfd8e711f);

    static deserialized(_data: ByteStream): DraftMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DraftMessage.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const noWebpage = (flags.value & 2) !== 0;
      let replyToMsgId: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        replyToMsgId = obj;
      }
      const message = TLString.deserialized(_data);
      if (!message) return undefined;
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new DraftMessage(noWebpage, replyToMsgId, message, entities, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DraftMessage.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.noWebpage) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.replyToMsgId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.replyToMsgId) _data.push(this.replyToMsgId);

      _data.push(this.message);
      if (this.entities) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.entities) _data.push(this.entities);

      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly noWebpage: boolean | undefined,
      readonly replyToMsgId: TLInt | undefined,
      readonly message: TLString,
      readonly entities: TLVector<MessageEntityType> | undefined,
      readonly date: TLInt
    ) {}
  }

  export namespace messages {
    export class FeaturedStickersNotModified implements TLObject {
      static readonly cons = new TLInt(0xc6dc0c66);

      static deserialized(
        _data: ByteStream
      ): FeaturedStickersNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(FeaturedStickersNotModified.cons)
        )
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;

        return new FeaturedStickersNotModified(count);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FeaturedStickersNotModified.cons];

        _data.push(this.count);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly count: TLInt) {}
    }
  }

  export namespace messages {
    export class FeaturedStickers implements TLObject {
      static readonly cons = new TLInt(0xbe382906);

      static deserialized(_data: ByteStream): FeaturedStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FeaturedStickers.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const premium = (flags.value & 1) !== 0;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const sets = TLVector.deserialized(
          _data
        ) as TLVector<StickerSetCoveredType>;
        if (!sets) return undefined;
        const unread = TLVector.deserialized(_data, TLLong);
        if (!unread) return undefined;

        return new FeaturedStickers(premium, hash, count, sets, unread);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FeaturedStickers.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.premium) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.hash);
        _data.push(this.count);
        _data.push(this.sets);
        _data.push(this.unread);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly premium: boolean | undefined,
        readonly hash: TLLong,
        readonly count: TLInt,
        readonly sets: TLVector<StickerSetCoveredType>,
        readonly unread: TLVector<TLLong>
      ) {}
    }
  }

  export namespace messages {
    export class RecentStickersNotModified implements TLObject {
      static readonly cons = new TLInt(0xb17f890);

      static deserialized(
        _data: ByteStream
      ): RecentStickersNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RecentStickersNotModified.cons))
          return undefined;

        return new RecentStickersNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RecentStickersNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class RecentStickers implements TLObject {
      static readonly cons = new TLInt(0x88d37c56);

      static deserialized(_data: ByteStream): RecentStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RecentStickers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const packs = TLVector.deserialized(_data, API.StickerPack);
        if (!packs) return undefined;
        const stickers = TLVector.deserialized(_data) as TLVector<DocumentType>;
        if (!stickers) return undefined;
        const dates = TLVector.deserialized(_data, TLInt);
        if (!dates) return undefined;

        return new RecentStickers(hash, packs, stickers, dates);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RecentStickers.cons];

        _data.push(this.hash);
        _data.push(this.packs);
        _data.push(this.stickers);
        _data.push(this.dates);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLLong,
        readonly packs: TLVector<API.StickerPack>,
        readonly stickers: TLVector<DocumentType>,
        readonly dates: TLVector<TLInt>
      ) {}
    }
  }

  export namespace messages {
    export class ArchivedStickers implements TLObject {
      static readonly cons = new TLInt(0x4fcba9c8);

      static deserialized(_data: ByteStream): ArchivedStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ArchivedStickers.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const sets = TLVector.deserialized(
          _data
        ) as TLVector<StickerSetCoveredType>;
        if (!sets) return undefined;

        return new ArchivedStickers(count, sets);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ArchivedStickers.cons];

        _data.push(this.count);
        _data.push(this.sets);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly sets: TLVector<StickerSetCoveredType>
      ) {}
    }
  }

  export namespace messages {
    export class StickerSetInstallResultSuccess implements TLObject {
      static readonly cons = new TLInt(0x38641628);

      static deserialized(
        _data: ByteStream
      ): StickerSetInstallResultSuccess | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(StickerSetInstallResultSuccess.cons)
        )
          return undefined;

        return new StickerSetInstallResultSuccess();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          StickerSetInstallResultSuccess.cons,
        ];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class StickerSetInstallResultArchive implements TLObject {
      static readonly cons = new TLInt(0x35e410a8);

      static deserialized(
        _data: ByteStream
      ): StickerSetInstallResultArchive | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(StickerSetInstallResultArchive.cons)
        )
          return undefined;

        const sets = TLVector.deserialized(
          _data
        ) as TLVector<StickerSetCoveredType>;
        if (!sets) return undefined;

        return new StickerSetInstallResultArchive(sets);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          StickerSetInstallResultArchive.cons,
        ];

        _data.push(this.sets);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly sets: TLVector<StickerSetCoveredType>) {}
    }
  }

  export class StickerSetCovered implements TLObject {
    static readonly cons = new TLInt(0x6410a5d2);

    static deserialized(_data: ByteStream): StickerSetCovered | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StickerSetCovered.cons))
        return undefined;

      const set = API.StickerSet.deserialized(_data);
      if (!set) return undefined;
      const cover = TLObject.deserialized(_data) as DocumentType;
      if (!cover) return undefined;

      return new StickerSetCovered(set, cover);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StickerSetCovered.cons];

      _data.push(this.set);
      _data.push(this.cover);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly set: API.StickerSet, readonly cover: DocumentType) {}
  }

  export class StickerSetMultiCovered implements TLObject {
    static readonly cons = new TLInt(0x3407e51b);

    static deserialized(_data: ByteStream): StickerSetMultiCovered | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StickerSetMultiCovered.cons))
        return undefined;

      const set = API.StickerSet.deserialized(_data);
      if (!set) return undefined;
      const covers = TLVector.deserialized(_data) as TLVector<DocumentType>;
      if (!covers) return undefined;

      return new StickerSetMultiCovered(set, covers);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StickerSetMultiCovered.cons];

      _data.push(this.set);
      _data.push(this.covers);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly set: API.StickerSet,
      readonly covers: TLVector<DocumentType>
    ) {}
  }

  export class StickerSetFullCovered implements TLObject {
    static readonly cons = new TLInt(0x40d13c0e);

    static deserialized(_data: ByteStream): StickerSetFullCovered | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StickerSetFullCovered.cons))
        return undefined;

      const set = API.StickerSet.deserialized(_data);
      if (!set) return undefined;
      const packs = TLVector.deserialized(_data, API.StickerPack);
      if (!packs) return undefined;
      const keywords = TLVector.deserialized(_data, API.StickerKeyword);
      if (!keywords) return undefined;
      const documents = TLVector.deserialized(_data) as TLVector<DocumentType>;
      if (!documents) return undefined;

      return new StickerSetFullCovered(set, packs, keywords, documents);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StickerSetFullCovered.cons];

      _data.push(this.set);
      _data.push(this.packs);
      _data.push(this.keywords);
      _data.push(this.documents);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly set: API.StickerSet,
      readonly packs: TLVector<API.StickerPack>,
      readonly keywords: TLVector<API.StickerKeyword>,
      readonly documents: TLVector<DocumentType>
    ) {}
  }

  export class StickerSetNoCovered implements TLObject {
    static readonly cons = new TLInt(0x77b15d1c);

    static deserialized(_data: ByteStream): StickerSetNoCovered | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StickerSetNoCovered.cons))
        return undefined;

      const set = API.StickerSet.deserialized(_data);
      if (!set) return undefined;

      return new StickerSetNoCovered(set);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StickerSetNoCovered.cons];

      _data.push(this.set);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly set: API.StickerSet) {}
  }

  export class MaskCoords implements TLObject {
    static readonly cons = new TLInt(0xaed6dbb2);

    static deserialized(_data: ByteStream): MaskCoords | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MaskCoords.cons))
        return undefined;

      const n = TLInt.deserialized(_data);
      if (!n) return undefined;
      const x = TLDouble.deserialized(_data);
      if (!x) return undefined;
      const y = TLDouble.deserialized(_data);
      if (!y) return undefined;
      const zoom = TLDouble.deserialized(_data);
      if (!zoom) return undefined;

      return new MaskCoords(n, x, y, zoom);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MaskCoords.cons];

      _data.push(this.n);
      _data.push(this.x);
      _data.push(this.y);
      _data.push(this.zoom);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly n: TLInt,
      readonly x: TLDouble,
      readonly y: TLDouble,
      readonly zoom: TLDouble
    ) {}
  }

  export class InputStickeredMediaPhoto implements TLObject {
    static readonly cons = new TLInt(0x4a992157);

    static deserialized(
      _data: ByteStream
    ): InputStickeredMediaPhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputStickeredMediaPhoto.cons))
        return undefined;

      const id = TLObject.deserialized(_data) as InputPhotoType;
      if (!id) return undefined;

      return new InputStickeredMediaPhoto(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputStickeredMediaPhoto.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: InputPhotoType) {}
  }

  export class InputStickeredMediaDocument implements TLObject {
    static readonly cons = new TLInt(0x438865b);

    static deserialized(
      _data: ByteStream
    ): InputStickeredMediaDocument | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputStickeredMediaDocument.cons))
        return undefined;

      const id = TLObject.deserialized(_data) as InputDocumentType;
      if (!id) return undefined;

      return new InputStickeredMediaDocument(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputStickeredMediaDocument.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: InputDocumentType) {}
  }

  export class Game implements TLObject {
    static readonly cons = new TLInt(0xbdf9653b);

    static deserialized(_data: ByteStream): Game | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Game.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const shortName = TLString.deserialized(_data);
      if (!shortName) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const description = TLString.deserialized(_data);
      if (!description) return undefined;
      const photo = TLObject.deserialized(_data) as PhotoType;
      if (!photo) return undefined;
      let document: DocumentType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as DocumentType;
        if (!obj) return undefined;
        document = obj;
      }

      return new Game(
        id,
        accessHash,
        shortName,
        title,
        description,
        photo,
        document
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Game.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.shortName);
      _data.push(this.title);
      _data.push(this.description);
      _data.push(this.photo);
      if (this.document) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.document) _data.push(this.document);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly shortName: TLString,
      readonly title: TLString,
      readonly description: TLString,
      readonly photo: PhotoType,
      readonly document: DocumentType | undefined
    ) {}
  }

  export class InputGameID implements TLObject {
    static readonly cons = new TLInt(0x32c3e77);

    static deserialized(_data: ByteStream): InputGameID | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputGameID.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputGameID(id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputGameID.cons];

      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputGameShortName implements TLObject {
    static readonly cons = new TLInt(0xc331e80a);

    static deserialized(_data: ByteStream): InputGameShortName | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputGameShortName.cons))
        return undefined;

      const botId = TLObject.deserialized(_data) as InputUserType;
      if (!botId) return undefined;
      const shortName = TLString.deserialized(_data);
      if (!shortName) return undefined;

      return new InputGameShortName(botId, shortName);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputGameShortName.cons];

      _data.push(this.botId);
      _data.push(this.shortName);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly botId: InputUserType, readonly shortName: TLString) {}
  }

  export class HighScore implements TLObject {
    static readonly cons = new TLInt(0x73a379eb);

    static deserialized(_data: ByteStream): HighScore | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(HighScore.cons)) return undefined;

      const pos = TLInt.deserialized(_data);
      if (!pos) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const score = TLInt.deserialized(_data);
      if (!score) return undefined;

      return new HighScore(pos, userId, score);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [HighScore.cons];

      _data.push(this.pos);
      _data.push(this.userId);
      _data.push(this.score);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly pos: TLInt,
      readonly userId: TLLong,
      readonly score: TLInt
    ) {}
  }

  export namespace messages {
    export class HighScores implements TLObject {
      static readonly cons = new TLInt(0x9a3bfd99);

      static deserialized(_data: ByteStream): HighScores | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(HighScores.cons))
          return undefined;

        const scores = TLVector.deserialized(_data, API.HighScore);
        if (!scores) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new HighScores(scores, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [HighScores.cons];

        _data.push(this.scores);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly scores: TLVector<API.HighScore>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class TextEmpty implements TLObject {
    static readonly cons = new TLInt(0xdc3d824f);

    static deserialized(_data: ByteStream): TextEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextEmpty.cons)) return undefined;

      return new TextEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class TextPlain implements TLObject {
    static readonly cons = new TLInt(0x744694e0);

    static deserialized(_data: ByteStream): TextPlain | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextPlain.cons)) return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new TextPlain(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextPlain.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString) {}
  }

  export class TextBold implements TLObject {
    static readonly cons = new TLInt(0x6724abc4);

    static deserialized(_data: ByteStream): TextBold | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextBold.cons)) return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new TextBold(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextBold.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class TextItalic implements TLObject {
    static readonly cons = new TLInt(0xd912a59c);

    static deserialized(_data: ByteStream): TextItalic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextItalic.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new TextItalic(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextItalic.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class TextUnderline implements TLObject {
    static readonly cons = new TLInt(0xc12622c4);

    static deserialized(_data: ByteStream): TextUnderline | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextUnderline.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new TextUnderline(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextUnderline.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class TextStrike implements TLObject {
    static readonly cons = new TLInt(0x9bf8bb95);

    static deserialized(_data: ByteStream): TextStrike | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextStrike.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new TextStrike(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextStrike.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class TextFixed implements TLObject {
    static readonly cons = new TLInt(0x6c3f19b9);

    static deserialized(_data: ByteStream): TextFixed | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextFixed.cons)) return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new TextFixed(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextFixed.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class TextUrl implements TLObject {
    static readonly cons = new TLInt(0x3c2884c1);

    static deserialized(_data: ByteStream): TextUrl | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextUrl.cons)) return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const webpageId = TLLong.deserialized(_data);
      if (!webpageId) return undefined;

      return new TextUrl(text, url, webpageId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextUrl.cons];

      _data.push(this.text);
      _data.push(this.url);
      _data.push(this.webpageId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly text: RichTextType,
      readonly url: TLString,
      readonly webpageId: TLLong
    ) {}
  }

  export class TextEmail implements TLObject {
    static readonly cons = new TLInt(0xde5a0dd6);

    static deserialized(_data: ByteStream): TextEmail | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextEmail.cons)) return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;
      const email = TLString.deserialized(_data);
      if (!email) return undefined;

      return new TextEmail(text, email);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextEmail.cons];

      _data.push(this.text);
      _data.push(this.email);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType, readonly email: TLString) {}
  }

  export class TextConcat implements TLObject {
    static readonly cons = new TLInt(0x7e6260d7);

    static deserialized(_data: ByteStream): TextConcat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextConcat.cons))
        return undefined;

      const texts = TLVector.deserialized(_data) as TLVector<RichTextType>;
      if (!texts) return undefined;

      return new TextConcat(texts);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextConcat.cons];

      _data.push(this.texts);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly texts: TLVector<RichTextType>) {}
  }

  export class TextSubscript implements TLObject {
    static readonly cons = new TLInt(0xed6a8504);

    static deserialized(_data: ByteStream): TextSubscript | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextSubscript.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new TextSubscript(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextSubscript.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class TextSuperscript implements TLObject {
    static readonly cons = new TLInt(0xc7fb5e01);

    static deserialized(_data: ByteStream): TextSuperscript | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextSuperscript.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new TextSuperscript(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextSuperscript.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class TextMarked implements TLObject {
    static readonly cons = new TLInt(0x34b8621);

    static deserialized(_data: ByteStream): TextMarked | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextMarked.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new TextMarked(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextMarked.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class TextPhone implements TLObject {
    static readonly cons = new TLInt(0x1ccb966a);

    static deserialized(_data: ByteStream): TextPhone | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextPhone.cons)) return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;
      const phone = TLString.deserialized(_data);
      if (!phone) return undefined;

      return new TextPhone(text, phone);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextPhone.cons];

      _data.push(this.text);
      _data.push(this.phone);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType, readonly phone: TLString) {}
  }

  export class TextImage implements TLObject {
    static readonly cons = new TLInt(0x81ccf4f);

    static deserialized(_data: ByteStream): TextImage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextImage.cons)) return undefined;

      const documentId = TLLong.deserialized(_data);
      if (!documentId) return undefined;
      const w = TLInt.deserialized(_data);
      if (!w) return undefined;
      const h = TLInt.deserialized(_data);
      if (!h) return undefined;

      return new TextImage(documentId, w, h);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextImage.cons];

      _data.push(this.documentId);
      _data.push(this.w);
      _data.push(this.h);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly documentId: TLLong,
      readonly w: TLInt,
      readonly h: TLInt
    ) {}
  }

  export class TextAnchor implements TLObject {
    static readonly cons = new TLInt(0x35553762);

    static deserialized(_data: ByteStream): TextAnchor | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextAnchor.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;
      const name = TLString.deserialized(_data);
      if (!name) return undefined;

      return new TextAnchor(text, name);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextAnchor.cons];

      _data.push(this.text);
      _data.push(this.name);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType, readonly name: TLString) {}
  }

  export class PageBlockUnsupported implements TLObject {
    static readonly cons = new TLInt(0x13567e8a);

    static deserialized(_data: ByteStream): PageBlockUnsupported | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockUnsupported.cons))
        return undefined;

      return new PageBlockUnsupported();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockUnsupported.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PageBlockTitle implements TLObject {
    static readonly cons = new TLInt(0x70abc3fd);

    static deserialized(_data: ByteStream): PageBlockTitle | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockTitle.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new PageBlockTitle(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockTitle.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class PageBlockSubtitle implements TLObject {
    static readonly cons = new TLInt(0x8ffa9a1f);

    static deserialized(_data: ByteStream): PageBlockSubtitle | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockSubtitle.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new PageBlockSubtitle(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockSubtitle.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class PageBlockAuthorDate implements TLObject {
    static readonly cons = new TLInt(0xbaafe5e0);

    static deserialized(_data: ByteStream): PageBlockAuthorDate | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockAuthorDate.cons))
        return undefined;

      const author = TLObject.deserialized(_data) as RichTextType;
      if (!author) return undefined;
      const publishedDate = TLInt.deserialized(_data);
      if (!publishedDate) return undefined;

      return new PageBlockAuthorDate(author, publishedDate);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockAuthorDate.cons];

      _data.push(this.author);
      _data.push(this.publishedDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly author: RichTextType, readonly publishedDate: TLInt) {}
  }

  export class PageBlockHeader implements TLObject {
    static readonly cons = new TLInt(0xbfd064ec);

    static deserialized(_data: ByteStream): PageBlockHeader | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockHeader.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new PageBlockHeader(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockHeader.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class PageBlockSubheader implements TLObject {
    static readonly cons = new TLInt(0xf12bb6e1);

    static deserialized(_data: ByteStream): PageBlockSubheader | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockSubheader.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new PageBlockSubheader(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockSubheader.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class PageBlockParagraph implements TLObject {
    static readonly cons = new TLInt(0x467a0766);

    static deserialized(_data: ByteStream): PageBlockParagraph | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockParagraph.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new PageBlockParagraph(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockParagraph.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class PageBlockPreformatted implements TLObject {
    static readonly cons = new TLInt(0xc070d93e);

    static deserialized(_data: ByteStream): PageBlockPreformatted | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockPreformatted.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;
      const language = TLString.deserialized(_data);
      if (!language) return undefined;

      return new PageBlockPreformatted(text, language);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockPreformatted.cons];

      _data.push(this.text);
      _data.push(this.language);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType, readonly language: TLString) {}
  }

  export class PageBlockFooter implements TLObject {
    static readonly cons = new TLInt(0x48870999);

    static deserialized(_data: ByteStream): PageBlockFooter | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockFooter.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new PageBlockFooter(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockFooter.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class PageBlockDivider implements TLObject {
    static readonly cons = new TLInt(0xdb20b188);

    static deserialized(_data: ByteStream): PageBlockDivider | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockDivider.cons))
        return undefined;

      return new PageBlockDivider();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockDivider.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PageBlockAnchor implements TLObject {
    static readonly cons = new TLInt(0xce0d37b0);

    static deserialized(_data: ByteStream): PageBlockAnchor | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockAnchor.cons))
        return undefined;

      const name = TLString.deserialized(_data);
      if (!name) return undefined;

      return new PageBlockAnchor(name);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockAnchor.cons];

      _data.push(this.name);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly name: TLString) {}
  }

  export class PageBlockList implements TLObject {
    static readonly cons = new TLInt(0xe4e88011);

    static deserialized(_data: ByteStream): PageBlockList | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockList.cons))
        return undefined;

      const items = TLVector.deserialized(_data) as TLVector<PageListItemType>;
      if (!items) return undefined;

      return new PageBlockList(items);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockList.cons];

      _data.push(this.items);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly items: TLVector<PageListItemType>) {}
  }

  export class PageBlockBlockquote implements TLObject {
    static readonly cons = new TLInt(0x263d7c26);

    static deserialized(_data: ByteStream): PageBlockBlockquote | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockBlockquote.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;
      const caption = TLObject.deserialized(_data) as RichTextType;
      if (!caption) return undefined;

      return new PageBlockBlockquote(text, caption);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockBlockquote.cons];

      _data.push(this.text);
      _data.push(this.caption);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType, readonly caption: RichTextType) {}
  }

  export class PageBlockPullquote implements TLObject {
    static readonly cons = new TLInt(0x4f4456d3);

    static deserialized(_data: ByteStream): PageBlockPullquote | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockPullquote.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;
      const caption = TLObject.deserialized(_data) as RichTextType;
      if (!caption) return undefined;

      return new PageBlockPullquote(text, caption);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockPullquote.cons];

      _data.push(this.text);
      _data.push(this.caption);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType, readonly caption: RichTextType) {}
  }

  export class PageBlockPhoto implements TLObject {
    static readonly cons = new TLInt(0x1759c560);

    static deserialized(_data: ByteStream): PageBlockPhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockPhoto.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const photoId = TLLong.deserialized(_data);
      if (!photoId) return undefined;
      const caption = API.PageCaption.deserialized(_data);
      if (!caption) return undefined;
      let url: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        url = obj;
      }
      let webpageId: TLLong | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        webpageId = obj;
      }

      return new PageBlockPhoto(photoId, caption, url, webpageId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockPhoto.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.photoId);
      _data.push(this.caption);
      if (this.url) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.url) _data.push(this.url);

      if (this.webpageId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.webpageId) _data.push(this.webpageId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly photoId: TLLong,
      readonly caption: API.PageCaption,
      readonly url: TLString | undefined,
      readonly webpageId: TLLong | undefined
    ) {}
  }

  export class PageBlockVideo implements TLObject {
    static readonly cons = new TLInt(0x7c8fe7b6);

    static deserialized(_data: ByteStream): PageBlockVideo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockVideo.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const autoplay = (flags.value & 1) !== 0;
      const loop = (flags.value & 2) !== 0;
      const videoId = TLLong.deserialized(_data);
      if (!videoId) return undefined;
      const caption = API.PageCaption.deserialized(_data);
      if (!caption) return undefined;

      return new PageBlockVideo(autoplay, loop, videoId, caption);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockVideo.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.autoplay) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.loop) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.videoId);
      _data.push(this.caption);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly autoplay: boolean | undefined,
      readonly loop: boolean | undefined,
      readonly videoId: TLLong,
      readonly caption: API.PageCaption
    ) {}
  }

  export class PageBlockCover implements TLObject {
    static readonly cons = new TLInt(0x39f23300);

    static deserialized(_data: ByteStream): PageBlockCover | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockCover.cons))
        return undefined;

      const cover = TLObject.deserialized(_data) as PageBlockType;
      if (!cover) return undefined;

      return new PageBlockCover(cover);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockCover.cons];

      _data.push(this.cover);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly cover: PageBlockType) {}
  }

  export class PageBlockEmbed implements TLObject {
    static readonly cons = new TLInt(0xa8718dc5);

    static deserialized(_data: ByteStream): PageBlockEmbed | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockEmbed.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const fullWidth = (flags.value & 1) !== 0;
      const allowScrolling = (flags.value & 8) !== 0;
      let url: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        url = obj;
      }
      let html: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        html = obj;
      }
      let posterPhotoId: TLLong | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        posterPhotoId = obj;
      }
      let w: TLInt | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        w = obj;
      }
      let h: TLInt | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        h = obj;
      }
      const caption = API.PageCaption.deserialized(_data);
      if (!caption) return undefined;

      return new PageBlockEmbed(
        fullWidth,
        allowScrolling,
        url,
        html,
        posterPhotoId,
        w,
        h,
        caption
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockEmbed.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.fullWidth) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.allowScrolling) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.url) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.url) _data.push(this.url);

      if (this.html) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.html) _data.push(this.html);

      if (this.posterPhotoId) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.posterPhotoId) _data.push(this.posterPhotoId);

      if (this.w) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.w) _data.push(this.w);

      if (this.h) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.h) _data.push(this.h);

      _data.push(this.caption);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly fullWidth: boolean | undefined,
      readonly allowScrolling: boolean | undefined,
      readonly url: TLString | undefined,
      readonly html: TLString | undefined,
      readonly posterPhotoId: TLLong | undefined,
      readonly w: TLInt | undefined,
      readonly h: TLInt | undefined,
      readonly caption: API.PageCaption
    ) {}
  }

  export class PageBlockEmbedPost implements TLObject {
    static readonly cons = new TLInt(0xf259a80b);

    static deserialized(_data: ByteStream): PageBlockEmbedPost | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockEmbedPost.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const webpageId = TLLong.deserialized(_data);
      if (!webpageId) return undefined;
      const authorPhotoId = TLLong.deserialized(_data);
      if (!authorPhotoId) return undefined;
      const author = TLString.deserialized(_data);
      if (!author) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const blocks = TLVector.deserialized(_data) as TLVector<PageBlockType>;
      if (!blocks) return undefined;
      const caption = API.PageCaption.deserialized(_data);
      if (!caption) return undefined;

      return new PageBlockEmbedPost(
        url,
        webpageId,
        authorPhotoId,
        author,
        date,
        blocks,
        caption
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockEmbedPost.cons];

      _data.push(this.url);
      _data.push(this.webpageId);
      _data.push(this.authorPhotoId);
      _data.push(this.author);
      _data.push(this.date);
      _data.push(this.blocks);
      _data.push(this.caption);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly url: TLString,
      readonly webpageId: TLLong,
      readonly authorPhotoId: TLLong,
      readonly author: TLString,
      readonly date: TLInt,
      readonly blocks: TLVector<PageBlockType>,
      readonly caption: API.PageCaption
    ) {}
  }

  export class PageBlockCollage implements TLObject {
    static readonly cons = new TLInt(0x65a0fa4d);

    static deserialized(_data: ByteStream): PageBlockCollage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockCollage.cons))
        return undefined;

      const items = TLVector.deserialized(_data) as TLVector<PageBlockType>;
      if (!items) return undefined;
      const caption = API.PageCaption.deserialized(_data);
      if (!caption) return undefined;

      return new PageBlockCollage(items, caption);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockCollage.cons];

      _data.push(this.items);
      _data.push(this.caption);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly items: TLVector<PageBlockType>,
      readonly caption: API.PageCaption
    ) {}
  }

  export class PageBlockSlideshow implements TLObject {
    static readonly cons = new TLInt(0x31f9590);

    static deserialized(_data: ByteStream): PageBlockSlideshow | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockSlideshow.cons))
        return undefined;

      const items = TLVector.deserialized(_data) as TLVector<PageBlockType>;
      if (!items) return undefined;
      const caption = API.PageCaption.deserialized(_data);
      if (!caption) return undefined;

      return new PageBlockSlideshow(items, caption);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockSlideshow.cons];

      _data.push(this.items);
      _data.push(this.caption);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly items: TLVector<PageBlockType>,
      readonly caption: API.PageCaption
    ) {}
  }

  export class PageBlockChannel implements TLObject {
    static readonly cons = new TLInt(0xef1751b5);

    static deserialized(_data: ByteStream): PageBlockChannel | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockChannel.cons))
        return undefined;

      const channel = TLObject.deserialized(_data) as ChatType;
      if (!channel) return undefined;

      return new PageBlockChannel(channel);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockChannel.cons];

      _data.push(this.channel);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly channel: ChatType) {}
  }

  export class PageBlockAudio implements TLObject {
    static readonly cons = new TLInt(0x804361ea);

    static deserialized(_data: ByteStream): PageBlockAudio | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockAudio.cons))
        return undefined;

      const audioId = TLLong.deserialized(_data);
      if (!audioId) return undefined;
      const caption = API.PageCaption.deserialized(_data);
      if (!caption) return undefined;

      return new PageBlockAudio(audioId, caption);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockAudio.cons];

      _data.push(this.audioId);
      _data.push(this.caption);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly audioId: TLLong, readonly caption: API.PageCaption) {}
  }

  export class PageBlockKicker implements TLObject {
    static readonly cons = new TLInt(0x1e148390);

    static deserialized(_data: ByteStream): PageBlockKicker | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockKicker.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new PageBlockKicker(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockKicker.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class PageBlockTable implements TLObject {
    static readonly cons = new TLInt(0xbf4dea82);

    static deserialized(_data: ByteStream): PageBlockTable | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockTable.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const bordered = (flags.value & 1) !== 0;
      const striped = (flags.value & 2) !== 0;
      const title = TLObject.deserialized(_data) as RichTextType;
      if (!title) return undefined;
      const rows = TLVector.deserialized(_data, API.PageTableRow);
      if (!rows) return undefined;

      return new PageBlockTable(bordered, striped, title, rows);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockTable.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.bordered) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.striped) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.title);
      _data.push(this.rows);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly bordered: boolean | undefined,
      readonly striped: boolean | undefined,
      readonly title: RichTextType,
      readonly rows: TLVector<API.PageTableRow>
    ) {}
  }

  export class PageBlockOrderedList implements TLObject {
    static readonly cons = new TLInt(0x9a8ae1e1);

    static deserialized(_data: ByteStream): PageBlockOrderedList | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockOrderedList.cons))
        return undefined;

      const items = TLVector.deserialized(
        _data
      ) as TLVector<PageListOrderedItemType>;
      if (!items) return undefined;

      return new PageBlockOrderedList(items);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockOrderedList.cons];

      _data.push(this.items);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly items: TLVector<PageListOrderedItemType>) {}
  }

  export class PageBlockDetails implements TLObject {
    static readonly cons = new TLInt(0x76768bed);

    static deserialized(_data: ByteStream): PageBlockDetails | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockDetails.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const open = (flags.value & 1) !== 0;
      const blocks = TLVector.deserialized(_data) as TLVector<PageBlockType>;
      if (!blocks) return undefined;
      const title = TLObject.deserialized(_data) as RichTextType;
      if (!title) return undefined;

      return new PageBlockDetails(open, blocks, title);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockDetails.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.open) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.blocks);
      _data.push(this.title);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly open: boolean | undefined,
      readonly blocks: TLVector<PageBlockType>,
      readonly title: RichTextType
    ) {}
  }

  export class PageBlockRelatedArticles implements TLObject {
    static readonly cons = new TLInt(0x16115a96);

    static deserialized(
      _data: ByteStream
    ): PageBlockRelatedArticles | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockRelatedArticles.cons))
        return undefined;

      const title = TLObject.deserialized(_data) as RichTextType;
      if (!title) return undefined;
      const articles = TLVector.deserialized(_data, API.PageRelatedArticle);
      if (!articles) return undefined;

      return new PageBlockRelatedArticles(title, articles);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockRelatedArticles.cons];

      _data.push(this.title);
      _data.push(this.articles);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly title: RichTextType,
      readonly articles: TLVector<API.PageRelatedArticle>
    ) {}
  }

  export class PageBlockMap implements TLObject {
    static readonly cons = new TLInt(0xa44f3ef6);

    static deserialized(_data: ByteStream): PageBlockMap | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageBlockMap.cons))
        return undefined;

      const geo = TLObject.deserialized(_data) as GeoPointType;
      if (!geo) return undefined;
      const zoom = TLInt.deserialized(_data);
      if (!zoom) return undefined;
      const w = TLInt.deserialized(_data);
      if (!w) return undefined;
      const h = TLInt.deserialized(_data);
      if (!h) return undefined;
      const caption = API.PageCaption.deserialized(_data);
      if (!caption) return undefined;

      return new PageBlockMap(geo, zoom, w, h, caption);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageBlockMap.cons];

      _data.push(this.geo);
      _data.push(this.zoom);
      _data.push(this.w);
      _data.push(this.h);
      _data.push(this.caption);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly geo: GeoPointType,
      readonly zoom: TLInt,
      readonly w: TLInt,
      readonly h: TLInt,
      readonly caption: API.PageCaption
    ) {}
  }

  export class PhoneCallDiscardReasonMissed implements TLObject {
    static readonly cons = new TLInt(0x85e42301);

    static deserialized(
      _data: ByteStream
    ): PhoneCallDiscardReasonMissed | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(PhoneCallDiscardReasonMissed.cons)
      )
        return undefined;

      return new PhoneCallDiscardReasonMissed();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneCallDiscardReasonMissed.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PhoneCallDiscardReasonDisconnect implements TLObject {
    static readonly cons = new TLInt(0xe095c1a0);

    static deserialized(
      _data: ByteStream
    ): PhoneCallDiscardReasonDisconnect | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(PhoneCallDiscardReasonDisconnect.cons)
      )
        return undefined;

      return new PhoneCallDiscardReasonDisconnect();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        PhoneCallDiscardReasonDisconnect.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PhoneCallDiscardReasonHangup implements TLObject {
    static readonly cons = new TLInt(0x57adc690);

    static deserialized(
      _data: ByteStream
    ): PhoneCallDiscardReasonHangup | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(PhoneCallDiscardReasonHangup.cons)
      )
        return undefined;

      return new PhoneCallDiscardReasonHangup();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneCallDiscardReasonHangup.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PhoneCallDiscardReasonBusy implements TLObject {
    static readonly cons = new TLInt(0xfaf7e8c9);

    static deserialized(
      _data: ByteStream
    ): PhoneCallDiscardReasonBusy | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhoneCallDiscardReasonBusy.cons))
        return undefined;

      return new PhoneCallDiscardReasonBusy();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneCallDiscardReasonBusy.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class DataJSON implements TLObject {
    static readonly cons = new TLInt(0x7d748d04);

    static deserialized(_data: ByteStream): DataJSON | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DataJSON.cons)) return undefined;

      const data = TLString.deserialized(_data);
      if (!data) return undefined;

      return new DataJSON(data);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DataJSON.cons];

      _data.push(this.data);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly data: TLString) {}
  }

  export class LabeledPrice implements TLObject {
    static readonly cons = new TLInt(0xcb296bf8);

    static deserialized(_data: ByteStream): LabeledPrice | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(LabeledPrice.cons))
        return undefined;

      const label = TLString.deserialized(_data);
      if (!label) return undefined;
      const amount = TLLong.deserialized(_data);
      if (!amount) return undefined;

      return new LabeledPrice(label, amount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [LabeledPrice.cons];

      _data.push(this.label);
      _data.push(this.amount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly label: TLString, readonly amount: TLLong) {}
  }

  export class Invoice implements TLObject {
    static readonly cons = new TLInt(0x5db95a15);

    static deserialized(_data: ByteStream): Invoice | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Invoice.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const test = (flags.value & 1) !== 0;
      const nameRequested = (flags.value & 2) !== 0;
      const phoneRequested = (flags.value & 4) !== 0;
      const emailRequested = (flags.value & 8) !== 0;
      const shippingAddressRequested = (flags.value & 16) !== 0;
      const flexible = (flags.value & 32) !== 0;
      const phoneToProvider = (flags.value & 64) !== 0;
      const emailToProvider = (flags.value & 128) !== 0;
      const recurring = (flags.value & 512) !== 0;
      const currency = TLString.deserialized(_data);
      if (!currency) return undefined;
      const prices = TLVector.deserialized(_data, API.LabeledPrice);
      if (!prices) return undefined;
      let maxTipAmount: TLLong | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        maxTipAmount = obj;
      }
      let suggestedTipAmounts: TLVector<TLLong> | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLVector.deserialized(_data, TLLong);
        if (!obj) return undefined;
        suggestedTipAmounts = obj;
      }
      let termsUrl: TLString | undefined;
      if ((flags.value & 1024) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        termsUrl = obj;
      }

      return new Invoice(
        test,
        nameRequested,
        phoneRequested,
        emailRequested,
        shippingAddressRequested,
        flexible,
        phoneToProvider,
        emailToProvider,
        recurring,
        currency,
        prices,
        maxTipAmount,
        suggestedTipAmounts,
        termsUrl
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Invoice.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.test) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.nameRequested) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.phoneRequested) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.emailRequested) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.shippingAddressRequested) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.flexible) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.phoneToProvider) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      if (this.emailToProvider) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.recurring) {
        flags.set(512);
      } else {
        flags.unset(512);
      }

      _data.push(this.currency);
      _data.push(this.prices);
      if (this.maxTipAmount) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.maxTipAmount) _data.push(this.maxTipAmount);

      if (this.suggestedTipAmounts) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.suggestedTipAmounts) _data.push(this.suggestedTipAmounts);

      if (this.termsUrl) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }
      if (this.termsUrl) _data.push(this.termsUrl);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly test: boolean | undefined,
      readonly nameRequested: boolean | undefined,
      readonly phoneRequested: boolean | undefined,
      readonly emailRequested: boolean | undefined,
      readonly shippingAddressRequested: boolean | undefined,
      readonly flexible: boolean | undefined,
      readonly phoneToProvider: boolean | undefined,
      readonly emailToProvider: boolean | undefined,
      readonly recurring: boolean | undefined,
      readonly currency: TLString,
      readonly prices: TLVector<API.LabeledPrice>,
      readonly maxTipAmount: TLLong | undefined,
      readonly suggestedTipAmounts: TLVector<TLLong> | undefined,
      readonly termsUrl: TLString | undefined
    ) {}
  }

  export class PaymentCharge implements TLObject {
    static readonly cons = new TLInt(0xea02c27e);

    static deserialized(_data: ByteStream): PaymentCharge | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PaymentCharge.cons))
        return undefined;

      const id = TLString.deserialized(_data);
      if (!id) return undefined;
      const providerChargeId = TLString.deserialized(_data);
      if (!providerChargeId) return undefined;

      return new PaymentCharge(id, providerChargeId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PaymentCharge.cons];

      _data.push(this.id);
      _data.push(this.providerChargeId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLString, readonly providerChargeId: TLString) {}
  }

  export class PostAddress implements TLObject {
    static readonly cons = new TLInt(0x1e8caaeb);

    static deserialized(_data: ByteStream): PostAddress | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PostAddress.cons))
        return undefined;

      const streetLine1 = TLString.deserialized(_data);
      if (!streetLine1) return undefined;
      const streetLine2 = TLString.deserialized(_data);
      if (!streetLine2) return undefined;
      const city = TLString.deserialized(_data);
      if (!city) return undefined;
      const state = TLString.deserialized(_data);
      if (!state) return undefined;
      const countryIso2 = TLString.deserialized(_data);
      if (!countryIso2) return undefined;
      const postCode = TLString.deserialized(_data);
      if (!postCode) return undefined;

      return new PostAddress(
        streetLine1,
        streetLine2,
        city,
        state,
        countryIso2,
        postCode
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PostAddress.cons];

      _data.push(this.streetLine1);
      _data.push(this.streetLine2);
      _data.push(this.city);
      _data.push(this.state);
      _data.push(this.countryIso2);
      _data.push(this.postCode);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly streetLine1: TLString,
      readonly streetLine2: TLString,
      readonly city: TLString,
      readonly state: TLString,
      readonly countryIso2: TLString,
      readonly postCode: TLString
    ) {}
  }

  export class PaymentRequestedInfo implements TLObject {
    static readonly cons = new TLInt(0x909c3f94);

    static deserialized(_data: ByteStream): PaymentRequestedInfo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PaymentRequestedInfo.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let name: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        name = obj;
      }
      let phone: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        phone = obj;
      }
      let email: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        email = obj;
      }
      let shippingAddress: API.PostAddress | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as API.PostAddress;
        if (!obj) return undefined;
        shippingAddress = obj;
      }

      return new PaymentRequestedInfo(name, phone, email, shippingAddress);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PaymentRequestedInfo.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.name) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.name) _data.push(this.name);

      if (this.phone) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.phone) _data.push(this.phone);

      if (this.email) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.email) _data.push(this.email);

      if (this.shippingAddress) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.shippingAddress) _data.push(this.shippingAddress);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly name: TLString | undefined,
      readonly phone: TLString | undefined,
      readonly email: TLString | undefined,
      readonly shippingAddress: API.PostAddress | undefined
    ) {}
  }

  export class PaymentSavedCredentialsCard implements TLObject {
    static readonly cons = new TLInt(0xcdc27a1f);

    static deserialized(
      _data: ByteStream
    ): PaymentSavedCredentialsCard | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PaymentSavedCredentialsCard.cons))
        return undefined;

      const id = TLString.deserialized(_data);
      if (!id) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;

      return new PaymentSavedCredentialsCard(id, title);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PaymentSavedCredentialsCard.cons];

      _data.push(this.id);
      _data.push(this.title);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLString, readonly title: TLString) {}
  }

  export class WebDocument implements TLObject {
    static readonly cons = new TLInt(0x1c570ed1);

    static deserialized(_data: ByteStream): WebDocument | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WebDocument.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const size = TLInt.deserialized(_data);
      if (!size) return undefined;
      const mimeType = TLString.deserialized(_data);
      if (!mimeType) return undefined;
      const attributes = TLVector.deserialized(
        _data
      ) as TLVector<DocumentAttributeType>;
      if (!attributes) return undefined;

      return new WebDocument(url, accessHash, size, mimeType, attributes);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WebDocument.cons];

      _data.push(this.url);
      _data.push(this.accessHash);
      _data.push(this.size);
      _data.push(this.mimeType);
      _data.push(this.attributes);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly url: TLString,
      readonly accessHash: TLLong,
      readonly size: TLInt,
      readonly mimeType: TLString,
      readonly attributes: TLVector<DocumentAttributeType>
    ) {}
  }

  export class WebDocumentNoProxy implements TLObject {
    static readonly cons = new TLInt(0xf9c8bcc6);

    static deserialized(_data: ByteStream): WebDocumentNoProxy | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WebDocumentNoProxy.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const size = TLInt.deserialized(_data);
      if (!size) return undefined;
      const mimeType = TLString.deserialized(_data);
      if (!mimeType) return undefined;
      const attributes = TLVector.deserialized(
        _data
      ) as TLVector<DocumentAttributeType>;
      if (!attributes) return undefined;

      return new WebDocumentNoProxy(url, size, mimeType, attributes);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WebDocumentNoProxy.cons];

      _data.push(this.url);
      _data.push(this.size);
      _data.push(this.mimeType);
      _data.push(this.attributes);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly url: TLString,
      readonly size: TLInt,
      readonly mimeType: TLString,
      readonly attributes: TLVector<DocumentAttributeType>
    ) {}
  }

  export class InputWebDocument implements TLObject {
    static readonly cons = new TLInt(0x9bed434d);

    static deserialized(_data: ByteStream): InputWebDocument | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputWebDocument.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const size = TLInt.deserialized(_data);
      if (!size) return undefined;
      const mimeType = TLString.deserialized(_data);
      if (!mimeType) return undefined;
      const attributes = TLVector.deserialized(
        _data
      ) as TLVector<DocumentAttributeType>;
      if (!attributes) return undefined;

      return new InputWebDocument(url, size, mimeType, attributes);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputWebDocument.cons];

      _data.push(this.url);
      _data.push(this.size);
      _data.push(this.mimeType);
      _data.push(this.attributes);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly url: TLString,
      readonly size: TLInt,
      readonly mimeType: TLString,
      readonly attributes: TLVector<DocumentAttributeType>
    ) {}
  }

  export class InputWebFileLocation implements TLObject {
    static readonly cons = new TLInt(0xc239d686);

    static deserialized(_data: ByteStream): InputWebFileLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputWebFileLocation.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputWebFileLocation(url, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputWebFileLocation.cons];

      _data.push(this.url);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString, readonly accessHash: TLLong) {}
  }

  export class InputWebFileGeoPointLocation implements TLObject {
    static readonly cons = new TLInt(0x9f2221c9);

    static deserialized(
      _data: ByteStream
    ): InputWebFileGeoPointLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputWebFileGeoPointLocation.cons)
      )
        return undefined;

      const geoPoint = TLObject.deserialized(_data) as InputGeoPointType;
      if (!geoPoint) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const w = TLInt.deserialized(_data);
      if (!w) return undefined;
      const h = TLInt.deserialized(_data);
      if (!h) return undefined;
      const zoom = TLInt.deserialized(_data);
      if (!zoom) return undefined;
      const scale = TLInt.deserialized(_data);
      if (!scale) return undefined;

      return new InputWebFileGeoPointLocation(
        geoPoint,
        accessHash,
        w,
        h,
        zoom,
        scale
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputWebFileGeoPointLocation.cons];

      _data.push(this.geoPoint);
      _data.push(this.accessHash);
      _data.push(this.w);
      _data.push(this.h);
      _data.push(this.zoom);
      _data.push(this.scale);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly geoPoint: InputGeoPointType,
      readonly accessHash: TLLong,
      readonly w: TLInt,
      readonly h: TLInt,
      readonly zoom: TLInt,
      readonly scale: TLInt
    ) {}
  }

  export class InputWebFileAudioAlbumThumbLocation implements TLObject {
    static readonly cons = new TLInt(0xf46fe924);

    static deserialized(
      _data: ByteStream
    ): InputWebFileAudioAlbumThumbLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputWebFileAudioAlbumThumbLocation.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const small = (flags.value & 4) !== 0;
      let document: InputDocumentType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as InputDocumentType;
        if (!obj) return undefined;
        document = obj;
      }
      let title: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        title = obj;
      }
      let performer: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        performer = obj;
      }

      return new InputWebFileAudioAlbumThumbLocation(
        small,
        document,
        title,
        performer
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputWebFileAudioAlbumThumbLocation.cons,
      ];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.small) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.document) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.document) _data.push(this.document);

      if (this.title) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.title) _data.push(this.title);

      if (this.performer) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.performer) _data.push(this.performer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly small: boolean | undefined,
      readonly document: InputDocumentType | undefined,
      readonly title: TLString | undefined,
      readonly performer: TLString | undefined
    ) {}
  }

  export namespace upload {
    export class WebFile implements TLObject {
      static readonly cons = new TLInt(0x21e753bc);

      static deserialized(_data: ByteStream): WebFile | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(WebFile.cons)) return undefined;

        const size = TLInt.deserialized(_data);
        if (!size) return undefined;
        const mimeType = TLString.deserialized(_data);
        if (!mimeType) return undefined;
        const fileType = TLObject.deserialized(_data) as storage.FileTypeType;
        if (!fileType) return undefined;
        const mtime = TLInt.deserialized(_data);
        if (!mtime) return undefined;
        const bytes = TLBytes.deserialized(_data);
        if (!bytes) return undefined;

        return new WebFile(size, mimeType, fileType, mtime, bytes);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [WebFile.cons];

        _data.push(this.size);
        _data.push(this.mimeType);
        _data.push(this.fileType);
        _data.push(this.mtime);
        _data.push(this.bytes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly size: TLInt,
        readonly mimeType: TLString,
        readonly fileType: storage.FileTypeType,
        readonly mtime: TLInt,
        readonly bytes: TLBytes
      ) {}
    }
  }

  export namespace payments {
    export class PaymentForm implements TLObject {
      static readonly cons = new TLInt(0xa0058751);

      static deserialized(_data: ByteStream): PaymentForm | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PaymentForm.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const canSaveCredentials = (flags.value & 4) !== 0;
        const passwordMissing = (flags.value & 8) !== 0;
        const formId = TLLong.deserialized(_data);
        if (!formId) return undefined;
        const botId = TLLong.deserialized(_data);
        if (!botId) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;
        const description = TLString.deserialized(_data);
        if (!description) return undefined;
        let photo: WebDocumentType | undefined;
        if ((flags.value & 32) !== 0) {
          const obj = TLObject.deserialized(_data) as WebDocumentType;
          if (!obj) return undefined;
          photo = obj;
        }
        const invoice = API.Invoice.deserialized(_data);
        if (!invoice) return undefined;
        const providerId = TLLong.deserialized(_data);
        if (!providerId) return undefined;
        const url = TLString.deserialized(_data);
        if (!url) return undefined;
        let nativeProvider: TLString | undefined;
        if ((flags.value & 16) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          nativeProvider = obj;
        }
        let nativeParams: API.DataJSON | undefined;
        if ((flags.value & 16) !== 0) {
          const obj = TLObject.deserialized(_data) as API.DataJSON;
          if (!obj) return undefined;
          nativeParams = obj;
        }
        let additionalMethods: TLVector<API.PaymentFormMethod> | undefined;
        if ((flags.value & 64) !== 0) {
          const obj = TLVector.deserialized(_data, API.PaymentFormMethod);
          if (!obj) return undefined;
          additionalMethods = obj;
        }
        let savedInfo: API.PaymentRequestedInfo | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as API.PaymentRequestedInfo;
          if (!obj) return undefined;
          savedInfo = obj;
        }
        let savedCredentials: TLVector<PaymentSavedCredentialsType> | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLVector.deserialized(
            _data
          ) as TLVector<PaymentSavedCredentialsType>;
          if (!obj) return undefined;
          savedCredentials = obj;
        }
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new PaymentForm(
          canSaveCredentials,
          passwordMissing,
          formId,
          botId,
          title,
          description,
          photo,
          invoice,
          providerId,
          url,
          nativeProvider,
          nativeParams,
          additionalMethods,
          savedInfo,
          savedCredentials,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PaymentForm.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.canSaveCredentials) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        if (this.passwordMissing) {
          flags.set(8);
        } else {
          flags.unset(8);
        }

        _data.push(this.formId);
        _data.push(this.botId);
        _data.push(this.title);
        _data.push(this.description);
        if (this.photo) {
          flags.set(32);
        } else {
          flags.unset(32);
        }
        if (this.photo) _data.push(this.photo);

        _data.push(this.invoice);
        _data.push(this.providerId);
        _data.push(this.url);
        if (this.nativeProvider) {
          flags.set(16);
        } else {
          flags.unset(16);
        }
        if (this.nativeProvider) _data.push(this.nativeProvider);

        if (this.nativeParams) {
          flags.set(16);
        } else {
          flags.unset(16);
        }
        if (this.nativeParams) _data.push(this.nativeParams);

        if (this.additionalMethods) {
          flags.set(64);
        } else {
          flags.unset(64);
        }
        if (this.additionalMethods) _data.push(this.additionalMethods);

        if (this.savedInfo) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.savedInfo) _data.push(this.savedInfo);

        if (this.savedCredentials) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.savedCredentials) _data.push(this.savedCredentials);

        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly canSaveCredentials: boolean | undefined,
        readonly passwordMissing: boolean | undefined,
        readonly formId: TLLong,
        readonly botId: TLLong,
        readonly title: TLString,
        readonly description: TLString,
        readonly photo: WebDocumentType | undefined,
        readonly invoice: API.Invoice,
        readonly providerId: TLLong,
        readonly url: TLString,
        readonly nativeProvider: TLString | undefined,
        readonly nativeParams: API.DataJSON | undefined,
        readonly additionalMethods: TLVector<API.PaymentFormMethod> | undefined,
        readonly savedInfo: API.PaymentRequestedInfo | undefined,
        readonly savedCredentials:
          | TLVector<PaymentSavedCredentialsType>
          | undefined,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace payments {
    export class ValidatedRequestedInfo implements TLObject {
      static readonly cons = new TLInt(0xd1451883);

      static deserialized(
        _data: ByteStream
      ): ValidatedRequestedInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ValidatedRequestedInfo.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let id: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          id = obj;
        }
        let shippingOptions: TLVector<API.ShippingOption> | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLVector.deserialized(_data, API.ShippingOption);
          if (!obj) return undefined;
          shippingOptions = obj;
        }

        return new ValidatedRequestedInfo(id, shippingOptions);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ValidatedRequestedInfo.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.id) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.id) _data.push(this.id);

        if (this.shippingOptions) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.shippingOptions) _data.push(this.shippingOptions);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly id: TLString | undefined,
        readonly shippingOptions: TLVector<API.ShippingOption> | undefined
      ) {}
    }
  }

  export namespace payments {
    export class PaymentResult implements TLObject {
      static readonly cons = new TLInt(0x4e5f810d);

      static deserialized(_data: ByteStream): PaymentResult | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PaymentResult.cons))
          return undefined;

        const updates = TLObject.deserialized(_data) as UpdatesType;
        if (!updates) return undefined;

        return new PaymentResult(updates);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PaymentResult.cons];

        _data.push(this.updates);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly updates: UpdatesType) {}
    }
  }

  export namespace payments {
    export class PaymentVerificationNeeded implements TLObject {
      static readonly cons = new TLInt(0xd8411139);

      static deserialized(
        _data: ByteStream
      ): PaymentVerificationNeeded | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PaymentVerificationNeeded.cons))
          return undefined;

        const url = TLString.deserialized(_data);
        if (!url) return undefined;

        return new PaymentVerificationNeeded(url);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PaymentVerificationNeeded.cons];

        _data.push(this.url);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly url: TLString) {}
    }
  }

  export namespace payments {
    export class PaymentReceipt implements TLObject {
      static readonly cons = new TLInt(0x70c4fe03);

      static deserialized(_data: ByteStream): PaymentReceipt | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PaymentReceipt.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const date = TLInt.deserialized(_data);
        if (!date) return undefined;
        const botId = TLLong.deserialized(_data);
        if (!botId) return undefined;
        const providerId = TLLong.deserialized(_data);
        if (!providerId) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;
        const description = TLString.deserialized(_data);
        if (!description) return undefined;
        let photo: WebDocumentType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as WebDocumentType;
          if (!obj) return undefined;
          photo = obj;
        }
        const invoice = API.Invoice.deserialized(_data);
        if (!invoice) return undefined;
        let info: API.PaymentRequestedInfo | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as API.PaymentRequestedInfo;
          if (!obj) return undefined;
          info = obj;
        }
        let shipping: API.ShippingOption | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as API.ShippingOption;
          if (!obj) return undefined;
          shipping = obj;
        }
        let tipAmount: TLLong | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLLong.deserialized(_data);
          if (!obj) return undefined;
          tipAmount = obj;
        }
        const currency = TLString.deserialized(_data);
        if (!currency) return undefined;
        const totalAmount = TLLong.deserialized(_data);
        if (!totalAmount) return undefined;
        const credentialsTitle = TLString.deserialized(_data);
        if (!credentialsTitle) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new PaymentReceipt(
          date,
          botId,
          providerId,
          title,
          description,
          photo,
          invoice,
          info,
          shipping,
          tipAmount,
          currency,
          totalAmount,
          credentialsTitle,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PaymentReceipt.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.date);
        _data.push(this.botId);
        _data.push(this.providerId);
        _data.push(this.title);
        _data.push(this.description);
        if (this.photo) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.photo) _data.push(this.photo);

        _data.push(this.invoice);
        if (this.info) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.info) _data.push(this.info);

        if (this.shipping) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.shipping) _data.push(this.shipping);

        if (this.tipAmount) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.tipAmount) _data.push(this.tipAmount);

        _data.push(this.currency);
        _data.push(this.totalAmount);
        _data.push(this.credentialsTitle);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly date: TLInt,
        readonly botId: TLLong,
        readonly providerId: TLLong,
        readonly title: TLString,
        readonly description: TLString,
        readonly photo: WebDocumentType | undefined,
        readonly invoice: API.Invoice,
        readonly info: API.PaymentRequestedInfo | undefined,
        readonly shipping: API.ShippingOption | undefined,
        readonly tipAmount: TLLong | undefined,
        readonly currency: TLString,
        readonly totalAmount: TLLong,
        readonly credentialsTitle: TLString,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace payments {
    export class SavedInfo implements TLObject {
      static readonly cons = new TLInt(0xfb8fe43c);

      static deserialized(_data: ByteStream): SavedInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SavedInfo.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const hasSavedCredentials = (flags.value & 2) !== 0;
        let savedInfo: API.PaymentRequestedInfo | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as API.PaymentRequestedInfo;
          if (!obj) return undefined;
          savedInfo = obj;
        }

        return new SavedInfo(hasSavedCredentials, savedInfo);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SavedInfo.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.hasSavedCredentials) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.savedInfo) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.savedInfo) _data.push(this.savedInfo);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hasSavedCredentials: boolean | undefined,
        readonly savedInfo: API.PaymentRequestedInfo | undefined
      ) {}
    }
  }

  export class InputPaymentCredentialsSaved implements TLObject {
    static readonly cons = new TLInt(0xc10eb2cf);

    static deserialized(
      _data: ByteStream
    ): InputPaymentCredentialsSaved | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPaymentCredentialsSaved.cons)
      )
        return undefined;

      const id = TLString.deserialized(_data);
      if (!id) return undefined;
      const tmpPassword = TLBytes.deserialized(_data);
      if (!tmpPassword) return undefined;

      return new InputPaymentCredentialsSaved(id, tmpPassword);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPaymentCredentialsSaved.cons];

      _data.push(this.id);
      _data.push(this.tmpPassword);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLString, readonly tmpPassword: TLBytes) {}
  }

  export class InputPaymentCredentials implements TLObject {
    static readonly cons = new TLInt(0x3417d728);

    static deserialized(
      _data: ByteStream
    ): InputPaymentCredentials | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPaymentCredentials.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const save = (flags.value & 1) !== 0;
      const data = API.DataJSON.deserialized(_data);
      if (!data) return undefined;

      return new InputPaymentCredentials(save, data);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPaymentCredentials.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.save) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.data);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly save: boolean | undefined,
      readonly data: API.DataJSON
    ) {}
  }

  export class InputPaymentCredentialsApplePay implements TLObject {
    static readonly cons = new TLInt(0xaa1c39f);

    static deserialized(
      _data: ByteStream
    ): InputPaymentCredentialsApplePay | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPaymentCredentialsApplePay.cons)
      )
        return undefined;

      const paymentData = API.DataJSON.deserialized(_data);
      if (!paymentData) return undefined;

      return new InputPaymentCredentialsApplePay(paymentData);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputPaymentCredentialsApplePay.cons,
      ];

      _data.push(this.paymentData);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly paymentData: API.DataJSON) {}
  }

  export class InputPaymentCredentialsGooglePay implements TLObject {
    static readonly cons = new TLInt(0x8ac32801);

    static deserialized(
      _data: ByteStream
    ): InputPaymentCredentialsGooglePay | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputPaymentCredentialsGooglePay.cons)
      )
        return undefined;

      const paymentToken = API.DataJSON.deserialized(_data);
      if (!paymentToken) return undefined;

      return new InputPaymentCredentialsGooglePay(paymentToken);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputPaymentCredentialsGooglePay.cons,
      ];

      _data.push(this.paymentToken);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly paymentToken: API.DataJSON) {}
  }

  export namespace account {
    export class TmpPassword implements TLObject {
      static readonly cons = new TLInt(0xdb64fd34);

      static deserialized(_data: ByteStream): TmpPassword | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TmpPassword.cons))
          return undefined;

        const tmpPassword = TLBytes.deserialized(_data);
        if (!tmpPassword) return undefined;
        const validUntil = TLInt.deserialized(_data);
        if (!validUntil) return undefined;

        return new TmpPassword(tmpPassword, validUntil);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TmpPassword.cons];

        _data.push(this.tmpPassword);
        _data.push(this.validUntil);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly tmpPassword: TLBytes, readonly validUntil: TLInt) {}
    }
  }

  export class ShippingOption implements TLObject {
    static readonly cons = new TLInt(0xb6213cdf);

    static deserialized(_data: ByteStream): ShippingOption | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ShippingOption.cons))
        return undefined;

      const id = TLString.deserialized(_data);
      if (!id) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const prices = TLVector.deserialized(_data, API.LabeledPrice);
      if (!prices) return undefined;

      return new ShippingOption(id, title, prices);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ShippingOption.cons];

      _data.push(this.id);
      _data.push(this.title);
      _data.push(this.prices);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLString,
      readonly title: TLString,
      readonly prices: TLVector<API.LabeledPrice>
    ) {}
  }

  export class InputStickerSetItem implements TLObject {
    static readonly cons = new TLInt(0x32da9e9c);

    static deserialized(_data: ByteStream): InputStickerSetItem | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputStickerSetItem.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const document = TLObject.deserialized(_data) as InputDocumentType;
      if (!document) return undefined;
      const emoji = TLString.deserialized(_data);
      if (!emoji) return undefined;
      let maskCoords: API.MaskCoords | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as API.MaskCoords;
        if (!obj) return undefined;
        maskCoords = obj;
      }
      let keywords: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        keywords = obj;
      }

      return new InputStickerSetItem(document, emoji, maskCoords, keywords);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputStickerSetItem.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.document);
      _data.push(this.emoji);
      if (this.maskCoords) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.maskCoords) _data.push(this.maskCoords);

      if (this.keywords) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.keywords) _data.push(this.keywords);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly document: InputDocumentType,
      readonly emoji: TLString,
      readonly maskCoords: API.MaskCoords | undefined,
      readonly keywords: TLString | undefined
    ) {}
  }

  export class InputPhoneCall implements TLObject {
    static readonly cons = new TLInt(0x1e36fded);

    static deserialized(_data: ByteStream): InputPhoneCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputPhoneCall.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputPhoneCall(id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputPhoneCall.cons];

      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly accessHash: TLLong) {}
  }

  export class PhoneCallEmpty implements TLObject {
    static readonly cons = new TLInt(0x5366c915);

    static deserialized(_data: ByteStream): PhoneCallEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhoneCallEmpty.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;

      return new PhoneCallEmpty(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneCallEmpty.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong) {}
  }

  export class PhoneCallWaiting implements TLObject {
    static readonly cons = new TLInt(0xc5226f17);

    static deserialized(_data: ByteStream): PhoneCallWaiting | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhoneCallWaiting.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const video = (flags.value & 64) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const adminId = TLLong.deserialized(_data);
      if (!adminId) return undefined;
      const participantId = TLLong.deserialized(_data);
      if (!participantId) return undefined;
      const protocol = API.PhoneCallProtocol.deserialized(_data);
      if (!protocol) return undefined;
      let receiveDate: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        receiveDate = obj;
      }

      return new PhoneCallWaiting(
        video,
        id,
        accessHash,
        date,
        adminId,
        participantId,
        protocol,
        receiveDate
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneCallWaiting.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.video) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.date);
      _data.push(this.adminId);
      _data.push(this.participantId);
      _data.push(this.protocol);
      if (this.receiveDate) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.receiveDate) _data.push(this.receiveDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly video: boolean | undefined,
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly date: TLInt,
      readonly adminId: TLLong,
      readonly participantId: TLLong,
      readonly protocol: API.PhoneCallProtocol,
      readonly receiveDate: TLInt | undefined
    ) {}
  }

  export class PhoneCallRequested implements TLObject {
    static readonly cons = new TLInt(0x14b0ed0c);

    static deserialized(_data: ByteStream): PhoneCallRequested | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhoneCallRequested.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const video = (flags.value & 64) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const adminId = TLLong.deserialized(_data);
      if (!adminId) return undefined;
      const participantId = TLLong.deserialized(_data);
      if (!participantId) return undefined;
      const gAHash = TLBytes.deserialized(_data);
      if (!gAHash) return undefined;
      const protocol = API.PhoneCallProtocol.deserialized(_data);
      if (!protocol) return undefined;

      return new PhoneCallRequested(
        video,
        id,
        accessHash,
        date,
        adminId,
        participantId,
        gAHash,
        protocol
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneCallRequested.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.video) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.date);
      _data.push(this.adminId);
      _data.push(this.participantId);
      _data.push(this.gAHash);
      _data.push(this.protocol);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly video: boolean | undefined,
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly date: TLInt,
      readonly adminId: TLLong,
      readonly participantId: TLLong,
      readonly gAHash: TLBytes,
      readonly protocol: API.PhoneCallProtocol
    ) {}
  }

  export class PhoneCallAccepted implements TLObject {
    static readonly cons = new TLInt(0x3660c311);

    static deserialized(_data: ByteStream): PhoneCallAccepted | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhoneCallAccepted.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const video = (flags.value & 64) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const adminId = TLLong.deserialized(_data);
      if (!adminId) return undefined;
      const participantId = TLLong.deserialized(_data);
      if (!participantId) return undefined;
      const gB = TLBytes.deserialized(_data);
      if (!gB) return undefined;
      const protocol = API.PhoneCallProtocol.deserialized(_data);
      if (!protocol) return undefined;

      return new PhoneCallAccepted(
        video,
        id,
        accessHash,
        date,
        adminId,
        participantId,
        gB,
        protocol
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneCallAccepted.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.video) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.date);
      _data.push(this.adminId);
      _data.push(this.participantId);
      _data.push(this.gB);
      _data.push(this.protocol);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly video: boolean | undefined,
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly date: TLInt,
      readonly adminId: TLLong,
      readonly participantId: TLLong,
      readonly gB: TLBytes,
      readonly protocol: API.PhoneCallProtocol
    ) {}
  }

  export class PhoneCall implements TLObject {
    static readonly cons = new TLInt(0x967f7c67);

    static deserialized(_data: ByteStream): PhoneCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhoneCall.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const p2pAllowed = (flags.value & 32) !== 0;
      const video = (flags.value & 64) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const adminId = TLLong.deserialized(_data);
      if (!adminId) return undefined;
      const participantId = TLLong.deserialized(_data);
      if (!participantId) return undefined;
      const gAOrB = TLBytes.deserialized(_data);
      if (!gAOrB) return undefined;
      const keyFingerprint = TLLong.deserialized(_data);
      if (!keyFingerprint) return undefined;
      const protocol = API.PhoneCallProtocol.deserialized(_data);
      if (!protocol) return undefined;
      const connections = TLVector.deserialized(
        _data
      ) as TLVector<PhoneConnectionType>;
      if (!connections) return undefined;
      const startDate = TLInt.deserialized(_data);
      if (!startDate) return undefined;

      return new PhoneCall(
        p2pAllowed,
        video,
        id,
        accessHash,
        date,
        adminId,
        participantId,
        gAOrB,
        keyFingerprint,
        protocol,
        connections,
        startDate
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneCall.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.p2pAllowed) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.video) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.date);
      _data.push(this.adminId);
      _data.push(this.participantId);
      _data.push(this.gAOrB);
      _data.push(this.keyFingerprint);
      _data.push(this.protocol);
      _data.push(this.connections);
      _data.push(this.startDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly p2pAllowed: boolean | undefined,
      readonly video: boolean | undefined,
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly date: TLInt,
      readonly adminId: TLLong,
      readonly participantId: TLLong,
      readonly gAOrB: TLBytes,
      readonly keyFingerprint: TLLong,
      readonly protocol: API.PhoneCallProtocol,
      readonly connections: TLVector<PhoneConnectionType>,
      readonly startDate: TLInt
    ) {}
  }

  export class PhoneCallDiscarded implements TLObject {
    static readonly cons = new TLInt(0x50ca4de1);

    static deserialized(_data: ByteStream): PhoneCallDiscarded | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhoneCallDiscarded.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const needRating = (flags.value & 4) !== 0;
      const needDebug = (flags.value & 8) !== 0;
      const video = (flags.value & 64) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      let reason: PhoneCallDiscardReasonType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as PhoneCallDiscardReasonType;
        if (!obj) return undefined;
        reason = obj;
      }
      let duration: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        duration = obj;
      }

      return new PhoneCallDiscarded(
        needRating,
        needDebug,
        video,
        id,
        reason,
        duration
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneCallDiscarded.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.needRating) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.needDebug) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.video) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      _data.push(this.id);
      if (this.reason) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.reason) _data.push(this.reason);

      if (this.duration) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.duration) _data.push(this.duration);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly needRating: boolean | undefined,
      readonly needDebug: boolean | undefined,
      readonly video: boolean | undefined,
      readonly id: TLLong,
      readonly reason: PhoneCallDiscardReasonType | undefined,
      readonly duration: TLInt | undefined
    ) {}
  }

  export class PhoneConnection implements TLObject {
    static readonly cons = new TLInt(0x9cc123c7);

    static deserialized(_data: ByteStream): PhoneConnection | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhoneConnection.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const tcp = (flags.value & 1) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const ip = TLString.deserialized(_data);
      if (!ip) return undefined;
      const ipv6 = TLString.deserialized(_data);
      if (!ipv6) return undefined;
      const port = TLInt.deserialized(_data);
      if (!port) return undefined;
      const peerTag = TLBytes.deserialized(_data);
      if (!peerTag) return undefined;

      return new PhoneConnection(tcp, id, ip, ipv6, port, peerTag);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneConnection.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.tcp) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.id);
      _data.push(this.ip);
      _data.push(this.ipv6);
      _data.push(this.port);
      _data.push(this.peerTag);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly tcp: boolean | undefined,
      readonly id: TLLong,
      readonly ip: TLString,
      readonly ipv6: TLString,
      readonly port: TLInt,
      readonly peerTag: TLBytes
    ) {}
  }

  export class PhoneConnectionWebrtc implements TLObject {
    static readonly cons = new TLInt(0x635fe375);

    static deserialized(_data: ByteStream): PhoneConnectionWebrtc | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhoneConnectionWebrtc.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const turn = (flags.value & 1) !== 0;
      const stun = (flags.value & 2) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const ip = TLString.deserialized(_data);
      if (!ip) return undefined;
      const ipv6 = TLString.deserialized(_data);
      if (!ipv6) return undefined;
      const port = TLInt.deserialized(_data);
      if (!port) return undefined;
      const username = TLString.deserialized(_data);
      if (!username) return undefined;
      const password = TLString.deserialized(_data);
      if (!password) return undefined;

      return new PhoneConnectionWebrtc(
        turn,
        stun,
        id,
        ip,
        ipv6,
        port,
        username,
        password
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneConnectionWebrtc.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.turn) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.stun) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.id);
      _data.push(this.ip);
      _data.push(this.ipv6);
      _data.push(this.port);
      _data.push(this.username);
      _data.push(this.password);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly turn: boolean | undefined,
      readonly stun: boolean | undefined,
      readonly id: TLLong,
      readonly ip: TLString,
      readonly ipv6: TLString,
      readonly port: TLInt,
      readonly username: TLString,
      readonly password: TLString
    ) {}
  }

  export class PhoneCallProtocol implements TLObject {
    static readonly cons = new TLInt(0xfc878fc8);

    static deserialized(_data: ByteStream): PhoneCallProtocol | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PhoneCallProtocol.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const udpP2p = (flags.value & 1) !== 0;
      const udpReflector = (flags.value & 2) !== 0;
      const minLayer = TLInt.deserialized(_data);
      if (!minLayer) return undefined;
      const maxLayer = TLInt.deserialized(_data);
      if (!maxLayer) return undefined;
      const libraryVersions = TLVector.deserialized(_data, TLString);
      if (!libraryVersions) return undefined;

      return new PhoneCallProtocol(
        udpP2p,
        udpReflector,
        minLayer,
        maxLayer,
        libraryVersions
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PhoneCallProtocol.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.udpP2p) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.udpReflector) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.minLayer);
      _data.push(this.maxLayer);
      _data.push(this.libraryVersions);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly udpP2p: boolean | undefined,
      readonly udpReflector: boolean | undefined,
      readonly minLayer: TLInt,
      readonly maxLayer: TLInt,
      readonly libraryVersions: TLVector<TLString>
    ) {}
  }

  export namespace phone {
    export class PhoneCall implements TLObject {
      static readonly cons = new TLInt(0xec82e140);

      static deserialized(_data: ByteStream): PhoneCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PhoneCall.cons))
          return undefined;

        const phoneCall = TLObject.deserialized(_data) as PhoneCallType;
        if (!phoneCall) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new PhoneCall(phoneCall, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PhoneCall.cons];

        _data.push(this.phoneCall);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneCall: PhoneCallType,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace upload {
    export class CdnFileReuploadNeeded implements TLObject {
      static readonly cons = new TLInt(0xeea8e46e);

      static deserialized(
        _data: ByteStream
      ): CdnFileReuploadNeeded | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CdnFileReuploadNeeded.cons))
          return undefined;

        const requestToken = TLBytes.deserialized(_data);
        if (!requestToken) return undefined;

        return new CdnFileReuploadNeeded(requestToken);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CdnFileReuploadNeeded.cons];

        _data.push(this.requestToken);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly requestToken: TLBytes) {}
    }
  }

  export namespace upload {
    export class CdnFile implements TLObject {
      static readonly cons = new TLInt(0xa99fca4f);

      static deserialized(_data: ByteStream): CdnFile | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CdnFile.cons)) return undefined;

        const bytes = TLBytes.deserialized(_data);
        if (!bytes) return undefined;

        return new CdnFile(bytes);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CdnFile.cons];

        _data.push(this.bytes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly bytes: TLBytes) {}
    }
  }

  export class CdnPublicKey implements TLObject {
    static readonly cons = new TLInt(0xc982eaba);

    static deserialized(_data: ByteStream): CdnPublicKey | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(CdnPublicKey.cons))
        return undefined;

      const dcId = TLInt.deserialized(_data);
      if (!dcId) return undefined;
      const publicKey = TLString.deserialized(_data);
      if (!publicKey) return undefined;

      return new CdnPublicKey(dcId, publicKey);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [CdnPublicKey.cons];

      _data.push(this.dcId);
      _data.push(this.publicKey);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly dcId: TLInt, readonly publicKey: TLString) {}
  }

  export class CdnConfig implements TLObject {
    static readonly cons = new TLInt(0x5725e40a);

    static deserialized(_data: ByteStream): CdnConfig | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(CdnConfig.cons)) return undefined;

      const publicKeys = TLVector.deserialized(_data, API.CdnPublicKey);
      if (!publicKeys) return undefined;

      return new CdnConfig(publicKeys);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [CdnConfig.cons];

      _data.push(this.publicKeys);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly publicKeys: TLVector<API.CdnPublicKey>) {}
  }

  export class LangPackString implements TLObject {
    static readonly cons = new TLInt(0xcad181f6);

    static deserialized(_data: ByteStream): LangPackString | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(LangPackString.cons))
        return undefined;

      const key = TLString.deserialized(_data);
      if (!key) return undefined;
      const value = TLString.deserialized(_data);
      if (!value) return undefined;

      return new LangPackString(key, value);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [LangPackString.cons];

      _data.push(this.key);
      _data.push(this.value);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly key: TLString, readonly value: TLString) {}
  }

  export class LangPackStringPluralized implements TLObject {
    static readonly cons = new TLInt(0x6c47ac9f);

    static deserialized(
      _data: ByteStream
    ): LangPackStringPluralized | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(LangPackStringPluralized.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const key = TLString.deserialized(_data);
      if (!key) return undefined;
      let zeroValue: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        zeroValue = obj;
      }
      let oneValue: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        oneValue = obj;
      }
      let twoValue: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        twoValue = obj;
      }
      let fewValue: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        fewValue = obj;
      }
      let manyValue: TLString | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        manyValue = obj;
      }
      const otherValue = TLString.deserialized(_data);
      if (!otherValue) return undefined;

      return new LangPackStringPluralized(
        key,
        zeroValue,
        oneValue,
        twoValue,
        fewValue,
        manyValue,
        otherValue
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [LangPackStringPluralized.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.key);
      if (this.zeroValue) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.zeroValue) _data.push(this.zeroValue);

      if (this.oneValue) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.oneValue) _data.push(this.oneValue);

      if (this.twoValue) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.twoValue) _data.push(this.twoValue);

      if (this.fewValue) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.fewValue) _data.push(this.fewValue);

      if (this.manyValue) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.manyValue) _data.push(this.manyValue);

      _data.push(this.otherValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly key: TLString,
      readonly zeroValue: TLString | undefined,
      readonly oneValue: TLString | undefined,
      readonly twoValue: TLString | undefined,
      readonly fewValue: TLString | undefined,
      readonly manyValue: TLString | undefined,
      readonly otherValue: TLString
    ) {}
  }

  export class LangPackStringDeleted implements TLObject {
    static readonly cons = new TLInt(0x2979eeb2);

    static deserialized(_data: ByteStream): LangPackStringDeleted | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(LangPackStringDeleted.cons))
        return undefined;

      const key = TLString.deserialized(_data);
      if (!key) return undefined;

      return new LangPackStringDeleted(key);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [LangPackStringDeleted.cons];

      _data.push(this.key);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly key: TLString) {}
  }

  export class LangPackDifference implements TLObject {
    static readonly cons = new TLInt(0xf385c1f6);

    static deserialized(_data: ByteStream): LangPackDifference | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(LangPackDifference.cons))
        return undefined;

      const langCode = TLString.deserialized(_data);
      if (!langCode) return undefined;
      const fromVersion = TLInt.deserialized(_data);
      if (!fromVersion) return undefined;
      const version = TLInt.deserialized(_data);
      if (!version) return undefined;
      const strings = TLVector.deserialized(
        _data
      ) as TLVector<LangPackStringType>;
      if (!strings) return undefined;

      return new LangPackDifference(langCode, fromVersion, version, strings);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [LangPackDifference.cons];

      _data.push(this.langCode);
      _data.push(this.fromVersion);
      _data.push(this.version);
      _data.push(this.strings);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly langCode: TLString,
      readonly fromVersion: TLInt,
      readonly version: TLInt,
      readonly strings: TLVector<LangPackStringType>
    ) {}
  }

  export class LangPackLanguage implements TLObject {
    static readonly cons = new TLInt(0xeeca5ce3);

    static deserialized(_data: ByteStream): LangPackLanguage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(LangPackLanguage.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const official = (flags.value & 1) !== 0;
      const rtl = (flags.value & 4) !== 0;
      const beta = (flags.value & 8) !== 0;
      const name = TLString.deserialized(_data);
      if (!name) return undefined;
      const nativeName = TLString.deserialized(_data);
      if (!nativeName) return undefined;
      const langCode = TLString.deserialized(_data);
      if (!langCode) return undefined;
      let baseLangCode: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        baseLangCode = obj;
      }
      const pluralCode = TLString.deserialized(_data);
      if (!pluralCode) return undefined;
      const stringsCount = TLInt.deserialized(_data);
      if (!stringsCount) return undefined;
      const translatedCount = TLInt.deserialized(_data);
      if (!translatedCount) return undefined;
      const translationsUrl = TLString.deserialized(_data);
      if (!translationsUrl) return undefined;

      return new LangPackLanguage(
        official,
        rtl,
        beta,
        name,
        nativeName,
        langCode,
        baseLangCode,
        pluralCode,
        stringsCount,
        translatedCount,
        translationsUrl
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [LangPackLanguage.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.official) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.rtl) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.beta) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      _data.push(this.name);
      _data.push(this.nativeName);
      _data.push(this.langCode);
      if (this.baseLangCode) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.baseLangCode) _data.push(this.baseLangCode);

      _data.push(this.pluralCode);
      _data.push(this.stringsCount);
      _data.push(this.translatedCount);
      _data.push(this.translationsUrl);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly official: boolean | undefined,
      readonly rtl: boolean | undefined,
      readonly beta: boolean | undefined,
      readonly name: TLString,
      readonly nativeName: TLString,
      readonly langCode: TLString,
      readonly baseLangCode: TLString | undefined,
      readonly pluralCode: TLString,
      readonly stringsCount: TLInt,
      readonly translatedCount: TLInt,
      readonly translationsUrl: TLString
    ) {}
  }

  export class ChannelAdminLogEventActionChangeTitle implements TLObject {
    static readonly cons = new TLInt(0xe6dfb825);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionChangeTitle | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionChangeTitle.cons)
      )
        return undefined;

      const prevValue = TLString.deserialized(_data);
      if (!prevValue) return undefined;
      const newValue = TLString.deserialized(_data);
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionChangeTitle(prevValue, newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionChangeTitle.cons,
      ];

      _data.push(this.prevValue);
      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly prevValue: TLString, readonly newValue: TLString) {}
  }

  export class ChannelAdminLogEventActionChangeAbout implements TLObject {
    static readonly cons = new TLInt(0x55188a2e);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionChangeAbout | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionChangeAbout.cons)
      )
        return undefined;

      const prevValue = TLString.deserialized(_data);
      if (!prevValue) return undefined;
      const newValue = TLString.deserialized(_data);
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionChangeAbout(prevValue, newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionChangeAbout.cons,
      ];

      _data.push(this.prevValue);
      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly prevValue: TLString, readonly newValue: TLString) {}
  }

  export class ChannelAdminLogEventActionChangeUsername implements TLObject {
    static readonly cons = new TLInt(0x6a4afc38);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionChangeUsername | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionChangeUsername.cons)
      )
        return undefined;

      const prevValue = TLString.deserialized(_data);
      if (!prevValue) return undefined;
      const newValue = TLString.deserialized(_data);
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionChangeUsername(prevValue, newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionChangeUsername.cons,
      ];

      _data.push(this.prevValue);
      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly prevValue: TLString, readonly newValue: TLString) {}
  }

  export class ChannelAdminLogEventActionChangePhoto implements TLObject {
    static readonly cons = new TLInt(0x434bd2af);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionChangePhoto | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionChangePhoto.cons)
      )
        return undefined;

      const prevPhoto = TLObject.deserialized(_data) as PhotoType;
      if (!prevPhoto) return undefined;
      const newPhoto = TLObject.deserialized(_data) as PhotoType;
      if (!newPhoto) return undefined;

      return new ChannelAdminLogEventActionChangePhoto(prevPhoto, newPhoto);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionChangePhoto.cons,
      ];

      _data.push(this.prevPhoto);
      _data.push(this.newPhoto);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly prevPhoto: PhotoType, readonly newPhoto: PhotoType) {}
  }

  export class ChannelAdminLogEventActionToggleInvites implements TLObject {
    static readonly cons = new TLInt(0x1b7907ae);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionToggleInvites | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionToggleInvites.cons)
      )
        return undefined;

      const newValue = TLObject.deserialized(_data) as BoolType;
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionToggleInvites(newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionToggleInvites.cons,
      ];

      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly newValue: BoolType) {}
  }

  export class ChannelAdminLogEventActionToggleSignatures implements TLObject {
    static readonly cons = new TLInt(0x26ae0971);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionToggleSignatures | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionToggleSignatures.cons)
      )
        return undefined;

      const newValue = TLObject.deserialized(_data) as BoolType;
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionToggleSignatures(newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionToggleSignatures.cons,
      ];

      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly newValue: BoolType) {}
  }

  export class ChannelAdminLogEventActionUpdatePinned implements TLObject {
    static readonly cons = new TLInt(0xe9e82c18);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionUpdatePinned | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionUpdatePinned.cons)
      )
        return undefined;

      const message = TLObject.deserialized(_data) as MessageType;
      if (!message) return undefined;

      return new ChannelAdminLogEventActionUpdatePinned(message);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionUpdatePinned.cons,
      ];

      _data.push(this.message);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly message: MessageType) {}
  }

  export class ChannelAdminLogEventActionEditMessage implements TLObject {
    static readonly cons = new TLInt(0x709b2405);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionEditMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionEditMessage.cons)
      )
        return undefined;

      const prevMessage = TLObject.deserialized(_data) as MessageType;
      if (!prevMessage) return undefined;
      const newMessage = TLObject.deserialized(_data) as MessageType;
      if (!newMessage) return undefined;

      return new ChannelAdminLogEventActionEditMessage(prevMessage, newMessage);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionEditMessage.cons,
      ];

      _data.push(this.prevMessage);
      _data.push(this.newMessage);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly prevMessage: MessageType,
      readonly newMessage: MessageType
    ) {}
  }

  export class ChannelAdminLogEventActionDeleteMessage implements TLObject {
    static readonly cons = new TLInt(0x42e047bb);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionDeleteMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionDeleteMessage.cons)
      )
        return undefined;

      const message = TLObject.deserialized(_data) as MessageType;
      if (!message) return undefined;

      return new ChannelAdminLogEventActionDeleteMessage(message);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionDeleteMessage.cons,
      ];

      _data.push(this.message);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly message: MessageType) {}
  }

  export class ChannelAdminLogEventActionParticipantJoin implements TLObject {
    static readonly cons = new TLInt(0x183040d3);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionParticipantJoin | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionParticipantJoin.cons)
      )
        return undefined;

      return new ChannelAdminLogEventActionParticipantJoin();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionParticipantJoin.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChannelAdminLogEventActionParticipantLeave implements TLObject {
    static readonly cons = new TLInt(0xf89777f2);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionParticipantLeave | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionParticipantLeave.cons)
      )
        return undefined;

      return new ChannelAdminLogEventActionParticipantLeave();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionParticipantLeave.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChannelAdminLogEventActionParticipantInvite implements TLObject {
    static readonly cons = new TLInt(0xe31c34d8);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionParticipantInvite | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionParticipantInvite.cons)
      )
        return undefined;

      const participant = TLObject.deserialized(
        _data
      ) as ChannelParticipantType;
      if (!participant) return undefined;

      return new ChannelAdminLogEventActionParticipantInvite(participant);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionParticipantInvite.cons,
      ];

      _data.push(this.participant);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly participant: ChannelParticipantType) {}
  }

  export class ChannelAdminLogEventActionParticipantToggleBan
    implements TLObject
  {
    static readonly cons = new TLInt(0xe6d83d7e);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionParticipantToggleBan | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionParticipantToggleBan.cons)
      )
        return undefined;

      const prevParticipant = TLObject.deserialized(
        _data
      ) as ChannelParticipantType;
      if (!prevParticipant) return undefined;
      const newParticipant = TLObject.deserialized(
        _data
      ) as ChannelParticipantType;
      if (!newParticipant) return undefined;

      return new ChannelAdminLogEventActionParticipantToggleBan(
        prevParticipant,
        newParticipant
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionParticipantToggleBan.cons,
      ];

      _data.push(this.prevParticipant);
      _data.push(this.newParticipant);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly prevParticipant: ChannelParticipantType,
      readonly newParticipant: ChannelParticipantType
    ) {}
  }

  export class ChannelAdminLogEventActionParticipantToggleAdmin
    implements TLObject
  {
    static readonly cons = new TLInt(0xd5676710);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionParticipantToggleAdmin | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(
          ChannelAdminLogEventActionParticipantToggleAdmin.cons
        )
      )
        return undefined;

      const prevParticipant = TLObject.deserialized(
        _data
      ) as ChannelParticipantType;
      if (!prevParticipant) return undefined;
      const newParticipant = TLObject.deserialized(
        _data
      ) as ChannelParticipantType;
      if (!newParticipant) return undefined;

      return new ChannelAdminLogEventActionParticipantToggleAdmin(
        prevParticipant,
        newParticipant
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionParticipantToggleAdmin.cons,
      ];

      _data.push(this.prevParticipant);
      _data.push(this.newParticipant);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly prevParticipant: ChannelParticipantType,
      readonly newParticipant: ChannelParticipantType
    ) {}
  }

  export class ChannelAdminLogEventActionChangeStickerSet implements TLObject {
    static readonly cons = new TLInt(0xb1c3caa7);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionChangeStickerSet | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionChangeStickerSet.cons)
      )
        return undefined;

      const prevStickerset = TLObject.deserialized(
        _data
      ) as InputStickerSetType;
      if (!prevStickerset) return undefined;
      const newStickerset = TLObject.deserialized(_data) as InputStickerSetType;
      if (!newStickerset) return undefined;

      return new ChannelAdminLogEventActionChangeStickerSet(
        prevStickerset,
        newStickerset
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionChangeStickerSet.cons,
      ];

      _data.push(this.prevStickerset);
      _data.push(this.newStickerset);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly prevStickerset: InputStickerSetType,
      readonly newStickerset: InputStickerSetType
    ) {}
  }

  export class ChannelAdminLogEventActionTogglePreHistoryHidden
    implements TLObject
  {
    static readonly cons = new TLInt(0x5f5c95f1);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionTogglePreHistoryHidden | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(
          ChannelAdminLogEventActionTogglePreHistoryHidden.cons
        )
      )
        return undefined;

      const newValue = TLObject.deserialized(_data) as BoolType;
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionTogglePreHistoryHidden(newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionTogglePreHistoryHidden.cons,
      ];

      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly newValue: BoolType) {}
  }

  export class ChannelAdminLogEventActionDefaultBannedRights
    implements TLObject
  {
    static readonly cons = new TLInt(0x2df5fc0a);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionDefaultBannedRights | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionDefaultBannedRights.cons)
      )
        return undefined;

      const prevBannedRights = API.ChatBannedRights.deserialized(_data);
      if (!prevBannedRights) return undefined;
      const newBannedRights = API.ChatBannedRights.deserialized(_data);
      if (!newBannedRights) return undefined;

      return new ChannelAdminLogEventActionDefaultBannedRights(
        prevBannedRights,
        newBannedRights
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionDefaultBannedRights.cons,
      ];

      _data.push(this.prevBannedRights);
      _data.push(this.newBannedRights);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly prevBannedRights: API.ChatBannedRights,
      readonly newBannedRights: API.ChatBannedRights
    ) {}
  }

  export class ChannelAdminLogEventActionStopPoll implements TLObject {
    static readonly cons = new TLInt(0x8f079643);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionStopPoll | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionStopPoll.cons)
      )
        return undefined;

      const message = TLObject.deserialized(_data) as MessageType;
      if (!message) return undefined;

      return new ChannelAdminLogEventActionStopPoll(message);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionStopPoll.cons,
      ];

      _data.push(this.message);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly message: MessageType) {}
  }

  export class ChannelAdminLogEventActionChangeLinkedChat implements TLObject {
    static readonly cons = new TLInt(0x50c7ac8);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionChangeLinkedChat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionChangeLinkedChat.cons)
      )
        return undefined;

      const prevValue = TLLong.deserialized(_data);
      if (!prevValue) return undefined;
      const newValue = TLLong.deserialized(_data);
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionChangeLinkedChat(
        prevValue,
        newValue
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionChangeLinkedChat.cons,
      ];

      _data.push(this.prevValue);
      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly prevValue: TLLong, readonly newValue: TLLong) {}
  }

  export class ChannelAdminLogEventActionChangeLocation implements TLObject {
    static readonly cons = new TLInt(0xe6b76ae);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionChangeLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionChangeLocation.cons)
      )
        return undefined;

      const prevValue = TLObject.deserialized(_data) as ChannelLocationType;
      if (!prevValue) return undefined;
      const newValue = TLObject.deserialized(_data) as ChannelLocationType;
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionChangeLocation(prevValue, newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionChangeLocation.cons,
      ];

      _data.push(this.prevValue);
      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly prevValue: ChannelLocationType,
      readonly newValue: ChannelLocationType
    ) {}
  }

  export class ChannelAdminLogEventActionToggleSlowMode implements TLObject {
    static readonly cons = new TLInt(0x53909779);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionToggleSlowMode | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionToggleSlowMode.cons)
      )
        return undefined;

      const prevValue = TLInt.deserialized(_data);
      if (!prevValue) return undefined;
      const newValue = TLInt.deserialized(_data);
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionToggleSlowMode(prevValue, newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionToggleSlowMode.cons,
      ];

      _data.push(this.prevValue);
      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly prevValue: TLInt, readonly newValue: TLInt) {}
  }

  export class ChannelAdminLogEventActionStartGroupCall implements TLObject {
    static readonly cons = new TLInt(0x23209745);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionStartGroupCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionStartGroupCall.cons)
      )
        return undefined;

      const call = API.InputGroupCall.deserialized(_data);
      if (!call) return undefined;

      return new ChannelAdminLogEventActionStartGroupCall(call);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionStartGroupCall.cons,
      ];

      _data.push(this.call);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly call: API.InputGroupCall) {}
  }

  export class ChannelAdminLogEventActionDiscardGroupCall implements TLObject {
    static readonly cons = new TLInt(0xdb9f9140);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionDiscardGroupCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionDiscardGroupCall.cons)
      )
        return undefined;

      const call = API.InputGroupCall.deserialized(_data);
      if (!call) return undefined;

      return new ChannelAdminLogEventActionDiscardGroupCall(call);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionDiscardGroupCall.cons,
      ];

      _data.push(this.call);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly call: API.InputGroupCall) {}
  }

  export class ChannelAdminLogEventActionParticipantMute implements TLObject {
    static readonly cons = new TLInt(0xf92424d2);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionParticipantMute | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionParticipantMute.cons)
      )
        return undefined;

      const participant = API.GroupCallParticipant.deserialized(_data);
      if (!participant) return undefined;

      return new ChannelAdminLogEventActionParticipantMute(participant);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionParticipantMute.cons,
      ];

      _data.push(this.participant);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly participant: API.GroupCallParticipant) {}
  }

  export class ChannelAdminLogEventActionParticipantUnmute implements TLObject {
    static readonly cons = new TLInt(0xe64429c0);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionParticipantUnmute | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionParticipantUnmute.cons)
      )
        return undefined;

      const participant = API.GroupCallParticipant.deserialized(_data);
      if (!participant) return undefined;

      return new ChannelAdminLogEventActionParticipantUnmute(participant);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionParticipantUnmute.cons,
      ];

      _data.push(this.participant);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly participant: API.GroupCallParticipant) {}
  }

  export class ChannelAdminLogEventActionToggleGroupCallSetting
    implements TLObject
  {
    static readonly cons = new TLInt(0x56d6a247);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionToggleGroupCallSetting | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(
          ChannelAdminLogEventActionToggleGroupCallSetting.cons
        )
      )
        return undefined;

      const joinMuted = TLObject.deserialized(_data) as BoolType;
      if (!joinMuted) return undefined;

      return new ChannelAdminLogEventActionToggleGroupCallSetting(joinMuted);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionToggleGroupCallSetting.cons,
      ];

      _data.push(this.joinMuted);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly joinMuted: BoolType) {}
  }

  export class ChannelAdminLogEventActionParticipantJoinByInvite
    implements TLObject
  {
    static readonly cons = new TLInt(0xfe9fc158);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionParticipantJoinByInvite | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(
          ChannelAdminLogEventActionParticipantJoinByInvite.cons
        )
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const viaChatlist = (flags.value & 1) !== 0;
      const invite = TLObject.deserialized(_data) as ExportedChatInviteType;
      if (!invite) return undefined;

      return new ChannelAdminLogEventActionParticipantJoinByInvite(
        viaChatlist,
        invite
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionParticipantJoinByInvite.cons,
      ];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.viaChatlist) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.invite);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly viaChatlist: boolean | undefined,
      readonly invite: ExportedChatInviteType
    ) {}
  }

  export class ChannelAdminLogEventActionExportedInviteDelete
    implements TLObject
  {
    static readonly cons = new TLInt(0x5a50fca4);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionExportedInviteDelete | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionExportedInviteDelete.cons)
      )
        return undefined;

      const invite = TLObject.deserialized(_data) as ExportedChatInviteType;
      if (!invite) return undefined;

      return new ChannelAdminLogEventActionExportedInviteDelete(invite);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionExportedInviteDelete.cons,
      ];

      _data.push(this.invite);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly invite: ExportedChatInviteType) {}
  }

  export class ChannelAdminLogEventActionExportedInviteRevoke
    implements TLObject
  {
    static readonly cons = new TLInt(0x410a134e);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionExportedInviteRevoke | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionExportedInviteRevoke.cons)
      )
        return undefined;

      const invite = TLObject.deserialized(_data) as ExportedChatInviteType;
      if (!invite) return undefined;

      return new ChannelAdminLogEventActionExportedInviteRevoke(invite);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionExportedInviteRevoke.cons,
      ];

      _data.push(this.invite);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly invite: ExportedChatInviteType) {}
  }

  export class ChannelAdminLogEventActionExportedInviteEdit
    implements TLObject
  {
    static readonly cons = new TLInt(0xe90ebb59);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionExportedInviteEdit | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionExportedInviteEdit.cons)
      )
        return undefined;

      const prevInvite = TLObject.deserialized(_data) as ExportedChatInviteType;
      if (!prevInvite) return undefined;
      const newInvite = TLObject.deserialized(_data) as ExportedChatInviteType;
      if (!newInvite) return undefined;

      return new ChannelAdminLogEventActionExportedInviteEdit(
        prevInvite,
        newInvite
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionExportedInviteEdit.cons,
      ];

      _data.push(this.prevInvite);
      _data.push(this.newInvite);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly prevInvite: ExportedChatInviteType,
      readonly newInvite: ExportedChatInviteType
    ) {}
  }

  export class ChannelAdminLogEventActionParticipantVolume implements TLObject {
    static readonly cons = new TLInt(0x3e7f6847);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionParticipantVolume | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionParticipantVolume.cons)
      )
        return undefined;

      const participant = API.GroupCallParticipant.deserialized(_data);
      if (!participant) return undefined;

      return new ChannelAdminLogEventActionParticipantVolume(participant);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionParticipantVolume.cons,
      ];

      _data.push(this.participant);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly participant: API.GroupCallParticipant) {}
  }

  export class ChannelAdminLogEventActionChangeHistoryTTL implements TLObject {
    static readonly cons = new TLInt(0x6e941a38);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionChangeHistoryTTL | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionChangeHistoryTTL.cons)
      )
        return undefined;

      const prevValue = TLInt.deserialized(_data);
      if (!prevValue) return undefined;
      const newValue = TLInt.deserialized(_data);
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionChangeHistoryTTL(
        prevValue,
        newValue
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionChangeHistoryTTL.cons,
      ];

      _data.push(this.prevValue);
      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly prevValue: TLInt, readonly newValue: TLInt) {}
  }

  export class ChannelAdminLogEventActionParticipantJoinByRequest
    implements TLObject
  {
    static readonly cons = new TLInt(0xafb6144a);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionParticipantJoinByRequest | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(
          ChannelAdminLogEventActionParticipantJoinByRequest.cons
        )
      )
        return undefined;

      const invite = TLObject.deserialized(_data) as ExportedChatInviteType;
      if (!invite) return undefined;
      const approvedBy = TLLong.deserialized(_data);
      if (!approvedBy) return undefined;

      return new ChannelAdminLogEventActionParticipantJoinByRequest(
        invite,
        approvedBy
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionParticipantJoinByRequest.cons,
      ];

      _data.push(this.invite);
      _data.push(this.approvedBy);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly invite: ExportedChatInviteType,
      readonly approvedBy: TLLong
    ) {}
  }

  export class ChannelAdminLogEventActionToggleNoForwards implements TLObject {
    static readonly cons = new TLInt(0xcb2ac766);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionToggleNoForwards | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionToggleNoForwards.cons)
      )
        return undefined;

      const newValue = TLObject.deserialized(_data) as BoolType;
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionToggleNoForwards(newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionToggleNoForwards.cons,
      ];

      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly newValue: BoolType) {}
  }

  export class ChannelAdminLogEventActionSendMessage implements TLObject {
    static readonly cons = new TLInt(0x278f2868);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionSendMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionSendMessage.cons)
      )
        return undefined;

      const message = TLObject.deserialized(_data) as MessageType;
      if (!message) return undefined;

      return new ChannelAdminLogEventActionSendMessage(message);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionSendMessage.cons,
      ];

      _data.push(this.message);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly message: MessageType) {}
  }

  export class ChannelAdminLogEventActionChangeAvailableReactions
    implements TLObject
  {
    static readonly cons = new TLInt(0xbe4e0ef8);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionChangeAvailableReactions | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(
          ChannelAdminLogEventActionChangeAvailableReactions.cons
        )
      )
        return undefined;

      const prevValue = TLObject.deserialized(_data) as ChatReactionsType;
      if (!prevValue) return undefined;
      const newValue = TLObject.deserialized(_data) as ChatReactionsType;
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionChangeAvailableReactions(
        prevValue,
        newValue
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionChangeAvailableReactions.cons,
      ];

      _data.push(this.prevValue);
      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly prevValue: ChatReactionsType,
      readonly newValue: ChatReactionsType
    ) {}
  }

  export class ChannelAdminLogEventActionChangeUsernames implements TLObject {
    static readonly cons = new TLInt(0xf04fb3a9);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionChangeUsernames | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionChangeUsernames.cons)
      )
        return undefined;

      const prevValue = TLVector.deserialized(_data, TLString);
      if (!prevValue) return undefined;
      const newValue = TLVector.deserialized(_data, TLString);
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionChangeUsernames(prevValue, newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionChangeUsernames.cons,
      ];

      _data.push(this.prevValue);
      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly prevValue: TLVector<TLString>,
      readonly newValue: TLVector<TLString>
    ) {}
  }

  export class ChannelAdminLogEventActionToggleForum implements TLObject {
    static readonly cons = new TLInt(0x2cc6383);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionToggleForum | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionToggleForum.cons)
      )
        return undefined;

      const newValue = TLObject.deserialized(_data) as BoolType;
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionToggleForum(newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionToggleForum.cons,
      ];

      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly newValue: BoolType) {}
  }

  export class ChannelAdminLogEventActionCreateTopic implements TLObject {
    static readonly cons = new TLInt(0x58707d28);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionCreateTopic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionCreateTopic.cons)
      )
        return undefined;

      const topic = TLObject.deserialized(_data) as ForumTopicType;
      if (!topic) return undefined;

      return new ChannelAdminLogEventActionCreateTopic(topic);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionCreateTopic.cons,
      ];

      _data.push(this.topic);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly topic: ForumTopicType) {}
  }

  export class ChannelAdminLogEventActionEditTopic implements TLObject {
    static readonly cons = new TLInt(0xf06fe208);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionEditTopic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionEditTopic.cons)
      )
        return undefined;

      const prevTopic = TLObject.deserialized(_data) as ForumTopicType;
      if (!prevTopic) return undefined;
      const newTopic = TLObject.deserialized(_data) as ForumTopicType;
      if (!newTopic) return undefined;

      return new ChannelAdminLogEventActionEditTopic(prevTopic, newTopic);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionEditTopic.cons,
      ];

      _data.push(this.prevTopic);
      _data.push(this.newTopic);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly prevTopic: ForumTopicType,
      readonly newTopic: ForumTopicType
    ) {}
  }

  export class ChannelAdminLogEventActionDeleteTopic implements TLObject {
    static readonly cons = new TLInt(0xae168909);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionDeleteTopic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionDeleteTopic.cons)
      )
        return undefined;

      const topic = TLObject.deserialized(_data) as ForumTopicType;
      if (!topic) return undefined;

      return new ChannelAdminLogEventActionDeleteTopic(topic);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionDeleteTopic.cons,
      ];

      _data.push(this.topic);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly topic: ForumTopicType) {}
  }

  export class ChannelAdminLogEventActionPinTopic implements TLObject {
    static readonly cons = new TLInt(0x5d8d353b);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionPinTopic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionPinTopic.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let prevTopic: ForumTopicType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as ForumTopicType;
        if (!obj) return undefined;
        prevTopic = obj;
      }
      let newTopic: ForumTopicType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as ForumTopicType;
        if (!obj) return undefined;
        newTopic = obj;
      }

      return new ChannelAdminLogEventActionPinTopic(prevTopic, newTopic);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionPinTopic.cons,
      ];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.prevTopic) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.prevTopic) _data.push(this.prevTopic);

      if (this.newTopic) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.newTopic) _data.push(this.newTopic);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly prevTopic: ForumTopicType | undefined,
      readonly newTopic: ForumTopicType | undefined
    ) {}
  }

  export class ChannelAdminLogEventActionToggleAntiSpam implements TLObject {
    static readonly cons = new TLInt(0x64f36dfc);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventActionToggleAntiSpam | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(ChannelAdminLogEventActionToggleAntiSpam.cons)
      )
        return undefined;

      const newValue = TLObject.deserialized(_data) as BoolType;
      if (!newValue) return undefined;

      return new ChannelAdminLogEventActionToggleAntiSpam(newValue);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        ChannelAdminLogEventActionToggleAntiSpam.cons,
      ];

      _data.push(this.newValue);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly newValue: BoolType) {}
  }

  export class ChannelAdminLogEvent implements TLObject {
    static readonly cons = new TLInt(0x1fad68cd);

    static deserialized(_data: ByteStream): ChannelAdminLogEvent | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelAdminLogEvent.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const action = TLObject.deserialized(
        _data
      ) as ChannelAdminLogEventActionType;
      if (!action) return undefined;

      return new ChannelAdminLogEvent(id, date, userId, action);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelAdminLogEvent.cons];

      _data.push(this.id);
      _data.push(this.date);
      _data.push(this.userId);
      _data.push(this.action);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly date: TLInt,
      readonly userId: TLLong,
      readonly action: ChannelAdminLogEventActionType
    ) {}
  }

  export namespace channels {
    export class AdminLogResults implements TLObject {
      static readonly cons = new TLInt(0xed8af74d);

      static deserialized(_data: ByteStream): AdminLogResults | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AdminLogResults.cons))
          return undefined;

        const events = TLVector.deserialized(_data, API.ChannelAdminLogEvent);
        if (!events) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new AdminLogResults(events, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AdminLogResults.cons];

        _data.push(this.events);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly events: TLVector<API.ChannelAdminLogEvent>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class ChannelAdminLogEventsFilter implements TLObject {
    static readonly cons = new TLInt(0xea107ae4);

    static deserialized(
      _data: ByteStream
    ): ChannelAdminLogEventsFilter | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelAdminLogEventsFilter.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const join = (flags.value & 1) !== 0;
      const leave = (flags.value & 2) !== 0;
      const invite = (flags.value & 4) !== 0;
      const ban = (flags.value & 8) !== 0;
      const unban = (flags.value & 16) !== 0;
      const kick = (flags.value & 32) !== 0;
      const unkick = (flags.value & 64) !== 0;
      const promote = (flags.value & 128) !== 0;
      const demote = (flags.value & 256) !== 0;
      const info = (flags.value & 512) !== 0;
      const settings = (flags.value & 1024) !== 0;
      const pinned = (flags.value & 2048) !== 0;
      const edit = (flags.value & 4096) !== 0;
      const argDelete = (flags.value & 8192) !== 0;
      const groupCall = (flags.value & 16384) !== 0;
      const invites = (flags.value & 32768) !== 0;
      const send = (flags.value & 65536) !== 0;
      const forums = (flags.value & 131072) !== 0;

      return new ChannelAdminLogEventsFilter(
        join,
        leave,
        invite,
        ban,
        unban,
        kick,
        unkick,
        promote,
        demote,
        info,
        settings,
        pinned,
        edit,
        argDelete,
        groupCall,
        invites,
        send,
        forums
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelAdminLogEventsFilter.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.join) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.leave) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.invite) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.ban) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.unban) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.kick) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.unkick) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      if (this.promote) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.demote) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      if (this.info) {
        flags.set(512);
      } else {
        flags.unset(512);
      }

      if (this.settings) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }

      if (this.pinned) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }

      if (this.edit) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }

      if (this.argDelete) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }

      if (this.groupCall) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }

      if (this.invites) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }

      if (this.send) {
        flags.set(65536);
      } else {
        flags.unset(65536);
      }

      if (this.forums) {
        flags.set(131072);
      } else {
        flags.unset(131072);
      }

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly join: boolean | undefined,
      readonly leave: boolean | undefined,
      readonly invite: boolean | undefined,
      readonly ban: boolean | undefined,
      readonly unban: boolean | undefined,
      readonly kick: boolean | undefined,
      readonly unkick: boolean | undefined,
      readonly promote: boolean | undefined,
      readonly demote: boolean | undefined,
      readonly info: boolean | undefined,
      readonly settings: boolean | undefined,
      readonly pinned: boolean | undefined,
      readonly edit: boolean | undefined,
      readonly argDelete: boolean | undefined,
      readonly groupCall: boolean | undefined,
      readonly invites: boolean | undefined,
      readonly send: boolean | undefined,
      readonly forums: boolean | undefined
    ) {}
  }

  export class PopularContact implements TLObject {
    static readonly cons = new TLInt(0x5ce14175);

    static deserialized(_data: ByteStream): PopularContact | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PopularContact.cons))
        return undefined;

      const clientId = TLLong.deserialized(_data);
      if (!clientId) return undefined;
      const importers = TLInt.deserialized(_data);
      if (!importers) return undefined;

      return new PopularContact(clientId, importers);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PopularContact.cons];

      _data.push(this.clientId);
      _data.push(this.importers);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly clientId: TLLong, readonly importers: TLInt) {}
  }

  export namespace messages {
    export class FavedStickersNotModified implements TLObject {
      static readonly cons = new TLInt(0x9e8fa6d3);

      static deserialized(
        _data: ByteStream
      ): FavedStickersNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FavedStickersNotModified.cons))
          return undefined;

        return new FavedStickersNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FavedStickersNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class FavedStickers implements TLObject {
      static readonly cons = new TLInt(0x2cb51097);

      static deserialized(_data: ByteStream): FavedStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FavedStickers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const packs = TLVector.deserialized(_data, API.StickerPack);
        if (!packs) return undefined;
        const stickers = TLVector.deserialized(_data) as TLVector<DocumentType>;
        if (!stickers) return undefined;

        return new FavedStickers(hash, packs, stickers);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FavedStickers.cons];

        _data.push(this.hash);
        _data.push(this.packs);
        _data.push(this.stickers);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLLong,
        readonly packs: TLVector<API.StickerPack>,
        readonly stickers: TLVector<DocumentType>
      ) {}
    }
  }

  export class RecentMeUrlUnknown implements TLObject {
    static readonly cons = new TLInt(0x46e1d13d);

    static deserialized(_data: ByteStream): RecentMeUrlUnknown | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(RecentMeUrlUnknown.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new RecentMeUrlUnknown(url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [RecentMeUrlUnknown.cons];

      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString) {}
  }

  export class RecentMeUrlUser implements TLObject {
    static readonly cons = new TLInt(0xb92c09e2);

    static deserialized(_data: ByteStream): RecentMeUrlUser | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(RecentMeUrlUser.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;

      return new RecentMeUrlUser(url, userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [RecentMeUrlUser.cons];

      _data.push(this.url);
      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString, readonly userId: TLLong) {}
  }

  export class RecentMeUrlChat implements TLObject {
    static readonly cons = new TLInt(0xb2da71d2);

    static deserialized(_data: ByteStream): RecentMeUrlChat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(RecentMeUrlChat.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const chatId = TLLong.deserialized(_data);
      if (!chatId) return undefined;

      return new RecentMeUrlChat(url, chatId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [RecentMeUrlChat.cons];

      _data.push(this.url);
      _data.push(this.chatId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString, readonly chatId: TLLong) {}
  }

  export class RecentMeUrlChatInvite implements TLObject {
    static readonly cons = new TLInt(0xeb49081d);

    static deserialized(_data: ByteStream): RecentMeUrlChatInvite | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(RecentMeUrlChatInvite.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const chatInvite = TLObject.deserialized(_data) as ChatInviteType;
      if (!chatInvite) return undefined;

      return new RecentMeUrlChatInvite(url, chatInvite);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [RecentMeUrlChatInvite.cons];

      _data.push(this.url);
      _data.push(this.chatInvite);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString, readonly chatInvite: ChatInviteType) {}
  }

  export class RecentMeUrlStickerSet implements TLObject {
    static readonly cons = new TLInt(0xbc0a57dc);

    static deserialized(_data: ByteStream): RecentMeUrlStickerSet | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(RecentMeUrlStickerSet.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const set = TLObject.deserialized(_data) as StickerSetCoveredType;
      if (!set) return undefined;

      return new RecentMeUrlStickerSet(url, set);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [RecentMeUrlStickerSet.cons];

      _data.push(this.url);
      _data.push(this.set);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString, readonly set: StickerSetCoveredType) {}
  }

  export namespace help {
    export class RecentMeUrls implements TLObject {
      static readonly cons = new TLInt(0xe0310d7);

      static deserialized(_data: ByteStream): RecentMeUrls | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RecentMeUrls.cons))
          return undefined;

        const urls = TLVector.deserialized(_data) as TLVector<RecentMeUrlType>;
        if (!urls) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new RecentMeUrls(urls, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RecentMeUrls.cons];

        _data.push(this.urls);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly urls: TLVector<RecentMeUrlType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class InputSingleMedia implements TLObject {
    static readonly cons = new TLInt(0x1cc6e91f);

    static deserialized(_data: ByteStream): InputSingleMedia | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputSingleMedia.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const media = TLObject.deserialized(_data) as InputMediaType;
      if (!media) return undefined;
      const randomId = TLLong.deserialized(_data);
      if (!randomId) return undefined;
      const message = TLString.deserialized(_data);
      if (!message) return undefined;
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }

      return new InputSingleMedia(media, randomId, message, entities);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputSingleMedia.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.media);
      _data.push(this.randomId);
      _data.push(this.message);
      if (this.entities) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.entities) _data.push(this.entities);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly media: InputMediaType,
      readonly randomId: TLLong,
      readonly message: TLString,
      readonly entities: TLVector<MessageEntityType> | undefined
    ) {}
  }

  export class WebAuthorization implements TLObject {
    static readonly cons = new TLInt(0xa6f8f452);

    static deserialized(_data: ByteStream): WebAuthorization | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WebAuthorization.cons))
        return undefined;

      const hash = TLLong.deserialized(_data);
      if (!hash) return undefined;
      const botId = TLLong.deserialized(_data);
      if (!botId) return undefined;
      const domain = TLString.deserialized(_data);
      if (!domain) return undefined;
      const browser = TLString.deserialized(_data);
      if (!browser) return undefined;
      const platform = TLString.deserialized(_data);
      if (!platform) return undefined;
      const dateCreated = TLInt.deserialized(_data);
      if (!dateCreated) return undefined;
      const dateActive = TLInt.deserialized(_data);
      if (!dateActive) return undefined;
      const ip = TLString.deserialized(_data);
      if (!ip) return undefined;
      const region = TLString.deserialized(_data);
      if (!region) return undefined;

      return new WebAuthorization(
        hash,
        botId,
        domain,
        browser,
        platform,
        dateCreated,
        dateActive,
        ip,
        region
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WebAuthorization.cons];

      _data.push(this.hash);
      _data.push(this.botId);
      _data.push(this.domain);
      _data.push(this.browser);
      _data.push(this.platform);
      _data.push(this.dateCreated);
      _data.push(this.dateActive);
      _data.push(this.ip);
      _data.push(this.region);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly hash: TLLong,
      readonly botId: TLLong,
      readonly domain: TLString,
      readonly browser: TLString,
      readonly platform: TLString,
      readonly dateCreated: TLInt,
      readonly dateActive: TLInt,
      readonly ip: TLString,
      readonly region: TLString
    ) {}
  }

  export namespace account {
    export class WebAuthorizations implements TLObject {
      static readonly cons = new TLInt(0xed56c9fc);

      static deserialized(_data: ByteStream): WebAuthorizations | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(WebAuthorizations.cons))
          return undefined;

        const authorizations = TLVector.deserialized(
          _data,
          API.WebAuthorization
        );
        if (!authorizations) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new WebAuthorizations(authorizations, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [WebAuthorizations.cons];

        _data.push(this.authorizations);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly authorizations: TLVector<API.WebAuthorization>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class InputMessageID implements TLObject {
    static readonly cons = new TLInt(0xa676a322);

    static deserialized(_data: ByteStream): InputMessageID | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessageID.cons))
        return undefined;

      const id = TLInt.deserialized(_data);
      if (!id) return undefined;

      return new InputMessageID(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessageID.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLInt) {}
  }

  export class InputMessageReplyTo implements TLObject {
    static readonly cons = new TLInt(0xbad88395);

    static deserialized(_data: ByteStream): InputMessageReplyTo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessageReplyTo.cons))
        return undefined;

      const id = TLInt.deserialized(_data);
      if (!id) return undefined;

      return new InputMessageReplyTo(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessageReplyTo.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLInt) {}
  }

  export class InputMessagePinned implements TLObject {
    static readonly cons = new TLInt(0x86872538);

    static deserialized(_data: ByteStream): InputMessagePinned | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessagePinned.cons))
        return undefined;

      return new InputMessagePinned();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessagePinned.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputMessageCallbackQuery implements TLObject {
    static readonly cons = new TLInt(0xacfa1a7e);

    static deserialized(
      _data: ByteStream
    ): InputMessageCallbackQuery | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMessageCallbackQuery.cons))
        return undefined;

      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const queryId = TLLong.deserialized(_data);
      if (!queryId) return undefined;

      return new InputMessageCallbackQuery(id, queryId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMessageCallbackQuery.cons];

      _data.push(this.id);
      _data.push(this.queryId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLInt, readonly queryId: TLLong) {}
  }

  export class InputDialogPeer implements TLObject {
    static readonly cons = new TLInt(0xfcaafeb7);

    static deserialized(_data: ByteStream): InputDialogPeer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputDialogPeer.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;

      return new InputDialogPeer(peer);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputDialogPeer.cons];

      _data.push(this.peer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: InputPeerType) {}
  }

  export class InputDialogPeerFolder implements TLObject {
    static readonly cons = new TLInt(0x64600527);

    static deserialized(_data: ByteStream): InputDialogPeerFolder | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputDialogPeerFolder.cons))
        return undefined;

      const folderId = TLInt.deserialized(_data);
      if (!folderId) return undefined;

      return new InputDialogPeerFolder(folderId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputDialogPeerFolder.cons];

      _data.push(this.folderId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly folderId: TLInt) {}
  }

  export class DialogPeer implements TLObject {
    static readonly cons = new TLInt(0xe56dbf05);

    static deserialized(_data: ByteStream): DialogPeer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DialogPeer.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;

      return new DialogPeer(peer);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DialogPeer.cons];

      _data.push(this.peer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType) {}
  }

  export class DialogPeerFolder implements TLObject {
    static readonly cons = new TLInt(0x514519e2);

    static deserialized(_data: ByteStream): DialogPeerFolder | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DialogPeerFolder.cons))
        return undefined;

      const folderId = TLInt.deserialized(_data);
      if (!folderId) return undefined;

      return new DialogPeerFolder(folderId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DialogPeerFolder.cons];

      _data.push(this.folderId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly folderId: TLInt) {}
  }

  export namespace messages {
    export class FoundStickerSetsNotModified implements TLObject {
      static readonly cons = new TLInt(0xd54b65d);

      static deserialized(
        _data: ByteStream
      ): FoundStickerSetsNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(FoundStickerSetsNotModified.cons)
        )
          return undefined;

        return new FoundStickerSetsNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FoundStickerSetsNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class FoundStickerSets implements TLObject {
      static readonly cons = new TLInt(0x8af09dd2);

      static deserialized(_data: ByteStream): FoundStickerSets | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FoundStickerSets.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const sets = TLVector.deserialized(
          _data
        ) as TLVector<StickerSetCoveredType>;
        if (!sets) return undefined;

        return new FoundStickerSets(hash, sets);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FoundStickerSets.cons];

        _data.push(this.hash);
        _data.push(this.sets);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLLong,
        readonly sets: TLVector<StickerSetCoveredType>
      ) {}
    }
  }

  export class FileHash implements TLObject {
    static readonly cons = new TLInt(0xf39b035c);

    static deserialized(_data: ByteStream): FileHash | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(FileHash.cons)) return undefined;

      const offset = TLLong.deserialized(_data);
      if (!offset) return undefined;
      const limit = TLInt.deserialized(_data);
      if (!limit) return undefined;
      const hash = TLBytes.deserialized(_data);
      if (!hash) return undefined;

      return new FileHash(offset, limit, hash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [FileHash.cons];

      _data.push(this.offset);
      _data.push(this.limit);
      _data.push(this.hash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly offset: TLLong,
      readonly limit: TLInt,
      readonly hash: TLBytes
    ) {}
  }

  export class InputClientProxy implements TLObject {
    static readonly cons = new TLInt(0x75588b3f);

    static deserialized(_data: ByteStream): InputClientProxy | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputClientProxy.cons))
        return undefined;

      const address = TLString.deserialized(_data);
      if (!address) return undefined;
      const port = TLInt.deserialized(_data);
      if (!port) return undefined;

      return new InputClientProxy(address, port);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputClientProxy.cons];

      _data.push(this.address);
      _data.push(this.port);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly address: TLString, readonly port: TLInt) {}
  }

  export namespace help {
    export class TermsOfServiceUpdateEmpty implements TLObject {
      static readonly cons = new TLInt(0xe3309f7f);

      static deserialized(
        _data: ByteStream
      ): TermsOfServiceUpdateEmpty | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TermsOfServiceUpdateEmpty.cons))
          return undefined;

        const expires = TLInt.deserialized(_data);
        if (!expires) return undefined;

        return new TermsOfServiceUpdateEmpty(expires);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TermsOfServiceUpdateEmpty.cons];

        _data.push(this.expires);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly expires: TLInt) {}
    }
  }

  export namespace help {
    export class TermsOfServiceUpdate implements TLObject {
      static readonly cons = new TLInt(0x28ecf961);

      static deserialized(_data: ByteStream): TermsOfServiceUpdate | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TermsOfServiceUpdate.cons))
          return undefined;

        const expires = TLInt.deserialized(_data);
        if (!expires) return undefined;
        const termsOfService = help.TermsOfService.deserialized(_data);
        if (!termsOfService) return undefined;

        return new TermsOfServiceUpdate(expires, termsOfService);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TermsOfServiceUpdate.cons];

        _data.push(this.expires);
        _data.push(this.termsOfService);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly expires: TLInt,
        readonly termsOfService: help.TermsOfService
      ) {}
    }
  }

  export class InputSecureFileUploaded implements TLObject {
    static readonly cons = new TLInt(0x3334b0f0);

    static deserialized(
      _data: ByteStream
    ): InputSecureFileUploaded | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputSecureFileUploaded.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const parts = TLInt.deserialized(_data);
      if (!parts) return undefined;
      const md5Checksum = TLString.deserialized(_data);
      if (!md5Checksum) return undefined;
      const fileHash = TLBytes.deserialized(_data);
      if (!fileHash) return undefined;
      const secret = TLBytes.deserialized(_data);
      if (!secret) return undefined;

      return new InputSecureFileUploaded(
        id,
        parts,
        md5Checksum,
        fileHash,
        secret
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputSecureFileUploaded.cons];

      _data.push(this.id);
      _data.push(this.parts);
      _data.push(this.md5Checksum);
      _data.push(this.fileHash);
      _data.push(this.secret);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly parts: TLInt,
      readonly md5Checksum: TLString,
      readonly fileHash: TLBytes,
      readonly secret: TLBytes
    ) {}
  }

  export class InputSecureFile implements TLObject {
    static readonly cons = new TLInt(0x5367e5be);

    static deserialized(_data: ByteStream): InputSecureFile | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputSecureFile.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputSecureFile(id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputSecureFile.cons];

      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly accessHash: TLLong) {}
  }

  export class SecureFileEmpty implements TLObject {
    static readonly cons = new TLInt(0x64199744);

    static deserialized(_data: ByteStream): SecureFileEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureFileEmpty.cons))
        return undefined;

      return new SecureFileEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureFileEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureFile implements TLObject {
    static readonly cons = new TLInt(0x7d09c27e);

    static deserialized(_data: ByteStream): SecureFile | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureFile.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const size = TLLong.deserialized(_data);
      if (!size) return undefined;
      const dcId = TLInt.deserialized(_data);
      if (!dcId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const fileHash = TLBytes.deserialized(_data);
      if (!fileHash) return undefined;
      const secret = TLBytes.deserialized(_data);
      if (!secret) return undefined;

      return new SecureFile(id, accessHash, size, dcId, date, fileHash, secret);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureFile.cons];

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.size);
      _data.push(this.dcId);
      _data.push(this.date);
      _data.push(this.fileHash);
      _data.push(this.secret);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly size: TLLong,
      readonly dcId: TLInt,
      readonly date: TLInt,
      readonly fileHash: TLBytes,
      readonly secret: TLBytes
    ) {}
  }

  export class SecureData implements TLObject {
    static readonly cons = new TLInt(0x8aeabec3);

    static deserialized(_data: ByteStream): SecureData | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureData.cons))
        return undefined;

      const data = TLBytes.deserialized(_data);
      if (!data) return undefined;
      const dataHash = TLBytes.deserialized(_data);
      if (!dataHash) return undefined;
      const secret = TLBytes.deserialized(_data);
      if (!secret) return undefined;

      return new SecureData(data, dataHash, secret);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureData.cons];

      _data.push(this.data);
      _data.push(this.dataHash);
      _data.push(this.secret);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly data: TLBytes,
      readonly dataHash: TLBytes,
      readonly secret: TLBytes
    ) {}
  }

  export class SecurePlainPhone implements TLObject {
    static readonly cons = new TLInt(0x7d6099dd);

    static deserialized(_data: ByteStream): SecurePlainPhone | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecurePlainPhone.cons))
        return undefined;

      const phone = TLString.deserialized(_data);
      if (!phone) return undefined;

      return new SecurePlainPhone(phone);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecurePlainPhone.cons];

      _data.push(this.phone);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly phone: TLString) {}
  }

  export class SecurePlainEmail implements TLObject {
    static readonly cons = new TLInt(0x21ec5a5f);

    static deserialized(_data: ByteStream): SecurePlainEmail | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecurePlainEmail.cons))
        return undefined;

      const email = TLString.deserialized(_data);
      if (!email) return undefined;

      return new SecurePlainEmail(email);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecurePlainEmail.cons];

      _data.push(this.email);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly email: TLString) {}
  }

  export class SecureValueTypePersonalDetails implements TLObject {
    static readonly cons = new TLInt(0x9d2a81e3);

    static deserialized(
      _data: ByteStream
    ): SecureValueTypePersonalDetails | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SecureValueTypePersonalDetails.cons)
      )
        return undefined;

      return new SecureValueTypePersonalDetails();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SecureValueTypePersonalDetails.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypePassport implements TLObject {
    static readonly cons = new TLInt(0x3dac6a00);

    static deserialized(
      _data: ByteStream
    ): SecureValueTypePassport | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueTypePassport.cons))
        return undefined;

      return new SecureValueTypePassport();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueTypePassport.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypeDriverLicense implements TLObject {
    static readonly cons = new TLInt(0x6e425c4);

    static deserialized(
      _data: ByteStream
    ): SecureValueTypeDriverLicense | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SecureValueTypeDriverLicense.cons)
      )
        return undefined;

      return new SecureValueTypeDriverLicense();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueTypeDriverLicense.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypeIdentityCard implements TLObject {
    static readonly cons = new TLInt(0xa0d0744b);

    static deserialized(
      _data: ByteStream
    ): SecureValueTypeIdentityCard | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueTypeIdentityCard.cons))
        return undefined;

      return new SecureValueTypeIdentityCard();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueTypeIdentityCard.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypeInternalPassport implements TLObject {
    static readonly cons = new TLInt(0x99a48f23);

    static deserialized(
      _data: ByteStream
    ): SecureValueTypeInternalPassport | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SecureValueTypeInternalPassport.cons)
      )
        return undefined;

      return new SecureValueTypeInternalPassport();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SecureValueTypeInternalPassport.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypeAddress implements TLObject {
    static readonly cons = new TLInt(0xcbe31e26);

    static deserialized(_data: ByteStream): SecureValueTypeAddress | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueTypeAddress.cons))
        return undefined;

      return new SecureValueTypeAddress();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueTypeAddress.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypeUtilityBill implements TLObject {
    static readonly cons = new TLInt(0xfc36954e);

    static deserialized(
      _data: ByteStream
    ): SecureValueTypeUtilityBill | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueTypeUtilityBill.cons))
        return undefined;

      return new SecureValueTypeUtilityBill();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueTypeUtilityBill.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypeBankStatement implements TLObject {
    static readonly cons = new TLInt(0x89137c0d);

    static deserialized(
      _data: ByteStream
    ): SecureValueTypeBankStatement | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SecureValueTypeBankStatement.cons)
      )
        return undefined;

      return new SecureValueTypeBankStatement();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueTypeBankStatement.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypeRentalAgreement implements TLObject {
    static readonly cons = new TLInt(0x8b883488);

    static deserialized(
      _data: ByteStream
    ): SecureValueTypeRentalAgreement | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SecureValueTypeRentalAgreement.cons)
      )
        return undefined;

      return new SecureValueTypeRentalAgreement();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SecureValueTypeRentalAgreement.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypePassportRegistration implements TLObject {
    static readonly cons = new TLInt(0x99e3806a);

    static deserialized(
      _data: ByteStream
    ): SecureValueTypePassportRegistration | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SecureValueTypePassportRegistration.cons)
      )
        return undefined;

      return new SecureValueTypePassportRegistration();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SecureValueTypePassportRegistration.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypeTemporaryRegistration implements TLObject {
    static readonly cons = new TLInt(0xea02ec33);

    static deserialized(
      _data: ByteStream
    ): SecureValueTypeTemporaryRegistration | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SecureValueTypeTemporaryRegistration.cons)
      )
        return undefined;

      return new SecureValueTypeTemporaryRegistration();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SecureValueTypeTemporaryRegistration.cons,
      ];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypePhone implements TLObject {
    static readonly cons = new TLInt(0xb320aadb);

    static deserialized(_data: ByteStream): SecureValueTypePhone | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueTypePhone.cons))
        return undefined;

      return new SecureValueTypePhone();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueTypePhone.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValueTypeEmail implements TLObject {
    static readonly cons = new TLInt(0x8e3ca7ee);

    static deserialized(_data: ByteStream): SecureValueTypeEmail | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueTypeEmail.cons))
        return undefined;

      return new SecureValueTypeEmail();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueTypeEmail.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecureValue implements TLObject {
    static readonly cons = new TLInt(0x187fa0ca);

    static deserialized(_data: ByteStream): SecureValue | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValue.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      let data: API.SecureData | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as API.SecureData;
        if (!obj) return undefined;
        data = obj;
      }
      let frontSide: SecureFileType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as SecureFileType;
        if (!obj) return undefined;
        frontSide = obj;
      }
      let reverseSide: SecureFileType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as SecureFileType;
        if (!obj) return undefined;
        reverseSide = obj;
      }
      let selfie: SecureFileType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as SecureFileType;
        if (!obj) return undefined;
        selfie = obj;
      }
      let translation: TLVector<SecureFileType> | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<SecureFileType>;
        if (!obj) return undefined;
        translation = obj;
      }
      let files: TLVector<SecureFileType> | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<SecureFileType>;
        if (!obj) return undefined;
        files = obj;
      }
      let plainData: SecurePlainDataType | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLObject.deserialized(_data) as SecurePlainDataType;
        if (!obj) return undefined;
        plainData = obj;
      }
      const hash = TLBytes.deserialized(_data);
      if (!hash) return undefined;

      return new SecureValue(
        type,
        data,
        frontSide,
        reverseSide,
        selfie,
        translation,
        files,
        plainData,
        hash
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValue.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.type);
      if (this.data) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.data) _data.push(this.data);

      if (this.frontSide) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.frontSide) _data.push(this.frontSide);

      if (this.reverseSide) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.reverseSide) _data.push(this.reverseSide);

      if (this.selfie) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.selfie) _data.push(this.selfie);

      if (this.translation) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.translation) _data.push(this.translation);

      if (this.files) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.files) _data.push(this.files);

      if (this.plainData) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.plainData) _data.push(this.plainData);

      _data.push(this.hash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: SecureValueTypeType,
      readonly data: API.SecureData | undefined,
      readonly frontSide: SecureFileType | undefined,
      readonly reverseSide: SecureFileType | undefined,
      readonly selfie: SecureFileType | undefined,
      readonly translation: TLVector<SecureFileType> | undefined,
      readonly files: TLVector<SecureFileType> | undefined,
      readonly plainData: SecurePlainDataType | undefined,
      readonly hash: TLBytes
    ) {}
  }

  export class InputSecureValue implements TLObject {
    static readonly cons = new TLInt(0xdb21d0a7);

    static deserialized(_data: ByteStream): InputSecureValue | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputSecureValue.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      let data: API.SecureData | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as API.SecureData;
        if (!obj) return undefined;
        data = obj;
      }
      let frontSide: InputSecureFileType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as InputSecureFileType;
        if (!obj) return undefined;
        frontSide = obj;
      }
      let reverseSide: InputSecureFileType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as InputSecureFileType;
        if (!obj) return undefined;
        reverseSide = obj;
      }
      let selfie: InputSecureFileType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as InputSecureFileType;
        if (!obj) return undefined;
        selfie = obj;
      }
      let translation: TLVector<InputSecureFileType> | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLVector.deserialized(
          _data
        ) as TLVector<InputSecureFileType>;
        if (!obj) return undefined;
        translation = obj;
      }
      let files: TLVector<InputSecureFileType> | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLVector.deserialized(
          _data
        ) as TLVector<InputSecureFileType>;
        if (!obj) return undefined;
        files = obj;
      }
      let plainData: SecurePlainDataType | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLObject.deserialized(_data) as SecurePlainDataType;
        if (!obj) return undefined;
        plainData = obj;
      }

      return new InputSecureValue(
        type,
        data,
        frontSide,
        reverseSide,
        selfie,
        translation,
        files,
        plainData
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputSecureValue.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.type);
      if (this.data) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.data) _data.push(this.data);

      if (this.frontSide) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.frontSide) _data.push(this.frontSide);

      if (this.reverseSide) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.reverseSide) _data.push(this.reverseSide);

      if (this.selfie) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.selfie) _data.push(this.selfie);

      if (this.translation) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.translation) _data.push(this.translation);

      if (this.files) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.files) _data.push(this.files);

      if (this.plainData) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.plainData) _data.push(this.plainData);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: SecureValueTypeType,
      readonly data: API.SecureData | undefined,
      readonly frontSide: InputSecureFileType | undefined,
      readonly reverseSide: InputSecureFileType | undefined,
      readonly selfie: InputSecureFileType | undefined,
      readonly translation: TLVector<InputSecureFileType> | undefined,
      readonly files: TLVector<InputSecureFileType> | undefined,
      readonly plainData: SecurePlainDataType | undefined
    ) {}
  }

  export class SecureValueHash implements TLObject {
    static readonly cons = new TLInt(0xed1ecdb0);

    static deserialized(_data: ByteStream): SecureValueHash | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueHash.cons))
        return undefined;

      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      const hash = TLBytes.deserialized(_data);
      if (!hash) return undefined;

      return new SecureValueHash(type, hash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueHash.cons];

      _data.push(this.type);
      _data.push(this.hash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly type: SecureValueTypeType, readonly hash: TLBytes) {}
  }

  export class SecureValueErrorData implements TLObject {
    static readonly cons = new TLInt(0xe8a40bd9);

    static deserialized(_data: ByteStream): SecureValueErrorData | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueErrorData.cons))
        return undefined;

      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      const dataHash = TLBytes.deserialized(_data);
      if (!dataHash) return undefined;
      const field = TLString.deserialized(_data);
      if (!field) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new SecureValueErrorData(type, dataHash, field, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueErrorData.cons];

      _data.push(this.type);
      _data.push(this.dataHash);
      _data.push(this.field);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: SecureValueTypeType,
      readonly dataHash: TLBytes,
      readonly field: TLString,
      readonly text: TLString
    ) {}
  }

  export class SecureValueErrorFrontSide implements TLObject {
    static readonly cons = new TLInt(0xbe3dfa);

    static deserialized(
      _data: ByteStream
    ): SecureValueErrorFrontSide | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueErrorFrontSide.cons))
        return undefined;

      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      const fileHash = TLBytes.deserialized(_data);
      if (!fileHash) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new SecureValueErrorFrontSide(type, fileHash, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueErrorFrontSide.cons];

      _data.push(this.type);
      _data.push(this.fileHash);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: SecureValueTypeType,
      readonly fileHash: TLBytes,
      readonly text: TLString
    ) {}
  }

  export class SecureValueErrorReverseSide implements TLObject {
    static readonly cons = new TLInt(0x868a2aa5);

    static deserialized(
      _data: ByteStream
    ): SecureValueErrorReverseSide | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueErrorReverseSide.cons))
        return undefined;

      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      const fileHash = TLBytes.deserialized(_data);
      if (!fileHash) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new SecureValueErrorReverseSide(type, fileHash, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueErrorReverseSide.cons];

      _data.push(this.type);
      _data.push(this.fileHash);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: SecureValueTypeType,
      readonly fileHash: TLBytes,
      readonly text: TLString
    ) {}
  }

  export class SecureValueErrorSelfie implements TLObject {
    static readonly cons = new TLInt(0xe537ced6);

    static deserialized(_data: ByteStream): SecureValueErrorSelfie | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueErrorSelfie.cons))
        return undefined;

      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      const fileHash = TLBytes.deserialized(_data);
      if (!fileHash) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new SecureValueErrorSelfie(type, fileHash, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueErrorSelfie.cons];

      _data.push(this.type);
      _data.push(this.fileHash);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: SecureValueTypeType,
      readonly fileHash: TLBytes,
      readonly text: TLString
    ) {}
  }

  export class SecureValueErrorFile implements TLObject {
    static readonly cons = new TLInt(0x7a700873);

    static deserialized(_data: ByteStream): SecureValueErrorFile | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueErrorFile.cons))
        return undefined;

      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      const fileHash = TLBytes.deserialized(_data);
      if (!fileHash) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new SecureValueErrorFile(type, fileHash, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueErrorFile.cons];

      _data.push(this.type);
      _data.push(this.fileHash);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: SecureValueTypeType,
      readonly fileHash: TLBytes,
      readonly text: TLString
    ) {}
  }

  export class SecureValueErrorFiles implements TLObject {
    static readonly cons = new TLInt(0x666220e9);

    static deserialized(_data: ByteStream): SecureValueErrorFiles | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueErrorFiles.cons))
        return undefined;

      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      const fileHash = TLVector.deserialized(_data, TLBytes);
      if (!fileHash) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new SecureValueErrorFiles(type, fileHash, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueErrorFiles.cons];

      _data.push(this.type);
      _data.push(this.fileHash);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: SecureValueTypeType,
      readonly fileHash: TLVector<TLBytes>,
      readonly text: TLString
    ) {}
  }

  export class SecureValueError implements TLObject {
    static readonly cons = new TLInt(0x869d758f);

    static deserialized(_data: ByteStream): SecureValueError | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureValueError.cons))
        return undefined;

      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      const hash = TLBytes.deserialized(_data);
      if (!hash) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new SecureValueError(type, hash, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureValueError.cons];

      _data.push(this.type);
      _data.push(this.hash);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: SecureValueTypeType,
      readonly hash: TLBytes,
      readonly text: TLString
    ) {}
  }

  export class SecureValueErrorTranslationFile implements TLObject {
    static readonly cons = new TLInt(0xa1144770);

    static deserialized(
      _data: ByteStream
    ): SecureValueErrorTranslationFile | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SecureValueErrorTranslationFile.cons)
      )
        return undefined;

      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      const fileHash = TLBytes.deserialized(_data);
      if (!fileHash) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new SecureValueErrorTranslationFile(type, fileHash, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SecureValueErrorTranslationFile.cons,
      ];

      _data.push(this.type);
      _data.push(this.fileHash);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: SecureValueTypeType,
      readonly fileHash: TLBytes,
      readonly text: TLString
    ) {}
  }

  export class SecureValueErrorTranslationFiles implements TLObject {
    static readonly cons = new TLInt(0x34636dd8);

    static deserialized(
      _data: ByteStream
    ): SecureValueErrorTranslationFiles | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SecureValueErrorTranslationFiles.cons)
      )
        return undefined;

      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;
      const fileHash = TLVector.deserialized(_data, TLBytes);
      if (!fileHash) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new SecureValueErrorTranslationFiles(type, fileHash, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SecureValueErrorTranslationFiles.cons,
      ];

      _data.push(this.type);
      _data.push(this.fileHash);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: SecureValueTypeType,
      readonly fileHash: TLVector<TLBytes>,
      readonly text: TLString
    ) {}
  }

  export class SecureCredentialsEncrypted implements TLObject {
    static readonly cons = new TLInt(0x33f0ea47);

    static deserialized(
      _data: ByteStream
    ): SecureCredentialsEncrypted | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureCredentialsEncrypted.cons))
        return undefined;

      const data = TLBytes.deserialized(_data);
      if (!data) return undefined;
      const hash = TLBytes.deserialized(_data);
      if (!hash) return undefined;
      const secret = TLBytes.deserialized(_data);
      if (!secret) return undefined;

      return new SecureCredentialsEncrypted(data, hash, secret);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureCredentialsEncrypted.cons];

      _data.push(this.data);
      _data.push(this.hash);
      _data.push(this.secret);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly data: TLBytes,
      readonly hash: TLBytes,
      readonly secret: TLBytes
    ) {}
  }

  export namespace account {
    export class AuthorizationForm implements TLObject {
      static readonly cons = new TLInt(0xad2e1cd8);

      static deserialized(_data: ByteStream): AuthorizationForm | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AuthorizationForm.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const requiredTypes = TLVector.deserialized(
          _data
        ) as TLVector<SecureRequiredTypeType>;
        if (!requiredTypes) return undefined;
        const values = TLVector.deserialized(_data, API.SecureValue);
        if (!values) return undefined;
        const errors = TLVector.deserialized(
          _data
        ) as TLVector<SecureValueErrorType>;
        if (!errors) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;
        let privacyPolicyUrl: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          privacyPolicyUrl = obj;
        }

        return new AuthorizationForm(
          requiredTypes,
          values,
          errors,
          users,
          privacyPolicyUrl
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AuthorizationForm.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.requiredTypes);
        _data.push(this.values);
        _data.push(this.errors);
        _data.push(this.users);
        if (this.privacyPolicyUrl) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.privacyPolicyUrl) _data.push(this.privacyPolicyUrl);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly requiredTypes: TLVector<SecureRequiredTypeType>,
        readonly values: TLVector<API.SecureValue>,
        readonly errors: TLVector<SecureValueErrorType>,
        readonly users: TLVector<UserType>,
        readonly privacyPolicyUrl: TLString | undefined
      ) {}
    }
  }

  export namespace account {
    export class SentEmailCode implements TLObject {
      static readonly cons = new TLInt(0x811f854f);

      static deserialized(_data: ByteStream): SentEmailCode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SentEmailCode.cons))
          return undefined;

        const emailPattern = TLString.deserialized(_data);
        if (!emailPattern) return undefined;
        const length = TLInt.deserialized(_data);
        if (!length) return undefined;

        return new SentEmailCode(emailPattern, length);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SentEmailCode.cons];

        _data.push(this.emailPattern);
        _data.push(this.length);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly emailPattern: TLString, readonly length: TLInt) {}
    }
  }

  export namespace help {
    export class DeepLinkInfoEmpty implements TLObject {
      static readonly cons = new TLInt(0x66afa166);

      static deserialized(_data: ByteStream): DeepLinkInfoEmpty | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeepLinkInfoEmpty.cons))
          return undefined;

        return new DeepLinkInfoEmpty();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeepLinkInfoEmpty.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class DeepLinkInfo implements TLObject {
      static readonly cons = new TLInt(0x6a4ee832);

      static deserialized(_data: ByteStream): DeepLinkInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeepLinkInfo.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const updateApp = (flags.value & 1) !== 0;
        const message = TLString.deserialized(_data);
        if (!message) return undefined;
        let entities: TLVector<MessageEntityType> | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLVector.deserialized(
            _data
          ) as TLVector<MessageEntityType>;
          if (!obj) return undefined;
          entities = obj;
        }

        return new DeepLinkInfo(updateApp, message, entities);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeepLinkInfo.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.updateApp) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.message);
        if (this.entities) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.entities) _data.push(this.entities);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly updateApp: boolean | undefined,
        readonly message: TLString,
        readonly entities: TLVector<MessageEntityType> | undefined
      ) {}
    }
  }

  export class SavedPhoneContact implements TLObject {
    static readonly cons = new TLInt(0x1142bd56);

    static deserialized(_data: ByteStream): SavedPhoneContact | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SavedPhoneContact.cons))
        return undefined;

      const phone = TLString.deserialized(_data);
      if (!phone) return undefined;
      const firstName = TLString.deserialized(_data);
      if (!firstName) return undefined;
      const lastName = TLString.deserialized(_data);
      if (!lastName) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new SavedPhoneContact(phone, firstName, lastName, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SavedPhoneContact.cons];

      _data.push(this.phone);
      _data.push(this.firstName);
      _data.push(this.lastName);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly phone: TLString,
      readonly firstName: TLString,
      readonly lastName: TLString,
      readonly date: TLInt
    ) {}
  }

  export namespace account {
    export class Takeout implements TLObject {
      static readonly cons = new TLInt(0x4dba4501);

      static deserialized(_data: ByteStream): Takeout | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Takeout.cons)) return undefined;

        const id = TLLong.deserialized(_data);
        if (!id) return undefined;

        return new Takeout(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Takeout.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLLong) {}
    }
  }

  export class PasswordKdfAlgoUnknown implements TLObject {
    static readonly cons = new TLInt(0xd45ab096);

    static deserialized(_data: ByteStream): PasswordKdfAlgoUnknown | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PasswordKdfAlgoUnknown.cons))
        return undefined;

      return new PasswordKdfAlgoUnknown();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PasswordKdfAlgoUnknown.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class PasswordKdfAlgoSHA256SHA256PBKDF2HMACSHA512iter100000SHA256ModPow
    implements TLObject
  {
    static readonly cons = new TLInt(0x3a912d4a);

    static deserialized(
      _data: ByteStream
    ):
      | PasswordKdfAlgoSHA256SHA256PBKDF2HMACSHA512iter100000SHA256ModPow
      | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(
          PasswordKdfAlgoSHA256SHA256PBKDF2HMACSHA512iter100000SHA256ModPow.cons
        )
      )
        return undefined;

      const salt1 = TLBytes.deserialized(_data);
      if (!salt1) return undefined;
      const salt2 = TLBytes.deserialized(_data);
      if (!salt2) return undefined;
      const g = TLInt.deserialized(_data);
      if (!g) return undefined;
      const p = TLBytes.deserialized(_data);
      if (!p) return undefined;

      return new PasswordKdfAlgoSHA256SHA256PBKDF2HMACSHA512iter100000SHA256ModPow(
        salt1,
        salt2,
        g,
        p
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        PasswordKdfAlgoSHA256SHA256PBKDF2HMACSHA512iter100000SHA256ModPow.cons,
      ];

      _data.push(this.salt1);
      _data.push(this.salt2);
      _data.push(this.g);
      _data.push(this.p);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly salt1: TLBytes,
      readonly salt2: TLBytes,
      readonly g: TLInt,
      readonly p: TLBytes
    ) {}
  }

  export class SecurePasswordKdfAlgoUnknown implements TLObject {
    static readonly cons = new TLInt(0x4a8537);

    static deserialized(
      _data: ByteStream
    ): SecurePasswordKdfAlgoUnknown | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(SecurePasswordKdfAlgoUnknown.cons)
      )
        return undefined;

      return new SecurePasswordKdfAlgoUnknown();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecurePasswordKdfAlgoUnknown.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class SecurePasswordKdfAlgoPBKDF2HMACSHA512iter100000
    implements TLObject
  {
    static readonly cons = new TLInt(0xbbf2dda0);

    static deserialized(
      _data: ByteStream
    ): SecurePasswordKdfAlgoPBKDF2HMACSHA512iter100000 | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(
          SecurePasswordKdfAlgoPBKDF2HMACSHA512iter100000.cons
        )
      )
        return undefined;

      const salt = TLBytes.deserialized(_data);
      if (!salt) return undefined;

      return new SecurePasswordKdfAlgoPBKDF2HMACSHA512iter100000(salt);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        SecurePasswordKdfAlgoPBKDF2HMACSHA512iter100000.cons,
      ];

      _data.push(this.salt);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly salt: TLBytes) {}
  }

  export class SecurePasswordKdfAlgoSHA512 implements TLObject {
    static readonly cons = new TLInt(0x86471d92);

    static deserialized(
      _data: ByteStream
    ): SecurePasswordKdfAlgoSHA512 | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecurePasswordKdfAlgoSHA512.cons))
        return undefined;

      const salt = TLBytes.deserialized(_data);
      if (!salt) return undefined;

      return new SecurePasswordKdfAlgoSHA512(salt);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecurePasswordKdfAlgoSHA512.cons];

      _data.push(this.salt);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly salt: TLBytes) {}
  }

  export class SecureSecretSettings implements TLObject {
    static readonly cons = new TLInt(0x1527bcac);

    static deserialized(_data: ByteStream): SecureSecretSettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureSecretSettings.cons))
        return undefined;

      const secureAlgo = TLObject.deserialized(
        _data
      ) as SecurePasswordKdfAlgoType;
      if (!secureAlgo) return undefined;
      const secureSecret = TLBytes.deserialized(_data);
      if (!secureSecret) return undefined;
      const secureSecretId = TLLong.deserialized(_data);
      if (!secureSecretId) return undefined;

      return new SecureSecretSettings(secureAlgo, secureSecret, secureSecretId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureSecretSettings.cons];

      _data.push(this.secureAlgo);
      _data.push(this.secureSecret);
      _data.push(this.secureSecretId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly secureAlgo: SecurePasswordKdfAlgoType,
      readonly secureSecret: TLBytes,
      readonly secureSecretId: TLLong
    ) {}
  }

  export class InputCheckPasswordEmpty implements TLObject {
    static readonly cons = new TLInt(0x9880f658);

    static deserialized(
      _data: ByteStream
    ): InputCheckPasswordEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputCheckPasswordEmpty.cons))
        return undefined;

      return new InputCheckPasswordEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputCheckPasswordEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputCheckPasswordSRP implements TLObject {
    static readonly cons = new TLInt(0xd27ff082);

    static deserialized(_data: ByteStream): InputCheckPasswordSRP | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputCheckPasswordSRP.cons))
        return undefined;

      const srpId = TLLong.deserialized(_data);
      if (!srpId) return undefined;
      const a = TLBytes.deserialized(_data);
      if (!a) return undefined;
      const m1 = TLBytes.deserialized(_data);
      if (!m1) return undefined;

      return new InputCheckPasswordSRP(srpId, a, m1);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputCheckPasswordSRP.cons];

      _data.push(this.srpId);
      _data.push(this.a);
      _data.push(this.m1);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly srpId: TLLong,
      readonly a: TLBytes,
      readonly m1: TLBytes
    ) {}
  }

  export class SecureRequiredType implements TLObject {
    static readonly cons = new TLInt(0x829d99da);

    static deserialized(_data: ByteStream): SecureRequiredType | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureRequiredType.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const nativeNames = (flags.value & 1) !== 0;
      const selfieRequired = (flags.value & 2) !== 0;
      const translationRequired = (flags.value & 4) !== 0;
      const type = TLObject.deserialized(_data) as SecureValueTypeType;
      if (!type) return undefined;

      return new SecureRequiredType(
        nativeNames,
        selfieRequired,
        translationRequired,
        type
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureRequiredType.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.nativeNames) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.selfieRequired) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.translationRequired) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.type);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly nativeNames: boolean | undefined,
      readonly selfieRequired: boolean | undefined,
      readonly translationRequired: boolean | undefined,
      readonly type: SecureValueTypeType
    ) {}
  }

  export class SecureRequiredTypeOneOf implements TLObject {
    static readonly cons = new TLInt(0x27477b4);

    static deserialized(
      _data: ByteStream
    ): SecureRequiredTypeOneOf | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SecureRequiredTypeOneOf.cons))
        return undefined;

      const types = TLVector.deserialized(
        _data
      ) as TLVector<SecureRequiredTypeType>;
      if (!types) return undefined;

      return new SecureRequiredTypeOneOf(types);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SecureRequiredTypeOneOf.cons];

      _data.push(this.types);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly types: TLVector<SecureRequiredTypeType>) {}
  }

  export namespace help {
    export class PassportConfigNotModified implements TLObject {
      static readonly cons = new TLInt(0xbfb9f457);

      static deserialized(
        _data: ByteStream
      ): PassportConfigNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PassportConfigNotModified.cons))
          return undefined;

        return new PassportConfigNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PassportConfigNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class PassportConfig implements TLObject {
      static readonly cons = new TLInt(0xa098d6af);

      static deserialized(_data: ByteStream): PassportConfig | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PassportConfig.cons))
          return undefined;

        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;
        const countriesLangs = API.DataJSON.deserialized(_data);
        if (!countriesLangs) return undefined;

        return new PassportConfig(hash, countriesLangs);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PassportConfig.cons];

        _data.push(this.hash);
        _data.push(this.countriesLangs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLInt,
        readonly countriesLangs: API.DataJSON
      ) {}
    }
  }

  export class InputAppEvent implements TLObject {
    static readonly cons = new TLInt(0x1d1b1245);

    static deserialized(_data: ByteStream): InputAppEvent | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputAppEvent.cons))
        return undefined;

      const time = TLDouble.deserialized(_data);
      if (!time) return undefined;
      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      const peer = TLLong.deserialized(_data);
      if (!peer) return undefined;
      const data = TLObject.deserialized(_data) as JSONValueType;
      if (!data) return undefined;

      return new InputAppEvent(time, type, peer, data);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputAppEvent.cons];

      _data.push(this.time);
      _data.push(this.type);
      _data.push(this.peer);
      _data.push(this.data);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly time: TLDouble,
      readonly type: TLString,
      readonly peer: TLLong,
      readonly data: JSONValueType
    ) {}
  }

  export class JsonObjectValue implements TLObject {
    static readonly cons = new TLInt(0xc0de1bd9);

    static deserialized(_data: ByteStream): JsonObjectValue | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(JsonObjectValue.cons))
        return undefined;

      const key = TLString.deserialized(_data);
      if (!key) return undefined;
      const value = TLObject.deserialized(_data) as JSONValueType;
      if (!value) return undefined;

      return new JsonObjectValue(key, value);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [JsonObjectValue.cons];

      _data.push(this.key);
      _data.push(this.value);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly key: TLString, readonly value: JSONValueType) {}
  }

  export class JsonNull implements TLObject {
    static readonly cons = new TLInt(0x3f6d7b68);

    static deserialized(_data: ByteStream): JsonNull | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(JsonNull.cons)) return undefined;

      return new JsonNull();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [JsonNull.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class JsonBool implements TLObject {
    static readonly cons = new TLInt(0xc7345e6a);

    static deserialized(_data: ByteStream): JsonBool | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(JsonBool.cons)) return undefined;

      const value = TLObject.deserialized(_data) as BoolType;
      if (!value) return undefined;

      return new JsonBool(value);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [JsonBool.cons];

      _data.push(this.value);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly value: BoolType) {}
  }

  export class JsonNumber implements TLObject {
    static readonly cons = new TLInt(0x2be0dfa4);

    static deserialized(_data: ByteStream): JsonNumber | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(JsonNumber.cons))
        return undefined;

      const value = TLDouble.deserialized(_data);
      if (!value) return undefined;

      return new JsonNumber(value);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [JsonNumber.cons];

      _data.push(this.value);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly value: TLDouble) {}
  }

  export class JsonString implements TLObject {
    static readonly cons = new TLInt(0xb71e767a);

    static deserialized(_data: ByteStream): JsonString | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(JsonString.cons))
        return undefined;

      const value = TLString.deserialized(_data);
      if (!value) return undefined;

      return new JsonString(value);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [JsonString.cons];

      _data.push(this.value);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly value: TLString) {}
  }

  export class JsonArray implements TLObject {
    static readonly cons = new TLInt(0xf7444763);

    static deserialized(_data: ByteStream): JsonArray | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(JsonArray.cons)) return undefined;

      const value = TLVector.deserialized(_data) as TLVector<JSONValueType>;
      if (!value) return undefined;

      return new JsonArray(value);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [JsonArray.cons];

      _data.push(this.value);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly value: TLVector<JSONValueType>) {}
  }

  export class JsonObject implements TLObject {
    static readonly cons = new TLInt(0x99c1d49d);

    static deserialized(_data: ByteStream): JsonObject | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(JsonObject.cons))
        return undefined;

      const value = TLVector.deserialized(
        _data
      ) as TLVector<JSONObjectValueType>;
      if (!value) return undefined;

      return new JsonObject(value);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [JsonObject.cons];

      _data.push(this.value);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly value: TLVector<JSONObjectValueType>) {}
  }

  export class PageTableCell implements TLObject {
    static readonly cons = new TLInt(0x34566b6a);

    static deserialized(_data: ByteStream): PageTableCell | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageTableCell.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const header = (flags.value & 1) !== 0;
      const alignCenter = (flags.value & 8) !== 0;
      const alignRight = (flags.value & 16) !== 0;
      const valignMiddle = (flags.value & 32) !== 0;
      const valignBottom = (flags.value & 64) !== 0;
      let text: RichTextType | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLObject.deserialized(_data) as RichTextType;
        if (!obj) return undefined;
        text = obj;
      }
      let colspan: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        colspan = obj;
      }
      let rowspan: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        rowspan = obj;
      }

      return new PageTableCell(
        header,
        alignCenter,
        alignRight,
        valignMiddle,
        valignBottom,
        text,
        colspan,
        rowspan
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageTableCell.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.header) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.alignCenter) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.alignRight) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.valignMiddle) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.valignBottom) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      if (this.text) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.text) _data.push(this.text);

      if (this.colspan) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.colspan) _data.push(this.colspan);

      if (this.rowspan) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.rowspan) _data.push(this.rowspan);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly header: boolean | undefined,
      readonly alignCenter: boolean | undefined,
      readonly alignRight: boolean | undefined,
      readonly valignMiddle: boolean | undefined,
      readonly valignBottom: boolean | undefined,
      readonly text: RichTextType | undefined,
      readonly colspan: TLInt | undefined,
      readonly rowspan: TLInt | undefined
    ) {}
  }

  export class PageTableRow implements TLObject {
    static readonly cons = new TLInt(0xe0c0c5e5);

    static deserialized(_data: ByteStream): PageTableRow | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageTableRow.cons))
        return undefined;

      const cells = TLVector.deserialized(_data, API.PageTableCell);
      if (!cells) return undefined;

      return new PageTableRow(cells);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageTableRow.cons];

      _data.push(this.cells);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly cells: TLVector<API.PageTableCell>) {}
  }

  export class PageCaption implements TLObject {
    static readonly cons = new TLInt(0x6f747657);

    static deserialized(_data: ByteStream): PageCaption | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageCaption.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;
      const credit = TLObject.deserialized(_data) as RichTextType;
      if (!credit) return undefined;

      return new PageCaption(text, credit);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageCaption.cons];

      _data.push(this.text);
      _data.push(this.credit);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType, readonly credit: RichTextType) {}
  }

  export class PageListItemText implements TLObject {
    static readonly cons = new TLInt(0xb92fb6cd);

    static deserialized(_data: ByteStream): PageListItemText | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageListItemText.cons))
        return undefined;

      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new PageListItemText(text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageListItemText.cons];

      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: RichTextType) {}
  }

  export class PageListItemBlocks implements TLObject {
    static readonly cons = new TLInt(0x25e073fc);

    static deserialized(_data: ByteStream): PageListItemBlocks | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageListItemBlocks.cons))
        return undefined;

      const blocks = TLVector.deserialized(_data) as TLVector<PageBlockType>;
      if (!blocks) return undefined;

      return new PageListItemBlocks(blocks);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageListItemBlocks.cons];

      _data.push(this.blocks);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly blocks: TLVector<PageBlockType>) {}
  }

  export class PageListOrderedItemText implements TLObject {
    static readonly cons = new TLInt(0x5e068047);

    static deserialized(
      _data: ByteStream
    ): PageListOrderedItemText | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageListOrderedItemText.cons))
        return undefined;

      const num = TLString.deserialized(_data);
      if (!num) return undefined;
      const text = TLObject.deserialized(_data) as RichTextType;
      if (!text) return undefined;

      return new PageListOrderedItemText(num, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageListOrderedItemText.cons];

      _data.push(this.num);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly num: TLString, readonly text: RichTextType) {}
  }

  export class PageListOrderedItemBlocks implements TLObject {
    static readonly cons = new TLInt(0x98dd8936);

    static deserialized(
      _data: ByteStream
    ): PageListOrderedItemBlocks | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageListOrderedItemBlocks.cons))
        return undefined;

      const num = TLString.deserialized(_data);
      if (!num) return undefined;
      const blocks = TLVector.deserialized(_data) as TLVector<PageBlockType>;
      if (!blocks) return undefined;

      return new PageListOrderedItemBlocks(num, blocks);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageListOrderedItemBlocks.cons];

      _data.push(this.num);
      _data.push(this.blocks);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly num: TLString,
      readonly blocks: TLVector<PageBlockType>
    ) {}
  }

  export class PageRelatedArticle implements TLObject {
    static readonly cons = new TLInt(0xb390dc08);

    static deserialized(_data: ByteStream): PageRelatedArticle | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PageRelatedArticle.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const webpageId = TLLong.deserialized(_data);
      if (!webpageId) return undefined;
      let title: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        title = obj;
      }
      let description: TLString | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        description = obj;
      }
      let photoId: TLLong | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        photoId = obj;
      }
      let author: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        author = obj;
      }
      let publishedDate: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        publishedDate = obj;
      }

      return new PageRelatedArticle(
        url,
        webpageId,
        title,
        description,
        photoId,
        author,
        publishedDate
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PageRelatedArticle.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.url);
      _data.push(this.webpageId);
      if (this.title) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.title) _data.push(this.title);

      if (this.description) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.description) _data.push(this.description);

      if (this.photoId) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.photoId) _data.push(this.photoId);

      if (this.author) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.author) _data.push(this.author);

      if (this.publishedDate) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.publishedDate) _data.push(this.publishedDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly url: TLString,
      readonly webpageId: TLLong,
      readonly title: TLString | undefined,
      readonly description: TLString | undefined,
      readonly photoId: TLLong | undefined,
      readonly author: TLString | undefined,
      readonly publishedDate: TLInt | undefined
    ) {}
  }

  export class Page implements TLObject {
    static readonly cons = new TLInt(0x98657f0d);

    static deserialized(_data: ByteStream): Page | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Page.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const part = (flags.value & 1) !== 0;
      const rtl = (flags.value & 2) !== 0;
      const v2 = (flags.value & 4) !== 0;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const blocks = TLVector.deserialized(_data) as TLVector<PageBlockType>;
      if (!blocks) return undefined;
      const photos = TLVector.deserialized(_data) as TLVector<PhotoType>;
      if (!photos) return undefined;
      const documents = TLVector.deserialized(_data) as TLVector<DocumentType>;
      if (!documents) return undefined;
      let views: TLInt | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        views = obj;
      }

      return new Page(part, rtl, v2, url, blocks, photos, documents, views);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Page.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.part) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.rtl) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.v2) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.url);
      _data.push(this.blocks);
      _data.push(this.photos);
      _data.push(this.documents);
      if (this.views) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.views) _data.push(this.views);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly part: boolean | undefined,
      readonly rtl: boolean | undefined,
      readonly v2: boolean | undefined,
      readonly url: TLString,
      readonly blocks: TLVector<PageBlockType>,
      readonly photos: TLVector<PhotoType>,
      readonly documents: TLVector<DocumentType>,
      readonly views: TLInt | undefined
    ) {}
  }

  export namespace help {
    export class SupportName implements TLObject {
      static readonly cons = new TLInt(0x8c05f1c9);

      static deserialized(_data: ByteStream): SupportName | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SupportName.cons))
          return undefined;

        const name = TLString.deserialized(_data);
        if (!name) return undefined;

        return new SupportName(name);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SupportName.cons];

        _data.push(this.name);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly name: TLString) {}
    }
  }

  export namespace help {
    export class UserInfoEmpty implements TLObject {
      static readonly cons = new TLInt(0xf3ae2eed);

      static deserialized(_data: ByteStream): UserInfoEmpty | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UserInfoEmpty.cons))
          return undefined;

        return new UserInfoEmpty();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UserInfoEmpty.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class UserInfo implements TLObject {
      static readonly cons = new TLInt(0x1eb3758);

      static deserialized(_data: ByteStream): UserInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UserInfo.cons))
          return undefined;

        const message = TLString.deserialized(_data);
        if (!message) return undefined;
        const entities = TLVector.deserialized(
          _data
        ) as TLVector<MessageEntityType>;
        if (!entities) return undefined;
        const author = TLString.deserialized(_data);
        if (!author) return undefined;
        const date = TLInt.deserialized(_data);
        if (!date) return undefined;

        return new UserInfo(message, entities, author, date);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UserInfo.cons];

        _data.push(this.message);
        _data.push(this.entities);
        _data.push(this.author);
        _data.push(this.date);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly message: TLString,
        readonly entities: TLVector<MessageEntityType>,
        readonly author: TLString,
        readonly date: TLInt
      ) {}
    }
  }

  export class PollAnswer implements TLObject {
    static readonly cons = new TLInt(0x6ca9c2e9);

    static deserialized(_data: ByteStream): PollAnswer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PollAnswer.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const option = TLBytes.deserialized(_data);
      if (!option) return undefined;

      return new PollAnswer(text, option);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PollAnswer.cons];

      _data.push(this.text);
      _data.push(this.option);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString, readonly option: TLBytes) {}
  }

  export class Poll implements TLObject {
    static readonly cons = new TLInt(0x86e18161);

    static deserialized(_data: ByteStream): Poll | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Poll.cons)) return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const closed = (flags.value & 1) !== 0;
      const publicVoters = (flags.value & 2) !== 0;
      const multipleChoice = (flags.value & 4) !== 0;
      const quiz = (flags.value & 8) !== 0;
      const question = TLString.deserialized(_data);
      if (!question) return undefined;
      const answers = TLVector.deserialized(_data, API.PollAnswer);
      if (!answers) return undefined;
      let closePeriod: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        closePeriod = obj;
      }
      let closeDate: TLInt | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        closeDate = obj;
      }

      return new Poll(
        id,
        closed,
        publicVoters,
        multipleChoice,
        quiz,
        question,
        answers,
        closePeriod,
        closeDate
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Poll.cons];

      _data.push(this.id);
      const flags = new TLFlags();
      _data.push(flags);
      if (this.closed) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.publicVoters) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.multipleChoice) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.quiz) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      _data.push(this.question);
      _data.push(this.answers);
      if (this.closePeriod) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.closePeriod) _data.push(this.closePeriod);

      if (this.closeDate) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.closeDate) _data.push(this.closeDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly closed: boolean | undefined,
      readonly publicVoters: boolean | undefined,
      readonly multipleChoice: boolean | undefined,
      readonly quiz: boolean | undefined,
      readonly question: TLString,
      readonly answers: TLVector<API.PollAnswer>,
      readonly closePeriod: TLInt | undefined,
      readonly closeDate: TLInt | undefined
    ) {}
  }

  export class PollAnswerVoters implements TLObject {
    static readonly cons = new TLInt(0x3b6ddad2);

    static deserialized(_data: ByteStream): PollAnswerVoters | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PollAnswerVoters.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const chosen = (flags.value & 1) !== 0;
      const correct = (flags.value & 2) !== 0;
      const option = TLBytes.deserialized(_data);
      if (!option) return undefined;
      const voters = TLInt.deserialized(_data);
      if (!voters) return undefined;

      return new PollAnswerVoters(chosen, correct, option, voters);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PollAnswerVoters.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.chosen) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.correct) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.option);
      _data.push(this.voters);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly chosen: boolean | undefined,
      readonly correct: boolean | undefined,
      readonly option: TLBytes,
      readonly voters: TLInt
    ) {}
  }

  export class PollResults implements TLObject {
    static readonly cons = new TLInt(0x7adf2420);

    static deserialized(_data: ByteStream): PollResults | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PollResults.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const min = (flags.value & 1) !== 0;
      let results: TLVector<API.PollAnswerVoters> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data, API.PollAnswerVoters);
        if (!obj) return undefined;
        results = obj;
      }
      let totalVoters: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        totalVoters = obj;
      }
      let recentVoters: TLVector<PeerType> | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<PeerType>;
        if (!obj) return undefined;
        recentVoters = obj;
      }
      let solution: TLString | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        solution = obj;
      }
      let solutionEntities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        solutionEntities = obj;
      }

      return new PollResults(
        min,
        results,
        totalVoters,
        recentVoters,
        solution,
        solutionEntities
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PollResults.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.min) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.results) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.results) _data.push(this.results);

      if (this.totalVoters) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.totalVoters) _data.push(this.totalVoters);

      if (this.recentVoters) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.recentVoters) _data.push(this.recentVoters);

      if (this.solution) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.solution) _data.push(this.solution);

      if (this.solutionEntities) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.solutionEntities) _data.push(this.solutionEntities);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly min: boolean | undefined,
      readonly results: TLVector<API.PollAnswerVoters> | undefined,
      readonly totalVoters: TLInt | undefined,
      readonly recentVoters: TLVector<PeerType> | undefined,
      readonly solution: TLString | undefined,
      readonly solutionEntities: TLVector<MessageEntityType> | undefined
    ) {}
  }

  export class ChatOnlines implements TLObject {
    static readonly cons = new TLInt(0xf041e250);

    static deserialized(_data: ByteStream): ChatOnlines | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatOnlines.cons))
        return undefined;

      const onlines = TLInt.deserialized(_data);
      if (!onlines) return undefined;

      return new ChatOnlines(onlines);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatOnlines.cons];

      _data.push(this.onlines);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly onlines: TLInt) {}
  }

  export class StatsURL implements TLObject {
    static readonly cons = new TLInt(0x47a971e0);

    static deserialized(_data: ByteStream): StatsURL | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StatsURL.cons)) return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new StatsURL(url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StatsURL.cons];

      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString) {}
  }

  export class ChatAdminRights implements TLObject {
    static readonly cons = new TLInt(0x5fb224d5);

    static deserialized(_data: ByteStream): ChatAdminRights | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatAdminRights.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const changeInfo = (flags.value & 1) !== 0;
      const postMessages = (flags.value & 2) !== 0;
      const editMessages = (flags.value & 4) !== 0;
      const deleteMessages = (flags.value & 8) !== 0;
      const banUsers = (flags.value & 16) !== 0;
      const inviteUsers = (flags.value & 32) !== 0;
      const pinMessages = (flags.value & 128) !== 0;
      const addAdmins = (flags.value & 512) !== 0;
      const anonymous = (flags.value & 1024) !== 0;
      const manageCall = (flags.value & 2048) !== 0;
      const other = (flags.value & 4096) !== 0;
      const manageTopics = (flags.value & 8192) !== 0;
      const postStories = (flags.value & 16384) !== 0;
      const editStories = (flags.value & 32768) !== 0;
      const deleteStories = (flags.value & 65536) !== 0;

      return new ChatAdminRights(
        changeInfo,
        postMessages,
        editMessages,
        deleteMessages,
        banUsers,
        inviteUsers,
        pinMessages,
        addAdmins,
        anonymous,
        manageCall,
        other,
        manageTopics,
        postStories,
        editStories,
        deleteStories
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatAdminRights.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.changeInfo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.postMessages) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.editMessages) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.deleteMessages) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.banUsers) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.inviteUsers) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.pinMessages) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.addAdmins) {
        flags.set(512);
      } else {
        flags.unset(512);
      }

      if (this.anonymous) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }

      if (this.manageCall) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }

      if (this.other) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }

      if (this.manageTopics) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }

      if (this.postStories) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }

      if (this.editStories) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }

      if (this.deleteStories) {
        flags.set(65536);
      } else {
        flags.unset(65536);
      }

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly changeInfo: boolean | undefined,
      readonly postMessages: boolean | undefined,
      readonly editMessages: boolean | undefined,
      readonly deleteMessages: boolean | undefined,
      readonly banUsers: boolean | undefined,
      readonly inviteUsers: boolean | undefined,
      readonly pinMessages: boolean | undefined,
      readonly addAdmins: boolean | undefined,
      readonly anonymous: boolean | undefined,
      readonly manageCall: boolean | undefined,
      readonly other: boolean | undefined,
      readonly manageTopics: boolean | undefined,
      readonly postStories: boolean | undefined,
      readonly editStories: boolean | undefined,
      readonly deleteStories: boolean | undefined
    ) {}
  }

  export class ChatBannedRights implements TLObject {
    static readonly cons = new TLInt(0x9f120418);

    static deserialized(_data: ByteStream): ChatBannedRights | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatBannedRights.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const viewMessages = (flags.value & 1) !== 0;
      const sendMessages = (flags.value & 2) !== 0;
      const sendMedia = (flags.value & 4) !== 0;
      const sendStickers = (flags.value & 8) !== 0;
      const sendGifs = (flags.value & 16) !== 0;
      const sendGames = (flags.value & 32) !== 0;
      const sendInline = (flags.value & 64) !== 0;
      const embedLinks = (flags.value & 128) !== 0;
      const sendPolls = (flags.value & 256) !== 0;
      const changeInfo = (flags.value & 1024) !== 0;
      const inviteUsers = (flags.value & 32768) !== 0;
      const pinMessages = (flags.value & 131072) !== 0;
      const manageTopics = (flags.value & 262144) !== 0;
      const sendPhotos = (flags.value & 524288) !== 0;
      const sendVideos = (flags.value & 1048576) !== 0;
      const sendRoundvideos = (flags.value & 2097152) !== 0;
      const sendAudios = (flags.value & 4194304) !== 0;
      const sendVoices = (flags.value & 8388608) !== 0;
      const sendDocs = (flags.value & 16777216) !== 0;
      const sendPlain = (flags.value & 33554432) !== 0;
      const untilDate = TLInt.deserialized(_data);
      if (!untilDate) return undefined;

      return new ChatBannedRights(
        viewMessages,
        sendMessages,
        sendMedia,
        sendStickers,
        sendGifs,
        sendGames,
        sendInline,
        embedLinks,
        sendPolls,
        changeInfo,
        inviteUsers,
        pinMessages,
        manageTopics,
        sendPhotos,
        sendVideos,
        sendRoundvideos,
        sendAudios,
        sendVoices,
        sendDocs,
        sendPlain,
        untilDate
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatBannedRights.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.viewMessages) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.sendMessages) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.sendMedia) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.sendStickers) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.sendGifs) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.sendGames) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.sendInline) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      if (this.embedLinks) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.sendPolls) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      if (this.changeInfo) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }

      if (this.inviteUsers) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }

      if (this.pinMessages) {
        flags.set(131072);
      } else {
        flags.unset(131072);
      }

      if (this.manageTopics) {
        flags.set(262144);
      } else {
        flags.unset(262144);
      }

      if (this.sendPhotos) {
        flags.set(524288);
      } else {
        flags.unset(524288);
      }

      if (this.sendVideos) {
        flags.set(1048576);
      } else {
        flags.unset(1048576);
      }

      if (this.sendRoundvideos) {
        flags.set(2097152);
      } else {
        flags.unset(2097152);
      }

      if (this.sendAudios) {
        flags.set(4194304);
      } else {
        flags.unset(4194304);
      }

      if (this.sendVoices) {
        flags.set(8388608);
      } else {
        flags.unset(8388608);
      }

      if (this.sendDocs) {
        flags.set(16777216);
      } else {
        flags.unset(16777216);
      }

      if (this.sendPlain) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }

      _data.push(this.untilDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly viewMessages: boolean | undefined,
      readonly sendMessages: boolean | undefined,
      readonly sendMedia: boolean | undefined,
      readonly sendStickers: boolean | undefined,
      readonly sendGifs: boolean | undefined,
      readonly sendGames: boolean | undefined,
      readonly sendInline: boolean | undefined,
      readonly embedLinks: boolean | undefined,
      readonly sendPolls: boolean | undefined,
      readonly changeInfo: boolean | undefined,
      readonly inviteUsers: boolean | undefined,
      readonly pinMessages: boolean | undefined,
      readonly manageTopics: boolean | undefined,
      readonly sendPhotos: boolean | undefined,
      readonly sendVideos: boolean | undefined,
      readonly sendRoundvideos: boolean | undefined,
      readonly sendAudios: boolean | undefined,
      readonly sendVoices: boolean | undefined,
      readonly sendDocs: boolean | undefined,
      readonly sendPlain: boolean | undefined,
      readonly untilDate: TLInt
    ) {}
  }

  export class InputWallPaper implements TLObject {
    static readonly cons = new TLInt(0xe630b979);

    static deserialized(_data: ByteStream): InputWallPaper | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputWallPaper.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputWallPaper(id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputWallPaper.cons];

      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputWallPaperSlug implements TLObject {
    static readonly cons = new TLInt(0x72091c80);

    static deserialized(_data: ByteStream): InputWallPaperSlug | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputWallPaperSlug.cons))
        return undefined;

      const slug = TLString.deserialized(_data);
      if (!slug) return undefined;

      return new InputWallPaperSlug(slug);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputWallPaperSlug.cons];

      _data.push(this.slug);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly slug: TLString) {}
  }

  export class InputWallPaperNoFile implements TLObject {
    static readonly cons = new TLInt(0x967a462e);

    static deserialized(_data: ByteStream): InputWallPaperNoFile | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputWallPaperNoFile.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;

      return new InputWallPaperNoFile(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputWallPaperNoFile.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong) {}
  }

  export namespace account {
    export class WallPapersNotModified implements TLObject {
      static readonly cons = new TLInt(0x1c199183);

      static deserialized(
        _data: ByteStream
      ): WallPapersNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(WallPapersNotModified.cons))
          return undefined;

        return new WallPapersNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [WallPapersNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class WallPapers implements TLObject {
      static readonly cons = new TLInt(0xcdc3858c);

      static deserialized(_data: ByteStream): WallPapers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(WallPapers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const wallpapers = TLVector.deserialized(
          _data
        ) as TLVector<WallPaperType>;
        if (!wallpapers) return undefined;

        return new WallPapers(hash, wallpapers);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [WallPapers.cons];

        _data.push(this.hash);
        _data.push(this.wallpapers);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLLong,
        readonly wallpapers: TLVector<WallPaperType>
      ) {}
    }
  }

  export class CodeSettings implements TLObject {
    static readonly cons = new TLInt(0xad253d78);

    static deserialized(_data: ByteStream): CodeSettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(CodeSettings.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const allowFlashcall = (flags.value & 1) !== 0;
      const currentNumber = (flags.value & 2) !== 0;
      const allowAppHash = (flags.value & 16) !== 0;
      const allowMissedCall = (flags.value & 32) !== 0;
      const allowFirebase = (flags.value & 128) !== 0;
      let logoutTokens: TLVector<TLBytes> | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLVector.deserialized(_data, TLBytes);
        if (!obj) return undefined;
        logoutTokens = obj;
      }
      let token: TLString | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        token = obj;
      }
      let appSandbox: BoolType | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        appSandbox = obj;
      }

      return new CodeSettings(
        allowFlashcall,
        currentNumber,
        allowAppHash,
        allowMissedCall,
        allowFirebase,
        logoutTokens,
        token,
        appSandbox
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [CodeSettings.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.allowFlashcall) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.currentNumber) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.allowAppHash) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.allowMissedCall) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.allowFirebase) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.logoutTokens) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.logoutTokens) _data.push(this.logoutTokens);

      if (this.token) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.token) _data.push(this.token);

      if (this.appSandbox) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.appSandbox) _data.push(this.appSandbox);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly allowFlashcall: boolean | undefined,
      readonly currentNumber: boolean | undefined,
      readonly allowAppHash: boolean | undefined,
      readonly allowMissedCall: boolean | undefined,
      readonly allowFirebase: boolean | undefined,
      readonly logoutTokens: TLVector<TLBytes> | undefined,
      readonly token: TLString | undefined,
      readonly appSandbox: BoolType | undefined
    ) {}
  }

  export class WallPaperSettings implements TLObject {
    static readonly cons = new TLInt(0x1dc1bca4);

    static deserialized(_data: ByteStream): WallPaperSettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WallPaperSettings.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const blur = (flags.value & 2) !== 0;
      const motion = (flags.value & 4) !== 0;
      let backgroundColor: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        backgroundColor = obj;
      }
      let secondBackgroundColor: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        secondBackgroundColor = obj;
      }
      let thirdBackgroundColor: TLInt | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        thirdBackgroundColor = obj;
      }
      let fourthBackgroundColor: TLInt | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        fourthBackgroundColor = obj;
      }
      let intensity: TLInt | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        intensity = obj;
      }
      let rotation: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        rotation = obj;
      }

      return new WallPaperSettings(
        blur,
        motion,
        backgroundColor,
        secondBackgroundColor,
        thirdBackgroundColor,
        fourthBackgroundColor,
        intensity,
        rotation
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WallPaperSettings.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.blur) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.motion) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.backgroundColor) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.backgroundColor) _data.push(this.backgroundColor);

      if (this.secondBackgroundColor) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.secondBackgroundColor) _data.push(this.secondBackgroundColor);

      if (this.thirdBackgroundColor) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.thirdBackgroundColor) _data.push(this.thirdBackgroundColor);

      if (this.fourthBackgroundColor) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.fourthBackgroundColor) _data.push(this.fourthBackgroundColor);

      if (this.intensity) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.intensity) _data.push(this.intensity);

      if (this.rotation) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.rotation) _data.push(this.rotation);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly blur: boolean | undefined,
      readonly motion: boolean | undefined,
      readonly backgroundColor: TLInt | undefined,
      readonly secondBackgroundColor: TLInt | undefined,
      readonly thirdBackgroundColor: TLInt | undefined,
      readonly fourthBackgroundColor: TLInt | undefined,
      readonly intensity: TLInt | undefined,
      readonly rotation: TLInt | undefined
    ) {}
  }

  export class AutoDownloadSettings implements TLObject {
    static readonly cons = new TLInt(0xbaa57628);

    static deserialized(_data: ByteStream): AutoDownloadSettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AutoDownloadSettings.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const disabled = (flags.value & 1) !== 0;
      const videoPreloadLarge = (flags.value & 2) !== 0;
      const audioPreloadNext = (flags.value & 4) !== 0;
      const phonecallsLessData = (flags.value & 8) !== 0;
      const storiesPreload = (flags.value & 16) !== 0;
      const photoSizeMax = TLInt.deserialized(_data);
      if (!photoSizeMax) return undefined;
      const videoSizeMax = TLLong.deserialized(_data);
      if (!videoSizeMax) return undefined;
      const fileSizeMax = TLLong.deserialized(_data);
      if (!fileSizeMax) return undefined;
      const videoUploadMaxbitrate = TLInt.deserialized(_data);
      if (!videoUploadMaxbitrate) return undefined;
      const smallQueueActiveOperationsMax = TLInt.deserialized(_data);
      if (!smallQueueActiveOperationsMax) return undefined;
      const largeQueueActiveOperationsMax = TLInt.deserialized(_data);
      if (!largeQueueActiveOperationsMax) return undefined;

      return new AutoDownloadSettings(
        disabled,
        videoPreloadLarge,
        audioPreloadNext,
        phonecallsLessData,
        storiesPreload,
        photoSizeMax,
        videoSizeMax,
        fileSizeMax,
        videoUploadMaxbitrate,
        smallQueueActiveOperationsMax,
        largeQueueActiveOperationsMax
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AutoDownloadSettings.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.disabled) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.videoPreloadLarge) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.audioPreloadNext) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.phonecallsLessData) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.storiesPreload) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      _data.push(this.photoSizeMax);
      _data.push(this.videoSizeMax);
      _data.push(this.fileSizeMax);
      _data.push(this.videoUploadMaxbitrate);
      _data.push(this.smallQueueActiveOperationsMax);
      _data.push(this.largeQueueActiveOperationsMax);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly disabled: boolean | undefined,
      readonly videoPreloadLarge: boolean | undefined,
      readonly audioPreloadNext: boolean | undefined,
      readonly phonecallsLessData: boolean | undefined,
      readonly storiesPreload: boolean | undefined,
      readonly photoSizeMax: TLInt,
      readonly videoSizeMax: TLLong,
      readonly fileSizeMax: TLLong,
      readonly videoUploadMaxbitrate: TLInt,
      readonly smallQueueActiveOperationsMax: TLInt,
      readonly largeQueueActiveOperationsMax: TLInt
    ) {}
  }

  export namespace account {
    export class AutoDownloadSettings implements TLObject {
      static readonly cons = new TLInt(0x63cacf26);

      static deserialized(_data: ByteStream): AutoDownloadSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AutoDownloadSettings.cons))
          return undefined;

        const low = API.AutoDownloadSettings.deserialized(_data);
        if (!low) return undefined;
        const medium = API.AutoDownloadSettings.deserialized(_data);
        if (!medium) return undefined;
        const high = API.AutoDownloadSettings.deserialized(_data);
        if (!high) return undefined;

        return new AutoDownloadSettings(low, medium, high);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AutoDownloadSettings.cons];

        _data.push(this.low);
        _data.push(this.medium);
        _data.push(this.high);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly low: API.AutoDownloadSettings,
        readonly medium: API.AutoDownloadSettings,
        readonly high: API.AutoDownloadSettings
      ) {}
    }
  }

  export class EmojiKeyword implements TLObject {
    static readonly cons = new TLInt(0xd5b3b9f9);

    static deserialized(_data: ByteStream): EmojiKeyword | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmojiKeyword.cons))
        return undefined;

      const keyword = TLString.deserialized(_data);
      if (!keyword) return undefined;
      const emoticons = TLVector.deserialized(_data, TLString);
      if (!emoticons) return undefined;

      return new EmojiKeyword(keyword, emoticons);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmojiKeyword.cons];

      _data.push(this.keyword);
      _data.push(this.emoticons);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly keyword: TLString,
      readonly emoticons: TLVector<TLString>
    ) {}
  }

  export class EmojiKeywordDeleted implements TLObject {
    static readonly cons = new TLInt(0x236df622);

    static deserialized(_data: ByteStream): EmojiKeywordDeleted | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmojiKeywordDeleted.cons))
        return undefined;

      const keyword = TLString.deserialized(_data);
      if (!keyword) return undefined;
      const emoticons = TLVector.deserialized(_data, TLString);
      if (!emoticons) return undefined;

      return new EmojiKeywordDeleted(keyword, emoticons);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmojiKeywordDeleted.cons];

      _data.push(this.keyword);
      _data.push(this.emoticons);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly keyword: TLString,
      readonly emoticons: TLVector<TLString>
    ) {}
  }

  export class EmojiKeywordsDifference implements TLObject {
    static readonly cons = new TLInt(0x5cc761bd);

    static deserialized(
      _data: ByteStream
    ): EmojiKeywordsDifference | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmojiKeywordsDifference.cons))
        return undefined;

      const langCode = TLString.deserialized(_data);
      if (!langCode) return undefined;
      const fromVersion = TLInt.deserialized(_data);
      if (!fromVersion) return undefined;
      const version = TLInt.deserialized(_data);
      if (!version) return undefined;
      const keywords = TLVector.deserialized(
        _data
      ) as TLVector<EmojiKeywordType>;
      if (!keywords) return undefined;

      return new EmojiKeywordsDifference(
        langCode,
        fromVersion,
        version,
        keywords
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmojiKeywordsDifference.cons];

      _data.push(this.langCode);
      _data.push(this.fromVersion);
      _data.push(this.version);
      _data.push(this.keywords);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly langCode: TLString,
      readonly fromVersion: TLInt,
      readonly version: TLInt,
      readonly keywords: TLVector<EmojiKeywordType>
    ) {}
  }

  export class EmojiURL implements TLObject {
    static readonly cons = new TLInt(0xa575739d);

    static deserialized(_data: ByteStream): EmojiURL | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmojiURL.cons)) return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new EmojiURL(url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmojiURL.cons];

      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString) {}
  }

  export class EmojiLanguage implements TLObject {
    static readonly cons = new TLInt(0xb3fb5361);

    static deserialized(_data: ByteStream): EmojiLanguage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmojiLanguage.cons))
        return undefined;

      const langCode = TLString.deserialized(_data);
      if (!langCode) return undefined;

      return new EmojiLanguage(langCode);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmojiLanguage.cons];

      _data.push(this.langCode);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly langCode: TLString) {}
  }

  export class Folder implements TLObject {
    static readonly cons = new TLInt(0xff544e65);

    static deserialized(_data: ByteStream): Folder | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Folder.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const autofillNewBroadcasts = (flags.value & 1) !== 0;
      const autofillPublicGroups = (flags.value & 2) !== 0;
      const autofillNewCorrespondents = (flags.value & 4) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      let photo: ChatPhotoType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as ChatPhotoType;
        if (!obj) return undefined;
        photo = obj;
      }

      return new Folder(
        autofillNewBroadcasts,
        autofillPublicGroups,
        autofillNewCorrespondents,
        id,
        title,
        photo
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Folder.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.autofillNewBroadcasts) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.autofillPublicGroups) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.autofillNewCorrespondents) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.id);
      _data.push(this.title);
      if (this.photo) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.photo) _data.push(this.photo);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly autofillNewBroadcasts: boolean | undefined,
      readonly autofillPublicGroups: boolean | undefined,
      readonly autofillNewCorrespondents: boolean | undefined,
      readonly id: TLInt,
      readonly title: TLString,
      readonly photo: ChatPhotoType | undefined
    ) {}
  }

  export class InputFolderPeer implements TLObject {
    static readonly cons = new TLInt(0xfbd2c296);

    static deserialized(_data: ByteStream): InputFolderPeer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputFolderPeer.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;
      const folderId = TLInt.deserialized(_data);
      if (!folderId) return undefined;

      return new InputFolderPeer(peer, folderId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputFolderPeer.cons];

      _data.push(this.peer);
      _data.push(this.folderId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: InputPeerType, readonly folderId: TLInt) {}
  }

  export class FolderPeer implements TLObject {
    static readonly cons = new TLInt(0xe9baa668);

    static deserialized(_data: ByteStream): FolderPeer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(FolderPeer.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const folderId = TLInt.deserialized(_data);
      if (!folderId) return undefined;

      return new FolderPeer(peer, folderId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [FolderPeer.cons];

      _data.push(this.peer);
      _data.push(this.folderId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType, readonly folderId: TLInt) {}
  }

  export namespace messages {
    export class SearchCounter implements TLObject {
      static readonly cons = new TLInt(0xe844ebff);

      static deserialized(_data: ByteStream): SearchCounter | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SearchCounter.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const inexact = (flags.value & 2) !== 0;
        const filter = TLObject.deserialized(_data) as MessagesFilterType;
        if (!filter) return undefined;
        const count = TLInt.deserialized(_data);
        if (!count) return undefined;

        return new SearchCounter(inexact, filter, count);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SearchCounter.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.inexact) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.filter);
        _data.push(this.count);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly inexact: boolean | undefined,
        readonly filter: MessagesFilterType,
        readonly count: TLInt
      ) {}
    }
  }

  export class UrlAuthResultRequest implements TLObject {
    static readonly cons = new TLInt(0x92d33a0e);

    static deserialized(_data: ByteStream): UrlAuthResultRequest | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UrlAuthResultRequest.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const requestWriteAccess = (flags.value & 1) !== 0;
      const bot = TLObject.deserialized(_data) as UserType;
      if (!bot) return undefined;
      const domain = TLString.deserialized(_data);
      if (!domain) return undefined;

      return new UrlAuthResultRequest(requestWriteAccess, bot, domain);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UrlAuthResultRequest.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.requestWriteAccess) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.bot);
      _data.push(this.domain);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly requestWriteAccess: boolean | undefined,
      readonly bot: UserType,
      readonly domain: TLString
    ) {}
  }

  export class UrlAuthResultAccepted implements TLObject {
    static readonly cons = new TLInt(0x8f8c0e4e);

    static deserialized(_data: ByteStream): UrlAuthResultAccepted | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UrlAuthResultAccepted.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new UrlAuthResultAccepted(url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UrlAuthResultAccepted.cons];

      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString) {}
  }

  export class UrlAuthResultDefault implements TLObject {
    static readonly cons = new TLInt(0xa9d6db1f);

    static deserialized(_data: ByteStream): UrlAuthResultDefault | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(UrlAuthResultDefault.cons))
        return undefined;

      return new UrlAuthResultDefault();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [UrlAuthResultDefault.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChannelLocationEmpty implements TLObject {
    static readonly cons = new TLInt(0xbfb5ad8b);

    static deserialized(_data: ByteStream): ChannelLocationEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelLocationEmpty.cons))
        return undefined;

      return new ChannelLocationEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelLocationEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChannelLocation implements TLObject {
    static readonly cons = new TLInt(0x209b82db);

    static deserialized(_data: ByteStream): ChannelLocation | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChannelLocation.cons))
        return undefined;

      const geoPoint = TLObject.deserialized(_data) as GeoPointType;
      if (!geoPoint) return undefined;
      const address = TLString.deserialized(_data);
      if (!address) return undefined;

      return new ChannelLocation(geoPoint, address);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChannelLocation.cons];

      _data.push(this.geoPoint);
      _data.push(this.address);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly geoPoint: GeoPointType, readonly address: TLString) {}
  }

  export class PeerLocated implements TLObject {
    static readonly cons = new TLInt(0xca461b5d);

    static deserialized(_data: ByteStream): PeerLocated | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PeerLocated.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const expires = TLInt.deserialized(_data);
      if (!expires) return undefined;
      const distance = TLInt.deserialized(_data);
      if (!distance) return undefined;

      return new PeerLocated(peer, expires, distance);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PeerLocated.cons];

      _data.push(this.peer);
      _data.push(this.expires);
      _data.push(this.distance);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly expires: TLInt,
      readonly distance: TLInt
    ) {}
  }

  export class PeerSelfLocated implements TLObject {
    static readonly cons = new TLInt(0xf8ec284b);

    static deserialized(_data: ByteStream): PeerSelfLocated | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PeerSelfLocated.cons))
        return undefined;

      const expires = TLInt.deserialized(_data);
      if (!expires) return undefined;

      return new PeerSelfLocated(expires);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PeerSelfLocated.cons];

      _data.push(this.expires);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly expires: TLInt) {}
  }

  export class RestrictionReason implements TLObject {
    static readonly cons = new TLInt(0xd072acb4);

    static deserialized(_data: ByteStream): RestrictionReason | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(RestrictionReason.cons))
        return undefined;

      const platform = TLString.deserialized(_data);
      if (!platform) return undefined;
      const reason = TLString.deserialized(_data);
      if (!reason) return undefined;
      const text = TLString.deserialized(_data);
      if (!text) return undefined;

      return new RestrictionReason(platform, reason, text);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [RestrictionReason.cons];

      _data.push(this.platform);
      _data.push(this.reason);
      _data.push(this.text);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly platform: TLString,
      readonly reason: TLString,
      readonly text: TLString
    ) {}
  }

  export class InputTheme implements TLObject {
    static readonly cons = new TLInt(0x3c5693e9);

    static deserialized(_data: ByteStream): InputTheme | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputTheme.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputTheme(id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputTheme.cons];

      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputThemeSlug implements TLObject {
    static readonly cons = new TLInt(0xf5890df1);

    static deserialized(_data: ByteStream): InputThemeSlug | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputThemeSlug.cons))
        return undefined;

      const slug = TLString.deserialized(_data);
      if (!slug) return undefined;

      return new InputThemeSlug(slug);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputThemeSlug.cons];

      _data.push(this.slug);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly slug: TLString) {}
  }

  export class Theme implements TLObject {
    static readonly cons = new TLInt(0xa00e67d6);

    static deserialized(_data: ByteStream): Theme | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Theme.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const creator = (flags.value & 1) !== 0;
      const argDefault = (flags.value & 2) !== 0;
      const forChat = (flags.value & 32) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const slug = TLString.deserialized(_data);
      if (!slug) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      let document: DocumentType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as DocumentType;
        if (!obj) return undefined;
        document = obj;
      }
      let settings: TLVector<API.ThemeSettings> | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLVector.deserialized(_data, API.ThemeSettings);
        if (!obj) return undefined;
        settings = obj;
      }
      let emoticon: TLString | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        emoticon = obj;
      }
      let installsCount: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        installsCount = obj;
      }

      return new Theme(
        creator,
        argDefault,
        forChat,
        id,
        accessHash,
        slug,
        title,
        document,
        settings,
        emoticon,
        installsCount
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Theme.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.creator) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.argDefault) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.forChat) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.slug);
      _data.push(this.title);
      if (this.document) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.document) _data.push(this.document);

      if (this.settings) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.settings) _data.push(this.settings);

      if (this.emoticon) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.emoticon) _data.push(this.emoticon);

      if (this.installsCount) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.installsCount) _data.push(this.installsCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly creator: boolean | undefined,
      readonly argDefault: boolean | undefined,
      readonly forChat: boolean | undefined,
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly slug: TLString,
      readonly title: TLString,
      readonly document: DocumentType | undefined,
      readonly settings: TLVector<API.ThemeSettings> | undefined,
      readonly emoticon: TLString | undefined,
      readonly installsCount: TLInt | undefined
    ) {}
  }

  export namespace account {
    export class ThemesNotModified implements TLObject {
      static readonly cons = new TLInt(0xf41eb622);

      static deserialized(_data: ByteStream): ThemesNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ThemesNotModified.cons))
          return undefined;

        return new ThemesNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ThemesNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class Themes implements TLObject {
      static readonly cons = new TLInt(0x9a3d8c6d);

      static deserialized(_data: ByteStream): Themes | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Themes.cons)) return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const themes = TLVector.deserialized(_data, API.Theme);
        if (!themes) return undefined;

        return new Themes(hash, themes);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Themes.cons];

        _data.push(this.hash);
        _data.push(this.themes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLLong,
        readonly themes: TLVector<API.Theme>
      ) {}
    }
  }

  export namespace auth {
    export class LoginToken implements TLObject {
      static readonly cons = new TLInt(0x629f1980);

      static deserialized(_data: ByteStream): LoginToken | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(LoginToken.cons))
          return undefined;

        const expires = TLInt.deserialized(_data);
        if (!expires) return undefined;
        const token = TLBytes.deserialized(_data);
        if (!token) return undefined;

        return new LoginToken(expires, token);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [LoginToken.cons];

        _data.push(this.expires);
        _data.push(this.token);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly expires: TLInt, readonly token: TLBytes) {}
    }
  }

  export namespace auth {
    export class LoginTokenMigrateTo implements TLObject {
      static readonly cons = new TLInt(0x68e9916);

      static deserialized(_data: ByteStream): LoginTokenMigrateTo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(LoginTokenMigrateTo.cons))
          return undefined;

        const dcId = TLInt.deserialized(_data);
        if (!dcId) return undefined;
        const token = TLBytes.deserialized(_data);
        if (!token) return undefined;

        return new LoginTokenMigrateTo(dcId, token);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [LoginTokenMigrateTo.cons];

        _data.push(this.dcId);
        _data.push(this.token);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly dcId: TLInt, readonly token: TLBytes) {}
    }
  }

  export namespace auth {
    export class LoginTokenSuccess implements TLObject {
      static readonly cons = new TLInt(0x390d5c5e);

      static deserialized(_data: ByteStream): LoginTokenSuccess | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(LoginTokenSuccess.cons))
          return undefined;

        const authorization = TLObject.deserialized(
          _data
        ) as auth.AuthorizationType;
        if (!authorization) return undefined;

        return new LoginTokenSuccess(authorization);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [LoginTokenSuccess.cons];

        _data.push(this.authorization);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly authorization: auth.AuthorizationType) {}
    }
  }

  export namespace account {
    export class ContentSettings implements TLObject {
      static readonly cons = new TLInt(0x57e28221);

      static deserialized(_data: ByteStream): ContentSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ContentSettings.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const sensitiveEnabled = (flags.value & 1) !== 0;
        const sensitiveCanChange = (flags.value & 2) !== 0;

        return new ContentSettings(sensitiveEnabled, sensitiveCanChange);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ContentSettings.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.sensitiveEnabled) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.sensitiveCanChange) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly sensitiveEnabled: boolean | undefined,
        readonly sensitiveCanChange: boolean | undefined
      ) {}
    }
  }

  export namespace messages {
    export class InactiveChats implements TLObject {
      static readonly cons = new TLInt(0xa927fec5);

      static deserialized(_data: ByteStream): InactiveChats | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(InactiveChats.cons))
          return undefined;

        const dates = TLVector.deserialized(_data, TLInt);
        if (!dates) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new InactiveChats(dates, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [InactiveChats.cons];

        _data.push(this.dates);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly dates: TLVector<TLInt>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class BaseThemeClassic implements TLObject {
    static readonly cons = new TLInt(0xc3a12462);

    static deserialized(_data: ByteStream): BaseThemeClassic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BaseThemeClassic.cons))
        return undefined;

      return new BaseThemeClassic();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BaseThemeClassic.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BaseThemeDay implements TLObject {
    static readonly cons = new TLInt(0xfbd81688);

    static deserialized(_data: ByteStream): BaseThemeDay | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BaseThemeDay.cons))
        return undefined;

      return new BaseThemeDay();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BaseThemeDay.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BaseThemeNight implements TLObject {
    static readonly cons = new TLInt(0xb7b31ea8);

    static deserialized(_data: ByteStream): BaseThemeNight | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BaseThemeNight.cons))
        return undefined;

      return new BaseThemeNight();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BaseThemeNight.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BaseThemeTinted implements TLObject {
    static readonly cons = new TLInt(0x6d5f77ee);

    static deserialized(_data: ByteStream): BaseThemeTinted | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BaseThemeTinted.cons))
        return undefined;

      return new BaseThemeTinted();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BaseThemeTinted.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BaseThemeArctic implements TLObject {
    static readonly cons = new TLInt(0x5b11125a);

    static deserialized(_data: ByteStream): BaseThemeArctic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BaseThemeArctic.cons))
        return undefined;

      return new BaseThemeArctic();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BaseThemeArctic.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputThemeSettings implements TLObject {
    static readonly cons = new TLInt(0x8fde504f);

    static deserialized(_data: ByteStream): InputThemeSettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputThemeSettings.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const messageColorsAnimated = (flags.value & 4) !== 0;
      const baseTheme = TLObject.deserialized(_data) as BaseThemeType;
      if (!baseTheme) return undefined;
      const accentColor = TLInt.deserialized(_data);
      if (!accentColor) return undefined;
      let outboxAccentColor: TLInt | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        outboxAccentColor = obj;
      }
      let messageColors: TLVector<TLInt> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data, TLInt);
        if (!obj) return undefined;
        messageColors = obj;
      }
      let wallpaper: InputWallPaperType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as InputWallPaperType;
        if (!obj) return undefined;
        wallpaper = obj;
      }
      let wallpaperSettings: API.WallPaperSettings | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as API.WallPaperSettings;
        if (!obj) return undefined;
        wallpaperSettings = obj;
      }

      return new InputThemeSettings(
        messageColorsAnimated,
        baseTheme,
        accentColor,
        outboxAccentColor,
        messageColors,
        wallpaper,
        wallpaperSettings
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputThemeSettings.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.messageColorsAnimated) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.baseTheme);
      _data.push(this.accentColor);
      if (this.outboxAccentColor) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.outboxAccentColor) _data.push(this.outboxAccentColor);

      if (this.messageColors) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.messageColors) _data.push(this.messageColors);

      if (this.wallpaper) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.wallpaper) _data.push(this.wallpaper);

      if (this.wallpaperSettings) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.wallpaperSettings) _data.push(this.wallpaperSettings);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly messageColorsAnimated: boolean | undefined,
      readonly baseTheme: BaseThemeType,
      readonly accentColor: TLInt,
      readonly outboxAccentColor: TLInt | undefined,
      readonly messageColors: TLVector<TLInt> | undefined,
      readonly wallpaper: InputWallPaperType | undefined,
      readonly wallpaperSettings: API.WallPaperSettings | undefined
    ) {}
  }

  export class ThemeSettings implements TLObject {
    static readonly cons = new TLInt(0xfa58b6d4);

    static deserialized(_data: ByteStream): ThemeSettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ThemeSettings.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const messageColorsAnimated = (flags.value & 4) !== 0;
      const baseTheme = TLObject.deserialized(_data) as BaseThemeType;
      if (!baseTheme) return undefined;
      const accentColor = TLInt.deserialized(_data);
      if (!accentColor) return undefined;
      let outboxAccentColor: TLInt | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        outboxAccentColor = obj;
      }
      let messageColors: TLVector<TLInt> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data, TLInt);
        if (!obj) return undefined;
        messageColors = obj;
      }
      let wallpaper: WallPaperType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as WallPaperType;
        if (!obj) return undefined;
        wallpaper = obj;
      }

      return new ThemeSettings(
        messageColorsAnimated,
        baseTheme,
        accentColor,
        outboxAccentColor,
        messageColors,
        wallpaper
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ThemeSettings.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.messageColorsAnimated) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.baseTheme);
      _data.push(this.accentColor);
      if (this.outboxAccentColor) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.outboxAccentColor) _data.push(this.outboxAccentColor);

      if (this.messageColors) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.messageColors) _data.push(this.messageColors);

      if (this.wallpaper) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.wallpaper) _data.push(this.wallpaper);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly messageColorsAnimated: boolean | undefined,
      readonly baseTheme: BaseThemeType,
      readonly accentColor: TLInt,
      readonly outboxAccentColor: TLInt | undefined,
      readonly messageColors: TLVector<TLInt> | undefined,
      readonly wallpaper: WallPaperType | undefined
    ) {}
  }

  export class WebPageAttributeTheme implements TLObject {
    static readonly cons = new TLInt(0x54b56617);

    static deserialized(_data: ByteStream): WebPageAttributeTheme | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WebPageAttributeTheme.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let documents: TLVector<DocumentType> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<DocumentType>;
        if (!obj) return undefined;
        documents = obj;
      }
      let settings: API.ThemeSettings | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ThemeSettings;
        if (!obj) return undefined;
        settings = obj;
      }

      return new WebPageAttributeTheme(documents, settings);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WebPageAttributeTheme.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.documents) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.documents) _data.push(this.documents);

      if (this.settings) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.settings) _data.push(this.settings);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly documents: TLVector<DocumentType> | undefined,
      readonly settings: API.ThemeSettings | undefined
    ) {}
  }

  export class WebPageAttributeStory implements TLObject {
    static readonly cons = new TLInt(0x2e94c3e7);

    static deserialized(_data: ByteStream): WebPageAttributeStory | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WebPageAttributeStory.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      let story: StoryItemType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as StoryItemType;
        if (!obj) return undefined;
        story = obj;
      }

      return new WebPageAttributeStory(peer, id, story);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WebPageAttributeStory.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.peer);
      _data.push(this.id);
      if (this.story) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.story) _data.push(this.story);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly id: TLInt,
      readonly story: StoryItemType | undefined
    ) {}
  }

  export namespace messages {
    export class VotesList implements TLObject {
      static readonly cons = new TLInt(0x4899484e);

      static deserialized(_data: ByteStream): VotesList | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(VotesList.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const votes = TLVector.deserialized(
          _data
        ) as TLVector<MessagePeerVoteType>;
        if (!votes) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;
        let nextOffset: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          nextOffset = obj;
        }

        return new VotesList(count, votes, chats, users, nextOffset);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [VotesList.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.count);
        _data.push(this.votes);
        _data.push(this.chats);
        _data.push(this.users);
        if (this.nextOffset) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.nextOffset) _data.push(this.nextOffset);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly votes: TLVector<MessagePeerVoteType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>,
        readonly nextOffset: TLString | undefined
      ) {}
    }
  }

  export class BankCardOpenUrl implements TLObject {
    static readonly cons = new TLInt(0xf568028a);

    static deserialized(_data: ByteStream): BankCardOpenUrl | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BankCardOpenUrl.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const name = TLString.deserialized(_data);
      if (!name) return undefined;

      return new BankCardOpenUrl(url, name);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BankCardOpenUrl.cons];

      _data.push(this.url);
      _data.push(this.name);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString, readonly name: TLString) {}
  }

  export namespace payments {
    export class BankCardData implements TLObject {
      static readonly cons = new TLInt(0x3e24e573);

      static deserialized(_data: ByteStream): BankCardData | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(BankCardData.cons))
          return undefined;

        const title = TLString.deserialized(_data);
        if (!title) return undefined;
        const openUrls = TLVector.deserialized(_data, API.BankCardOpenUrl);
        if (!openUrls) return undefined;

        return new BankCardData(title, openUrls);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [BankCardData.cons];

        _data.push(this.title);
        _data.push(this.openUrls);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly title: TLString,
        readonly openUrls: TLVector<API.BankCardOpenUrl>
      ) {}
    }
  }

  export class DialogFilter implements TLObject {
    static readonly cons = new TLInt(0x7438f7e8);

    static deserialized(_data: ByteStream): DialogFilter | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DialogFilter.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const contacts = (flags.value & 1) !== 0;
      const nonContacts = (flags.value & 2) !== 0;
      const groups = (flags.value & 4) !== 0;
      const broadcasts = (flags.value & 8) !== 0;
      const bots = (flags.value & 16) !== 0;
      const excludeMuted = (flags.value & 2048) !== 0;
      const excludeRead = (flags.value & 4096) !== 0;
      const excludeArchived = (flags.value & 8192) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      let emoticon: TLString | undefined;
      if ((flags.value & 33554432) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        emoticon = obj;
      }
      const pinnedPeers = TLVector.deserialized(
        _data
      ) as TLVector<InputPeerType>;
      if (!pinnedPeers) return undefined;
      const includePeers = TLVector.deserialized(
        _data
      ) as TLVector<InputPeerType>;
      if (!includePeers) return undefined;
      const excludePeers = TLVector.deserialized(
        _data
      ) as TLVector<InputPeerType>;
      if (!excludePeers) return undefined;

      return new DialogFilter(
        contacts,
        nonContacts,
        groups,
        broadcasts,
        bots,
        excludeMuted,
        excludeRead,
        excludeArchived,
        id,
        title,
        emoticon,
        pinnedPeers,
        includePeers,
        excludePeers
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DialogFilter.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.contacts) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.nonContacts) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.groups) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.broadcasts) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.bots) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.excludeMuted) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }

      if (this.excludeRead) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }

      if (this.excludeArchived) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }

      _data.push(this.id);
      _data.push(this.title);
      if (this.emoticon) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }
      if (this.emoticon) _data.push(this.emoticon);

      _data.push(this.pinnedPeers);
      _data.push(this.includePeers);
      _data.push(this.excludePeers);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly contacts: boolean | undefined,
      readonly nonContacts: boolean | undefined,
      readonly groups: boolean | undefined,
      readonly broadcasts: boolean | undefined,
      readonly bots: boolean | undefined,
      readonly excludeMuted: boolean | undefined,
      readonly excludeRead: boolean | undefined,
      readonly excludeArchived: boolean | undefined,
      readonly id: TLInt,
      readonly title: TLString,
      readonly emoticon: TLString | undefined,
      readonly pinnedPeers: TLVector<InputPeerType>,
      readonly includePeers: TLVector<InputPeerType>,
      readonly excludePeers: TLVector<InputPeerType>
    ) {}
  }

  export class DialogFilterDefault implements TLObject {
    static readonly cons = new TLInt(0x363293ae);

    static deserialized(_data: ByteStream): DialogFilterDefault | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DialogFilterDefault.cons))
        return undefined;

      return new DialogFilterDefault();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DialogFilterDefault.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class DialogFilterChatlist implements TLObject {
    static readonly cons = new TLInt(0xd64a04a8);

    static deserialized(_data: ByteStream): DialogFilterChatlist | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DialogFilterChatlist.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const hasMyInvites = (flags.value & 67108864) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      let emoticon: TLString | undefined;
      if ((flags.value & 33554432) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        emoticon = obj;
      }
      const pinnedPeers = TLVector.deserialized(
        _data
      ) as TLVector<InputPeerType>;
      if (!pinnedPeers) return undefined;
      const includePeers = TLVector.deserialized(
        _data
      ) as TLVector<InputPeerType>;
      if (!includePeers) return undefined;

      return new DialogFilterChatlist(
        hasMyInvites,
        id,
        title,
        emoticon,
        pinnedPeers,
        includePeers
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DialogFilterChatlist.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.hasMyInvites) {
        flags.set(67108864);
      } else {
        flags.unset(67108864);
      }

      _data.push(this.id);
      _data.push(this.title);
      if (this.emoticon) {
        flags.set(33554432);
      } else {
        flags.unset(33554432);
      }
      if (this.emoticon) _data.push(this.emoticon);

      _data.push(this.pinnedPeers);
      _data.push(this.includePeers);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly hasMyInvites: boolean | undefined,
      readonly id: TLInt,
      readonly title: TLString,
      readonly emoticon: TLString | undefined,
      readonly pinnedPeers: TLVector<InputPeerType>,
      readonly includePeers: TLVector<InputPeerType>
    ) {}
  }

  export class DialogFilterSuggested implements TLObject {
    static readonly cons = new TLInt(0x77744d4a);

    static deserialized(_data: ByteStream): DialogFilterSuggested | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DialogFilterSuggested.cons))
        return undefined;

      const filter = TLObject.deserialized(_data) as DialogFilterType;
      if (!filter) return undefined;
      const description = TLString.deserialized(_data);
      if (!description) return undefined;

      return new DialogFilterSuggested(filter, description);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DialogFilterSuggested.cons];

      _data.push(this.filter);
      _data.push(this.description);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly filter: DialogFilterType,
      readonly description: TLString
    ) {}
  }

  export class StatsDateRangeDays implements TLObject {
    static readonly cons = new TLInt(0xb637edaf);

    static deserialized(_data: ByteStream): StatsDateRangeDays | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StatsDateRangeDays.cons))
        return undefined;

      const minDate = TLInt.deserialized(_data);
      if (!minDate) return undefined;
      const maxDate = TLInt.deserialized(_data);
      if (!maxDate) return undefined;

      return new StatsDateRangeDays(minDate, maxDate);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StatsDateRangeDays.cons];

      _data.push(this.minDate);
      _data.push(this.maxDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly minDate: TLInt, readonly maxDate: TLInt) {}
  }

  export class StatsAbsValueAndPrev implements TLObject {
    static readonly cons = new TLInt(0xcb43acde);

    static deserialized(_data: ByteStream): StatsAbsValueAndPrev | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StatsAbsValueAndPrev.cons))
        return undefined;

      const current = TLDouble.deserialized(_data);
      if (!current) return undefined;
      const previous = TLDouble.deserialized(_data);
      if (!previous) return undefined;

      return new StatsAbsValueAndPrev(current, previous);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StatsAbsValueAndPrev.cons];

      _data.push(this.current);
      _data.push(this.previous);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly current: TLDouble, readonly previous: TLDouble) {}
  }

  export class StatsPercentValue implements TLObject {
    static readonly cons = new TLInt(0xcbce2fe0);

    static deserialized(_data: ByteStream): StatsPercentValue | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StatsPercentValue.cons))
        return undefined;

      const part = TLDouble.deserialized(_data);
      if (!part) return undefined;
      const total = TLDouble.deserialized(_data);
      if (!total) return undefined;

      return new StatsPercentValue(part, total);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StatsPercentValue.cons];

      _data.push(this.part);
      _data.push(this.total);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly part: TLDouble, readonly total: TLDouble) {}
  }

  export class StatsGraphAsync implements TLObject {
    static readonly cons = new TLInt(0x4a27eb2d);

    static deserialized(_data: ByteStream): StatsGraphAsync | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StatsGraphAsync.cons))
        return undefined;

      const token = TLString.deserialized(_data);
      if (!token) return undefined;

      return new StatsGraphAsync(token);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StatsGraphAsync.cons];

      _data.push(this.token);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly token: TLString) {}
  }

  export class StatsGraphError implements TLObject {
    static readonly cons = new TLInt(0xbedc9822);

    static deserialized(_data: ByteStream): StatsGraphError | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StatsGraphError.cons))
        return undefined;

      const error = TLString.deserialized(_data);
      if (!error) return undefined;

      return new StatsGraphError(error);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StatsGraphError.cons];

      _data.push(this.error);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly error: TLString) {}
  }

  export class StatsGraph implements TLObject {
    static readonly cons = new TLInt(0x8ea464b6);

    static deserialized(_data: ByteStream): StatsGraph | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StatsGraph.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const json = API.DataJSON.deserialized(_data);
      if (!json) return undefined;
      let zoomToken: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        zoomToken = obj;
      }

      return new StatsGraph(json, zoomToken);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StatsGraph.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.json);
      if (this.zoomToken) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.zoomToken) _data.push(this.zoomToken);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly json: API.DataJSON,
      readonly zoomToken: TLString | undefined
    ) {}
  }

  export class MessageInteractionCounters implements TLObject {
    static readonly cons = new TLInt(0xad4fc9bd);

    static deserialized(
      _data: ByteStream
    ): MessageInteractionCounters | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageInteractionCounters.cons))
        return undefined;

      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;
      const views = TLInt.deserialized(_data);
      if (!views) return undefined;
      const forwards = TLInt.deserialized(_data);
      if (!forwards) return undefined;

      return new MessageInteractionCounters(msgId, views, forwards);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageInteractionCounters.cons];

      _data.push(this.msgId);
      _data.push(this.views);
      _data.push(this.forwards);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly msgId: TLInt,
      readonly views: TLInt,
      readonly forwards: TLInt
    ) {}
  }

  export namespace stats {
    export class BroadcastStats implements TLObject {
      static readonly cons = new TLInt(0xbdf78394);

      static deserialized(_data: ByteStream): BroadcastStats | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(BroadcastStats.cons))
          return undefined;

        const period = API.StatsDateRangeDays.deserialized(_data);
        if (!period) return undefined;
        const followers = API.StatsAbsValueAndPrev.deserialized(_data);
        if (!followers) return undefined;
        const viewsPerPost = API.StatsAbsValueAndPrev.deserialized(_data);
        if (!viewsPerPost) return undefined;
        const sharesPerPost = API.StatsAbsValueAndPrev.deserialized(_data);
        if (!sharesPerPost) return undefined;
        const enabledNotifications = API.StatsPercentValue.deserialized(_data);
        if (!enabledNotifications) return undefined;
        const growthGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!growthGraph) return undefined;
        const followersGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!followersGraph) return undefined;
        const muteGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!muteGraph) return undefined;
        const topHoursGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!topHoursGraph) return undefined;
        const interactionsGraph = TLObject.deserialized(
          _data
        ) as StatsGraphType;
        if (!interactionsGraph) return undefined;
        const ivInteractionsGraph = TLObject.deserialized(
          _data
        ) as StatsGraphType;
        if (!ivInteractionsGraph) return undefined;
        const viewsBySourceGraph = TLObject.deserialized(
          _data
        ) as StatsGraphType;
        if (!viewsBySourceGraph) return undefined;
        const newFollowersBySourceGraph = TLObject.deserialized(
          _data
        ) as StatsGraphType;
        if (!newFollowersBySourceGraph) return undefined;
        const languagesGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!languagesGraph) return undefined;
        const recentMessageInteractions = TLVector.deserialized(
          _data,
          API.MessageInteractionCounters
        );
        if (!recentMessageInteractions) return undefined;

        return new BroadcastStats(
          period,
          followers,
          viewsPerPost,
          sharesPerPost,
          enabledNotifications,
          growthGraph,
          followersGraph,
          muteGraph,
          topHoursGraph,
          interactionsGraph,
          ivInteractionsGraph,
          viewsBySourceGraph,
          newFollowersBySourceGraph,
          languagesGraph,
          recentMessageInteractions
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [BroadcastStats.cons];

        _data.push(this.period);
        _data.push(this.followers);
        _data.push(this.viewsPerPost);
        _data.push(this.sharesPerPost);
        _data.push(this.enabledNotifications);
        _data.push(this.growthGraph);
        _data.push(this.followersGraph);
        _data.push(this.muteGraph);
        _data.push(this.topHoursGraph);
        _data.push(this.interactionsGraph);
        _data.push(this.ivInteractionsGraph);
        _data.push(this.viewsBySourceGraph);
        _data.push(this.newFollowersBySourceGraph);
        _data.push(this.languagesGraph);
        _data.push(this.recentMessageInteractions);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly period: API.StatsDateRangeDays,
        readonly followers: API.StatsAbsValueAndPrev,
        readonly viewsPerPost: API.StatsAbsValueAndPrev,
        readonly sharesPerPost: API.StatsAbsValueAndPrev,
        readonly enabledNotifications: API.StatsPercentValue,
        readonly growthGraph: StatsGraphType,
        readonly followersGraph: StatsGraphType,
        readonly muteGraph: StatsGraphType,
        readonly topHoursGraph: StatsGraphType,
        readonly interactionsGraph: StatsGraphType,
        readonly ivInteractionsGraph: StatsGraphType,
        readonly viewsBySourceGraph: StatsGraphType,
        readonly newFollowersBySourceGraph: StatsGraphType,
        readonly languagesGraph: StatsGraphType,
        readonly recentMessageInteractions: TLVector<API.MessageInteractionCounters>
      ) {}
    }
  }

  export namespace help {
    export class PromoDataEmpty implements TLObject {
      static readonly cons = new TLInt(0x98f6ac75);

      static deserialized(_data: ByteStream): PromoDataEmpty | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PromoDataEmpty.cons))
          return undefined;

        const expires = TLInt.deserialized(_data);
        if (!expires) return undefined;

        return new PromoDataEmpty(expires);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PromoDataEmpty.cons];

        _data.push(this.expires);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly expires: TLInt) {}
    }
  }

  export namespace help {
    export class PromoData implements TLObject {
      static readonly cons = new TLInt(0x8c39793f);

      static deserialized(_data: ByteStream): PromoData | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PromoData.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const proxy = (flags.value & 1) !== 0;
        const expires = TLInt.deserialized(_data);
        if (!expires) return undefined;
        const peer = TLObject.deserialized(_data) as PeerType;
        if (!peer) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;
        let psaType: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          psaType = obj;
        }
        let psaMessage: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          psaMessage = obj;
        }

        return new PromoData(
          proxy,
          expires,
          peer,
          chats,
          users,
          psaType,
          psaMessage
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PromoData.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.proxy) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.expires);
        _data.push(this.peer);
        _data.push(this.chats);
        _data.push(this.users);
        if (this.psaType) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.psaType) _data.push(this.psaType);

        if (this.psaMessage) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.psaMessage) _data.push(this.psaMessage);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly proxy: boolean | undefined,
        readonly expires: TLInt,
        readonly peer: PeerType,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>,
        readonly psaType: TLString | undefined,
        readonly psaMessage: TLString | undefined
      ) {}
    }
  }

  export class VideoSize implements TLObject {
    static readonly cons = new TLInt(0xde33b094);

    static deserialized(_data: ByteStream): VideoSize | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(VideoSize.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const type = TLString.deserialized(_data);
      if (!type) return undefined;
      const w = TLInt.deserialized(_data);
      if (!w) return undefined;
      const h = TLInt.deserialized(_data);
      if (!h) return undefined;
      const size = TLInt.deserialized(_data);
      if (!size) return undefined;
      let videoStartTs: TLDouble | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLDouble.deserialized(_data);
        if (!obj) return undefined;
        videoStartTs = obj;
      }

      return new VideoSize(type, w, h, size, videoStartTs);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [VideoSize.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.type);
      _data.push(this.w);
      _data.push(this.h);
      _data.push(this.size);
      if (this.videoStartTs) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.videoStartTs) _data.push(this.videoStartTs);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly type: TLString,
      readonly w: TLInt,
      readonly h: TLInt,
      readonly size: TLInt,
      readonly videoStartTs: TLDouble | undefined
    ) {}
  }

  export class VideoSizeEmojiMarkup implements TLObject {
    static readonly cons = new TLInt(0xf85c413c);

    static deserialized(_data: ByteStream): VideoSizeEmojiMarkup | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(VideoSizeEmojiMarkup.cons))
        return undefined;

      const emojiId = TLLong.deserialized(_data);
      if (!emojiId) return undefined;
      const backgroundColors = TLVector.deserialized(_data, TLInt);
      if (!backgroundColors) return undefined;

      return new VideoSizeEmojiMarkup(emojiId, backgroundColors);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [VideoSizeEmojiMarkup.cons];

      _data.push(this.emojiId);
      _data.push(this.backgroundColors);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly emojiId: TLLong,
      readonly backgroundColors: TLVector<TLInt>
    ) {}
  }

  export class VideoSizeStickerMarkup implements TLObject {
    static readonly cons = new TLInt(0xda082fe);

    static deserialized(_data: ByteStream): VideoSizeStickerMarkup | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(VideoSizeStickerMarkup.cons))
        return undefined;

      const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
      if (!stickerset) return undefined;
      const stickerId = TLLong.deserialized(_data);
      if (!stickerId) return undefined;
      const backgroundColors = TLVector.deserialized(_data, TLInt);
      if (!backgroundColors) return undefined;

      return new VideoSizeStickerMarkup(
        stickerset,
        stickerId,
        backgroundColors
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [VideoSizeStickerMarkup.cons];

      _data.push(this.stickerset);
      _data.push(this.stickerId);
      _data.push(this.backgroundColors);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly stickerset: InputStickerSetType,
      readonly stickerId: TLLong,
      readonly backgroundColors: TLVector<TLInt>
    ) {}
  }

  export class StatsGroupTopPoster implements TLObject {
    static readonly cons = new TLInt(0x9d04af9b);

    static deserialized(_data: ByteStream): StatsGroupTopPoster | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StatsGroupTopPoster.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const messages = TLInt.deserialized(_data);
      if (!messages) return undefined;
      const avgChars = TLInt.deserialized(_data);
      if (!avgChars) return undefined;

      return new StatsGroupTopPoster(userId, messages, avgChars);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StatsGroupTopPoster.cons];

      _data.push(this.userId);
      _data.push(this.messages);
      _data.push(this.avgChars);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: TLLong,
      readonly messages: TLInt,
      readonly avgChars: TLInt
    ) {}
  }

  export class StatsGroupTopAdmin implements TLObject {
    static readonly cons = new TLInt(0xd7584c87);

    static deserialized(_data: ByteStream): StatsGroupTopAdmin | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StatsGroupTopAdmin.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const deleted = TLInt.deserialized(_data);
      if (!deleted) return undefined;
      const kicked = TLInt.deserialized(_data);
      if (!kicked) return undefined;
      const banned = TLInt.deserialized(_data);
      if (!banned) return undefined;

      return new StatsGroupTopAdmin(userId, deleted, kicked, banned);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StatsGroupTopAdmin.cons];

      _data.push(this.userId);
      _data.push(this.deleted);
      _data.push(this.kicked);
      _data.push(this.banned);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: TLLong,
      readonly deleted: TLInt,
      readonly kicked: TLInt,
      readonly banned: TLInt
    ) {}
  }

  export class StatsGroupTopInviter implements TLObject {
    static readonly cons = new TLInt(0x535f779d);

    static deserialized(_data: ByteStream): StatsGroupTopInviter | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StatsGroupTopInviter.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const invitations = TLInt.deserialized(_data);
      if (!invitations) return undefined;

      return new StatsGroupTopInviter(userId, invitations);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StatsGroupTopInviter.cons];

      _data.push(this.userId);
      _data.push(this.invitations);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly invitations: TLInt) {}
  }

  export namespace stats {
    export class MegagroupStats implements TLObject {
      static readonly cons = new TLInt(0xef7ff916);

      static deserialized(_data: ByteStream): MegagroupStats | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(MegagroupStats.cons))
          return undefined;

        const period = API.StatsDateRangeDays.deserialized(_data);
        if (!period) return undefined;
        const members = API.StatsAbsValueAndPrev.deserialized(_data);
        if (!members) return undefined;
        const messages = API.StatsAbsValueAndPrev.deserialized(_data);
        if (!messages) return undefined;
        const viewers = API.StatsAbsValueAndPrev.deserialized(_data);
        if (!viewers) return undefined;
        const posters = API.StatsAbsValueAndPrev.deserialized(_data);
        if (!posters) return undefined;
        const growthGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!growthGraph) return undefined;
        const membersGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!membersGraph) return undefined;
        const newMembersBySourceGraph = TLObject.deserialized(
          _data
        ) as StatsGraphType;
        if (!newMembersBySourceGraph) return undefined;
        const languagesGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!languagesGraph) return undefined;
        const messagesGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!messagesGraph) return undefined;
        const actionsGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!actionsGraph) return undefined;
        const topHoursGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!topHoursGraph) return undefined;
        const weekdaysGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!weekdaysGraph) return undefined;
        const topPosters = TLVector.deserialized(
          _data,
          API.StatsGroupTopPoster
        );
        if (!topPosters) return undefined;
        const topAdmins = TLVector.deserialized(_data, API.StatsGroupTopAdmin);
        if (!topAdmins) return undefined;
        const topInviters = TLVector.deserialized(
          _data,
          API.StatsGroupTopInviter
        );
        if (!topInviters) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new MegagroupStats(
          period,
          members,
          messages,
          viewers,
          posters,
          growthGraph,
          membersGraph,
          newMembersBySourceGraph,
          languagesGraph,
          messagesGraph,
          actionsGraph,
          topHoursGraph,
          weekdaysGraph,
          topPosters,
          topAdmins,
          topInviters,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [MegagroupStats.cons];

        _data.push(this.period);
        _data.push(this.members);
        _data.push(this.messages);
        _data.push(this.viewers);
        _data.push(this.posters);
        _data.push(this.growthGraph);
        _data.push(this.membersGraph);
        _data.push(this.newMembersBySourceGraph);
        _data.push(this.languagesGraph);
        _data.push(this.messagesGraph);
        _data.push(this.actionsGraph);
        _data.push(this.topHoursGraph);
        _data.push(this.weekdaysGraph);
        _data.push(this.topPosters);
        _data.push(this.topAdmins);
        _data.push(this.topInviters);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly period: API.StatsDateRangeDays,
        readonly members: API.StatsAbsValueAndPrev,
        readonly messages: API.StatsAbsValueAndPrev,
        readonly viewers: API.StatsAbsValueAndPrev,
        readonly posters: API.StatsAbsValueAndPrev,
        readonly growthGraph: StatsGraphType,
        readonly membersGraph: StatsGraphType,
        readonly newMembersBySourceGraph: StatsGraphType,
        readonly languagesGraph: StatsGraphType,
        readonly messagesGraph: StatsGraphType,
        readonly actionsGraph: StatsGraphType,
        readonly topHoursGraph: StatsGraphType,
        readonly weekdaysGraph: StatsGraphType,
        readonly topPosters: TLVector<API.StatsGroupTopPoster>,
        readonly topAdmins: TLVector<API.StatsGroupTopAdmin>,
        readonly topInviters: TLVector<API.StatsGroupTopInviter>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class GlobalPrivacySettings implements TLObject {
    static readonly cons = new TLInt(0x734c4ccb);

    static deserialized(_data: ByteStream): GlobalPrivacySettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(GlobalPrivacySettings.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const archiveAndMuteNewNoncontactPeers = (flags.value & 1) !== 0;
      const keepArchivedUnmuted = (flags.value & 2) !== 0;
      const keepArchivedFolders = (flags.value & 4) !== 0;

      return new GlobalPrivacySettings(
        archiveAndMuteNewNoncontactPeers,
        keepArchivedUnmuted,
        keepArchivedFolders
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [GlobalPrivacySettings.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.archiveAndMuteNewNoncontactPeers) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.keepArchivedUnmuted) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.keepArchivedFolders) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly archiveAndMuteNewNoncontactPeers: boolean | undefined,
      readonly keepArchivedUnmuted: boolean | undefined,
      readonly keepArchivedFolders: boolean | undefined
    ) {}
  }

  export namespace help {
    export class CountryCode implements TLObject {
      static readonly cons = new TLInt(0x4203c5ef);

      static deserialized(_data: ByteStream): CountryCode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CountryCode.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const countryCode = TLString.deserialized(_data);
        if (!countryCode) return undefined;
        let prefixes: TLVector<TLString> | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLVector.deserialized(_data, TLString);
          if (!obj) return undefined;
          prefixes = obj;
        }
        let patterns: TLVector<TLString> | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLVector.deserialized(_data, TLString);
          if (!obj) return undefined;
          patterns = obj;
        }

        return new CountryCode(countryCode, prefixes, patterns);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CountryCode.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.countryCode);
        if (this.prefixes) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.prefixes) _data.push(this.prefixes);

        if (this.patterns) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.patterns) _data.push(this.patterns);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly countryCode: TLString,
        readonly prefixes: TLVector<TLString> | undefined,
        readonly patterns: TLVector<TLString> | undefined
      ) {}
    }
  }

  export namespace help {
    export class Country implements TLObject {
      static readonly cons = new TLInt(0xc3878e23);

      static deserialized(_data: ByteStream): Country | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Country.cons)) return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const hidden = (flags.value & 1) !== 0;
        const iso2 = TLString.deserialized(_data);
        if (!iso2) return undefined;
        const defaultName = TLString.deserialized(_data);
        if (!defaultName) return undefined;
        let name: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          name = obj;
        }
        const countryCodes = TLVector.deserialized(_data, help.CountryCode);
        if (!countryCodes) return undefined;

        return new Country(hidden, iso2, defaultName, name, countryCodes);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Country.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.hidden) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.iso2);
        _data.push(this.defaultName);
        if (this.name) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.name) _data.push(this.name);

        _data.push(this.countryCodes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hidden: boolean | undefined,
        readonly iso2: TLString,
        readonly defaultName: TLString,
        readonly name: TLString | undefined,
        readonly countryCodes: TLVector<help.CountryCode>
      ) {}
    }
  }

  export namespace help {
    export class CountriesListNotModified implements TLObject {
      static readonly cons = new TLInt(0x93cc1f32);

      static deserialized(
        _data: ByteStream
      ): CountriesListNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CountriesListNotModified.cons))
          return undefined;

        return new CountriesListNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CountriesListNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class CountriesList implements TLObject {
      static readonly cons = new TLInt(0x87d0759e);

      static deserialized(_data: ByteStream): CountriesList | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CountriesList.cons))
          return undefined;

        const countries = TLVector.deserialized(_data, help.Country);
        if (!countries) return undefined;
        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;

        return new CountriesList(countries, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CountriesList.cons];

        _data.push(this.countries);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly countries: TLVector<help.Country>,
        readonly hash: TLInt
      ) {}
    }
  }

  export class MessageViews implements TLObject {
    static readonly cons = new TLInt(0x455b853d);

    static deserialized(_data: ByteStream): MessageViews | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageViews.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let views: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        views = obj;
      }
      let forwards: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        forwards = obj;
      }
      let replies: API.MessageReplies | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as API.MessageReplies;
        if (!obj) return undefined;
        replies = obj;
      }

      return new MessageViews(views, forwards, replies);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageViews.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.views) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.views) _data.push(this.views);

      if (this.forwards) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.forwards) _data.push(this.forwards);

      if (this.replies) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.replies) _data.push(this.replies);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly views: TLInt | undefined,
      readonly forwards: TLInt | undefined,
      readonly replies: API.MessageReplies | undefined
    ) {}
  }

  export namespace messages {
    export class MessageViews implements TLObject {
      static readonly cons = new TLInt(0xb6c4f543);

      static deserialized(_data: ByteStream): MessageViews | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(MessageViews.cons))
          return undefined;

        const views = TLVector.deserialized(_data, API.MessageViews);
        if (!views) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new MessageViews(views, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [MessageViews.cons];

        _data.push(this.views);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly views: TLVector<API.MessageViews>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class DiscussionMessage implements TLObject {
      static readonly cons = new TLInt(0xa6341782);

      static deserialized(_data: ByteStream): DiscussionMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DiscussionMessage.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const messages = TLVector.deserialized(_data) as TLVector<MessageType>;
        if (!messages) return undefined;
        let maxId: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          maxId = obj;
        }
        let readInboxMaxId: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          readInboxMaxId = obj;
        }
        let readOutboxMaxId: TLInt | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          readOutboxMaxId = obj;
        }
        const unreadCount = TLInt.deserialized(_data);
        if (!unreadCount) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new DiscussionMessage(
          messages,
          maxId,
          readInboxMaxId,
          readOutboxMaxId,
          unreadCount,
          chats,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DiscussionMessage.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.messages);
        if (this.maxId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.maxId) _data.push(this.maxId);

        if (this.readInboxMaxId) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.readInboxMaxId) _data.push(this.readInboxMaxId);

        if (this.readOutboxMaxId) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.readOutboxMaxId) _data.push(this.readOutboxMaxId);

        _data.push(this.unreadCount);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly messages: TLVector<MessageType>,
        readonly maxId: TLInt | undefined,
        readonly readInboxMaxId: TLInt | undefined,
        readonly readOutboxMaxId: TLInt | undefined,
        readonly unreadCount: TLInt,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class MessageReplyHeader implements TLObject {
    static readonly cons = new TLInt(0xa6d57763);

    static deserialized(_data: ByteStream): MessageReplyHeader | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageReplyHeader.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const replyToScheduled = (flags.value & 4) !== 0;
      const forumTopic = (flags.value & 8) !== 0;
      const replyToMsgId = TLInt.deserialized(_data);
      if (!replyToMsgId) return undefined;
      let replyToPeerId: PeerType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as PeerType;
        if (!obj) return undefined;
        replyToPeerId = obj;
      }
      let replyToTopId: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        replyToTopId = obj;
      }

      return new MessageReplyHeader(
        replyToScheduled,
        forumTopic,
        replyToMsgId,
        replyToPeerId,
        replyToTopId
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageReplyHeader.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.replyToScheduled) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.forumTopic) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      _data.push(this.replyToMsgId);
      if (this.replyToPeerId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.replyToPeerId) _data.push(this.replyToPeerId);

      if (this.replyToTopId) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.replyToTopId) _data.push(this.replyToTopId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly replyToScheduled: boolean | undefined,
      readonly forumTopic: boolean | undefined,
      readonly replyToMsgId: TLInt,
      readonly replyToPeerId: PeerType | undefined,
      readonly replyToTopId: TLInt | undefined
    ) {}
  }

  export class MessageReplyStoryHeader implements TLObject {
    static readonly cons = new TLInt(0x9c98bfc1);

    static deserialized(
      _data: ByteStream
    ): MessageReplyStoryHeader | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageReplyStoryHeader.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const storyId = TLInt.deserialized(_data);
      if (!storyId) return undefined;

      return new MessageReplyStoryHeader(userId, storyId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageReplyStoryHeader.cons];

      _data.push(this.userId);
      _data.push(this.storyId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly storyId: TLInt) {}
  }

  export class MessageReplies implements TLObject {
    static readonly cons = new TLInt(0x83d60fc2);

    static deserialized(_data: ByteStream): MessageReplies | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageReplies.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const comments = (flags.value & 1) !== 0;
      const replies = TLInt.deserialized(_data);
      if (!replies) return undefined;
      const repliesPts = TLInt.deserialized(_data);
      if (!repliesPts) return undefined;
      let recentRepliers: TLVector<PeerType> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<PeerType>;
        if (!obj) return undefined;
        recentRepliers = obj;
      }
      let channelId: TLLong | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        channelId = obj;
      }
      let maxId: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        maxId = obj;
      }
      let readMaxId: TLInt | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        readMaxId = obj;
      }

      return new MessageReplies(
        comments,
        replies,
        repliesPts,
        recentRepliers,
        channelId,
        maxId,
        readMaxId
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageReplies.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.comments) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.replies);
      _data.push(this.repliesPts);
      if (this.recentRepliers) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.recentRepliers) _data.push(this.recentRepliers);

      if (this.channelId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.channelId) _data.push(this.channelId);

      if (this.maxId) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.maxId) _data.push(this.maxId);

      if (this.readMaxId) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.readMaxId) _data.push(this.readMaxId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly comments: boolean | undefined,
      readonly replies: TLInt,
      readonly repliesPts: TLInt,
      readonly recentRepliers: TLVector<PeerType> | undefined,
      readonly channelId: TLLong | undefined,
      readonly maxId: TLInt | undefined,
      readonly readMaxId: TLInt | undefined
    ) {}
  }

  export class PeerBlocked implements TLObject {
    static readonly cons = new TLInt(0xe8fd8014);

    static deserialized(_data: ByteStream): PeerBlocked | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PeerBlocked.cons))
        return undefined;

      const peerId = TLObject.deserialized(_data) as PeerType;
      if (!peerId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new PeerBlocked(peerId, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PeerBlocked.cons];

      _data.push(this.peerId);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peerId: PeerType, readonly date: TLInt) {}
  }

  export namespace stats {
    export class MessageStats implements TLObject {
      static readonly cons = new TLInt(0x8999f295);

      static deserialized(_data: ByteStream): MessageStats | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(MessageStats.cons))
          return undefined;

        const viewsGraph = TLObject.deserialized(_data) as StatsGraphType;
        if (!viewsGraph) return undefined;

        return new MessageStats(viewsGraph);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [MessageStats.cons];

        _data.push(this.viewsGraph);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly viewsGraph: StatsGraphType) {}
    }
  }

  export class GroupCallDiscarded implements TLObject {
    static readonly cons = new TLInt(0x7780bcb4);

    static deserialized(_data: ByteStream): GroupCallDiscarded | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(GroupCallDiscarded.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const duration = TLInt.deserialized(_data);
      if (!duration) return undefined;

      return new GroupCallDiscarded(id, accessHash, duration);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [GroupCallDiscarded.cons];

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.duration);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly duration: TLInt
    ) {}
  }

  export class GroupCall implements TLObject {
    static readonly cons = new TLInt(0xd597650c);

    static deserialized(_data: ByteStream): GroupCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(GroupCall.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const joinMuted = (flags.value & 2) !== 0;
      const canChangeJoinMuted = (flags.value & 4) !== 0;
      const joinDateAsc = (flags.value & 64) !== 0;
      const scheduleStartSubscribed = (flags.value & 256) !== 0;
      const canStartVideo = (flags.value & 512) !== 0;
      const recordVideoActive = (flags.value & 2048) !== 0;
      const rtmpStream = (flags.value & 4096) !== 0;
      const listenersHidden = (flags.value & 8192) !== 0;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const participantsCount = TLInt.deserialized(_data);
      if (!participantsCount) return undefined;
      let title: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        title = obj;
      }
      let streamDcId: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        streamDcId = obj;
      }
      let recordStartDate: TLInt | undefined;
      if ((flags.value & 32) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        recordStartDate = obj;
      }
      let scheduleDate: TLInt | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        scheduleDate = obj;
      }
      let unmutedVideoCount: TLInt | undefined;
      if ((flags.value & 1024) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        unmutedVideoCount = obj;
      }
      const unmutedVideoLimit = TLInt.deserialized(_data);
      if (!unmutedVideoLimit) return undefined;
      const version = TLInt.deserialized(_data);
      if (!version) return undefined;

      return new GroupCall(
        joinMuted,
        canChangeJoinMuted,
        joinDateAsc,
        scheduleStartSubscribed,
        canStartVideo,
        recordVideoActive,
        rtmpStream,
        listenersHidden,
        id,
        accessHash,
        participantsCount,
        title,
        streamDcId,
        recordStartDate,
        scheduleDate,
        unmutedVideoCount,
        unmutedVideoLimit,
        version
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [GroupCall.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.joinMuted) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.canChangeJoinMuted) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.joinDateAsc) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      if (this.scheduleStartSubscribed) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      if (this.canStartVideo) {
        flags.set(512);
      } else {
        flags.unset(512);
      }

      if (this.recordVideoActive) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }

      if (this.rtmpStream) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }

      if (this.listenersHidden) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }

      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.participantsCount);
      if (this.title) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.title) _data.push(this.title);

      if (this.streamDcId) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.streamDcId) _data.push(this.streamDcId);

      if (this.recordStartDate) {
        flags.set(32);
      } else {
        flags.unset(32);
      }
      if (this.recordStartDate) _data.push(this.recordStartDate);

      if (this.scheduleDate) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.scheduleDate) _data.push(this.scheduleDate);

      if (this.unmutedVideoCount) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }
      if (this.unmutedVideoCount) _data.push(this.unmutedVideoCount);

      _data.push(this.unmutedVideoLimit);
      _data.push(this.version);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly joinMuted: boolean | undefined,
      readonly canChangeJoinMuted: boolean | undefined,
      readonly joinDateAsc: boolean | undefined,
      readonly scheduleStartSubscribed: boolean | undefined,
      readonly canStartVideo: boolean | undefined,
      readonly recordVideoActive: boolean | undefined,
      readonly rtmpStream: boolean | undefined,
      readonly listenersHidden: boolean | undefined,
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly participantsCount: TLInt,
      readonly title: TLString | undefined,
      readonly streamDcId: TLInt | undefined,
      readonly recordStartDate: TLInt | undefined,
      readonly scheduleDate: TLInt | undefined,
      readonly unmutedVideoCount: TLInt | undefined,
      readonly unmutedVideoLimit: TLInt,
      readonly version: TLInt
    ) {}
  }

  export class InputGroupCall implements TLObject {
    static readonly cons = new TLInt(0xd8aa840f);

    static deserialized(_data: ByteStream): InputGroupCall | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputGroupCall.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputGroupCall(id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputGroupCall.cons];

      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly accessHash: TLLong) {}
  }

  export class GroupCallParticipant implements TLObject {
    static readonly cons = new TLInt(0xeba636fe);

    static deserialized(_data: ByteStream): GroupCallParticipant | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(GroupCallParticipant.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const muted = (flags.value & 1) !== 0;
      const left = (flags.value & 2) !== 0;
      const canSelfUnmute = (flags.value & 4) !== 0;
      const justJoined = (flags.value & 16) !== 0;
      const versioned = (flags.value & 32) !== 0;
      const min = (flags.value & 256) !== 0;
      const mutedByYou = (flags.value & 512) !== 0;
      const volumeByAdmin = (flags.value & 1024) !== 0;
      const self = (flags.value & 4096) !== 0;
      const videoJoined = (flags.value & 32768) !== 0;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      let activeDate: TLInt | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        activeDate = obj;
      }
      const source = TLInt.deserialized(_data);
      if (!source) return undefined;
      let volume: TLInt | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        volume = obj;
      }
      let about: TLString | undefined;
      if ((flags.value & 2048) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        about = obj;
      }
      let raiseHandRating: TLLong | undefined;
      if ((flags.value & 8192) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        raiseHandRating = obj;
      }
      let video: API.GroupCallParticipantVideo | undefined;
      if ((flags.value & 64) !== 0) {
        const obj = TLObject.deserialized(
          _data
        ) as API.GroupCallParticipantVideo;
        if (!obj) return undefined;
        video = obj;
      }
      let presentation: API.GroupCallParticipantVideo | undefined;
      if ((flags.value & 16384) !== 0) {
        const obj = TLObject.deserialized(
          _data
        ) as API.GroupCallParticipantVideo;
        if (!obj) return undefined;
        presentation = obj;
      }

      return new GroupCallParticipant(
        muted,
        left,
        canSelfUnmute,
        justJoined,
        versioned,
        min,
        mutedByYou,
        volumeByAdmin,
        self,
        videoJoined,
        peer,
        date,
        activeDate,
        source,
        volume,
        about,
        raiseHandRating,
        video,
        presentation
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [GroupCallParticipant.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.muted) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.left) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.canSelfUnmute) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.justJoined) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.versioned) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.min) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      if (this.mutedByYou) {
        flags.set(512);
      } else {
        flags.unset(512);
      }

      if (this.volumeByAdmin) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }

      if (this.self) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }

      if (this.videoJoined) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }

      _data.push(this.peer);
      _data.push(this.date);
      if (this.activeDate) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.activeDate) _data.push(this.activeDate);

      _data.push(this.source);
      if (this.volume) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.volume) _data.push(this.volume);

      if (this.about) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }
      if (this.about) _data.push(this.about);

      if (this.raiseHandRating) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }
      if (this.raiseHandRating) _data.push(this.raiseHandRating);

      if (this.video) {
        flags.set(64);
      } else {
        flags.unset(64);
      }
      if (this.video) _data.push(this.video);

      if (this.presentation) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }
      if (this.presentation) _data.push(this.presentation);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly muted: boolean | undefined,
      readonly left: boolean | undefined,
      readonly canSelfUnmute: boolean | undefined,
      readonly justJoined: boolean | undefined,
      readonly versioned: boolean | undefined,
      readonly min: boolean | undefined,
      readonly mutedByYou: boolean | undefined,
      readonly volumeByAdmin: boolean | undefined,
      readonly self: boolean | undefined,
      readonly videoJoined: boolean | undefined,
      readonly peer: PeerType,
      readonly date: TLInt,
      readonly activeDate: TLInt | undefined,
      readonly source: TLInt,
      readonly volume: TLInt | undefined,
      readonly about: TLString | undefined,
      readonly raiseHandRating: TLLong | undefined,
      readonly video: API.GroupCallParticipantVideo | undefined,
      readonly presentation: API.GroupCallParticipantVideo | undefined
    ) {}
  }

  export namespace phone {
    export class GroupCall implements TLObject {
      static readonly cons = new TLInt(0x9e727aad);

      static deserialized(_data: ByteStream): GroupCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GroupCall.cons))
          return undefined;

        const call = TLObject.deserialized(_data) as GroupCallType;
        if (!call) return undefined;
        const participants = TLVector.deserialized(
          _data,
          API.GroupCallParticipant
        );
        if (!participants) return undefined;
        const participantsNextOffset = TLString.deserialized(_data);
        if (!participantsNextOffset) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new GroupCall(
          call,
          participants,
          participantsNextOffset,
          chats,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GroupCall.cons];

        _data.push(this.call);
        _data.push(this.participants);
        _data.push(this.participantsNextOffset);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly call: GroupCallType,
        readonly participants: TLVector<API.GroupCallParticipant>,
        readonly participantsNextOffset: TLString,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace phone {
    export class GroupParticipants implements TLObject {
      static readonly cons = new TLInt(0xf47751b6);

      static deserialized(_data: ByteStream): GroupParticipants | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GroupParticipants.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const participants = TLVector.deserialized(
          _data,
          API.GroupCallParticipant
        );
        if (!participants) return undefined;
        const nextOffset = TLString.deserialized(_data);
        if (!nextOffset) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;
        const version = TLInt.deserialized(_data);
        if (!version) return undefined;

        return new GroupParticipants(
          count,
          participants,
          nextOffset,
          chats,
          users,
          version
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GroupParticipants.cons];

        _data.push(this.count);
        _data.push(this.participants);
        _data.push(this.nextOffset);
        _data.push(this.chats);
        _data.push(this.users);
        _data.push(this.version);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly participants: TLVector<API.GroupCallParticipant>,
        readonly nextOffset: TLString,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>,
        readonly version: TLInt
      ) {}
    }
  }

  export class InlineQueryPeerTypeSameBotPM implements TLObject {
    static readonly cons = new TLInt(0x3081ed9d);

    static deserialized(
      _data: ByteStream
    ): InlineQueryPeerTypeSameBotPM | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InlineQueryPeerTypeSameBotPM.cons)
      )
        return undefined;

      return new InlineQueryPeerTypeSameBotPM();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InlineQueryPeerTypeSameBotPM.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InlineQueryPeerTypePM implements TLObject {
    static readonly cons = new TLInt(0x833c0fac);

    static deserialized(_data: ByteStream): InlineQueryPeerTypePM | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InlineQueryPeerTypePM.cons))
        return undefined;

      return new InlineQueryPeerTypePM();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InlineQueryPeerTypePM.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InlineQueryPeerTypeChat implements TLObject {
    static readonly cons = new TLInt(0xd766c50a);

    static deserialized(
      _data: ByteStream
    ): InlineQueryPeerTypeChat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InlineQueryPeerTypeChat.cons))
        return undefined;

      return new InlineQueryPeerTypeChat();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InlineQueryPeerTypeChat.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InlineQueryPeerTypeMegagroup implements TLObject {
    static readonly cons = new TLInt(0x5ec4be43);

    static deserialized(
      _data: ByteStream
    ): InlineQueryPeerTypeMegagroup | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InlineQueryPeerTypeMegagroup.cons)
      )
        return undefined;

      return new InlineQueryPeerTypeMegagroup();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InlineQueryPeerTypeMegagroup.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InlineQueryPeerTypeBroadcast implements TLObject {
    static readonly cons = new TLInt(0x6334ee9a);

    static deserialized(
      _data: ByteStream
    ): InlineQueryPeerTypeBroadcast | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InlineQueryPeerTypeBroadcast.cons)
      )
        return undefined;

      return new InlineQueryPeerTypeBroadcast();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InlineQueryPeerTypeBroadcast.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InlineQueryPeerTypeBotPM implements TLObject {
    static readonly cons = new TLInt(0xe3b2d0c);

    static deserialized(
      _data: ByteStream
    ): InlineQueryPeerTypeBotPM | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InlineQueryPeerTypeBotPM.cons))
        return undefined;

      return new InlineQueryPeerTypeBotPM();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InlineQueryPeerTypeBotPM.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export namespace messages {
    export class HistoryImport implements TLObject {
      static readonly cons = new TLInt(0x1662af0b);

      static deserialized(_data: ByteStream): HistoryImport | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(HistoryImport.cons))
          return undefined;

        const id = TLLong.deserialized(_data);
        if (!id) return undefined;

        return new HistoryImport(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [HistoryImport.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLLong) {}
    }
  }

  export namespace messages {
    export class HistoryImportParsed implements TLObject {
      static readonly cons = new TLInt(0x5e0fb7b9);

      static deserialized(_data: ByteStream): HistoryImportParsed | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(HistoryImportParsed.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const pm = (flags.value & 1) !== 0;
        const group = (flags.value & 2) !== 0;
        let title: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          title = obj;
        }

        return new HistoryImportParsed(pm, group, title);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [HistoryImportParsed.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.pm) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.group) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.title) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.title) _data.push(this.title);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly pm: boolean | undefined,
        readonly group: boolean | undefined,
        readonly title: TLString | undefined
      ) {}
    }
  }

  export namespace messages {
    export class AffectedFoundMessages implements TLObject {
      static readonly cons = new TLInt(0xef8d3e6c);

      static deserialized(
        _data: ByteStream
      ): AffectedFoundMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AffectedFoundMessages.cons))
          return undefined;

        const pts = TLInt.deserialized(_data);
        if (!pts) return undefined;
        const ptsCount = TLInt.deserialized(_data);
        if (!ptsCount) return undefined;
        const offset = TLInt.deserialized(_data);
        if (!offset) return undefined;
        const messages = TLVector.deserialized(_data, TLInt);
        if (!messages) return undefined;

        return new AffectedFoundMessages(pts, ptsCount, offset, messages);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AffectedFoundMessages.cons];

        _data.push(this.pts);
        _data.push(this.ptsCount);
        _data.push(this.offset);
        _data.push(this.messages);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly pts: TLInt,
        readonly ptsCount: TLInt,
        readonly offset: TLInt,
        readonly messages: TLVector<TLInt>
      ) {}
    }
  }

  export class ChatInviteImporter implements TLObject {
    static readonly cons = new TLInt(0x8c5adfd9);

    static deserialized(_data: ByteStream): ChatInviteImporter | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatInviteImporter.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const requested = (flags.value & 1) !== 0;
      const viaChatlist = (flags.value & 8) !== 0;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      let about: TLString | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        about = obj;
      }
      let approvedBy: TLLong | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        approvedBy = obj;
      }

      return new ChatInviteImporter(
        requested,
        viaChatlist,
        userId,
        date,
        about,
        approvedBy
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatInviteImporter.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.requested) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.viaChatlist) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      _data.push(this.userId);
      _data.push(this.date);
      if (this.about) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.about) _data.push(this.about);

      if (this.approvedBy) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.approvedBy) _data.push(this.approvedBy);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly requested: boolean | undefined,
      readonly viaChatlist: boolean | undefined,
      readonly userId: TLLong,
      readonly date: TLInt,
      readonly about: TLString | undefined,
      readonly approvedBy: TLLong | undefined
    ) {}
  }

  export namespace messages {
    export class ExportedChatInvites implements TLObject {
      static readonly cons = new TLInt(0xbdc62dcc);

      static deserialized(_data: ByteStream): ExportedChatInvites | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportedChatInvites.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const invites = TLVector.deserialized(
          _data
        ) as TLVector<ExportedChatInviteType>;
        if (!invites) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ExportedChatInvites(count, invites, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportedChatInvites.cons];

        _data.push(this.count);
        _data.push(this.invites);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly invites: TLVector<ExportedChatInviteType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class ExportedChatInvite implements TLObject {
      static readonly cons = new TLInt(0x1871be50);

      static deserialized(_data: ByteStream): ExportedChatInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportedChatInvite.cons))
          return undefined;

        const invite = TLObject.deserialized(_data) as ExportedChatInviteType;
        if (!invite) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ExportedChatInvite(invite, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportedChatInvite.cons];

        _data.push(this.invite);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly invite: ExportedChatInviteType,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class ExportedChatInviteReplaced implements TLObject {
      static readonly cons = new TLInt(0x222600ef);

      static deserialized(
        _data: ByteStream
      ): ExportedChatInviteReplaced | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(ExportedChatInviteReplaced.cons)
        )
          return undefined;

        const invite = TLObject.deserialized(_data) as ExportedChatInviteType;
        if (!invite) return undefined;
        const newInvite = TLObject.deserialized(
          _data
        ) as ExportedChatInviteType;
        if (!newInvite) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ExportedChatInviteReplaced(invite, newInvite, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportedChatInviteReplaced.cons];

        _data.push(this.invite);
        _data.push(this.newInvite);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly invite: ExportedChatInviteType,
        readonly newInvite: ExportedChatInviteType,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class ChatInviteImporters implements TLObject {
      static readonly cons = new TLInt(0x81b6b00a);

      static deserialized(_data: ByteStream): ChatInviteImporters | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChatInviteImporters.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const importers = TLVector.deserialized(_data, API.ChatInviteImporter);
        if (!importers) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ChatInviteImporters(count, importers, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChatInviteImporters.cons];

        _data.push(this.count);
        _data.push(this.importers);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly importers: TLVector<API.ChatInviteImporter>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class ChatAdminWithInvites implements TLObject {
    static readonly cons = new TLInt(0xf2ecef23);

    static deserialized(_data: ByteStream): ChatAdminWithInvites | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatAdminWithInvites.cons))
        return undefined;

      const adminId = TLLong.deserialized(_data);
      if (!adminId) return undefined;
      const invitesCount = TLInt.deserialized(_data);
      if (!invitesCount) return undefined;
      const revokedInvitesCount = TLInt.deserialized(_data);
      if (!revokedInvitesCount) return undefined;

      return new ChatAdminWithInvites(
        adminId,
        invitesCount,
        revokedInvitesCount
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatAdminWithInvites.cons];

      _data.push(this.adminId);
      _data.push(this.invitesCount);
      _data.push(this.revokedInvitesCount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly adminId: TLLong,
      readonly invitesCount: TLInt,
      readonly revokedInvitesCount: TLInt
    ) {}
  }

  export namespace messages {
    export class ChatAdminsWithInvites implements TLObject {
      static readonly cons = new TLInt(0xb69b72d7);

      static deserialized(
        _data: ByteStream
      ): ChatAdminsWithInvites | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChatAdminsWithInvites.cons))
          return undefined;

        const admins = TLVector.deserialized(_data, API.ChatAdminWithInvites);
        if (!admins) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ChatAdminsWithInvites(admins, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChatAdminsWithInvites.cons];

        _data.push(this.admins);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly admins: TLVector<API.ChatAdminWithInvites>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class CheckedHistoryImportPeer implements TLObject {
      static readonly cons = new TLInt(0xa24de717);

      static deserialized(
        _data: ByteStream
      ): CheckedHistoryImportPeer | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CheckedHistoryImportPeer.cons))
          return undefined;

        const confirmText = TLString.deserialized(_data);
        if (!confirmText) return undefined;

        return new CheckedHistoryImportPeer(confirmText);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CheckedHistoryImportPeer.cons];

        _data.push(this.confirmText);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly confirmText: TLString) {}
    }
  }

  export namespace phone {
    export class JoinAsPeers implements TLObject {
      static readonly cons = new TLInt(0xafe5623f);

      static deserialized(_data: ByteStream): JoinAsPeers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(JoinAsPeers.cons))
          return undefined;

        const peers = TLVector.deserialized(_data) as TLVector<PeerType>;
        if (!peers) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new JoinAsPeers(peers, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [JoinAsPeers.cons];

        _data.push(this.peers);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peers: TLVector<PeerType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace phone {
    export class ExportedGroupCallInvite implements TLObject {
      static readonly cons = new TLInt(0x204bd158);

      static deserialized(
        _data: ByteStream
      ): ExportedGroupCallInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportedGroupCallInvite.cons))
          return undefined;

        const link = TLString.deserialized(_data);
        if (!link) return undefined;

        return new ExportedGroupCallInvite(link);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportedGroupCallInvite.cons];

        _data.push(this.link);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly link: TLString) {}
    }
  }

  export class GroupCallParticipantVideoSourceGroup implements TLObject {
    static readonly cons = new TLInt(0xdcb118b7);

    static deserialized(
      _data: ByteStream
    ): GroupCallParticipantVideoSourceGroup | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(GroupCallParticipantVideoSourceGroup.cons)
      )
        return undefined;

      const semantics = TLString.deserialized(_data);
      if (!semantics) return undefined;
      const sources = TLVector.deserialized(_data, TLInt);
      if (!sources) return undefined;

      return new GroupCallParticipantVideoSourceGroup(semantics, sources);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        GroupCallParticipantVideoSourceGroup.cons,
      ];

      _data.push(this.semantics);
      _data.push(this.sources);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly semantics: TLString,
      readonly sources: TLVector<TLInt>
    ) {}
  }

  export class GroupCallParticipantVideo implements TLObject {
    static readonly cons = new TLInt(0x67753ac8);

    static deserialized(
      _data: ByteStream
    ): GroupCallParticipantVideo | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(GroupCallParticipantVideo.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const paused = (flags.value & 1) !== 0;
      const endpoint = TLString.deserialized(_data);
      if (!endpoint) return undefined;
      const sourceGroups = TLVector.deserialized(
        _data,
        API.GroupCallParticipantVideoSourceGroup
      );
      if (!sourceGroups) return undefined;
      let audioSource: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        audioSource = obj;
      }

      return new GroupCallParticipantVideo(
        paused,
        endpoint,
        sourceGroups,
        audioSource
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [GroupCallParticipantVideo.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.paused) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.endpoint);
      _data.push(this.sourceGroups);
      if (this.audioSource) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.audioSource) _data.push(this.audioSource);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly paused: boolean | undefined,
      readonly endpoint: TLString,
      readonly sourceGroups: TLVector<API.GroupCallParticipantVideoSourceGroup>,
      readonly audioSource: TLInt | undefined
    ) {}
  }

  export namespace stickers {
    export class SuggestedShortName implements TLObject {
      static readonly cons = new TLInt(0x85fea03f);

      static deserialized(_data: ByteStream): SuggestedShortName | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SuggestedShortName.cons))
          return undefined;

        const shortName = TLString.deserialized(_data);
        if (!shortName) return undefined;

        return new SuggestedShortName(shortName);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SuggestedShortName.cons];

        _data.push(this.shortName);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly shortName: TLString) {}
    }
  }

  export class BotCommandScopeDefault implements TLObject {
    static readonly cons = new TLInt(0x2f6cb2ab);

    static deserialized(_data: ByteStream): BotCommandScopeDefault | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotCommandScopeDefault.cons))
        return undefined;

      return new BotCommandScopeDefault();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotCommandScopeDefault.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BotCommandScopeUsers implements TLObject {
    static readonly cons = new TLInt(0x3c4f04d8);

    static deserialized(_data: ByteStream): BotCommandScopeUsers | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotCommandScopeUsers.cons))
        return undefined;

      return new BotCommandScopeUsers();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotCommandScopeUsers.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BotCommandScopeChats implements TLObject {
    static readonly cons = new TLInt(0x6fe1a881);

    static deserialized(_data: ByteStream): BotCommandScopeChats | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotCommandScopeChats.cons))
        return undefined;

      return new BotCommandScopeChats();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotCommandScopeChats.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BotCommandScopeChatAdmins implements TLObject {
    static readonly cons = new TLInt(0xb9aa606a);

    static deserialized(
      _data: ByteStream
    ): BotCommandScopeChatAdmins | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotCommandScopeChatAdmins.cons))
        return undefined;

      return new BotCommandScopeChatAdmins();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotCommandScopeChatAdmins.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BotCommandScopePeer implements TLObject {
    static readonly cons = new TLInt(0xdb9d897d);

    static deserialized(_data: ByteStream): BotCommandScopePeer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotCommandScopePeer.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;

      return new BotCommandScopePeer(peer);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotCommandScopePeer.cons];

      _data.push(this.peer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: InputPeerType) {}
  }

  export class BotCommandScopePeerAdmins implements TLObject {
    static readonly cons = new TLInt(0x3fd863d1);

    static deserialized(
      _data: ByteStream
    ): BotCommandScopePeerAdmins | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotCommandScopePeerAdmins.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;

      return new BotCommandScopePeerAdmins(peer);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotCommandScopePeerAdmins.cons];

      _data.push(this.peer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: InputPeerType) {}
  }

  export class BotCommandScopePeerUser implements TLObject {
    static readonly cons = new TLInt(0xa1321f3);

    static deserialized(
      _data: ByteStream
    ): BotCommandScopePeerUser | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotCommandScopePeerUser.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;
      const userId = TLObject.deserialized(_data) as InputUserType;
      if (!userId) return undefined;

      return new BotCommandScopePeerUser(peer, userId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotCommandScopePeerUser.cons];

      _data.push(this.peer);
      _data.push(this.userId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: InputPeerType, readonly userId: InputUserType) {}
  }

  export namespace account {
    export class ResetPasswordFailedWait implements TLObject {
      static readonly cons = new TLInt(0xe3779861);

      static deserialized(
        _data: ByteStream
      ): ResetPasswordFailedWait | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetPasswordFailedWait.cons))
          return undefined;

        const retryDate = TLInt.deserialized(_data);
        if (!retryDate) return undefined;

        return new ResetPasswordFailedWait(retryDate);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetPasswordFailedWait.cons];

        _data.push(this.retryDate);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly retryDate: TLInt) {}
    }
  }

  export namespace account {
    export class ResetPasswordRequestedWait implements TLObject {
      static readonly cons = new TLInt(0xe9effc7d);

      static deserialized(
        _data: ByteStream
      ): ResetPasswordRequestedWait | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(ResetPasswordRequestedWait.cons)
        )
          return undefined;

        const untilDate = TLInt.deserialized(_data);
        if (!untilDate) return undefined;

        return new ResetPasswordRequestedWait(untilDate);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetPasswordRequestedWait.cons];

        _data.push(this.untilDate);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly untilDate: TLInt) {}
    }
  }

  export namespace account {
    export class ResetPasswordOk implements TLObject {
      static readonly cons = new TLInt(0xe926d63e);

      static deserialized(_data: ByteStream): ResetPasswordOk | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetPasswordOk.cons))
          return undefined;

        return new ResetPasswordOk();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetPasswordOk.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export class SponsoredMessage implements TLObject {
    static readonly cons = new TLInt(0xdaafff6b);

    static deserialized(_data: ByteStream): SponsoredMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SponsoredMessage.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const recommended = (flags.value & 32) !== 0;
      const showPeerPhoto = (flags.value & 64) !== 0;
      const randomId = TLBytes.deserialized(_data);
      if (!randomId) return undefined;
      let fromId: PeerType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as PeerType;
        if (!obj) return undefined;
        fromId = obj;
      }
      let chatInvite: ChatInviteType | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLObject.deserialized(_data) as ChatInviteType;
        if (!obj) return undefined;
        chatInvite = obj;
      }
      let chatInviteHash: TLString | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        chatInviteHash = obj;
      }
      let channelPost: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        channelPost = obj;
      }
      let startParam: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        startParam = obj;
      }
      let webpage: API.SponsoredWebPage | undefined;
      if ((flags.value & 512) !== 0) {
        const obj = TLObject.deserialized(_data) as API.SponsoredWebPage;
        if (!obj) return undefined;
        webpage = obj;
      }
      const message = TLString.deserialized(_data);
      if (!message) return undefined;
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }
      let sponsorInfo: TLString | undefined;
      if ((flags.value & 128) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        sponsorInfo = obj;
      }
      let additionalInfo: TLString | undefined;
      if ((flags.value & 256) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        additionalInfo = obj;
      }

      return new SponsoredMessage(
        recommended,
        showPeerPhoto,
        randomId,
        fromId,
        chatInvite,
        chatInviteHash,
        channelPost,
        startParam,
        webpage,
        message,
        entities,
        sponsorInfo,
        additionalInfo
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SponsoredMessage.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.recommended) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.showPeerPhoto) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      _data.push(this.randomId);
      if (this.fromId) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.fromId) _data.push(this.fromId);

      if (this.chatInvite) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.chatInvite) _data.push(this.chatInvite);

      if (this.chatInviteHash) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.chatInviteHash) _data.push(this.chatInviteHash);

      if (this.channelPost) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.channelPost) _data.push(this.channelPost);

      if (this.startParam) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.startParam) _data.push(this.startParam);

      if (this.webpage) {
        flags.set(512);
      } else {
        flags.unset(512);
      }
      if (this.webpage) _data.push(this.webpage);

      _data.push(this.message);
      if (this.entities) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.entities) _data.push(this.entities);

      if (this.sponsorInfo) {
        flags.set(128);
      } else {
        flags.unset(128);
      }
      if (this.sponsorInfo) _data.push(this.sponsorInfo);

      if (this.additionalInfo) {
        flags.set(256);
      } else {
        flags.unset(256);
      }
      if (this.additionalInfo) _data.push(this.additionalInfo);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly recommended: boolean | undefined,
      readonly showPeerPhoto: boolean | undefined,
      readonly randomId: TLBytes,
      readonly fromId: PeerType | undefined,
      readonly chatInvite: ChatInviteType | undefined,
      readonly chatInviteHash: TLString | undefined,
      readonly channelPost: TLInt | undefined,
      readonly startParam: TLString | undefined,
      readonly webpage: API.SponsoredWebPage | undefined,
      readonly message: TLString,
      readonly entities: TLVector<MessageEntityType> | undefined,
      readonly sponsorInfo: TLString | undefined,
      readonly additionalInfo: TLString | undefined
    ) {}
  }

  export namespace messages {
    export class SponsoredMessages implements TLObject {
      static readonly cons = new TLInt(0xc9ee1d87);

      static deserialized(_data: ByteStream): SponsoredMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SponsoredMessages.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let postsBetween: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          postsBetween = obj;
        }
        const messages = TLVector.deserialized(_data, API.SponsoredMessage);
        if (!messages) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new SponsoredMessages(postsBetween, messages, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SponsoredMessages.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.postsBetween) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.postsBetween) _data.push(this.postsBetween);

        _data.push(this.messages);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly postsBetween: TLInt | undefined,
        readonly messages: TLVector<API.SponsoredMessage>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class SponsoredMessagesEmpty implements TLObject {
      static readonly cons = new TLInt(0x1839490f);

      static deserialized(
        _data: ByteStream
      ): SponsoredMessagesEmpty | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SponsoredMessagesEmpty.cons))
          return undefined;

        return new SponsoredMessagesEmpty();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SponsoredMessagesEmpty.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export class SearchResultsCalendarPeriod implements TLObject {
    static readonly cons = new TLInt(0xc9b0539f);

    static deserialized(
      _data: ByteStream
    ): SearchResultsCalendarPeriod | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SearchResultsCalendarPeriod.cons))
        return undefined;

      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const minMsgId = TLInt.deserialized(_data);
      if (!minMsgId) return undefined;
      const maxMsgId = TLInt.deserialized(_data);
      if (!maxMsgId) return undefined;
      const count = TLInt.deserialized(_data);
      if (!count) return undefined;

      return new SearchResultsCalendarPeriod(date, minMsgId, maxMsgId, count);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SearchResultsCalendarPeriod.cons];

      _data.push(this.date);
      _data.push(this.minMsgId);
      _data.push(this.maxMsgId);
      _data.push(this.count);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly date: TLInt,
      readonly minMsgId: TLInt,
      readonly maxMsgId: TLInt,
      readonly count: TLInt
    ) {}
  }

  export namespace messages {
    export class SearchResultsCalendar implements TLObject {
      static readonly cons = new TLInt(0x147ee23c);

      static deserialized(
        _data: ByteStream
      ): SearchResultsCalendar | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SearchResultsCalendar.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const inexact = (flags.value & 1) !== 0;
        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const minDate = TLInt.deserialized(_data);
        if (!minDate) return undefined;
        const minMsgId = TLInt.deserialized(_data);
        if (!minMsgId) return undefined;
        let offsetIdOffset: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          offsetIdOffset = obj;
        }
        const periods = TLVector.deserialized(
          _data,
          API.SearchResultsCalendarPeriod
        );
        if (!periods) return undefined;
        const messages = TLVector.deserialized(_data) as TLVector<MessageType>;
        if (!messages) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new SearchResultsCalendar(
          inexact,
          count,
          minDate,
          minMsgId,
          offsetIdOffset,
          periods,
          messages,
          chats,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SearchResultsCalendar.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.inexact) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.count);
        _data.push(this.minDate);
        _data.push(this.minMsgId);
        if (this.offsetIdOffset) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.offsetIdOffset) _data.push(this.offsetIdOffset);

        _data.push(this.periods);
        _data.push(this.messages);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly inexact: boolean | undefined,
        readonly count: TLInt,
        readonly minDate: TLInt,
        readonly minMsgId: TLInt,
        readonly offsetIdOffset: TLInt | undefined,
        readonly periods: TLVector<API.SearchResultsCalendarPeriod>,
        readonly messages: TLVector<MessageType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class SearchResultPosition implements TLObject {
    static readonly cons = new TLInt(0x7f648b67);

    static deserialized(_data: ByteStream): SearchResultPosition | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SearchResultPosition.cons))
        return undefined;

      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const offset = TLInt.deserialized(_data);
      if (!offset) return undefined;

      return new SearchResultPosition(msgId, date, offset);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SearchResultPosition.cons];

      _data.push(this.msgId);
      _data.push(this.date);
      _data.push(this.offset);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly msgId: TLInt,
      readonly date: TLInt,
      readonly offset: TLInt
    ) {}
  }

  export namespace messages {
    export class SearchResultsPositions implements TLObject {
      static readonly cons = new TLInt(0x53b22baf);

      static deserialized(
        _data: ByteStream
      ): SearchResultsPositions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SearchResultsPositions.cons))
          return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const positions = TLVector.deserialized(
          _data
        ) as TLVector<SearchResultsPositionType>;
        if (!positions) return undefined;

        return new SearchResultsPositions(count, positions);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SearchResultsPositions.cons];

        _data.push(this.count);
        _data.push(this.positions);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly positions: TLVector<SearchResultsPositionType>
      ) {}
    }
  }

  export namespace channels {
    export class SendAsPeers implements TLObject {
      static readonly cons = new TLInt(0xf496b0c6);

      static deserialized(_data: ByteStream): SendAsPeers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendAsPeers.cons))
          return undefined;

        const peers = TLVector.deserialized(_data, API.SendAsPeer);
        if (!peers) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new SendAsPeers(peers, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendAsPeers.cons];

        _data.push(this.peers);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peers: TLVector<API.SendAsPeer>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace users {
    export class UserFull implements TLObject {
      static readonly cons = new TLInt(0x3b6d152e);

      static deserialized(_data: ByteStream): UserFull | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UserFull.cons))
          return undefined;

        const fullUser = API.UserFull.deserialized(_data);
        if (!fullUser) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new UserFull(fullUser, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UserFull.cons];

        _data.push(this.fullUser);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly fullUser: API.UserFull,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class PeerSettings implements TLObject {
      static readonly cons = new TLInt(0x6880b94d);

      static deserialized(_data: ByteStream): PeerSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PeerSettings.cons))
          return undefined;

        const settings = API.PeerSettings.deserialized(_data);
        if (!settings) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new PeerSettings(settings, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PeerSettings.cons];

        _data.push(this.settings);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly settings: API.PeerSettings,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace auth {
    export class LoggedOut implements TLObject {
      static readonly cons = new TLInt(0xc3a2835f);

      static deserialized(_data: ByteStream): LoggedOut | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(LoggedOut.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let futureAuthToken: TLBytes | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLBytes.deserialized(_data);
          if (!obj) return undefined;
          futureAuthToken = obj;
        }

        return new LoggedOut(futureAuthToken);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [LoggedOut.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.futureAuthToken) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.futureAuthToken) _data.push(this.futureAuthToken);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly futureAuthToken: TLBytes | undefined) {}
    }
  }

  export class ReactionCount implements TLObject {
    static readonly cons = new TLInt(0xa3d1cb80);

    static deserialized(_data: ByteStream): ReactionCount | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ReactionCount.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let chosenOrder: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        chosenOrder = obj;
      }
      const reaction = TLObject.deserialized(_data) as ReactionType;
      if (!reaction) return undefined;
      const count = TLInt.deserialized(_data);
      if (!count) return undefined;

      return new ReactionCount(chosenOrder, reaction, count);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ReactionCount.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.chosenOrder) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.chosenOrder) _data.push(this.chosenOrder);

      _data.push(this.reaction);
      _data.push(this.count);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly chosenOrder: TLInt | undefined,
      readonly reaction: ReactionType,
      readonly count: TLInt
    ) {}
  }

  export class MessageReactions implements TLObject {
    static readonly cons = new TLInt(0x4f2b9479);

    static deserialized(_data: ByteStream): MessageReactions | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageReactions.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const min = (flags.value & 1) !== 0;
      const canSeeList = (flags.value & 4) !== 0;
      const results = TLVector.deserialized(_data, API.ReactionCount);
      if (!results) return undefined;
      let recentReactions: TLVector<API.MessagePeerReaction> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data, API.MessagePeerReaction);
        if (!obj) return undefined;
        recentReactions = obj;
      }

      return new MessageReactions(min, canSeeList, results, recentReactions);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageReactions.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.min) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.canSeeList) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.results);
      if (this.recentReactions) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.recentReactions) _data.push(this.recentReactions);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly min: boolean | undefined,
      readonly canSeeList: boolean | undefined,
      readonly results: TLVector<API.ReactionCount>,
      readonly recentReactions: TLVector<API.MessagePeerReaction> | undefined
    ) {}
  }

  export namespace messages {
    export class MessageReactionsList implements TLObject {
      static readonly cons = new TLInt(0x31bd492d);

      static deserialized(_data: ByteStream): MessageReactionsList | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(MessageReactionsList.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const reactions = TLVector.deserialized(_data, API.MessagePeerReaction);
        if (!reactions) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;
        let nextOffset: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          nextOffset = obj;
        }

        return new MessageReactionsList(
          count,
          reactions,
          chats,
          users,
          nextOffset
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [MessageReactionsList.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.count);
        _data.push(this.reactions);
        _data.push(this.chats);
        _data.push(this.users);
        if (this.nextOffset) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.nextOffset) _data.push(this.nextOffset);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly reactions: TLVector<API.MessagePeerReaction>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>,
        readonly nextOffset: TLString | undefined
      ) {}
    }
  }

  export class AvailableReaction implements TLObject {
    static readonly cons = new TLInt(0xc077ec01);

    static deserialized(_data: ByteStream): AvailableReaction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AvailableReaction.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const inactive = (flags.value & 1) !== 0;
      const premium = (flags.value & 4) !== 0;
      const reaction = TLString.deserialized(_data);
      if (!reaction) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const staticIcon = TLObject.deserialized(_data) as DocumentType;
      if (!staticIcon) return undefined;
      const appearAnimation = TLObject.deserialized(_data) as DocumentType;
      if (!appearAnimation) return undefined;
      const selectAnimation = TLObject.deserialized(_data) as DocumentType;
      if (!selectAnimation) return undefined;
      const activateAnimation = TLObject.deserialized(_data) as DocumentType;
      if (!activateAnimation) return undefined;
      const effectAnimation = TLObject.deserialized(_data) as DocumentType;
      if (!effectAnimation) return undefined;
      let aroundAnimation: DocumentType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as DocumentType;
        if (!obj) return undefined;
        aroundAnimation = obj;
      }
      let centerIcon: DocumentType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as DocumentType;
        if (!obj) return undefined;
        centerIcon = obj;
      }

      return new AvailableReaction(
        inactive,
        premium,
        reaction,
        title,
        staticIcon,
        appearAnimation,
        selectAnimation,
        activateAnimation,
        effectAnimation,
        aroundAnimation,
        centerIcon
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AvailableReaction.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.inactive) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.premium) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.reaction);
      _data.push(this.title);
      _data.push(this.staticIcon);
      _data.push(this.appearAnimation);
      _data.push(this.selectAnimation);
      _data.push(this.activateAnimation);
      _data.push(this.effectAnimation);
      if (this.aroundAnimation) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.aroundAnimation) _data.push(this.aroundAnimation);

      if (this.centerIcon) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.centerIcon) _data.push(this.centerIcon);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly inactive: boolean | undefined,
      readonly premium: boolean | undefined,
      readonly reaction: TLString,
      readonly title: TLString,
      readonly staticIcon: DocumentType,
      readonly appearAnimation: DocumentType,
      readonly selectAnimation: DocumentType,
      readonly activateAnimation: DocumentType,
      readonly effectAnimation: DocumentType,
      readonly aroundAnimation: DocumentType | undefined,
      readonly centerIcon: DocumentType | undefined
    ) {}
  }

  export namespace messages {
    export class AvailableReactionsNotModified implements TLObject {
      static readonly cons = new TLInt(0x9f071957);

      static deserialized(
        _data: ByteStream
      ): AvailableReactionsNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(AvailableReactionsNotModified.cons)
        )
          return undefined;

        return new AvailableReactionsNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          AvailableReactionsNotModified.cons,
        ];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class AvailableReactions implements TLObject {
      static readonly cons = new TLInt(0x768e3aad);

      static deserialized(_data: ByteStream): AvailableReactions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AvailableReactions.cons))
          return undefined;

        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;
        const reactions = TLVector.deserialized(_data, API.AvailableReaction);
        if (!reactions) return undefined;

        return new AvailableReactions(hash, reactions);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AvailableReactions.cons];

        _data.push(this.hash);
        _data.push(this.reactions);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLInt,
        readonly reactions: TLVector<API.AvailableReaction>
      ) {}
    }
  }

  export class MessagePeerReaction implements TLObject {
    static readonly cons = new TLInt(0x8c79b63c);

    static deserialized(_data: ByteStream): MessagePeerReaction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessagePeerReaction.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const big = (flags.value & 1) !== 0;
      const unread = (flags.value & 2) !== 0;
      const my = (flags.value & 4) !== 0;
      const peerId = TLObject.deserialized(_data) as PeerType;
      if (!peerId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const reaction = TLObject.deserialized(_data) as ReactionType;
      if (!reaction) return undefined;

      return new MessagePeerReaction(big, unread, my, peerId, date, reaction);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessagePeerReaction.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.big) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.unread) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.my) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      _data.push(this.peerId);
      _data.push(this.date);
      _data.push(this.reaction);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly big: boolean | undefined,
      readonly unread: boolean | undefined,
      readonly my: boolean | undefined,
      readonly peerId: PeerType,
      readonly date: TLInt,
      readonly reaction: ReactionType
    ) {}
  }

  export class GroupCallStreamChannel implements TLObject {
    static readonly cons = new TLInt(0x80eb48af);

    static deserialized(_data: ByteStream): GroupCallStreamChannel | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(GroupCallStreamChannel.cons))
        return undefined;

      const channel = TLInt.deserialized(_data);
      if (!channel) return undefined;
      const scale = TLInt.deserialized(_data);
      if (!scale) return undefined;
      const lastTimestampMs = TLLong.deserialized(_data);
      if (!lastTimestampMs) return undefined;

      return new GroupCallStreamChannel(channel, scale, lastTimestampMs);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [GroupCallStreamChannel.cons];

      _data.push(this.channel);
      _data.push(this.scale);
      _data.push(this.lastTimestampMs);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly channel: TLInt,
      readonly scale: TLInt,
      readonly lastTimestampMs: TLLong
    ) {}
  }

  export namespace phone {
    export class GroupCallStreamChannels implements TLObject {
      static readonly cons = new TLInt(0xd0e482b2);

      static deserialized(
        _data: ByteStream
      ): GroupCallStreamChannels | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GroupCallStreamChannels.cons))
          return undefined;

        const channels = TLVector.deserialized(
          _data,
          API.GroupCallStreamChannel
        );
        if (!channels) return undefined;

        return new GroupCallStreamChannels(channels);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GroupCallStreamChannels.cons];

        _data.push(this.channels);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly channels: TLVector<API.GroupCallStreamChannel>) {}
    }
  }

  export namespace phone {
    export class GroupCallStreamRtmpUrl implements TLObject {
      static readonly cons = new TLInt(0x2dbf3432);

      static deserialized(
        _data: ByteStream
      ): GroupCallStreamRtmpUrl | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GroupCallStreamRtmpUrl.cons))
          return undefined;

        const url = TLString.deserialized(_data);
        if (!url) return undefined;
        const key = TLString.deserialized(_data);
        if (!key) return undefined;

        return new GroupCallStreamRtmpUrl(url, key);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GroupCallStreamRtmpUrl.cons];

        _data.push(this.url);
        _data.push(this.key);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly url: TLString, readonly key: TLString) {}
    }
  }

  export class AttachMenuBotIconColor implements TLObject {
    static readonly cons = new TLInt(0x4576f3f0);

    static deserialized(_data: ByteStream): AttachMenuBotIconColor | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AttachMenuBotIconColor.cons))
        return undefined;

      const name = TLString.deserialized(_data);
      if (!name) return undefined;
      const color = TLInt.deserialized(_data);
      if (!color) return undefined;

      return new AttachMenuBotIconColor(name, color);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AttachMenuBotIconColor.cons];

      _data.push(this.name);
      _data.push(this.color);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly name: TLString, readonly color: TLInt) {}
  }

  export class AttachMenuBotIcon implements TLObject {
    static readonly cons = new TLInt(0xb2a7386b);

    static deserialized(_data: ByteStream): AttachMenuBotIcon | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AttachMenuBotIcon.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const name = TLString.deserialized(_data);
      if (!name) return undefined;
      const icon = TLObject.deserialized(_data) as DocumentType;
      if (!icon) return undefined;
      let colors: TLVector<API.AttachMenuBotIconColor> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data, API.AttachMenuBotIconColor);
        if (!obj) return undefined;
        colors = obj;
      }

      return new AttachMenuBotIcon(name, icon, colors);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AttachMenuBotIcon.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.name);
      _data.push(this.icon);
      if (this.colors) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.colors) _data.push(this.colors);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly name: TLString,
      readonly icon: DocumentType,
      readonly colors: TLVector<API.AttachMenuBotIconColor> | undefined
    ) {}
  }

  export class AttachMenuBot implements TLObject {
    static readonly cons = new TLInt(0xd90d8dfe);

    static deserialized(_data: ByteStream): AttachMenuBot | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AttachMenuBot.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const inactive = (flags.value & 1) !== 0;
      const hasSettings = (flags.value & 2) !== 0;
      const requestWriteAccess = (flags.value & 4) !== 0;
      const showInAttachMenu = (flags.value & 8) !== 0;
      const showInSideMenu = (flags.value & 16) !== 0;
      const sideMenuDisclaimerNeeded = (flags.value & 32) !== 0;
      const botId = TLLong.deserialized(_data);
      if (!botId) return undefined;
      const shortName = TLString.deserialized(_data);
      if (!shortName) return undefined;
      let peerTypes: TLVector<AttachMenuPeerTypeType> | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLVector.deserialized(
          _data
        ) as TLVector<AttachMenuPeerTypeType>;
        if (!obj) return undefined;
        peerTypes = obj;
      }
      const icons = TLVector.deserialized(_data, API.AttachMenuBotIcon);
      if (!icons) return undefined;

      return new AttachMenuBot(
        inactive,
        hasSettings,
        requestWriteAccess,
        showInAttachMenu,
        showInSideMenu,
        sideMenuDisclaimerNeeded,
        botId,
        shortName,
        peerTypes,
        icons
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AttachMenuBot.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.inactive) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.hasSettings) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.requestWriteAccess) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.showInAttachMenu) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.showInSideMenu) {
        flags.set(16);
      } else {
        flags.unset(16);
      }

      if (this.sideMenuDisclaimerNeeded) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      _data.push(this.botId);
      _data.push(this.shortName);
      if (this.peerTypes) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.peerTypes) _data.push(this.peerTypes);

      _data.push(this.icons);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly inactive: boolean | undefined,
      readonly hasSettings: boolean | undefined,
      readonly requestWriteAccess: boolean | undefined,
      readonly showInAttachMenu: boolean | undefined,
      readonly showInSideMenu: boolean | undefined,
      readonly sideMenuDisclaimerNeeded: boolean | undefined,
      readonly botId: TLLong,
      readonly shortName: TLString,
      readonly peerTypes: TLVector<AttachMenuPeerTypeType> | undefined,
      readonly icons: TLVector<API.AttachMenuBotIcon>
    ) {}
  }

  export class AttachMenuBotsNotModified implements TLObject {
    static readonly cons = new TLInt(0xf1d88a5c);

    static deserialized(
      _data: ByteStream
    ): AttachMenuBotsNotModified | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AttachMenuBotsNotModified.cons))
        return undefined;

      return new AttachMenuBotsNotModified();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AttachMenuBotsNotModified.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class AttachMenuBots implements TLObject {
    static readonly cons = new TLInt(0x3c4301c0);

    static deserialized(_data: ByteStream): AttachMenuBots | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AttachMenuBots.cons))
        return undefined;

      const hash = TLLong.deserialized(_data);
      if (!hash) return undefined;
      const bots = TLVector.deserialized(_data, API.AttachMenuBot);
      if (!bots) return undefined;
      const users = TLVector.deserialized(_data) as TLVector<UserType>;
      if (!users) return undefined;

      return new AttachMenuBots(hash, bots, users);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AttachMenuBots.cons];

      _data.push(this.hash);
      _data.push(this.bots);
      _data.push(this.users);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly hash: TLLong,
      readonly bots: TLVector<API.AttachMenuBot>,
      readonly users: TLVector<UserType>
    ) {}
  }

  export class AttachMenuBotsBot implements TLObject {
    static readonly cons = new TLInt(0x93bf667f);

    static deserialized(_data: ByteStream): AttachMenuBotsBot | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AttachMenuBotsBot.cons))
        return undefined;

      const bot = API.AttachMenuBot.deserialized(_data);
      if (!bot) return undefined;
      const users = TLVector.deserialized(_data) as TLVector<UserType>;
      if (!users) return undefined;

      return new AttachMenuBotsBot(bot, users);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AttachMenuBotsBot.cons];

      _data.push(this.bot);
      _data.push(this.users);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly bot: API.AttachMenuBot,
      readonly users: TLVector<UserType>
    ) {}
  }

  export class WebViewResultUrl implements TLObject {
    static readonly cons = new TLInt(0xc14557c);

    static deserialized(_data: ByteStream): WebViewResultUrl | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WebViewResultUrl.cons))
        return undefined;

      const queryId = TLLong.deserialized(_data);
      if (!queryId) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new WebViewResultUrl(queryId, url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WebViewResultUrl.cons];

      _data.push(this.queryId);
      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly queryId: TLLong, readonly url: TLString) {}
  }

  export class SimpleWebViewResultUrl implements TLObject {
    static readonly cons = new TLInt(0x882f76bb);

    static deserialized(_data: ByteStream): SimpleWebViewResultUrl | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SimpleWebViewResultUrl.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new SimpleWebViewResultUrl(url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SimpleWebViewResultUrl.cons];

      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString) {}
  }

  export class WebViewMessageSent implements TLObject {
    static readonly cons = new TLInt(0xc94511c);

    static deserialized(_data: ByteStream): WebViewMessageSent | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(WebViewMessageSent.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let msgId: InputBotInlineMessageIDType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as InputBotInlineMessageIDType;
        if (!obj) return undefined;
        msgId = obj;
      }

      return new WebViewMessageSent(msgId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [WebViewMessageSent.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.msgId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.msgId) _data.push(this.msgId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly msgId: InputBotInlineMessageIDType | undefined) {}
  }

  export class BotMenuButtonDefault implements TLObject {
    static readonly cons = new TLInt(0x7533a588);

    static deserialized(_data: ByteStream): BotMenuButtonDefault | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotMenuButtonDefault.cons))
        return undefined;

      return new BotMenuButtonDefault();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotMenuButtonDefault.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BotMenuButtonCommands implements TLObject {
    static readonly cons = new TLInt(0x4258c205);

    static deserialized(_data: ByteStream): BotMenuButtonCommands | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotMenuButtonCommands.cons))
        return undefined;

      return new BotMenuButtonCommands();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotMenuButtonCommands.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BotMenuButton implements TLObject {
    static readonly cons = new TLInt(0xc7b57ce6);

    static deserialized(_data: ByteStream): BotMenuButton | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotMenuButton.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new BotMenuButton(text, url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotMenuButton.cons];

      _data.push(this.text);
      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString, readonly url: TLString) {}
  }

  export namespace account {
    export class SavedRingtonesNotModified implements TLObject {
      static readonly cons = new TLInt(0xfbf6e8b1);

      static deserialized(
        _data: ByteStream
      ): SavedRingtonesNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SavedRingtonesNotModified.cons))
          return undefined;

        return new SavedRingtonesNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SavedRingtonesNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class SavedRingtones implements TLObject {
      static readonly cons = new TLInt(0xc1e92cc5);

      static deserialized(_data: ByteStream): SavedRingtones | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SavedRingtones.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const ringtones = TLVector.deserialized(
          _data
        ) as TLVector<DocumentType>;
        if (!ringtones) return undefined;

        return new SavedRingtones(hash, ringtones);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SavedRingtones.cons];

        _data.push(this.hash);
        _data.push(this.ringtones);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLLong,
        readonly ringtones: TLVector<DocumentType>
      ) {}
    }
  }

  export class NotificationSoundDefault implements TLObject {
    static readonly cons = new TLInt(0x97e8bebe);

    static deserialized(
      _data: ByteStream
    ): NotificationSoundDefault | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(NotificationSoundDefault.cons))
        return undefined;

      return new NotificationSoundDefault();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [NotificationSoundDefault.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class NotificationSoundNone implements TLObject {
    static readonly cons = new TLInt(0x6f0c34df);

    static deserialized(_data: ByteStream): NotificationSoundNone | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(NotificationSoundNone.cons))
        return undefined;

      return new NotificationSoundNone();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [NotificationSoundNone.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class NotificationSoundLocal implements TLObject {
    static readonly cons = new TLInt(0x830b9ae4);

    static deserialized(_data: ByteStream): NotificationSoundLocal | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(NotificationSoundLocal.cons))
        return undefined;

      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const data = TLString.deserialized(_data);
      if (!data) return undefined;

      return new NotificationSoundLocal(title, data);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [NotificationSoundLocal.cons];

      _data.push(this.title);
      _data.push(this.data);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly title: TLString, readonly data: TLString) {}
  }

  export class NotificationSoundRingtone implements TLObject {
    static readonly cons = new TLInt(0xff6c8049);

    static deserialized(
      _data: ByteStream
    ): NotificationSoundRingtone | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(NotificationSoundRingtone.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;

      return new NotificationSoundRingtone(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [NotificationSoundRingtone.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong) {}
  }

  export namespace account {
    export class SavedRingtone implements TLObject {
      static readonly cons = new TLInt(0xb7263f6d);

      static deserialized(_data: ByteStream): SavedRingtone | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SavedRingtone.cons))
          return undefined;

        return new SavedRingtone();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SavedRingtone.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class SavedRingtoneConverted implements TLObject {
      static readonly cons = new TLInt(0x1f307eb7);

      static deserialized(
        _data: ByteStream
      ): SavedRingtoneConverted | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SavedRingtoneConverted.cons))
          return undefined;

        const document = TLObject.deserialized(_data) as DocumentType;
        if (!document) return undefined;

        return new SavedRingtoneConverted(document);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SavedRingtoneConverted.cons];

        _data.push(this.document);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly document: DocumentType) {}
    }
  }

  export class AttachMenuPeerTypeSameBotPM implements TLObject {
    static readonly cons = new TLInt(0x7d6be90e);

    static deserialized(
      _data: ByteStream
    ): AttachMenuPeerTypeSameBotPM | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AttachMenuPeerTypeSameBotPM.cons))
        return undefined;

      return new AttachMenuPeerTypeSameBotPM();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AttachMenuPeerTypeSameBotPM.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class AttachMenuPeerTypeBotPM implements TLObject {
    static readonly cons = new TLInt(0xc32bfa1a);

    static deserialized(
      _data: ByteStream
    ): AttachMenuPeerTypeBotPM | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AttachMenuPeerTypeBotPM.cons))
        return undefined;

      return new AttachMenuPeerTypeBotPM();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AttachMenuPeerTypeBotPM.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class AttachMenuPeerTypePM implements TLObject {
    static readonly cons = new TLInt(0xf146d31f);

    static deserialized(_data: ByteStream): AttachMenuPeerTypePM | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AttachMenuPeerTypePM.cons))
        return undefined;

      return new AttachMenuPeerTypePM();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AttachMenuPeerTypePM.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class AttachMenuPeerTypeChat implements TLObject {
    static readonly cons = new TLInt(0x509113f);

    static deserialized(_data: ByteStream): AttachMenuPeerTypeChat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AttachMenuPeerTypeChat.cons))
        return undefined;

      return new AttachMenuPeerTypeChat();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AttachMenuPeerTypeChat.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class AttachMenuPeerTypeBroadcast implements TLObject {
    static readonly cons = new TLInt(0x7bfbdefc);

    static deserialized(
      _data: ByteStream
    ): AttachMenuPeerTypeBroadcast | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AttachMenuPeerTypeBroadcast.cons))
        return undefined;

      return new AttachMenuPeerTypeBroadcast();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AttachMenuPeerTypeBroadcast.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class InputInvoiceMessage implements TLObject {
    static readonly cons = new TLInt(0xc5b56859);

    static deserialized(_data: ByteStream): InputInvoiceMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputInvoiceMessage.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as InputPeerType;
      if (!peer) return undefined;
      const msgId = TLInt.deserialized(_data);
      if (!msgId) return undefined;

      return new InputInvoiceMessage(peer, msgId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputInvoiceMessage.cons];

      _data.push(this.peer);
      _data.push(this.msgId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: InputPeerType, readonly msgId: TLInt) {}
  }

  export class InputInvoiceSlug implements TLObject {
    static readonly cons = new TLInt(0xc326caef);

    static deserialized(_data: ByteStream): InputInvoiceSlug | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputInvoiceSlug.cons))
        return undefined;

      const slug = TLString.deserialized(_data);
      if (!slug) return undefined;

      return new InputInvoiceSlug(slug);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputInvoiceSlug.cons];

      _data.push(this.slug);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly slug: TLString) {}
  }

  export namespace payments {
    export class ExportedInvoice implements TLObject {
      static readonly cons = new TLInt(0xaed0cbd9);

      static deserialized(_data: ByteStream): ExportedInvoice | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportedInvoice.cons))
          return undefined;

        const url = TLString.deserialized(_data);
        if (!url) return undefined;

        return new ExportedInvoice(url);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportedInvoice.cons];

        _data.push(this.url);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly url: TLString) {}
    }
  }

  export namespace messages {
    export class TranscribedAudio implements TLObject {
      static readonly cons = new TLInt(0x93752c52);

      static deserialized(_data: ByteStream): TranscribedAudio | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TranscribedAudio.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const pending = (flags.value & 1) !== 0;
        const transcriptionId = TLLong.deserialized(_data);
        if (!transcriptionId) return undefined;
        const text = TLString.deserialized(_data);
        if (!text) return undefined;

        return new TranscribedAudio(pending, transcriptionId, text);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TranscribedAudio.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.pending) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.transcriptionId);
        _data.push(this.text);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly pending: boolean | undefined,
        readonly transcriptionId: TLLong,
        readonly text: TLString
      ) {}
    }
  }

  export namespace help {
    export class PremiumPromo implements TLObject {
      static readonly cons = new TLInt(0x5334759c);

      static deserialized(_data: ByteStream): PremiumPromo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PremiumPromo.cons))
          return undefined;

        const statusText = TLString.deserialized(_data);
        if (!statusText) return undefined;
        const statusEntities = TLVector.deserialized(
          _data
        ) as TLVector<MessageEntityType>;
        if (!statusEntities) return undefined;
        const videoSections = TLVector.deserialized(_data, TLString);
        if (!videoSections) return undefined;
        const videos = TLVector.deserialized(_data) as TLVector<DocumentType>;
        if (!videos) return undefined;
        const periodOptions = TLVector.deserialized(
          _data,
          API.PremiumSubscriptionOption
        );
        if (!periodOptions) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new PremiumPromo(
          statusText,
          statusEntities,
          videoSections,
          videos,
          periodOptions,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PremiumPromo.cons];

        _data.push(this.statusText);
        _data.push(this.statusEntities);
        _data.push(this.videoSections);
        _data.push(this.videos);
        _data.push(this.periodOptions);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly statusText: TLString,
        readonly statusEntities: TLVector<MessageEntityType>,
        readonly videoSections: TLVector<TLString>,
        readonly videos: TLVector<DocumentType>,
        readonly periodOptions: TLVector<API.PremiumSubscriptionOption>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class InputStorePaymentPremiumSubscription implements TLObject {
    static readonly cons = new TLInt(0xa6751e66);

    static deserialized(
      _data: ByteStream
    ): InputStorePaymentPremiumSubscription | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputStorePaymentPremiumSubscription.cons)
      )
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const restore = (flags.value & 1) !== 0;
      const upgrade = (flags.value & 2) !== 0;

      return new InputStorePaymentPremiumSubscription(restore, upgrade);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [
        InputStorePaymentPremiumSubscription.cons,
      ];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.restore) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.upgrade) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly restore: boolean | undefined,
      readonly upgrade: boolean | undefined
    ) {}
  }

  export class InputStorePaymentGiftPremium implements TLObject {
    static readonly cons = new TLInt(0x616f7fe8);

    static deserialized(
      _data: ByteStream
    ): InputStorePaymentGiftPremium | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(InputStorePaymentGiftPremium.cons)
      )
        return undefined;

      const userId = TLObject.deserialized(_data) as InputUserType;
      if (!userId) return undefined;
      const currency = TLString.deserialized(_data);
      if (!currency) return undefined;
      const amount = TLLong.deserialized(_data);
      if (!amount) return undefined;

      return new InputStorePaymentGiftPremium(userId, currency, amount);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputStorePaymentGiftPremium.cons];

      _data.push(this.userId);
      _data.push(this.currency);
      _data.push(this.amount);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly userId: InputUserType,
      readonly currency: TLString,
      readonly amount: TLLong
    ) {}
  }

  export class PremiumGiftOption implements TLObject {
    static readonly cons = new TLInt(0x74c34319);

    static deserialized(_data: ByteStream): PremiumGiftOption | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PremiumGiftOption.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const months = TLInt.deserialized(_data);
      if (!months) return undefined;
      const currency = TLString.deserialized(_data);
      if (!currency) return undefined;
      const amount = TLLong.deserialized(_data);
      if (!amount) return undefined;
      const botUrl = TLString.deserialized(_data);
      if (!botUrl) return undefined;
      let storeProduct: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        storeProduct = obj;
      }

      return new PremiumGiftOption(
        months,
        currency,
        amount,
        botUrl,
        storeProduct
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PremiumGiftOption.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.months);
      _data.push(this.currency);
      _data.push(this.amount);
      _data.push(this.botUrl);
      if (this.storeProduct) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.storeProduct) _data.push(this.storeProduct);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly months: TLInt,
      readonly currency: TLString,
      readonly amount: TLLong,
      readonly botUrl: TLString,
      readonly storeProduct: TLString | undefined
    ) {}
  }

  export class PaymentFormMethod implements TLObject {
    static readonly cons = new TLInt(0x88f8f21b);

    static deserialized(_data: ByteStream): PaymentFormMethod | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PaymentFormMethod.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;

      return new PaymentFormMethod(url, title);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PaymentFormMethod.cons];

      _data.push(this.url);
      _data.push(this.title);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString, readonly title: TLString) {}
  }

  export class EmojiStatusEmpty implements TLObject {
    static readonly cons = new TLInt(0x2de11aae);

    static deserialized(_data: ByteStream): EmojiStatusEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmojiStatusEmpty.cons))
        return undefined;

      return new EmojiStatusEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmojiStatusEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class EmojiStatus implements TLObject {
    static readonly cons = new TLInt(0x929b619d);

    static deserialized(_data: ByteStream): EmojiStatus | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmojiStatus.cons))
        return undefined;

      const documentId = TLLong.deserialized(_data);
      if (!documentId) return undefined;

      return new EmojiStatus(documentId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmojiStatus.cons];

      _data.push(this.documentId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly documentId: TLLong) {}
  }

  export class EmojiStatusUntil implements TLObject {
    static readonly cons = new TLInt(0xfa30a8c7);

    static deserialized(_data: ByteStream): EmojiStatusUntil | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmojiStatusUntil.cons))
        return undefined;

      const documentId = TLLong.deserialized(_data);
      if (!documentId) return undefined;
      const until = TLInt.deserialized(_data);
      if (!until) return undefined;

      return new EmojiStatusUntil(documentId, until);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmojiStatusUntil.cons];

      _data.push(this.documentId);
      _data.push(this.until);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly documentId: TLLong, readonly until: TLInt) {}
  }

  export namespace account {
    export class EmojiStatusesNotModified implements TLObject {
      static readonly cons = new TLInt(0xd08ce645);

      static deserialized(
        _data: ByteStream
      ): EmojiStatusesNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EmojiStatusesNotModified.cons))
          return undefined;

        return new EmojiStatusesNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EmojiStatusesNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class EmojiStatuses implements TLObject {
      static readonly cons = new TLInt(0x90c467d1);

      static deserialized(_data: ByteStream): EmojiStatuses | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EmojiStatuses.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const statuses = TLVector.deserialized(
          _data
        ) as TLVector<EmojiStatusType>;
        if (!statuses) return undefined;

        return new EmojiStatuses(hash, statuses);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EmojiStatuses.cons];

        _data.push(this.hash);
        _data.push(this.statuses);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLLong,
        readonly statuses: TLVector<EmojiStatusType>
      ) {}
    }
  }

  export class ReactionEmpty implements TLObject {
    static readonly cons = new TLInt(0x79f5d419);

    static deserialized(_data: ByteStream): ReactionEmpty | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ReactionEmpty.cons))
        return undefined;

      return new ReactionEmpty();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ReactionEmpty.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ReactionEmoji implements TLObject {
    static readonly cons = new TLInt(0x1b2286b8);

    static deserialized(_data: ByteStream): ReactionEmoji | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ReactionEmoji.cons))
        return undefined;

      const emoticon = TLString.deserialized(_data);
      if (!emoticon) return undefined;

      return new ReactionEmoji(emoticon);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ReactionEmoji.cons];

      _data.push(this.emoticon);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly emoticon: TLString) {}
  }

  export class ReactionCustomEmoji implements TLObject {
    static readonly cons = new TLInt(0x8935fc73);

    static deserialized(_data: ByteStream): ReactionCustomEmoji | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ReactionCustomEmoji.cons))
        return undefined;

      const documentId = TLLong.deserialized(_data);
      if (!documentId) return undefined;

      return new ReactionCustomEmoji(documentId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ReactionCustomEmoji.cons];

      _data.push(this.documentId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly documentId: TLLong) {}
  }

  export class ChatReactionsNone implements TLObject {
    static readonly cons = new TLInt(0xeafc32bc);

    static deserialized(_data: ByteStream): ChatReactionsNone | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatReactionsNone.cons))
        return undefined;

      return new ChatReactionsNone();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatReactionsNone.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class ChatReactionsAll implements TLObject {
    static readonly cons = new TLInt(0x52928bca);

    static deserialized(_data: ByteStream): ChatReactionsAll | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatReactionsAll.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const allowCustom = (flags.value & 1) !== 0;

      return new ChatReactionsAll(allowCustom);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatReactionsAll.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.allowCustom) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly allowCustom: boolean | undefined) {}
  }

  export class ChatReactionsSome implements TLObject {
    static readonly cons = new TLInt(0x661d4037);

    static deserialized(_data: ByteStream): ChatReactionsSome | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ChatReactionsSome.cons))
        return undefined;

      const reactions = TLVector.deserialized(_data) as TLVector<ReactionType>;
      if (!reactions) return undefined;

      return new ChatReactionsSome(reactions);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ChatReactionsSome.cons];

      _data.push(this.reactions);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly reactions: TLVector<ReactionType>) {}
  }

  export namespace messages {
    export class ReactionsNotModified implements TLObject {
      static readonly cons = new TLInt(0xb06fdbdf);

      static deserialized(_data: ByteStream): ReactionsNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReactionsNotModified.cons))
          return undefined;

        return new ReactionsNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReactionsNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class Reactions implements TLObject {
      static readonly cons = new TLInt(0xeafdf716);

      static deserialized(_data: ByteStream): Reactions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Reactions.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        const reactions = TLVector.deserialized(
          _data
        ) as TLVector<ReactionType>;
        if (!reactions) return undefined;

        return new Reactions(hash, reactions);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Reactions.cons];

        _data.push(this.hash);
        _data.push(this.reactions);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLLong,
        readonly reactions: TLVector<ReactionType>
      ) {}
    }
  }

  export class EmailVerifyPurposeLoginSetup implements TLObject {
    static readonly cons = new TLInt(0x4345be73);

    static deserialized(
      _data: ByteStream
    ): EmailVerifyPurposeLoginSetup | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(EmailVerifyPurposeLoginSetup.cons)
      )
        return undefined;

      const phoneNumber = TLString.deserialized(_data);
      if (!phoneNumber) return undefined;
      const phoneCodeHash = TLString.deserialized(_data);
      if (!phoneCodeHash) return undefined;

      return new EmailVerifyPurposeLoginSetup(phoneNumber, phoneCodeHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmailVerifyPurposeLoginSetup.cons];

      _data.push(this.phoneNumber);
      _data.push(this.phoneCodeHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly phoneNumber: TLString,
      readonly phoneCodeHash: TLString
    ) {}
  }

  export class EmailVerifyPurposeLoginChange implements TLObject {
    static readonly cons = new TLInt(0x527d22eb);

    static deserialized(
      _data: ByteStream
    ): EmailVerifyPurposeLoginChange | undefined {
      const constructor = TLInt.deserialized(_data);
      if (
        !constructor ||
        !constructor.equals(EmailVerifyPurposeLoginChange.cons)
      )
        return undefined;

      return new EmailVerifyPurposeLoginChange();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmailVerifyPurposeLoginChange.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class EmailVerifyPurposePassport implements TLObject {
    static readonly cons = new TLInt(0xbbf51685);

    static deserialized(
      _data: ByteStream
    ): EmailVerifyPurposePassport | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmailVerifyPurposePassport.cons))
        return undefined;

      return new EmailVerifyPurposePassport();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmailVerifyPurposePassport.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class EmailVerificationCode implements TLObject {
    static readonly cons = new TLInt(0x922e55a9);

    static deserialized(_data: ByteStream): EmailVerificationCode | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmailVerificationCode.cons))
        return undefined;

      const code = TLString.deserialized(_data);
      if (!code) return undefined;

      return new EmailVerificationCode(code);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmailVerificationCode.cons];

      _data.push(this.code);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly code: TLString) {}
  }

  export class EmailVerificationGoogle implements TLObject {
    static readonly cons = new TLInt(0xdb909ec2);

    static deserialized(
      _data: ByteStream
    ): EmailVerificationGoogle | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmailVerificationGoogle.cons))
        return undefined;

      const token = TLString.deserialized(_data);
      if (!token) return undefined;

      return new EmailVerificationGoogle(token);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmailVerificationGoogle.cons];

      _data.push(this.token);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly token: TLString) {}
  }

  export class EmailVerificationApple implements TLObject {
    static readonly cons = new TLInt(0x96d074fd);

    static deserialized(_data: ByteStream): EmailVerificationApple | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmailVerificationApple.cons))
        return undefined;

      const token = TLString.deserialized(_data);
      if (!token) return undefined;

      return new EmailVerificationApple(token);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmailVerificationApple.cons];

      _data.push(this.token);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly token: TLString) {}
  }

  export namespace account {
    export class EmailVerified implements TLObject {
      static readonly cons = new TLInt(0x2b96cd1b);

      static deserialized(_data: ByteStream): EmailVerified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EmailVerified.cons))
          return undefined;

        const email = TLString.deserialized(_data);
        if (!email) return undefined;

        return new EmailVerified(email);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EmailVerified.cons];

        _data.push(this.email);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly email: TLString) {}
    }
  }

  export namespace account {
    export class EmailVerifiedLogin implements TLObject {
      static readonly cons = new TLInt(0xe1bb0d61);

      static deserialized(_data: ByteStream): EmailVerifiedLogin | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EmailVerifiedLogin.cons))
          return undefined;

        const email = TLString.deserialized(_data);
        if (!email) return undefined;
        const sentCode = TLObject.deserialized(_data) as auth.SentCodeType;
        if (!sentCode) return undefined;

        return new EmailVerifiedLogin(email, sentCode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EmailVerifiedLogin.cons];

        _data.push(this.email);
        _data.push(this.sentCode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly email: TLString,
        readonly sentCode: auth.SentCodeType
      ) {}
    }
  }

  export class PremiumSubscriptionOption implements TLObject {
    static readonly cons = new TLInt(0x5f2d1df2);

    static deserialized(
      _data: ByteStream
    ): PremiumSubscriptionOption | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PremiumSubscriptionOption.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const current = (flags.value & 2) !== 0;
      const canPurchaseUpgrade = (flags.value & 4) !== 0;
      let transaction: TLString | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        transaction = obj;
      }
      const months = TLInt.deserialized(_data);
      if (!months) return undefined;
      const currency = TLString.deserialized(_data);
      if (!currency) return undefined;
      const amount = TLLong.deserialized(_data);
      if (!amount) return undefined;
      const botUrl = TLString.deserialized(_data);
      if (!botUrl) return undefined;
      let storeProduct: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        storeProduct = obj;
      }

      return new PremiumSubscriptionOption(
        current,
        canPurchaseUpgrade,
        transaction,
        months,
        currency,
        amount,
        botUrl,
        storeProduct
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PremiumSubscriptionOption.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.current) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.canPurchaseUpgrade) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.transaction) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.transaction) _data.push(this.transaction);

      _data.push(this.months);
      _data.push(this.currency);
      _data.push(this.amount);
      _data.push(this.botUrl);
      if (this.storeProduct) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.storeProduct) _data.push(this.storeProduct);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly current: boolean | undefined,
      readonly canPurchaseUpgrade: boolean | undefined,
      readonly transaction: TLString | undefined,
      readonly months: TLInt,
      readonly currency: TLString,
      readonly amount: TLLong,
      readonly botUrl: TLString,
      readonly storeProduct: TLString | undefined
    ) {}
  }

  export class SendAsPeer implements TLObject {
    static readonly cons = new TLInt(0xb81c7034);

    static deserialized(_data: ByteStream): SendAsPeer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SendAsPeer.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const premiumRequired = (flags.value & 1) !== 0;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;

      return new SendAsPeer(premiumRequired, peer);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SendAsPeer.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.premiumRequired) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      _data.push(this.peer);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly premiumRequired: boolean | undefined,
      readonly peer: PeerType
    ) {}
  }

  export class MessageExtendedMediaPreview implements TLObject {
    static readonly cons = new TLInt(0xad628cc8);

    static deserialized(
      _data: ByteStream
    ): MessageExtendedMediaPreview | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageExtendedMediaPreview.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let w: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        w = obj;
      }
      let h: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        h = obj;
      }
      let thumb: PhotoSizeType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as PhotoSizeType;
        if (!obj) return undefined;
        thumb = obj;
      }
      let videoDuration: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        videoDuration = obj;
      }

      return new MessageExtendedMediaPreview(w, h, thumb, videoDuration);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageExtendedMediaPreview.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.w) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.w) _data.push(this.w);

      if (this.h) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.h) _data.push(this.h);

      if (this.thumb) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.thumb) _data.push(this.thumb);

      if (this.videoDuration) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.videoDuration) _data.push(this.videoDuration);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly w: TLInt | undefined,
      readonly h: TLInt | undefined,
      readonly thumb: PhotoSizeType | undefined,
      readonly videoDuration: TLInt | undefined
    ) {}
  }

  export class MessageExtendedMedia implements TLObject {
    static readonly cons = new TLInt(0xee479c64);

    static deserialized(_data: ByteStream): MessageExtendedMedia | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessageExtendedMedia.cons))
        return undefined;

      const media = TLObject.deserialized(_data) as MessageMediaType;
      if (!media) return undefined;

      return new MessageExtendedMedia(media);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessageExtendedMedia.cons];

      _data.push(this.media);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly media: MessageMediaType) {}
  }

  export class StickerKeyword implements TLObject {
    static readonly cons = new TLInt(0xfcfeb29c);

    static deserialized(_data: ByteStream): StickerKeyword | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StickerKeyword.cons))
        return undefined;

      const documentId = TLLong.deserialized(_data);
      if (!documentId) return undefined;
      const keyword = TLVector.deserialized(_data, TLString);
      if (!keyword) return undefined;

      return new StickerKeyword(documentId, keyword);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StickerKeyword.cons];

      _data.push(this.documentId);
      _data.push(this.keyword);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly documentId: TLLong,
      readonly keyword: TLVector<TLString>
    ) {}
  }

  export class Username implements TLObject {
    static readonly cons = new TLInt(0xb4073647);

    static deserialized(_data: ByteStream): Username | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Username.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const editable = (flags.value & 1) !== 0;
      const active = (flags.value & 2) !== 0;
      const username = TLString.deserialized(_data);
      if (!username) return undefined;

      return new Username(editable, active, username);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Username.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.editable) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.active) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.username);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly editable: boolean | undefined,
      readonly active: boolean | undefined,
      readonly username: TLString
    ) {}
  }

  export class ForumTopicDeleted implements TLObject {
    static readonly cons = new TLInt(0x23f109b);

    static deserialized(_data: ByteStream): ForumTopicDeleted | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ForumTopicDeleted.cons))
        return undefined;

      const id = TLInt.deserialized(_data);
      if (!id) return undefined;

      return new ForumTopicDeleted(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ForumTopicDeleted.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLInt) {}
  }

  export class ForumTopic implements TLObject {
    static readonly cons = new TLInt(0x71701da9);

    static deserialized(_data: ByteStream): ForumTopic | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ForumTopic.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const my = (flags.value & 2) !== 0;
      const closed = (flags.value & 4) !== 0;
      const pinned = (flags.value & 8) !== 0;
      const short = (flags.value & 32) !== 0;
      const hidden = (flags.value & 64) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const iconColor = TLInt.deserialized(_data);
      if (!iconColor) return undefined;
      let iconEmojiId: TLLong | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        iconEmojiId = obj;
      }
      const topMessage = TLInt.deserialized(_data);
      if (!topMessage) return undefined;
      const readInboxMaxId = TLInt.deserialized(_data);
      if (!readInboxMaxId) return undefined;
      const readOutboxMaxId = TLInt.deserialized(_data);
      if (!readOutboxMaxId) return undefined;
      const unreadCount = TLInt.deserialized(_data);
      if (!unreadCount) return undefined;
      const unreadMentionsCount = TLInt.deserialized(_data);
      if (!unreadMentionsCount) return undefined;
      const unreadReactionsCount = TLInt.deserialized(_data);
      if (!unreadReactionsCount) return undefined;
      const fromId = TLObject.deserialized(_data) as PeerType;
      if (!fromId) return undefined;
      const notifySettings = API.PeerNotifySettings.deserialized(_data);
      if (!notifySettings) return undefined;
      let draft: DraftMessageType | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLObject.deserialized(_data) as DraftMessageType;
        if (!obj) return undefined;
        draft = obj;
      }

      return new ForumTopic(
        my,
        closed,
        pinned,
        short,
        hidden,
        id,
        date,
        title,
        iconColor,
        iconEmojiId,
        topMessage,
        readInboxMaxId,
        readOutboxMaxId,
        unreadCount,
        unreadMentionsCount,
        unreadReactionsCount,
        fromId,
        notifySettings,
        draft
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ForumTopic.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.my) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.closed) {
        flags.set(4);
      } else {
        flags.unset(4);
      }

      if (this.pinned) {
        flags.set(8);
      } else {
        flags.unset(8);
      }

      if (this.short) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.hidden) {
        flags.set(64);
      } else {
        flags.unset(64);
      }

      _data.push(this.id);
      _data.push(this.date);
      _data.push(this.title);
      _data.push(this.iconColor);
      if (this.iconEmojiId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.iconEmojiId) _data.push(this.iconEmojiId);

      _data.push(this.topMessage);
      _data.push(this.readInboxMaxId);
      _data.push(this.readOutboxMaxId);
      _data.push(this.unreadCount);
      _data.push(this.unreadMentionsCount);
      _data.push(this.unreadReactionsCount);
      _data.push(this.fromId);
      _data.push(this.notifySettings);
      if (this.draft) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.draft) _data.push(this.draft);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly my: boolean | undefined,
      readonly closed: boolean | undefined,
      readonly pinned: boolean | undefined,
      readonly short: boolean | undefined,
      readonly hidden: boolean | undefined,
      readonly id: TLInt,
      readonly date: TLInt,
      readonly title: TLString,
      readonly iconColor: TLInt,
      readonly iconEmojiId: TLLong | undefined,
      readonly topMessage: TLInt,
      readonly readInboxMaxId: TLInt,
      readonly readOutboxMaxId: TLInt,
      readonly unreadCount: TLInt,
      readonly unreadMentionsCount: TLInt,
      readonly unreadReactionsCount: TLInt,
      readonly fromId: PeerType,
      readonly notifySettings: API.PeerNotifySettings,
      readonly draft: DraftMessageType | undefined
    ) {}
  }

  export namespace messages {
    export class ForumTopics implements TLObject {
      static readonly cons = new TLInt(0x367617d3);

      static deserialized(_data: ByteStream): ForumTopics | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ForumTopics.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const orderByCreateDate = (flags.value & 1) !== 0;
        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const topics = TLVector.deserialized(_data) as TLVector<ForumTopicType>;
        if (!topics) return undefined;
        const messages = TLVector.deserialized(_data) as TLVector<MessageType>;
        if (!messages) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;
        const pts = TLInt.deserialized(_data);
        if (!pts) return undefined;

        return new ForumTopics(
          orderByCreateDate,
          count,
          topics,
          messages,
          chats,
          users,
          pts
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ForumTopics.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.orderByCreateDate) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.count);
        _data.push(this.topics);
        _data.push(this.messages);
        _data.push(this.chats);
        _data.push(this.users);
        _data.push(this.pts);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly orderByCreateDate: boolean | undefined,
        readonly count: TLInt,
        readonly topics: TLVector<ForumTopicType>,
        readonly messages: TLVector<MessageType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>,
        readonly pts: TLInt
      ) {}
    }
  }

  export class DefaultHistoryTTL implements TLObject {
    static readonly cons = new TLInt(0x43b46b20);

    static deserialized(_data: ByteStream): DefaultHistoryTTL | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(DefaultHistoryTTL.cons))
        return undefined;

      const period = TLInt.deserialized(_data);
      if (!period) return undefined;

      return new DefaultHistoryTTL(period);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [DefaultHistoryTTL.cons];

      _data.push(this.period);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly period: TLInt) {}
  }

  export class ExportedContactToken implements TLObject {
    static readonly cons = new TLInt(0x41bf109b);

    static deserialized(_data: ByteStream): ExportedContactToken | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ExportedContactToken.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const expires = TLInt.deserialized(_data);
      if (!expires) return undefined;

      return new ExportedContactToken(url, expires);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ExportedContactToken.cons];

      _data.push(this.url);
      _data.push(this.expires);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString, readonly expires: TLInt) {}
  }

  export class RequestPeerTypeUser implements TLObject {
    static readonly cons = new TLInt(0x5f3b8a00);

    static deserialized(_data: ByteStream): RequestPeerTypeUser | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(RequestPeerTypeUser.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let bot: BoolType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        bot = obj;
      }
      let premium: BoolType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        premium = obj;
      }

      return new RequestPeerTypeUser(bot, premium);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [RequestPeerTypeUser.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.bot) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.bot) _data.push(this.bot);

      if (this.premium) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.premium) _data.push(this.premium);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly bot: BoolType | undefined,
      readonly premium: BoolType | undefined
    ) {}
  }

  export class RequestPeerTypeChat implements TLObject {
    static readonly cons = new TLInt(0xc9f06e1b);

    static deserialized(_data: ByteStream): RequestPeerTypeChat | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(RequestPeerTypeChat.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const creator = (flags.value & 1) !== 0;
      const botParticipant = (flags.value & 32) !== 0;
      let hasUsername: BoolType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        hasUsername = obj;
      }
      let forum: BoolType | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        forum = obj;
      }
      let userAdminRights: API.ChatAdminRights | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ChatAdminRights;
        if (!obj) return undefined;
        userAdminRights = obj;
      }
      let botAdminRights: API.ChatAdminRights | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ChatAdminRights;
        if (!obj) return undefined;
        botAdminRights = obj;
      }

      return new RequestPeerTypeChat(
        creator,
        botParticipant,
        hasUsername,
        forum,
        userAdminRights,
        botAdminRights
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [RequestPeerTypeChat.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.creator) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.botParticipant) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.hasUsername) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.hasUsername) _data.push(this.hasUsername);

      if (this.forum) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.forum) _data.push(this.forum);

      if (this.userAdminRights) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.userAdminRights) _data.push(this.userAdminRights);

      if (this.botAdminRights) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.botAdminRights) _data.push(this.botAdminRights);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly creator: boolean | undefined,
      readonly botParticipant: boolean | undefined,
      readonly hasUsername: BoolType | undefined,
      readonly forum: BoolType | undefined,
      readonly userAdminRights: API.ChatAdminRights | undefined,
      readonly botAdminRights: API.ChatAdminRights | undefined
    ) {}
  }

  export class RequestPeerTypeBroadcast implements TLObject {
    static readonly cons = new TLInt(0x339bef6c);

    static deserialized(
      _data: ByteStream
    ): RequestPeerTypeBroadcast | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(RequestPeerTypeBroadcast.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const creator = (flags.value & 1) !== 0;
      let hasUsername: BoolType | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as BoolType;
        if (!obj) return undefined;
        hasUsername = obj;
      }
      let userAdminRights: API.ChatAdminRights | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ChatAdminRights;
        if (!obj) return undefined;
        userAdminRights = obj;
      }
      let botAdminRights: API.ChatAdminRights | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as API.ChatAdminRights;
        if (!obj) return undefined;
        botAdminRights = obj;
      }

      return new RequestPeerTypeBroadcast(
        creator,
        hasUsername,
        userAdminRights,
        botAdminRights
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [RequestPeerTypeBroadcast.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.creator) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.hasUsername) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.hasUsername) _data.push(this.hasUsername);

      if (this.userAdminRights) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.userAdminRights) _data.push(this.userAdminRights);

      if (this.botAdminRights) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.botAdminRights) _data.push(this.botAdminRights);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly creator: boolean | undefined,
      readonly hasUsername: BoolType | undefined,
      readonly userAdminRights: API.ChatAdminRights | undefined,
      readonly botAdminRights: API.ChatAdminRights | undefined
    ) {}
  }

  export class EmojiListNotModified implements TLObject {
    static readonly cons = new TLInt(0x481eadfa);

    static deserialized(_data: ByteStream): EmojiListNotModified | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmojiListNotModified.cons))
        return undefined;

      return new EmojiListNotModified();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmojiListNotModified.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class EmojiList implements TLObject {
    static readonly cons = new TLInt(0x7a1e11d1);

    static deserialized(_data: ByteStream): EmojiList | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmojiList.cons)) return undefined;

      const hash = TLLong.deserialized(_data);
      if (!hash) return undefined;
      const documentId = TLVector.deserialized(_data, TLLong);
      if (!documentId) return undefined;

      return new EmojiList(hash, documentId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmojiList.cons];

      _data.push(this.hash);
      _data.push(this.documentId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly hash: TLLong, readonly documentId: TLVector<TLLong>) {}
  }

  export class EmojiGroup implements TLObject {
    static readonly cons = new TLInt(0x7a9abda9);

    static deserialized(_data: ByteStream): EmojiGroup | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(EmojiGroup.cons))
        return undefined;

      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const iconEmojiId = TLLong.deserialized(_data);
      if (!iconEmojiId) return undefined;
      const emoticons = TLVector.deserialized(_data, TLString);
      if (!emoticons) return undefined;

      return new EmojiGroup(title, iconEmojiId, emoticons);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [EmojiGroup.cons];

      _data.push(this.title);
      _data.push(this.iconEmojiId);
      _data.push(this.emoticons);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly title: TLString,
      readonly iconEmojiId: TLLong,
      readonly emoticons: TLVector<TLString>
    ) {}
  }

  export namespace messages {
    export class EmojiGroupsNotModified implements TLObject {
      static readonly cons = new TLInt(0x6fb4ad87);

      static deserialized(
        _data: ByteStream
      ): EmojiGroupsNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EmojiGroupsNotModified.cons))
          return undefined;

        return new EmojiGroupsNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EmojiGroupsNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class EmojiGroups implements TLObject {
      static readonly cons = new TLInt(0x881fb94b);

      static deserialized(_data: ByteStream): EmojiGroups | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EmojiGroups.cons))
          return undefined;

        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;
        const groups = TLVector.deserialized(_data, API.EmojiGroup);
        if (!groups) return undefined;

        return new EmojiGroups(hash, groups);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EmojiGroups.cons];

        _data.push(this.hash);
        _data.push(this.groups);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLInt,
        readonly groups: TLVector<API.EmojiGroup>
      ) {}
    }
  }

  export class TextWithEntities implements TLObject {
    static readonly cons = new TLInt(0x751f3146);

    static deserialized(_data: ByteStream): TextWithEntities | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(TextWithEntities.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const entities = TLVector.deserialized(
        _data
      ) as TLVector<MessageEntityType>;
      if (!entities) return undefined;

      return new TextWithEntities(text, entities);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [TextWithEntities.cons];

      _data.push(this.text);
      _data.push(this.entities);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly text: TLString,
      readonly entities: TLVector<MessageEntityType>
    ) {}
  }

  export namespace messages {
    export class TranslateResult implements TLObject {
      static readonly cons = new TLInt(0x33db32f8);

      static deserialized(_data: ByteStream): TranslateResult | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TranslateResult.cons))
          return undefined;

        const result = TLVector.deserialized(_data, API.TextWithEntities);
        if (!result) return undefined;

        return new TranslateResult(result);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TranslateResult.cons];

        _data.push(this.result);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly result: TLVector<API.TextWithEntities>) {}
    }
  }

  export class AutoSaveSettings implements TLObject {
    static readonly cons = new TLInt(0xc84834ce);

    static deserialized(_data: ByteStream): AutoSaveSettings | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AutoSaveSettings.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const photos = (flags.value & 1) !== 0;
      const videos = (flags.value & 2) !== 0;
      let videoMaxSize: TLLong | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLLong.deserialized(_data);
        if (!obj) return undefined;
        videoMaxSize = obj;
      }

      return new AutoSaveSettings(photos, videos, videoMaxSize);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AutoSaveSettings.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.photos) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.videos) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      if (this.videoMaxSize) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.videoMaxSize) _data.push(this.videoMaxSize);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly photos: boolean | undefined,
      readonly videos: boolean | undefined,
      readonly videoMaxSize: TLLong | undefined
    ) {}
  }

  export class AutoSaveException implements TLObject {
    static readonly cons = new TLInt(0x81602d47);

    static deserialized(_data: ByteStream): AutoSaveException | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AutoSaveException.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const settings = API.AutoSaveSettings.deserialized(_data);
      if (!settings) return undefined;

      return new AutoSaveException(peer, settings);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AutoSaveException.cons];

      _data.push(this.peer);
      _data.push(this.settings);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly settings: API.AutoSaveSettings
    ) {}
  }

  export namespace account {
    export class AutoSaveSettings implements TLObject {
      static readonly cons = new TLInt(0x4c3e069d);

      static deserialized(_data: ByteStream): AutoSaveSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AutoSaveSettings.cons))
          return undefined;

        const usersSettings = API.AutoSaveSettings.deserialized(_data);
        if (!usersSettings) return undefined;
        const chatsSettings = API.AutoSaveSettings.deserialized(_data);
        if (!chatsSettings) return undefined;
        const broadcastsSettings = API.AutoSaveSettings.deserialized(_data);
        if (!broadcastsSettings) return undefined;
        const exceptions = TLVector.deserialized(_data, API.AutoSaveException);
        if (!exceptions) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new AutoSaveSettings(
          usersSettings,
          chatsSettings,
          broadcastsSettings,
          exceptions,
          chats,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AutoSaveSettings.cons];

        _data.push(this.usersSettings);
        _data.push(this.chatsSettings);
        _data.push(this.broadcastsSettings);
        _data.push(this.exceptions);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly usersSettings: API.AutoSaveSettings,
        readonly chatsSettings: API.AutoSaveSettings,
        readonly broadcastsSettings: API.AutoSaveSettings,
        readonly exceptions: TLVector<API.AutoSaveException>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace help {
    export class AppConfigNotModified implements TLObject {
      static readonly cons = new TLInt(0x7cde641d);

      static deserialized(_data: ByteStream): AppConfigNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AppConfigNotModified.cons))
          return undefined;

        return new AppConfigNotModified();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AppConfigNotModified.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class AppConfig implements TLObject {
      static readonly cons = new TLInt(0xdd18782e);

      static deserialized(_data: ByteStream): AppConfig | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AppConfig.cons))
          return undefined;

        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;
        const config = TLObject.deserialized(_data) as JSONValueType;
        if (!config) return undefined;

        return new AppConfig(hash, config);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AppConfig.cons];

        _data.push(this.hash);
        _data.push(this.config);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLInt, readonly config: JSONValueType) {}
    }
  }

  export class InputBotAppID implements TLObject {
    static readonly cons = new TLInt(0xa920bd7a);

    static deserialized(_data: ByteStream): InputBotAppID | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputBotAppID.cons))
        return undefined;

      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;

      return new InputBotAppID(id, accessHash);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputBotAppID.cons];

      _data.push(this.id);
      _data.push(this.accessHash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLLong, readonly accessHash: TLLong) {}
  }

  export class InputBotAppShortName implements TLObject {
    static readonly cons = new TLInt(0x908c0407);

    static deserialized(_data: ByteStream): InputBotAppShortName | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputBotAppShortName.cons))
        return undefined;

      const botId = TLObject.deserialized(_data) as InputUserType;
      if (!botId) return undefined;
      const shortName = TLString.deserialized(_data);
      if (!shortName) return undefined;

      return new InputBotAppShortName(botId, shortName);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputBotAppShortName.cons];

      _data.push(this.botId);
      _data.push(this.shortName);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly botId: InputUserType, readonly shortName: TLString) {}
  }

  export class BotAppNotModified implements TLObject {
    static readonly cons = new TLInt(0x5da674b7);

    static deserialized(_data: ByteStream): BotAppNotModified | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotAppNotModified.cons))
        return undefined;

      return new BotAppNotModified();
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotAppNotModified.cons];

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor() {}
  }

  export class BotApp implements TLObject {
    static readonly cons = new TLInt(0x95fcd1d6);

    static deserialized(_data: ByteStream): BotApp | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(BotApp.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const id = TLLong.deserialized(_data);
      if (!id) return undefined;
      const accessHash = TLLong.deserialized(_data);
      if (!accessHash) return undefined;
      const shortName = TLString.deserialized(_data);
      if (!shortName) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const description = TLString.deserialized(_data);
      if (!description) return undefined;
      const photo = TLObject.deserialized(_data) as PhotoType;
      if (!photo) return undefined;
      let document: DocumentType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as DocumentType;
        if (!obj) return undefined;
        document = obj;
      }
      const hash = TLLong.deserialized(_data);
      if (!hash) return undefined;

      return new BotApp(
        id,
        accessHash,
        shortName,
        title,
        description,
        photo,
        document,
        hash
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [BotApp.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.id);
      _data.push(this.accessHash);
      _data.push(this.shortName);
      _data.push(this.title);
      _data.push(this.description);
      _data.push(this.photo);
      if (this.document) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.document) _data.push(this.document);

      _data.push(this.hash);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly id: TLLong,
      readonly accessHash: TLLong,
      readonly shortName: TLString,
      readonly title: TLString,
      readonly description: TLString,
      readonly photo: PhotoType,
      readonly document: DocumentType | undefined,
      readonly hash: TLLong
    ) {}
  }

  export namespace messages {
    export class BotApp implements TLObject {
      static readonly cons = new TLInt(0xeb50adf5);

      static deserialized(_data: ByteStream): BotApp | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(BotApp.cons)) return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const inactive = (flags.value & 1) !== 0;
        const requestWriteAccess = (flags.value & 2) !== 0;
        const hasSettings = (flags.value & 4) !== 0;
        const app = TLObject.deserialized(_data) as BotAppType;
        if (!app) return undefined;

        return new BotApp(inactive, requestWriteAccess, hasSettings, app);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [BotApp.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.inactive) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.requestWriteAccess) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.hasSettings) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.app);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly inactive: boolean | undefined,
        readonly requestWriteAccess: boolean | undefined,
        readonly hasSettings: boolean | undefined,
        readonly app: BotAppType
      ) {}
    }
  }

  export class AppWebViewResultUrl implements TLObject {
    static readonly cons = new TLInt(0x3c1b4f0d);

    static deserialized(_data: ByteStream): AppWebViewResultUrl | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(AppWebViewResultUrl.cons))
        return undefined;

      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new AppWebViewResultUrl(url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [AppWebViewResultUrl.cons];

      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly url: TLString) {}
  }

  export class InlineBotWebView implements TLObject {
    static readonly cons = new TLInt(0xb57295d5);

    static deserialized(_data: ByteStream): InlineBotWebView | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InlineBotWebView.cons))
        return undefined;

      const text = TLString.deserialized(_data);
      if (!text) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;

      return new InlineBotWebView(text, url);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InlineBotWebView.cons];

      _data.push(this.text);
      _data.push(this.url);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly text: TLString, readonly url: TLString) {}
  }

  export class ReadParticipantDate implements TLObject {
    static readonly cons = new TLInt(0x4a4ff172);

    static deserialized(_data: ByteStream): ReadParticipantDate | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ReadParticipantDate.cons))
        return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new ReadParticipantDate(userId, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ReadParticipantDate.cons];

      _data.push(this.userId);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly date: TLInt) {}
  }

  export class InputChatlistDialogFilter implements TLObject {
    static readonly cons = new TLInt(0xf3e0da33);

    static deserialized(
      _data: ByteStream
    ): InputChatlistDialogFilter | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputChatlistDialogFilter.cons))
        return undefined;

      const filterId = TLInt.deserialized(_data);
      if (!filterId) return undefined;

      return new InputChatlistDialogFilter(filterId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputChatlistDialogFilter.cons];

      _data.push(this.filterId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly filterId: TLInt) {}
  }

  export class ExportedChatlistInvite implements TLObject {
    static readonly cons = new TLInt(0xc5181ac);

    static deserialized(_data: ByteStream): ExportedChatlistInvite | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ExportedChatlistInvite.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const peers = TLVector.deserialized(_data) as TLVector<PeerType>;
      if (!peers) return undefined;

      return new ExportedChatlistInvite(title, url, peers);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ExportedChatlistInvite.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.title);
      _data.push(this.url);
      _data.push(this.peers);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly title: TLString,
      readonly url: TLString,
      readonly peers: TLVector<PeerType>
    ) {}
  }

  export namespace chatlists {
    export class ExportedChatlistInvite implements TLObject {
      static readonly cons = new TLInt(0x10e6e3a6);

      static deserialized(
        _data: ByteStream
      ): ExportedChatlistInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportedChatlistInvite.cons))
          return undefined;

        const filter = TLObject.deserialized(_data) as DialogFilterType;
        if (!filter) return undefined;
        const invite = API.ExportedChatlistInvite.deserialized(_data);
        if (!invite) return undefined;

        return new ExportedChatlistInvite(filter, invite);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportedChatlistInvite.cons];

        _data.push(this.filter);
        _data.push(this.invite);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly filter: DialogFilterType,
        readonly invite: API.ExportedChatlistInvite
      ) {}
    }
  }

  export namespace chatlists {
    export class ExportedInvites implements TLObject {
      static readonly cons = new TLInt(0x10ab6dc7);

      static deserialized(_data: ByteStream): ExportedInvites | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportedInvites.cons))
          return undefined;

        const invites = TLVector.deserialized(
          _data,
          API.ExportedChatlistInvite
        );
        if (!invites) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ExportedInvites(invites, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportedInvites.cons];

        _data.push(this.invites);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly invites: TLVector<API.ExportedChatlistInvite>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace chatlists {
    export class ChatlistInviteAlready implements TLObject {
      static readonly cons = new TLInt(0xfa87f659);

      static deserialized(
        _data: ByteStream
      ): ChatlistInviteAlready | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChatlistInviteAlready.cons))
          return undefined;

        const filterId = TLInt.deserialized(_data);
        if (!filterId) return undefined;
        const missingPeers = TLVector.deserialized(_data) as TLVector<PeerType>;
        if (!missingPeers) return undefined;
        const alreadyPeers = TLVector.deserialized(_data) as TLVector<PeerType>;
        if (!alreadyPeers) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ChatlistInviteAlready(
          filterId,
          missingPeers,
          alreadyPeers,
          chats,
          users
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChatlistInviteAlready.cons];

        _data.push(this.filterId);
        _data.push(this.missingPeers);
        _data.push(this.alreadyPeers);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly filterId: TLInt,
        readonly missingPeers: TLVector<PeerType>,
        readonly alreadyPeers: TLVector<PeerType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace chatlists {
    export class ChatlistInvite implements TLObject {
      static readonly cons = new TLInt(0x1dcd839d);

      static deserialized(_data: ByteStream): ChatlistInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChatlistInvite.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;
        let emoticon: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          emoticon = obj;
        }
        const peers = TLVector.deserialized(_data) as TLVector<PeerType>;
        if (!peers) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ChatlistInvite(title, emoticon, peers, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChatlistInvite.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.title);
        if (this.emoticon) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.emoticon) _data.push(this.emoticon);

        _data.push(this.peers);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly title: TLString,
        readonly emoticon: TLString | undefined,
        readonly peers: TLVector<PeerType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace chatlists {
    export class ChatlistUpdates implements TLObject {
      static readonly cons = new TLInt(0x93bd878d);

      static deserialized(_data: ByteStream): ChatlistUpdates | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChatlistUpdates.cons))
          return undefined;

        const missingPeers = TLVector.deserialized(_data) as TLVector<PeerType>;
        if (!missingPeers) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new ChatlistUpdates(missingPeers, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChatlistUpdates.cons];

        _data.push(this.missingPeers);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly missingPeers: TLVector<PeerType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace bots {
    export class BotInfo implements TLObject {
      static readonly cons = new TLInt(0xe8a775b0);

      static deserialized(_data: ByteStream): BotInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(BotInfo.cons)) return undefined;

        const name = TLString.deserialized(_data);
        if (!name) return undefined;
        const about = TLString.deserialized(_data);
        if (!about) return undefined;
        const description = TLString.deserialized(_data);
        if (!description) return undefined;

        return new BotInfo(name, about, description);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [BotInfo.cons];

        _data.push(this.name);
        _data.push(this.about);
        _data.push(this.description);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly name: TLString,
        readonly about: TLString,
        readonly description: TLString
      ) {}
    }
  }

  export class MessagePeerVote implements TLObject {
    static readonly cons = new TLInt(0xb6cc2d5c);

    static deserialized(_data: ByteStream): MessagePeerVote | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessagePeerVote.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const option = TLBytes.deserialized(_data);
      if (!option) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new MessagePeerVote(peer, option, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessagePeerVote.cons];

      _data.push(this.peer);
      _data.push(this.option);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly option: TLBytes,
      readonly date: TLInt
    ) {}
  }

  export class MessagePeerVoteInputOption implements TLObject {
    static readonly cons = new TLInt(0x74cda504);

    static deserialized(
      _data: ByteStream
    ): MessagePeerVoteInputOption | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessagePeerVoteInputOption.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new MessagePeerVoteInputOption(peer, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessagePeerVoteInputOption.cons];

      _data.push(this.peer);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly peer: PeerType, readonly date: TLInt) {}
  }

  export class MessagePeerVoteMultiple implements TLObject {
    static readonly cons = new TLInt(0x4628f6e6);

    static deserialized(
      _data: ByteStream
    ): MessagePeerVoteMultiple | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MessagePeerVoteMultiple.cons))
        return undefined;

      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      const options = TLVector.deserialized(_data, TLBytes);
      if (!options) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;

      return new MessagePeerVoteMultiple(peer, options, date);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MessagePeerVoteMultiple.cons];

      _data.push(this.peer);
      _data.push(this.options);
      _data.push(this.date);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly options: TLVector<TLBytes>,
      readonly date: TLInt
    ) {}
  }

  export class SponsoredWebPage implements TLObject {
    static readonly cons = new TLInt(0x3db8ec63);

    static deserialized(_data: ByteStream): SponsoredWebPage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(SponsoredWebPage.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const url = TLString.deserialized(_data);
      if (!url) return undefined;
      const siteName = TLString.deserialized(_data);
      if (!siteName) return undefined;
      let photo: PhotoType | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as PhotoType;
        if (!obj) return undefined;
        photo = obj;
      }

      return new SponsoredWebPage(url, siteName, photo);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [SponsoredWebPage.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.url);
      _data.push(this.siteName);
      if (this.photo) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.photo) _data.push(this.photo);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly url: TLString,
      readonly siteName: TLString,
      readonly photo: PhotoType | undefined
    ) {}
  }

  export class StoryViews implements TLObject {
    static readonly cons = new TLInt(0x8d595cd6);

    static deserialized(_data: ByteStream): StoryViews | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StoryViews.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const hasViewers = (flags.value & 2) !== 0;
      const viewsCount = TLInt.deserialized(_data);
      if (!viewsCount) return undefined;
      let forwardsCount: TLInt | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        forwardsCount = obj;
      }
      let reactions: TLVector<API.ReactionCount> | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLVector.deserialized(_data, API.ReactionCount);
        if (!obj) return undefined;
        reactions = obj;
      }
      let reactionsCount: TLInt | undefined;
      if ((flags.value & 16) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        reactionsCount = obj;
      }
      let recentViewers: TLVector<TLLong> | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLVector.deserialized(_data, TLLong);
        if (!obj) return undefined;
        recentViewers = obj;
      }

      return new StoryViews(
        hasViewers,
        viewsCount,
        forwardsCount,
        reactions,
        reactionsCount,
        recentViewers
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StoryViews.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.hasViewers) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.viewsCount);
      if (this.forwardsCount) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.forwardsCount) _data.push(this.forwardsCount);

      if (this.reactions) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.reactions) _data.push(this.reactions);

      if (this.reactionsCount) {
        flags.set(16);
      } else {
        flags.unset(16);
      }
      if (this.reactionsCount) _data.push(this.reactionsCount);

      if (this.recentViewers) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.recentViewers) _data.push(this.recentViewers);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly hasViewers: boolean | undefined,
      readonly viewsCount: TLInt,
      readonly forwardsCount: TLInt | undefined,
      readonly reactions: TLVector<API.ReactionCount> | undefined,
      readonly reactionsCount: TLInt | undefined,
      readonly recentViewers: TLVector<TLLong> | undefined
    ) {}
  }

  export class StoryItemDeleted implements TLObject {
    static readonly cons = new TLInt(0x51e6ee4f);

    static deserialized(_data: ByteStream): StoryItemDeleted | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StoryItemDeleted.cons))
        return undefined;

      const id = TLInt.deserialized(_data);
      if (!id) return undefined;

      return new StoryItemDeleted(id);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StoryItemDeleted.cons];

      _data.push(this.id);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly id: TLInt) {}
  }

  export class StoryItemSkipped implements TLObject {
    static readonly cons = new TLInt(0xffadc913);

    static deserialized(_data: ByteStream): StoryItemSkipped | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StoryItemSkipped.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const closeFriends = (flags.value & 256) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const expireDate = TLInt.deserialized(_data);
      if (!expireDate) return undefined;

      return new StoryItemSkipped(closeFriends, id, date, expireDate);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StoryItemSkipped.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.closeFriends) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      _data.push(this.id);
      _data.push(this.date);
      _data.push(this.expireDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly closeFriends: boolean | undefined,
      readonly id: TLInt,
      readonly date: TLInt,
      readonly expireDate: TLInt
    ) {}
  }

  export class StoryItem implements TLObject {
    static readonly cons = new TLInt(0x44c457ce);

    static deserialized(_data: ByteStream): StoryItem | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StoryItem.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const pinned = (flags.value & 32) !== 0;
      const argPublic = (flags.value & 128) !== 0;
      const closeFriends = (flags.value & 256) !== 0;
      const min = (flags.value & 512) !== 0;
      const noforwards = (flags.value & 1024) !== 0;
      const edited = (flags.value & 2048) !== 0;
      const contacts = (flags.value & 4096) !== 0;
      const selectedContacts = (flags.value & 8192) !== 0;
      const out = (flags.value & 65536) !== 0;
      const id = TLInt.deserialized(_data);
      if (!id) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      const expireDate = TLInt.deserialized(_data);
      if (!expireDate) return undefined;
      let caption: TLString | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLString.deserialized(_data);
        if (!obj) return undefined;
        caption = obj;
      }
      let entities: TLVector<MessageEntityType> | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MessageEntityType>;
        if (!obj) return undefined;
        entities = obj;
      }
      const media = TLObject.deserialized(_data) as MessageMediaType;
      if (!media) return undefined;
      let mediaAreas: TLVector<MediaAreaType> | undefined;
      if ((flags.value & 16384) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<MediaAreaType>;
        if (!obj) return undefined;
        mediaAreas = obj;
      }
      let privacy: TLVector<PrivacyRuleType> | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLVector.deserialized(_data) as TLVector<PrivacyRuleType>;
        if (!obj) return undefined;
        privacy = obj;
      }
      let views: API.StoryViews | undefined;
      if ((flags.value & 8) !== 0) {
        const obj = TLObject.deserialized(_data) as API.StoryViews;
        if (!obj) return undefined;
        views = obj;
      }
      let sentReaction: ReactionType | undefined;
      if ((flags.value & 32768) !== 0) {
        const obj = TLObject.deserialized(_data) as ReactionType;
        if (!obj) return undefined;
        sentReaction = obj;
      }

      return new StoryItem(
        pinned,
        argPublic,
        closeFriends,
        min,
        noforwards,
        edited,
        contacts,
        selectedContacts,
        out,
        id,
        date,
        expireDate,
        caption,
        entities,
        media,
        mediaAreas,
        privacy,
        views,
        sentReaction
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StoryItem.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.pinned) {
        flags.set(32);
      } else {
        flags.unset(32);
      }

      if (this.argPublic) {
        flags.set(128);
      } else {
        flags.unset(128);
      }

      if (this.closeFriends) {
        flags.set(256);
      } else {
        flags.unset(256);
      }

      if (this.min) {
        flags.set(512);
      } else {
        flags.unset(512);
      }

      if (this.noforwards) {
        flags.set(1024);
      } else {
        flags.unset(1024);
      }

      if (this.edited) {
        flags.set(2048);
      } else {
        flags.unset(2048);
      }

      if (this.contacts) {
        flags.set(4096);
      } else {
        flags.unset(4096);
      }

      if (this.selectedContacts) {
        flags.set(8192);
      } else {
        flags.unset(8192);
      }

      if (this.out) {
        flags.set(65536);
      } else {
        flags.unset(65536);
      }

      _data.push(this.id);
      _data.push(this.date);
      _data.push(this.expireDate);
      if (this.caption) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.caption) _data.push(this.caption);

      if (this.entities) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.entities) _data.push(this.entities);

      _data.push(this.media);
      if (this.mediaAreas) {
        flags.set(16384);
      } else {
        flags.unset(16384);
      }
      if (this.mediaAreas) _data.push(this.mediaAreas);

      if (this.privacy) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.privacy) _data.push(this.privacy);

      if (this.views) {
        flags.set(8);
      } else {
        flags.unset(8);
      }
      if (this.views) _data.push(this.views);

      if (this.sentReaction) {
        flags.set(32768);
      } else {
        flags.unset(32768);
      }
      if (this.sentReaction) _data.push(this.sentReaction);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly pinned: boolean | undefined,
      readonly argPublic: boolean | undefined,
      readonly closeFriends: boolean | undefined,
      readonly min: boolean | undefined,
      readonly noforwards: boolean | undefined,
      readonly edited: boolean | undefined,
      readonly contacts: boolean | undefined,
      readonly selectedContacts: boolean | undefined,
      readonly out: boolean | undefined,
      readonly id: TLInt,
      readonly date: TLInt,
      readonly expireDate: TLInt,
      readonly caption: TLString | undefined,
      readonly entities: TLVector<MessageEntityType> | undefined,
      readonly media: MessageMediaType,
      readonly mediaAreas: TLVector<MediaAreaType> | undefined,
      readonly privacy: TLVector<PrivacyRuleType> | undefined,
      readonly views: API.StoryViews | undefined,
      readonly sentReaction: ReactionType | undefined
    ) {}
  }

  export namespace stories {
    export class AllStoriesNotModified implements TLObject {
      static readonly cons = new TLInt(0x1158fe3e);

      static deserialized(
        _data: ByteStream
      ): AllStoriesNotModified | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AllStoriesNotModified.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const state = TLString.deserialized(_data);
        if (!state) return undefined;
        const stealthMode = API.StoriesStealthMode.deserialized(_data);
        if (!stealthMode) return undefined;

        return new AllStoriesNotModified(state, stealthMode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AllStoriesNotModified.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.state);
        _data.push(this.stealthMode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly state: TLString,
        readonly stealthMode: API.StoriesStealthMode
      ) {}
    }
  }

  export namespace stories {
    export class AllStories implements TLObject {
      static readonly cons = new TLInt(0x6efc5e81);

      static deserialized(_data: ByteStream): AllStories | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AllStories.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const hasMore = (flags.value & 1) !== 0;
        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const state = TLString.deserialized(_data);
        if (!state) return undefined;
        const peerStories = TLVector.deserialized(_data, API.PeerStories);
        if (!peerStories) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;
        const stealthMode = API.StoriesStealthMode.deserialized(_data);
        if (!stealthMode) return undefined;

        return new AllStories(
          hasMore,
          count,
          state,
          peerStories,
          chats,
          users,
          stealthMode
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AllStories.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.hasMore) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.count);
        _data.push(this.state);
        _data.push(this.peerStories);
        _data.push(this.chats);
        _data.push(this.users);
        _data.push(this.stealthMode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hasMore: boolean | undefined,
        readonly count: TLInt,
        readonly state: TLString,
        readonly peerStories: TLVector<API.PeerStories>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>,
        readonly stealthMode: API.StoriesStealthMode
      ) {}
    }
  }

  export namespace stories {
    export class Stories implements TLObject {
      static readonly cons = new TLInt(0x5dd8c3c8);

      static deserialized(_data: ByteStream): Stories | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Stories.cons)) return undefined;

        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const stories = TLVector.deserialized(_data) as TLVector<StoryItemType>;
        if (!stories) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new Stories(count, stories, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Stories.cons];

        _data.push(this.count);
        _data.push(this.stories);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly stories: TLVector<StoryItemType>,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class StoryView implements TLObject {
    static readonly cons = new TLInt(0xb0bdeac5);

    static deserialized(_data: ByteStream): StoryView | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StoryView.cons)) return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const blocked = (flags.value & 1) !== 0;
      const blockedMyStoriesFrom = (flags.value & 2) !== 0;
      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const date = TLInt.deserialized(_data);
      if (!date) return undefined;
      let reaction: ReactionType | undefined;
      if ((flags.value & 4) !== 0) {
        const obj = TLObject.deserialized(_data) as ReactionType;
        if (!obj) return undefined;
        reaction = obj;
      }

      return new StoryView(
        blocked,
        blockedMyStoriesFrom,
        userId,
        date,
        reaction
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StoryView.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.blocked) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.blockedMyStoriesFrom) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.userId);
      _data.push(this.date);
      if (this.reaction) {
        flags.set(4);
      } else {
        flags.unset(4);
      }
      if (this.reaction) _data.push(this.reaction);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly blocked: boolean | undefined,
      readonly blockedMyStoriesFrom: boolean | undefined,
      readonly userId: TLLong,
      readonly date: TLInt,
      readonly reaction: ReactionType | undefined
    ) {}
  }

  export namespace stories {
    export class StoryViewsList implements TLObject {
      static readonly cons = new TLInt(0x46e9b9ec);

      static deserialized(_data: ByteStream): StoryViewsList | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(StoryViewsList.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const reactionsCount = TLInt.deserialized(_data);
        if (!reactionsCount) return undefined;
        const views = TLVector.deserialized(_data, API.StoryView);
        if (!views) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;
        let nextOffset: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          nextOffset = obj;
        }

        return new StoryViewsList(
          count,
          reactionsCount,
          views,
          users,
          nextOffset
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [StoryViewsList.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.count);
        _data.push(this.reactionsCount);
        _data.push(this.views);
        _data.push(this.users);
        if (this.nextOffset) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.nextOffset) _data.push(this.nextOffset);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly reactionsCount: TLInt,
        readonly views: TLVector<API.StoryView>,
        readonly users: TLVector<UserType>,
        readonly nextOffset: TLString | undefined
      ) {}
    }
  }

  export namespace stories {
    export class StoryViews implements TLObject {
      static readonly cons = new TLInt(0xde9eed1d);

      static deserialized(_data: ByteStream): StoryViews | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(StoryViews.cons))
          return undefined;

        const views = TLVector.deserialized(_data, API.StoryViews);
        if (!views) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new StoryViews(views, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [StoryViews.cons];

        _data.push(this.views);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly views: TLVector<API.StoryViews>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class InputReplyToMessage implements TLObject {
    static readonly cons = new TLInt(0x9c5386e4);

    static deserialized(_data: ByteStream): InputReplyToMessage | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputReplyToMessage.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const replyToMsgId = TLInt.deserialized(_data);
      if (!replyToMsgId) return undefined;
      let topMsgId: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        topMsgId = obj;
      }

      return new InputReplyToMessage(replyToMsgId, topMsgId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputReplyToMessage.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.replyToMsgId);
      if (this.topMsgId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.topMsgId) _data.push(this.topMsgId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly replyToMsgId: TLInt,
      readonly topMsgId: TLInt | undefined
    ) {}
  }

  export class InputReplyToStory implements TLObject {
    static readonly cons = new TLInt(0x15b0f283);

    static deserialized(_data: ByteStream): InputReplyToStory | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputReplyToStory.cons))
        return undefined;

      const userId = TLObject.deserialized(_data) as InputUserType;
      if (!userId) return undefined;
      const storyId = TLInt.deserialized(_data);
      if (!storyId) return undefined;

      return new InputReplyToStory(userId, storyId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputReplyToStory.cons];

      _data.push(this.userId);
      _data.push(this.storyId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: InputUserType, readonly storyId: TLInt) {}
  }

  export class ExportedStoryLink implements TLObject {
    static readonly cons = new TLInt(0x3fc9053b);

    static deserialized(_data: ByteStream): ExportedStoryLink | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(ExportedStoryLink.cons))
        return undefined;

      const link = TLString.deserialized(_data);
      if (!link) return undefined;

      return new ExportedStoryLink(link);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [ExportedStoryLink.cons];

      _data.push(this.link);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly link: TLString) {}
  }

  export class StoriesStealthMode implements TLObject {
    static readonly cons = new TLInt(0x712e27fd);

    static deserialized(_data: ByteStream): StoriesStealthMode | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(StoriesStealthMode.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      let activeUntilDate: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        activeUntilDate = obj;
      }
      let cooldownUntilDate: TLInt | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        cooldownUntilDate = obj;
      }

      return new StoriesStealthMode(activeUntilDate, cooldownUntilDate);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [StoriesStealthMode.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.activeUntilDate) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.activeUntilDate) _data.push(this.activeUntilDate);

      if (this.cooldownUntilDate) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.cooldownUntilDate) _data.push(this.cooldownUntilDate);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly activeUntilDate: TLInt | undefined,
      readonly cooldownUntilDate: TLInt | undefined
    ) {}
  }

  export class MediaAreaCoordinates implements TLObject {
    static readonly cons = new TLInt(0x3d1ea4e);

    static deserialized(_data: ByteStream): MediaAreaCoordinates | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MediaAreaCoordinates.cons))
        return undefined;

      const x = TLDouble.deserialized(_data);
      if (!x) return undefined;
      const y = TLDouble.deserialized(_data);
      if (!y) return undefined;
      const w = TLDouble.deserialized(_data);
      if (!w) return undefined;
      const h = TLDouble.deserialized(_data);
      if (!h) return undefined;
      const rotation = TLDouble.deserialized(_data);
      if (!rotation) return undefined;

      return new MediaAreaCoordinates(x, y, w, h, rotation);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MediaAreaCoordinates.cons];

      _data.push(this.x);
      _data.push(this.y);
      _data.push(this.w);
      _data.push(this.h);
      _data.push(this.rotation);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly x: TLDouble,
      readonly y: TLDouble,
      readonly w: TLDouble,
      readonly h: TLDouble,
      readonly rotation: TLDouble
    ) {}
  }

  export class MediaAreaVenue implements TLObject {
    static readonly cons = new TLInt(0xbe82db9c);

    static deserialized(_data: ByteStream): MediaAreaVenue | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MediaAreaVenue.cons))
        return undefined;

      const coordinates = API.MediaAreaCoordinates.deserialized(_data);
      if (!coordinates) return undefined;
      const geo = TLObject.deserialized(_data) as GeoPointType;
      if (!geo) return undefined;
      const title = TLString.deserialized(_data);
      if (!title) return undefined;
      const address = TLString.deserialized(_data);
      if (!address) return undefined;
      const provider = TLString.deserialized(_data);
      if (!provider) return undefined;
      const venueId = TLString.deserialized(_data);
      if (!venueId) return undefined;
      const venueType = TLString.deserialized(_data);
      if (!venueType) return undefined;

      return new MediaAreaVenue(
        coordinates,
        geo,
        title,
        address,
        provider,
        venueId,
        venueType
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MediaAreaVenue.cons];

      _data.push(this.coordinates);
      _data.push(this.geo);
      _data.push(this.title);
      _data.push(this.address);
      _data.push(this.provider);
      _data.push(this.venueId);
      _data.push(this.venueType);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly coordinates: API.MediaAreaCoordinates,
      readonly geo: GeoPointType,
      readonly title: TLString,
      readonly address: TLString,
      readonly provider: TLString,
      readonly venueId: TLString,
      readonly venueType: TLString
    ) {}
  }

  export class InputMediaAreaVenue implements TLObject {
    static readonly cons = new TLInt(0xb282217f);

    static deserialized(_data: ByteStream): InputMediaAreaVenue | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InputMediaAreaVenue.cons))
        return undefined;

      const coordinates = API.MediaAreaCoordinates.deserialized(_data);
      if (!coordinates) return undefined;
      const queryId = TLLong.deserialized(_data);
      if (!queryId) return undefined;
      const resultId = TLString.deserialized(_data);
      if (!resultId) return undefined;

      return new InputMediaAreaVenue(coordinates, queryId, resultId);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InputMediaAreaVenue.cons];

      _data.push(this.coordinates);
      _data.push(this.queryId);
      _data.push(this.resultId);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly coordinates: API.MediaAreaCoordinates,
      readonly queryId: TLLong,
      readonly resultId: TLString
    ) {}
  }

  export class MediaAreaGeoPoint implements TLObject {
    static readonly cons = new TLInt(0xdf8b3b22);

    static deserialized(_data: ByteStream): MediaAreaGeoPoint | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MediaAreaGeoPoint.cons))
        return undefined;

      const coordinates = API.MediaAreaCoordinates.deserialized(_data);
      if (!coordinates) return undefined;
      const geo = TLObject.deserialized(_data) as GeoPointType;
      if (!geo) return undefined;

      return new MediaAreaGeoPoint(coordinates, geo);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MediaAreaGeoPoint.cons];

      _data.push(this.coordinates);
      _data.push(this.geo);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly coordinates: API.MediaAreaCoordinates,
      readonly geo: GeoPointType
    ) {}
  }

  export class MediaAreaSuggestedReaction implements TLObject {
    static readonly cons = new TLInt(0x14455871);

    static deserialized(
      _data: ByteStream
    ): MediaAreaSuggestedReaction | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(MediaAreaSuggestedReaction.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const dark = (flags.value & 1) !== 0;
      const flipped = (flags.value & 2) !== 0;
      const coordinates = API.MediaAreaCoordinates.deserialized(_data);
      if (!coordinates) return undefined;
      const reaction = TLObject.deserialized(_data) as ReactionType;
      if (!reaction) return undefined;

      return new MediaAreaSuggestedReaction(
        dark,
        flipped,
        coordinates,
        reaction
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [MediaAreaSuggestedReaction.cons];

      const flags = new TLFlags();
      _data.push(flags);
      if (this.dark) {
        flags.set(1);
      } else {
        flags.unset(1);
      }

      if (this.flipped) {
        flags.set(2);
      } else {
        flags.unset(2);
      }

      _data.push(this.coordinates);
      _data.push(this.reaction);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly dark: boolean | undefined,
      readonly flipped: boolean | undefined,
      readonly coordinates: API.MediaAreaCoordinates,
      readonly reaction: ReactionType
    ) {}
  }

  export class PeerStories implements TLObject {
    static readonly cons = new TLInt(0x9a35e999);

    static deserialized(_data: ByteStream): PeerStories | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(PeerStories.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const peer = TLObject.deserialized(_data) as PeerType;
      if (!peer) return undefined;
      let maxReadId: TLInt | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLInt.deserialized(_data);
        if (!obj) return undefined;
        maxReadId = obj;
      }
      const stories = TLVector.deserialized(_data) as TLVector<StoryItemType>;
      if (!stories) return undefined;

      return new PeerStories(peer, maxReadId, stories);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [PeerStories.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.peer);
      if (this.maxReadId) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.maxReadId) _data.push(this.maxReadId);

      _data.push(this.stories);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly peer: PeerType,
      readonly maxReadId: TLInt | undefined,
      readonly stories: TLVector<StoryItemType>
    ) {}
  }

  export namespace stories {
    export class PeerStories implements TLObject {
      static readonly cons = new TLInt(0xcae68768);

      static deserialized(_data: ByteStream): PeerStories | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(PeerStories.cons))
          return undefined;

        const stories = API.PeerStories.deserialized(_data);
        if (!stories) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new PeerStories(stories, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [PeerStories.cons];

        _data.push(this.stories);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly stories: API.PeerStories,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace stories {
    export class BoostsStatus implements TLObject {
      static readonly cons = new TLInt(0xe5c1aa5c);

      static deserialized(_data: ByteStream): BoostsStatus | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(BoostsStatus.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const myBoost = (flags.value & 4) !== 0;
        const level = TLInt.deserialized(_data);
        if (!level) return undefined;
        const currentLevelBoosts = TLInt.deserialized(_data);
        if (!currentLevelBoosts) return undefined;
        const boosts = TLInt.deserialized(_data);
        if (!boosts) return undefined;
        let nextLevelBoosts: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          nextLevelBoosts = obj;
        }
        let premiumAudience: API.StatsPercentValue | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as API.StatsPercentValue;
          if (!obj) return undefined;
          premiumAudience = obj;
        }
        const boostUrl = TLString.deserialized(_data);
        if (!boostUrl) return undefined;

        return new BoostsStatus(
          myBoost,
          level,
          currentLevelBoosts,
          boosts,
          nextLevelBoosts,
          premiumAudience,
          boostUrl
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [BoostsStatus.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.myBoost) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.level);
        _data.push(this.currentLevelBoosts);
        _data.push(this.boosts);
        if (this.nextLevelBoosts) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.nextLevelBoosts) _data.push(this.nextLevelBoosts);

        if (this.premiumAudience) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.premiumAudience) _data.push(this.premiumAudience);

        _data.push(this.boostUrl);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly myBoost: boolean | undefined,
        readonly level: TLInt,
        readonly currentLevelBoosts: TLInt,
        readonly boosts: TLInt,
        readonly nextLevelBoosts: TLInt | undefined,
        readonly premiumAudience: API.StatsPercentValue | undefined,
        readonly boostUrl: TLString
      ) {}
    }
  }

  export namespace stories {
    export class CanApplyBoostOk implements TLObject {
      static readonly cons = new TLInt(0xc3173587);

      static deserialized(_data: ByteStream): CanApplyBoostOk | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CanApplyBoostOk.cons))
          return undefined;

        return new CanApplyBoostOk();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CanApplyBoostOk.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace stories {
    export class CanApplyBoostReplace implements TLObject {
      static readonly cons = new TLInt(0x712c4655);

      static deserialized(_data: ByteStream): CanApplyBoostReplace | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CanApplyBoostReplace.cons))
          return undefined;

        const currentBoost = TLObject.deserialized(_data) as PeerType;
        if (!currentBoost) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;

        return new CanApplyBoostReplace(currentBoost, chats);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CanApplyBoostReplace.cons];

        _data.push(this.currentBoost);
        _data.push(this.chats);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly currentBoost: PeerType,
        readonly chats: TLVector<ChatType>
      ) {}
    }
  }

  export class Booster implements TLObject {
    static readonly cons = new TLInt(0xe9e6380);

    static deserialized(_data: ByteStream): Booster | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(Booster.cons)) return undefined;

      const userId = TLLong.deserialized(_data);
      if (!userId) return undefined;
      const expires = TLInt.deserialized(_data);
      if (!expires) return undefined;

      return new Booster(userId, expires);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [Booster.cons];

      _data.push(this.userId);
      _data.push(this.expires);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly userId: TLLong, readonly expires: TLInt) {}
  }

  export namespace stories {
    export class BoostersList implements TLObject {
      static readonly cons = new TLInt(0xf3dd3d1d);

      static deserialized(_data: ByteStream): BoostersList | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(BoostersList.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const count = TLInt.deserialized(_data);
        if (!count) return undefined;
        const boosters = TLVector.deserialized(_data, API.Booster);
        if (!boosters) return undefined;
        let nextOffset: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          nextOffset = obj;
        }
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new BoostersList(count, boosters, nextOffset, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [BoostersList.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.count);
        _data.push(this.boosters);
        if (this.nextOffset) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.nextOffset) _data.push(this.nextOffset);

        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly count: TLInt,
        readonly boosters: TLVector<API.Booster>,
        readonly nextOffset: TLString | undefined,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export namespace messages {
    export class WebPage implements TLObject {
      static readonly cons = new TLInt(0xfd5e12bd);

      static deserialized(_data: ByteStream): WebPage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(WebPage.cons)) return undefined;

        const webpage = TLObject.deserialized(_data) as WebPageType;
        if (!webpage) return undefined;
        const chats = TLVector.deserialized(_data) as TLVector<ChatType>;
        if (!chats) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<UserType>;
        if (!users) return undefined;

        return new WebPage(webpage, chats, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [WebPage.cons];

        _data.push(this.webpage);
        _data.push(this.chats);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly webpage: WebPageType,
        readonly chats: TLVector<ChatType>,
        readonly users: TLVector<UserType>
      ) {}
    }
  }

  export class InvokeAfterMsg implements TLFunction<TLObject> {
    static readonly cons = new TLInt(0xcb9f372d);

    static deserialized(_data: ByteStream): InvokeAfterMsg | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InvokeAfterMsg.cons))
        return undefined;

      const msgId = TLLong.deserialized(_data);
      if (!msgId) return undefined;
      const query = TLObject.deserialized(_data);
      if (!query) return undefined;

      return new InvokeAfterMsg(msgId, query);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InvokeAfterMsg.cons];

      _data.push(this.msgId);
      _data.push(this.query);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly msgId: TLLong, readonly query: TLObject) {}
  }

  export class InvokeAfterMsgs implements TLFunction<TLObject> {
    static readonly cons = new TLInt(0x3dc4b4f0);

    static deserialized(_data: ByteStream): InvokeAfterMsgs | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InvokeAfterMsgs.cons))
        return undefined;

      const msgIds = TLVector.deserialized(_data, TLLong);
      if (!msgIds) return undefined;
      const query = TLObject.deserialized(_data);
      if (!query) return undefined;

      return new InvokeAfterMsgs(msgIds, query);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InvokeAfterMsgs.cons];

      _data.push(this.msgIds);
      _data.push(this.query);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly msgIds: TLVector<TLLong>, readonly query: TLObject) {}
  }

  export class InitConnection implements TLFunction<TLObject> {
    static readonly cons = new TLInt(0xc1cd5ea9);

    static deserialized(_data: ByteStream): InitConnection | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InitConnection.cons))
        return undefined;

      const flags = TLInt.deserialized(_data);
      if (!flags) return undefined;
      const apiId = TLInt.deserialized(_data);
      if (!apiId) return undefined;
      const deviceModel = TLString.deserialized(_data);
      if (!deviceModel) return undefined;
      const systemVersion = TLString.deserialized(_data);
      if (!systemVersion) return undefined;
      const appVersion = TLString.deserialized(_data);
      if (!appVersion) return undefined;
      const systemLangCode = TLString.deserialized(_data);
      if (!systemLangCode) return undefined;
      const langPack = TLString.deserialized(_data);
      if (!langPack) return undefined;
      const langCode = TLString.deserialized(_data);
      if (!langCode) return undefined;
      let proxy: API.InputClientProxy | undefined;
      if ((flags.value & 1) !== 0) {
        const obj = TLObject.deserialized(_data) as API.InputClientProxy;
        if (!obj) return undefined;
        proxy = obj;
      }
      let params: JSONValueType | undefined;
      if ((flags.value & 2) !== 0) {
        const obj = TLObject.deserialized(_data) as JSONValueType;
        if (!obj) return undefined;
        params = obj;
      }
      const query = TLObject.deserialized(_data);
      if (!query) return undefined;

      return new InitConnection(
        apiId,
        deviceModel,
        systemVersion,
        appVersion,
        systemLangCode,
        langPack,
        langCode,
        proxy,
        params,
        query
      );
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InitConnection.cons];

      const flags = new TLFlags();
      _data.push(flags);
      _data.push(this.apiId);
      _data.push(this.deviceModel);
      _data.push(this.systemVersion);
      _data.push(this.appVersion);
      _data.push(this.systemLangCode);
      _data.push(this.langPack);
      _data.push(this.langCode);
      if (this.proxy) {
        flags.set(1);
      } else {
        flags.unset(1);
      }
      if (this.proxy) _data.push(this.proxy);

      if (this.params) {
        flags.set(2);
      } else {
        flags.unset(2);
      }
      if (this.params) _data.push(this.params);

      _data.push(this.query);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(
      readonly apiId: TLInt,
      readonly deviceModel: TLString,
      readonly systemVersion: TLString,
      readonly appVersion: TLString,
      readonly systemLangCode: TLString,
      readonly langPack: TLString,
      readonly langCode: TLString,
      readonly proxy: API.InputClientProxy | undefined,
      readonly params: JSONValueType | undefined,
      readonly query: TLObject
    ) {}
  }

  export class InvokeWithLayer implements TLFunction<TLObject> {
    static readonly cons = new TLInt(0xda9b0d0d);

    static deserialized(_data: ByteStream): InvokeWithLayer | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InvokeWithLayer.cons))
        return undefined;

      const layer = TLInt.deserialized(_data);
      if (!layer) return undefined;
      const query = TLObject.deserialized(_data);
      if (!query) return undefined;

      return new InvokeWithLayer(layer, query);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InvokeWithLayer.cons];

      _data.push(this.layer);
      _data.push(this.query);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly layer: TLInt, readonly query: TLObject) {}
  }

  export class InvokeWithoutUpdates implements TLFunction<TLObject> {
    static readonly cons = new TLInt(0xbf9459b7);

    static deserialized(_data: ByteStream): InvokeWithoutUpdates | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InvokeWithoutUpdates.cons))
        return undefined;

      const query = TLObject.deserialized(_data);
      if (!query) return undefined;

      return new InvokeWithoutUpdates(query);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InvokeWithoutUpdates.cons];

      _data.push(this.query);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly query: TLObject) {}
  }

  export class InvokeWithMessagesRange implements TLFunction<TLObject> {
    static readonly cons = new TLInt(0x365275f2);

    static deserialized(
      _data: ByteStream
    ): InvokeWithMessagesRange | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InvokeWithMessagesRange.cons))
        return undefined;

      const range = API.MessageRange.deserialized(_data);
      if (!range) return undefined;
      const query = TLObject.deserialized(_data);
      if (!query) return undefined;

      return new InvokeWithMessagesRange(range, query);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InvokeWithMessagesRange.cons];

      _data.push(this.range);
      _data.push(this.query);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly range: API.MessageRange, readonly query: TLObject) {}
  }

  export class InvokeWithTakeout implements TLFunction<TLObject> {
    static readonly cons = new TLInt(0xaca9fd2e);

    static deserialized(_data: ByteStream): InvokeWithTakeout | undefined {
      const constructor = TLInt.deserialized(_data);
      if (!constructor || !constructor.equals(InvokeWithTakeout.cons))
        return undefined;

      const takeoutId = TLLong.deserialized(_data);
      if (!takeoutId) return undefined;
      const query = TLObject.deserialized(_data);
      if (!query) return undefined;

      return new InvokeWithTakeout(takeoutId, query);
    }

    serialized(): Uint8Array {
      const _data: Array<TLSerializable> = [InvokeWithTakeout.cons];

      _data.push(this.takeoutId);
      _data.push(this.query);

      return concat(..._data.map((item) => item.serialized()));
    }

    constructor(readonly takeoutId: TLLong, readonly query: TLObject) {}
  }

  export namespace auth {
    export class SendCode implements TLFunction<auth.SentCodeTypeType> {
      static readonly cons = new TLInt(0xa677244f);

      static deserialized(_data: ByteStream): SendCode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendCode.cons))
          return undefined;

        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const apiId = TLInt.deserialized(_data);
        if (!apiId) return undefined;
        const apiHash = TLString.deserialized(_data);
        if (!apiHash) return undefined;
        const settings = API.CodeSettings.deserialized(_data);
        if (!settings) return undefined;

        return new SendCode(phoneNumber, apiId, apiHash, settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendCode.cons];

        _data.push(this.phoneNumber);
        _data.push(this.apiId);
        _data.push(this.apiHash);
        _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneNumber: TLString,
        readonly apiId: TLInt,
        readonly apiHash: TLString,
        readonly settings: API.CodeSettings
      ) {}
    }
  }

  export namespace auth {
    export class SignUp implements TLFunction<auth.AuthorizationType> {
      static readonly cons = new TLInt(0x80eee427);

      static deserialized(_data: ByteStream): SignUp | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SignUp.cons)) return undefined;

        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const phoneCodeHash = TLString.deserialized(_data);
        if (!phoneCodeHash) return undefined;
        const firstName = TLString.deserialized(_data);
        if (!firstName) return undefined;
        const lastName = TLString.deserialized(_data);
        if (!lastName) return undefined;

        return new SignUp(phoneNumber, phoneCodeHash, firstName, lastName);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SignUp.cons];

        _data.push(this.phoneNumber);
        _data.push(this.phoneCodeHash);
        _data.push(this.firstName);
        _data.push(this.lastName);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneNumber: TLString,
        readonly phoneCodeHash: TLString,
        readonly firstName: TLString,
        readonly lastName: TLString
      ) {}
    }
  }

  export namespace auth {
    export class SignIn implements TLFunction<auth.AuthorizationType> {
      static readonly cons = new TLInt(0x8d52a951);

      static deserialized(_data: ByteStream): SignIn | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SignIn.cons)) return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const phoneCodeHash = TLString.deserialized(_data);
        if (!phoneCodeHash) return undefined;
        let phoneCode: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          phoneCode = obj;
        }
        let emailVerification: EmailVerificationType | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as EmailVerificationType;
          if (!obj) return undefined;
          emailVerification = obj;
        }

        return new SignIn(
          phoneNumber,
          phoneCodeHash,
          phoneCode,
          emailVerification
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SignIn.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.phoneNumber);
        _data.push(this.phoneCodeHash);
        if (this.phoneCode) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.phoneCode) _data.push(this.phoneCode);

        if (this.emailVerification) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.emailVerification) _data.push(this.emailVerification);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneNumber: TLString,
        readonly phoneCodeHash: TLString,
        readonly phoneCode: TLString | undefined,
        readonly emailVerification: EmailVerificationType | undefined
      ) {}
    }
  }

  export namespace auth {
    export class LogOut implements TLFunction<auth.LoggedOut> {
      static readonly cons = new TLInt(0x3e72ba19);

      static deserialized(_data: ByteStream): LogOut | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(LogOut.cons)) return undefined;

        return new LogOut();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [LogOut.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace auth {
    export class ResetAuthorizations implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x9fab0d1a);

      static deserialized(_data: ByteStream): ResetAuthorizations | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetAuthorizations.cons))
          return undefined;

        return new ResetAuthorizations();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetAuthorizations.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace auth {
    export class ExportAuthorization
      implements TLFunction<auth.ExportedAuthorization>
    {
      static readonly cons = new TLInt(0xe5bfffcd);

      static deserialized(_data: ByteStream): ExportAuthorization | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportAuthorization.cons))
          return undefined;

        const dcId = TLInt.deserialized(_data);
        if (!dcId) return undefined;

        return new ExportAuthorization(dcId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportAuthorization.cons];

        _data.push(this.dcId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly dcId: TLInt) {}
    }
  }

  export namespace auth {
    export class ImportAuthorization
      implements TLFunction<auth.AuthorizationType>
    {
      static readonly cons = new TLInt(0xa57a7dad);

      static deserialized(_data: ByteStream): ImportAuthorization | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ImportAuthorization.cons))
          return undefined;

        const id = TLLong.deserialized(_data);
        if (!id) return undefined;
        const bytes = TLBytes.deserialized(_data);
        if (!bytes) return undefined;

        return new ImportAuthorization(id, bytes);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ImportAuthorization.cons];

        _data.push(this.id);
        _data.push(this.bytes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLLong, readonly bytes: TLBytes) {}
    }
  }

  export namespace auth {
    export class BindTempAuthKey implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xcdd42a05);

      static deserialized(_data: ByteStream): BindTempAuthKey | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(BindTempAuthKey.cons))
          return undefined;

        const permAuthKeyId = TLLong.deserialized(_data);
        if (!permAuthKeyId) return undefined;
        const nonce = TLLong.deserialized(_data);
        if (!nonce) return undefined;
        const expiresAt = TLInt.deserialized(_data);
        if (!expiresAt) return undefined;
        const encryptedMessage = TLBytes.deserialized(_data);
        if (!encryptedMessage) return undefined;

        return new BindTempAuthKey(
          permAuthKeyId,
          nonce,
          expiresAt,
          encryptedMessage
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [BindTempAuthKey.cons];

        _data.push(this.permAuthKeyId);
        _data.push(this.nonce);
        _data.push(this.expiresAt);
        _data.push(this.encryptedMessage);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly permAuthKeyId: TLLong,
        readonly nonce: TLLong,
        readonly expiresAt: TLInt,
        readonly encryptedMessage: TLBytes
      ) {}
    }
  }

  export namespace auth {
    export class ImportBotAuthorization
      implements TLFunction<auth.AuthorizationType>
    {
      static readonly cons = new TLInt(0x67a3ff2c);

      static deserialized(
        _data: ByteStream
      ): ImportBotAuthorization | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ImportBotAuthorization.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const apiId = TLInt.deserialized(_data);
        if (!apiId) return undefined;
        const apiHash = TLString.deserialized(_data);
        if (!apiHash) return undefined;
        const botAuthToken = TLString.deserialized(_data);
        if (!botAuthToken) return undefined;

        return new ImportBotAuthorization(flags, apiId, apiHash, botAuthToken);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ImportBotAuthorization.cons];

        _data.push(this.flags);
        _data.push(this.apiId);
        _data.push(this.apiHash);
        _data.push(this.botAuthToken);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly flags: TLInt,
        readonly apiId: TLInt,
        readonly apiHash: TLString,
        readonly botAuthToken: TLString
      ) {}
    }
  }

  export namespace auth {
    export class CheckPassword implements TLFunction<auth.AuthorizationType> {
      static readonly cons = new TLInt(0xd18b4d16);

      static deserialized(_data: ByteStream): CheckPassword | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CheckPassword.cons))
          return undefined;

        const password = TLObject.deserialized(
          _data
        ) as InputCheckPasswordSRPType;
        if (!password) return undefined;

        return new CheckPassword(password);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CheckPassword.cons];

        _data.push(this.password);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly password: InputCheckPasswordSRPType) {}
    }
  }

  export namespace auth {
    export class RequestPasswordRecovery
      implements TLFunction<auth.PasswordRecovery>
    {
      static readonly cons = new TLInt(0xd897bc66);

      static deserialized(
        _data: ByteStream
      ): RequestPasswordRecovery | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RequestPasswordRecovery.cons))
          return undefined;

        return new RequestPasswordRecovery();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RequestPasswordRecovery.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace auth {
    export class RecoverPassword implements TLFunction<auth.AuthorizationType> {
      static readonly cons = new TLInt(0x37096c70);

      static deserialized(_data: ByteStream): RecoverPassword | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RecoverPassword.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const code = TLString.deserialized(_data);
        if (!code) return undefined;
        let newSettings: account.PasswordInputSettings | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(
            _data
          ) as account.PasswordInputSettings;
          if (!obj) return undefined;
          newSettings = obj;
        }

        return new RecoverPassword(code, newSettings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RecoverPassword.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.code);
        if (this.newSettings) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.newSettings) _data.push(this.newSettings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly code: TLString,
        readonly newSettings: account.PasswordInputSettings | undefined
      ) {}
    }
  }

  export namespace auth {
    export class ResendCode implements TLFunction<auth.SentCodeTypeType> {
      static readonly cons = new TLInt(0x3ef1a9bf);

      static deserialized(_data: ByteStream): ResendCode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResendCode.cons))
          return undefined;

        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const phoneCodeHash = TLString.deserialized(_data);
        if (!phoneCodeHash) return undefined;

        return new ResendCode(phoneNumber, phoneCodeHash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResendCode.cons];

        _data.push(this.phoneNumber);
        _data.push(this.phoneCodeHash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneNumber: TLString,
        readonly phoneCodeHash: TLString
      ) {}
    }
  }

  export namespace auth {
    export class CancelCode implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x1f040578);

      static deserialized(_data: ByteStream): CancelCode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CancelCode.cons))
          return undefined;

        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const phoneCodeHash = TLString.deserialized(_data);
        if (!phoneCodeHash) return undefined;

        return new CancelCode(phoneNumber, phoneCodeHash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CancelCode.cons];

        _data.push(this.phoneNumber);
        _data.push(this.phoneCodeHash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneNumber: TLString,
        readonly phoneCodeHash: TLString
      ) {}
    }
  }

  export namespace auth {
    export class DropTempAuthKeys implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x8e48a188);

      static deserialized(_data: ByteStream): DropTempAuthKeys | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DropTempAuthKeys.cons))
          return undefined;

        const exceptAuthKeys = TLVector.deserialized(_data, TLLong);
        if (!exceptAuthKeys) return undefined;

        return new DropTempAuthKeys(exceptAuthKeys);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DropTempAuthKeys.cons];

        _data.push(this.exceptAuthKeys);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly exceptAuthKeys: TLVector<TLLong>) {}
    }
  }

  export namespace auth {
    export class ExportLoginToken implements TLFunction<auth.LoginTokenType> {
      static readonly cons = new TLInt(0xb7e085fe);

      static deserialized(_data: ByteStream): ExportLoginToken | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportLoginToken.cons))
          return undefined;

        const apiId = TLInt.deserialized(_data);
        if (!apiId) return undefined;
        const apiHash = TLString.deserialized(_data);
        if (!apiHash) return undefined;
        const exceptIds = TLVector.deserialized(_data, TLLong);
        if (!exceptIds) return undefined;

        return new ExportLoginToken(apiId, apiHash, exceptIds);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportLoginToken.cons];

        _data.push(this.apiId);
        _data.push(this.apiHash);
        _data.push(this.exceptIds);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly apiId: TLInt,
        readonly apiHash: TLString,
        readonly exceptIds: TLVector<TLLong>
      ) {}
    }
  }

  export namespace auth {
    export class ImportLoginToken implements TLFunction<auth.LoginTokenType> {
      static readonly cons = new TLInt(0x95ac5ce4);

      static deserialized(_data: ByteStream): ImportLoginToken | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ImportLoginToken.cons))
          return undefined;

        const token = TLBytes.deserialized(_data);
        if (!token) return undefined;

        return new ImportLoginToken(token);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ImportLoginToken.cons];

        _data.push(this.token);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly token: TLBytes) {}
    }
  }

  export namespace auth {
    export class AcceptLoginToken implements TLFunction<API.Authorization> {
      static readonly cons = new TLInt(0xe894ad4d);

      static deserialized(_data: ByteStream): AcceptLoginToken | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AcceptLoginToken.cons))
          return undefined;

        const token = TLBytes.deserialized(_data);
        if (!token) return undefined;

        return new AcceptLoginToken(token);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AcceptLoginToken.cons];

        _data.push(this.token);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly token: TLBytes) {}
    }
  }

  export namespace auth {
    export class CheckRecoveryPassword implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xd36bf79);

      static deserialized(
        _data: ByteStream
      ): CheckRecoveryPassword | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CheckRecoveryPassword.cons))
          return undefined;

        const code = TLString.deserialized(_data);
        if (!code) return undefined;

        return new CheckRecoveryPassword(code);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CheckRecoveryPassword.cons];

        _data.push(this.code);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly code: TLString) {}
    }
  }

  export namespace auth {
    export class ImportWebTokenAuthorization
      implements TLFunction<auth.AuthorizationType>
    {
      static readonly cons = new TLInt(0x2db873a9);

      static deserialized(
        _data: ByteStream
      ): ImportWebTokenAuthorization | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(ImportWebTokenAuthorization.cons)
        )
          return undefined;

        const apiId = TLInt.deserialized(_data);
        if (!apiId) return undefined;
        const apiHash = TLString.deserialized(_data);
        if (!apiHash) return undefined;
        const webAuthToken = TLString.deserialized(_data);
        if (!webAuthToken) return undefined;

        return new ImportWebTokenAuthorization(apiId, apiHash, webAuthToken);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ImportWebTokenAuthorization.cons];

        _data.push(this.apiId);
        _data.push(this.apiHash);
        _data.push(this.webAuthToken);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly apiId: TLInt,
        readonly apiHash: TLString,
        readonly webAuthToken: TLString
      ) {}
    }
  }

  export namespace auth {
    export class RequestFirebaseSms implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x89464b50);

      static deserialized(_data: ByteStream): RequestFirebaseSms | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RequestFirebaseSms.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const phoneCodeHash = TLString.deserialized(_data);
        if (!phoneCodeHash) return undefined;
        let safetyNetToken: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          safetyNetToken = obj;
        }
        let iosPushSecret: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          iosPushSecret = obj;
        }

        return new RequestFirebaseSms(
          phoneNumber,
          phoneCodeHash,
          safetyNetToken,
          iosPushSecret
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RequestFirebaseSms.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.phoneNumber);
        _data.push(this.phoneCodeHash);
        if (this.safetyNetToken) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.safetyNetToken) _data.push(this.safetyNetToken);

        if (this.iosPushSecret) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.iosPushSecret) _data.push(this.iosPushSecret);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneNumber: TLString,
        readonly phoneCodeHash: TLString,
        readonly safetyNetToken: TLString | undefined,
        readonly iosPushSecret: TLString | undefined
      ) {}
    }
  }

  export namespace auth {
    export class ResetLoginEmail implements TLFunction<auth.SentCodeTypeType> {
      static readonly cons = new TLInt(0x7e960193);

      static deserialized(_data: ByteStream): ResetLoginEmail | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetLoginEmail.cons))
          return undefined;

        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const phoneCodeHash = TLString.deserialized(_data);
        if (!phoneCodeHash) return undefined;

        return new ResetLoginEmail(phoneNumber, phoneCodeHash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetLoginEmail.cons];

        _data.push(this.phoneNumber);
        _data.push(this.phoneCodeHash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneNumber: TLString,
        readonly phoneCodeHash: TLString
      ) {}
    }
  }

  export namespace account {
    export class RegisterDevice implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xec86017a);

      static deserialized(_data: ByteStream): RegisterDevice | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RegisterDevice.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const noMuted = (flags.value & 1) !== 0;
        const tokenType = TLInt.deserialized(_data);
        if (!tokenType) return undefined;
        const token = TLString.deserialized(_data);
        if (!token) return undefined;
        const appSandbox = TLObject.deserialized(_data) as BoolType;
        if (!appSandbox) return undefined;
        const secret = TLBytes.deserialized(_data);
        if (!secret) return undefined;
        const otherUids = TLVector.deserialized(_data, TLLong);
        if (!otherUids) return undefined;

        return new RegisterDevice(
          noMuted,
          tokenType,
          token,
          appSandbox,
          secret,
          otherUids
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RegisterDevice.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.noMuted) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.tokenType);
        _data.push(this.token);
        _data.push(this.appSandbox);
        _data.push(this.secret);
        _data.push(this.otherUids);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly noMuted: boolean | undefined,
        readonly tokenType: TLInt,
        readonly token: TLString,
        readonly appSandbox: BoolType,
        readonly secret: TLBytes,
        readonly otherUids: TLVector<TLLong>
      ) {}
    }
  }

  export namespace account {
    export class UnregisterDevice implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x6a0d3206);

      static deserialized(_data: ByteStream): UnregisterDevice | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UnregisterDevice.cons))
          return undefined;

        const tokenType = TLInt.deserialized(_data);
        if (!tokenType) return undefined;
        const token = TLString.deserialized(_data);
        if (!token) return undefined;
        const otherUids = TLVector.deserialized(_data, TLLong);
        if (!otherUids) return undefined;

        return new UnregisterDevice(tokenType, token, otherUids);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UnregisterDevice.cons];

        _data.push(this.tokenType);
        _data.push(this.token);
        _data.push(this.otherUids);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly tokenType: TLInt,
        readonly token: TLString,
        readonly otherUids: TLVector<TLLong>
      ) {}
    }
  }

  export namespace account {
    export class UpdateNotifySettings implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x84be5b93);

      static deserialized(_data: ByteStream): UpdateNotifySettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdateNotifySettings.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputNotifyPeerType;
        if (!peer) return undefined;
        const settings = API.InputPeerNotifySettings.deserialized(_data);
        if (!settings) return undefined;

        return new UpdateNotifySettings(peer, settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdateNotifySettings.cons];

        _data.push(this.peer);
        _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputNotifyPeerType,
        readonly settings: API.InputPeerNotifySettings
      ) {}
    }
  }

  export namespace account {
    export class GetNotifySettings
      implements TLFunction<API.PeerNotifySettings>
    {
      static readonly cons = new TLInt(0x12b3ad31);

      static deserialized(_data: ByteStream): GetNotifySettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetNotifySettings.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputNotifyPeerType;
        if (!peer) return undefined;

        return new GetNotifySettings(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetNotifySettings.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputNotifyPeerType) {}
    }
  }

  export namespace account {
    export class ResetNotifySettings implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xdb7e1747);

      static deserialized(_data: ByteStream): ResetNotifySettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetNotifySettings.cons))
          return undefined;

        return new ResetNotifySettings();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetNotifySettings.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class UpdateProfile implements TLFunction<UserType> {
      static readonly cons = new TLInt(0x78515775);

      static deserialized(_data: ByteStream): UpdateProfile | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdateProfile.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let firstName: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          firstName = obj;
        }
        let lastName: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          lastName = obj;
        }
        let about: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          about = obj;
        }

        return new UpdateProfile(firstName, lastName, about);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdateProfile.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.firstName) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.firstName) _data.push(this.firstName);

        if (this.lastName) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.lastName) _data.push(this.lastName);

        if (this.about) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.about) _data.push(this.about);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly firstName: TLString | undefined,
        readonly lastName: TLString | undefined,
        readonly about: TLString | undefined
      ) {}
    }
  }

  export namespace account {
    export class UpdateStatus implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x6628562c);

      static deserialized(_data: ByteStream): UpdateStatus | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdateStatus.cons))
          return undefined;

        const offline = TLObject.deserialized(_data) as BoolType;
        if (!offline) return undefined;

        return new UpdateStatus(offline);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdateStatus.cons];

        _data.push(this.offline);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly offline: BoolType) {}
    }
  }

  export namespace account {
    export class GetWallPapers implements TLFunction<account.WallPapersType> {
      static readonly cons = new TLInt(0x7967d36);

      static deserialized(_data: ByteStream): GetWallPapers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetWallPapers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetWallPapers(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetWallPapers.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace account {
    export class ReportPeer implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xc5ba3d86);

      static deserialized(_data: ByteStream): ReportPeer | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReportPeer.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const reason = TLObject.deserialized(_data) as ReportReasonType;
        if (!reason) return undefined;
        const message = TLString.deserialized(_data);
        if (!message) return undefined;

        return new ReportPeer(peer, reason, message);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReportPeer.cons];

        _data.push(this.peer);
        _data.push(this.reason);
        _data.push(this.message);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly reason: ReportReasonType,
        readonly message: TLString
      ) {}
    }
  }

  export namespace account {
    export class CheckUsername implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x2714d86c);

      static deserialized(_data: ByteStream): CheckUsername | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CheckUsername.cons))
          return undefined;

        const username = TLString.deserialized(_data);
        if (!username) return undefined;

        return new CheckUsername(username);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CheckUsername.cons];

        _data.push(this.username);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly username: TLString) {}
    }
  }

  export namespace account {
    export class UpdateUsername implements TLFunction<UserType> {
      static readonly cons = new TLInt(0x3e0bdd7c);

      static deserialized(_data: ByteStream): UpdateUsername | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdateUsername.cons))
          return undefined;

        const username = TLString.deserialized(_data);
        if (!username) return undefined;

        return new UpdateUsername(username);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdateUsername.cons];

        _data.push(this.username);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly username: TLString) {}
    }
  }

  export namespace account {
    export class GetPrivacy implements TLFunction<account.PrivacyRules> {
      static readonly cons = new TLInt(0xdadbc950);

      static deserialized(_data: ByteStream): GetPrivacy | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPrivacy.cons))
          return undefined;

        const key = TLObject.deserialized(_data) as InputPrivacyKeyType;
        if (!key) return undefined;

        return new GetPrivacy(key);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPrivacy.cons];

        _data.push(this.key);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly key: InputPrivacyKeyType) {}
    }
  }

  export namespace account {
    export class SetPrivacy implements TLFunction<account.PrivacyRules> {
      static readonly cons = new TLInt(0xc9f81ce8);

      static deserialized(_data: ByteStream): SetPrivacy | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetPrivacy.cons))
          return undefined;

        const key = TLObject.deserialized(_data) as InputPrivacyKeyType;
        if (!key) return undefined;
        const rules = TLVector.deserialized(
          _data
        ) as TLVector<InputPrivacyRuleType>;
        if (!rules) return undefined;

        return new SetPrivacy(key, rules);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetPrivacy.cons];

        _data.push(this.key);
        _data.push(this.rules);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly key: InputPrivacyKeyType,
        readonly rules: TLVector<InputPrivacyRuleType>
      ) {}
    }
  }

  export namespace account {
    export class DeleteAccount implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xa2c0cf74);

      static deserialized(_data: ByteStream): DeleteAccount | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteAccount.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const reason = TLString.deserialized(_data);
        if (!reason) return undefined;
        let password: InputCheckPasswordSRPType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputCheckPasswordSRPType;
          if (!obj) return undefined;
          password = obj;
        }

        return new DeleteAccount(reason, password);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteAccount.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.reason);
        if (this.password) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.password) _data.push(this.password);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly reason: TLString,
        readonly password: InputCheckPasswordSRPType | undefined
      ) {}
    }
  }

  export namespace account {
    export class GetAccountTTL implements TLFunction<API.AccountDaysTTL> {
      static readonly cons = new TLInt(0x8fc711d);

      static deserialized(_data: ByteStream): GetAccountTTL | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAccountTTL.cons))
          return undefined;

        return new GetAccountTTL();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAccountTTL.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class SetAccountTTL implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x2442485e);

      static deserialized(_data: ByteStream): SetAccountTTL | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetAccountTTL.cons))
          return undefined;

        const ttl = API.AccountDaysTTL.deserialized(_data);
        if (!ttl) return undefined;

        return new SetAccountTTL(ttl);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetAccountTTL.cons];

        _data.push(this.ttl);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly ttl: API.AccountDaysTTL) {}
    }
  }

  export namespace account {
    export class SendChangePhoneCode
      implements TLFunction<auth.SentCodeTypeType>
    {
      static readonly cons = new TLInt(0x82574ae5);

      static deserialized(_data: ByteStream): SendChangePhoneCode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendChangePhoneCode.cons))
          return undefined;

        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const settings = API.CodeSettings.deserialized(_data);
        if (!settings) return undefined;

        return new SendChangePhoneCode(phoneNumber, settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendChangePhoneCode.cons];

        _data.push(this.phoneNumber);
        _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneNumber: TLString,
        readonly settings: API.CodeSettings
      ) {}
    }
  }

  export namespace account {
    export class ChangePhone implements TLFunction<UserType> {
      static readonly cons = new TLInt(0x70c32edb);

      static deserialized(_data: ByteStream): ChangePhone | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChangePhone.cons))
          return undefined;

        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const phoneCodeHash = TLString.deserialized(_data);
        if (!phoneCodeHash) return undefined;
        const phoneCode = TLString.deserialized(_data);
        if (!phoneCode) return undefined;

        return new ChangePhone(phoneNumber, phoneCodeHash, phoneCode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChangePhone.cons];

        _data.push(this.phoneNumber);
        _data.push(this.phoneCodeHash);
        _data.push(this.phoneCode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneNumber: TLString,
        readonly phoneCodeHash: TLString,
        readonly phoneCode: TLString
      ) {}
    }
  }

  export namespace account {
    export class UpdateDeviceLocked implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x38df3532);

      static deserialized(_data: ByteStream): UpdateDeviceLocked | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdateDeviceLocked.cons))
          return undefined;

        const period = TLInt.deserialized(_data);
        if (!period) return undefined;

        return new UpdateDeviceLocked(period);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdateDeviceLocked.cons];

        _data.push(this.period);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly period: TLInt) {}
    }
  }

  export namespace account {
    export class GetAuthorizations
      implements TLFunction<account.Authorizations>
    {
      static readonly cons = new TLInt(0xe320c158);

      static deserialized(_data: ByteStream): GetAuthorizations | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAuthorizations.cons))
          return undefined;

        return new GetAuthorizations();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAuthorizations.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class ResetAuthorization implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xdf77f3bc);

      static deserialized(_data: ByteStream): ResetAuthorization | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetAuthorization.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new ResetAuthorization(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetAuthorization.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace account {
    export class GetPassword implements TLFunction<account.Password> {
      static readonly cons = new TLInt(0x548a30f5);

      static deserialized(_data: ByteStream): GetPassword | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPassword.cons))
          return undefined;

        return new GetPassword();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPassword.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class GetPasswordSettings
      implements TLFunction<account.PasswordSettings>
    {
      static readonly cons = new TLInt(0x9cd4eaf9);

      static deserialized(_data: ByteStream): GetPasswordSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPasswordSettings.cons))
          return undefined;

        const password = TLObject.deserialized(
          _data
        ) as InputCheckPasswordSRPType;
        if (!password) return undefined;

        return new GetPasswordSettings(password);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPasswordSettings.cons];

        _data.push(this.password);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly password: InputCheckPasswordSRPType) {}
    }
  }

  export namespace account {
    export class UpdatePasswordSettings implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xa59b102f);

      static deserialized(
        _data: ByteStream
      ): UpdatePasswordSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdatePasswordSettings.cons))
          return undefined;

        const password = TLObject.deserialized(
          _data
        ) as InputCheckPasswordSRPType;
        if (!password) return undefined;
        const newSettings = account.PasswordInputSettings.deserialized(_data);
        if (!newSettings) return undefined;

        return new UpdatePasswordSettings(password, newSettings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdatePasswordSettings.cons];

        _data.push(this.password);
        _data.push(this.newSettings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly password: InputCheckPasswordSRPType,
        readonly newSettings: account.PasswordInputSettings
      ) {}
    }
  }

  export namespace account {
    export class SendConfirmPhoneCode
      implements TLFunction<auth.SentCodeTypeType>
    {
      static readonly cons = new TLInt(0x1b3faa88);

      static deserialized(_data: ByteStream): SendConfirmPhoneCode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendConfirmPhoneCode.cons))
          return undefined;

        const hash = TLString.deserialized(_data);
        if (!hash) return undefined;
        const settings = API.CodeSettings.deserialized(_data);
        if (!settings) return undefined;

        return new SendConfirmPhoneCode(hash, settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendConfirmPhoneCode.cons];

        _data.push(this.hash);
        _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly hash: TLString,
        readonly settings: API.CodeSettings
      ) {}
    }
  }

  export namespace account {
    export class ConfirmPhone implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x5f2178c3);

      static deserialized(_data: ByteStream): ConfirmPhone | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ConfirmPhone.cons))
          return undefined;

        const phoneCodeHash = TLString.deserialized(_data);
        if (!phoneCodeHash) return undefined;
        const phoneCode = TLString.deserialized(_data);
        if (!phoneCode) return undefined;

        return new ConfirmPhone(phoneCodeHash, phoneCode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ConfirmPhone.cons];

        _data.push(this.phoneCodeHash);
        _data.push(this.phoneCode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneCodeHash: TLString,
        readonly phoneCode: TLString
      ) {}
    }
  }

  export namespace account {
    export class GetTmpPassword implements TLFunction<account.TmpPassword> {
      static readonly cons = new TLInt(0x449e0b51);

      static deserialized(_data: ByteStream): GetTmpPassword | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetTmpPassword.cons))
          return undefined;

        const password = TLObject.deserialized(
          _data
        ) as InputCheckPasswordSRPType;
        if (!password) return undefined;
        const period = TLInt.deserialized(_data);
        if (!period) return undefined;

        return new GetTmpPassword(password, period);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetTmpPassword.cons];

        _data.push(this.password);
        _data.push(this.period);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly password: InputCheckPasswordSRPType,
        readonly period: TLInt
      ) {}
    }
  }

  export namespace account {
    export class GetWebAuthorizations
      implements TLFunction<account.WebAuthorizations>
    {
      static readonly cons = new TLInt(0x182e6d6f);

      static deserialized(_data: ByteStream): GetWebAuthorizations | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetWebAuthorizations.cons))
          return undefined;

        return new GetWebAuthorizations();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetWebAuthorizations.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class ResetWebAuthorization implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x2d01b9ef);

      static deserialized(
        _data: ByteStream
      ): ResetWebAuthorization | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetWebAuthorization.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new ResetWebAuthorization(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetWebAuthorization.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace account {
    export class ResetWebAuthorizations implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x682d2594);

      static deserialized(
        _data: ByteStream
      ): ResetWebAuthorizations | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetWebAuthorizations.cons))
          return undefined;

        return new ResetWebAuthorizations();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetWebAuthorizations.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class GetAllSecureValues
      implements TLFunction<TLVector<API.SecureValue>>
    {
      static readonly cons = new TLInt(0xb288bc7d);

      static deserialized(_data: ByteStream): GetAllSecureValues | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAllSecureValues.cons))
          return undefined;

        return new GetAllSecureValues();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAllSecureValues.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class GetSecureValue
      implements TLFunction<TLVector<API.SecureValue>>
    {
      static readonly cons = new TLInt(0x73665bc2);

      static deserialized(_data: ByteStream): GetSecureValue | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSecureValue.cons))
          return undefined;

        const types = TLVector.deserialized(
          _data
        ) as TLVector<SecureValueTypeType>;
        if (!types) return undefined;

        return new GetSecureValue(types);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSecureValue.cons];

        _data.push(this.types);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly types: TLVector<SecureValueTypeType>) {}
    }
  }

  export namespace account {
    export class SaveSecureValue implements TLFunction<API.SecureValue> {
      static readonly cons = new TLInt(0x899fe31d);

      static deserialized(_data: ByteStream): SaveSecureValue | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveSecureValue.cons))
          return undefined;

        const value = API.InputSecureValue.deserialized(_data);
        if (!value) return undefined;
        const secureSecretId = TLLong.deserialized(_data);
        if (!secureSecretId) return undefined;

        return new SaveSecureValue(value, secureSecretId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveSecureValue.cons];

        _data.push(this.value);
        _data.push(this.secureSecretId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly value: API.InputSecureValue,
        readonly secureSecretId: TLLong
      ) {}
    }
  }

  export namespace account {
    export class DeleteSecureValue implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xb880bc4b);

      static deserialized(_data: ByteStream): DeleteSecureValue | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteSecureValue.cons))
          return undefined;

        const types = TLVector.deserialized(
          _data
        ) as TLVector<SecureValueTypeType>;
        if (!types) return undefined;

        return new DeleteSecureValue(types);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteSecureValue.cons];

        _data.push(this.types);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly types: TLVector<SecureValueTypeType>) {}
    }
  }

  export namespace account {
    export class GetAuthorizationForm
      implements TLFunction<account.AuthorizationForm>
    {
      static readonly cons = new TLInt(0xa929597a);

      static deserialized(_data: ByteStream): GetAuthorizationForm | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAuthorizationForm.cons))
          return undefined;

        const botId = TLLong.deserialized(_data);
        if (!botId) return undefined;
        const scope = TLString.deserialized(_data);
        if (!scope) return undefined;
        const publicKey = TLString.deserialized(_data);
        if (!publicKey) return undefined;

        return new GetAuthorizationForm(botId, scope, publicKey);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAuthorizationForm.cons];

        _data.push(this.botId);
        _data.push(this.scope);
        _data.push(this.publicKey);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly botId: TLLong,
        readonly scope: TLString,
        readonly publicKey: TLString
      ) {}
    }
  }

  export namespace account {
    export class AcceptAuthorization implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xf3ed4c73);

      static deserialized(_data: ByteStream): AcceptAuthorization | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AcceptAuthorization.cons))
          return undefined;

        const botId = TLLong.deserialized(_data);
        if (!botId) return undefined;
        const scope = TLString.deserialized(_data);
        if (!scope) return undefined;
        const publicKey = TLString.deserialized(_data);
        if (!publicKey) return undefined;
        const valueHashes = TLVector.deserialized(_data, API.SecureValueHash);
        if (!valueHashes) return undefined;
        const credentials = API.SecureCredentialsEncrypted.deserialized(_data);
        if (!credentials) return undefined;

        return new AcceptAuthorization(
          botId,
          scope,
          publicKey,
          valueHashes,
          credentials
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AcceptAuthorization.cons];

        _data.push(this.botId);
        _data.push(this.scope);
        _data.push(this.publicKey);
        _data.push(this.valueHashes);
        _data.push(this.credentials);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly botId: TLLong,
        readonly scope: TLString,
        readonly publicKey: TLString,
        readonly valueHashes: TLVector<API.SecureValueHash>,
        readonly credentials: API.SecureCredentialsEncrypted
      ) {}
    }
  }

  export namespace account {
    export class SendVerifyPhoneCode
      implements TLFunction<auth.SentCodeTypeType>
    {
      static readonly cons = new TLInt(0xa5a356f9);

      static deserialized(_data: ByteStream): SendVerifyPhoneCode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendVerifyPhoneCode.cons))
          return undefined;

        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const settings = API.CodeSettings.deserialized(_data);
        if (!settings) return undefined;

        return new SendVerifyPhoneCode(phoneNumber, settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendVerifyPhoneCode.cons];

        _data.push(this.phoneNumber);
        _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneNumber: TLString,
        readonly settings: API.CodeSettings
      ) {}
    }
  }

  export namespace account {
    export class VerifyPhone implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x4dd3a7f6);

      static deserialized(_data: ByteStream): VerifyPhone | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(VerifyPhone.cons))
          return undefined;

        const phoneNumber = TLString.deserialized(_data);
        if (!phoneNumber) return undefined;
        const phoneCodeHash = TLString.deserialized(_data);
        if (!phoneCodeHash) return undefined;
        const phoneCode = TLString.deserialized(_data);
        if (!phoneCode) return undefined;

        return new VerifyPhone(phoneNumber, phoneCodeHash, phoneCode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [VerifyPhone.cons];

        _data.push(this.phoneNumber);
        _data.push(this.phoneCodeHash);
        _data.push(this.phoneCode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly phoneNumber: TLString,
        readonly phoneCodeHash: TLString,
        readonly phoneCode: TLString
      ) {}
    }
  }

  export namespace account {
    export class SendVerifyEmailCode
      implements TLFunction<account.SentEmailCode>
    {
      static readonly cons = new TLInt(0x98e037bb);

      static deserialized(_data: ByteStream): SendVerifyEmailCode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendVerifyEmailCode.cons))
          return undefined;

        const purpose = TLObject.deserialized(_data) as EmailVerifyPurposeType;
        if (!purpose) return undefined;
        const email = TLString.deserialized(_data);
        if (!email) return undefined;

        return new SendVerifyEmailCode(purpose, email);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendVerifyEmailCode.cons];

        _data.push(this.purpose);
        _data.push(this.email);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly purpose: EmailVerifyPurposeType,
        readonly email: TLString
      ) {}
    }
  }

  export namespace account {
    export class VerifyEmail implements TLFunction<account.EmailVerifiedType> {
      static readonly cons = new TLInt(0x32da4cf);

      static deserialized(_data: ByteStream): VerifyEmail | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(VerifyEmail.cons))
          return undefined;

        const purpose = TLObject.deserialized(_data) as EmailVerifyPurposeType;
        if (!purpose) return undefined;
        const verification = TLObject.deserialized(
          _data
        ) as EmailVerificationType;
        if (!verification) return undefined;

        return new VerifyEmail(purpose, verification);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [VerifyEmail.cons];

        _data.push(this.purpose);
        _data.push(this.verification);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly purpose: EmailVerifyPurposeType,
        readonly verification: EmailVerificationType
      ) {}
    }
  }

  export namespace account {
    export class InitTakeoutSession implements TLFunction<account.Takeout> {
      static readonly cons = new TLInt(0x8ef3eab0);

      static deserialized(_data: ByteStream): InitTakeoutSession | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(InitTakeoutSession.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const contacts = (flags.value & 1) !== 0;
        const messageUsers = (flags.value & 2) !== 0;
        const messageChats = (flags.value & 4) !== 0;
        const messageMegagroups = (flags.value & 8) !== 0;
        const messageChannels = (flags.value & 16) !== 0;
        const files = (flags.value & 32) !== 0;
        let fileMaxSize: TLLong | undefined;
        if ((flags.value & 32) !== 0) {
          const obj = TLLong.deserialized(_data);
          if (!obj) return undefined;
          fileMaxSize = obj;
        }

        return new InitTakeoutSession(
          contacts,
          messageUsers,
          messageChats,
          messageMegagroups,
          messageChannels,
          files,
          fileMaxSize
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [InitTakeoutSession.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.contacts) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.messageUsers) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.messageChats) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        if (this.messageMegagroups) {
          flags.set(8);
        } else {
          flags.unset(8);
        }

        if (this.messageChannels) {
          flags.set(16);
        } else {
          flags.unset(16);
        }

        if (this.files) {
          flags.set(32);
        } else {
          flags.unset(32);
        }

        if (this.fileMaxSize) {
          flags.set(32);
        } else {
          flags.unset(32);
        }
        if (this.fileMaxSize) _data.push(this.fileMaxSize);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly contacts: boolean | undefined,
        readonly messageUsers: boolean | undefined,
        readonly messageChats: boolean | undefined,
        readonly messageMegagroups: boolean | undefined,
        readonly messageChannels: boolean | undefined,
        readonly files: boolean | undefined,
        readonly fileMaxSize: TLLong | undefined
      ) {}
    }
  }

  export namespace account {
    export class FinishTakeoutSession implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x1d2652ee);

      static deserialized(_data: ByteStream): FinishTakeoutSession | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FinishTakeoutSession.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const success = (flags.value & 1) !== 0;

        return new FinishTakeoutSession(success);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FinishTakeoutSession.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.success) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly success: boolean | undefined) {}
    }
  }

  export namespace account {
    export class ConfirmPasswordEmail implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x8fdf1920);

      static deserialized(_data: ByteStream): ConfirmPasswordEmail | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ConfirmPasswordEmail.cons))
          return undefined;

        const code = TLString.deserialized(_data);
        if (!code) return undefined;

        return new ConfirmPasswordEmail(code);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ConfirmPasswordEmail.cons];

        _data.push(this.code);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly code: TLString) {}
    }
  }

  export namespace account {
    export class ResendPasswordEmail implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x7a7f2a15);

      static deserialized(_data: ByteStream): ResendPasswordEmail | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResendPasswordEmail.cons))
          return undefined;

        return new ResendPasswordEmail();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResendPasswordEmail.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class CancelPasswordEmail implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xc1cbd5b6);

      static deserialized(_data: ByteStream): CancelPasswordEmail | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CancelPasswordEmail.cons))
          return undefined;

        return new CancelPasswordEmail();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CancelPasswordEmail.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class GetContactSignUpNotification implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x9f07c728);

      static deserialized(
        _data: ByteStream
      ): GetContactSignUpNotification | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(GetContactSignUpNotification.cons)
        )
          return undefined;

        return new GetContactSignUpNotification();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          GetContactSignUpNotification.cons,
        ];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class SetContactSignUpNotification implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xcff43f61);

      static deserialized(
        _data: ByteStream
      ): SetContactSignUpNotification | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(SetContactSignUpNotification.cons)
        )
          return undefined;

        const silent = TLObject.deserialized(_data) as BoolType;
        if (!silent) return undefined;

        return new SetContactSignUpNotification(silent);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          SetContactSignUpNotification.cons,
        ];

        _data.push(this.silent);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly silent: BoolType) {}
    }
  }

  export namespace account {
    export class GetNotifyExceptions implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x53577479);

      static deserialized(_data: ByteStream): GetNotifyExceptions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetNotifyExceptions.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const compareSound = (flags.value & 2) !== 0;
        const compareStories = (flags.value & 4) !== 0;
        let peer: InputNotifyPeerType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputNotifyPeerType;
          if (!obj) return undefined;
          peer = obj;
        }

        return new GetNotifyExceptions(compareSound, compareStories, peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetNotifyExceptions.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.compareSound) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.compareStories) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        if (this.peer) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.peer) _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly compareSound: boolean | undefined,
        readonly compareStories: boolean | undefined,
        readonly peer: InputNotifyPeerType | undefined
      ) {}
    }
  }

  export namespace account {
    export class GetWallPaper implements TLFunction<WallPaperType> {
      static readonly cons = new TLInt(0xfc8ddbea);

      static deserialized(_data: ByteStream): GetWallPaper | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetWallPaper.cons))
          return undefined;

        const wallpaper = TLObject.deserialized(_data) as InputWallPaperType;
        if (!wallpaper) return undefined;

        return new GetWallPaper(wallpaper);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetWallPaper.cons];

        _data.push(this.wallpaper);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly wallpaper: InputWallPaperType) {}
    }
  }

  export namespace account {
    export class UploadWallPaper implements TLFunction<WallPaperType> {
      static readonly cons = new TLInt(0xe39a8f03);

      static deserialized(_data: ByteStream): UploadWallPaper | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UploadWallPaper.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const forChat = (flags.value & 1) !== 0;
        const file = TLObject.deserialized(_data) as InputFileType;
        if (!file) return undefined;
        const mimeType = TLString.deserialized(_data);
        if (!mimeType) return undefined;
        const settings = API.WallPaperSettings.deserialized(_data);
        if (!settings) return undefined;

        return new UploadWallPaper(forChat, file, mimeType, settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UploadWallPaper.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.forChat) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.file);
        _data.push(this.mimeType);
        _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly forChat: boolean | undefined,
        readonly file: InputFileType,
        readonly mimeType: TLString,
        readonly settings: API.WallPaperSettings
      ) {}
    }
  }

  export namespace account {
    export class SaveWallPaper implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x6c5a5b37);

      static deserialized(_data: ByteStream): SaveWallPaper | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveWallPaper.cons))
          return undefined;

        const wallpaper = TLObject.deserialized(_data) as InputWallPaperType;
        if (!wallpaper) return undefined;
        const unsave = TLObject.deserialized(_data) as BoolType;
        if (!unsave) return undefined;
        const settings = API.WallPaperSettings.deserialized(_data);
        if (!settings) return undefined;

        return new SaveWallPaper(wallpaper, unsave, settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveWallPaper.cons];

        _data.push(this.wallpaper);
        _data.push(this.unsave);
        _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly wallpaper: InputWallPaperType,
        readonly unsave: BoolType,
        readonly settings: API.WallPaperSettings
      ) {}
    }
  }

  export namespace account {
    export class InstallWallPaper implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xfeed5769);

      static deserialized(_data: ByteStream): InstallWallPaper | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(InstallWallPaper.cons))
          return undefined;

        const wallpaper = TLObject.deserialized(_data) as InputWallPaperType;
        if (!wallpaper) return undefined;
        const settings = API.WallPaperSettings.deserialized(_data);
        if (!settings) return undefined;

        return new InstallWallPaper(wallpaper, settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [InstallWallPaper.cons];

        _data.push(this.wallpaper);
        _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly wallpaper: InputWallPaperType,
        readonly settings: API.WallPaperSettings
      ) {}
    }
  }

  export namespace account {
    export class ResetWallPapers implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xbb3b9804);

      static deserialized(_data: ByteStream): ResetWallPapers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetWallPapers.cons))
          return undefined;

        return new ResetWallPapers();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetWallPapers.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class GetAutoDownloadSettings
      implements TLFunction<account.AutoDownloadSettings>
    {
      static readonly cons = new TLInt(0x56da0b3f);

      static deserialized(
        _data: ByteStream
      ): GetAutoDownloadSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAutoDownloadSettings.cons))
          return undefined;

        return new GetAutoDownloadSettings();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAutoDownloadSettings.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class SaveAutoDownloadSettings implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x76f36233);

      static deserialized(
        _data: ByteStream
      ): SaveAutoDownloadSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveAutoDownloadSettings.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const low = (flags.value & 1) !== 0;
        const high = (flags.value & 2) !== 0;
        const settings = API.AutoDownloadSettings.deserialized(_data);
        if (!settings) return undefined;

        return new SaveAutoDownloadSettings(low, high, settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveAutoDownloadSettings.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.low) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.high) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly low: boolean | undefined,
        readonly high: boolean | undefined,
        readonly settings: API.AutoDownloadSettings
      ) {}
    }
  }

  export namespace account {
    export class UploadTheme implements TLFunction<DocumentType> {
      static readonly cons = new TLInt(0x1c3db333);

      static deserialized(_data: ByteStream): UploadTheme | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UploadTheme.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const file = TLObject.deserialized(_data) as InputFileType;
        if (!file) return undefined;
        let thumb: InputFileType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputFileType;
          if (!obj) return undefined;
          thumb = obj;
        }
        const fileName = TLString.deserialized(_data);
        if (!fileName) return undefined;
        const mimeType = TLString.deserialized(_data);
        if (!mimeType) return undefined;

        return new UploadTheme(file, thumb, fileName, mimeType);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UploadTheme.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.file);
        if (this.thumb) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.thumb) _data.push(this.thumb);

        _data.push(this.fileName);
        _data.push(this.mimeType);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly file: InputFileType,
        readonly thumb: InputFileType | undefined,
        readonly fileName: TLString,
        readonly mimeType: TLString
      ) {}
    }
  }

  export namespace account {
    export class CreateTheme implements TLFunction<API.Theme> {
      static readonly cons = new TLInt(0x652e4400);

      static deserialized(_data: ByteStream): CreateTheme | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CreateTheme.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const slug = TLString.deserialized(_data);
        if (!slug) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;
        let document: InputDocumentType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as InputDocumentType;
          if (!obj) return undefined;
          document = obj;
        }
        let settings: TLVector<API.InputThemeSettings> | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLVector.deserialized(_data, API.InputThemeSettings);
          if (!obj) return undefined;
          settings = obj;
        }

        return new CreateTheme(slug, title, document, settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CreateTheme.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.slug);
        _data.push(this.title);
        if (this.document) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.document) _data.push(this.document);

        if (this.settings) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.settings) _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly slug: TLString,
        readonly title: TLString,
        readonly document: InputDocumentType | undefined,
        readonly settings: TLVector<API.InputThemeSettings> | undefined
      ) {}
    }
  }

  export namespace account {
    export class UpdateTheme implements TLFunction<API.Theme> {
      static readonly cons = new TLInt(0x2bf40ccc);

      static deserialized(_data: ByteStream): UpdateTheme | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdateTheme.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const format = TLString.deserialized(_data);
        if (!format) return undefined;
        const theme = TLObject.deserialized(_data) as InputThemeType;
        if (!theme) return undefined;
        let slug: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          slug = obj;
        }
        let title: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          title = obj;
        }
        let document: InputDocumentType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as InputDocumentType;
          if (!obj) return undefined;
          document = obj;
        }
        let settings: TLVector<API.InputThemeSettings> | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLVector.deserialized(_data, API.InputThemeSettings);
          if (!obj) return undefined;
          settings = obj;
        }

        return new UpdateTheme(format, theme, slug, title, document, settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdateTheme.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.format);
        _data.push(this.theme);
        if (this.slug) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.slug) _data.push(this.slug);

        if (this.title) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.title) _data.push(this.title);

        if (this.document) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.document) _data.push(this.document);

        if (this.settings) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.settings) _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly format: TLString,
        readonly theme: InputThemeType,
        readonly slug: TLString | undefined,
        readonly title: TLString | undefined,
        readonly document: InputDocumentType | undefined,
        readonly settings: TLVector<API.InputThemeSettings> | undefined
      ) {}
    }
  }

  export namespace account {
    export class SaveTheme implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xf257106c);

      static deserialized(_data: ByteStream): SaveTheme | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveTheme.cons))
          return undefined;

        const theme = TLObject.deserialized(_data) as InputThemeType;
        if (!theme) return undefined;
        const unsave = TLObject.deserialized(_data) as BoolType;
        if (!unsave) return undefined;

        return new SaveTheme(theme, unsave);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveTheme.cons];

        _data.push(this.theme);
        _data.push(this.unsave);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly theme: InputThemeType, readonly unsave: BoolType) {}
    }
  }

  export namespace account {
    export class InstallTheme implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xc727bb3b);

      static deserialized(_data: ByteStream): InstallTheme | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(InstallTheme.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const dark = (flags.value & 1) !== 0;
        let theme: InputThemeType | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as InputThemeType;
          if (!obj) return undefined;
          theme = obj;
        }
        let format: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          format = obj;
        }
        let baseTheme: BaseThemeType | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLObject.deserialized(_data) as BaseThemeType;
          if (!obj) return undefined;
          baseTheme = obj;
        }

        return new InstallTheme(dark, theme, format, baseTheme);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [InstallTheme.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.dark) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.theme) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.theme) _data.push(this.theme);

        if (this.format) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.format) _data.push(this.format);

        if (this.baseTheme) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.baseTheme) _data.push(this.baseTheme);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly dark: boolean | undefined,
        readonly theme: InputThemeType | undefined,
        readonly format: TLString | undefined,
        readonly baseTheme: BaseThemeType | undefined
      ) {}
    }
  }

  export namespace account {
    export class GetTheme implements TLFunction<API.Theme> {
      static readonly cons = new TLInt(0x3a5869ec);

      static deserialized(_data: ByteStream): GetTheme | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetTheme.cons))
          return undefined;

        const format = TLString.deserialized(_data);
        if (!format) return undefined;
        const theme = TLObject.deserialized(_data) as InputThemeType;
        if (!theme) return undefined;

        return new GetTheme(format, theme);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetTheme.cons];

        _data.push(this.format);
        _data.push(this.theme);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly format: TLString, readonly theme: InputThemeType) {}
    }
  }

  export namespace account {
    export class GetThemes implements TLFunction<account.ThemesType> {
      static readonly cons = new TLInt(0x7206e458);

      static deserialized(_data: ByteStream): GetThemes | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetThemes.cons))
          return undefined;

        const format = TLString.deserialized(_data);
        if (!format) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetThemes(format, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetThemes.cons];

        _data.push(this.format);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly format: TLString, readonly hash: TLLong) {}
    }
  }

  export namespace account {
    export class SetContentSettings implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xb574b16b);

      static deserialized(_data: ByteStream): SetContentSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetContentSettings.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const sensitiveEnabled = (flags.value & 1) !== 0;

        return new SetContentSettings(sensitiveEnabled);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetContentSettings.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.sensitiveEnabled) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly sensitiveEnabled: boolean | undefined) {}
    }
  }

  export namespace account {
    export class GetContentSettings
      implements TLFunction<account.ContentSettings>
    {
      static readonly cons = new TLInt(0x8b9b4dae);

      static deserialized(_data: ByteStream): GetContentSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetContentSettings.cons))
          return undefined;

        return new GetContentSettings();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetContentSettings.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class GetMultiWallPapers
      implements TLFunction<TLVector<WallPaperType>>
    {
      static readonly cons = new TLInt(0x65ad71dc);

      static deserialized(_data: ByteStream): GetMultiWallPapers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetMultiWallPapers.cons))
          return undefined;

        const wallpapers = TLVector.deserialized(
          _data
        ) as TLVector<InputWallPaperType>;
        if (!wallpapers) return undefined;

        return new GetMultiWallPapers(wallpapers);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMultiWallPapers.cons];

        _data.push(this.wallpapers);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly wallpapers: TLVector<InputWallPaperType>) {}
    }
  }

  export namespace account {
    export class GetGlobalPrivacySettings
      implements TLFunction<API.GlobalPrivacySettings>
    {
      static readonly cons = new TLInt(0xeb2b4cf6);

      static deserialized(
        _data: ByteStream
      ): GetGlobalPrivacySettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetGlobalPrivacySettings.cons))
          return undefined;

        return new GetGlobalPrivacySettings();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetGlobalPrivacySettings.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class SetGlobalPrivacySettings
      implements TLFunction<API.GlobalPrivacySettings>
    {
      static readonly cons = new TLInt(0x1edaaac2);

      static deserialized(
        _data: ByteStream
      ): SetGlobalPrivacySettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetGlobalPrivacySettings.cons))
          return undefined;

        const settings = API.GlobalPrivacySettings.deserialized(_data);
        if (!settings) return undefined;

        return new SetGlobalPrivacySettings(settings);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetGlobalPrivacySettings.cons];

        _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly settings: API.GlobalPrivacySettings) {}
    }
  }

  export namespace account {
    export class ReportProfilePhoto implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xfa8cc6f5);

      static deserialized(_data: ByteStream): ReportProfilePhoto | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReportProfilePhoto.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const photoId = TLObject.deserialized(_data) as InputPhotoType;
        if (!photoId) return undefined;
        const reason = TLObject.deserialized(_data) as ReportReasonType;
        if (!reason) return undefined;
        const message = TLString.deserialized(_data);
        if (!message) return undefined;

        return new ReportProfilePhoto(peer, photoId, reason, message);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReportProfilePhoto.cons];

        _data.push(this.peer);
        _data.push(this.photoId);
        _data.push(this.reason);
        _data.push(this.message);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly photoId: InputPhotoType,
        readonly reason: ReportReasonType,
        readonly message: TLString
      ) {}
    }
  }

  export namespace account {
    export class ResetPassword
      implements TLFunction<account.ResetPasswordResultType>
    {
      static readonly cons = new TLInt(0x9308ce1b);

      static deserialized(_data: ByteStream): ResetPassword | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetPassword.cons))
          return undefined;

        return new ResetPassword();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetPassword.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class DeclinePasswordReset implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x4c9409f6);

      static deserialized(_data: ByteStream): DeclinePasswordReset | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeclinePasswordReset.cons))
          return undefined;

        return new DeclinePasswordReset();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeclinePasswordReset.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class GetChatThemes implements TLFunction<account.ThemesType> {
      static readonly cons = new TLInt(0xd638de89);

      static deserialized(_data: ByteStream): GetChatThemes | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetChatThemes.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetChatThemes(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetChatThemes.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace account {
    export class SetAuthorizationTTL implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xbf899aa0);

      static deserialized(_data: ByteStream): SetAuthorizationTTL | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetAuthorizationTTL.cons))
          return undefined;

        const authorizationTtlDays = TLInt.deserialized(_data);
        if (!authorizationTtlDays) return undefined;

        return new SetAuthorizationTTL(authorizationTtlDays);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetAuthorizationTTL.cons];

        _data.push(this.authorizationTtlDays);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly authorizationTtlDays: TLInt) {}
    }
  }

  export namespace account {
    export class ChangeAuthorizationSettings implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x40f48462);

      static deserialized(
        _data: ByteStream
      ): ChangeAuthorizationSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(ChangeAuthorizationSettings.cons)
        )
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const confirmed = (flags.value & 8) !== 0;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;
        let encryptedRequestsDisabled: BoolType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          encryptedRequestsDisabled = obj;
        }
        let callRequestsDisabled: BoolType | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          callRequestsDisabled = obj;
        }

        return new ChangeAuthorizationSettings(
          confirmed,
          hash,
          encryptedRequestsDisabled,
          callRequestsDisabled
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChangeAuthorizationSettings.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.confirmed) {
          flags.set(8);
        } else {
          flags.unset(8);
        }

        _data.push(this.hash);
        if (this.encryptedRequestsDisabled) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.encryptedRequestsDisabled)
          _data.push(this.encryptedRequestsDisabled);

        if (this.callRequestsDisabled) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.callRequestsDisabled) _data.push(this.callRequestsDisabled);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly confirmed: boolean | undefined,
        readonly hash: TLLong,
        readonly encryptedRequestsDisabled: BoolType | undefined,
        readonly callRequestsDisabled: BoolType | undefined
      ) {}
    }
  }

  export namespace account {
    export class GetSavedRingtones
      implements TLFunction<account.SavedRingtonesType>
    {
      static readonly cons = new TLInt(0xe1902288);

      static deserialized(_data: ByteStream): GetSavedRingtones | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSavedRingtones.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetSavedRingtones(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSavedRingtones.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace account {
    export class SaveRingtone implements TLFunction<account.SavedRingtoneType> {
      static readonly cons = new TLInt(0x3dea5b03);

      static deserialized(_data: ByteStream): SaveRingtone | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveRingtone.cons))
          return undefined;

        const id = TLObject.deserialized(_data) as InputDocumentType;
        if (!id) return undefined;
        const unsave = TLObject.deserialized(_data) as BoolType;
        if (!unsave) return undefined;

        return new SaveRingtone(id, unsave);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveRingtone.cons];

        _data.push(this.id);
        _data.push(this.unsave);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: InputDocumentType, readonly unsave: BoolType) {}
    }
  }

  export namespace account {
    export class UploadRingtone implements TLFunction<DocumentType> {
      static readonly cons = new TLInt(0x831a83a2);

      static deserialized(_data: ByteStream): UploadRingtone | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UploadRingtone.cons))
          return undefined;

        const file = TLObject.deserialized(_data) as InputFileType;
        if (!file) return undefined;
        const fileName = TLString.deserialized(_data);
        if (!fileName) return undefined;
        const mimeType = TLString.deserialized(_data);
        if (!mimeType) return undefined;

        return new UploadRingtone(file, fileName, mimeType);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UploadRingtone.cons];

        _data.push(this.file);
        _data.push(this.fileName);
        _data.push(this.mimeType);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly file: InputFileType,
        readonly fileName: TLString,
        readonly mimeType: TLString
      ) {}
    }
  }

  export namespace account {
    export class UpdateEmojiStatus implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xfbd3de6b);

      static deserialized(_data: ByteStream): UpdateEmojiStatus | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdateEmojiStatus.cons))
          return undefined;

        const emojiStatus = TLObject.deserialized(_data) as EmojiStatusType;
        if (!emojiStatus) return undefined;

        return new UpdateEmojiStatus(emojiStatus);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdateEmojiStatus.cons];

        _data.push(this.emojiStatus);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly emojiStatus: EmojiStatusType) {}
    }
  }

  export namespace account {
    export class GetDefaultEmojiStatuses
      implements TLFunction<account.EmojiStatusesType>
    {
      static readonly cons = new TLInt(0xd6753386);

      static deserialized(
        _data: ByteStream
      ): GetDefaultEmojiStatuses | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetDefaultEmojiStatuses.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetDefaultEmojiStatuses(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDefaultEmojiStatuses.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace account {
    export class GetRecentEmojiStatuses
      implements TLFunction<account.EmojiStatusesType>
    {
      static readonly cons = new TLInt(0xf578105);

      static deserialized(
        _data: ByteStream
      ): GetRecentEmojiStatuses | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetRecentEmojiStatuses.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetRecentEmojiStatuses(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetRecentEmojiStatuses.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace account {
    export class ClearRecentEmojiStatuses implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x18201aae);

      static deserialized(
        _data: ByteStream
      ): ClearRecentEmojiStatuses | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ClearRecentEmojiStatuses.cons))
          return undefined;

        return new ClearRecentEmojiStatuses();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ClearRecentEmojiStatuses.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class ReorderUsernames implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xef500eab);

      static deserialized(_data: ByteStream): ReorderUsernames | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReorderUsernames.cons))
          return undefined;

        const order = TLVector.deserialized(_data, TLString);
        if (!order) return undefined;

        return new ReorderUsernames(order);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReorderUsernames.cons];

        _data.push(this.order);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly order: TLVector<TLString>) {}
    }
  }

  export namespace account {
    export class ToggleUsername implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x58d6b376);

      static deserialized(_data: ByteStream): ToggleUsername | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleUsername.cons))
          return undefined;

        const username = TLString.deserialized(_data);
        if (!username) return undefined;
        const active = TLObject.deserialized(_data) as BoolType;
        if (!active) return undefined;

        return new ToggleUsername(username, active);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleUsername.cons];

        _data.push(this.username);
        _data.push(this.active);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly username: TLString, readonly active: BoolType) {}
    }
  }

  export namespace account {
    export class GetDefaultProfilePhotoEmojis
      implements TLFunction<EmojiListType>
    {
      static readonly cons = new TLInt(0xe2750328);

      static deserialized(
        _data: ByteStream
      ): GetDefaultProfilePhotoEmojis | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(GetDefaultProfilePhotoEmojis.cons)
        )
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetDefaultProfilePhotoEmojis(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          GetDefaultProfilePhotoEmojis.cons,
        ];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace account {
    export class GetDefaultGroupPhotoEmojis
      implements TLFunction<EmojiListType>
    {
      static readonly cons = new TLInt(0x915860ae);

      static deserialized(
        _data: ByteStream
      ): GetDefaultGroupPhotoEmojis | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(GetDefaultGroupPhotoEmojis.cons)
        )
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetDefaultGroupPhotoEmojis(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDefaultGroupPhotoEmojis.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace account {
    export class GetAutoSaveSettings
      implements TLFunction<account.AutoSaveSettings>
    {
      static readonly cons = new TLInt(0xadcbbcda);

      static deserialized(_data: ByteStream): GetAutoSaveSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAutoSaveSettings.cons))
          return undefined;

        return new GetAutoSaveSettings();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAutoSaveSettings.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class SaveAutoSaveSettings implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xd69b8361);

      static deserialized(_data: ByteStream): SaveAutoSaveSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveAutoSaveSettings.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const users = (flags.value & 1) !== 0;
        const chats = (flags.value & 2) !== 0;
        const broadcasts = (flags.value & 4) !== 0;
        let peer: InputPeerType | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          peer = obj;
        }
        const settings = API.AutoSaveSettings.deserialized(_data);
        if (!settings) return undefined;

        return new SaveAutoSaveSettings(
          users,
          chats,
          broadcasts,
          peer,
          settings
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveAutoSaveSettings.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.users) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.chats) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.broadcasts) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        if (this.peer) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.peer) _data.push(this.peer);

        _data.push(this.settings);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly users: boolean | undefined,
        readonly chats: boolean | undefined,
        readonly broadcasts: boolean | undefined,
        readonly peer: InputPeerType | undefined,
        readonly settings: API.AutoSaveSettings
      ) {}
    }
  }

  export namespace account {
    export class DeleteAutoSaveExceptions implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x53bc0020);

      static deserialized(
        _data: ByteStream
      ): DeleteAutoSaveExceptions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteAutoSaveExceptions.cons))
          return undefined;

        return new DeleteAutoSaveExceptions();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteAutoSaveExceptions.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace account {
    export class InvalidateSignInCodes implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xca8ae8ba);

      static deserialized(
        _data: ByteStream
      ): InvalidateSignInCodes | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(InvalidateSignInCodes.cons))
          return undefined;

        const codes = TLVector.deserialized(_data, TLString);
        if (!codes) return undefined;

        return new InvalidateSignInCodes(codes);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [InvalidateSignInCodes.cons];

        _data.push(this.codes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly codes: TLVector<TLString>) {}
    }
  }

  export namespace users {
    export class GetUsers implements TLFunction<TLVector<UserType>> {
      static readonly cons = new TLInt(0xd91a548);

      static deserialized(_data: ByteStream): GetUsers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetUsers.cons))
          return undefined;

        const id = TLVector.deserialized(_data) as TLVector<InputUserType>;
        if (!id) return undefined;

        return new GetUsers(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetUsers.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLVector<InputUserType>) {}
    }
  }

  export namespace users {
    export class GetFullUser implements TLFunction<users.UserFull> {
      static readonly cons = new TLInt(0xb60f5918);

      static deserialized(_data: ByteStream): GetFullUser | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetFullUser.cons))
          return undefined;

        const id = TLObject.deserialized(_data) as InputUserType;
        if (!id) return undefined;

        return new GetFullUser(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetFullUser.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: InputUserType) {}
    }
  }

  export namespace users {
    export class SetSecureValueErrors implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x90c894b5);

      static deserialized(_data: ByteStream): SetSecureValueErrors | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetSecureValueErrors.cons))
          return undefined;

        const id = TLObject.deserialized(_data) as InputUserType;
        if (!id) return undefined;
        const errors = TLVector.deserialized(
          _data
        ) as TLVector<SecureValueErrorType>;
        if (!errors) return undefined;

        return new SetSecureValueErrors(id, errors);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetSecureValueErrors.cons];

        _data.push(this.id);
        _data.push(this.errors);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly id: InputUserType,
        readonly errors: TLVector<SecureValueErrorType>
      ) {}
    }
  }

  export namespace contacts {
    export class GetContactIDs implements TLFunction<TLVector<TLInt>> {
      static readonly cons = new TLInt(0x7adc669d);

      static deserialized(_data: ByteStream): GetContactIDs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetContactIDs.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetContactIDs(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetContactIDs.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace contacts {
    export class GetStatuses
      implements TLFunction<TLVector<API.ContactStatus>>
    {
      static readonly cons = new TLInt(0xc4a353ee);

      static deserialized(_data: ByteStream): GetStatuses | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetStatuses.cons))
          return undefined;

        return new GetStatuses();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetStatuses.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace contacts {
    export class GetContacts implements TLFunction<contacts.ContactsType> {
      static readonly cons = new TLInt(0x5dd69e12);

      static deserialized(_data: ByteStream): GetContacts | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetContacts.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetContacts(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetContacts.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace contacts {
    export class ImportContacts
      implements TLFunction<contacts.ImportedContacts>
    {
      static readonly cons = new TLInt(0x2c800be5);

      static deserialized(_data: ByteStream): ImportContacts | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ImportContacts.cons))
          return undefined;

        const contacts = TLVector.deserialized(
          _data
        ) as TLVector<InputContactType>;
        if (!contacts) return undefined;

        return new ImportContacts(contacts);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ImportContacts.cons];

        _data.push(this.contacts);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly contacts: TLVector<InputContactType>) {}
    }
  }

  export namespace contacts {
    export class DeleteContacts implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x96a0e00);

      static deserialized(_data: ByteStream): DeleteContacts | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteContacts.cons))
          return undefined;

        const id = TLVector.deserialized(_data) as TLVector<InputUserType>;
        if (!id) return undefined;

        return new DeleteContacts(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteContacts.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLVector<InputUserType>) {}
    }
  }

  export namespace contacts {
    export class DeleteByPhones implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x1013fd9e);

      static deserialized(_data: ByteStream): DeleteByPhones | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteByPhones.cons))
          return undefined;

        const phones = TLVector.deserialized(_data, TLString);
        if (!phones) return undefined;

        return new DeleteByPhones(phones);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteByPhones.cons];

        _data.push(this.phones);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly phones: TLVector<TLString>) {}
    }
  }

  export namespace contacts {
    export class Block implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x2e2e8734);

      static deserialized(_data: ByteStream): Block | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Block.cons)) return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const myStoriesFrom = (flags.value & 1) !== 0;
        const id = TLObject.deserialized(_data) as InputPeerType;
        if (!id) return undefined;

        return new Block(myStoriesFrom, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Block.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.myStoriesFrom) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly myStoriesFrom: boolean | undefined,
        readonly id: InputPeerType
      ) {}
    }
  }

  export namespace contacts {
    export class Unblock implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xb550d328);

      static deserialized(_data: ByteStream): Unblock | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Unblock.cons)) return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const myStoriesFrom = (flags.value & 1) !== 0;
        const id = TLObject.deserialized(_data) as InputPeerType;
        if (!id) return undefined;

        return new Unblock(myStoriesFrom, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Unblock.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.myStoriesFrom) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly myStoriesFrom: boolean | undefined,
        readonly id: InputPeerType
      ) {}
    }
  }

  export namespace contacts {
    export class GetBlocked implements TLFunction<contacts.BlockedType> {
      static readonly cons = new TLInt(0x9a868f80);

      static deserialized(_data: ByteStream): GetBlocked | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetBlocked.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const myStoriesFrom = (flags.value & 1) !== 0;
        const offset = TLInt.deserialized(_data);
        if (!offset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetBlocked(myStoriesFrom, offset, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetBlocked.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.myStoriesFrom) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.offset);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly myStoriesFrom: boolean | undefined,
        readonly offset: TLInt,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace contacts {
    export class Search implements TLFunction<contacts.Found> {
      static readonly cons = new TLInt(0x11f812d8);

      static deserialized(_data: ByteStream): Search | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Search.cons)) return undefined;

        const q = TLString.deserialized(_data);
        if (!q) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new Search(q, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Search.cons];

        _data.push(this.q);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly q: TLString, readonly limit: TLInt) {}
    }
  }

  export namespace contacts {
    export class ResolveUsername implements TLFunction<contacts.ResolvedPeer> {
      static readonly cons = new TLInt(0xf93ccba3);

      static deserialized(_data: ByteStream): ResolveUsername | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResolveUsername.cons))
          return undefined;

        const username = TLString.deserialized(_data);
        if (!username) return undefined;

        return new ResolveUsername(username);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResolveUsername.cons];

        _data.push(this.username);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly username: TLString) {}
    }
  }

  export namespace contacts {
    export class GetTopPeers implements TLFunction<contacts.TopPeersType> {
      static readonly cons = new TLInt(0x973478b6);

      static deserialized(_data: ByteStream): GetTopPeers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetTopPeers.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const correspondents = (flags.value & 1) !== 0;
        const botsPm = (flags.value & 2) !== 0;
        const botsInline = (flags.value & 4) !== 0;
        const phoneCalls = (flags.value & 8) !== 0;
        const forwardUsers = (flags.value & 16) !== 0;
        const forwardChats = (flags.value & 32) !== 0;
        const groups = (flags.value & 1024) !== 0;
        const channels = (flags.value & 32768) !== 0;
        const offset = TLInt.deserialized(_data);
        if (!offset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetTopPeers(
          correspondents,
          botsPm,
          botsInline,
          phoneCalls,
          forwardUsers,
          forwardChats,
          groups,
          channels,
          offset,
          limit,
          hash
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetTopPeers.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.correspondents) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.botsPm) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.botsInline) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        if (this.phoneCalls) {
          flags.set(8);
        } else {
          flags.unset(8);
        }

        if (this.forwardUsers) {
          flags.set(16);
        } else {
          flags.unset(16);
        }

        if (this.forwardChats) {
          flags.set(32);
        } else {
          flags.unset(32);
        }

        if (this.groups) {
          flags.set(1024);
        } else {
          flags.unset(1024);
        }

        if (this.channels) {
          flags.set(32768);
        } else {
          flags.unset(32768);
        }

        _data.push(this.offset);
        _data.push(this.limit);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly correspondents: boolean | undefined,
        readonly botsPm: boolean | undefined,
        readonly botsInline: boolean | undefined,
        readonly phoneCalls: boolean | undefined,
        readonly forwardUsers: boolean | undefined,
        readonly forwardChats: boolean | undefined,
        readonly groups: boolean | undefined,
        readonly channels: boolean | undefined,
        readonly offset: TLInt,
        readonly limit: TLInt,
        readonly hash: TLLong
      ) {}
    }
  }

  export namespace contacts {
    export class ResetTopPeerRating implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x1ae373ac);

      static deserialized(_data: ByteStream): ResetTopPeerRating | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetTopPeerRating.cons))
          return undefined;

        const category = TLObject.deserialized(_data) as TopPeerCategoryType;
        if (!category) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new ResetTopPeerRating(category, peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetTopPeerRating.cons];

        _data.push(this.category);
        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly category: TopPeerCategoryType,
        readonly peer: InputPeerType
      ) {}
    }
  }

  export namespace contacts {
    export class ResetSaved implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x879537f1);

      static deserialized(_data: ByteStream): ResetSaved | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetSaved.cons))
          return undefined;

        return new ResetSaved();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetSaved.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace contacts {
    export class GetSaved implements TLFunction<TLVector<SavedContactType>> {
      static readonly cons = new TLInt(0x82f1e39f);

      static deserialized(_data: ByteStream): GetSaved | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSaved.cons))
          return undefined;

        return new GetSaved();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSaved.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace contacts {
    export class ToggleTopPeers implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x8514bdda);

      static deserialized(_data: ByteStream): ToggleTopPeers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleTopPeers.cons))
          return undefined;

        const enabled = TLObject.deserialized(_data) as BoolType;
        if (!enabled) return undefined;

        return new ToggleTopPeers(enabled);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleTopPeers.cons];

        _data.push(this.enabled);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly enabled: BoolType) {}
    }
  }

  export namespace contacts {
    export class AddContact implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xe8f463d0);

      static deserialized(_data: ByteStream): AddContact | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AddContact.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const addPhonePrivacyException = (flags.value & 1) !== 0;
        const id = TLObject.deserialized(_data) as InputUserType;
        if (!id) return undefined;
        const firstName = TLString.deserialized(_data);
        if (!firstName) return undefined;
        const lastName = TLString.deserialized(_data);
        if (!lastName) return undefined;
        const phone = TLString.deserialized(_data);
        if (!phone) return undefined;

        return new AddContact(
          addPhonePrivacyException,
          id,
          firstName,
          lastName,
          phone
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AddContact.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.addPhonePrivacyException) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.id);
        _data.push(this.firstName);
        _data.push(this.lastName);
        _data.push(this.phone);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly addPhonePrivacyException: boolean | undefined,
        readonly id: InputUserType,
        readonly firstName: TLString,
        readonly lastName: TLString,
        readonly phone: TLString
      ) {}
    }
  }

  export namespace contacts {
    export class AcceptContact implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xf831a20f);

      static deserialized(_data: ByteStream): AcceptContact | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AcceptContact.cons))
          return undefined;

        const id = TLObject.deserialized(_data) as InputUserType;
        if (!id) return undefined;

        return new AcceptContact(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AcceptContact.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: InputUserType) {}
    }
  }

  export namespace contacts {
    export class GetLocated implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xd348bc44);

      static deserialized(_data: ByteStream): GetLocated | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetLocated.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const background = (flags.value & 2) !== 0;
        const geoPoint = TLObject.deserialized(_data) as InputGeoPointType;
        if (!geoPoint) return undefined;
        let selfExpires: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          selfExpires = obj;
        }

        return new GetLocated(background, geoPoint, selfExpires);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetLocated.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.background) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.geoPoint);
        if (this.selfExpires) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.selfExpires) _data.push(this.selfExpires);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly background: boolean | undefined,
        readonly geoPoint: InputGeoPointType,
        readonly selfExpires: TLInt | undefined
      ) {}
    }
  }

  export namespace contacts {
    export class BlockFromReplies implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x29a8962c);

      static deserialized(_data: ByteStream): BlockFromReplies | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(BlockFromReplies.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const deleteMessage = (flags.value & 1) !== 0;
        const deleteHistory = (flags.value & 2) !== 0;
        const reportSpam = (flags.value & 4) !== 0;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;

        return new BlockFromReplies(
          deleteMessage,
          deleteHistory,
          reportSpam,
          msgId
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [BlockFromReplies.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.deleteMessage) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.deleteHistory) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.reportSpam) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.msgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly deleteMessage: boolean | undefined,
        readonly deleteHistory: boolean | undefined,
        readonly reportSpam: boolean | undefined,
        readonly msgId: TLInt
      ) {}
    }
  }

  export namespace contacts {
    export class ResolvePhone implements TLFunction<contacts.ResolvedPeer> {
      static readonly cons = new TLInt(0x8af94344);

      static deserialized(_data: ByteStream): ResolvePhone | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResolvePhone.cons))
          return undefined;

        const phone = TLString.deserialized(_data);
        if (!phone) return undefined;

        return new ResolvePhone(phone);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResolvePhone.cons];

        _data.push(this.phone);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly phone: TLString) {}
    }
  }

  export namespace contacts {
    export class ExportContactToken
      implements TLFunction<API.ExportedContactToken>
    {
      static readonly cons = new TLInt(0xf8654027);

      static deserialized(_data: ByteStream): ExportContactToken | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportContactToken.cons))
          return undefined;

        return new ExportContactToken();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportContactToken.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace contacts {
    export class ImportContactToken implements TLFunction<UserType> {
      static readonly cons = new TLInt(0x13005788);

      static deserialized(_data: ByteStream): ImportContactToken | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ImportContactToken.cons))
          return undefined;

        const token = TLString.deserialized(_data);
        if (!token) return undefined;

        return new ImportContactToken(token);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ImportContactToken.cons];

        _data.push(this.token);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly token: TLString) {}
    }
  }

  export namespace contacts {
    export class EditCloseFriends implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xba6705f0);

      static deserialized(_data: ByteStream): EditCloseFriends | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditCloseFriends.cons))
          return undefined;

        const id = TLVector.deserialized(_data, TLLong);
        if (!id) return undefined;

        return new EditCloseFriends(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditCloseFriends.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLVector<TLLong>) {}
    }
  }

  export namespace contacts {
    export class SetBlocked implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x94c65c76);

      static deserialized(_data: ByteStream): SetBlocked | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetBlocked.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const myStoriesFrom = (flags.value & 1) !== 0;
        const id = TLVector.deserialized(_data) as TLVector<InputPeerType>;
        if (!id) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new SetBlocked(myStoriesFrom, id, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetBlocked.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.myStoriesFrom) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.id);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly myStoriesFrom: boolean | undefined,
        readonly id: TLVector<InputPeerType>,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class GetMessages implements TLFunction<messages.MessagesType> {
      static readonly cons = new TLInt(0x63c66506);

      static deserialized(_data: ByteStream): GetMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetMessages.cons))
          return undefined;

        const id = TLVector.deserialized(_data) as TLVector<InputMessageType>;
        if (!id) return undefined;

        return new GetMessages(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMessages.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLVector<InputMessageType>) {}
    }
  }

  export namespace messages {
    export class GetDialogs implements TLFunction<messages.DialogsType> {
      static readonly cons = new TLInt(0xa0f4cb4f);

      static deserialized(_data: ByteStream): GetDialogs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetDialogs.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const excludePinned = (flags.value & 1) !== 0;
        let folderId: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          folderId = obj;
        }
        const offsetDate = TLInt.deserialized(_data);
        if (!offsetDate) return undefined;
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const offsetPeer = TLObject.deserialized(_data) as InputPeerType;
        if (!offsetPeer) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetDialogs(
          excludePinned,
          folderId,
          offsetDate,
          offsetId,
          offsetPeer,
          limit,
          hash
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDialogs.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.excludePinned) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.folderId) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.folderId) _data.push(this.folderId);

        _data.push(this.offsetDate);
        _data.push(this.offsetId);
        _data.push(this.offsetPeer);
        _data.push(this.limit);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly excludePinned: boolean | undefined,
        readonly folderId: TLInt | undefined,
        readonly offsetDate: TLInt,
        readonly offsetId: TLInt,
        readonly offsetPeer: InputPeerType,
        readonly limit: TLInt,
        readonly hash: TLLong
      ) {}
    }
  }

  export namespace messages {
    export class GetHistory implements TLFunction<messages.MessagesType> {
      static readonly cons = new TLInt(0x4423e6c5);

      static deserialized(_data: ByteStream): GetHistory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetHistory.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const offsetDate = TLInt.deserialized(_data);
        if (!offsetDate) return undefined;
        const addOffset = TLInt.deserialized(_data);
        if (!addOffset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const maxId = TLInt.deserialized(_data);
        if (!maxId) return undefined;
        const minId = TLInt.deserialized(_data);
        if (!minId) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetHistory(
          peer,
          offsetId,
          offsetDate,
          addOffset,
          limit,
          maxId,
          minId,
          hash
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetHistory.cons];

        _data.push(this.peer);
        _data.push(this.offsetId);
        _data.push(this.offsetDate);
        _data.push(this.addOffset);
        _data.push(this.limit);
        _data.push(this.maxId);
        _data.push(this.minId);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly offsetId: TLInt,
        readonly offsetDate: TLInt,
        readonly addOffset: TLInt,
        readonly limit: TLInt,
        readonly maxId: TLInt,
        readonly minId: TLInt,
        readonly hash: TLLong
      ) {}
    }
  }

  export namespace messages {
    export class Search implements TLFunction<messages.MessagesType> {
      static readonly cons = new TLInt(0xa0fda762);

      static deserialized(_data: ByteStream): Search | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Search.cons)) return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const q = TLString.deserialized(_data);
        if (!q) return undefined;
        let fromId: InputPeerType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          fromId = obj;
        }
        let topMsgId: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          topMsgId = obj;
        }
        const filter = TLObject.deserialized(_data) as MessagesFilterType;
        if (!filter) return undefined;
        const minDate = TLInt.deserialized(_data);
        if (!minDate) return undefined;
        const maxDate = TLInt.deserialized(_data);
        if (!maxDate) return undefined;
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const addOffset = TLInt.deserialized(_data);
        if (!addOffset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const maxId = TLInt.deserialized(_data);
        if (!maxId) return undefined;
        const minId = TLInt.deserialized(_data);
        if (!minId) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new Search(
          peer,
          q,
          fromId,
          topMsgId,
          filter,
          minDate,
          maxDate,
          offsetId,
          addOffset,
          limit,
          maxId,
          minId,
          hash
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Search.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        _data.push(this.q);
        if (this.fromId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.fromId) _data.push(this.fromId);

        if (this.topMsgId) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.topMsgId) _data.push(this.topMsgId);

        _data.push(this.filter);
        _data.push(this.minDate);
        _data.push(this.maxDate);
        _data.push(this.offsetId);
        _data.push(this.addOffset);
        _data.push(this.limit);
        _data.push(this.maxId);
        _data.push(this.minId);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly q: TLString,
        readonly fromId: InputPeerType | undefined,
        readonly topMsgId: TLInt | undefined,
        readonly filter: MessagesFilterType,
        readonly minDate: TLInt,
        readonly maxDate: TLInt,
        readonly offsetId: TLInt,
        readonly addOffset: TLInt,
        readonly limit: TLInt,
        readonly maxId: TLInt,
        readonly minId: TLInt,
        readonly hash: TLLong
      ) {}
    }
  }

  export namespace messages {
    export class ReadHistory implements TLFunction<messages.AffectedMessages> {
      static readonly cons = new TLInt(0xe306d3a);

      static deserialized(_data: ByteStream): ReadHistory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReadHistory.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const maxId = TLInt.deserialized(_data);
        if (!maxId) return undefined;

        return new ReadHistory(peer, maxId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReadHistory.cons];

        _data.push(this.peer);
        _data.push(this.maxId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly maxId: TLInt) {}
    }
  }

  export namespace messages {
    export class DeleteHistory implements TLFunction<messages.AffectedHistory> {
      static readonly cons = new TLInt(0xb08f922a);

      static deserialized(_data: ByteStream): DeleteHistory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteHistory.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const justClear = (flags.value & 1) !== 0;
        const revoke = (flags.value & 2) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const maxId = TLInt.deserialized(_data);
        if (!maxId) return undefined;
        let minDate: TLInt | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          minDate = obj;
        }
        let maxDate: TLInt | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          maxDate = obj;
        }

        return new DeleteHistory(
          justClear,
          revoke,
          peer,
          maxId,
          minDate,
          maxDate
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteHistory.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.justClear) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.revoke) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.peer);
        _data.push(this.maxId);
        if (this.minDate) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.minDate) _data.push(this.minDate);

        if (this.maxDate) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.maxDate) _data.push(this.maxDate);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly justClear: boolean | undefined,
        readonly revoke: boolean | undefined,
        readonly peer: InputPeerType,
        readonly maxId: TLInt,
        readonly minDate: TLInt | undefined,
        readonly maxDate: TLInt | undefined
      ) {}
    }
  }

  export namespace messages {
    export class DeleteMessages
      implements TLFunction<messages.AffectedMessages>
    {
      static readonly cons = new TLInt(0xe58e95d2);

      static deserialized(_data: ByteStream): DeleteMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteMessages.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const revoke = (flags.value & 1) !== 0;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new DeleteMessages(revoke, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteMessages.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.revoke) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly revoke: boolean | undefined,
        readonly id: TLVector<TLInt>
      ) {}
    }
  }

  export namespace messages {
    export class ReceivedMessages
      implements TLFunction<TLVector<API.ReceivedNotifyMessage>>
    {
      static readonly cons = new TLInt(0x5a954c0);

      static deserialized(_data: ByteStream): ReceivedMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReceivedMessages.cons))
          return undefined;

        const maxId = TLInt.deserialized(_data);
        if (!maxId) return undefined;

        return new ReceivedMessages(maxId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReceivedMessages.cons];

        _data.push(this.maxId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly maxId: TLInt) {}
    }
  }

  export namespace messages {
    export class SetTyping implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x58943ee2);

      static deserialized(_data: ByteStream): SetTyping | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetTyping.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let topMsgId: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          topMsgId = obj;
        }
        const action = TLObject.deserialized(_data) as SendMessageActionType;
        if (!action) return undefined;

        return new SetTyping(peer, topMsgId, action);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetTyping.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        if (this.topMsgId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.topMsgId) _data.push(this.topMsgId);

        _data.push(this.action);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly topMsgId: TLInt | undefined,
        readonly action: SendMessageActionType
      ) {}
    }
  }

  export namespace messages {
    export class SendMessage implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x280d096f);

      static deserialized(_data: ByteStream): SendMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendMessage.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const noWebpage = (flags.value & 2) !== 0;
        const silent = (flags.value & 32) !== 0;
        const background = (flags.value & 64) !== 0;
        const clearDraft = (flags.value & 128) !== 0;
        const noforwards = (flags.value & 16384) !== 0;
        const updateStickersetsOrder = (flags.value & 32768) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let replyTo: InputReplyToType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputReplyToType;
          if (!obj) return undefined;
          replyTo = obj;
        }
        const message = TLString.deserialized(_data);
        if (!message) return undefined;
        const randomId = TLLong.deserialized(_data);
        if (!randomId) return undefined;
        let replyMarkup: ReplyMarkupType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as ReplyMarkupType;
          if (!obj) return undefined;
          replyMarkup = obj;
        }
        let entities: TLVector<MessageEntityType> | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLVector.deserialized(
            _data
          ) as TLVector<MessageEntityType>;
          if (!obj) return undefined;
          entities = obj;
        }
        let scheduleDate: TLInt | undefined;
        if ((flags.value & 1024) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          scheduleDate = obj;
        }
        let sendAs: InputPeerType | undefined;
        if ((flags.value & 8192) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          sendAs = obj;
        }

        return new SendMessage(
          noWebpage,
          silent,
          background,
          clearDraft,
          noforwards,
          updateStickersetsOrder,
          peer,
          replyTo,
          message,
          randomId,
          replyMarkup,
          entities,
          scheduleDate,
          sendAs
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendMessage.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.noWebpage) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.silent) {
          flags.set(32);
        } else {
          flags.unset(32);
        }

        if (this.background) {
          flags.set(64);
        } else {
          flags.unset(64);
        }

        if (this.clearDraft) {
          flags.set(128);
        } else {
          flags.unset(128);
        }

        if (this.noforwards) {
          flags.set(16384);
        } else {
          flags.unset(16384);
        }

        if (this.updateStickersetsOrder) {
          flags.set(32768);
        } else {
          flags.unset(32768);
        }

        _data.push(this.peer);
        if (this.replyTo) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.replyTo) _data.push(this.replyTo);

        _data.push(this.message);
        _data.push(this.randomId);
        if (this.replyMarkup) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.replyMarkup) _data.push(this.replyMarkup);

        if (this.entities) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.entities) _data.push(this.entities);

        if (this.scheduleDate) {
          flags.set(1024);
        } else {
          flags.unset(1024);
        }
        if (this.scheduleDate) _data.push(this.scheduleDate);

        if (this.sendAs) {
          flags.set(8192);
        } else {
          flags.unset(8192);
        }
        if (this.sendAs) _data.push(this.sendAs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly noWebpage: boolean | undefined,
        readonly silent: boolean | undefined,
        readonly background: boolean | undefined,
        readonly clearDraft: boolean | undefined,
        readonly noforwards: boolean | undefined,
        readonly updateStickersetsOrder: boolean | undefined,
        readonly peer: InputPeerType,
        readonly replyTo: InputReplyToType | undefined,
        readonly message: TLString,
        readonly randomId: TLLong,
        readonly replyMarkup: ReplyMarkupType | undefined,
        readonly entities: TLVector<MessageEntityType> | undefined,
        readonly scheduleDate: TLInt | undefined,
        readonly sendAs: InputPeerType | undefined
      ) {}
    }
  }

  export namespace messages {
    export class SendMedia implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x72ccc23d);

      static deserialized(_data: ByteStream): SendMedia | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendMedia.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const silent = (flags.value & 32) !== 0;
        const background = (flags.value & 64) !== 0;
        const clearDraft = (flags.value & 128) !== 0;
        const noforwards = (flags.value & 16384) !== 0;
        const updateStickersetsOrder = (flags.value & 32768) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let replyTo: InputReplyToType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputReplyToType;
          if (!obj) return undefined;
          replyTo = obj;
        }
        const media = TLObject.deserialized(_data) as InputMediaType;
        if (!media) return undefined;
        const message = TLString.deserialized(_data);
        if (!message) return undefined;
        const randomId = TLLong.deserialized(_data);
        if (!randomId) return undefined;
        let replyMarkup: ReplyMarkupType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as ReplyMarkupType;
          if (!obj) return undefined;
          replyMarkup = obj;
        }
        let entities: TLVector<MessageEntityType> | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLVector.deserialized(
            _data
          ) as TLVector<MessageEntityType>;
          if (!obj) return undefined;
          entities = obj;
        }
        let scheduleDate: TLInt | undefined;
        if ((flags.value & 1024) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          scheduleDate = obj;
        }
        let sendAs: InputPeerType | undefined;
        if ((flags.value & 8192) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          sendAs = obj;
        }

        return new SendMedia(
          silent,
          background,
          clearDraft,
          noforwards,
          updateStickersetsOrder,
          peer,
          replyTo,
          media,
          message,
          randomId,
          replyMarkup,
          entities,
          scheduleDate,
          sendAs
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendMedia.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.silent) {
          flags.set(32);
        } else {
          flags.unset(32);
        }

        if (this.background) {
          flags.set(64);
        } else {
          flags.unset(64);
        }

        if (this.clearDraft) {
          flags.set(128);
        } else {
          flags.unset(128);
        }

        if (this.noforwards) {
          flags.set(16384);
        } else {
          flags.unset(16384);
        }

        if (this.updateStickersetsOrder) {
          flags.set(32768);
        } else {
          flags.unset(32768);
        }

        _data.push(this.peer);
        if (this.replyTo) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.replyTo) _data.push(this.replyTo);

        _data.push(this.media);
        _data.push(this.message);
        _data.push(this.randomId);
        if (this.replyMarkup) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.replyMarkup) _data.push(this.replyMarkup);

        if (this.entities) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.entities) _data.push(this.entities);

        if (this.scheduleDate) {
          flags.set(1024);
        } else {
          flags.unset(1024);
        }
        if (this.scheduleDate) _data.push(this.scheduleDate);

        if (this.sendAs) {
          flags.set(8192);
        } else {
          flags.unset(8192);
        }
        if (this.sendAs) _data.push(this.sendAs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly silent: boolean | undefined,
        readonly background: boolean | undefined,
        readonly clearDraft: boolean | undefined,
        readonly noforwards: boolean | undefined,
        readonly updateStickersetsOrder: boolean | undefined,
        readonly peer: InputPeerType,
        readonly replyTo: InputReplyToType | undefined,
        readonly media: InputMediaType,
        readonly message: TLString,
        readonly randomId: TLLong,
        readonly replyMarkup: ReplyMarkupType | undefined,
        readonly entities: TLVector<MessageEntityType> | undefined,
        readonly scheduleDate: TLInt | undefined,
        readonly sendAs: InputPeerType | undefined
      ) {}
    }
  }

  export namespace messages {
    export class ForwardMessages implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xc661bbc4);

      static deserialized(_data: ByteStream): ForwardMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ForwardMessages.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const silent = (flags.value & 32) !== 0;
        const background = (flags.value & 64) !== 0;
        const withMyScore = (flags.value & 256) !== 0;
        const dropAuthor = (flags.value & 2048) !== 0;
        const dropMediaCaptions = (flags.value & 4096) !== 0;
        const noforwards = (flags.value & 16384) !== 0;
        const fromPeer = TLObject.deserialized(_data) as InputPeerType;
        if (!fromPeer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;
        const randomId = TLVector.deserialized(_data, TLLong);
        if (!randomId) return undefined;
        const toPeer = TLObject.deserialized(_data) as InputPeerType;
        if (!toPeer) return undefined;
        let topMsgId: TLInt | undefined;
        if ((flags.value & 512) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          topMsgId = obj;
        }
        let scheduleDate: TLInt | undefined;
        if ((flags.value & 1024) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          scheduleDate = obj;
        }
        let sendAs: InputPeerType | undefined;
        if ((flags.value & 8192) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          sendAs = obj;
        }

        return new ForwardMessages(
          silent,
          background,
          withMyScore,
          dropAuthor,
          dropMediaCaptions,
          noforwards,
          fromPeer,
          id,
          randomId,
          toPeer,
          topMsgId,
          scheduleDate,
          sendAs
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ForwardMessages.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.silent) {
          flags.set(32);
        } else {
          flags.unset(32);
        }

        if (this.background) {
          flags.set(64);
        } else {
          flags.unset(64);
        }

        if (this.withMyScore) {
          flags.set(256);
        } else {
          flags.unset(256);
        }

        if (this.dropAuthor) {
          flags.set(2048);
        } else {
          flags.unset(2048);
        }

        if (this.dropMediaCaptions) {
          flags.set(4096);
        } else {
          flags.unset(4096);
        }

        if (this.noforwards) {
          flags.set(16384);
        } else {
          flags.unset(16384);
        }

        _data.push(this.fromPeer);
        _data.push(this.id);
        _data.push(this.randomId);
        _data.push(this.toPeer);
        if (this.topMsgId) {
          flags.set(512);
        } else {
          flags.unset(512);
        }
        if (this.topMsgId) _data.push(this.topMsgId);

        if (this.scheduleDate) {
          flags.set(1024);
        } else {
          flags.unset(1024);
        }
        if (this.scheduleDate) _data.push(this.scheduleDate);

        if (this.sendAs) {
          flags.set(8192);
        } else {
          flags.unset(8192);
        }
        if (this.sendAs) _data.push(this.sendAs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly silent: boolean | undefined,
        readonly background: boolean | undefined,
        readonly withMyScore: boolean | undefined,
        readonly dropAuthor: boolean | undefined,
        readonly dropMediaCaptions: boolean | undefined,
        readonly noforwards: boolean | undefined,
        readonly fromPeer: InputPeerType,
        readonly id: TLVector<TLInt>,
        readonly randomId: TLVector<TLLong>,
        readonly toPeer: InputPeerType,
        readonly topMsgId: TLInt | undefined,
        readonly scheduleDate: TLInt | undefined,
        readonly sendAs: InputPeerType | undefined
      ) {}
    }
  }

  export namespace messages {
    export class ReportSpam implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xcf1592db);

      static deserialized(_data: ByteStream): ReportSpam | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReportSpam.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new ReportSpam(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReportSpam.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace messages {
    export class GetPeerSettings implements TLFunction<messages.PeerSettings> {
      static readonly cons = new TLInt(0xefd9a6a2);

      static deserialized(_data: ByteStream): GetPeerSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPeerSettings.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new GetPeerSettings(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPeerSettings.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace messages {
    export class Report implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x8953ab4e);

      static deserialized(_data: ByteStream): Report | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Report.cons)) return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;
        const reason = TLObject.deserialized(_data) as ReportReasonType;
        if (!reason) return undefined;
        const message = TLString.deserialized(_data);
        if (!message) return undefined;

        return new Report(peer, id, reason, message);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Report.cons];

        _data.push(this.peer);
        _data.push(this.id);
        _data.push(this.reason);
        _data.push(this.message);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly id: TLVector<TLInt>,
        readonly reason: ReportReasonType,
        readonly message: TLString
      ) {}
    }
  }

  export namespace messages {
    export class GetChats implements TLFunction<messages.ChatsType> {
      static readonly cons = new TLInt(0x49e9528f);

      static deserialized(_data: ByteStream): GetChats | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetChats.cons))
          return undefined;

        const id = TLVector.deserialized(_data, TLLong);
        if (!id) return undefined;

        return new GetChats(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetChats.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLVector<TLLong>) {}
    }
  }

  export namespace messages {
    export class GetFullChat implements TLFunction<messages.ChatFull> {
      static readonly cons = new TLInt(0xaeb00b34);

      static deserialized(_data: ByteStream): GetFullChat | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetFullChat.cons))
          return undefined;

        const chatId = TLLong.deserialized(_data);
        if (!chatId) return undefined;

        return new GetFullChat(chatId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetFullChat.cons];

        _data.push(this.chatId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly chatId: TLLong) {}
    }
  }

  export namespace messages {
    export class EditChatTitle implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x73783ffd);

      static deserialized(_data: ByteStream): EditChatTitle | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditChatTitle.cons))
          return undefined;

        const chatId = TLLong.deserialized(_data);
        if (!chatId) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;

        return new EditChatTitle(chatId, title);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditChatTitle.cons];

        _data.push(this.chatId);
        _data.push(this.title);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly chatId: TLLong, readonly title: TLString) {}
    }
  }

  export namespace messages {
    export class EditChatPhoto implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x35ddd674);

      static deserialized(_data: ByteStream): EditChatPhoto | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditChatPhoto.cons))
          return undefined;

        const chatId = TLLong.deserialized(_data);
        if (!chatId) return undefined;
        const photo = TLObject.deserialized(_data) as InputChatPhotoType;
        if (!photo) return undefined;

        return new EditChatPhoto(chatId, photo);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditChatPhoto.cons];

        _data.push(this.chatId);
        _data.push(this.photo);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly chatId: TLLong,
        readonly photo: InputChatPhotoType
      ) {}
    }
  }

  export namespace messages {
    export class AddChatUser implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xf24753e3);

      static deserialized(_data: ByteStream): AddChatUser | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AddChatUser.cons))
          return undefined;

        const chatId = TLLong.deserialized(_data);
        if (!chatId) return undefined;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const fwdLimit = TLInt.deserialized(_data);
        if (!fwdLimit) return undefined;

        return new AddChatUser(chatId, userId, fwdLimit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AddChatUser.cons];

        _data.push(this.chatId);
        _data.push(this.userId);
        _data.push(this.fwdLimit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly chatId: TLLong,
        readonly userId: InputUserType,
        readonly fwdLimit: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class DeleteChatUser implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xa2185cab);

      static deserialized(_data: ByteStream): DeleteChatUser | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteChatUser.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const revokeHistory = (flags.value & 1) !== 0;
        const chatId = TLLong.deserialized(_data);
        if (!chatId) return undefined;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;

        return new DeleteChatUser(revokeHistory, chatId, userId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteChatUser.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.revokeHistory) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.chatId);
        _data.push(this.userId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly revokeHistory: boolean | undefined,
        readonly chatId: TLLong,
        readonly userId: InputUserType
      ) {}
    }
  }

  export namespace messages {
    export class CreateChat implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x34a818);

      static deserialized(_data: ByteStream): CreateChat | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CreateChat.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<InputUserType>;
        if (!users) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;
        let ttlPeriod: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          ttlPeriod = obj;
        }

        return new CreateChat(users, title, ttlPeriod);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CreateChat.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.users);
        _data.push(this.title);
        if (this.ttlPeriod) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.ttlPeriod) _data.push(this.ttlPeriod);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly users: TLVector<InputUserType>,
        readonly title: TLString,
        readonly ttlPeriod: TLInt | undefined
      ) {}
    }
  }

  export namespace messages {
    export class GetDhConfig implements TLFunction<messages.DhConfigType> {
      static readonly cons = new TLInt(0x26cf8950);

      static deserialized(_data: ByteStream): GetDhConfig | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetDhConfig.cons))
          return undefined;

        const version = TLInt.deserialized(_data);
        if (!version) return undefined;
        const randomLength = TLInt.deserialized(_data);
        if (!randomLength) return undefined;

        return new GetDhConfig(version, randomLength);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDhConfig.cons];

        _data.push(this.version);
        _data.push(this.randomLength);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly version: TLInt, readonly randomLength: TLInt) {}
    }
  }

  export namespace messages {
    export class RequestEncryption implements TLFunction<EncryptedChatType> {
      static readonly cons = new TLInt(0xf64daf43);

      static deserialized(_data: ByteStream): RequestEncryption | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RequestEncryption.cons))
          return undefined;

        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const randomId = TLInt.deserialized(_data);
        if (!randomId) return undefined;
        const gA = TLBytes.deserialized(_data);
        if (!gA) return undefined;

        return new RequestEncryption(userId, randomId, gA);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RequestEncryption.cons];

        _data.push(this.userId);
        _data.push(this.randomId);
        _data.push(this.gA);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly userId: InputUserType,
        readonly randomId: TLInt,
        readonly gA: TLBytes
      ) {}
    }
  }

  export namespace messages {
    export class AcceptEncryption implements TLFunction<EncryptedChatType> {
      static readonly cons = new TLInt(0x3dbc0415);

      static deserialized(_data: ByteStream): AcceptEncryption | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AcceptEncryption.cons))
          return undefined;

        const peer = API.InputEncryptedChat.deserialized(_data);
        if (!peer) return undefined;
        const gB = TLBytes.deserialized(_data);
        if (!gB) return undefined;
        const keyFingerprint = TLLong.deserialized(_data);
        if (!keyFingerprint) return undefined;

        return new AcceptEncryption(peer, gB, keyFingerprint);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AcceptEncryption.cons];

        _data.push(this.peer);
        _data.push(this.gB);
        _data.push(this.keyFingerprint);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: API.InputEncryptedChat,
        readonly gB: TLBytes,
        readonly keyFingerprint: TLLong
      ) {}
    }
  }

  export namespace messages {
    export class DiscardEncryption implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xf393aea0);

      static deserialized(_data: ByteStream): DiscardEncryption | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DiscardEncryption.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const deleteHistory = (flags.value & 1) !== 0;
        const chatId = TLInt.deserialized(_data);
        if (!chatId) return undefined;

        return new DiscardEncryption(deleteHistory, chatId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DiscardEncryption.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.deleteHistory) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.chatId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly deleteHistory: boolean | undefined,
        readonly chatId: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class SetEncryptedTyping implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x791451ed);

      static deserialized(_data: ByteStream): SetEncryptedTyping | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetEncryptedTyping.cons))
          return undefined;

        const peer = API.InputEncryptedChat.deserialized(_data);
        if (!peer) return undefined;
        const typing = TLObject.deserialized(_data) as BoolType;
        if (!typing) return undefined;

        return new SetEncryptedTyping(peer, typing);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetEncryptedTyping.cons];

        _data.push(this.peer);
        _data.push(this.typing);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: API.InputEncryptedChat,
        readonly typing: BoolType
      ) {}
    }
  }

  export namespace messages {
    export class ReadEncryptedHistory implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x7f4b690a);

      static deserialized(_data: ByteStream): ReadEncryptedHistory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReadEncryptedHistory.cons))
          return undefined;

        const peer = API.InputEncryptedChat.deserialized(_data);
        if (!peer) return undefined;
        const maxDate = TLInt.deserialized(_data);
        if (!maxDate) return undefined;

        return new ReadEncryptedHistory(peer, maxDate);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReadEncryptedHistory.cons];

        _data.push(this.peer);
        _data.push(this.maxDate);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: API.InputEncryptedChat,
        readonly maxDate: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class SendEncrypted
      implements TLFunction<messages.SentEncryptedMessageType>
    {
      static readonly cons = new TLInt(0x44fa7a15);

      static deserialized(_data: ByteStream): SendEncrypted | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendEncrypted.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const silent = (flags.value & 1) !== 0;
        const peer = API.InputEncryptedChat.deserialized(_data);
        if (!peer) return undefined;
        const randomId = TLLong.deserialized(_data);
        if (!randomId) return undefined;
        const data = TLBytes.deserialized(_data);
        if (!data) return undefined;

        return new SendEncrypted(silent, peer, randomId, data);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendEncrypted.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.silent) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);
        _data.push(this.randomId);
        _data.push(this.data);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly silent: boolean | undefined,
        readonly peer: API.InputEncryptedChat,
        readonly randomId: TLLong,
        readonly data: TLBytes
      ) {}
    }
  }

  export namespace messages {
    export class SendEncryptedFile
      implements TLFunction<messages.SentEncryptedMessageType>
    {
      static readonly cons = new TLInt(0x5559481d);

      static deserialized(_data: ByteStream): SendEncryptedFile | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendEncryptedFile.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const silent = (flags.value & 1) !== 0;
        const peer = API.InputEncryptedChat.deserialized(_data);
        if (!peer) return undefined;
        const randomId = TLLong.deserialized(_data);
        if (!randomId) return undefined;
        const data = TLBytes.deserialized(_data);
        if (!data) return undefined;
        const file = TLObject.deserialized(_data) as InputEncryptedFileType;
        if (!file) return undefined;

        return new SendEncryptedFile(silent, peer, randomId, data, file);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendEncryptedFile.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.silent) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);
        _data.push(this.randomId);
        _data.push(this.data);
        _data.push(this.file);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly silent: boolean | undefined,
        readonly peer: API.InputEncryptedChat,
        readonly randomId: TLLong,
        readonly data: TLBytes,
        readonly file: InputEncryptedFileType
      ) {}
    }
  }

  export namespace messages {
    export class SendEncryptedService
      implements TLFunction<messages.SentEncryptedMessageType>
    {
      static readonly cons = new TLInt(0x32d439a4);

      static deserialized(_data: ByteStream): SendEncryptedService | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendEncryptedService.cons))
          return undefined;

        const peer = API.InputEncryptedChat.deserialized(_data);
        if (!peer) return undefined;
        const randomId = TLLong.deserialized(_data);
        if (!randomId) return undefined;
        const data = TLBytes.deserialized(_data);
        if (!data) return undefined;

        return new SendEncryptedService(peer, randomId, data);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendEncryptedService.cons];

        _data.push(this.peer);
        _data.push(this.randomId);
        _data.push(this.data);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: API.InputEncryptedChat,
        readonly randomId: TLLong,
        readonly data: TLBytes
      ) {}
    }
  }

  export namespace messages {
    export class ReceivedQueue implements TLFunction<TLVector<TLLong>> {
      static readonly cons = new TLInt(0x55a5bb66);

      static deserialized(_data: ByteStream): ReceivedQueue | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReceivedQueue.cons))
          return undefined;

        const maxQts = TLInt.deserialized(_data);
        if (!maxQts) return undefined;

        return new ReceivedQueue(maxQts);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReceivedQueue.cons];

        _data.push(this.maxQts);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly maxQts: TLInt) {}
    }
  }

  export namespace messages {
    export class ReportEncryptedSpam implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x4b0c8c0f);

      static deserialized(_data: ByteStream): ReportEncryptedSpam | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReportEncryptedSpam.cons))
          return undefined;

        const peer = API.InputEncryptedChat.deserialized(_data);
        if (!peer) return undefined;

        return new ReportEncryptedSpam(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReportEncryptedSpam.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: API.InputEncryptedChat) {}
    }
  }

  export namespace messages {
    export class ReadMessageContents
      implements TLFunction<messages.AffectedMessages>
    {
      static readonly cons = new TLInt(0x36a73f77);

      static deserialized(_data: ByteStream): ReadMessageContents | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReadMessageContents.cons))
          return undefined;

        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new ReadMessageContents(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReadMessageContents.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLVector<TLInt>) {}
    }
  }

  export namespace messages {
    export class GetStickers implements TLFunction<messages.StickersType> {
      static readonly cons = new TLInt(0xd5a5d3a1);

      static deserialized(_data: ByteStream): GetStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetStickers.cons))
          return undefined;

        const emoticon = TLString.deserialized(_data);
        if (!emoticon) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetStickers(emoticon, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetStickers.cons];

        _data.push(this.emoticon);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly emoticon: TLString, readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class GetAllStickers
      implements TLFunction<messages.AllStickersType>
    {
      static readonly cons = new TLInt(0xb8a0a1a8);

      static deserialized(_data: ByteStream): GetAllStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAllStickers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetAllStickers(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAllStickers.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class GetWebPagePreview implements TLFunction<MessageMediaType> {
      static readonly cons = new TLInt(0x8b68b0cc);

      static deserialized(_data: ByteStream): GetWebPagePreview | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetWebPagePreview.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const message = TLString.deserialized(_data);
        if (!message) return undefined;
        let entities: TLVector<MessageEntityType> | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLVector.deserialized(
            _data
          ) as TLVector<MessageEntityType>;
          if (!obj) return undefined;
          entities = obj;
        }

        return new GetWebPagePreview(message, entities);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetWebPagePreview.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.message);
        if (this.entities) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.entities) _data.push(this.entities);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly message: TLString,
        readonly entities: TLVector<MessageEntityType> | undefined
      ) {}
    }
  }

  export namespace messages {
    export class ExportChatInvite
      implements TLFunction<ExportedChatInviteType>
    {
      static readonly cons = new TLInt(0xa02ce5d5);

      static deserialized(_data: ByteStream): ExportChatInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportChatInvite.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const legacyRevokePermanent = (flags.value & 4) !== 0;
        const requestNeeded = (flags.value & 8) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let expireDate: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          expireDate = obj;
        }
        let usageLimit: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          usageLimit = obj;
        }
        let title: TLString | undefined;
        if ((flags.value & 16) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          title = obj;
        }

        return new ExportChatInvite(
          legacyRevokePermanent,
          requestNeeded,
          peer,
          expireDate,
          usageLimit,
          title
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportChatInvite.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.legacyRevokePermanent) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        if (this.requestNeeded) {
          flags.set(8);
        } else {
          flags.unset(8);
        }

        _data.push(this.peer);
        if (this.expireDate) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.expireDate) _data.push(this.expireDate);

        if (this.usageLimit) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.usageLimit) _data.push(this.usageLimit);

        if (this.title) {
          flags.set(16);
        } else {
          flags.unset(16);
        }
        if (this.title) _data.push(this.title);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly legacyRevokePermanent: boolean | undefined,
        readonly requestNeeded: boolean | undefined,
        readonly peer: InputPeerType,
        readonly expireDate: TLInt | undefined,
        readonly usageLimit: TLInt | undefined,
        readonly title: TLString | undefined
      ) {}
    }
  }

  export namespace messages {
    export class CheckChatInvite implements TLFunction<ChatInviteType> {
      static readonly cons = new TLInt(0x3eadb1bb);

      static deserialized(_data: ByteStream): CheckChatInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CheckChatInvite.cons))
          return undefined;

        const hash = TLString.deserialized(_data);
        if (!hash) return undefined;

        return new CheckChatInvite(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CheckChatInvite.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLString) {}
    }
  }

  export namespace messages {
    export class ImportChatInvite implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x6c50051c);

      static deserialized(_data: ByteStream): ImportChatInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ImportChatInvite.cons))
          return undefined;

        const hash = TLString.deserialized(_data);
        if (!hash) return undefined;

        return new ImportChatInvite(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ImportChatInvite.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLString) {}
    }
  }

  export namespace messages {
    export class GetStickerSet implements TLFunction<messages.StickerSetType> {
      static readonly cons = new TLInt(0xc8a0ec74);

      static deserialized(_data: ByteStream): GetStickerSet | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetStickerSet.cons))
          return undefined;

        const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
        if (!stickerset) return undefined;
        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;

        return new GetStickerSet(stickerset, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetStickerSet.cons];

        _data.push(this.stickerset);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly stickerset: InputStickerSetType,
        readonly hash: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class InstallStickerSet
      implements TLFunction<messages.StickerSetInstallResultType>
    {
      static readonly cons = new TLInt(0xc78fe460);

      static deserialized(_data: ByteStream): InstallStickerSet | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(InstallStickerSet.cons))
          return undefined;

        const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
        if (!stickerset) return undefined;
        const archived = TLObject.deserialized(_data) as BoolType;
        if (!archived) return undefined;

        return new InstallStickerSet(stickerset, archived);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [InstallStickerSet.cons];

        _data.push(this.stickerset);
        _data.push(this.archived);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly stickerset: InputStickerSetType,
        readonly archived: BoolType
      ) {}
    }
  }

  export namespace messages {
    export class UninstallStickerSet implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xf96e55de);

      static deserialized(_data: ByteStream): UninstallStickerSet | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UninstallStickerSet.cons))
          return undefined;

        const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
        if (!stickerset) return undefined;

        return new UninstallStickerSet(stickerset);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UninstallStickerSet.cons];

        _data.push(this.stickerset);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly stickerset: InputStickerSetType) {}
    }
  }

  export namespace messages {
    export class StartBot implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xe6df7378);

      static deserialized(_data: ByteStream): StartBot | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(StartBot.cons))
          return undefined;

        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const randomId = TLLong.deserialized(_data);
        if (!randomId) return undefined;
        const startParam = TLString.deserialized(_data);
        if (!startParam) return undefined;

        return new StartBot(bot, peer, randomId, startParam);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [StartBot.cons];

        _data.push(this.bot);
        _data.push(this.peer);
        _data.push(this.randomId);
        _data.push(this.startParam);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly bot: InputUserType,
        readonly peer: InputPeerType,
        readonly randomId: TLLong,
        readonly startParam: TLString
      ) {}
    }
  }

  export namespace messages {
    export class GetMessagesViews implements TLFunction<messages.MessageViews> {
      static readonly cons = new TLInt(0x5784d3e1);

      static deserialized(_data: ByteStream): GetMessagesViews | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetMessagesViews.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;
        const increment = TLObject.deserialized(_data) as BoolType;
        if (!increment) return undefined;

        return new GetMessagesViews(peer, id, increment);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMessagesViews.cons];

        _data.push(this.peer);
        _data.push(this.id);
        _data.push(this.increment);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly id: TLVector<TLInt>,
        readonly increment: BoolType
      ) {}
    }
  }

  export namespace messages {
    export class EditChatAdmin implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xa85bd1c2);

      static deserialized(_data: ByteStream): EditChatAdmin | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditChatAdmin.cons))
          return undefined;

        const chatId = TLLong.deserialized(_data);
        if (!chatId) return undefined;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const isAdmin = TLObject.deserialized(_data) as BoolType;
        if (!isAdmin) return undefined;

        return new EditChatAdmin(chatId, userId, isAdmin);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditChatAdmin.cons];

        _data.push(this.chatId);
        _data.push(this.userId);
        _data.push(this.isAdmin);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly chatId: TLLong,
        readonly userId: InputUserType,
        readonly isAdmin: BoolType
      ) {}
    }
  }

  export namespace messages {
    export class MigrateChat implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xa2875319);

      static deserialized(_data: ByteStream): MigrateChat | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(MigrateChat.cons))
          return undefined;

        const chatId = TLLong.deserialized(_data);
        if (!chatId) return undefined;

        return new MigrateChat(chatId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [MigrateChat.cons];

        _data.push(this.chatId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly chatId: TLLong) {}
    }
  }

  export namespace messages {
    export class SearchGlobal implements TLFunction<messages.MessagesType> {
      static readonly cons = new TLInt(0x4bc6589a);

      static deserialized(_data: ByteStream): SearchGlobal | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SearchGlobal.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let folderId: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          folderId = obj;
        }
        const q = TLString.deserialized(_data);
        if (!q) return undefined;
        const filter = TLObject.deserialized(_data) as MessagesFilterType;
        if (!filter) return undefined;
        const minDate = TLInt.deserialized(_data);
        if (!minDate) return undefined;
        const maxDate = TLInt.deserialized(_data);
        if (!maxDate) return undefined;
        const offsetRate = TLInt.deserialized(_data);
        if (!offsetRate) return undefined;
        const offsetPeer = TLObject.deserialized(_data) as InputPeerType;
        if (!offsetPeer) return undefined;
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new SearchGlobal(
          folderId,
          q,
          filter,
          minDate,
          maxDate,
          offsetRate,
          offsetPeer,
          offsetId,
          limit
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SearchGlobal.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.folderId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.folderId) _data.push(this.folderId);

        _data.push(this.q);
        _data.push(this.filter);
        _data.push(this.minDate);
        _data.push(this.maxDate);
        _data.push(this.offsetRate);
        _data.push(this.offsetPeer);
        _data.push(this.offsetId);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly folderId: TLInt | undefined,
        readonly q: TLString,
        readonly filter: MessagesFilterType,
        readonly minDate: TLInt,
        readonly maxDate: TLInt,
        readonly offsetRate: TLInt,
        readonly offsetPeer: InputPeerType,
        readonly offsetId: TLInt,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class ReorderStickerSets implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x78337739);

      static deserialized(_data: ByteStream): ReorderStickerSets | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReorderStickerSets.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const masks = (flags.value & 1) !== 0;
        const emojis = (flags.value & 2) !== 0;
        const order = TLVector.deserialized(_data, TLLong);
        if (!order) return undefined;

        return new ReorderStickerSets(masks, emojis, order);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReorderStickerSets.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.masks) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.emojis) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.order);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly masks: boolean | undefined,
        readonly emojis: boolean | undefined,
        readonly order: TLVector<TLLong>
      ) {}
    }
  }

  export namespace messages {
    export class GetDocumentByHash implements TLFunction<DocumentType> {
      static readonly cons = new TLInt(0xb1f2061f);

      static deserialized(_data: ByteStream): GetDocumentByHash | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetDocumentByHash.cons))
          return undefined;

        const sha256 = TLBytes.deserialized(_data);
        if (!sha256) return undefined;
        const size = TLLong.deserialized(_data);
        if (!size) return undefined;
        const mimeType = TLString.deserialized(_data);
        if (!mimeType) return undefined;

        return new GetDocumentByHash(sha256, size, mimeType);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDocumentByHash.cons];

        _data.push(this.sha256);
        _data.push(this.size);
        _data.push(this.mimeType);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly sha256: TLBytes,
        readonly size: TLLong,
        readonly mimeType: TLString
      ) {}
    }
  }

  export namespace messages {
    export class GetSavedGifs implements TLFunction<messages.SavedGifsType> {
      static readonly cons = new TLInt(0x5cf09635);

      static deserialized(_data: ByteStream): GetSavedGifs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSavedGifs.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetSavedGifs(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSavedGifs.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class SaveGif implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x327a30cb);

      static deserialized(_data: ByteStream): SaveGif | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveGif.cons)) return undefined;

        const id = TLObject.deserialized(_data) as InputDocumentType;
        if (!id) return undefined;
        const unsave = TLObject.deserialized(_data) as BoolType;
        if (!unsave) return undefined;

        return new SaveGif(id, unsave);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveGif.cons];

        _data.push(this.id);
        _data.push(this.unsave);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: InputDocumentType, readonly unsave: BoolType) {}
    }
  }

  export namespace messages {
    export class GetInlineBotResults
      implements TLFunction<messages.BotResults>
    {
      static readonly cons = new TLInt(0x514e999d);

      static deserialized(_data: ByteStream): GetInlineBotResults | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetInlineBotResults.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let geoPoint: InputGeoPointType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputGeoPointType;
          if (!obj) return undefined;
          geoPoint = obj;
        }
        const query = TLString.deserialized(_data);
        if (!query) return undefined;
        const offset = TLString.deserialized(_data);
        if (!offset) return undefined;

        return new GetInlineBotResults(bot, peer, geoPoint, query, offset);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetInlineBotResults.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.bot);
        _data.push(this.peer);
        if (this.geoPoint) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.geoPoint) _data.push(this.geoPoint);

        _data.push(this.query);
        _data.push(this.offset);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly bot: InputUserType,
        readonly peer: InputPeerType,
        readonly geoPoint: InputGeoPointType | undefined,
        readonly query: TLString,
        readonly offset: TLString
      ) {}
    }
  }

  export namespace messages {
    export class SetInlineBotResults implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xbb12a419);

      static deserialized(_data: ByteStream): SetInlineBotResults | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetInlineBotResults.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const gallery = (flags.value & 1) !== 0;
        const argPrivate = (flags.value & 2) !== 0;
        const queryId = TLLong.deserialized(_data);
        if (!queryId) return undefined;
        const results = TLVector.deserialized(
          _data
        ) as TLVector<InputBotInlineResultType>;
        if (!results) return undefined;
        const cacheTime = TLInt.deserialized(_data);
        if (!cacheTime) return undefined;
        let nextOffset: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          nextOffset = obj;
        }
        let switchPm: API.InlineBotSwitchPM | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLObject.deserialized(_data) as API.InlineBotSwitchPM;
          if (!obj) return undefined;
          switchPm = obj;
        }
        let switchWebview: API.InlineBotWebView | undefined;
        if ((flags.value & 16) !== 0) {
          const obj = TLObject.deserialized(_data) as API.InlineBotWebView;
          if (!obj) return undefined;
          switchWebview = obj;
        }

        return new SetInlineBotResults(
          gallery,
          argPrivate,
          queryId,
          results,
          cacheTime,
          nextOffset,
          switchPm,
          switchWebview
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetInlineBotResults.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.gallery) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.argPrivate) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.queryId);
        _data.push(this.results);
        _data.push(this.cacheTime);
        if (this.nextOffset) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.nextOffset) _data.push(this.nextOffset);

        if (this.switchPm) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.switchPm) _data.push(this.switchPm);

        if (this.switchWebview) {
          flags.set(16);
        } else {
          flags.unset(16);
        }
        if (this.switchWebview) _data.push(this.switchWebview);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly gallery: boolean | undefined,
        readonly argPrivate: boolean | undefined,
        readonly queryId: TLLong,
        readonly results: TLVector<InputBotInlineResultType>,
        readonly cacheTime: TLInt,
        readonly nextOffset: TLString | undefined,
        readonly switchPm: API.InlineBotSwitchPM | undefined,
        readonly switchWebview: API.InlineBotWebView | undefined
      ) {}
    }
  }

  export namespace messages {
    export class SendInlineBotResult implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xf7bc68ba);

      static deserialized(_data: ByteStream): SendInlineBotResult | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendInlineBotResult.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const silent = (flags.value & 32) !== 0;
        const background = (flags.value & 64) !== 0;
        const clearDraft = (flags.value & 128) !== 0;
        const hideVia = (flags.value & 2048) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let replyTo: InputReplyToType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputReplyToType;
          if (!obj) return undefined;
          replyTo = obj;
        }
        const randomId = TLLong.deserialized(_data);
        if (!randomId) return undefined;
        const queryId = TLLong.deserialized(_data);
        if (!queryId) return undefined;
        const id = TLString.deserialized(_data);
        if (!id) return undefined;
        let scheduleDate: TLInt | undefined;
        if ((flags.value & 1024) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          scheduleDate = obj;
        }
        let sendAs: InputPeerType | undefined;
        if ((flags.value & 8192) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          sendAs = obj;
        }

        return new SendInlineBotResult(
          silent,
          background,
          clearDraft,
          hideVia,
          peer,
          replyTo,
          randomId,
          queryId,
          id,
          scheduleDate,
          sendAs
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendInlineBotResult.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.silent) {
          flags.set(32);
        } else {
          flags.unset(32);
        }

        if (this.background) {
          flags.set(64);
        } else {
          flags.unset(64);
        }

        if (this.clearDraft) {
          flags.set(128);
        } else {
          flags.unset(128);
        }

        if (this.hideVia) {
          flags.set(2048);
        } else {
          flags.unset(2048);
        }

        _data.push(this.peer);
        if (this.replyTo) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.replyTo) _data.push(this.replyTo);

        _data.push(this.randomId);
        _data.push(this.queryId);
        _data.push(this.id);
        if (this.scheduleDate) {
          flags.set(1024);
        } else {
          flags.unset(1024);
        }
        if (this.scheduleDate) _data.push(this.scheduleDate);

        if (this.sendAs) {
          flags.set(8192);
        } else {
          flags.unset(8192);
        }
        if (this.sendAs) _data.push(this.sendAs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly silent: boolean | undefined,
        readonly background: boolean | undefined,
        readonly clearDraft: boolean | undefined,
        readonly hideVia: boolean | undefined,
        readonly peer: InputPeerType,
        readonly replyTo: InputReplyToType | undefined,
        readonly randomId: TLLong,
        readonly queryId: TLLong,
        readonly id: TLString,
        readonly scheduleDate: TLInt | undefined,
        readonly sendAs: InputPeerType | undefined
      ) {}
    }
  }

  export namespace messages {
    export class GetMessageEditData
      implements TLFunction<messages.MessageEditData>
    {
      static readonly cons = new TLInt(0xfda68d36);

      static deserialized(_data: ByteStream): GetMessageEditData | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetMessageEditData.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;

        return new GetMessageEditData(peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMessageEditData.cons];

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly id: TLInt) {}
    }
  }

  export namespace messages {
    export class EditMessage implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x48f71778);

      static deserialized(_data: ByteStream): EditMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditMessage.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const noWebpage = (flags.value & 2) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;
        let message: TLString | undefined;
        if ((flags.value & 2048) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          message = obj;
        }
        let media: InputMediaType | undefined;
        if ((flags.value & 16384) !== 0) {
          const obj = TLObject.deserialized(_data) as InputMediaType;
          if (!obj) return undefined;
          media = obj;
        }
        let replyMarkup: ReplyMarkupType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as ReplyMarkupType;
          if (!obj) return undefined;
          replyMarkup = obj;
        }
        let entities: TLVector<MessageEntityType> | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLVector.deserialized(
            _data
          ) as TLVector<MessageEntityType>;
          if (!obj) return undefined;
          entities = obj;
        }
        let scheduleDate: TLInt | undefined;
        if ((flags.value & 32768) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          scheduleDate = obj;
        }

        return new EditMessage(
          noWebpage,
          peer,
          id,
          message,
          media,
          replyMarkup,
          entities,
          scheduleDate
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditMessage.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.noWebpage) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.peer);
        _data.push(this.id);
        if (this.message) {
          flags.set(2048);
        } else {
          flags.unset(2048);
        }
        if (this.message) _data.push(this.message);

        if (this.media) {
          flags.set(16384);
        } else {
          flags.unset(16384);
        }
        if (this.media) _data.push(this.media);

        if (this.replyMarkup) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.replyMarkup) _data.push(this.replyMarkup);

        if (this.entities) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.entities) _data.push(this.entities);

        if (this.scheduleDate) {
          flags.set(32768);
        } else {
          flags.unset(32768);
        }
        if (this.scheduleDate) _data.push(this.scheduleDate);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly noWebpage: boolean | undefined,
        readonly peer: InputPeerType,
        readonly id: TLInt,
        readonly message: TLString | undefined,
        readonly media: InputMediaType | undefined,
        readonly replyMarkup: ReplyMarkupType | undefined,
        readonly entities: TLVector<MessageEntityType> | undefined,
        readonly scheduleDate: TLInt | undefined
      ) {}
    }
  }

  export namespace messages {
    export class EditInlineBotMessage implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x83557dba);

      static deserialized(_data: ByteStream): EditInlineBotMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditInlineBotMessage.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const noWebpage = (flags.value & 2) !== 0;
        const id = TLObject.deserialized(_data) as InputBotInlineMessageIDType;
        if (!id) return undefined;
        let message: TLString | undefined;
        if ((flags.value & 2048) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          message = obj;
        }
        let media: InputMediaType | undefined;
        if ((flags.value & 16384) !== 0) {
          const obj = TLObject.deserialized(_data) as InputMediaType;
          if (!obj) return undefined;
          media = obj;
        }
        let replyMarkup: ReplyMarkupType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as ReplyMarkupType;
          if (!obj) return undefined;
          replyMarkup = obj;
        }
        let entities: TLVector<MessageEntityType> | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLVector.deserialized(
            _data
          ) as TLVector<MessageEntityType>;
          if (!obj) return undefined;
          entities = obj;
        }

        return new EditInlineBotMessage(
          noWebpage,
          id,
          message,
          media,
          replyMarkup,
          entities
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditInlineBotMessage.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.noWebpage) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.id);
        if (this.message) {
          flags.set(2048);
        } else {
          flags.unset(2048);
        }
        if (this.message) _data.push(this.message);

        if (this.media) {
          flags.set(16384);
        } else {
          flags.unset(16384);
        }
        if (this.media) _data.push(this.media);

        if (this.replyMarkup) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.replyMarkup) _data.push(this.replyMarkup);

        if (this.entities) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.entities) _data.push(this.entities);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly noWebpage: boolean | undefined,
        readonly id: InputBotInlineMessageIDType,
        readonly message: TLString | undefined,
        readonly media: InputMediaType | undefined,
        readonly replyMarkup: ReplyMarkupType | undefined,
        readonly entities: TLVector<MessageEntityType> | undefined
      ) {}
    }
  }

  export namespace messages {
    export class GetBotCallbackAnswer
      implements TLFunction<messages.BotCallbackAnswer>
    {
      static readonly cons = new TLInt(0x9342ca07);

      static deserialized(_data: ByteStream): GetBotCallbackAnswer | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetBotCallbackAnswer.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const game = (flags.value & 2) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;
        let data: TLBytes | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLBytes.deserialized(_data);
          if (!obj) return undefined;
          data = obj;
        }
        let password: InputCheckPasswordSRPType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as InputCheckPasswordSRPType;
          if (!obj) return undefined;
          password = obj;
        }

        return new GetBotCallbackAnswer(game, peer, msgId, data, password);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetBotCallbackAnswer.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.game) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.peer);
        _data.push(this.msgId);
        if (this.data) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.data) _data.push(this.data);

        if (this.password) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.password) _data.push(this.password);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly game: boolean | undefined,
        readonly peer: InputPeerType,
        readonly msgId: TLInt,
        readonly data: TLBytes | undefined,
        readonly password: InputCheckPasswordSRPType | undefined
      ) {}
    }
  }

  export namespace messages {
    export class SetBotCallbackAnswer implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xd58f130a);

      static deserialized(_data: ByteStream): SetBotCallbackAnswer | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetBotCallbackAnswer.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const alert = (flags.value & 2) !== 0;
        const queryId = TLLong.deserialized(_data);
        if (!queryId) return undefined;
        let message: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          message = obj;
        }
        let url: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          url = obj;
        }
        const cacheTime = TLInt.deserialized(_data);
        if (!cacheTime) return undefined;

        return new SetBotCallbackAnswer(
          alert,
          queryId,
          message,
          url,
          cacheTime
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetBotCallbackAnswer.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.alert) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.queryId);
        if (this.message) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.message) _data.push(this.message);

        if (this.url) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.url) _data.push(this.url);

        _data.push(this.cacheTime);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly alert: boolean | undefined,
        readonly queryId: TLLong,
        readonly message: TLString | undefined,
        readonly url: TLString | undefined,
        readonly cacheTime: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class GetPeerDialogs implements TLFunction<messages.PeerDialogs> {
      static readonly cons = new TLInt(0xe470bcfd);

      static deserialized(_data: ByteStream): GetPeerDialogs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPeerDialogs.cons))
          return undefined;

        const peers = TLVector.deserialized(
          _data
        ) as TLVector<InputDialogPeerType>;
        if (!peers) return undefined;

        return new GetPeerDialogs(peers);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPeerDialogs.cons];

        _data.push(this.peers);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peers: TLVector<InputDialogPeerType>) {}
    }
  }

  export namespace messages {
    export class SaveDraft implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xb4331e3f);

      static deserialized(_data: ByteStream): SaveDraft | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveDraft.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const noWebpage = (flags.value & 2) !== 0;
        let replyToMsgId: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          replyToMsgId = obj;
        }
        let topMsgId: TLInt | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          topMsgId = obj;
        }
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const message = TLString.deserialized(_data);
        if (!message) return undefined;
        let entities: TLVector<MessageEntityType> | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLVector.deserialized(
            _data
          ) as TLVector<MessageEntityType>;
          if (!obj) return undefined;
          entities = obj;
        }

        return new SaveDraft(
          noWebpage,
          replyToMsgId,
          topMsgId,
          peer,
          message,
          entities
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveDraft.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.noWebpage) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.replyToMsgId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.replyToMsgId) _data.push(this.replyToMsgId);

        if (this.topMsgId) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.topMsgId) _data.push(this.topMsgId);

        _data.push(this.peer);
        _data.push(this.message);
        if (this.entities) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.entities) _data.push(this.entities);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly noWebpage: boolean | undefined,
        readonly replyToMsgId: TLInt | undefined,
        readonly topMsgId: TLInt | undefined,
        readonly peer: InputPeerType,
        readonly message: TLString,
        readonly entities: TLVector<MessageEntityType> | undefined
      ) {}
    }
  }

  export namespace messages {
    export class GetAllDrafts implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x6a3f8d65);

      static deserialized(_data: ByteStream): GetAllDrafts | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAllDrafts.cons))
          return undefined;

        return new GetAllDrafts();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAllDrafts.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class GetFeaturedStickers
      implements TLFunction<messages.FeaturedStickersType>
    {
      static readonly cons = new TLInt(0x64780b14);

      static deserialized(_data: ByteStream): GetFeaturedStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetFeaturedStickers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetFeaturedStickers(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetFeaturedStickers.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class ReadFeaturedStickers implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x5b118126);

      static deserialized(_data: ByteStream): ReadFeaturedStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReadFeaturedStickers.cons))
          return undefined;

        const id = TLVector.deserialized(_data, TLLong);
        if (!id) return undefined;

        return new ReadFeaturedStickers(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReadFeaturedStickers.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLVector<TLLong>) {}
    }
  }

  export namespace messages {
    export class GetRecentStickers
      implements TLFunction<messages.RecentStickersType>
    {
      static readonly cons = new TLInt(0x9da9403b);

      static deserialized(_data: ByteStream): GetRecentStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetRecentStickers.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const attached = (flags.value & 1) !== 0;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetRecentStickers(attached, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetRecentStickers.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.attached) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly attached: boolean | undefined,
        readonly hash: TLLong
      ) {}
    }
  }

  export namespace messages {
    export class SaveRecentSticker implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x392718f8);

      static deserialized(_data: ByteStream): SaveRecentSticker | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveRecentSticker.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const attached = (flags.value & 1) !== 0;
        const id = TLObject.deserialized(_data) as InputDocumentType;
        if (!id) return undefined;
        const unsave = TLObject.deserialized(_data) as BoolType;
        if (!unsave) return undefined;

        return new SaveRecentSticker(attached, id, unsave);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveRecentSticker.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.attached) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.id);
        _data.push(this.unsave);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly attached: boolean | undefined,
        readonly id: InputDocumentType,
        readonly unsave: BoolType
      ) {}
    }
  }

  export namespace messages {
    export class ClearRecentStickers implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x8999602d);

      static deserialized(_data: ByteStream): ClearRecentStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ClearRecentStickers.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const attached = (flags.value & 1) !== 0;

        return new ClearRecentStickers(attached);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ClearRecentStickers.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.attached) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly attached: boolean | undefined) {}
    }
  }

  export namespace messages {
    export class GetArchivedStickers
      implements TLFunction<messages.ArchivedStickers>
    {
      static readonly cons = new TLInt(0x57f17692);

      static deserialized(_data: ByteStream): GetArchivedStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetArchivedStickers.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const masks = (flags.value & 1) !== 0;
        const emojis = (flags.value & 2) !== 0;
        const offsetId = TLLong.deserialized(_data);
        if (!offsetId) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetArchivedStickers(masks, emojis, offsetId, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetArchivedStickers.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.masks) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.emojis) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.offsetId);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly masks: boolean | undefined,
        readonly emojis: boolean | undefined,
        readonly offsetId: TLLong,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class GetMaskStickers
      implements TLFunction<messages.AllStickersType>
    {
      static readonly cons = new TLInt(0x640f82b8);

      static deserialized(_data: ByteStream): GetMaskStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetMaskStickers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetMaskStickers(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMaskStickers.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class GetAttachedStickers
      implements TLFunction<TLVector<StickerSetCoveredType>>
    {
      static readonly cons = new TLInt(0xcc5b67cc);

      static deserialized(_data: ByteStream): GetAttachedStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAttachedStickers.cons))
          return undefined;

        const media = TLObject.deserialized(_data) as InputStickeredMediaType;
        if (!media) return undefined;

        return new GetAttachedStickers(media);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAttachedStickers.cons];

        _data.push(this.media);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly media: InputStickeredMediaType) {}
    }
  }

  export namespace messages {
    export class SetGameScore implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x8ef8ecc0);

      static deserialized(_data: ByteStream): SetGameScore | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetGameScore.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const editMessage = (flags.value & 1) !== 0;
        const force = (flags.value & 2) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const score = TLInt.deserialized(_data);
        if (!score) return undefined;

        return new SetGameScore(editMessage, force, peer, id, userId, score);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetGameScore.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.editMessage) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.force) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.peer);
        _data.push(this.id);
        _data.push(this.userId);
        _data.push(this.score);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly editMessage: boolean | undefined,
        readonly force: boolean | undefined,
        readonly peer: InputPeerType,
        readonly id: TLInt,
        readonly userId: InputUserType,
        readonly score: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class SetInlineGameScore implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x15ad9f64);

      static deserialized(_data: ByteStream): SetInlineGameScore | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetInlineGameScore.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const editMessage = (flags.value & 1) !== 0;
        const force = (flags.value & 2) !== 0;
        const id = TLObject.deserialized(_data) as InputBotInlineMessageIDType;
        if (!id) return undefined;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const score = TLInt.deserialized(_data);
        if (!score) return undefined;

        return new SetInlineGameScore(editMessage, force, id, userId, score);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetInlineGameScore.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.editMessage) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.force) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.id);
        _data.push(this.userId);
        _data.push(this.score);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly editMessage: boolean | undefined,
        readonly force: boolean | undefined,
        readonly id: InputBotInlineMessageIDType,
        readonly userId: InputUserType,
        readonly score: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class GetGameHighScores implements TLFunction<messages.HighScores> {
      static readonly cons = new TLInt(0xe822649d);

      static deserialized(_data: ByteStream): GetGameHighScores | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetGameHighScores.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;

        return new GetGameHighScores(peer, id, userId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetGameHighScores.cons];

        _data.push(this.peer);
        _data.push(this.id);
        _data.push(this.userId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly id: TLInt,
        readonly userId: InputUserType
      ) {}
    }
  }

  export namespace messages {
    export class GetInlineGameHighScores
      implements TLFunction<messages.HighScores>
    {
      static readonly cons = new TLInt(0xf635e1b);

      static deserialized(
        _data: ByteStream
      ): GetInlineGameHighScores | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetInlineGameHighScores.cons))
          return undefined;

        const id = TLObject.deserialized(_data) as InputBotInlineMessageIDType;
        if (!id) return undefined;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;

        return new GetInlineGameHighScores(id, userId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetInlineGameHighScores.cons];

        _data.push(this.id);
        _data.push(this.userId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly id: InputBotInlineMessageIDType,
        readonly userId: InputUserType
      ) {}
    }
  }

  export namespace messages {
    export class GetCommonChats implements TLFunction<messages.ChatsType> {
      static readonly cons = new TLInt(0xe40ca104);

      static deserialized(_data: ByteStream): GetCommonChats | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetCommonChats.cons))
          return undefined;

        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const maxId = TLLong.deserialized(_data);
        if (!maxId) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetCommonChats(userId, maxId, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetCommonChats.cons];

        _data.push(this.userId);
        _data.push(this.maxId);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly userId: InputUserType,
        readonly maxId: TLLong,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class GetWebPage implements TLFunction<messages.WebPage> {
      static readonly cons = new TLInt(0x8d9692a3);

      static deserialized(_data: ByteStream): GetWebPage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetWebPage.cons))
          return undefined;

        const url = TLString.deserialized(_data);
        if (!url) return undefined;
        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;

        return new GetWebPage(url, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetWebPage.cons];

        _data.push(this.url);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly url: TLString, readonly hash: TLInt) {}
    }
  }

  export namespace messages {
    export class ToggleDialogPin implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xa731e257);

      static deserialized(_data: ByteStream): ToggleDialogPin | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleDialogPin.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const pinned = (flags.value & 1) !== 0;
        const peer = TLObject.deserialized(_data) as InputDialogPeerType;
        if (!peer) return undefined;

        return new ToggleDialogPin(pinned, peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleDialogPin.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.pinned) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly pinned: boolean | undefined,
        readonly peer: InputDialogPeerType
      ) {}
    }
  }

  export namespace messages {
    export class ReorderPinnedDialogs implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x3b1adf37);

      static deserialized(_data: ByteStream): ReorderPinnedDialogs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReorderPinnedDialogs.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const force = (flags.value & 1) !== 0;
        const folderId = TLInt.deserialized(_data);
        if (!folderId) return undefined;
        const order = TLVector.deserialized(
          _data
        ) as TLVector<InputDialogPeerType>;
        if (!order) return undefined;

        return new ReorderPinnedDialogs(force, folderId, order);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReorderPinnedDialogs.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.force) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.folderId);
        _data.push(this.order);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly force: boolean | undefined,
        readonly folderId: TLInt,
        readonly order: TLVector<InputDialogPeerType>
      ) {}
    }
  }

  export namespace messages {
    export class GetPinnedDialogs implements TLFunction<messages.PeerDialogs> {
      static readonly cons = new TLInt(0xd6b94df2);

      static deserialized(_data: ByteStream): GetPinnedDialogs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPinnedDialogs.cons))
          return undefined;

        const folderId = TLInt.deserialized(_data);
        if (!folderId) return undefined;

        return new GetPinnedDialogs(folderId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPinnedDialogs.cons];

        _data.push(this.folderId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly folderId: TLInt) {}
    }
  }

  export namespace messages {
    export class SetBotShippingResults implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xe5f672fa);

      static deserialized(
        _data: ByteStream
      ): SetBotShippingResults | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetBotShippingResults.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const queryId = TLLong.deserialized(_data);
        if (!queryId) return undefined;
        let error: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          error = obj;
        }
        let shippingOptions: TLVector<API.ShippingOption> | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLVector.deserialized(_data, API.ShippingOption);
          if (!obj) return undefined;
          shippingOptions = obj;
        }

        return new SetBotShippingResults(queryId, error, shippingOptions);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetBotShippingResults.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.queryId);
        if (this.error) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.error) _data.push(this.error);

        if (this.shippingOptions) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.shippingOptions) _data.push(this.shippingOptions);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly queryId: TLLong,
        readonly error: TLString | undefined,
        readonly shippingOptions: TLVector<API.ShippingOption> | undefined
      ) {}
    }
  }

  export namespace messages {
    export class SetBotPrecheckoutResults implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x9c2dd95);

      static deserialized(
        _data: ByteStream
      ): SetBotPrecheckoutResults | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetBotPrecheckoutResults.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const success = (flags.value & 2) !== 0;
        const queryId = TLLong.deserialized(_data);
        if (!queryId) return undefined;
        let error: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          error = obj;
        }

        return new SetBotPrecheckoutResults(success, queryId, error);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetBotPrecheckoutResults.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.success) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.queryId);
        if (this.error) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.error) _data.push(this.error);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly success: boolean | undefined,
        readonly queryId: TLLong,
        readonly error: TLString | undefined
      ) {}
    }
  }

  export namespace messages {
    export class UploadMedia implements TLFunction<MessageMediaType> {
      static readonly cons = new TLInt(0x519bc2b1);

      static deserialized(_data: ByteStream): UploadMedia | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UploadMedia.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const media = TLObject.deserialized(_data) as InputMediaType;
        if (!media) return undefined;

        return new UploadMedia(peer, media);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UploadMedia.cons];

        _data.push(this.peer);
        _data.push(this.media);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly media: InputMediaType
      ) {}
    }
  }

  export namespace messages {
    export class SendScreenshotNotification implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xa1405817);

      static deserialized(
        _data: ByteStream
      ): SendScreenshotNotification | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(SendScreenshotNotification.cons)
        )
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const replyTo = TLObject.deserialized(_data) as InputReplyToType;
        if (!replyTo) return undefined;
        const randomId = TLLong.deserialized(_data);
        if (!randomId) return undefined;

        return new SendScreenshotNotification(peer, replyTo, randomId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendScreenshotNotification.cons];

        _data.push(this.peer);
        _data.push(this.replyTo);
        _data.push(this.randomId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly replyTo: InputReplyToType,
        readonly randomId: TLLong
      ) {}
    }
  }

  export namespace messages {
    export class GetFavedStickers
      implements TLFunction<messages.FavedStickersType>
    {
      static readonly cons = new TLInt(0x4f1aaa9);

      static deserialized(_data: ByteStream): GetFavedStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetFavedStickers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetFavedStickers(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetFavedStickers.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class FaveSticker implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xb9ffc55b);

      static deserialized(_data: ByteStream): FaveSticker | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(FaveSticker.cons))
          return undefined;

        const id = TLObject.deserialized(_data) as InputDocumentType;
        if (!id) return undefined;
        const unfave = TLObject.deserialized(_data) as BoolType;
        if (!unfave) return undefined;

        return new FaveSticker(id, unfave);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [FaveSticker.cons];

        _data.push(this.id);
        _data.push(this.unfave);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: InputDocumentType, readonly unfave: BoolType) {}
    }
  }

  export namespace messages {
    export class GetUnreadMentions
      implements TLFunction<messages.MessagesType>
    {
      static readonly cons = new TLInt(0xf107e790);

      static deserialized(_data: ByteStream): GetUnreadMentions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetUnreadMentions.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let topMsgId: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          topMsgId = obj;
        }
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const addOffset = TLInt.deserialized(_data);
        if (!addOffset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const maxId = TLInt.deserialized(_data);
        if (!maxId) return undefined;
        const minId = TLInt.deserialized(_data);
        if (!minId) return undefined;

        return new GetUnreadMentions(
          peer,
          topMsgId,
          offsetId,
          addOffset,
          limit,
          maxId,
          minId
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetUnreadMentions.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        if (this.topMsgId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.topMsgId) _data.push(this.topMsgId);

        _data.push(this.offsetId);
        _data.push(this.addOffset);
        _data.push(this.limit);
        _data.push(this.maxId);
        _data.push(this.minId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly topMsgId: TLInt | undefined,
        readonly offsetId: TLInt,
        readonly addOffset: TLInt,
        readonly limit: TLInt,
        readonly maxId: TLInt,
        readonly minId: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class ReadMentions implements TLFunction<messages.AffectedHistory> {
      static readonly cons = new TLInt(0x36e5bf4d);

      static deserialized(_data: ByteStream): ReadMentions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReadMentions.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let topMsgId: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          topMsgId = obj;
        }

        return new ReadMentions(peer, topMsgId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReadMentions.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        if (this.topMsgId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.topMsgId) _data.push(this.topMsgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly topMsgId: TLInt | undefined
      ) {}
    }
  }

  export namespace messages {
    export class GetRecentLocations
      implements TLFunction<messages.MessagesType>
    {
      static readonly cons = new TLInt(0x702a40e0);

      static deserialized(_data: ByteStream): GetRecentLocations | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetRecentLocations.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetRecentLocations(peer, limit, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetRecentLocations.cons];

        _data.push(this.peer);
        _data.push(this.limit);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly limit: TLInt,
        readonly hash: TLLong
      ) {}
    }
  }

  export namespace messages {
    export class SendMultiMedia implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x456e8987);

      static deserialized(_data: ByteStream): SendMultiMedia | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendMultiMedia.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const silent = (flags.value & 32) !== 0;
        const background = (flags.value & 64) !== 0;
        const clearDraft = (flags.value & 128) !== 0;
        const noforwards = (flags.value & 16384) !== 0;
        const updateStickersetsOrder = (flags.value & 32768) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let replyTo: InputReplyToType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputReplyToType;
          if (!obj) return undefined;
          replyTo = obj;
        }
        const multiMedia = TLVector.deserialized(_data, API.InputSingleMedia);
        if (!multiMedia) return undefined;
        let scheduleDate: TLInt | undefined;
        if ((flags.value & 1024) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          scheduleDate = obj;
        }
        let sendAs: InputPeerType | undefined;
        if ((flags.value & 8192) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          sendAs = obj;
        }

        return new SendMultiMedia(
          silent,
          background,
          clearDraft,
          noforwards,
          updateStickersetsOrder,
          peer,
          replyTo,
          multiMedia,
          scheduleDate,
          sendAs
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendMultiMedia.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.silent) {
          flags.set(32);
        } else {
          flags.unset(32);
        }

        if (this.background) {
          flags.set(64);
        } else {
          flags.unset(64);
        }

        if (this.clearDraft) {
          flags.set(128);
        } else {
          flags.unset(128);
        }

        if (this.noforwards) {
          flags.set(16384);
        } else {
          flags.unset(16384);
        }

        if (this.updateStickersetsOrder) {
          flags.set(32768);
        } else {
          flags.unset(32768);
        }

        _data.push(this.peer);
        if (this.replyTo) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.replyTo) _data.push(this.replyTo);

        _data.push(this.multiMedia);
        if (this.scheduleDate) {
          flags.set(1024);
        } else {
          flags.unset(1024);
        }
        if (this.scheduleDate) _data.push(this.scheduleDate);

        if (this.sendAs) {
          flags.set(8192);
        } else {
          flags.unset(8192);
        }
        if (this.sendAs) _data.push(this.sendAs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly silent: boolean | undefined,
        readonly background: boolean | undefined,
        readonly clearDraft: boolean | undefined,
        readonly noforwards: boolean | undefined,
        readonly updateStickersetsOrder: boolean | undefined,
        readonly peer: InputPeerType,
        readonly replyTo: InputReplyToType | undefined,
        readonly multiMedia: TLVector<API.InputSingleMedia>,
        readonly scheduleDate: TLInt | undefined,
        readonly sendAs: InputPeerType | undefined
      ) {}
    }
  }

  export namespace messages {
    export class UploadEncryptedFile implements TLFunction<EncryptedFileType> {
      static readonly cons = new TLInt(0x5057c497);

      static deserialized(_data: ByteStream): UploadEncryptedFile | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UploadEncryptedFile.cons))
          return undefined;

        const peer = API.InputEncryptedChat.deserialized(_data);
        if (!peer) return undefined;
        const file = TLObject.deserialized(_data) as InputEncryptedFileType;
        if (!file) return undefined;

        return new UploadEncryptedFile(peer, file);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UploadEncryptedFile.cons];

        _data.push(this.peer);
        _data.push(this.file);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: API.InputEncryptedChat,
        readonly file: InputEncryptedFileType
      ) {}
    }
  }

  export namespace messages {
    export class SearchStickerSets
      implements TLFunction<messages.FoundStickerSetsType>
    {
      static readonly cons = new TLInt(0x35705b8a);

      static deserialized(_data: ByteStream): SearchStickerSets | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SearchStickerSets.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const excludeFeatured = (flags.value & 1) !== 0;
        const q = TLString.deserialized(_data);
        if (!q) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new SearchStickerSets(excludeFeatured, q, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SearchStickerSets.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.excludeFeatured) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.q);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly excludeFeatured: boolean | undefined,
        readonly q: TLString,
        readonly hash: TLLong
      ) {}
    }
  }

  export namespace messages {
    export class GetSplitRanges
      implements TLFunction<TLVector<API.MessageRange>>
    {
      static readonly cons = new TLInt(0x1cff7e08);

      static deserialized(_data: ByteStream): GetSplitRanges | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSplitRanges.cons))
          return undefined;

        return new GetSplitRanges();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSplitRanges.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class MarkDialogUnread implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xc286d98f);

      static deserialized(_data: ByteStream): MarkDialogUnread | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(MarkDialogUnread.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const unread = (flags.value & 1) !== 0;
        const peer = TLObject.deserialized(_data) as InputDialogPeerType;
        if (!peer) return undefined;

        return new MarkDialogUnread(unread, peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [MarkDialogUnread.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.unread) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly unread: boolean | undefined,
        readonly peer: InputDialogPeerType
      ) {}
    }
  }

  export namespace messages {
    export class GetDialogUnreadMarks
      implements TLFunction<TLVector<DialogPeerType>>
    {
      static readonly cons = new TLInt(0x22e24e22);

      static deserialized(_data: ByteStream): GetDialogUnreadMarks | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetDialogUnreadMarks.cons))
          return undefined;

        return new GetDialogUnreadMarks();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDialogUnreadMarks.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class ClearAllDrafts implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x7e58ee9c);

      static deserialized(_data: ByteStream): ClearAllDrafts | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ClearAllDrafts.cons))
          return undefined;

        return new ClearAllDrafts();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ClearAllDrafts.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class UpdatePinnedMessage implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xd2aaf7ec);

      static deserialized(_data: ByteStream): UpdatePinnedMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdatePinnedMessage.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const silent = (flags.value & 1) !== 0;
        const unpin = (flags.value & 2) !== 0;
        const pmOneside = (flags.value & 4) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;

        return new UpdatePinnedMessage(silent, unpin, pmOneside, peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdatePinnedMessage.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.silent) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.unpin) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.pmOneside) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly silent: boolean | undefined,
        readonly unpin: boolean | undefined,
        readonly pmOneside: boolean | undefined,
        readonly peer: InputPeerType,
        readonly id: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class SendVote implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x10ea6184);

      static deserialized(_data: ByteStream): SendVote | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendVote.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;
        const options = TLVector.deserialized(_data, TLBytes);
        if (!options) return undefined;

        return new SendVote(peer, msgId, options);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendVote.cons];

        _data.push(this.peer);
        _data.push(this.msgId);
        _data.push(this.options);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly msgId: TLInt,
        readonly options: TLVector<TLBytes>
      ) {}
    }
  }

  export namespace messages {
    export class GetPollResults implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x73bb643b);

      static deserialized(_data: ByteStream): GetPollResults | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPollResults.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;

        return new GetPollResults(peer, msgId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPollResults.cons];

        _data.push(this.peer);
        _data.push(this.msgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly msgId: TLInt) {}
    }
  }

  export namespace messages {
    export class GetOnlines implements TLFunction<API.ChatOnlines> {
      static readonly cons = new TLInt(0x6e2be050);

      static deserialized(_data: ByteStream): GetOnlines | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetOnlines.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new GetOnlines(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetOnlines.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace messages {
    export class EditChatAbout implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xdef60797);

      static deserialized(_data: ByteStream): EditChatAbout | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditChatAbout.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const about = TLString.deserialized(_data);
        if (!about) return undefined;

        return new EditChatAbout(peer, about);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditChatAbout.cons];

        _data.push(this.peer);
        _data.push(this.about);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly about: TLString) {}
    }
  }

  export namespace messages {
    export class EditChatDefaultBannedRights
      implements TLFunction<UpdatesType>
    {
      static readonly cons = new TLInt(0xa5866b41);

      static deserialized(
        _data: ByteStream
      ): EditChatDefaultBannedRights | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(EditChatDefaultBannedRights.cons)
        )
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const bannedRights = API.ChatBannedRights.deserialized(_data);
        if (!bannedRights) return undefined;

        return new EditChatDefaultBannedRights(peer, bannedRights);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditChatDefaultBannedRights.cons];

        _data.push(this.peer);
        _data.push(this.bannedRights);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly bannedRights: API.ChatBannedRights
      ) {}
    }
  }

  export namespace messages {
    export class GetEmojiKeywords
      implements TLFunction<API.EmojiKeywordsDifference>
    {
      static readonly cons = new TLInt(0x35a0e062);

      static deserialized(_data: ByteStream): GetEmojiKeywords | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetEmojiKeywords.cons))
          return undefined;

        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;

        return new GetEmojiKeywords(langCode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetEmojiKeywords.cons];

        _data.push(this.langCode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly langCode: TLString) {}
    }
  }

  export namespace messages {
    export class GetEmojiKeywordsDifference
      implements TLFunction<API.EmojiKeywordsDifference>
    {
      static readonly cons = new TLInt(0x1508b6af);

      static deserialized(
        _data: ByteStream
      ): GetEmojiKeywordsDifference | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(GetEmojiKeywordsDifference.cons)
        )
          return undefined;

        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;
        const fromVersion = TLInt.deserialized(_data);
        if (!fromVersion) return undefined;

        return new GetEmojiKeywordsDifference(langCode, fromVersion);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetEmojiKeywordsDifference.cons];

        _data.push(this.langCode);
        _data.push(this.fromVersion);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly langCode: TLString, readonly fromVersion: TLInt) {}
    }
  }

  export namespace messages {
    export class GetEmojiKeywordsLanguages
      implements TLFunction<TLVector<API.EmojiLanguage>>
    {
      static readonly cons = new TLInt(0x4e9963b2);

      static deserialized(
        _data: ByteStream
      ): GetEmojiKeywordsLanguages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetEmojiKeywordsLanguages.cons))
          return undefined;

        const langCodes = TLVector.deserialized(_data, TLString);
        if (!langCodes) return undefined;

        return new GetEmojiKeywordsLanguages(langCodes);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetEmojiKeywordsLanguages.cons];

        _data.push(this.langCodes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly langCodes: TLVector<TLString>) {}
    }
  }

  export namespace messages {
    export class GetEmojiURL implements TLFunction<API.EmojiURL> {
      static readonly cons = new TLInt(0xd5b10c26);

      static deserialized(_data: ByteStream): GetEmojiURL | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetEmojiURL.cons))
          return undefined;

        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;

        return new GetEmojiURL(langCode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetEmojiURL.cons];

        _data.push(this.langCode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly langCode: TLString) {}
    }
  }

  export namespace messages {
    export class GetSearchCounters
      implements TLFunction<TLVector<messages.SearchCounter>>
    {
      static readonly cons = new TLInt(0xae7cc1);

      static deserialized(_data: ByteStream): GetSearchCounters | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSearchCounters.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let topMsgId: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          topMsgId = obj;
        }
        const filters = TLVector.deserialized(
          _data
        ) as TLVector<MessagesFilterType>;
        if (!filters) return undefined;

        return new GetSearchCounters(peer, topMsgId, filters);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSearchCounters.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        if (this.topMsgId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.topMsgId) _data.push(this.topMsgId);

        _data.push(this.filters);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly topMsgId: TLInt | undefined,
        readonly filters: TLVector<MessagesFilterType>
      ) {}
    }
  }

  export namespace messages {
    export class RequestUrlAuth implements TLFunction<UrlAuthResultType> {
      static readonly cons = new TLInt(0x198fb446);

      static deserialized(_data: ByteStream): RequestUrlAuth | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RequestUrlAuth.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let peer: InputPeerType | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          peer = obj;
        }
        let msgId: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          msgId = obj;
        }
        let buttonId: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          buttonId = obj;
        }
        let url: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          url = obj;
        }

        return new RequestUrlAuth(peer, msgId, buttonId, url);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RequestUrlAuth.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.peer) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.peer) _data.push(this.peer);

        if (this.msgId) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.msgId) _data.push(this.msgId);

        if (this.buttonId) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.buttonId) _data.push(this.buttonId);

        if (this.url) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.url) _data.push(this.url);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType | undefined,
        readonly msgId: TLInt | undefined,
        readonly buttonId: TLInt | undefined,
        readonly url: TLString | undefined
      ) {}
    }
  }

  export namespace messages {
    export class AcceptUrlAuth implements TLFunction<UrlAuthResultType> {
      static readonly cons = new TLInt(0xb12c7125);

      static deserialized(_data: ByteStream): AcceptUrlAuth | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AcceptUrlAuth.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const writeAllowed = (flags.value & 1) !== 0;
        let peer: InputPeerType | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          peer = obj;
        }
        let msgId: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          msgId = obj;
        }
        let buttonId: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          buttonId = obj;
        }
        let url: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          url = obj;
        }

        return new AcceptUrlAuth(writeAllowed, peer, msgId, buttonId, url);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AcceptUrlAuth.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.writeAllowed) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.peer) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.peer) _data.push(this.peer);

        if (this.msgId) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.msgId) _data.push(this.msgId);

        if (this.buttonId) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.buttonId) _data.push(this.buttonId);

        if (this.url) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.url) _data.push(this.url);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly writeAllowed: boolean | undefined,
        readonly peer: InputPeerType | undefined,
        readonly msgId: TLInt | undefined,
        readonly buttonId: TLInt | undefined,
        readonly url: TLString | undefined
      ) {}
    }
  }

  export namespace messages {
    export class HidePeerSettingsBar implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x4facb138);

      static deserialized(_data: ByteStream): HidePeerSettingsBar | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(HidePeerSettingsBar.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new HidePeerSettingsBar(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [HidePeerSettingsBar.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace messages {
    export class GetScheduledHistory
      implements TLFunction<messages.MessagesType>
    {
      static readonly cons = new TLInt(0xf516760b);

      static deserialized(_data: ByteStream): GetScheduledHistory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetScheduledHistory.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetScheduledHistory(peer, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetScheduledHistory.cons];

        _data.push(this.peer);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class GetScheduledMessages
      implements TLFunction<messages.MessagesType>
    {
      static readonly cons = new TLInt(0xbdbb0464);

      static deserialized(_data: ByteStream): GetScheduledMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetScheduledMessages.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new GetScheduledMessages(peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetScheduledMessages.cons];

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly id: TLVector<TLInt>) {}
    }
  }

  export namespace messages {
    export class SendScheduledMessages implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xbd38850a);

      static deserialized(
        _data: ByteStream
      ): SendScheduledMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendScheduledMessages.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new SendScheduledMessages(peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendScheduledMessages.cons];

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly id: TLVector<TLInt>) {}
    }
  }

  export namespace messages {
    export class DeleteScheduledMessages implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x59ae2b16);

      static deserialized(
        _data: ByteStream
      ): DeleteScheduledMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteScheduledMessages.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new DeleteScheduledMessages(peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteScheduledMessages.cons];

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly id: TLVector<TLInt>) {}
    }
  }

  export namespace messages {
    export class GetPollVotes implements TLFunction<messages.VotesList> {
      static readonly cons = new TLInt(0xb86e380e);

      static deserialized(_data: ByteStream): GetPollVotes | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPollVotes.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;
        let option: TLBytes | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLBytes.deserialized(_data);
          if (!obj) return undefined;
          option = obj;
        }
        let offset: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          offset = obj;
        }
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetPollVotes(peer, id, option, offset, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPollVotes.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        _data.push(this.id);
        if (this.option) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.option) _data.push(this.option);

        if (this.offset) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.offset) _data.push(this.offset);

        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly id: TLInt,
        readonly option: TLBytes | undefined,
        readonly offset: TLString | undefined,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class ToggleStickerSets implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xb5052fea);

      static deserialized(_data: ByteStream): ToggleStickerSets | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleStickerSets.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const uninstall = (flags.value & 1) !== 0;
        const archive = (flags.value & 2) !== 0;
        const unarchive = (flags.value & 4) !== 0;
        const stickersets = TLVector.deserialized(
          _data
        ) as TLVector<InputStickerSetType>;
        if (!stickersets) return undefined;

        return new ToggleStickerSets(
          uninstall,
          archive,
          unarchive,
          stickersets
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleStickerSets.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.uninstall) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.archive) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.unarchive) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.stickersets);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly uninstall: boolean | undefined,
        readonly archive: boolean | undefined,
        readonly unarchive: boolean | undefined,
        readonly stickersets: TLVector<InputStickerSetType>
      ) {}
    }
  }

  export namespace messages {
    export class GetDialogFilters
      implements TLFunction<TLVector<DialogFilterType>>
    {
      static readonly cons = new TLInt(0xf19ed96d);

      static deserialized(_data: ByteStream): GetDialogFilters | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetDialogFilters.cons))
          return undefined;

        return new GetDialogFilters();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDialogFilters.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class GetSuggestedDialogFilters
      implements TLFunction<TLVector<API.DialogFilterSuggested>>
    {
      static readonly cons = new TLInt(0xa29cd42c);

      static deserialized(
        _data: ByteStream
      ): GetSuggestedDialogFilters | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSuggestedDialogFilters.cons))
          return undefined;

        return new GetSuggestedDialogFilters();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSuggestedDialogFilters.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class UpdateDialogFilter implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x1ad4a04a);

      static deserialized(_data: ByteStream): UpdateDialogFilter | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdateDialogFilter.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;
        let filter: DialogFilterType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as DialogFilterType;
          if (!obj) return undefined;
          filter = obj;
        }

        return new UpdateDialogFilter(id, filter);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdateDialogFilter.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.id);
        if (this.filter) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.filter) _data.push(this.filter);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly id: TLInt,
        readonly filter: DialogFilterType | undefined
      ) {}
    }
  }

  export namespace messages {
    export class UpdateDialogFiltersOrder implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xc563c1e4);

      static deserialized(
        _data: ByteStream
      ): UpdateDialogFiltersOrder | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdateDialogFiltersOrder.cons))
          return undefined;

        const order = TLVector.deserialized(_data, TLInt);
        if (!order) return undefined;

        return new UpdateDialogFiltersOrder(order);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdateDialogFiltersOrder.cons];

        _data.push(this.order);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly order: TLVector<TLInt>) {}
    }
  }

  export namespace messages {
    export class GetOldFeaturedStickers
      implements TLFunction<messages.FeaturedStickersType>
    {
      static readonly cons = new TLInt(0x7ed094a1);

      static deserialized(
        _data: ByteStream
      ): GetOldFeaturedStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetOldFeaturedStickers.cons))
          return undefined;

        const offset = TLInt.deserialized(_data);
        if (!offset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetOldFeaturedStickers(offset, limit, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetOldFeaturedStickers.cons];

        _data.push(this.offset);
        _data.push(this.limit);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly offset: TLInt,
        readonly limit: TLInt,
        readonly hash: TLLong
      ) {}
    }
  }

  export namespace messages {
    export class GetReplies implements TLFunction<messages.MessagesType> {
      static readonly cons = new TLInt(0x22ddd30c);

      static deserialized(_data: ByteStream): GetReplies | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetReplies.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const offsetDate = TLInt.deserialized(_data);
        if (!offsetDate) return undefined;
        const addOffset = TLInt.deserialized(_data);
        if (!addOffset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const maxId = TLInt.deserialized(_data);
        if (!maxId) return undefined;
        const minId = TLInt.deserialized(_data);
        if (!minId) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetReplies(
          peer,
          msgId,
          offsetId,
          offsetDate,
          addOffset,
          limit,
          maxId,
          minId,
          hash
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetReplies.cons];

        _data.push(this.peer);
        _data.push(this.msgId);
        _data.push(this.offsetId);
        _data.push(this.offsetDate);
        _data.push(this.addOffset);
        _data.push(this.limit);
        _data.push(this.maxId);
        _data.push(this.minId);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly msgId: TLInt,
        readonly offsetId: TLInt,
        readonly offsetDate: TLInt,
        readonly addOffset: TLInt,
        readonly limit: TLInt,
        readonly maxId: TLInt,
        readonly minId: TLInt,
        readonly hash: TLLong
      ) {}
    }
  }

  export namespace messages {
    export class GetDiscussionMessage
      implements TLFunction<messages.DiscussionMessage>
    {
      static readonly cons = new TLInt(0x446972fd);

      static deserialized(_data: ByteStream): GetDiscussionMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetDiscussionMessage.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;

        return new GetDiscussionMessage(peer, msgId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDiscussionMessage.cons];

        _data.push(this.peer);
        _data.push(this.msgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly msgId: TLInt) {}
    }
  }

  export namespace messages {
    export class ReadDiscussion implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xf731a9f4);

      static deserialized(_data: ByteStream): ReadDiscussion | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReadDiscussion.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;
        const readMaxId = TLInt.deserialized(_data);
        if (!readMaxId) return undefined;

        return new ReadDiscussion(peer, msgId, readMaxId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReadDiscussion.cons];

        _data.push(this.peer);
        _data.push(this.msgId);
        _data.push(this.readMaxId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly msgId: TLInt,
        readonly readMaxId: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class UnpinAllMessages
      implements TLFunction<messages.AffectedHistory>
    {
      static readonly cons = new TLInt(0xee22b9a8);

      static deserialized(_data: ByteStream): UnpinAllMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UnpinAllMessages.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let topMsgId: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          topMsgId = obj;
        }

        return new UnpinAllMessages(peer, topMsgId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UnpinAllMessages.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        if (this.topMsgId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.topMsgId) _data.push(this.topMsgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly topMsgId: TLInt | undefined
      ) {}
    }
  }

  export namespace messages {
    export class DeleteChat implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x5bd0ee50);

      static deserialized(_data: ByteStream): DeleteChat | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteChat.cons))
          return undefined;

        const chatId = TLLong.deserialized(_data);
        if (!chatId) return undefined;

        return new DeleteChat(chatId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteChat.cons];

        _data.push(this.chatId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly chatId: TLLong) {}
    }
  }

  export namespace messages {
    export class DeletePhoneCallHistory
      implements TLFunction<messages.AffectedFoundMessages>
    {
      static readonly cons = new TLInt(0xf9cbe409);

      static deserialized(
        _data: ByteStream
      ): DeletePhoneCallHistory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeletePhoneCallHistory.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const revoke = (flags.value & 1) !== 0;

        return new DeletePhoneCallHistory(revoke);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeletePhoneCallHistory.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.revoke) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly revoke: boolean | undefined) {}
    }
  }

  export namespace messages {
    export class CheckHistoryImport
      implements TLFunction<messages.HistoryImportParsed>
    {
      static readonly cons = new TLInt(0x43fe19f3);

      static deserialized(_data: ByteStream): CheckHistoryImport | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CheckHistoryImport.cons))
          return undefined;

        const importHead = TLString.deserialized(_data);
        if (!importHead) return undefined;

        return new CheckHistoryImport(importHead);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CheckHistoryImport.cons];

        _data.push(this.importHead);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly importHead: TLString) {}
    }
  }

  export namespace messages {
    export class InitHistoryImport
      implements TLFunction<messages.HistoryImport>
    {
      static readonly cons = new TLInt(0x34090c3b);

      static deserialized(_data: ByteStream): InitHistoryImport | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(InitHistoryImport.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const file = TLObject.deserialized(_data) as InputFileType;
        if (!file) return undefined;
        const mediaCount = TLInt.deserialized(_data);
        if (!mediaCount) return undefined;

        return new InitHistoryImport(peer, file, mediaCount);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [InitHistoryImport.cons];

        _data.push(this.peer);
        _data.push(this.file);
        _data.push(this.mediaCount);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly file: InputFileType,
        readonly mediaCount: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class UploadImportedMedia implements TLFunction<MessageMediaType> {
      static readonly cons = new TLInt(0x2a862092);

      static deserialized(_data: ByteStream): UploadImportedMedia | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UploadImportedMedia.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const importId = TLLong.deserialized(_data);
        if (!importId) return undefined;
        const fileName = TLString.deserialized(_data);
        if (!fileName) return undefined;
        const media = TLObject.deserialized(_data) as InputMediaType;
        if (!media) return undefined;

        return new UploadImportedMedia(peer, importId, fileName, media);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UploadImportedMedia.cons];

        _data.push(this.peer);
        _data.push(this.importId);
        _data.push(this.fileName);
        _data.push(this.media);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly importId: TLLong,
        readonly fileName: TLString,
        readonly media: InputMediaType
      ) {}
    }
  }

  export namespace messages {
    export class StartHistoryImport implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xb43df344);

      static deserialized(_data: ByteStream): StartHistoryImport | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(StartHistoryImport.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const importId = TLLong.deserialized(_data);
        if (!importId) return undefined;

        return new StartHistoryImport(peer, importId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [StartHistoryImport.cons];

        _data.push(this.peer);
        _data.push(this.importId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly importId: TLLong) {}
    }
  }

  export namespace messages {
    export class GetExportedChatInvites
      implements TLFunction<messages.ExportedChatInvites>
    {
      static readonly cons = new TLInt(0xa2b5a3f6);

      static deserialized(
        _data: ByteStream
      ): GetExportedChatInvites | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetExportedChatInvites.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const revoked = (flags.value & 8) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const adminId = TLObject.deserialized(_data) as InputUserType;
        if (!adminId) return undefined;
        let offsetDate: TLInt | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          offsetDate = obj;
        }
        let offsetLink: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          offsetLink = obj;
        }
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetExportedChatInvites(
          revoked,
          peer,
          adminId,
          offsetDate,
          offsetLink,
          limit
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetExportedChatInvites.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.revoked) {
          flags.set(8);
        } else {
          flags.unset(8);
        }

        _data.push(this.peer);
        _data.push(this.adminId);
        if (this.offsetDate) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.offsetDate) _data.push(this.offsetDate);

        if (this.offsetLink) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.offsetLink) _data.push(this.offsetLink);

        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly revoked: boolean | undefined,
        readonly peer: InputPeerType,
        readonly adminId: InputUserType,
        readonly offsetDate: TLInt | undefined,
        readonly offsetLink: TLString | undefined,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class GetExportedChatInvite
      implements TLFunction<messages.ExportedChatInviteType>
    {
      static readonly cons = new TLInt(0x73746f5c);

      static deserialized(
        _data: ByteStream
      ): GetExportedChatInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetExportedChatInvite.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const link = TLString.deserialized(_data);
        if (!link) return undefined;

        return new GetExportedChatInvite(peer, link);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetExportedChatInvite.cons];

        _data.push(this.peer);
        _data.push(this.link);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly link: TLString) {}
    }
  }

  export namespace messages {
    export class EditExportedChatInvite
      implements TLFunction<messages.ExportedChatInviteType>
    {
      static readonly cons = new TLInt(0xbdca2f75);

      static deserialized(
        _data: ByteStream
      ): EditExportedChatInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditExportedChatInvite.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const revoked = (flags.value & 4) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const link = TLString.deserialized(_data);
        if (!link) return undefined;
        let expireDate: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          expireDate = obj;
        }
        let usageLimit: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          usageLimit = obj;
        }
        let requestNeeded: BoolType | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          requestNeeded = obj;
        }
        let title: TLString | undefined;
        if ((flags.value & 16) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          title = obj;
        }

        return new EditExportedChatInvite(
          revoked,
          peer,
          link,
          expireDate,
          usageLimit,
          requestNeeded,
          title
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditExportedChatInvite.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.revoked) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.peer);
        _data.push(this.link);
        if (this.expireDate) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.expireDate) _data.push(this.expireDate);

        if (this.usageLimit) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.usageLimit) _data.push(this.usageLimit);

        if (this.requestNeeded) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.requestNeeded) _data.push(this.requestNeeded);

        if (this.title) {
          flags.set(16);
        } else {
          flags.unset(16);
        }
        if (this.title) _data.push(this.title);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly revoked: boolean | undefined,
        readonly peer: InputPeerType,
        readonly link: TLString,
        readonly expireDate: TLInt | undefined,
        readonly usageLimit: TLInt | undefined,
        readonly requestNeeded: BoolType | undefined,
        readonly title: TLString | undefined
      ) {}
    }
  }

  export namespace messages {
    export class DeleteRevokedExportedChatInvites
      implements TLFunction<BoolType>
    {
      static readonly cons = new TLInt(0x56987bd5);

      static deserialized(
        _data: ByteStream
      ): DeleteRevokedExportedChatInvites | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(DeleteRevokedExportedChatInvites.cons)
        )
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const adminId = TLObject.deserialized(_data) as InputUserType;
        if (!adminId) return undefined;

        return new DeleteRevokedExportedChatInvites(peer, adminId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          DeleteRevokedExportedChatInvites.cons,
        ];

        _data.push(this.peer);
        _data.push(this.adminId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly adminId: InputUserType
      ) {}
    }
  }

  export namespace messages {
    export class DeleteExportedChatInvite implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xd464a42b);

      static deserialized(
        _data: ByteStream
      ): DeleteExportedChatInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteExportedChatInvite.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const link = TLString.deserialized(_data);
        if (!link) return undefined;

        return new DeleteExportedChatInvite(peer, link);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteExportedChatInvite.cons];

        _data.push(this.peer);
        _data.push(this.link);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly link: TLString) {}
    }
  }

  export namespace messages {
    export class GetAdminsWithInvites
      implements TLFunction<messages.ChatAdminsWithInvites>
    {
      static readonly cons = new TLInt(0x3920e6ef);

      static deserialized(_data: ByteStream): GetAdminsWithInvites | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAdminsWithInvites.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new GetAdminsWithInvites(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAdminsWithInvites.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace messages {
    export class GetChatInviteImporters
      implements TLFunction<messages.ChatInviteImporters>
    {
      static readonly cons = new TLInt(0xdf04dd4e);

      static deserialized(
        _data: ByteStream
      ): GetChatInviteImporters | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetChatInviteImporters.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const requested = (flags.value & 1) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let link: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          link = obj;
        }
        let q: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          q = obj;
        }
        const offsetDate = TLInt.deserialized(_data);
        if (!offsetDate) return undefined;
        const offsetUser = TLObject.deserialized(_data) as InputUserType;
        if (!offsetUser) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetChatInviteImporters(
          requested,
          peer,
          link,
          q,
          offsetDate,
          offsetUser,
          limit
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetChatInviteImporters.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.requested) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);
        if (this.link) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.link) _data.push(this.link);

        if (this.q) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.q) _data.push(this.q);

        _data.push(this.offsetDate);
        _data.push(this.offsetUser);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly requested: boolean | undefined,
        readonly peer: InputPeerType,
        readonly link: TLString | undefined,
        readonly q: TLString | undefined,
        readonly offsetDate: TLInt,
        readonly offsetUser: InputUserType,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class SetHistoryTTL implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xb80e5fe4);

      static deserialized(_data: ByteStream): SetHistoryTTL | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetHistoryTTL.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const period = TLInt.deserialized(_data);
        if (!period) return undefined;

        return new SetHistoryTTL(peer, period);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetHistoryTTL.cons];

        _data.push(this.peer);
        _data.push(this.period);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly period: TLInt) {}
    }
  }

  export namespace messages {
    export class CheckHistoryImportPeer
      implements TLFunction<messages.CheckedHistoryImportPeer>
    {
      static readonly cons = new TLInt(0x5dc60f03);

      static deserialized(
        _data: ByteStream
      ): CheckHistoryImportPeer | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CheckHistoryImportPeer.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new CheckHistoryImportPeer(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CheckHistoryImportPeer.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace messages {
    export class SetChatTheme implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xe63be13f);

      static deserialized(_data: ByteStream): SetChatTheme | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetChatTheme.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const emoticon = TLString.deserialized(_data);
        if (!emoticon) return undefined;

        return new SetChatTheme(peer, emoticon);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetChatTheme.cons];

        _data.push(this.peer);
        _data.push(this.emoticon);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly emoticon: TLString) {}
    }
  }

  export namespace messages {
    export class GetMessageReadParticipants
      implements TLFunction<TLVector<API.ReadParticipantDate>>
    {
      static readonly cons = new TLInt(0x31c1c44f);

      static deserialized(
        _data: ByteStream
      ): GetMessageReadParticipants | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(GetMessageReadParticipants.cons)
        )
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;

        return new GetMessageReadParticipants(peer, msgId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMessageReadParticipants.cons];

        _data.push(this.peer);
        _data.push(this.msgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly msgId: TLInt) {}
    }
  }

  export namespace messages {
    export class GetSearchResultsCalendar
      implements TLFunction<messages.SearchResultsCalendar>
    {
      static readonly cons = new TLInt(0x49f0bde9);

      static deserialized(
        _data: ByteStream
      ): GetSearchResultsCalendar | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSearchResultsCalendar.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const filter = TLObject.deserialized(_data) as MessagesFilterType;
        if (!filter) return undefined;
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const offsetDate = TLInt.deserialized(_data);
        if (!offsetDate) return undefined;

        return new GetSearchResultsCalendar(peer, filter, offsetId, offsetDate);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSearchResultsCalendar.cons];

        _data.push(this.peer);
        _data.push(this.filter);
        _data.push(this.offsetId);
        _data.push(this.offsetDate);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly filter: MessagesFilterType,
        readonly offsetId: TLInt,
        readonly offsetDate: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class GetSearchResultsPositions
      implements TLFunction<messages.SearchResultsPositions>
    {
      static readonly cons = new TLInt(0x6e9583a3);

      static deserialized(
        _data: ByteStream
      ): GetSearchResultsPositions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSearchResultsPositions.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const filter = TLObject.deserialized(_data) as MessagesFilterType;
        if (!filter) return undefined;
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetSearchResultsPositions(peer, filter, offsetId, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSearchResultsPositions.cons];

        _data.push(this.peer);
        _data.push(this.filter);
        _data.push(this.offsetId);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly filter: MessagesFilterType,
        readonly offsetId: TLInt,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class HideChatJoinRequest implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x7fe7e815);

      static deserialized(_data: ByteStream): HideChatJoinRequest | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(HideChatJoinRequest.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const approved = (flags.value & 1) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;

        return new HideChatJoinRequest(approved, peer, userId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [HideChatJoinRequest.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.approved) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);
        _data.push(this.userId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly approved: boolean | undefined,
        readonly peer: InputPeerType,
        readonly userId: InputUserType
      ) {}
    }
  }

  export namespace messages {
    export class HideAllChatJoinRequests implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xe085f4ea);

      static deserialized(
        _data: ByteStream
      ): HideAllChatJoinRequests | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(HideAllChatJoinRequests.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const approved = (flags.value & 1) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let link: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          link = obj;
        }

        return new HideAllChatJoinRequests(approved, peer, link);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [HideAllChatJoinRequests.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.approved) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);
        if (this.link) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.link) _data.push(this.link);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly approved: boolean | undefined,
        readonly peer: InputPeerType,
        readonly link: TLString | undefined
      ) {}
    }
  }

  export namespace messages {
    export class ToggleNoForwards implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xb11eafa2);

      static deserialized(_data: ByteStream): ToggleNoForwards | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleNoForwards.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const enabled = TLObject.deserialized(_data) as BoolType;
        if (!enabled) return undefined;

        return new ToggleNoForwards(peer, enabled);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleNoForwards.cons];

        _data.push(this.peer);
        _data.push(this.enabled);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly enabled: BoolType) {}
    }
  }

  export namespace messages {
    export class SaveDefaultSendAs implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xccfddf96);

      static deserialized(_data: ByteStream): SaveDefaultSendAs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveDefaultSendAs.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const sendAs = TLObject.deserialized(_data) as InputPeerType;
        if (!sendAs) return undefined;

        return new SaveDefaultSendAs(peer, sendAs);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveDefaultSendAs.cons];

        _data.push(this.peer);
        _data.push(this.sendAs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly sendAs: InputPeerType
      ) {}
    }
  }

  export namespace messages {
    export class SendReaction implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xd30d78d4);

      static deserialized(_data: ByteStream): SendReaction | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendReaction.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const big = (flags.value & 2) !== 0;
        const addToRecent = (flags.value & 4) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;
        let reaction: TLVector<ReactionType> | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLVector.deserialized(_data) as TLVector<ReactionType>;
          if (!obj) return undefined;
          reaction = obj;
        }

        return new SendReaction(big, addToRecent, peer, msgId, reaction);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendReaction.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.big) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.addToRecent) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.peer);
        _data.push(this.msgId);
        if (this.reaction) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.reaction) _data.push(this.reaction);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly big: boolean | undefined,
        readonly addToRecent: boolean | undefined,
        readonly peer: InputPeerType,
        readonly msgId: TLInt,
        readonly reaction: TLVector<ReactionType> | undefined
      ) {}
    }
  }

  export namespace messages {
    export class GetMessagesReactions implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x8bba90e6);

      static deserialized(_data: ByteStream): GetMessagesReactions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetMessagesReactions.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new GetMessagesReactions(peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMessagesReactions.cons];

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly id: TLVector<TLInt>) {}
    }
  }

  export namespace messages {
    export class GetMessageReactionsList
      implements TLFunction<messages.MessageReactionsList>
    {
      static readonly cons = new TLInt(0x461b3f48);

      static deserialized(
        _data: ByteStream
      ): GetMessageReactionsList | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetMessageReactionsList.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;
        let reaction: ReactionType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as ReactionType;
          if (!obj) return undefined;
          reaction = obj;
        }
        let offset: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          offset = obj;
        }
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetMessageReactionsList(peer, id, reaction, offset, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMessageReactionsList.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        _data.push(this.id);
        if (this.reaction) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.reaction) _data.push(this.reaction);

        if (this.offset) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.offset) _data.push(this.offset);

        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly id: TLInt,
        readonly reaction: ReactionType | undefined,
        readonly offset: TLString | undefined,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class SetChatAvailableReactions implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xfeb16771);

      static deserialized(
        _data: ByteStream
      ): SetChatAvailableReactions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetChatAvailableReactions.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const availableReactions = TLObject.deserialized(
          _data
        ) as ChatReactionsType;
        if (!availableReactions) return undefined;

        return new SetChatAvailableReactions(peer, availableReactions);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetChatAvailableReactions.cons];

        _data.push(this.peer);
        _data.push(this.availableReactions);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly availableReactions: ChatReactionsType
      ) {}
    }
  }

  export namespace messages {
    export class GetAvailableReactions
      implements TLFunction<messages.AvailableReactionsType>
    {
      static readonly cons = new TLInt(0x18dea0ac);

      static deserialized(
        _data: ByteStream
      ): GetAvailableReactions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAvailableReactions.cons))
          return undefined;

        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;

        return new GetAvailableReactions(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAvailableReactions.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLInt) {}
    }
  }

  export namespace messages {
    export class SetDefaultReaction implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x4f47a016);

      static deserialized(_data: ByteStream): SetDefaultReaction | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetDefaultReaction.cons))
          return undefined;

        const reaction = TLObject.deserialized(_data) as ReactionType;
        if (!reaction) return undefined;

        return new SetDefaultReaction(reaction);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetDefaultReaction.cons];

        _data.push(this.reaction);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly reaction: ReactionType) {}
    }
  }

  export namespace messages {
    export class TranslateText
      implements TLFunction<messages.TranslatedTextType>
    {
      static readonly cons = new TLInt(0x63183030);

      static deserialized(_data: ByteStream): TranslateText | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TranslateText.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let peer: InputPeerType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          peer = obj;
        }
        let id: TLVector<TLInt> | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLVector.deserialized(_data, TLInt);
          if (!obj) return undefined;
          id = obj;
        }
        let text: TLVector<API.TextWithEntities> | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLVector.deserialized(_data, API.TextWithEntities);
          if (!obj) return undefined;
          text = obj;
        }
        const toLang = TLString.deserialized(_data);
        if (!toLang) return undefined;

        return new TranslateText(peer, id, text, toLang);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TranslateText.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.peer) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.peer) _data.push(this.peer);

        if (this.id) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.id) _data.push(this.id);

        if (this.text) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.text) _data.push(this.text);

        _data.push(this.toLang);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType | undefined,
        readonly id: TLVector<TLInt> | undefined,
        readonly text: TLVector<API.TextWithEntities> | undefined,
        readonly toLang: TLString
      ) {}
    }
  }

  export namespace messages {
    export class GetUnreadReactions
      implements TLFunction<messages.MessagesType>
    {
      static readonly cons = new TLInt(0x3223495b);

      static deserialized(_data: ByteStream): GetUnreadReactions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetUnreadReactions.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let topMsgId: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          topMsgId = obj;
        }
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const addOffset = TLInt.deserialized(_data);
        if (!addOffset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const maxId = TLInt.deserialized(_data);
        if (!maxId) return undefined;
        const minId = TLInt.deserialized(_data);
        if (!minId) return undefined;

        return new GetUnreadReactions(
          peer,
          topMsgId,
          offsetId,
          addOffset,
          limit,
          maxId,
          minId
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetUnreadReactions.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        if (this.topMsgId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.topMsgId) _data.push(this.topMsgId);

        _data.push(this.offsetId);
        _data.push(this.addOffset);
        _data.push(this.limit);
        _data.push(this.maxId);
        _data.push(this.minId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly topMsgId: TLInt | undefined,
        readonly offsetId: TLInt,
        readonly addOffset: TLInt,
        readonly limit: TLInt,
        readonly maxId: TLInt,
        readonly minId: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class ReadReactions implements TLFunction<messages.AffectedHistory> {
      static readonly cons = new TLInt(0x54aa7f8e);

      static deserialized(_data: ByteStream): ReadReactions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReadReactions.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let topMsgId: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          topMsgId = obj;
        }

        return new ReadReactions(peer, topMsgId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReadReactions.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        if (this.topMsgId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.topMsgId) _data.push(this.topMsgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly topMsgId: TLInt | undefined
      ) {}
    }
  }

  export namespace messages {
    export class SearchSentMedia implements TLFunction<messages.MessagesType> {
      static readonly cons = new TLInt(0x107e31a0);

      static deserialized(_data: ByteStream): SearchSentMedia | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SearchSentMedia.cons))
          return undefined;

        const q = TLString.deserialized(_data);
        if (!q) return undefined;
        const filter = TLObject.deserialized(_data) as MessagesFilterType;
        if (!filter) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new SearchSentMedia(q, filter, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SearchSentMedia.cons];

        _data.push(this.q);
        _data.push(this.filter);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly q: TLString,
        readonly filter: MessagesFilterType,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace messages {
    export class GetAttachMenuBots implements TLFunction<AttachMenuBotsType> {
      static readonly cons = new TLInt(0x16fcc2cb);

      static deserialized(_data: ByteStream): GetAttachMenuBots | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAttachMenuBots.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetAttachMenuBots(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAttachMenuBots.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class GetAttachMenuBot implements TLFunction<API.AttachMenuBotsBot> {
      static readonly cons = new TLInt(0x77216192);

      static deserialized(_data: ByteStream): GetAttachMenuBot | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAttachMenuBot.cons))
          return undefined;

        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;

        return new GetAttachMenuBot(bot);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAttachMenuBot.cons];

        _data.push(this.bot);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly bot: InputUserType) {}
    }
  }

  export namespace messages {
    export class ToggleBotInAttachMenu implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x69f59d69);

      static deserialized(
        _data: ByteStream
      ): ToggleBotInAttachMenu | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleBotInAttachMenu.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const writeAllowed = (flags.value & 1) !== 0;
        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;
        const enabled = TLObject.deserialized(_data) as BoolType;
        if (!enabled) return undefined;

        return new ToggleBotInAttachMenu(writeAllowed, bot, enabled);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleBotInAttachMenu.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.writeAllowed) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.bot);
        _data.push(this.enabled);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly writeAllowed: boolean | undefined,
        readonly bot: InputUserType,
        readonly enabled: BoolType
      ) {}
    }
  }

  export namespace messages {
    export class RequestWebView implements TLFunction<WebViewResultType> {
      static readonly cons = new TLInt(0x269dc2c1);

      static deserialized(_data: ByteStream): RequestWebView | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RequestWebView.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const fromBotMenu = (flags.value & 16) !== 0;
        const silent = (flags.value & 32) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;
        let url: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          url = obj;
        }
        let startParam: TLString | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          startParam = obj;
        }
        let themeParams: API.DataJSON | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as API.DataJSON;
          if (!obj) return undefined;
          themeParams = obj;
        }
        const platform = TLString.deserialized(_data);
        if (!platform) return undefined;
        let replyTo: InputReplyToType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputReplyToType;
          if (!obj) return undefined;
          replyTo = obj;
        }
        let sendAs: InputPeerType | undefined;
        if ((flags.value & 8192) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          sendAs = obj;
        }

        return new RequestWebView(
          fromBotMenu,
          silent,
          peer,
          bot,
          url,
          startParam,
          themeParams,
          platform,
          replyTo,
          sendAs
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RequestWebView.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.fromBotMenu) {
          flags.set(16);
        } else {
          flags.unset(16);
        }

        if (this.silent) {
          flags.set(32);
        } else {
          flags.unset(32);
        }

        _data.push(this.peer);
        _data.push(this.bot);
        if (this.url) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.url) _data.push(this.url);

        if (this.startParam) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.startParam) _data.push(this.startParam);

        if (this.themeParams) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.themeParams) _data.push(this.themeParams);

        _data.push(this.platform);
        if (this.replyTo) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.replyTo) _data.push(this.replyTo);

        if (this.sendAs) {
          flags.set(8192);
        } else {
          flags.unset(8192);
        }
        if (this.sendAs) _data.push(this.sendAs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly fromBotMenu: boolean | undefined,
        readonly silent: boolean | undefined,
        readonly peer: InputPeerType,
        readonly bot: InputUserType,
        readonly url: TLString | undefined,
        readonly startParam: TLString | undefined,
        readonly themeParams: API.DataJSON | undefined,
        readonly platform: TLString,
        readonly replyTo: InputReplyToType | undefined,
        readonly sendAs: InputPeerType | undefined
      ) {}
    }
  }

  export namespace messages {
    export class ProlongWebView implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xb0d81a83);

      static deserialized(_data: ByteStream): ProlongWebView | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ProlongWebView.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const silent = (flags.value & 32) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;
        const queryId = TLLong.deserialized(_data);
        if (!queryId) return undefined;
        let replyTo: InputReplyToType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputReplyToType;
          if (!obj) return undefined;
          replyTo = obj;
        }
        let sendAs: InputPeerType | undefined;
        if ((flags.value & 8192) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          sendAs = obj;
        }

        return new ProlongWebView(silent, peer, bot, queryId, replyTo, sendAs);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ProlongWebView.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.silent) {
          flags.set(32);
        } else {
          flags.unset(32);
        }

        _data.push(this.peer);
        _data.push(this.bot);
        _data.push(this.queryId);
        if (this.replyTo) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.replyTo) _data.push(this.replyTo);

        if (this.sendAs) {
          flags.set(8192);
        } else {
          flags.unset(8192);
        }
        if (this.sendAs) _data.push(this.sendAs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly silent: boolean | undefined,
        readonly peer: InputPeerType,
        readonly bot: InputUserType,
        readonly queryId: TLLong,
        readonly replyTo: InputReplyToType | undefined,
        readonly sendAs: InputPeerType | undefined
      ) {}
    }
  }

  export namespace messages {
    export class RequestSimpleWebView
      implements TLFunction<SimpleWebViewResultType>
    {
      static readonly cons = new TLInt(0x1a46500a);

      static deserialized(_data: ByteStream): RequestSimpleWebView | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RequestSimpleWebView.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const fromSwitchWebview = (flags.value & 2) !== 0;
        const fromSideMenu = (flags.value & 4) !== 0;
        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;
        let url: TLString | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          url = obj;
        }
        let startParam: TLString | undefined;
        if ((flags.value & 16) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          startParam = obj;
        }
        let themeParams: API.DataJSON | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as API.DataJSON;
          if (!obj) return undefined;
          themeParams = obj;
        }
        const platform = TLString.deserialized(_data);
        if (!platform) return undefined;

        return new RequestSimpleWebView(
          fromSwitchWebview,
          fromSideMenu,
          bot,
          url,
          startParam,
          themeParams,
          platform
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RequestSimpleWebView.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.fromSwitchWebview) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.fromSideMenu) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.bot);
        if (this.url) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.url) _data.push(this.url);

        if (this.startParam) {
          flags.set(16);
        } else {
          flags.unset(16);
        }
        if (this.startParam) _data.push(this.startParam);

        if (this.themeParams) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.themeParams) _data.push(this.themeParams);

        _data.push(this.platform);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly fromSwitchWebview: boolean | undefined,
        readonly fromSideMenu: boolean | undefined,
        readonly bot: InputUserType,
        readonly url: TLString | undefined,
        readonly startParam: TLString | undefined,
        readonly themeParams: API.DataJSON | undefined,
        readonly platform: TLString
      ) {}
    }
  }

  export namespace messages {
    export class SendWebViewResultMessage
      implements TLFunction<API.WebViewMessageSent>
    {
      static readonly cons = new TLInt(0xa4314f5);

      static deserialized(
        _data: ByteStream
      ): SendWebViewResultMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendWebViewResultMessage.cons))
          return undefined;

        const botQueryId = TLString.deserialized(_data);
        if (!botQueryId) return undefined;
        const result = TLObject.deserialized(_data) as InputBotInlineResultType;
        if (!result) return undefined;

        return new SendWebViewResultMessage(botQueryId, result);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendWebViewResultMessage.cons];

        _data.push(this.botQueryId);
        _data.push(this.result);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly botQueryId: TLString,
        readonly result: InputBotInlineResultType
      ) {}
    }
  }

  export namespace messages {
    export class SendWebViewData implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xdc0242c8);

      static deserialized(_data: ByteStream): SendWebViewData | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendWebViewData.cons))
          return undefined;

        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;
        const randomId = TLLong.deserialized(_data);
        if (!randomId) return undefined;
        const buttonText = TLString.deserialized(_data);
        if (!buttonText) return undefined;
        const data = TLString.deserialized(_data);
        if (!data) return undefined;

        return new SendWebViewData(bot, randomId, buttonText, data);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendWebViewData.cons];

        _data.push(this.bot);
        _data.push(this.randomId);
        _data.push(this.buttonText);
        _data.push(this.data);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly bot: InputUserType,
        readonly randomId: TLLong,
        readonly buttonText: TLString,
        readonly data: TLString
      ) {}
    }
  }

  export namespace messages {
    export class TranscribeAudio
      implements TLFunction<messages.TranscribedAudio>
    {
      static readonly cons = new TLInt(0x269e9a49);

      static deserialized(_data: ByteStream): TranscribeAudio | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TranscribeAudio.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;

        return new TranscribeAudio(peer, msgId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TranscribeAudio.cons];

        _data.push(this.peer);
        _data.push(this.msgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly msgId: TLInt) {}
    }
  }

  export namespace messages {
    export class RateTranscribedAudio implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x7f1d072f);

      static deserialized(_data: ByteStream): RateTranscribedAudio | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RateTranscribedAudio.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;
        const transcriptionId = TLLong.deserialized(_data);
        if (!transcriptionId) return undefined;
        const good = TLObject.deserialized(_data) as BoolType;
        if (!good) return undefined;

        return new RateTranscribedAudio(peer, msgId, transcriptionId, good);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RateTranscribedAudio.cons];

        _data.push(this.peer);
        _data.push(this.msgId);
        _data.push(this.transcriptionId);
        _data.push(this.good);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly msgId: TLInt,
        readonly transcriptionId: TLLong,
        readonly good: BoolType
      ) {}
    }
  }

  export namespace messages {
    export class GetCustomEmojiDocuments
      implements TLFunction<TLVector<DocumentType>>
    {
      static readonly cons = new TLInt(0xd9ab0f54);

      static deserialized(
        _data: ByteStream
      ): GetCustomEmojiDocuments | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetCustomEmojiDocuments.cons))
          return undefined;

        const documentId = TLVector.deserialized(_data, TLLong);
        if (!documentId) return undefined;

        return new GetCustomEmojiDocuments(documentId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetCustomEmojiDocuments.cons];

        _data.push(this.documentId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly documentId: TLVector<TLLong>) {}
    }
  }

  export namespace messages {
    export class GetEmojiStickers
      implements TLFunction<messages.AllStickersType>
    {
      static readonly cons = new TLInt(0xfbfca18f);

      static deserialized(_data: ByteStream): GetEmojiStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetEmojiStickers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetEmojiStickers(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetEmojiStickers.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class GetFeaturedEmojiStickers
      implements TLFunction<messages.FeaturedStickersType>
    {
      static readonly cons = new TLInt(0xecf6736);

      static deserialized(
        _data: ByteStream
      ): GetFeaturedEmojiStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetFeaturedEmojiStickers.cons))
          return undefined;

        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetFeaturedEmojiStickers(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetFeaturedEmojiStickers.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class ReportReaction implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x3f64c076);

      static deserialized(_data: ByteStream): ReportReaction | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReportReaction.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;
        const reactionPeer = TLObject.deserialized(_data) as InputPeerType;
        if (!reactionPeer) return undefined;

        return new ReportReaction(peer, id, reactionPeer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReportReaction.cons];

        _data.push(this.peer);
        _data.push(this.id);
        _data.push(this.reactionPeer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly id: TLInt,
        readonly reactionPeer: InputPeerType
      ) {}
    }
  }

  export namespace messages {
    export class GetTopReactions implements TLFunction<messages.ReactionsType> {
      static readonly cons = new TLInt(0xbb8125ba);

      static deserialized(_data: ByteStream): GetTopReactions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetTopReactions.cons))
          return undefined;

        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetTopReactions(limit, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetTopReactions.cons];

        _data.push(this.limit);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly limit: TLInt, readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class GetRecentReactions
      implements TLFunction<messages.ReactionsType>
    {
      static readonly cons = new TLInt(0x39461db2);

      static deserialized(_data: ByteStream): GetRecentReactions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetRecentReactions.cons))
          return undefined;

        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetRecentReactions(limit, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetRecentReactions.cons];

        _data.push(this.limit);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly limit: TLInt, readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class ClearRecentReactions implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x9dfeefb4);

      static deserialized(_data: ByteStream): ClearRecentReactions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ClearRecentReactions.cons))
          return undefined;

        return new ClearRecentReactions();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ClearRecentReactions.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class GetExtendedMedia implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x84f80814);

      static deserialized(_data: ByteStream): GetExtendedMedia | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetExtendedMedia.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new GetExtendedMedia(peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetExtendedMedia.cons];

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly id: TLVector<TLInt>) {}
    }
  }

  export namespace messages {
    export class SetDefaultHistoryTTL implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x9eb51445);

      static deserialized(_data: ByteStream): SetDefaultHistoryTTL | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetDefaultHistoryTTL.cons))
          return undefined;

        const period = TLInt.deserialized(_data);
        if (!period) return undefined;

        return new SetDefaultHistoryTTL(period);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetDefaultHistoryTTL.cons];

        _data.push(this.period);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly period: TLInt) {}
    }
  }

  export namespace messages {
    export class GetDefaultHistoryTTL
      implements TLFunction<API.DefaultHistoryTTL>
    {
      static readonly cons = new TLInt(0x658b7188);

      static deserialized(_data: ByteStream): GetDefaultHistoryTTL | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetDefaultHistoryTTL.cons))
          return undefined;

        return new GetDefaultHistoryTTL();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDefaultHistoryTTL.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace messages {
    export class SendBotRequestedPeer implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xfe38d01b);

      static deserialized(_data: ByteStream): SendBotRequestedPeer | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendBotRequestedPeer.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;
        const buttonId = TLInt.deserialized(_data);
        if (!buttonId) return undefined;
        const requestedPeer = TLObject.deserialized(_data) as InputPeerType;
        if (!requestedPeer) return undefined;

        return new SendBotRequestedPeer(peer, msgId, buttonId, requestedPeer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendBotRequestedPeer.cons];

        _data.push(this.peer);
        _data.push(this.msgId);
        _data.push(this.buttonId);
        _data.push(this.requestedPeer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly msgId: TLInt,
        readonly buttonId: TLInt,
        readonly requestedPeer: InputPeerType
      ) {}
    }
  }

  export namespace messages {
    export class GetEmojiGroups
      implements TLFunction<messages.EmojiGroupsType>
    {
      static readonly cons = new TLInt(0x7488ce5b);

      static deserialized(_data: ByteStream): GetEmojiGroups | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetEmojiGroups.cons))
          return undefined;

        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;

        return new GetEmojiGroups(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetEmojiGroups.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLInt) {}
    }
  }

  export namespace messages {
    export class GetEmojiStatusGroups
      implements TLFunction<messages.EmojiGroupsType>
    {
      static readonly cons = new TLInt(0x2ecd56cd);

      static deserialized(_data: ByteStream): GetEmojiStatusGroups | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetEmojiStatusGroups.cons))
          return undefined;

        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;

        return new GetEmojiStatusGroups(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetEmojiStatusGroups.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLInt) {}
    }
  }

  export namespace messages {
    export class GetEmojiProfilePhotoGroups
      implements TLFunction<messages.EmojiGroupsType>
    {
      static readonly cons = new TLInt(0x21a548f3);

      static deserialized(
        _data: ByteStream
      ): GetEmojiProfilePhotoGroups | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(GetEmojiProfilePhotoGroups.cons)
        )
          return undefined;

        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;

        return new GetEmojiProfilePhotoGroups(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetEmojiProfilePhotoGroups.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLInt) {}
    }
  }

  export namespace messages {
    export class SearchCustomEmoji implements TLFunction<EmojiListType> {
      static readonly cons = new TLInt(0x2c11c0d7);

      static deserialized(_data: ByteStream): SearchCustomEmoji | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SearchCustomEmoji.cons))
          return undefined;

        const emoticon = TLString.deserialized(_data);
        if (!emoticon) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new SearchCustomEmoji(emoticon, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SearchCustomEmoji.cons];

        _data.push(this.emoticon);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly emoticon: TLString, readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class TogglePeerTranslations implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xe47cb579);

      static deserialized(
        _data: ByteStream
      ): TogglePeerTranslations | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TogglePeerTranslations.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const disabled = (flags.value & 1) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new TogglePeerTranslations(disabled, peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TogglePeerTranslations.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.disabled) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly disabled: boolean | undefined,
        readonly peer: InputPeerType
      ) {}
    }
  }

  export namespace messages {
    export class GetBotApp implements TLFunction<messages.BotApp> {
      static readonly cons = new TLInt(0x34fdc5c3);

      static deserialized(_data: ByteStream): GetBotApp | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetBotApp.cons))
          return undefined;

        const app = TLObject.deserialized(_data) as InputBotAppType;
        if (!app) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetBotApp(app, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetBotApp.cons];

        _data.push(this.app);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly app: InputBotAppType, readonly hash: TLLong) {}
    }
  }

  export namespace messages {
    export class RequestAppWebView implements TLFunction<AppWebViewResultType> {
      static readonly cons = new TLInt(0x8c5a3b3c);

      static deserialized(_data: ByteStream): RequestAppWebView | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RequestAppWebView.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const writeAllowed = (flags.value & 1) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const app = TLObject.deserialized(_data) as InputBotAppType;
        if (!app) return undefined;
        let startParam: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          startParam = obj;
        }
        let themeParams: API.DataJSON | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as API.DataJSON;
          if (!obj) return undefined;
          themeParams = obj;
        }
        const platform = TLString.deserialized(_data);
        if (!platform) return undefined;

        return new RequestAppWebView(
          writeAllowed,
          peer,
          app,
          startParam,
          themeParams,
          platform
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RequestAppWebView.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.writeAllowed) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);
        _data.push(this.app);
        if (this.startParam) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.startParam) _data.push(this.startParam);

        if (this.themeParams) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.themeParams) _data.push(this.themeParams);

        _data.push(this.platform);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly writeAllowed: boolean | undefined,
        readonly peer: InputPeerType,
        readonly app: InputBotAppType,
        readonly startParam: TLString | undefined,
        readonly themeParams: API.DataJSON | undefined,
        readonly platform: TLString
      ) {}
    }
  }

  export namespace messages {
    export class SetChatWallPaper implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x8ffacae1);

      static deserialized(_data: ByteStream): SetChatWallPaper | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetChatWallPaper.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let wallpaper: InputWallPaperType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputWallPaperType;
          if (!obj) return undefined;
          wallpaper = obj;
        }
        let settings: API.WallPaperSettings | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as API.WallPaperSettings;
          if (!obj) return undefined;
          settings = obj;
        }
        let id: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          id = obj;
        }

        return new SetChatWallPaper(peer, wallpaper, settings, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetChatWallPaper.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        if (this.wallpaper) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.wallpaper) _data.push(this.wallpaper);

        if (this.settings) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.settings) _data.push(this.settings);

        if (this.id) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.id) _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly wallpaper: InputWallPaperType | undefined,
        readonly settings: API.WallPaperSettings | undefined,
        readonly id: TLInt | undefined
      ) {}
    }
  }

  export namespace updates {
    export class GetState implements TLFunction<updates.State> {
      static readonly cons = new TLInt(0xedd4882a);

      static deserialized(_data: ByteStream): GetState | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetState.cons))
          return undefined;

        return new GetState();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetState.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace updates {
    export class GetDifference implements TLFunction<updates.DifferenceType> {
      static readonly cons = new TLInt(0x19c2f763);

      static deserialized(_data: ByteStream): GetDifference | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetDifference.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const pts = TLInt.deserialized(_data);
        if (!pts) return undefined;
        let ptsLimit: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          ptsLimit = obj;
        }
        let ptsTotalLimit: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          ptsTotalLimit = obj;
        }
        const date = TLInt.deserialized(_data);
        if (!date) return undefined;
        const qts = TLInt.deserialized(_data);
        if (!qts) return undefined;
        let qtsLimit: TLInt | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          qtsLimit = obj;
        }

        return new GetDifference(
          pts,
          ptsLimit,
          ptsTotalLimit,
          date,
          qts,
          qtsLimit
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDifference.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.pts);
        if (this.ptsLimit) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.ptsLimit) _data.push(this.ptsLimit);

        if (this.ptsTotalLimit) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.ptsTotalLimit) _data.push(this.ptsTotalLimit);

        _data.push(this.date);
        _data.push(this.qts);
        if (this.qtsLimit) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.qtsLimit) _data.push(this.qtsLimit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly pts: TLInt,
        readonly ptsLimit: TLInt | undefined,
        readonly ptsTotalLimit: TLInt | undefined,
        readonly date: TLInt,
        readonly qts: TLInt,
        readonly qtsLimit: TLInt | undefined
      ) {}
    }
  }

  export namespace updates {
    export class GetChannelDifference
      implements TLFunction<updates.ChannelDifferenceType>
    {
      static readonly cons = new TLInt(0x3173d78);

      static deserialized(_data: ByteStream): GetChannelDifference | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetChannelDifference.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const force = (flags.value & 1) !== 0;
        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const filter = TLObject.deserialized(
          _data
        ) as ChannelMessagesFilterType;
        if (!filter) return undefined;
        const pts = TLInt.deserialized(_data);
        if (!pts) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetChannelDifference(force, channel, filter, pts, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetChannelDifference.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.force) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.channel);
        _data.push(this.filter);
        _data.push(this.pts);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly force: boolean | undefined,
        readonly channel: InputChannelType,
        readonly filter: ChannelMessagesFilterType,
        readonly pts: TLInt,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace photos {
    export class UpdateProfilePhoto implements TLFunction<photos.Photo> {
      static readonly cons = new TLInt(0x9e82039);

      static deserialized(_data: ByteStream): UpdateProfilePhoto | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdateProfilePhoto.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const fallback = (flags.value & 1) !== 0;
        let bot: InputUserType | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as InputUserType;
          if (!obj) return undefined;
          bot = obj;
        }
        const id = TLObject.deserialized(_data) as InputPhotoType;
        if (!id) return undefined;

        return new UpdateProfilePhoto(fallback, bot, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdateProfilePhoto.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.fallback) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.bot) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.bot) _data.push(this.bot);

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly fallback: boolean | undefined,
        readonly bot: InputUserType | undefined,
        readonly id: InputPhotoType
      ) {}
    }
  }

  export namespace photos {
    export class UploadProfilePhoto implements TLFunction<photos.Photo> {
      static readonly cons = new TLInt(0x388a3b5);

      static deserialized(_data: ByteStream): UploadProfilePhoto | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UploadProfilePhoto.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const fallback = (flags.value & 8) !== 0;
        let bot: InputUserType | undefined;
        if ((flags.value & 32) !== 0) {
          const obj = TLObject.deserialized(_data) as InputUserType;
          if (!obj) return undefined;
          bot = obj;
        }
        let file: InputFileType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputFileType;
          if (!obj) return undefined;
          file = obj;
        }
        let video: InputFileType | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as InputFileType;
          if (!obj) return undefined;
          video = obj;
        }
        let videoStartTs: TLDouble | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLDouble.deserialized(_data);
          if (!obj) return undefined;
          videoStartTs = obj;
        }
        let videoEmojiMarkup: VideoSizeType | undefined;
        if ((flags.value & 16) !== 0) {
          const obj = TLObject.deserialized(_data) as VideoSizeType;
          if (!obj) return undefined;
          videoEmojiMarkup = obj;
        }

        return new UploadProfilePhoto(
          fallback,
          bot,
          file,
          video,
          videoStartTs,
          videoEmojiMarkup
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UploadProfilePhoto.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.fallback) {
          flags.set(8);
        } else {
          flags.unset(8);
        }

        if (this.bot) {
          flags.set(32);
        } else {
          flags.unset(32);
        }
        if (this.bot) _data.push(this.bot);

        if (this.file) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.file) _data.push(this.file);

        if (this.video) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.video) _data.push(this.video);

        if (this.videoStartTs) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.videoStartTs) _data.push(this.videoStartTs);

        if (this.videoEmojiMarkup) {
          flags.set(16);
        } else {
          flags.unset(16);
        }
        if (this.videoEmojiMarkup) _data.push(this.videoEmojiMarkup);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly fallback: boolean | undefined,
        readonly bot: InputUserType | undefined,
        readonly file: InputFileType | undefined,
        readonly video: InputFileType | undefined,
        readonly videoStartTs: TLDouble | undefined,
        readonly videoEmojiMarkup: VideoSizeType | undefined
      ) {}
    }
  }

  export namespace photos {
    export class DeletePhotos implements TLFunction<TLVector<TLLong>> {
      static readonly cons = new TLInt(0x87cf7f2f);

      static deserialized(_data: ByteStream): DeletePhotos | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeletePhotos.cons))
          return undefined;

        const id = TLVector.deserialized(_data) as TLVector<InputPhotoType>;
        if (!id) return undefined;

        return new DeletePhotos(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeletePhotos.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLVector<InputPhotoType>) {}
    }
  }

  export namespace photos {
    export class GetUserPhotos implements TLFunction<photos.PhotosType> {
      static readonly cons = new TLInt(0x91cd32a8);

      static deserialized(_data: ByteStream): GetUserPhotos | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetUserPhotos.cons))
          return undefined;

        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const offset = TLInt.deserialized(_data);
        if (!offset) return undefined;
        const maxId = TLLong.deserialized(_data);
        if (!maxId) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetUserPhotos(userId, offset, maxId, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetUserPhotos.cons];

        _data.push(this.userId);
        _data.push(this.offset);
        _data.push(this.maxId);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly userId: InputUserType,
        readonly offset: TLInt,
        readonly maxId: TLLong,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace photos {
    export class UploadContactProfilePhoto implements TLFunction<photos.Photo> {
      static readonly cons = new TLInt(0xe14c4a71);

      static deserialized(
        _data: ByteStream
      ): UploadContactProfilePhoto | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UploadContactProfilePhoto.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const suggest = (flags.value & 8) !== 0;
        const save = (flags.value & 16) !== 0;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        let file: InputFileType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputFileType;
          if (!obj) return undefined;
          file = obj;
        }
        let video: InputFileType | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as InputFileType;
          if (!obj) return undefined;
          video = obj;
        }
        let videoStartTs: TLDouble | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLDouble.deserialized(_data);
          if (!obj) return undefined;
          videoStartTs = obj;
        }
        let videoEmojiMarkup: VideoSizeType | undefined;
        if ((flags.value & 32) !== 0) {
          const obj = TLObject.deserialized(_data) as VideoSizeType;
          if (!obj) return undefined;
          videoEmojiMarkup = obj;
        }

        return new UploadContactProfilePhoto(
          suggest,
          save,
          userId,
          file,
          video,
          videoStartTs,
          videoEmojiMarkup
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UploadContactProfilePhoto.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.suggest) {
          flags.set(8);
        } else {
          flags.unset(8);
        }

        if (this.save) {
          flags.set(16);
        } else {
          flags.unset(16);
        }

        _data.push(this.userId);
        if (this.file) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.file) _data.push(this.file);

        if (this.video) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.video) _data.push(this.video);

        if (this.videoStartTs) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.videoStartTs) _data.push(this.videoStartTs);

        if (this.videoEmojiMarkup) {
          flags.set(32);
        } else {
          flags.unset(32);
        }
        if (this.videoEmojiMarkup) _data.push(this.videoEmojiMarkup);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly suggest: boolean | undefined,
        readonly save: boolean | undefined,
        readonly userId: InputUserType,
        readonly file: InputFileType | undefined,
        readonly video: InputFileType | undefined,
        readonly videoStartTs: TLDouble | undefined,
        readonly videoEmojiMarkup: VideoSizeType | undefined
      ) {}
    }
  }

  export namespace upload {
    export class SaveFilePart implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xb304a621);

      static deserialized(_data: ByteStream): SaveFilePart | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveFilePart.cons))
          return undefined;

        const fileId = TLLong.deserialized(_data);
        if (!fileId) return undefined;
        const filePart = TLInt.deserialized(_data);
        if (!filePart) return undefined;
        const bytes = TLBytes.deserialized(_data);
        if (!bytes) return undefined;

        return new SaveFilePart(fileId, filePart, bytes);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveFilePart.cons];

        _data.push(this.fileId);
        _data.push(this.filePart);
        _data.push(this.bytes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly fileId: TLLong,
        readonly filePart: TLInt,
        readonly bytes: TLBytes
      ) {}
    }
  }

  export namespace upload {
    export class GetFile implements TLFunction<upload.FileType> {
      static readonly cons = new TLInt(0xbe5335be);

      static deserialized(_data: ByteStream): GetFile | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetFile.cons)) return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const precise = (flags.value & 1) !== 0;
        const cdnSupported = (flags.value & 2) !== 0;
        const location = TLObject.deserialized(_data) as InputFileLocationType;
        if (!location) return undefined;
        const offset = TLLong.deserialized(_data);
        if (!offset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetFile(precise, cdnSupported, location, offset, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetFile.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.precise) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.cdnSupported) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.location);
        _data.push(this.offset);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly precise: boolean | undefined,
        readonly cdnSupported: boolean | undefined,
        readonly location: InputFileLocationType,
        readonly offset: TLLong,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace upload {
    export class SaveBigFilePart implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xde7b673d);

      static deserialized(_data: ByteStream): SaveBigFilePart | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveBigFilePart.cons))
          return undefined;

        const fileId = TLLong.deserialized(_data);
        if (!fileId) return undefined;
        const filePart = TLInt.deserialized(_data);
        if (!filePart) return undefined;
        const fileTotalParts = TLInt.deserialized(_data);
        if (!fileTotalParts) return undefined;
        const bytes = TLBytes.deserialized(_data);
        if (!bytes) return undefined;

        return new SaveBigFilePart(fileId, filePart, fileTotalParts, bytes);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveBigFilePart.cons];

        _data.push(this.fileId);
        _data.push(this.filePart);
        _data.push(this.fileTotalParts);
        _data.push(this.bytes);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly fileId: TLLong,
        readonly filePart: TLInt,
        readonly fileTotalParts: TLInt,
        readonly bytes: TLBytes
      ) {}
    }
  }

  export namespace upload {
    export class GetWebFile implements TLFunction<upload.WebFile> {
      static readonly cons = new TLInt(0x24e6818d);

      static deserialized(_data: ByteStream): GetWebFile | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetWebFile.cons))
          return undefined;

        const location = TLObject.deserialized(
          _data
        ) as InputWebFileLocationType;
        if (!location) return undefined;
        const offset = TLInt.deserialized(_data);
        if (!offset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetWebFile(location, offset, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetWebFile.cons];

        _data.push(this.location);
        _data.push(this.offset);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly location: InputWebFileLocationType,
        readonly offset: TLInt,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace upload {
    export class GetCdnFile implements TLFunction<upload.CdnFileType> {
      static readonly cons = new TLInt(0x395f69da);

      static deserialized(_data: ByteStream): GetCdnFile | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetCdnFile.cons))
          return undefined;

        const fileToken = TLBytes.deserialized(_data);
        if (!fileToken) return undefined;
        const offset = TLLong.deserialized(_data);
        if (!offset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetCdnFile(fileToken, offset, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetCdnFile.cons];

        _data.push(this.fileToken);
        _data.push(this.offset);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly fileToken: TLBytes,
        readonly offset: TLLong,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace upload {
    export class ReuploadCdnFile implements TLFunction<TLVector<API.FileHash>> {
      static readonly cons = new TLInt(0x9b2754a8);

      static deserialized(_data: ByteStream): ReuploadCdnFile | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReuploadCdnFile.cons))
          return undefined;

        const fileToken = TLBytes.deserialized(_data);
        if (!fileToken) return undefined;
        const requestToken = TLBytes.deserialized(_data);
        if (!requestToken) return undefined;

        return new ReuploadCdnFile(fileToken, requestToken);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReuploadCdnFile.cons];

        _data.push(this.fileToken);
        _data.push(this.requestToken);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly fileToken: TLBytes,
        readonly requestToken: TLBytes
      ) {}
    }
  }

  export namespace upload {
    export class GetCdnFileHashes
      implements TLFunction<TLVector<API.FileHash>>
    {
      static readonly cons = new TLInt(0x91dc3f31);

      static deserialized(_data: ByteStream): GetCdnFileHashes | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetCdnFileHashes.cons))
          return undefined;

        const fileToken = TLBytes.deserialized(_data);
        if (!fileToken) return undefined;
        const offset = TLLong.deserialized(_data);
        if (!offset) return undefined;

        return new GetCdnFileHashes(fileToken, offset);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetCdnFileHashes.cons];

        _data.push(this.fileToken);
        _data.push(this.offset);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly fileToken: TLBytes, readonly offset: TLLong) {}
    }
  }

  export namespace upload {
    export class GetFileHashes implements TLFunction<TLVector<API.FileHash>> {
      static readonly cons = new TLInt(0x9156982a);

      static deserialized(_data: ByteStream): GetFileHashes | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetFileHashes.cons))
          return undefined;

        const location = TLObject.deserialized(_data) as InputFileLocationType;
        if (!location) return undefined;
        const offset = TLLong.deserialized(_data);
        if (!offset) return undefined;

        return new GetFileHashes(location, offset);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetFileHashes.cons];

        _data.push(this.location);
        _data.push(this.offset);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly location: InputFileLocationType,
        readonly offset: TLLong
      ) {}
    }
  }

  export namespace help {
    export class GetConfig implements TLFunction<API.Config> {
      static readonly cons = new TLInt(0xc4f9186b);

      static deserialized(_data: ByteStream): GetConfig | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetConfig.cons))
          return undefined;

        return new GetConfig();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetConfig.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class GetNearestDc implements TLFunction<API.NearestDc> {
      static readonly cons = new TLInt(0x1fb33026);

      static deserialized(_data: ByteStream): GetNearestDc | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetNearestDc.cons))
          return undefined;

        return new GetNearestDc();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetNearestDc.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class GetAppUpdate implements TLFunction<help.AppUpdateType> {
      static readonly cons = new TLInt(0x522d5a7d);

      static deserialized(_data: ByteStream): GetAppUpdate | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAppUpdate.cons))
          return undefined;

        const source = TLString.deserialized(_data);
        if (!source) return undefined;

        return new GetAppUpdate(source);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAppUpdate.cons];

        _data.push(this.source);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly source: TLString) {}
    }
  }

  export namespace help {
    export class GetInviteText implements TLFunction<help.InviteText> {
      static readonly cons = new TLInt(0x4d392343);

      static deserialized(_data: ByteStream): GetInviteText | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetInviteText.cons))
          return undefined;

        return new GetInviteText();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetInviteText.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class GetSupport implements TLFunction<help.Support> {
      static readonly cons = new TLInt(0x9cdf08cd);

      static deserialized(_data: ByteStream): GetSupport | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSupport.cons))
          return undefined;

        return new GetSupport();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSupport.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class GetAppChangelog implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x9010ef6f);

      static deserialized(_data: ByteStream): GetAppChangelog | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAppChangelog.cons))
          return undefined;

        const prevAppVersion = TLString.deserialized(_data);
        if (!prevAppVersion) return undefined;

        return new GetAppChangelog(prevAppVersion);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAppChangelog.cons];

        _data.push(this.prevAppVersion);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly prevAppVersion: TLString) {}
    }
  }

  export namespace help {
    export class SetBotUpdatesStatus implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xec22cfcd);

      static deserialized(_data: ByteStream): SetBotUpdatesStatus | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetBotUpdatesStatus.cons))
          return undefined;

        const pendingUpdatesCount = TLInt.deserialized(_data);
        if (!pendingUpdatesCount) return undefined;
        const message = TLString.deserialized(_data);
        if (!message) return undefined;

        return new SetBotUpdatesStatus(pendingUpdatesCount, message);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetBotUpdatesStatus.cons];

        _data.push(this.pendingUpdatesCount);
        _data.push(this.message);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly pendingUpdatesCount: TLInt,
        readonly message: TLString
      ) {}
    }
  }

  export namespace help {
    export class GetCdnConfig implements TLFunction<API.CdnConfig> {
      static readonly cons = new TLInt(0x52029342);

      static deserialized(_data: ByteStream): GetCdnConfig | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetCdnConfig.cons))
          return undefined;

        return new GetCdnConfig();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetCdnConfig.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class GetRecentMeUrls implements TLFunction<help.RecentMeUrls> {
      static readonly cons = new TLInt(0x3dc0f114);

      static deserialized(_data: ByteStream): GetRecentMeUrls | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetRecentMeUrls.cons))
          return undefined;

        const referer = TLString.deserialized(_data);
        if (!referer) return undefined;

        return new GetRecentMeUrls(referer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetRecentMeUrls.cons];

        _data.push(this.referer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly referer: TLString) {}
    }
  }

  export namespace help {
    export class GetTermsOfServiceUpdate
      implements TLFunction<help.TermsOfServiceUpdateType>
    {
      static readonly cons = new TLInt(0x2ca51fd1);

      static deserialized(
        _data: ByteStream
      ): GetTermsOfServiceUpdate | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetTermsOfServiceUpdate.cons))
          return undefined;

        return new GetTermsOfServiceUpdate();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetTermsOfServiceUpdate.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class AcceptTermsOfService implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xee72f79a);

      static deserialized(_data: ByteStream): AcceptTermsOfService | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AcceptTermsOfService.cons))
          return undefined;

        const id = API.DataJSON.deserialized(_data);
        if (!id) return undefined;

        return new AcceptTermsOfService(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AcceptTermsOfService.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: API.DataJSON) {}
    }
  }

  export namespace help {
    export class GetDeepLinkInfo implements TLFunction<help.DeepLinkInfoType> {
      static readonly cons = new TLInt(0x3fedc75f);

      static deserialized(_data: ByteStream): GetDeepLinkInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetDeepLinkInfo.cons))
          return undefined;

        const path = TLString.deserialized(_data);
        if (!path) return undefined;

        return new GetDeepLinkInfo(path);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDeepLinkInfo.cons];

        _data.push(this.path);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly path: TLString) {}
    }
  }

  export namespace help {
    export class GetAppConfig implements TLFunction<help.AppConfigType> {
      static readonly cons = new TLInt(0x61e3f854);

      static deserialized(_data: ByteStream): GetAppConfig | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAppConfig.cons))
          return undefined;

        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;

        return new GetAppConfig(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAppConfig.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLInt) {}
    }
  }

  export namespace help {
    export class SaveAppLog implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x6f02f748);

      static deserialized(_data: ByteStream): SaveAppLog | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveAppLog.cons))
          return undefined;

        const events = TLVector.deserialized(_data, API.InputAppEvent);
        if (!events) return undefined;

        return new SaveAppLog(events);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveAppLog.cons];

        _data.push(this.events);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly events: TLVector<API.InputAppEvent>) {}
    }
  }

  export namespace help {
    export class GetPassportConfig
      implements TLFunction<help.PassportConfigType>
    {
      static readonly cons = new TLInt(0xc661ad08);

      static deserialized(_data: ByteStream): GetPassportConfig | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPassportConfig.cons))
          return undefined;

        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;

        return new GetPassportConfig(hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPassportConfig.cons];

        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hash: TLInt) {}
    }
  }

  export namespace help {
    export class GetSupportName implements TLFunction<help.SupportName> {
      static readonly cons = new TLInt(0xd360e72c);

      static deserialized(_data: ByteStream): GetSupportName | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSupportName.cons))
          return undefined;

        return new GetSupportName();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSupportName.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class GetUserInfo implements TLFunction<help.UserInfoType> {
      static readonly cons = new TLInt(0x38a08d3);

      static deserialized(_data: ByteStream): GetUserInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetUserInfo.cons))
          return undefined;

        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;

        return new GetUserInfo(userId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetUserInfo.cons];

        _data.push(this.userId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly userId: InputUserType) {}
    }
  }

  export namespace help {
    export class EditUserInfo implements TLFunction<help.UserInfoType> {
      static readonly cons = new TLInt(0x66b91b70);

      static deserialized(_data: ByteStream): EditUserInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditUserInfo.cons))
          return undefined;

        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const message = TLString.deserialized(_data);
        if (!message) return undefined;
        const entities = TLVector.deserialized(
          _data
        ) as TLVector<MessageEntityType>;
        if (!entities) return undefined;

        return new EditUserInfo(userId, message, entities);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditUserInfo.cons];

        _data.push(this.userId);
        _data.push(this.message);
        _data.push(this.entities);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly userId: InputUserType,
        readonly message: TLString,
        readonly entities: TLVector<MessageEntityType>
      ) {}
    }
  }

  export namespace help {
    export class GetPromoData implements TLFunction<help.PromoDataType> {
      static readonly cons = new TLInt(0xc0977421);

      static deserialized(_data: ByteStream): GetPromoData | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPromoData.cons))
          return undefined;

        return new GetPromoData();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPromoData.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace help {
    export class HidePromoData implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x1e251c95);

      static deserialized(_data: ByteStream): HidePromoData | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(HidePromoData.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new HidePromoData(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [HidePromoData.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace help {
    export class DismissSuggestion implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xf50dbaa1);

      static deserialized(_data: ByteStream): DismissSuggestion | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DismissSuggestion.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const suggestion = TLString.deserialized(_data);
        if (!suggestion) return undefined;

        return new DismissSuggestion(peer, suggestion);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DismissSuggestion.cons];

        _data.push(this.peer);
        _data.push(this.suggestion);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly suggestion: TLString
      ) {}
    }
  }

  export namespace help {
    export class GetCountriesList
      implements TLFunction<help.CountriesListType>
    {
      static readonly cons = new TLInt(0x735787a8);

      static deserialized(_data: ByteStream): GetCountriesList | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetCountriesList.cons))
          return undefined;

        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;
        const hash = TLInt.deserialized(_data);
        if (!hash) return undefined;

        return new GetCountriesList(langCode, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetCountriesList.cons];

        _data.push(this.langCode);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly langCode: TLString, readonly hash: TLInt) {}
    }
  }

  export namespace help {
    export class GetPremiumPromo implements TLFunction<help.PremiumPromo> {
      static readonly cons = new TLInt(0xb81b93d4);

      static deserialized(_data: ByteStream): GetPremiumPromo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPremiumPromo.cons))
          return undefined;

        return new GetPremiumPromo();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPremiumPromo.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace channels {
    export class ReadHistory implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xcc104937);

      static deserialized(_data: ByteStream): ReadHistory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReadHistory.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const maxId = TLInt.deserialized(_data);
        if (!maxId) return undefined;

        return new ReadHistory(channel, maxId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReadHistory.cons];

        _data.push(this.channel);
        _data.push(this.maxId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly channel: InputChannelType, readonly maxId: TLInt) {}
    }
  }

  export namespace channels {
    export class DeleteMessages
      implements TLFunction<messages.AffectedMessages>
    {
      static readonly cons = new TLInt(0x84c1fd4e);

      static deserialized(_data: ByteStream): DeleteMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteMessages.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new DeleteMessages(channel, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteMessages.cons];

        _data.push(this.channel);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly id: TLVector<TLInt>
      ) {}
    }
  }

  export namespace channels {
    export class ReportSpam implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xf44a8315);

      static deserialized(_data: ByteStream): ReportSpam | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReportSpam.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const participant = TLObject.deserialized(_data) as InputPeerType;
        if (!participant) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new ReportSpam(channel, participant, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReportSpam.cons];

        _data.push(this.channel);
        _data.push(this.participant);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly participant: InputPeerType,
        readonly id: TLVector<TLInt>
      ) {}
    }
  }

  export namespace channels {
    export class GetMessages implements TLFunction<messages.MessagesType> {
      static readonly cons = new TLInt(0xad8c9a23);

      static deserialized(_data: ByteStream): GetMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetMessages.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const id = TLVector.deserialized(_data) as TLVector<InputMessageType>;
        if (!id) return undefined;

        return new GetMessages(channel, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMessages.cons];

        _data.push(this.channel);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly id: TLVector<InputMessageType>
      ) {}
    }
  }

  export namespace channels {
    export class GetParticipants
      implements TLFunction<channels.ChannelParticipantsType>
    {
      static readonly cons = new TLInt(0x77ced9d0);

      static deserialized(_data: ByteStream): GetParticipants | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetParticipants.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const filter = TLObject.deserialized(
          _data
        ) as ChannelParticipantsFilterType;
        if (!filter) return undefined;
        const offset = TLInt.deserialized(_data);
        if (!offset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;
        const hash = TLLong.deserialized(_data);
        if (!hash) return undefined;

        return new GetParticipants(channel, filter, offset, limit, hash);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetParticipants.cons];

        _data.push(this.channel);
        _data.push(this.filter);
        _data.push(this.offset);
        _data.push(this.limit);
        _data.push(this.hash);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly filter: ChannelParticipantsFilterType,
        readonly offset: TLInt,
        readonly limit: TLInt,
        readonly hash: TLLong
      ) {}
    }
  }

  export namespace channels {
    export class GetParticipant
      implements TLFunction<channels.ChannelParticipant>
    {
      static readonly cons = new TLInt(0xa0ab6cc6);

      static deserialized(_data: ByteStream): GetParticipant | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetParticipant.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const participant = TLObject.deserialized(_data) as InputPeerType;
        if (!participant) return undefined;

        return new GetParticipant(channel, participant);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetParticipant.cons];

        _data.push(this.channel);
        _data.push(this.participant);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly participant: InputPeerType
      ) {}
    }
  }

  export namespace channels {
    export class GetChannels implements TLFunction<messages.ChatsType> {
      static readonly cons = new TLInt(0xa7f6bbb);

      static deserialized(_data: ByteStream): GetChannels | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetChannels.cons))
          return undefined;

        const id = TLVector.deserialized(_data) as TLVector<InputChannelType>;
        if (!id) return undefined;

        return new GetChannels(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetChannels.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLVector<InputChannelType>) {}
    }
  }

  export namespace channels {
    export class GetFullChannel implements TLFunction<messages.ChatFull> {
      static readonly cons = new TLInt(0x8736a09);

      static deserialized(_data: ByteStream): GetFullChannel | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetFullChannel.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;

        return new GetFullChannel(channel);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetFullChannel.cons];

        _data.push(this.channel);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly channel: InputChannelType) {}
    }
  }

  export namespace channels {
    export class CreateChannel implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x91006707);

      static deserialized(_data: ByteStream): CreateChannel | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CreateChannel.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const broadcast = (flags.value & 1) !== 0;
        const megagroup = (flags.value & 2) !== 0;
        const forImport = (flags.value & 8) !== 0;
        const forum = (flags.value & 32) !== 0;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;
        const about = TLString.deserialized(_data);
        if (!about) return undefined;
        let geoPoint: InputGeoPointType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as InputGeoPointType;
          if (!obj) return undefined;
          geoPoint = obj;
        }
        let address: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          address = obj;
        }
        let ttlPeriod: TLInt | undefined;
        if ((flags.value & 16) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          ttlPeriod = obj;
        }

        return new CreateChannel(
          broadcast,
          megagroup,
          forImport,
          forum,
          title,
          about,
          geoPoint,
          address,
          ttlPeriod
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CreateChannel.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.broadcast) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.megagroup) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.forImport) {
          flags.set(8);
        } else {
          flags.unset(8);
        }

        if (this.forum) {
          flags.set(32);
        } else {
          flags.unset(32);
        }

        _data.push(this.title);
        _data.push(this.about);
        if (this.geoPoint) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.geoPoint) _data.push(this.geoPoint);

        if (this.address) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.address) _data.push(this.address);

        if (this.ttlPeriod) {
          flags.set(16);
        } else {
          flags.unset(16);
        }
        if (this.ttlPeriod) _data.push(this.ttlPeriod);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly broadcast: boolean | undefined,
        readonly megagroup: boolean | undefined,
        readonly forImport: boolean | undefined,
        readonly forum: boolean | undefined,
        readonly title: TLString,
        readonly about: TLString,
        readonly geoPoint: InputGeoPointType | undefined,
        readonly address: TLString | undefined,
        readonly ttlPeriod: TLInt | undefined
      ) {}
    }
  }

  export namespace channels {
    export class EditAdmin implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xd33c8902);

      static deserialized(_data: ByteStream): EditAdmin | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditAdmin.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const adminRights = API.ChatAdminRights.deserialized(_data);
        if (!adminRights) return undefined;
        const rank = TLString.deserialized(_data);
        if (!rank) return undefined;

        return new EditAdmin(channel, userId, adminRights, rank);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditAdmin.cons];

        _data.push(this.channel);
        _data.push(this.userId);
        _data.push(this.adminRights);
        _data.push(this.rank);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly userId: InputUserType,
        readonly adminRights: API.ChatAdminRights,
        readonly rank: TLString
      ) {}
    }
  }

  export namespace channels {
    export class EditTitle implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x566decd0);

      static deserialized(_data: ByteStream): EditTitle | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditTitle.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;

        return new EditTitle(channel, title);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditTitle.cons];

        _data.push(this.channel);
        _data.push(this.title);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly title: TLString
      ) {}
    }
  }

  export namespace channels {
    export class EditPhoto implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xf12e57c9);

      static deserialized(_data: ByteStream): EditPhoto | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditPhoto.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const photo = TLObject.deserialized(_data) as InputChatPhotoType;
        if (!photo) return undefined;

        return new EditPhoto(channel, photo);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditPhoto.cons];

        _data.push(this.channel);
        _data.push(this.photo);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly photo: InputChatPhotoType
      ) {}
    }
  }

  export namespace channels {
    export class CheckUsername implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x10e6bd2c);

      static deserialized(_data: ByteStream): CheckUsername | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CheckUsername.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const username = TLString.deserialized(_data);
        if (!username) return undefined;

        return new CheckUsername(channel, username);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CheckUsername.cons];

        _data.push(this.channel);
        _data.push(this.username);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly username: TLString
      ) {}
    }
  }

  export namespace channels {
    export class UpdateUsername implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x3514b3de);

      static deserialized(_data: ByteStream): UpdateUsername | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdateUsername.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const username = TLString.deserialized(_data);
        if (!username) return undefined;

        return new UpdateUsername(channel, username);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdateUsername.cons];

        _data.push(this.channel);
        _data.push(this.username);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly username: TLString
      ) {}
    }
  }

  export namespace channels {
    export class JoinChannel implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x24b524c5);

      static deserialized(_data: ByteStream): JoinChannel | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(JoinChannel.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;

        return new JoinChannel(channel);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [JoinChannel.cons];

        _data.push(this.channel);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly channel: InputChannelType) {}
    }
  }

  export namespace channels {
    export class LeaveChannel implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xf836aa95);

      static deserialized(_data: ByteStream): LeaveChannel | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(LeaveChannel.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;

        return new LeaveChannel(channel);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [LeaveChannel.cons];

        _data.push(this.channel);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly channel: InputChannelType) {}
    }
  }

  export namespace channels {
    export class InviteToChannel implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x199f3a6c);

      static deserialized(_data: ByteStream): InviteToChannel | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(InviteToChannel.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<InputUserType>;
        if (!users) return undefined;

        return new InviteToChannel(channel, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [InviteToChannel.cons];

        _data.push(this.channel);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly users: TLVector<InputUserType>
      ) {}
    }
  }

  export namespace channels {
    export class DeleteChannel implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xc0111fe3);

      static deserialized(_data: ByteStream): DeleteChannel | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteChannel.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;

        return new DeleteChannel(channel);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteChannel.cons];

        _data.push(this.channel);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly channel: InputChannelType) {}
    }
  }

  export namespace channels {
    export class ExportMessageLink
      implements TLFunction<API.ExportedMessageLink>
    {
      static readonly cons = new TLInt(0xe63fadeb);

      static deserialized(_data: ByteStream): ExportMessageLink | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportMessageLink.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const grouped = (flags.value & 1) !== 0;
        const thread = (flags.value & 2) !== 0;
        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;

        return new ExportMessageLink(grouped, thread, channel, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportMessageLink.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.grouped) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.thread) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.channel);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly grouped: boolean | undefined,
        readonly thread: boolean | undefined,
        readonly channel: InputChannelType,
        readonly id: TLInt
      ) {}
    }
  }

  export namespace channels {
    export class ToggleSignatures implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x1f69b606);

      static deserialized(_data: ByteStream): ToggleSignatures | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleSignatures.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const enabled = TLObject.deserialized(_data) as BoolType;
        if (!enabled) return undefined;

        return new ToggleSignatures(channel, enabled);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleSignatures.cons];

        _data.push(this.channel);
        _data.push(this.enabled);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly enabled: BoolType
      ) {}
    }
  }

  export namespace channels {
    export class GetAdminedPublicChannels
      implements TLFunction<messages.ChatsType>
    {
      static readonly cons = new TLInt(0xf8b036af);

      static deserialized(
        _data: ByteStream
      ): GetAdminedPublicChannels | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAdminedPublicChannels.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const byLocation = (flags.value & 1) !== 0;
        const checkLimit = (flags.value & 2) !== 0;

        return new GetAdminedPublicChannels(byLocation, checkLimit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAdminedPublicChannels.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.byLocation) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.checkLimit) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly byLocation: boolean | undefined,
        readonly checkLimit: boolean | undefined
      ) {}
    }
  }

  export namespace channels {
    export class EditBanned implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x96e6cd81);

      static deserialized(_data: ByteStream): EditBanned | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditBanned.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const participant = TLObject.deserialized(_data) as InputPeerType;
        if (!participant) return undefined;
        const bannedRights = API.ChatBannedRights.deserialized(_data);
        if (!bannedRights) return undefined;

        return new EditBanned(channel, participant, bannedRights);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditBanned.cons];

        _data.push(this.channel);
        _data.push(this.participant);
        _data.push(this.bannedRights);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly participant: InputPeerType,
        readonly bannedRights: API.ChatBannedRights
      ) {}
    }
  }

  export namespace channels {
    export class GetAdminLog implements TLFunction<channels.AdminLogResults> {
      static readonly cons = new TLInt(0x33ddf480);

      static deserialized(_data: ByteStream): GetAdminLog | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAdminLog.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const q = TLString.deserialized(_data);
        if (!q) return undefined;
        let eventsFilter: API.ChannelAdminLogEventsFilter | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(
            _data
          ) as API.ChannelAdminLogEventsFilter;
          if (!obj) return undefined;
          eventsFilter = obj;
        }
        let admins: TLVector<InputUserType> | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLVector.deserialized(_data) as TLVector<InputUserType>;
          if (!obj) return undefined;
          admins = obj;
        }
        const maxId = TLLong.deserialized(_data);
        if (!maxId) return undefined;
        const minId = TLLong.deserialized(_data);
        if (!minId) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetAdminLog(
          channel,
          q,
          eventsFilter,
          admins,
          maxId,
          minId,
          limit
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAdminLog.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.channel);
        _data.push(this.q);
        if (this.eventsFilter) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.eventsFilter) _data.push(this.eventsFilter);

        if (this.admins) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.admins) _data.push(this.admins);

        _data.push(this.maxId);
        _data.push(this.minId);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly q: TLString,
        readonly eventsFilter: API.ChannelAdminLogEventsFilter | undefined,
        readonly admins: TLVector<InputUserType> | undefined,
        readonly maxId: TLLong,
        readonly minId: TLLong,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace channels {
    export class SetStickers implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xea8ca4f9);

      static deserialized(_data: ByteStream): SetStickers | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetStickers.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
        if (!stickerset) return undefined;

        return new SetStickers(channel, stickerset);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetStickers.cons];

        _data.push(this.channel);
        _data.push(this.stickerset);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly stickerset: InputStickerSetType
      ) {}
    }
  }

  export namespace channels {
    export class ReadMessageContents implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xeab5dc38);

      static deserialized(_data: ByteStream): ReadMessageContents | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReadMessageContents.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new ReadMessageContents(channel, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReadMessageContents.cons];

        _data.push(this.channel);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly id: TLVector<TLInt>
      ) {}
    }
  }

  export namespace channels {
    export class DeleteHistory implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x9baa9647);

      static deserialized(_data: ByteStream): DeleteHistory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteHistory.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const forEveryone = (flags.value & 1) !== 0;
        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const maxId = TLInt.deserialized(_data);
        if (!maxId) return undefined;

        return new DeleteHistory(forEveryone, channel, maxId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteHistory.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.forEveryone) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.channel);
        _data.push(this.maxId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly forEveryone: boolean | undefined,
        readonly channel: InputChannelType,
        readonly maxId: TLInt
      ) {}
    }
  }

  export namespace channels {
    export class TogglePreHistoryHidden implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xeabbb94c);

      static deserialized(
        _data: ByteStream
      ): TogglePreHistoryHidden | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TogglePreHistoryHidden.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const enabled = TLObject.deserialized(_data) as BoolType;
        if (!enabled) return undefined;

        return new TogglePreHistoryHidden(channel, enabled);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TogglePreHistoryHidden.cons];

        _data.push(this.channel);
        _data.push(this.enabled);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly enabled: BoolType
      ) {}
    }
  }

  export namespace channels {
    export class GetLeftChannels implements TLFunction<messages.ChatsType> {
      static readonly cons = new TLInt(0x8341ecc0);

      static deserialized(_data: ByteStream): GetLeftChannels | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetLeftChannels.cons))
          return undefined;

        const offset = TLInt.deserialized(_data);
        if (!offset) return undefined;

        return new GetLeftChannels(offset);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetLeftChannels.cons];

        _data.push(this.offset);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly offset: TLInt) {}
    }
  }

  export namespace channels {
    export class GetGroupsForDiscussion
      implements TLFunction<messages.ChatsType>
    {
      static readonly cons = new TLInt(0xf5dad378);

      static deserialized(
        _data: ByteStream
      ): GetGroupsForDiscussion | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetGroupsForDiscussion.cons))
          return undefined;

        return new GetGroupsForDiscussion();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetGroupsForDiscussion.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace channels {
    export class SetDiscussionGroup implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x40582bb2);

      static deserialized(_data: ByteStream): SetDiscussionGroup | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetDiscussionGroup.cons))
          return undefined;

        const broadcast = TLObject.deserialized(_data) as InputChannelType;
        if (!broadcast) return undefined;
        const group = TLObject.deserialized(_data) as InputChannelType;
        if (!group) return undefined;

        return new SetDiscussionGroup(broadcast, group);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetDiscussionGroup.cons];

        _data.push(this.broadcast);
        _data.push(this.group);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly broadcast: InputChannelType,
        readonly group: InputChannelType
      ) {}
    }
  }

  export namespace channels {
    export class EditCreator implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x8f38cd1f);

      static deserialized(_data: ByteStream): EditCreator | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditCreator.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const password = TLObject.deserialized(
          _data
        ) as InputCheckPasswordSRPType;
        if (!password) return undefined;

        return new EditCreator(channel, userId, password);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditCreator.cons];

        _data.push(this.channel);
        _data.push(this.userId);
        _data.push(this.password);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly userId: InputUserType,
        readonly password: InputCheckPasswordSRPType
      ) {}
    }
  }

  export namespace channels {
    export class EditLocation implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x58e63f6d);

      static deserialized(_data: ByteStream): EditLocation | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditLocation.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const geoPoint = TLObject.deserialized(_data) as InputGeoPointType;
        if (!geoPoint) return undefined;
        const address = TLString.deserialized(_data);
        if (!address) return undefined;

        return new EditLocation(channel, geoPoint, address);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditLocation.cons];

        _data.push(this.channel);
        _data.push(this.geoPoint);
        _data.push(this.address);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly geoPoint: InputGeoPointType,
        readonly address: TLString
      ) {}
    }
  }

  export namespace channels {
    export class ToggleSlowMode implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xedd49ef0);

      static deserialized(_data: ByteStream): ToggleSlowMode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleSlowMode.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const seconds = TLInt.deserialized(_data);
        if (!seconds) return undefined;

        return new ToggleSlowMode(channel, seconds);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleSlowMode.cons];

        _data.push(this.channel);
        _data.push(this.seconds);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly seconds: TLInt
      ) {}
    }
  }

  export namespace channels {
    export class GetInactiveChannels
      implements TLFunction<messages.InactiveChats>
    {
      static readonly cons = new TLInt(0x11e831ee);

      static deserialized(_data: ByteStream): GetInactiveChannels | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetInactiveChannels.cons))
          return undefined;

        return new GetInactiveChannels();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetInactiveChannels.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace channels {
    export class ConvertToGigagroup implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xb290c69);

      static deserialized(_data: ByteStream): ConvertToGigagroup | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ConvertToGigagroup.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;

        return new ConvertToGigagroup(channel);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ConvertToGigagroup.cons];

        _data.push(this.channel);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly channel: InputChannelType) {}
    }
  }

  export namespace channels {
    export class ViewSponsoredMessage implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xbeaedb94);

      static deserialized(_data: ByteStream): ViewSponsoredMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ViewSponsoredMessage.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const randomId = TLBytes.deserialized(_data);
        if (!randomId) return undefined;

        return new ViewSponsoredMessage(channel, randomId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ViewSponsoredMessage.cons];

        _data.push(this.channel);
        _data.push(this.randomId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly randomId: TLBytes
      ) {}
    }
  }

  export namespace channels {
    export class GetSponsoredMessages
      implements TLFunction<messages.SponsoredMessagesType>
    {
      static readonly cons = new TLInt(0xec210fbf);

      static deserialized(_data: ByteStream): GetSponsoredMessages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSponsoredMessages.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;

        return new GetSponsoredMessages(channel);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSponsoredMessages.cons];

        _data.push(this.channel);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly channel: InputChannelType) {}
    }
  }

  export namespace channels {
    export class GetSendAs implements TLFunction<channels.SendAsPeers> {
      static readonly cons = new TLInt(0xdc770ee);

      static deserialized(_data: ByteStream): GetSendAs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSendAs.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new GetSendAs(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSendAs.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace channels {
    export class DeleteParticipantHistory
      implements TLFunction<messages.AffectedHistory>
    {
      static readonly cons = new TLInt(0x367544db);

      static deserialized(
        _data: ByteStream
      ): DeleteParticipantHistory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteParticipantHistory.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const participant = TLObject.deserialized(_data) as InputPeerType;
        if (!participant) return undefined;

        return new DeleteParticipantHistory(channel, participant);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteParticipantHistory.cons];

        _data.push(this.channel);
        _data.push(this.participant);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly participant: InputPeerType
      ) {}
    }
  }

  export namespace channels {
    export class ToggleJoinToSend implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xe4cb9580);

      static deserialized(_data: ByteStream): ToggleJoinToSend | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleJoinToSend.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const enabled = TLObject.deserialized(_data) as BoolType;
        if (!enabled) return undefined;

        return new ToggleJoinToSend(channel, enabled);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleJoinToSend.cons];

        _data.push(this.channel);
        _data.push(this.enabled);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly enabled: BoolType
      ) {}
    }
  }

  export namespace channels {
    export class ToggleJoinRequest implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x4c2985b6);

      static deserialized(_data: ByteStream): ToggleJoinRequest | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleJoinRequest.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const enabled = TLObject.deserialized(_data) as BoolType;
        if (!enabled) return undefined;

        return new ToggleJoinRequest(channel, enabled);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleJoinRequest.cons];

        _data.push(this.channel);
        _data.push(this.enabled);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly enabled: BoolType
      ) {}
    }
  }

  export namespace channels {
    export class ReorderUsernames implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xb45ced1d);

      static deserialized(_data: ByteStream): ReorderUsernames | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReorderUsernames.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const order = TLVector.deserialized(_data, TLString);
        if (!order) return undefined;

        return new ReorderUsernames(channel, order);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReorderUsernames.cons];

        _data.push(this.channel);
        _data.push(this.order);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly order: TLVector<TLString>
      ) {}
    }
  }

  export namespace channels {
    export class ToggleUsername implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x50f24105);

      static deserialized(_data: ByteStream): ToggleUsername | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleUsername.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const username = TLString.deserialized(_data);
        if (!username) return undefined;
        const active = TLObject.deserialized(_data) as BoolType;
        if (!active) return undefined;

        return new ToggleUsername(channel, username, active);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleUsername.cons];

        _data.push(this.channel);
        _data.push(this.username);
        _data.push(this.active);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly username: TLString,
        readonly active: BoolType
      ) {}
    }
  }

  export namespace channels {
    export class DeactivateAllUsernames implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xa245dd3);

      static deserialized(
        _data: ByteStream
      ): DeactivateAllUsernames | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeactivateAllUsernames.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;

        return new DeactivateAllUsernames(channel);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeactivateAllUsernames.cons];

        _data.push(this.channel);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly channel: InputChannelType) {}
    }
  }

  export namespace channels {
    export class ToggleForum implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xa4298b29);

      static deserialized(_data: ByteStream): ToggleForum | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleForum.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const enabled = TLObject.deserialized(_data) as BoolType;
        if (!enabled) return undefined;

        return new ToggleForum(channel, enabled);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleForum.cons];

        _data.push(this.channel);
        _data.push(this.enabled);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly enabled: BoolType
      ) {}
    }
  }

  export namespace channels {
    export class CreateForumTopic implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xf40c0224);

      static deserialized(_data: ByteStream): CreateForumTopic | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CreateForumTopic.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;
        let iconColor: TLInt | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          iconColor = obj;
        }
        let iconEmojiId: TLLong | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLLong.deserialized(_data);
          if (!obj) return undefined;
          iconEmojiId = obj;
        }
        const randomId = TLLong.deserialized(_data);
        if (!randomId) return undefined;
        let sendAs: InputPeerType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as InputPeerType;
          if (!obj) return undefined;
          sendAs = obj;
        }

        return new CreateForumTopic(
          channel,
          title,
          iconColor,
          iconEmojiId,
          randomId,
          sendAs
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CreateForumTopic.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.channel);
        _data.push(this.title);
        if (this.iconColor) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.iconColor) _data.push(this.iconColor);

        if (this.iconEmojiId) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.iconEmojiId) _data.push(this.iconEmojiId);

        _data.push(this.randomId);
        if (this.sendAs) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.sendAs) _data.push(this.sendAs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly title: TLString,
        readonly iconColor: TLInt | undefined,
        readonly iconEmojiId: TLLong | undefined,
        readonly randomId: TLLong,
        readonly sendAs: InputPeerType | undefined
      ) {}
    }
  }

  export namespace channels {
    export class GetForumTopics implements TLFunction<messages.ForumTopics> {
      static readonly cons = new TLInt(0xde560d1);

      static deserialized(_data: ByteStream): GetForumTopics | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetForumTopics.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        let q: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          q = obj;
        }
        const offsetDate = TLInt.deserialized(_data);
        if (!offsetDate) return undefined;
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const offsetTopic = TLInt.deserialized(_data);
        if (!offsetTopic) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetForumTopics(
          channel,
          q,
          offsetDate,
          offsetId,
          offsetTopic,
          limit
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetForumTopics.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.channel);
        if (this.q) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.q) _data.push(this.q);

        _data.push(this.offsetDate);
        _data.push(this.offsetId);
        _data.push(this.offsetTopic);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly q: TLString | undefined,
        readonly offsetDate: TLInt,
        readonly offsetId: TLInt,
        readonly offsetTopic: TLInt,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace channels {
    export class GetForumTopicsByID
      implements TLFunction<messages.ForumTopics>
    {
      static readonly cons = new TLInt(0xb0831eb9);

      static deserialized(_data: ByteStream): GetForumTopicsByID | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetForumTopicsByID.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const topics = TLVector.deserialized(_data, TLInt);
        if (!topics) return undefined;

        return new GetForumTopicsByID(channel, topics);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetForumTopicsByID.cons];

        _data.push(this.channel);
        _data.push(this.topics);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly topics: TLVector<TLInt>
      ) {}
    }
  }

  export namespace channels {
    export class EditForumTopic implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xf4dfa185);

      static deserialized(_data: ByteStream): EditForumTopic | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditForumTopic.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const topicId = TLInt.deserialized(_data);
        if (!topicId) return undefined;
        let title: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          title = obj;
        }
        let iconEmojiId: TLLong | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLLong.deserialized(_data);
          if (!obj) return undefined;
          iconEmojiId = obj;
        }
        let closed: BoolType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          closed = obj;
        }
        let hidden: BoolType | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          hidden = obj;
        }

        return new EditForumTopic(
          channel,
          topicId,
          title,
          iconEmojiId,
          closed,
          hidden
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditForumTopic.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.channel);
        _data.push(this.topicId);
        if (this.title) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.title) _data.push(this.title);

        if (this.iconEmojiId) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.iconEmojiId) _data.push(this.iconEmojiId);

        if (this.closed) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.closed) _data.push(this.closed);

        if (this.hidden) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.hidden) _data.push(this.hidden);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly topicId: TLInt,
        readonly title: TLString | undefined,
        readonly iconEmojiId: TLLong | undefined,
        readonly closed: BoolType | undefined,
        readonly hidden: BoolType | undefined
      ) {}
    }
  }

  export namespace channels {
    export class UpdatePinnedForumTopic implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x6c2d9026);

      static deserialized(
        _data: ByteStream
      ): UpdatePinnedForumTopic | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(UpdatePinnedForumTopic.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const topicId = TLInt.deserialized(_data);
        if (!topicId) return undefined;
        const pinned = TLObject.deserialized(_data) as BoolType;
        if (!pinned) return undefined;

        return new UpdatePinnedForumTopic(channel, topicId, pinned);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [UpdatePinnedForumTopic.cons];

        _data.push(this.channel);
        _data.push(this.topicId);
        _data.push(this.pinned);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly topicId: TLInt,
        readonly pinned: BoolType
      ) {}
    }
  }

  export namespace channels {
    export class DeleteTopicHistory
      implements TLFunction<messages.AffectedHistory>
    {
      static readonly cons = new TLInt(0x34435f2d);

      static deserialized(_data: ByteStream): DeleteTopicHistory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteTopicHistory.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const topMsgId = TLInt.deserialized(_data);
        if (!topMsgId) return undefined;

        return new DeleteTopicHistory(channel, topMsgId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteTopicHistory.cons];

        _data.push(this.channel);
        _data.push(this.topMsgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly topMsgId: TLInt
      ) {}
    }
  }

  export namespace channels {
    export class ReorderPinnedForumTopics implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x2950a18f);

      static deserialized(
        _data: ByteStream
      ): ReorderPinnedForumTopics | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReorderPinnedForumTopics.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const force = (flags.value & 1) !== 0;
        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const order = TLVector.deserialized(_data, TLInt);
        if (!order) return undefined;

        return new ReorderPinnedForumTopics(force, channel, order);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReorderPinnedForumTopics.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.force) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.channel);
        _data.push(this.order);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly force: boolean | undefined,
        readonly channel: InputChannelType,
        readonly order: TLVector<TLInt>
      ) {}
    }
  }

  export namespace channels {
    export class ToggleAntiSpam implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x68f3e4eb);

      static deserialized(_data: ByteStream): ToggleAntiSpam | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleAntiSpam.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const enabled = TLObject.deserialized(_data) as BoolType;
        if (!enabled) return undefined;

        return new ToggleAntiSpam(channel, enabled);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleAntiSpam.cons];

        _data.push(this.channel);
        _data.push(this.enabled);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly enabled: BoolType
      ) {}
    }
  }

  export namespace channels {
    export class ReportAntiSpamFalsePositive implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xa850a693);

      static deserialized(
        _data: ByteStream
      ): ReportAntiSpamFalsePositive | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(ReportAntiSpamFalsePositive.cons)
        )
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;

        return new ReportAntiSpamFalsePositive(channel, msgId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReportAntiSpamFalsePositive.cons];

        _data.push(this.channel);
        _data.push(this.msgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly channel: InputChannelType, readonly msgId: TLInt) {}
    }
  }

  export namespace channels {
    export class ToggleParticipantsHidden implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x6a6e7854);

      static deserialized(
        _data: ByteStream
      ): ToggleParticipantsHidden | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleParticipantsHidden.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const enabled = TLObject.deserialized(_data) as BoolType;
        if (!enabled) return undefined;

        return new ToggleParticipantsHidden(channel, enabled);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleParticipantsHidden.cons];

        _data.push(this.channel);
        _data.push(this.enabled);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly enabled: BoolType
      ) {}
    }
  }

  export namespace channels {
    export class ClickSponsoredMessage implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x18afbc93);

      static deserialized(
        _data: ByteStream
      ): ClickSponsoredMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ClickSponsoredMessage.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const randomId = TLBytes.deserialized(_data);
        if (!randomId) return undefined;

        return new ClickSponsoredMessage(channel, randomId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ClickSponsoredMessage.cons];

        _data.push(this.channel);
        _data.push(this.randomId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly randomId: TLBytes
      ) {}
    }
  }

  export namespace bots {
    export class SendCustomRequest implements TLFunction<API.DataJSON> {
      static readonly cons = new TLInt(0xaa2769ed);

      static deserialized(_data: ByteStream): SendCustomRequest | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendCustomRequest.cons))
          return undefined;

        const customMethod = TLString.deserialized(_data);
        if (!customMethod) return undefined;
        const params = API.DataJSON.deserialized(_data);
        if (!params) return undefined;

        return new SendCustomRequest(customMethod, params);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendCustomRequest.cons];

        _data.push(this.customMethod);
        _data.push(this.params);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly customMethod: TLString,
        readonly params: API.DataJSON
      ) {}
    }
  }

  export namespace bots {
    export class AnswerWebhookJSONQuery implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xe6213f4d);

      static deserialized(
        _data: ByteStream
      ): AnswerWebhookJSONQuery | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AnswerWebhookJSONQuery.cons))
          return undefined;

        const queryId = TLLong.deserialized(_data);
        if (!queryId) return undefined;
        const data = API.DataJSON.deserialized(_data);
        if (!data) return undefined;

        return new AnswerWebhookJSONQuery(queryId, data);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AnswerWebhookJSONQuery.cons];

        _data.push(this.queryId);
        _data.push(this.data);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly queryId: TLLong, readonly data: API.DataJSON) {}
    }
  }

  export namespace bots {
    export class SetBotCommands implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x517165a);

      static deserialized(_data: ByteStream): SetBotCommands | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetBotCommands.cons))
          return undefined;

        const scope = TLObject.deserialized(_data) as BotCommandScopeType;
        if (!scope) return undefined;
        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;
        const commands = TLVector.deserialized(_data, API.BotCommand);
        if (!commands) return undefined;

        return new SetBotCommands(scope, langCode, commands);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetBotCommands.cons];

        _data.push(this.scope);
        _data.push(this.langCode);
        _data.push(this.commands);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly scope: BotCommandScopeType,
        readonly langCode: TLString,
        readonly commands: TLVector<API.BotCommand>
      ) {}
    }
  }

  export namespace bots {
    export class ResetBotCommands implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x3d8de0f9);

      static deserialized(_data: ByteStream): ResetBotCommands | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ResetBotCommands.cons))
          return undefined;

        const scope = TLObject.deserialized(_data) as BotCommandScopeType;
        if (!scope) return undefined;
        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;

        return new ResetBotCommands(scope, langCode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ResetBotCommands.cons];

        _data.push(this.scope);
        _data.push(this.langCode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly scope: BotCommandScopeType,
        readonly langCode: TLString
      ) {}
    }
  }

  export namespace bots {
    export class GetBotCommands
      implements TLFunction<TLVector<API.BotCommand>>
    {
      static readonly cons = new TLInt(0xe34c0dd6);

      static deserialized(_data: ByteStream): GetBotCommands | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetBotCommands.cons))
          return undefined;

        const scope = TLObject.deserialized(_data) as BotCommandScopeType;
        if (!scope) return undefined;
        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;

        return new GetBotCommands(scope, langCode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetBotCommands.cons];

        _data.push(this.scope);
        _data.push(this.langCode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly scope: BotCommandScopeType,
        readonly langCode: TLString
      ) {}
    }
  }

  export namespace bots {
    export class SetBotMenuButton implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x4504d54f);

      static deserialized(_data: ByteStream): SetBotMenuButton | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetBotMenuButton.cons))
          return undefined;

        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const button = TLObject.deserialized(_data) as BotMenuButtonType;
        if (!button) return undefined;

        return new SetBotMenuButton(userId, button);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetBotMenuButton.cons];

        _data.push(this.userId);
        _data.push(this.button);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly userId: InputUserType,
        readonly button: BotMenuButtonType
      ) {}
    }
  }

  export namespace bots {
    export class GetBotMenuButton implements TLFunction<BotMenuButtonType> {
      static readonly cons = new TLInt(0x9c60eb28);

      static deserialized(_data: ByteStream): GetBotMenuButton | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetBotMenuButton.cons))
          return undefined;

        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;

        return new GetBotMenuButton(userId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetBotMenuButton.cons];

        _data.push(this.userId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly userId: InputUserType) {}
    }
  }

  export namespace bots {
    export class SetBotBroadcastDefaultAdminRights
      implements TLFunction<BoolType>
    {
      static readonly cons = new TLInt(0x788464e1);

      static deserialized(
        _data: ByteStream
      ): SetBotBroadcastDefaultAdminRights | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(SetBotBroadcastDefaultAdminRights.cons)
        )
          return undefined;

        const adminRights = API.ChatAdminRights.deserialized(_data);
        if (!adminRights) return undefined;

        return new SetBotBroadcastDefaultAdminRights(adminRights);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          SetBotBroadcastDefaultAdminRights.cons,
        ];

        _data.push(this.adminRights);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly adminRights: API.ChatAdminRights) {}
    }
  }

  export namespace bots {
    export class SetBotGroupDefaultAdminRights implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x925ec9ea);

      static deserialized(
        _data: ByteStream
      ): SetBotGroupDefaultAdminRights | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(SetBotGroupDefaultAdminRights.cons)
        )
          return undefined;

        const adminRights = API.ChatAdminRights.deserialized(_data);
        if (!adminRights) return undefined;

        return new SetBotGroupDefaultAdminRights(adminRights);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          SetBotGroupDefaultAdminRights.cons,
        ];

        _data.push(this.adminRights);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly adminRights: API.ChatAdminRights) {}
    }
  }

  export namespace bots {
    export class SetBotInfo implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x10cf3123);

      static deserialized(_data: ByteStream): SetBotInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetBotInfo.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let bot: InputUserType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as InputUserType;
          if (!obj) return undefined;
          bot = obj;
        }
        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;
        let name: TLString | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          name = obj;
        }
        let about: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          about = obj;
        }
        let description: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          description = obj;
        }

        return new SetBotInfo(bot, langCode, name, about, description);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetBotInfo.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.bot) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.bot) _data.push(this.bot);

        _data.push(this.langCode);
        if (this.name) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.name) _data.push(this.name);

        if (this.about) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.about) _data.push(this.about);

        if (this.description) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.description) _data.push(this.description);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly bot: InputUserType | undefined,
        readonly langCode: TLString,
        readonly name: TLString | undefined,
        readonly about: TLString | undefined,
        readonly description: TLString | undefined
      ) {}
    }
  }

  export namespace bots {
    export class GetBotInfo implements TLFunction<bots.BotInfo> {
      static readonly cons = new TLInt(0xdcd914fd);

      static deserialized(_data: ByteStream): GetBotInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetBotInfo.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        let bot: InputUserType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputUserType;
          if (!obj) return undefined;
          bot = obj;
        }
        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;

        return new GetBotInfo(bot, langCode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetBotInfo.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.bot) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.bot) _data.push(this.bot);

        _data.push(this.langCode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly bot: InputUserType | undefined,
        readonly langCode: TLString
      ) {}
    }
  }

  export namespace bots {
    export class ReorderUsernames implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x9709b1c2);

      static deserialized(_data: ByteStream): ReorderUsernames | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReorderUsernames.cons))
          return undefined;

        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;
        const order = TLVector.deserialized(_data, TLString);
        if (!order) return undefined;

        return new ReorderUsernames(bot, order);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReorderUsernames.cons];

        _data.push(this.bot);
        _data.push(this.order);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly bot: InputUserType,
        readonly order: TLVector<TLString>
      ) {}
    }
  }

  export namespace bots {
    export class ToggleUsername implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x53ca973);

      static deserialized(_data: ByteStream): ToggleUsername | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleUsername.cons))
          return undefined;

        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;
        const username = TLString.deserialized(_data);
        if (!username) return undefined;
        const active = TLObject.deserialized(_data) as BoolType;
        if (!active) return undefined;

        return new ToggleUsername(bot, username, active);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleUsername.cons];

        _data.push(this.bot);
        _data.push(this.username);
        _data.push(this.active);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly bot: InputUserType,
        readonly username: TLString,
        readonly active: BoolType
      ) {}
    }
  }

  export namespace bots {
    export class CanSendMessage implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x1359f4e6);

      static deserialized(_data: ByteStream): CanSendMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CanSendMessage.cons))
          return undefined;

        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;

        return new CanSendMessage(bot);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CanSendMessage.cons];

        _data.push(this.bot);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly bot: InputUserType) {}
    }
  }

  export namespace bots {
    export class AllowSendMessage implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xf132e3ef);

      static deserialized(_data: ByteStream): AllowSendMessage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AllowSendMessage.cons))
          return undefined;

        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;

        return new AllowSendMessage(bot);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AllowSendMessage.cons];

        _data.push(this.bot);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly bot: InputUserType) {}
    }
  }

  export namespace bots {
    export class InvokeWebViewCustomMethod implements TLFunction<API.DataJSON> {
      static readonly cons = new TLInt(0x87fc5e7);

      static deserialized(
        _data: ByteStream
      ): InvokeWebViewCustomMethod | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(InvokeWebViewCustomMethod.cons))
          return undefined;

        const bot = TLObject.deserialized(_data) as InputUserType;
        if (!bot) return undefined;
        const customMethod = TLString.deserialized(_data);
        if (!customMethod) return undefined;
        const params = API.DataJSON.deserialized(_data);
        if (!params) return undefined;

        return new InvokeWebViewCustomMethod(bot, customMethod, params);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [InvokeWebViewCustomMethod.cons];

        _data.push(this.bot);
        _data.push(this.customMethod);
        _data.push(this.params);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly bot: InputUserType,
        readonly customMethod: TLString,
        readonly params: API.DataJSON
      ) {}
    }
  }

  export namespace payments {
    export class GetPaymentForm implements TLFunction<payments.PaymentForm> {
      static readonly cons = new TLInt(0x37148dbb);

      static deserialized(_data: ByteStream): GetPaymentForm | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPaymentForm.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const invoice = TLObject.deserialized(_data) as InputInvoiceType;
        if (!invoice) return undefined;
        let themeParams: API.DataJSON | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as API.DataJSON;
          if (!obj) return undefined;
          themeParams = obj;
        }

        return new GetPaymentForm(invoice, themeParams);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPaymentForm.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.invoice);
        if (this.themeParams) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.themeParams) _data.push(this.themeParams);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly invoice: InputInvoiceType,
        readonly themeParams: API.DataJSON | undefined
      ) {}
    }
  }

  export namespace payments {
    export class GetPaymentReceipt
      implements TLFunction<payments.PaymentReceipt>
    {
      static readonly cons = new TLInt(0x2478d1cc);

      static deserialized(_data: ByteStream): GetPaymentReceipt | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPaymentReceipt.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;

        return new GetPaymentReceipt(peer, msgId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPaymentReceipt.cons];

        _data.push(this.peer);
        _data.push(this.msgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly msgId: TLInt) {}
    }
  }

  export namespace payments {
    export class ValidateRequestedInfo
      implements TLFunction<payments.ValidatedRequestedInfo>
    {
      static readonly cons = new TLInt(0xb6c8f12b);

      static deserialized(
        _data: ByteStream
      ): ValidateRequestedInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ValidateRequestedInfo.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const save = (flags.value & 1) !== 0;
        const invoice = TLObject.deserialized(_data) as InputInvoiceType;
        if (!invoice) return undefined;
        const info = API.PaymentRequestedInfo.deserialized(_data);
        if (!info) return undefined;

        return new ValidateRequestedInfo(save, invoice, info);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ValidateRequestedInfo.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.save) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.invoice);
        _data.push(this.info);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly save: boolean | undefined,
        readonly invoice: InputInvoiceType,
        readonly info: API.PaymentRequestedInfo
      ) {}
    }
  }

  export namespace payments {
    export class SendPaymentForm
      implements TLFunction<payments.PaymentResultType>
    {
      static readonly cons = new TLInt(0x2d03522f);

      static deserialized(_data: ByteStream): SendPaymentForm | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendPaymentForm.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const formId = TLLong.deserialized(_data);
        if (!formId) return undefined;
        const invoice = TLObject.deserialized(_data) as InputInvoiceType;
        if (!invoice) return undefined;
        let requestedInfoId: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          requestedInfoId = obj;
        }
        let shippingOptionId: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          shippingOptionId = obj;
        }
        const credentials = TLObject.deserialized(
          _data
        ) as InputPaymentCredentialsType;
        if (!credentials) return undefined;
        let tipAmount: TLLong | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLLong.deserialized(_data);
          if (!obj) return undefined;
          tipAmount = obj;
        }

        return new SendPaymentForm(
          formId,
          invoice,
          requestedInfoId,
          shippingOptionId,
          credentials,
          tipAmount
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendPaymentForm.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.formId);
        _data.push(this.invoice);
        if (this.requestedInfoId) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.requestedInfoId) _data.push(this.requestedInfoId);

        if (this.shippingOptionId) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.shippingOptionId) _data.push(this.shippingOptionId);

        _data.push(this.credentials);
        if (this.tipAmount) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.tipAmount) _data.push(this.tipAmount);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly formId: TLLong,
        readonly invoice: InputInvoiceType,
        readonly requestedInfoId: TLString | undefined,
        readonly shippingOptionId: TLString | undefined,
        readonly credentials: InputPaymentCredentialsType,
        readonly tipAmount: TLLong | undefined
      ) {}
    }
  }

  export namespace payments {
    export class GetSavedInfo implements TLFunction<payments.SavedInfo> {
      static readonly cons = new TLInt(0x227d824b);

      static deserialized(_data: ByteStream): GetSavedInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetSavedInfo.cons))
          return undefined;

        return new GetSavedInfo();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetSavedInfo.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace payments {
    export class ClearSavedInfo implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xd83d70c1);

      static deserialized(_data: ByteStream): ClearSavedInfo | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ClearSavedInfo.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const credentials = (flags.value & 1) !== 0;
        const info = (flags.value & 2) !== 0;

        return new ClearSavedInfo(credentials, info);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ClearSavedInfo.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.credentials) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.info) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly credentials: boolean | undefined,
        readonly info: boolean | undefined
      ) {}
    }
  }

  export namespace payments {
    export class GetBankCardData implements TLFunction<payments.BankCardData> {
      static readonly cons = new TLInt(0x2e79d779);

      static deserialized(_data: ByteStream): GetBankCardData | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetBankCardData.cons))
          return undefined;

        const number = TLString.deserialized(_data);
        if (!number) return undefined;

        return new GetBankCardData(number);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetBankCardData.cons];

        _data.push(this.number);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly number: TLString) {}
    }
  }

  export namespace payments {
    export class ExportInvoice implements TLFunction<payments.ExportedInvoice> {
      static readonly cons = new TLInt(0xf91b065);

      static deserialized(_data: ByteStream): ExportInvoice | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportInvoice.cons))
          return undefined;

        const invoiceMedia = TLObject.deserialized(_data) as InputMediaType;
        if (!invoiceMedia) return undefined;

        return new ExportInvoice(invoiceMedia);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportInvoice.cons];

        _data.push(this.invoiceMedia);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly invoiceMedia: InputMediaType) {}
    }
  }

  export namespace payments {
    export class AssignAppStoreTransaction implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x80ed747d);

      static deserialized(
        _data: ByteStream
      ): AssignAppStoreTransaction | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AssignAppStoreTransaction.cons))
          return undefined;

        const receipt = TLBytes.deserialized(_data);
        if (!receipt) return undefined;
        const purpose = TLObject.deserialized(
          _data
        ) as InputStorePaymentPurposeType;
        if (!purpose) return undefined;

        return new AssignAppStoreTransaction(receipt, purpose);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AssignAppStoreTransaction.cons];

        _data.push(this.receipt);
        _data.push(this.purpose);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly receipt: TLBytes,
        readonly purpose: InputStorePaymentPurposeType
      ) {}
    }
  }

  export namespace payments {
    export class AssignPlayMarketTransaction
      implements TLFunction<UpdatesType>
    {
      static readonly cons = new TLInt(0xdffd50d3);

      static deserialized(
        _data: ByteStream
      ): AssignPlayMarketTransaction | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(AssignPlayMarketTransaction.cons)
        )
          return undefined;

        const receipt = API.DataJSON.deserialized(_data);
        if (!receipt) return undefined;
        const purpose = TLObject.deserialized(
          _data
        ) as InputStorePaymentPurposeType;
        if (!purpose) return undefined;

        return new AssignPlayMarketTransaction(receipt, purpose);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AssignPlayMarketTransaction.cons];

        _data.push(this.receipt);
        _data.push(this.purpose);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly receipt: API.DataJSON,
        readonly purpose: InputStorePaymentPurposeType
      ) {}
    }
  }

  export namespace payments {
    export class CanPurchasePremium implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x9fc19eb6);

      static deserialized(_data: ByteStream): CanPurchasePremium | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CanPurchasePremium.cons))
          return undefined;

        const purpose = TLObject.deserialized(
          _data
        ) as InputStorePaymentPurposeType;
        if (!purpose) return undefined;

        return new CanPurchasePremium(purpose);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CanPurchasePremium.cons];

        _data.push(this.purpose);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly purpose: InputStorePaymentPurposeType) {}
    }
  }

  export namespace stickers {
    export class CreateStickerSet
      implements TLFunction<messages.StickerSetType>
    {
      static readonly cons = new TLInt(0x9021ab67);

      static deserialized(_data: ByteStream): CreateStickerSet | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CreateStickerSet.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const masks = (flags.value & 1) !== 0;
        const animated = (flags.value & 2) !== 0;
        const videos = (flags.value & 16) !== 0;
        const emojis = (flags.value & 32) !== 0;
        const textColor = (flags.value & 64) !== 0;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;
        const shortName = TLString.deserialized(_data);
        if (!shortName) return undefined;
        let thumb: InputDocumentType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as InputDocumentType;
          if (!obj) return undefined;
          thumb = obj;
        }
        const stickers = TLVector.deserialized(_data, API.InputStickerSetItem);
        if (!stickers) return undefined;
        let software: TLString | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          software = obj;
        }

        return new CreateStickerSet(
          masks,
          animated,
          videos,
          emojis,
          textColor,
          userId,
          title,
          shortName,
          thumb,
          stickers,
          software
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CreateStickerSet.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.masks) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.animated) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.videos) {
          flags.set(16);
        } else {
          flags.unset(16);
        }

        if (this.emojis) {
          flags.set(32);
        } else {
          flags.unset(32);
        }

        if (this.textColor) {
          flags.set(64);
        } else {
          flags.unset(64);
        }

        _data.push(this.userId);
        _data.push(this.title);
        _data.push(this.shortName);
        if (this.thumb) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.thumb) _data.push(this.thumb);

        _data.push(this.stickers);
        if (this.software) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.software) _data.push(this.software);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly masks: boolean | undefined,
        readonly animated: boolean | undefined,
        readonly videos: boolean | undefined,
        readonly emojis: boolean | undefined,
        readonly textColor: boolean | undefined,
        readonly userId: InputUserType,
        readonly title: TLString,
        readonly shortName: TLString,
        readonly thumb: InputDocumentType | undefined,
        readonly stickers: TLVector<API.InputStickerSetItem>,
        readonly software: TLString | undefined
      ) {}
    }
  }

  export namespace stickers {
    export class RemoveStickerFromSet
      implements TLFunction<messages.StickerSetType>
    {
      static readonly cons = new TLInt(0xf7760f51);

      static deserialized(_data: ByteStream): RemoveStickerFromSet | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RemoveStickerFromSet.cons))
          return undefined;

        const sticker = TLObject.deserialized(_data) as InputDocumentType;
        if (!sticker) return undefined;

        return new RemoveStickerFromSet(sticker);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RemoveStickerFromSet.cons];

        _data.push(this.sticker);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly sticker: InputDocumentType) {}
    }
  }

  export namespace stickers {
    export class ChangeStickerPosition
      implements TLFunction<messages.StickerSetType>
    {
      static readonly cons = new TLInt(0xffb6d4ca);

      static deserialized(
        _data: ByteStream
      ): ChangeStickerPosition | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChangeStickerPosition.cons))
          return undefined;

        const sticker = TLObject.deserialized(_data) as InputDocumentType;
        if (!sticker) return undefined;
        const position = TLInt.deserialized(_data);
        if (!position) return undefined;

        return new ChangeStickerPosition(sticker, position);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChangeStickerPosition.cons];

        _data.push(this.sticker);
        _data.push(this.position);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly sticker: InputDocumentType,
        readonly position: TLInt
      ) {}
    }
  }

  export namespace stickers {
    export class AddStickerToSet
      implements TLFunction<messages.StickerSetType>
    {
      static readonly cons = new TLInt(0x8653febe);

      static deserialized(_data: ByteStream): AddStickerToSet | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AddStickerToSet.cons))
          return undefined;

        const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
        if (!stickerset) return undefined;
        const sticker = API.InputStickerSetItem.deserialized(_data);
        if (!sticker) return undefined;

        return new AddStickerToSet(stickerset, sticker);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AddStickerToSet.cons];

        _data.push(this.stickerset);
        _data.push(this.sticker);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly stickerset: InputStickerSetType,
        readonly sticker: API.InputStickerSetItem
      ) {}
    }
  }

  export namespace stickers {
    export class SetStickerSetThumb
      implements TLFunction<messages.StickerSetType>
    {
      static readonly cons = new TLInt(0xa76a5392);

      static deserialized(_data: ByteStream): SetStickerSetThumb | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetStickerSetThumb.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
        if (!stickerset) return undefined;
        let thumb: InputDocumentType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputDocumentType;
          if (!obj) return undefined;
          thumb = obj;
        }
        let thumbDocumentId: TLLong | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLLong.deserialized(_data);
          if (!obj) return undefined;
          thumbDocumentId = obj;
        }

        return new SetStickerSetThumb(stickerset, thumb, thumbDocumentId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetStickerSetThumb.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.stickerset);
        if (this.thumb) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.thumb) _data.push(this.thumb);

        if (this.thumbDocumentId) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.thumbDocumentId) _data.push(this.thumbDocumentId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly stickerset: InputStickerSetType,
        readonly thumb: InputDocumentType | undefined,
        readonly thumbDocumentId: TLLong | undefined
      ) {}
    }
  }

  export namespace stickers {
    export class CheckShortName implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x284b3639);

      static deserialized(_data: ByteStream): CheckShortName | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CheckShortName.cons))
          return undefined;

        const shortName = TLString.deserialized(_data);
        if (!shortName) return undefined;

        return new CheckShortName(shortName);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CheckShortName.cons];

        _data.push(this.shortName);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly shortName: TLString) {}
    }
  }

  export namespace stickers {
    export class SuggestShortName
      implements TLFunction<stickers.SuggestedShortName>
    {
      static readonly cons = new TLInt(0x4dafc503);

      static deserialized(_data: ByteStream): SuggestShortName | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SuggestShortName.cons))
          return undefined;

        const title = TLString.deserialized(_data);
        if (!title) return undefined;

        return new SuggestShortName(title);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SuggestShortName.cons];

        _data.push(this.title);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly title: TLString) {}
    }
  }

  export namespace stickers {
    export class ChangeSticker implements TLFunction<messages.StickerSetType> {
      static readonly cons = new TLInt(0xf5537ebc);

      static deserialized(_data: ByteStream): ChangeSticker | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ChangeSticker.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const sticker = TLObject.deserialized(_data) as InputDocumentType;
        if (!sticker) return undefined;
        let emoji: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          emoji = obj;
        }
        let maskCoords: API.MaskCoords | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLObject.deserialized(_data) as API.MaskCoords;
          if (!obj) return undefined;
          maskCoords = obj;
        }
        let keywords: TLString | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          keywords = obj;
        }

        return new ChangeSticker(sticker, emoji, maskCoords, keywords);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ChangeSticker.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.sticker);
        if (this.emoji) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.emoji) _data.push(this.emoji);

        if (this.maskCoords) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.maskCoords) _data.push(this.maskCoords);

        if (this.keywords) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.keywords) _data.push(this.keywords);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly sticker: InputDocumentType,
        readonly emoji: TLString | undefined,
        readonly maskCoords: API.MaskCoords | undefined,
        readonly keywords: TLString | undefined
      ) {}
    }
  }

  export namespace stickers {
    export class RenameStickerSet
      implements TLFunction<messages.StickerSetType>
    {
      static readonly cons = new TLInt(0x124b1c00);

      static deserialized(_data: ByteStream): RenameStickerSet | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RenameStickerSet.cons))
          return undefined;

        const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
        if (!stickerset) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;

        return new RenameStickerSet(stickerset, title);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RenameStickerSet.cons];

        _data.push(this.stickerset);
        _data.push(this.title);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly stickerset: InputStickerSetType,
        readonly title: TLString
      ) {}
    }
  }

  export namespace stickers {
    export class DeleteStickerSet implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x87704394);

      static deserialized(_data: ByteStream): DeleteStickerSet | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteStickerSet.cons))
          return undefined;

        const stickerset = TLObject.deserialized(_data) as InputStickerSetType;
        if (!stickerset) return undefined;

        return new DeleteStickerSet(stickerset);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteStickerSet.cons];

        _data.push(this.stickerset);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly stickerset: InputStickerSetType) {}
    }
  }

  export namespace phone {
    export class GetCallConfig implements TLFunction<API.DataJSON> {
      static readonly cons = new TLInt(0x55451fa9);

      static deserialized(_data: ByteStream): GetCallConfig | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetCallConfig.cons))
          return undefined;

        return new GetCallConfig();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetCallConfig.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace phone {
    export class RequestCall implements TLFunction<phone.PhoneCall> {
      static readonly cons = new TLInt(0x42ff96ed);

      static deserialized(_data: ByteStream): RequestCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(RequestCall.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const video = (flags.value & 1) !== 0;
        const userId = TLObject.deserialized(_data) as InputUserType;
        if (!userId) return undefined;
        const randomId = TLInt.deserialized(_data);
        if (!randomId) return undefined;
        const gAHash = TLBytes.deserialized(_data);
        if (!gAHash) return undefined;
        const protocol = API.PhoneCallProtocol.deserialized(_data);
        if (!protocol) return undefined;

        return new RequestCall(video, userId, randomId, gAHash, protocol);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [RequestCall.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.video) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.userId);
        _data.push(this.randomId);
        _data.push(this.gAHash);
        _data.push(this.protocol);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly video: boolean | undefined,
        readonly userId: InputUserType,
        readonly randomId: TLInt,
        readonly gAHash: TLBytes,
        readonly protocol: API.PhoneCallProtocol
      ) {}
    }
  }

  export namespace phone {
    export class AcceptCall implements TLFunction<phone.PhoneCall> {
      static readonly cons = new TLInt(0x3bd2b4a0);

      static deserialized(_data: ByteStream): AcceptCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(AcceptCall.cons))
          return undefined;

        const peer = API.InputPhoneCall.deserialized(_data);
        if (!peer) return undefined;
        const gB = TLBytes.deserialized(_data);
        if (!gB) return undefined;
        const protocol = API.PhoneCallProtocol.deserialized(_data);
        if (!protocol) return undefined;

        return new AcceptCall(peer, gB, protocol);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [AcceptCall.cons];

        _data.push(this.peer);
        _data.push(this.gB);
        _data.push(this.protocol);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: API.InputPhoneCall,
        readonly gB: TLBytes,
        readonly protocol: API.PhoneCallProtocol
      ) {}
    }
  }

  export namespace phone {
    export class ConfirmCall implements TLFunction<phone.PhoneCall> {
      static readonly cons = new TLInt(0x2efe1722);

      static deserialized(_data: ByteStream): ConfirmCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ConfirmCall.cons))
          return undefined;

        const peer = API.InputPhoneCall.deserialized(_data);
        if (!peer) return undefined;
        const gA = TLBytes.deserialized(_data);
        if (!gA) return undefined;
        const keyFingerprint = TLLong.deserialized(_data);
        if (!keyFingerprint) return undefined;
        const protocol = API.PhoneCallProtocol.deserialized(_data);
        if (!protocol) return undefined;

        return new ConfirmCall(peer, gA, keyFingerprint, protocol);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ConfirmCall.cons];

        _data.push(this.peer);
        _data.push(this.gA);
        _data.push(this.keyFingerprint);
        _data.push(this.protocol);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: API.InputPhoneCall,
        readonly gA: TLBytes,
        readonly keyFingerprint: TLLong,
        readonly protocol: API.PhoneCallProtocol
      ) {}
    }
  }

  export namespace phone {
    export class ReceivedCall implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x17d54f61);

      static deserialized(_data: ByteStream): ReceivedCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReceivedCall.cons))
          return undefined;

        const peer = API.InputPhoneCall.deserialized(_data);
        if (!peer) return undefined;

        return new ReceivedCall(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReceivedCall.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: API.InputPhoneCall) {}
    }
  }

  export namespace phone {
    export class DiscardCall implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xb2cbc1c0);

      static deserialized(_data: ByteStream): DiscardCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DiscardCall.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const video = (flags.value & 1) !== 0;
        const peer = API.InputPhoneCall.deserialized(_data);
        if (!peer) return undefined;
        const duration = TLInt.deserialized(_data);
        if (!duration) return undefined;
        const reason = TLObject.deserialized(
          _data
        ) as PhoneCallDiscardReasonType;
        if (!reason) return undefined;
        const connectionId = TLLong.deserialized(_data);
        if (!connectionId) return undefined;

        return new DiscardCall(video, peer, duration, reason, connectionId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DiscardCall.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.video) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);
        _data.push(this.duration);
        _data.push(this.reason);
        _data.push(this.connectionId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly video: boolean | undefined,
        readonly peer: API.InputPhoneCall,
        readonly duration: TLInt,
        readonly reason: PhoneCallDiscardReasonType,
        readonly connectionId: TLLong
      ) {}
    }
  }

  export namespace phone {
    export class SetCallRating implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x59ead627);

      static deserialized(_data: ByteStream): SetCallRating | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SetCallRating.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const userInitiative = (flags.value & 1) !== 0;
        const peer = API.InputPhoneCall.deserialized(_data);
        if (!peer) return undefined;
        const rating = TLInt.deserialized(_data);
        if (!rating) return undefined;
        const comment = TLString.deserialized(_data);
        if (!comment) return undefined;

        return new SetCallRating(userInitiative, peer, rating, comment);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SetCallRating.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.userInitiative) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);
        _data.push(this.rating);
        _data.push(this.comment);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly userInitiative: boolean | undefined,
        readonly peer: API.InputPhoneCall,
        readonly rating: TLInt,
        readonly comment: TLString
      ) {}
    }
  }

  export namespace phone {
    export class SaveCallDebug implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x277add7e);

      static deserialized(_data: ByteStream): SaveCallDebug | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveCallDebug.cons))
          return undefined;

        const peer = API.InputPhoneCall.deserialized(_data);
        if (!peer) return undefined;
        const debug = API.DataJSON.deserialized(_data);
        if (!debug) return undefined;

        return new SaveCallDebug(peer, debug);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveCallDebug.cons];

        _data.push(this.peer);
        _data.push(this.debug);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: API.InputPhoneCall,
        readonly debug: API.DataJSON
      ) {}
    }
  }

  export namespace phone {
    export class SendSignalingData implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xff7a9383);

      static deserialized(_data: ByteStream): SendSignalingData | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendSignalingData.cons))
          return undefined;

        const peer = API.InputPhoneCall.deserialized(_data);
        if (!peer) return undefined;
        const data = TLBytes.deserialized(_data);
        if (!data) return undefined;

        return new SendSignalingData(peer, data);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendSignalingData.cons];

        _data.push(this.peer);
        _data.push(this.data);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: API.InputPhoneCall, readonly data: TLBytes) {}
    }
  }

  export namespace phone {
    export class CreateGroupCall implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x48cdc6d8);

      static deserialized(_data: ByteStream): CreateGroupCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CreateGroupCall.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const rtmpStream = (flags.value & 4) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const randomId = TLInt.deserialized(_data);
        if (!randomId) return undefined;
        let title: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          title = obj;
        }
        let scheduleDate: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          scheduleDate = obj;
        }

        return new CreateGroupCall(
          rtmpStream,
          peer,
          randomId,
          title,
          scheduleDate
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CreateGroupCall.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.rtmpStream) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.peer);
        _data.push(this.randomId);
        if (this.title) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.title) _data.push(this.title);

        if (this.scheduleDate) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.scheduleDate) _data.push(this.scheduleDate);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly rtmpStream: boolean | undefined,
        readonly peer: InputPeerType,
        readonly randomId: TLInt,
        readonly title: TLString | undefined,
        readonly scheduleDate: TLInt | undefined
      ) {}
    }
  }

  export namespace phone {
    export class JoinGroupCall implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xb132ff7b);

      static deserialized(_data: ByteStream): JoinGroupCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(JoinGroupCall.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const muted = (flags.value & 1) !== 0;
        const videoStopped = (flags.value & 4) !== 0;
        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        const joinAs = TLObject.deserialized(_data) as InputPeerType;
        if (!joinAs) return undefined;
        let inviteHash: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          inviteHash = obj;
        }
        const params = API.DataJSON.deserialized(_data);
        if (!params) return undefined;

        return new JoinGroupCall(
          muted,
          videoStopped,
          call,
          joinAs,
          inviteHash,
          params
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [JoinGroupCall.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.muted) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.videoStopped) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.call);
        _data.push(this.joinAs);
        if (this.inviteHash) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.inviteHash) _data.push(this.inviteHash);

        _data.push(this.params);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly muted: boolean | undefined,
        readonly videoStopped: boolean | undefined,
        readonly call: API.InputGroupCall,
        readonly joinAs: InputPeerType,
        readonly inviteHash: TLString | undefined,
        readonly params: API.DataJSON
      ) {}
    }
  }

  export namespace phone {
    export class LeaveGroupCall implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x500377f9);

      static deserialized(_data: ByteStream): LeaveGroupCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(LeaveGroupCall.cons))
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        const source = TLInt.deserialized(_data);
        if (!source) return undefined;

        return new LeaveGroupCall(call, source);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [LeaveGroupCall.cons];

        _data.push(this.call);
        _data.push(this.source);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly call: API.InputGroupCall, readonly source: TLInt) {}
    }
  }

  export namespace phone {
    export class InviteToGroupCall implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x7b393160);

      static deserialized(_data: ByteStream): InviteToGroupCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(InviteToGroupCall.cons))
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        const users = TLVector.deserialized(_data) as TLVector<InputUserType>;
        if (!users) return undefined;

        return new InviteToGroupCall(call, users);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [InviteToGroupCall.cons];

        _data.push(this.call);
        _data.push(this.users);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly call: API.InputGroupCall,
        readonly users: TLVector<InputUserType>
      ) {}
    }
  }

  export namespace phone {
    export class DiscardGroupCall implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x7a777135);

      static deserialized(_data: ByteStream): DiscardGroupCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DiscardGroupCall.cons))
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;

        return new DiscardGroupCall(call);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DiscardGroupCall.cons];

        _data.push(this.call);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly call: API.InputGroupCall) {}
    }
  }

  export namespace phone {
    export class ToggleGroupCallSettings implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x74bbb43d);

      static deserialized(
        _data: ByteStream
      ): ToggleGroupCallSettings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleGroupCallSettings.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const resetInviteHash = (flags.value & 2) !== 0;
        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        let joinMuted: BoolType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          joinMuted = obj;
        }

        return new ToggleGroupCallSettings(resetInviteHash, call, joinMuted);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleGroupCallSettings.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.resetInviteHash) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        _data.push(this.call);
        if (this.joinMuted) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.joinMuted) _data.push(this.joinMuted);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly resetInviteHash: boolean | undefined,
        readonly call: API.InputGroupCall,
        readonly joinMuted: BoolType | undefined
      ) {}
    }
  }

  export namespace phone {
    export class GetGroupCall implements TLFunction<phone.GroupCall> {
      static readonly cons = new TLInt(0x41845db);

      static deserialized(_data: ByteStream): GetGroupCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetGroupCall.cons))
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetGroupCall(call, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetGroupCall.cons];

        _data.push(this.call);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly call: API.InputGroupCall, readonly limit: TLInt) {}
    }
  }

  export namespace phone {
    export class GetGroupParticipants
      implements TLFunction<phone.GroupParticipants>
    {
      static readonly cons = new TLInt(0xc558d8ab);

      static deserialized(_data: ByteStream): GetGroupParticipants | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetGroupParticipants.cons))
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        const ids = TLVector.deserialized(_data) as TLVector<InputPeerType>;
        if (!ids) return undefined;
        const sources = TLVector.deserialized(_data, TLInt);
        if (!sources) return undefined;
        const offset = TLString.deserialized(_data);
        if (!offset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetGroupParticipants(call, ids, sources, offset, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetGroupParticipants.cons];

        _data.push(this.call);
        _data.push(this.ids);
        _data.push(this.sources);
        _data.push(this.offset);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly call: API.InputGroupCall,
        readonly ids: TLVector<InputPeerType>,
        readonly sources: TLVector<TLInt>,
        readonly offset: TLString,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace phone {
    export class CheckGroupCall implements TLFunction<TLVector<TLInt>> {
      static readonly cons = new TLInt(0xb59cf977);

      static deserialized(_data: ByteStream): CheckGroupCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CheckGroupCall.cons))
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        const sources = TLVector.deserialized(_data, TLInt);
        if (!sources) return undefined;

        return new CheckGroupCall(call, sources);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CheckGroupCall.cons];

        _data.push(this.call);
        _data.push(this.sources);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly call: API.InputGroupCall,
        readonly sources: TLVector<TLInt>
      ) {}
    }
  }

  export namespace phone {
    export class ToggleGroupCallRecord implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xf128c708);

      static deserialized(
        _data: ByteStream
      ): ToggleGroupCallRecord | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleGroupCallRecord.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const start = (flags.value & 1) !== 0;
        const video = (flags.value & 4) !== 0;
        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        let title: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          title = obj;
        }
        let videoPortrait: BoolType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          videoPortrait = obj;
        }

        return new ToggleGroupCallRecord(
          start,
          video,
          call,
          title,
          videoPortrait
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleGroupCallRecord.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.start) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.video) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.call);
        if (this.title) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.title) _data.push(this.title);

        if (this.videoPortrait) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.videoPortrait) _data.push(this.videoPortrait);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly start: boolean | undefined,
        readonly video: boolean | undefined,
        readonly call: API.InputGroupCall,
        readonly title: TLString | undefined,
        readonly videoPortrait: BoolType | undefined
      ) {}
    }
  }

  export namespace phone {
    export class EditGroupCallParticipant implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xa5273abf);

      static deserialized(
        _data: ByteStream
      ): EditGroupCallParticipant | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditGroupCallParticipant.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        const participant = TLObject.deserialized(_data) as InputPeerType;
        if (!participant) return undefined;
        let muted: BoolType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          muted = obj;
        }
        let volume: TLInt | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          volume = obj;
        }
        let raiseHand: BoolType | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          raiseHand = obj;
        }
        let videoStopped: BoolType | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          videoStopped = obj;
        }
        let videoPaused: BoolType | undefined;
        if ((flags.value & 16) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          videoPaused = obj;
        }
        let presentationPaused: BoolType | undefined;
        if ((flags.value & 32) !== 0) {
          const obj = TLObject.deserialized(_data) as BoolType;
          if (!obj) return undefined;
          presentationPaused = obj;
        }

        return new EditGroupCallParticipant(
          call,
          participant,
          muted,
          volume,
          raiseHand,
          videoStopped,
          videoPaused,
          presentationPaused
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditGroupCallParticipant.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.call);
        _data.push(this.participant);
        if (this.muted) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.muted) _data.push(this.muted);

        if (this.volume) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.volume) _data.push(this.volume);

        if (this.raiseHand) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.raiseHand) _data.push(this.raiseHand);

        if (this.videoStopped) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.videoStopped) _data.push(this.videoStopped);

        if (this.videoPaused) {
          flags.set(16);
        } else {
          flags.unset(16);
        }
        if (this.videoPaused) _data.push(this.videoPaused);

        if (this.presentationPaused) {
          flags.set(32);
        } else {
          flags.unset(32);
        }
        if (this.presentationPaused) _data.push(this.presentationPaused);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly call: API.InputGroupCall,
        readonly participant: InputPeerType,
        readonly muted: BoolType | undefined,
        readonly volume: TLInt | undefined,
        readonly raiseHand: BoolType | undefined,
        readonly videoStopped: BoolType | undefined,
        readonly videoPaused: BoolType | undefined,
        readonly presentationPaused: BoolType | undefined
      ) {}
    }
  }

  export namespace phone {
    export class EditGroupCallTitle implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x1ca6ac0a);

      static deserialized(_data: ByteStream): EditGroupCallTitle | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditGroupCallTitle.cons))
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;

        return new EditGroupCallTitle(call, title);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditGroupCallTitle.cons];

        _data.push(this.call);
        _data.push(this.title);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly call: API.InputGroupCall,
        readonly title: TLString
      ) {}
    }
  }

  export namespace phone {
    export class GetGroupCallJoinAs implements TLFunction<phone.JoinAsPeers> {
      static readonly cons = new TLInt(0xef7c213a);

      static deserialized(_data: ByteStream): GetGroupCallJoinAs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetGroupCallJoinAs.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new GetGroupCallJoinAs(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetGroupCallJoinAs.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace phone {
    export class ExportGroupCallInvite
      implements TLFunction<phone.ExportedGroupCallInvite>
    {
      static readonly cons = new TLInt(0xe6aa647f);

      static deserialized(
        _data: ByteStream
      ): ExportGroupCallInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportGroupCallInvite.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const canSelfUnmute = (flags.value & 1) !== 0;
        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;

        return new ExportGroupCallInvite(canSelfUnmute, call);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportGroupCallInvite.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.canSelfUnmute) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.call);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly canSelfUnmute: boolean | undefined,
        readonly call: API.InputGroupCall
      ) {}
    }
  }

  export namespace phone {
    export class ToggleGroupCallStartSubscription
      implements TLFunction<UpdatesType>
    {
      static readonly cons = new TLInt(0x219c34e6);

      static deserialized(
        _data: ByteStream
      ): ToggleGroupCallStartSubscription | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(ToggleGroupCallStartSubscription.cons)
        )
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        const subscribed = TLObject.deserialized(_data) as BoolType;
        if (!subscribed) return undefined;

        return new ToggleGroupCallStartSubscription(call, subscribed);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [
          ToggleGroupCallStartSubscription.cons,
        ];

        _data.push(this.call);
        _data.push(this.subscribed);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly call: API.InputGroupCall,
        readonly subscribed: BoolType
      ) {}
    }
  }

  export namespace phone {
    export class StartScheduledGroupCall implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x5680e342);

      static deserialized(
        _data: ByteStream
      ): StartScheduledGroupCall | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(StartScheduledGroupCall.cons))
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;

        return new StartScheduledGroupCall(call);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [StartScheduledGroupCall.cons];

        _data.push(this.call);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly call: API.InputGroupCall) {}
    }
  }

  export namespace phone {
    export class SaveDefaultGroupCallJoinAs implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x575e1f8c);

      static deserialized(
        _data: ByteStream
      ): SaveDefaultGroupCallJoinAs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(SaveDefaultGroupCallJoinAs.cons)
        )
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const joinAs = TLObject.deserialized(_data) as InputPeerType;
        if (!joinAs) return undefined;

        return new SaveDefaultGroupCallJoinAs(peer, joinAs);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveDefaultGroupCallJoinAs.cons];

        _data.push(this.peer);
        _data.push(this.joinAs);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly joinAs: InputPeerType
      ) {}
    }
  }

  export namespace phone {
    export class JoinGroupCallPresentation implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xcbea6bc4);

      static deserialized(
        _data: ByteStream
      ): JoinGroupCallPresentation | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(JoinGroupCallPresentation.cons))
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;
        const params = API.DataJSON.deserialized(_data);
        if (!params) return undefined;

        return new JoinGroupCallPresentation(call, params);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [JoinGroupCallPresentation.cons];

        _data.push(this.call);
        _data.push(this.params);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly call: API.InputGroupCall,
        readonly params: API.DataJSON
      ) {}
    }
  }

  export namespace phone {
    export class LeaveGroupCallPresentation implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x1c50d144);

      static deserialized(
        _data: ByteStream
      ): LeaveGroupCallPresentation | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(LeaveGroupCallPresentation.cons)
        )
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;

        return new LeaveGroupCallPresentation(call);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [LeaveGroupCallPresentation.cons];

        _data.push(this.call);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly call: API.InputGroupCall) {}
    }
  }

  export namespace phone {
    export class GetGroupCallStreamChannels
      implements TLFunction<phone.GroupCallStreamChannels>
    {
      static readonly cons = new TLInt(0x1ab21940);

      static deserialized(
        _data: ByteStream
      ): GetGroupCallStreamChannels | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(GetGroupCallStreamChannels.cons)
        )
          return undefined;

        const call = API.InputGroupCall.deserialized(_data);
        if (!call) return undefined;

        return new GetGroupCallStreamChannels(call);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetGroupCallStreamChannels.cons];

        _data.push(this.call);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly call: API.InputGroupCall) {}
    }
  }

  export namespace phone {
    export class GetGroupCallStreamRtmpUrl
      implements TLFunction<phone.GroupCallStreamRtmpUrl>
    {
      static readonly cons = new TLInt(0xdeb3abbf);

      static deserialized(
        _data: ByteStream
      ): GetGroupCallStreamRtmpUrl | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetGroupCallStreamRtmpUrl.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const revoke = TLObject.deserialized(_data) as BoolType;
        if (!revoke) return undefined;

        return new GetGroupCallStreamRtmpUrl(peer, revoke);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetGroupCallStreamRtmpUrl.cons];

        _data.push(this.peer);
        _data.push(this.revoke);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly revoke: BoolType) {}
    }
  }

  export namespace phone {
    export class SaveCallLog implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x41248786);

      static deserialized(_data: ByteStream): SaveCallLog | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SaveCallLog.cons))
          return undefined;

        const peer = API.InputPhoneCall.deserialized(_data);
        if (!peer) return undefined;
        const file = TLObject.deserialized(_data) as InputFileType;
        if (!file) return undefined;

        return new SaveCallLog(peer, file);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SaveCallLog.cons];

        _data.push(this.peer);
        _data.push(this.file);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: API.InputPhoneCall,
        readonly file: InputFileType
      ) {}
    }
  }

  export namespace langpack {
    export class GetLangPack implements TLFunction<API.LangPackDifference> {
      static readonly cons = new TLInt(0xf2f2330a);

      static deserialized(_data: ByteStream): GetLangPack | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetLangPack.cons))
          return undefined;

        const langPack = TLString.deserialized(_data);
        if (!langPack) return undefined;
        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;

        return new GetLangPack(langPack, langCode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetLangPack.cons];

        _data.push(this.langPack);
        _data.push(this.langCode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly langPack: TLString, readonly langCode: TLString) {}
    }
  }

  export namespace langpack {
    export class GetStrings
      implements TLFunction<TLVector<LangPackStringType>>
    {
      static readonly cons = new TLInt(0xefea3803);

      static deserialized(_data: ByteStream): GetStrings | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetStrings.cons))
          return undefined;

        const langPack = TLString.deserialized(_data);
        if (!langPack) return undefined;
        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;
        const keys = TLVector.deserialized(_data, TLString);
        if (!keys) return undefined;

        return new GetStrings(langPack, langCode, keys);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetStrings.cons];

        _data.push(this.langPack);
        _data.push(this.langCode);
        _data.push(this.keys);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly langPack: TLString,
        readonly langCode: TLString,
        readonly keys: TLVector<TLString>
      ) {}
    }
  }

  export namespace langpack {
    export class GetDifference implements TLFunction<API.LangPackDifference> {
      static readonly cons = new TLInt(0xcd984aa5);

      static deserialized(_data: ByteStream): GetDifference | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetDifference.cons))
          return undefined;

        const langPack = TLString.deserialized(_data);
        if (!langPack) return undefined;
        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;
        const fromVersion = TLInt.deserialized(_data);
        if (!fromVersion) return undefined;

        return new GetDifference(langPack, langCode, fromVersion);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetDifference.cons];

        _data.push(this.langPack);
        _data.push(this.langCode);
        _data.push(this.fromVersion);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly langPack: TLString,
        readonly langCode: TLString,
        readonly fromVersion: TLInt
      ) {}
    }
  }

  export namespace langpack {
    export class GetLanguages
      implements TLFunction<TLVector<API.LangPackLanguage>>
    {
      static readonly cons = new TLInt(0x42c6978f);

      static deserialized(_data: ByteStream): GetLanguages | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetLanguages.cons))
          return undefined;

        const langPack = TLString.deserialized(_data);
        if (!langPack) return undefined;

        return new GetLanguages(langPack);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetLanguages.cons];

        _data.push(this.langPack);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly langPack: TLString) {}
    }
  }

  export namespace langpack {
    export class GetLanguage implements TLFunction<API.LangPackLanguage> {
      static readonly cons = new TLInt(0x6a596502);

      static deserialized(_data: ByteStream): GetLanguage | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetLanguage.cons))
          return undefined;

        const langPack = TLString.deserialized(_data);
        if (!langPack) return undefined;
        const langCode = TLString.deserialized(_data);
        if (!langCode) return undefined;

        return new GetLanguage(langPack, langCode);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetLanguage.cons];

        _data.push(this.langPack);
        _data.push(this.langCode);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly langPack: TLString, readonly langCode: TLString) {}
    }
  }

  export namespace folders {
    export class EditPeerFolders implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x6847d0ab);

      static deserialized(_data: ByteStream): EditPeerFolders | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditPeerFolders.cons))
          return undefined;

        const folderPeers = TLVector.deserialized(_data, API.InputFolderPeer);
        if (!folderPeers) return undefined;

        return new EditPeerFolders(folderPeers);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditPeerFolders.cons];

        _data.push(this.folderPeers);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly folderPeers: TLVector<API.InputFolderPeer>) {}
    }
  }

  export namespace stats {
    export class GetBroadcastStats implements TLFunction<stats.BroadcastStats> {
      static readonly cons = new TLInt(0xab42441a);

      static deserialized(_data: ByteStream): GetBroadcastStats | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetBroadcastStats.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const dark = (flags.value & 1) !== 0;
        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;

        return new GetBroadcastStats(dark, channel);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetBroadcastStats.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.dark) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.channel);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly dark: boolean | undefined,
        readonly channel: InputChannelType
      ) {}
    }
  }

  export namespace stats {
    export class LoadAsyncGraph implements TLFunction<StatsGraphType> {
      static readonly cons = new TLInt(0x621d5fa0);

      static deserialized(_data: ByteStream): LoadAsyncGraph | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(LoadAsyncGraph.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const token = TLString.deserialized(_data);
        if (!token) return undefined;
        let x: TLLong | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLLong.deserialized(_data);
          if (!obj) return undefined;
          x = obj;
        }

        return new LoadAsyncGraph(token, x);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [LoadAsyncGraph.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.token);
        if (this.x) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.x) _data.push(this.x);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly token: TLString, readonly x: TLLong | undefined) {}
    }
  }

  export namespace stats {
    export class GetMegagroupStats implements TLFunction<stats.MegagroupStats> {
      static readonly cons = new TLInt(0xdcdf8607);

      static deserialized(_data: ByteStream): GetMegagroupStats | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetMegagroupStats.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const dark = (flags.value & 1) !== 0;
        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;

        return new GetMegagroupStats(dark, channel);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMegagroupStats.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.dark) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.channel);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly dark: boolean | undefined,
        readonly channel: InputChannelType
      ) {}
    }
  }

  export namespace stats {
    export class GetMessagePublicForwards
      implements TLFunction<messages.MessagesType>
    {
      static readonly cons = new TLInt(0x5630281b);

      static deserialized(
        _data: ByteStream
      ): GetMessagePublicForwards | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetMessagePublicForwards.cons))
          return undefined;

        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;
        const offsetRate = TLInt.deserialized(_data);
        if (!offsetRate) return undefined;
        const offsetPeer = TLObject.deserialized(_data) as InputPeerType;
        if (!offsetPeer) return undefined;
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetMessagePublicForwards(
          channel,
          msgId,
          offsetRate,
          offsetPeer,
          offsetId,
          limit
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMessagePublicForwards.cons];

        _data.push(this.channel);
        _data.push(this.msgId);
        _data.push(this.offsetRate);
        _data.push(this.offsetPeer);
        _data.push(this.offsetId);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly channel: InputChannelType,
        readonly msgId: TLInt,
        readonly offsetRate: TLInt,
        readonly offsetPeer: InputPeerType,
        readonly offsetId: TLInt,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace stats {
    export class GetMessageStats implements TLFunction<stats.MessageStats> {
      static readonly cons = new TLInt(0xb6e0a3f5);

      static deserialized(_data: ByteStream): GetMessageStats | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetMessageStats.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const dark = (flags.value & 1) !== 0;
        const channel = TLObject.deserialized(_data) as InputChannelType;
        if (!channel) return undefined;
        const msgId = TLInt.deserialized(_data);
        if (!msgId) return undefined;

        return new GetMessageStats(dark, channel, msgId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetMessageStats.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.dark) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.channel);
        _data.push(this.msgId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly dark: boolean | undefined,
        readonly channel: InputChannelType,
        readonly msgId: TLInt
      ) {}
    }
  }

  export namespace chatlists {
    export class ExportChatlistInvite
      implements TLFunction<chatlists.ExportedChatlistInvite>
    {
      static readonly cons = new TLInt(0x8472478e);

      static deserialized(_data: ByteStream): ExportChatlistInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportChatlistInvite.cons))
          return undefined;

        const chatlist = TLObject.deserialized(_data) as InputChatlistType;
        if (!chatlist) return undefined;
        const title = TLString.deserialized(_data);
        if (!title) return undefined;
        const peers = TLVector.deserialized(_data) as TLVector<InputPeerType>;
        if (!peers) return undefined;

        return new ExportChatlistInvite(chatlist, title, peers);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportChatlistInvite.cons];

        _data.push(this.chatlist);
        _data.push(this.title);
        _data.push(this.peers);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly chatlist: InputChatlistType,
        readonly title: TLString,
        readonly peers: TLVector<InputPeerType>
      ) {}
    }
  }

  export namespace chatlists {
    export class DeleteExportedInvite implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x719c5c5e);

      static deserialized(_data: ByteStream): DeleteExportedInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteExportedInvite.cons))
          return undefined;

        const chatlist = TLObject.deserialized(_data) as InputChatlistType;
        if (!chatlist) return undefined;
        const slug = TLString.deserialized(_data);
        if (!slug) return undefined;

        return new DeleteExportedInvite(chatlist, slug);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteExportedInvite.cons];

        _data.push(this.chatlist);
        _data.push(this.slug);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly chatlist: InputChatlistType,
        readonly slug: TLString
      ) {}
    }
  }

  export namespace chatlists {
    export class EditExportedInvite
      implements TLFunction<API.ExportedChatlistInvite>
    {
      static readonly cons = new TLInt(0x653db63d);

      static deserialized(_data: ByteStream): EditExportedInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditExportedInvite.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const chatlist = TLObject.deserialized(_data) as InputChatlistType;
        if (!chatlist) return undefined;
        const slug = TLString.deserialized(_data);
        if (!slug) return undefined;
        let title: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          title = obj;
        }
        let peers: TLVector<InputPeerType> | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLVector.deserialized(_data) as TLVector<InputPeerType>;
          if (!obj) return undefined;
          peers = obj;
        }

        return new EditExportedInvite(chatlist, slug, title, peers);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditExportedInvite.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.chatlist);
        _data.push(this.slug);
        if (this.title) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.title) _data.push(this.title);

        if (this.peers) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.peers) _data.push(this.peers);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly chatlist: InputChatlistType,
        readonly slug: TLString,
        readonly title: TLString | undefined,
        readonly peers: TLVector<InputPeerType> | undefined
      ) {}
    }
  }

  export namespace chatlists {
    export class GetExportedInvites
      implements TLFunction<chatlists.ExportedInvites>
    {
      static readonly cons = new TLInt(0xce03da83);

      static deserialized(_data: ByteStream): GetExportedInvites | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetExportedInvites.cons))
          return undefined;

        const chatlist = TLObject.deserialized(_data) as InputChatlistType;
        if (!chatlist) return undefined;

        return new GetExportedInvites(chatlist);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetExportedInvites.cons];

        _data.push(this.chatlist);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly chatlist: InputChatlistType) {}
    }
  }

  export namespace chatlists {
    export class CheckChatlistInvite
      implements TLFunction<chatlists.ChatlistInviteType>
    {
      static readonly cons = new TLInt(0x41c10fff);

      static deserialized(_data: ByteStream): CheckChatlistInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CheckChatlistInvite.cons))
          return undefined;

        const slug = TLString.deserialized(_data);
        if (!slug) return undefined;

        return new CheckChatlistInvite(slug);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CheckChatlistInvite.cons];

        _data.push(this.slug);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly slug: TLString) {}
    }
  }

  export namespace chatlists {
    export class JoinChatlistInvite implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xa6b1e39a);

      static deserialized(_data: ByteStream): JoinChatlistInvite | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(JoinChatlistInvite.cons))
          return undefined;

        const slug = TLString.deserialized(_data);
        if (!slug) return undefined;
        const peers = TLVector.deserialized(_data) as TLVector<InputPeerType>;
        if (!peers) return undefined;

        return new JoinChatlistInvite(slug, peers);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [JoinChatlistInvite.cons];

        _data.push(this.slug);
        _data.push(this.peers);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly slug: TLString,
        readonly peers: TLVector<InputPeerType>
      ) {}
    }
  }

  export namespace chatlists {
    export class GetChatlistUpdates
      implements TLFunction<chatlists.ChatlistUpdates>
    {
      static readonly cons = new TLInt(0x89419521);

      static deserialized(_data: ByteStream): GetChatlistUpdates | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetChatlistUpdates.cons))
          return undefined;

        const chatlist = TLObject.deserialized(_data) as InputChatlistType;
        if (!chatlist) return undefined;

        return new GetChatlistUpdates(chatlist);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetChatlistUpdates.cons];

        _data.push(this.chatlist);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly chatlist: InputChatlistType) {}
    }
  }

  export namespace chatlists {
    export class JoinChatlistUpdates implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xe089f8f5);

      static deserialized(_data: ByteStream): JoinChatlistUpdates | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(JoinChatlistUpdates.cons))
          return undefined;

        const chatlist = TLObject.deserialized(_data) as InputChatlistType;
        if (!chatlist) return undefined;
        const peers = TLVector.deserialized(_data) as TLVector<InputPeerType>;
        if (!peers) return undefined;

        return new JoinChatlistUpdates(chatlist, peers);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [JoinChatlistUpdates.cons];

        _data.push(this.chatlist);
        _data.push(this.peers);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly chatlist: InputChatlistType,
        readonly peers: TLVector<InputPeerType>
      ) {}
    }
  }

  export namespace chatlists {
    export class HideChatlistUpdates implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x66e486fb);

      static deserialized(_data: ByteStream): HideChatlistUpdates | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(HideChatlistUpdates.cons))
          return undefined;

        const chatlist = TLObject.deserialized(_data) as InputChatlistType;
        if (!chatlist) return undefined;

        return new HideChatlistUpdates(chatlist);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [HideChatlistUpdates.cons];

        _data.push(this.chatlist);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly chatlist: InputChatlistType) {}
    }
  }

  export namespace chatlists {
    export class GetLeaveChatlistSuggestions
      implements TLFunction<TLVector<PeerType>>
    {
      static readonly cons = new TLInt(0xfdbcd714);

      static deserialized(
        _data: ByteStream
      ): GetLeaveChatlistSuggestions | undefined {
        const constructor = TLInt.deserialized(_data);
        if (
          !constructor ||
          !constructor.equals(GetLeaveChatlistSuggestions.cons)
        )
          return undefined;

        const chatlist = TLObject.deserialized(_data) as InputChatlistType;
        if (!chatlist) return undefined;

        return new GetLeaveChatlistSuggestions(chatlist);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetLeaveChatlistSuggestions.cons];

        _data.push(this.chatlist);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly chatlist: InputChatlistType) {}
    }
  }

  export namespace chatlists {
    export class LeaveChatlist implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x74fae13a);

      static deserialized(_data: ByteStream): LeaveChatlist | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(LeaveChatlist.cons))
          return undefined;

        const chatlist = TLObject.deserialized(_data) as InputChatlistType;
        if (!chatlist) return undefined;
        const peers = TLVector.deserialized(_data) as TLVector<InputPeerType>;
        if (!peers) return undefined;

        return new LeaveChatlist(chatlist, peers);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [LeaveChatlist.cons];

        _data.push(this.chatlist);
        _data.push(this.peers);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly chatlist: InputChatlistType,
        readonly peers: TLVector<InputPeerType>
      ) {}
    }
  }

  export namespace stories {
    export class CanSendStory implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xc7dfdfdd);

      static deserialized(_data: ByteStream): CanSendStory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CanSendStory.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new CanSendStory(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CanSendStory.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace stories {
    export class SendStory implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xbcb73644);

      static deserialized(_data: ByteStream): SendStory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendStory.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const pinned = (flags.value & 4) !== 0;
        const noforwards = (flags.value & 16) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const media = TLObject.deserialized(_data) as InputMediaType;
        if (!media) return undefined;
        let mediaAreas: TLVector<MediaAreaType> | undefined;
        if ((flags.value & 32) !== 0) {
          const obj = TLVector.deserialized(_data) as TLVector<MediaAreaType>;
          if (!obj) return undefined;
          mediaAreas = obj;
        }
        let caption: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          caption = obj;
        }
        let entities: TLVector<MessageEntityType> | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLVector.deserialized(
            _data
          ) as TLVector<MessageEntityType>;
          if (!obj) return undefined;
          entities = obj;
        }
        const privacyRules = TLVector.deserialized(
          _data
        ) as TLVector<InputPrivacyRuleType>;
        if (!privacyRules) return undefined;
        const randomId = TLLong.deserialized(_data);
        if (!randomId) return undefined;
        let period: TLInt | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLInt.deserialized(_data);
          if (!obj) return undefined;
          period = obj;
        }

        return new SendStory(
          pinned,
          noforwards,
          peer,
          media,
          mediaAreas,
          caption,
          entities,
          privacyRules,
          randomId,
          period
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendStory.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.pinned) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        if (this.noforwards) {
          flags.set(16);
        } else {
          flags.unset(16);
        }

        _data.push(this.peer);
        _data.push(this.media);
        if (this.mediaAreas) {
          flags.set(32);
        } else {
          flags.unset(32);
        }
        if (this.mediaAreas) _data.push(this.mediaAreas);

        if (this.caption) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.caption) _data.push(this.caption);

        if (this.entities) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.entities) _data.push(this.entities);

        _data.push(this.privacyRules);
        _data.push(this.randomId);
        if (this.period) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.period) _data.push(this.period);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly pinned: boolean | undefined,
        readonly noforwards: boolean | undefined,
        readonly peer: InputPeerType,
        readonly media: InputMediaType,
        readonly mediaAreas: TLVector<MediaAreaType> | undefined,
        readonly caption: TLString | undefined,
        readonly entities: TLVector<MessageEntityType> | undefined,
        readonly privacyRules: TLVector<InputPrivacyRuleType>,
        readonly randomId: TLLong,
        readonly period: TLInt | undefined
      ) {}
    }
  }

  export namespace stories {
    export class EditStory implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0xb583ba46);

      static deserialized(_data: ByteStream): EditStory | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(EditStory.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;
        let media: InputMediaType | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLObject.deserialized(_data) as InputMediaType;
          if (!obj) return undefined;
          media = obj;
        }
        let mediaAreas: TLVector<MediaAreaType> | undefined;
        if ((flags.value & 8) !== 0) {
          const obj = TLVector.deserialized(_data) as TLVector<MediaAreaType>;
          if (!obj) return undefined;
          mediaAreas = obj;
        }
        let caption: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          caption = obj;
        }
        let entities: TLVector<MessageEntityType> | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLVector.deserialized(
            _data
          ) as TLVector<MessageEntityType>;
          if (!obj) return undefined;
          entities = obj;
        }
        let privacyRules: TLVector<InputPrivacyRuleType> | undefined;
        if ((flags.value & 4) !== 0) {
          const obj = TLVector.deserialized(
            _data
          ) as TLVector<InputPrivacyRuleType>;
          if (!obj) return undefined;
          privacyRules = obj;
        }

        return new EditStory(
          peer,
          id,
          media,
          mediaAreas,
          caption,
          entities,
          privacyRules
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [EditStory.cons];

        const flags = new TLFlags();
        _data.push(flags);
        _data.push(this.peer);
        _data.push(this.id);
        if (this.media) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.media) _data.push(this.media);

        if (this.mediaAreas) {
          flags.set(8);
        } else {
          flags.unset(8);
        }
        if (this.mediaAreas) _data.push(this.mediaAreas);

        if (this.caption) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.caption) _data.push(this.caption);

        if (this.entities) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.entities) _data.push(this.entities);

        if (this.privacyRules) {
          flags.set(4);
        } else {
          flags.unset(4);
        }
        if (this.privacyRules) _data.push(this.privacyRules);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly id: TLInt,
        readonly media: InputMediaType | undefined,
        readonly mediaAreas: TLVector<MediaAreaType> | undefined,
        readonly caption: TLString | undefined,
        readonly entities: TLVector<MessageEntityType> | undefined,
        readonly privacyRules: TLVector<InputPrivacyRuleType> | undefined
      ) {}
    }
  }

  export namespace stories {
    export class DeleteStories implements TLFunction<TLVector<TLInt>> {
      static readonly cons = new TLInt(0xae59db5f);

      static deserialized(_data: ByteStream): DeleteStories | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(DeleteStories.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new DeleteStories(peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [DeleteStories.cons];

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly id: TLVector<TLInt>) {}
    }
  }

  export namespace stories {
    export class TogglePinned implements TLFunction<TLVector<TLInt>> {
      static readonly cons = new TLInt(0x9a75a1ef);

      static deserialized(_data: ByteStream): TogglePinned | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TogglePinned.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;
        const pinned = TLObject.deserialized(_data) as BoolType;
        if (!pinned) return undefined;

        return new TogglePinned(peer, id, pinned);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TogglePinned.cons];

        _data.push(this.peer);
        _data.push(this.id);
        _data.push(this.pinned);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly id: TLVector<TLInt>,
        readonly pinned: BoolType
      ) {}
    }
  }

  export namespace stories {
    export class GetAllStories implements TLFunction<stories.AllStoriesType> {
      static readonly cons = new TLInt(0xeeb0d625);

      static deserialized(_data: ByteStream): GetAllStories | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAllStories.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const next = (flags.value & 2) !== 0;
        const hidden = (flags.value & 4) !== 0;
        let state: TLString | undefined;
        if ((flags.value & 1) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          state = obj;
        }

        return new GetAllStories(next, hidden, state);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAllStories.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.next) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        if (this.hidden) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        if (this.state) {
          flags.set(1);
        } else {
          flags.unset(1);
        }
        if (this.state) _data.push(this.state);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly next: boolean | undefined,
        readonly hidden: boolean | undefined,
        readonly state: TLString | undefined
      ) {}
    }
  }

  export namespace stories {
    export class GetPinnedStories implements TLFunction<stories.Stories> {
      static readonly cons = new TLInt(0x5821a5dc);

      static deserialized(_data: ByteStream): GetPinnedStories | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPinnedStories.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetPinnedStories(peer, offsetId, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPinnedStories.cons];

        _data.push(this.peer);
        _data.push(this.offsetId);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly offsetId: TLInt,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace stories {
    export class GetStoriesArchive implements TLFunction<stories.Stories> {
      static readonly cons = new TLInt(0xb4352016);

      static deserialized(_data: ByteStream): GetStoriesArchive | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetStoriesArchive.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const offsetId = TLInt.deserialized(_data);
        if (!offsetId) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetStoriesArchive(peer, offsetId, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetStoriesArchive.cons];

        _data.push(this.peer);
        _data.push(this.offsetId);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly offsetId: TLInt,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace stories {
    export class GetStoriesByID implements TLFunction<stories.Stories> {
      static readonly cons = new TLInt(0x5774ca74);

      static deserialized(_data: ByteStream): GetStoriesByID | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetStoriesByID.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new GetStoriesByID(peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetStoriesByID.cons];

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly id: TLVector<TLInt>) {}
    }
  }

  export namespace stories {
    export class ToggleAllStoriesHidden implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x7c2557c4);

      static deserialized(
        _data: ByteStream
      ): ToggleAllStoriesHidden | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ToggleAllStoriesHidden.cons))
          return undefined;

        const hidden = TLObject.deserialized(_data) as BoolType;
        if (!hidden) return undefined;

        return new ToggleAllStoriesHidden(hidden);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ToggleAllStoriesHidden.cons];

        _data.push(this.hidden);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly hidden: BoolType) {}
    }
  }

  export namespace stories {
    export class ReadStories implements TLFunction<TLVector<TLInt>> {
      static readonly cons = new TLInt(0xa556dac8);

      static deserialized(_data: ByteStream): ReadStories | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ReadStories.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const maxId = TLInt.deserialized(_data);
        if (!maxId) return undefined;

        return new ReadStories(peer, maxId);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ReadStories.cons];

        _data.push(this.peer);
        _data.push(this.maxId);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly maxId: TLInt) {}
    }
  }

  export namespace stories {
    export class IncrementStoryViews implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xb2028afb);

      static deserialized(_data: ByteStream): IncrementStoryViews | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(IncrementStoryViews.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new IncrementStoryViews(peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [IncrementStoryViews.cons];

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly id: TLVector<TLInt>) {}
    }
  }

  export namespace stories {
    export class GetStoryViewsList
      implements TLFunction<stories.StoryViewsList>
    {
      static readonly cons = new TLInt(0x7ed23c57);

      static deserialized(_data: ByteStream): GetStoryViewsList | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetStoryViewsList.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const justContacts = (flags.value & 1) !== 0;
        const reactionsFirst = (flags.value & 4) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        let q: TLString | undefined;
        if ((flags.value & 2) !== 0) {
          const obj = TLString.deserialized(_data);
          if (!obj) return undefined;
          q = obj;
        }
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;
        const offset = TLString.deserialized(_data);
        if (!offset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetStoryViewsList(
          justContacts,
          reactionsFirst,
          peer,
          q,
          id,
          offset,
          limit
        );
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetStoryViewsList.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.justContacts) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.reactionsFirst) {
          flags.set(4);
        } else {
          flags.unset(4);
        }

        _data.push(this.peer);
        if (this.q) {
          flags.set(2);
        } else {
          flags.unset(2);
        }
        if (this.q) _data.push(this.q);

        _data.push(this.id);
        _data.push(this.offset);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly justContacts: boolean | undefined,
        readonly reactionsFirst: boolean | undefined,
        readonly peer: InputPeerType,
        readonly q: TLString | undefined,
        readonly id: TLInt,
        readonly offset: TLString,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace stories {
    export class GetStoriesViews implements TLFunction<stories.StoryViews> {
      static readonly cons = new TLInt(0x28e16cc8);

      static deserialized(_data: ByteStream): GetStoriesViews | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetStoriesViews.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;

        return new GetStoriesViews(peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetStoriesViews.cons];

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly id: TLVector<TLInt>) {}
    }
  }

  export namespace stories {
    export class ExportStoryLink implements TLFunction<API.ExportedStoryLink> {
      static readonly cons = new TLInt(0x7b8def20);

      static deserialized(_data: ByteStream): ExportStoryLink | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ExportStoryLink.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLInt.deserialized(_data);
        if (!id) return undefined;

        return new ExportStoryLink(peer, id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ExportStoryLink.cons];

        _data.push(this.peer);
        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly id: TLInt) {}
    }
  }

  export namespace stories {
    export class Report implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0x1923fa8c);

      static deserialized(_data: ByteStream): Report | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(Report.cons)) return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const id = TLVector.deserialized(_data, TLInt);
        if (!id) return undefined;
        const reason = TLObject.deserialized(_data) as ReportReasonType;
        if (!reason) return undefined;
        const message = TLString.deserialized(_data);
        if (!message) return undefined;

        return new Report(peer, id, reason, message);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [Report.cons];

        _data.push(this.peer);
        _data.push(this.id);
        _data.push(this.reason);
        _data.push(this.message);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly id: TLVector<TLInt>,
        readonly reason: ReportReasonType,
        readonly message: TLString
      ) {}
    }
  }

  export namespace stories {
    export class ActivateStealthMode implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x57bbd166);

      static deserialized(_data: ByteStream): ActivateStealthMode | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ActivateStealthMode.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const past = (flags.value & 1) !== 0;
        const future = (flags.value & 2) !== 0;

        return new ActivateStealthMode(past, future);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ActivateStealthMode.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.past) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        if (this.future) {
          flags.set(2);
        } else {
          flags.unset(2);
        }

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly past: boolean | undefined,
        readonly future: boolean | undefined
      ) {}
    }
  }

  export namespace stories {
    export class SendReaction implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x7fd736b2);

      static deserialized(_data: ByteStream): SendReaction | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(SendReaction.cons))
          return undefined;

        const flags = TLInt.deserialized(_data);
        if (!flags) return undefined;
        const addToRecent = (flags.value & 1) !== 0;
        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const storyId = TLInt.deserialized(_data);
        if (!storyId) return undefined;
        const reaction = TLObject.deserialized(_data) as ReactionType;
        if (!reaction) return undefined;

        return new SendReaction(addToRecent, peer, storyId, reaction);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [SendReaction.cons];

        const flags = new TLFlags();
        _data.push(flags);
        if (this.addToRecent) {
          flags.set(1);
        } else {
          flags.unset(1);
        }

        _data.push(this.peer);
        _data.push(this.storyId);
        _data.push(this.reaction);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly addToRecent: boolean | undefined,
        readonly peer: InputPeerType,
        readonly storyId: TLInt,
        readonly reaction: ReactionType
      ) {}
    }
  }

  export namespace stories {
    export class GetPeerStories implements TLFunction<stories.PeerStories> {
      static readonly cons = new TLInt(0x2c4ada50);

      static deserialized(_data: ByteStream): GetPeerStories | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPeerStories.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new GetPeerStories(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPeerStories.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace stories {
    export class GetAllReadPeerStories implements TLFunction<UpdatesType> {
      static readonly cons = new TLInt(0x9b5ae7f9);

      static deserialized(
        _data: ByteStream
      ): GetAllReadPeerStories | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetAllReadPeerStories.cons))
          return undefined;

        return new GetAllReadPeerStories();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetAllReadPeerStories.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace stories {
    export class GetPeerMaxIDs implements TLFunction<TLVector<TLInt>> {
      static readonly cons = new TLInt(0x535983c3);

      static deserialized(_data: ByteStream): GetPeerMaxIDs | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetPeerMaxIDs.cons))
          return undefined;

        const id = TLVector.deserialized(_data) as TLVector<InputPeerType>;
        if (!id) return undefined;

        return new GetPeerMaxIDs(id);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetPeerMaxIDs.cons];

        _data.push(this.id);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly id: TLVector<InputPeerType>) {}
    }
  }

  export namespace stories {
    export class GetChatsToSend implements TLFunction<messages.ChatsType> {
      static readonly cons = new TLInt(0xa56a8b60);

      static deserialized(_data: ByteStream): GetChatsToSend | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetChatsToSend.cons))
          return undefined;

        return new GetChatsToSend();
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetChatsToSend.cons];

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor() {}
    }
  }

  export namespace stories {
    export class TogglePeerStoriesHidden implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xbd0415c4);

      static deserialized(
        _data: ByteStream
      ): TogglePeerStoriesHidden | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(TogglePeerStoriesHidden.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const hidden = TLObject.deserialized(_data) as BoolType;
        if (!hidden) return undefined;

        return new TogglePeerStoriesHidden(peer, hidden);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [TogglePeerStoriesHidden.cons];

        _data.push(this.peer);
        _data.push(this.hidden);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType, readonly hidden: BoolType) {}
    }
  }

  export namespace stories {
    export class GetBoostsStatus implements TLFunction<stories.BoostsStatus> {
      static readonly cons = new TLInt(0x4c449472);

      static deserialized(_data: ByteStream): GetBoostsStatus | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetBoostsStatus.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new GetBoostsStatus(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetBoostsStatus.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace stories {
    export class GetBoostersList implements TLFunction<stories.BoostersList> {
      static readonly cons = new TLInt(0x337ef980);

      static deserialized(_data: ByteStream): GetBoostersList | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(GetBoostersList.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;
        const offset = TLString.deserialized(_data);
        if (!offset) return undefined;
        const limit = TLInt.deserialized(_data);
        if (!limit) return undefined;

        return new GetBoostersList(peer, offset, limit);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [GetBoostersList.cons];

        _data.push(this.peer);
        _data.push(this.offset);
        _data.push(this.limit);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(
        readonly peer: InputPeerType,
        readonly offset: TLString,
        readonly limit: TLInt
      ) {}
    }
  }

  export namespace stories {
    export class CanApplyBoost
      implements TLFunction<stories.CanApplyBoostResultType>
    {
      static readonly cons = new TLInt(0xdb05c1bd);

      static deserialized(_data: ByteStream): CanApplyBoost | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(CanApplyBoost.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new CanApplyBoost(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [CanApplyBoost.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export namespace stories {
    export class ApplyBoost implements TLFunction<BoolType> {
      static readonly cons = new TLInt(0xf29d7c2b);

      static deserialized(_data: ByteStream): ApplyBoost | undefined {
        const constructor = TLInt.deserialized(_data);
        if (!constructor || !constructor.equals(ApplyBoost.cons))
          return undefined;

        const peer = TLObject.deserialized(_data) as InputPeerType;
        if (!peer) return undefined;

        return new ApplyBoost(peer);
      }

      serialized(): Uint8Array {
        const _data: Array<TLSerializable> = [ApplyBoost.cons];

        _data.push(this.peer);

        return concat(..._data.map((item) => item.serialized()));
      }

      constructor(readonly peer: InputPeerType) {}
    }
  }

  export const constructables = ((): HashMap<TLInt, unknown> => {
    const map = new HashMap<TLInt, unknown>(1699);

    map.put(BoolFalse.cons, BoolFalse);
    map.put(BoolTrue.cons, BoolTrue);
    map.put(True.cons, True);
    map.put(Error.cons, Error);
    map.put(Null.cons, Null);
    map.put(InputPeerEmpty.cons, InputPeerEmpty);
    map.put(InputPeerSelf.cons, InputPeerSelf);
    map.put(InputPeerChat.cons, InputPeerChat);
    map.put(InputPeerUser.cons, InputPeerUser);
    map.put(InputPeerChannel.cons, InputPeerChannel);
    map.put(InputPeerUserFromMessage.cons, InputPeerUserFromMessage);
    map.put(InputPeerChannelFromMessage.cons, InputPeerChannelFromMessage);
    map.put(InputUserEmpty.cons, InputUserEmpty);
    map.put(InputUserSelf.cons, InputUserSelf);
    map.put(InputUser.cons, InputUser);
    map.put(InputUserFromMessage.cons, InputUserFromMessage);
    map.put(InputPhoneContact.cons, InputPhoneContact);
    map.put(InputFile.cons, InputFile);
    map.put(InputFileBig.cons, InputFileBig);
    map.put(InputMediaEmpty.cons, InputMediaEmpty);
    map.put(InputMediaUploadedPhoto.cons, InputMediaUploadedPhoto);
    map.put(InputMediaPhoto.cons, InputMediaPhoto);
    map.put(InputMediaGeoPoint.cons, InputMediaGeoPoint);
    map.put(InputMediaContact.cons, InputMediaContact);
    map.put(InputMediaUploadedDocument.cons, InputMediaUploadedDocument);
    map.put(InputMediaDocument.cons, InputMediaDocument);
    map.put(InputMediaVenue.cons, InputMediaVenue);
    map.put(InputMediaPhotoExternal.cons, InputMediaPhotoExternal);
    map.put(InputMediaDocumentExternal.cons, InputMediaDocumentExternal);
    map.put(InputMediaGame.cons, InputMediaGame);
    map.put(InputMediaInvoice.cons, InputMediaInvoice);
    map.put(InputMediaGeoLive.cons, InputMediaGeoLive);
    map.put(InputMediaPoll.cons, InputMediaPoll);
    map.put(InputMediaDice.cons, InputMediaDice);
    map.put(InputMediaStory.cons, InputMediaStory);
    map.put(InputChatPhotoEmpty.cons, InputChatPhotoEmpty);
    map.put(InputChatUploadedPhoto.cons, InputChatUploadedPhoto);
    map.put(InputChatPhoto.cons, InputChatPhoto);
    map.put(InputGeoPointEmpty.cons, InputGeoPointEmpty);
    map.put(InputGeoPoint.cons, InputGeoPoint);
    map.put(InputPhotoEmpty.cons, InputPhotoEmpty);
    map.put(InputPhoto.cons, InputPhoto);
    map.put(InputFileLocation.cons, InputFileLocation);
    map.put(InputEncryptedFileLocation.cons, InputEncryptedFileLocation);
    map.put(InputDocumentFileLocation.cons, InputDocumentFileLocation);
    map.put(InputSecureFileLocation.cons, InputSecureFileLocation);
    map.put(InputTakeoutFileLocation.cons, InputTakeoutFileLocation);
    map.put(InputPhotoFileLocation.cons, InputPhotoFileLocation);
    map.put(InputPhotoLegacyFileLocation.cons, InputPhotoLegacyFileLocation);
    map.put(InputPeerPhotoFileLocation.cons, InputPeerPhotoFileLocation);
    map.put(InputStickerSetThumb.cons, InputStickerSetThumb);
    map.put(InputGroupCallStream.cons, InputGroupCallStream);
    map.put(PeerUser.cons, PeerUser);
    map.put(PeerChat.cons, PeerChat);
    map.put(PeerChannel.cons, PeerChannel);
    map.put(storage.FileUnknown.cons, storage.FileUnknown);
    map.put(storage.FilePartial.cons, storage.FilePartial);
    map.put(storage.FileJpeg.cons, storage.FileJpeg);
    map.put(storage.FileGif.cons, storage.FileGif);
    map.put(storage.FilePng.cons, storage.FilePng);
    map.put(storage.FilePdf.cons, storage.FilePdf);
    map.put(storage.FileMp3.cons, storage.FileMp3);
    map.put(storage.FileMov.cons, storage.FileMov);
    map.put(storage.FileMp4.cons, storage.FileMp4);
    map.put(storage.FileWebp.cons, storage.FileWebp);
    map.put(UserEmpty.cons, UserEmpty);
    map.put(User.cons, User);
    map.put(UserProfilePhotoEmpty.cons, UserProfilePhotoEmpty);
    map.put(UserProfilePhoto.cons, UserProfilePhoto);
    map.put(UserStatusEmpty.cons, UserStatusEmpty);
    map.put(UserStatusOnline.cons, UserStatusOnline);
    map.put(UserStatusOffline.cons, UserStatusOffline);
    map.put(UserStatusRecently.cons, UserStatusRecently);
    map.put(UserStatusLastWeek.cons, UserStatusLastWeek);
    map.put(UserStatusLastMonth.cons, UserStatusLastMonth);
    map.put(ChatEmpty.cons, ChatEmpty);
    map.put(Chat.cons, Chat);
    map.put(ChatForbidden.cons, ChatForbidden);
    map.put(Channel.cons, Channel);
    map.put(ChannelForbidden.cons, ChannelForbidden);
    map.put(ChatFull.cons, ChatFull);
    map.put(ChannelFull.cons, ChannelFull);
    map.put(ChatParticipant.cons, ChatParticipant);
    map.put(ChatParticipantCreator.cons, ChatParticipantCreator);
    map.put(ChatParticipantAdmin.cons, ChatParticipantAdmin);
    map.put(ChatParticipantsForbidden.cons, ChatParticipantsForbidden);
    map.put(ChatParticipants.cons, ChatParticipants);
    map.put(ChatPhotoEmpty.cons, ChatPhotoEmpty);
    map.put(ChatPhoto.cons, ChatPhoto);
    map.put(MessageEmpty.cons, MessageEmpty);
    map.put(Message.cons, Message);
    map.put(MessageService.cons, MessageService);
    map.put(MessageMediaEmpty.cons, MessageMediaEmpty);
    map.put(MessageMediaPhoto.cons, MessageMediaPhoto);
    map.put(MessageMediaGeo.cons, MessageMediaGeo);
    map.put(MessageMediaContact.cons, MessageMediaContact);
    map.put(MessageMediaUnsupported.cons, MessageMediaUnsupported);
    map.put(MessageMediaDocument.cons, MessageMediaDocument);
    map.put(MessageMediaWebPage.cons, MessageMediaWebPage);
    map.put(MessageMediaVenue.cons, MessageMediaVenue);
    map.put(MessageMediaGame.cons, MessageMediaGame);
    map.put(MessageMediaInvoice.cons, MessageMediaInvoice);
    map.put(MessageMediaGeoLive.cons, MessageMediaGeoLive);
    map.put(MessageMediaPoll.cons, MessageMediaPoll);
    map.put(MessageMediaDice.cons, MessageMediaDice);
    map.put(MessageMediaStory.cons, MessageMediaStory);
    map.put(MessageActionEmpty.cons, MessageActionEmpty);
    map.put(MessageActionChatCreate.cons, MessageActionChatCreate);
    map.put(MessageActionChatEditTitle.cons, MessageActionChatEditTitle);
    map.put(MessageActionChatEditPhoto.cons, MessageActionChatEditPhoto);
    map.put(MessageActionChatDeletePhoto.cons, MessageActionChatDeletePhoto);
    map.put(MessageActionChatAddUser.cons, MessageActionChatAddUser);
    map.put(MessageActionChatDeleteUser.cons, MessageActionChatDeleteUser);
    map.put(MessageActionChatJoinedByLink.cons, MessageActionChatJoinedByLink);
    map.put(MessageActionChannelCreate.cons, MessageActionChannelCreate);
    map.put(MessageActionChatMigrateTo.cons, MessageActionChatMigrateTo);
    map.put(
      MessageActionChannelMigrateFrom.cons,
      MessageActionChannelMigrateFrom
    );
    map.put(MessageActionPinMessage.cons, MessageActionPinMessage);
    map.put(MessageActionHistoryClear.cons, MessageActionHistoryClear);
    map.put(MessageActionGameScore.cons, MessageActionGameScore);
    map.put(MessageActionPaymentSentMe.cons, MessageActionPaymentSentMe);
    map.put(MessageActionPaymentSent.cons, MessageActionPaymentSent);
    map.put(MessageActionPhoneCall.cons, MessageActionPhoneCall);
    map.put(MessageActionScreenshotTaken.cons, MessageActionScreenshotTaken);
    map.put(MessageActionCustomAction.cons, MessageActionCustomAction);
    map.put(MessageActionBotAllowed.cons, MessageActionBotAllowed);
    map.put(
      MessageActionSecureValuesSentMe.cons,
      MessageActionSecureValuesSentMe
    );
    map.put(MessageActionSecureValuesSent.cons, MessageActionSecureValuesSent);
    map.put(MessageActionContactSignUp.cons, MessageActionContactSignUp);
    map.put(
      MessageActionGeoProximityReached.cons,
      MessageActionGeoProximityReached
    );
    map.put(MessageActionGroupCall.cons, MessageActionGroupCall);
    map.put(
      MessageActionInviteToGroupCall.cons,
      MessageActionInviteToGroupCall
    );
    map.put(MessageActionSetMessagesTTL.cons, MessageActionSetMessagesTTL);
    map.put(
      MessageActionGroupCallScheduled.cons,
      MessageActionGroupCallScheduled
    );
    map.put(MessageActionSetChatTheme.cons, MessageActionSetChatTheme);
    map.put(
      MessageActionChatJoinedByRequest.cons,
      MessageActionChatJoinedByRequest
    );
    map.put(
      MessageActionWebViewDataSentMe.cons,
      MessageActionWebViewDataSentMe
    );
    map.put(MessageActionWebViewDataSent.cons, MessageActionWebViewDataSent);
    map.put(MessageActionGiftPremium.cons, MessageActionGiftPremium);
    map.put(MessageActionTopicCreate.cons, MessageActionTopicCreate);
    map.put(MessageActionTopicEdit.cons, MessageActionTopicEdit);
    map.put(
      MessageActionSuggestProfilePhoto.cons,
      MessageActionSuggestProfilePhoto
    );
    map.put(MessageActionRequestedPeer.cons, MessageActionRequestedPeer);
    map.put(MessageActionSetChatWallPaper.cons, MessageActionSetChatWallPaper);
    map.put(
      MessageActionSetSameChatWallPaper.cons,
      MessageActionSetSameChatWallPaper
    );
    map.put(Dialog.cons, Dialog);
    map.put(DialogFolder.cons, DialogFolder);
    map.put(PhotoEmpty.cons, PhotoEmpty);
    map.put(Photo.cons, Photo);
    map.put(PhotoSizeEmpty.cons, PhotoSizeEmpty);
    map.put(PhotoSize.cons, PhotoSize);
    map.put(PhotoCachedSize.cons, PhotoCachedSize);
    map.put(PhotoStrippedSize.cons, PhotoStrippedSize);
    map.put(PhotoSizeProgressive.cons, PhotoSizeProgressive);
    map.put(PhotoPathSize.cons, PhotoPathSize);
    map.put(GeoPointEmpty.cons, GeoPointEmpty);
    map.put(GeoPoint.cons, GeoPoint);
    map.put(auth.SentCode.cons, auth.SentCode);
    map.put(auth.SentCodeSuccess.cons, auth.SentCodeSuccess);
    map.put(auth.Authorization.cons, auth.Authorization);
    map.put(
      auth.AuthorizationSignUpRequired.cons,
      auth.AuthorizationSignUpRequired
    );
    map.put(auth.ExportedAuthorization.cons, auth.ExportedAuthorization);
    map.put(InputNotifyPeer.cons, InputNotifyPeer);
    map.put(InputNotifyUsers.cons, InputNotifyUsers);
    map.put(InputNotifyChats.cons, InputNotifyChats);
    map.put(InputNotifyBroadcasts.cons, InputNotifyBroadcasts);
    map.put(InputNotifyForumTopic.cons, InputNotifyForumTopic);
    map.put(InputPeerNotifySettings.cons, InputPeerNotifySettings);
    map.put(PeerNotifySettings.cons, PeerNotifySettings);
    map.put(PeerSettings.cons, PeerSettings);
    map.put(WallPaper.cons, WallPaper);
    map.put(WallPaperNoFile.cons, WallPaperNoFile);
    map.put(InputReportReasonSpam.cons, InputReportReasonSpam);
    map.put(InputReportReasonViolence.cons, InputReportReasonViolence);
    map.put(InputReportReasonPornography.cons, InputReportReasonPornography);
    map.put(InputReportReasonChildAbuse.cons, InputReportReasonChildAbuse);
    map.put(InputReportReasonOther.cons, InputReportReasonOther);
    map.put(InputReportReasonCopyright.cons, InputReportReasonCopyright);
    map.put(
      InputReportReasonGeoIrrelevant.cons,
      InputReportReasonGeoIrrelevant
    );
    map.put(InputReportReasonFake.cons, InputReportReasonFake);
    map.put(InputReportReasonIllegalDrugs.cons, InputReportReasonIllegalDrugs);
    map.put(
      InputReportReasonPersonalDetails.cons,
      InputReportReasonPersonalDetails
    );
    map.put(UserFull.cons, UserFull);
    map.put(Contact.cons, Contact);
    map.put(ImportedContact.cons, ImportedContact);
    map.put(ContactStatus.cons, ContactStatus);
    map.put(contacts.ContactsNotModified.cons, contacts.ContactsNotModified);
    map.put(contacts.Contacts.cons, contacts.Contacts);
    map.put(contacts.ImportedContacts.cons, contacts.ImportedContacts);
    map.put(contacts.Blocked.cons, contacts.Blocked);
    map.put(contacts.BlockedSlice.cons, contacts.BlockedSlice);
    map.put(messages.Dialogs.cons, messages.Dialogs);
    map.put(messages.DialogsSlice.cons, messages.DialogsSlice);
    map.put(messages.DialogsNotModified.cons, messages.DialogsNotModified);
    map.put(messages.Messages.cons, messages.Messages);
    map.put(messages.MessagesSlice.cons, messages.MessagesSlice);
    map.put(messages.ChannelMessages.cons, messages.ChannelMessages);
    map.put(messages.MessagesNotModified.cons, messages.MessagesNotModified);
    map.put(messages.Chats.cons, messages.Chats);
    map.put(messages.ChatsSlice.cons, messages.ChatsSlice);
    map.put(messages.ChatFull.cons, messages.ChatFull);
    map.put(messages.AffectedHistory.cons, messages.AffectedHistory);
    map.put(InputMessagesFilterEmpty.cons, InputMessagesFilterEmpty);
    map.put(InputMessagesFilterPhotos.cons, InputMessagesFilterPhotos);
    map.put(InputMessagesFilterVideo.cons, InputMessagesFilterVideo);
    map.put(InputMessagesFilterPhotoVideo.cons, InputMessagesFilterPhotoVideo);
    map.put(InputMessagesFilterDocument.cons, InputMessagesFilterDocument);
    map.put(InputMessagesFilterUrl.cons, InputMessagesFilterUrl);
    map.put(InputMessagesFilterGif.cons, InputMessagesFilterGif);
    map.put(InputMessagesFilterVoice.cons, InputMessagesFilterVoice);
    map.put(InputMessagesFilterMusic.cons, InputMessagesFilterMusic);
    map.put(InputMessagesFilterChatPhotos.cons, InputMessagesFilterChatPhotos);
    map.put(InputMessagesFilterPhoneCalls.cons, InputMessagesFilterPhoneCalls);
    map.put(InputMessagesFilterRoundVoice.cons, InputMessagesFilterRoundVoice);
    map.put(InputMessagesFilterRoundVideo.cons, InputMessagesFilterRoundVideo);
    map.put(InputMessagesFilterMyMentions.cons, InputMessagesFilterMyMentions);
    map.put(InputMessagesFilterGeo.cons, InputMessagesFilterGeo);
    map.put(InputMessagesFilterContacts.cons, InputMessagesFilterContacts);
    map.put(InputMessagesFilterPinned.cons, InputMessagesFilterPinned);
    map.put(UpdateNewMessage.cons, UpdateNewMessage);
    map.put(UpdateMessageID.cons, UpdateMessageID);
    map.put(UpdateDeleteMessages.cons, UpdateDeleteMessages);
    map.put(UpdateUserTyping.cons, UpdateUserTyping);
    map.put(UpdateChatUserTyping.cons, UpdateChatUserTyping);
    map.put(UpdateChatParticipants.cons, UpdateChatParticipants);
    map.put(UpdateUserStatus.cons, UpdateUserStatus);
    map.put(UpdateUserName.cons, UpdateUserName);
    map.put(UpdateNewAuthorization.cons, UpdateNewAuthorization);
    map.put(UpdateNewEncryptedMessage.cons, UpdateNewEncryptedMessage);
    map.put(UpdateEncryptedChatTyping.cons, UpdateEncryptedChatTyping);
    map.put(UpdateEncryption.cons, UpdateEncryption);
    map.put(UpdateEncryptedMessagesRead.cons, UpdateEncryptedMessagesRead);
    map.put(UpdateChatParticipantAdd.cons, UpdateChatParticipantAdd);
    map.put(UpdateChatParticipantDelete.cons, UpdateChatParticipantDelete);
    map.put(UpdateDcOptions.cons, UpdateDcOptions);
    map.put(UpdateNotifySettings.cons, UpdateNotifySettings);
    map.put(UpdateServiceNotification.cons, UpdateServiceNotification);
    map.put(UpdatePrivacy.cons, UpdatePrivacy);
    map.put(UpdateUserPhone.cons, UpdateUserPhone);
    map.put(UpdateReadHistoryInbox.cons, UpdateReadHistoryInbox);
    map.put(UpdateReadHistoryOutbox.cons, UpdateReadHistoryOutbox);
    map.put(UpdateWebPage.cons, UpdateWebPage);
    map.put(UpdateReadMessagesContents.cons, UpdateReadMessagesContents);
    map.put(UpdateChannelTooLong.cons, UpdateChannelTooLong);
    map.put(UpdateChannel.cons, UpdateChannel);
    map.put(UpdateNewChannelMessage.cons, UpdateNewChannelMessage);
    map.put(UpdateReadChannelInbox.cons, UpdateReadChannelInbox);
    map.put(UpdateDeleteChannelMessages.cons, UpdateDeleteChannelMessages);
    map.put(UpdateChannelMessageViews.cons, UpdateChannelMessageViews);
    map.put(UpdateChatParticipantAdmin.cons, UpdateChatParticipantAdmin);
    map.put(UpdateNewStickerSet.cons, UpdateNewStickerSet);
    map.put(UpdateStickerSetsOrder.cons, UpdateStickerSetsOrder);
    map.put(UpdateStickerSets.cons, UpdateStickerSets);
    map.put(UpdateSavedGifs.cons, UpdateSavedGifs);
    map.put(UpdateBotInlineQuery.cons, UpdateBotInlineQuery);
    map.put(UpdateBotInlineSend.cons, UpdateBotInlineSend);
    map.put(UpdateEditChannelMessage.cons, UpdateEditChannelMessage);
    map.put(UpdateBotCallbackQuery.cons, UpdateBotCallbackQuery);
    map.put(UpdateEditMessage.cons, UpdateEditMessage);
    map.put(UpdateInlineBotCallbackQuery.cons, UpdateInlineBotCallbackQuery);
    map.put(UpdateReadChannelOutbox.cons, UpdateReadChannelOutbox);
    map.put(UpdateDraftMessage.cons, UpdateDraftMessage);
    map.put(UpdateReadFeaturedStickers.cons, UpdateReadFeaturedStickers);
    map.put(UpdateRecentStickers.cons, UpdateRecentStickers);
    map.put(UpdateConfig.cons, UpdateConfig);
    map.put(UpdatePtsChanged.cons, UpdatePtsChanged);
    map.put(UpdateChannelWebPage.cons, UpdateChannelWebPage);
    map.put(UpdateDialogPinned.cons, UpdateDialogPinned);
    map.put(UpdatePinnedDialogs.cons, UpdatePinnedDialogs);
    map.put(UpdateBotWebhookJSON.cons, UpdateBotWebhookJSON);
    map.put(UpdateBotWebhookJSONQuery.cons, UpdateBotWebhookJSONQuery);
    map.put(UpdateBotShippingQuery.cons, UpdateBotShippingQuery);
    map.put(UpdateBotPrecheckoutQuery.cons, UpdateBotPrecheckoutQuery);
    map.put(UpdatePhoneCall.cons, UpdatePhoneCall);
    map.put(UpdateLangPackTooLong.cons, UpdateLangPackTooLong);
    map.put(UpdateLangPack.cons, UpdateLangPack);
    map.put(UpdateFavedStickers.cons, UpdateFavedStickers);
    map.put(
      UpdateChannelReadMessagesContents.cons,
      UpdateChannelReadMessagesContents
    );
    map.put(UpdateContactsReset.cons, UpdateContactsReset);
    map.put(
      UpdateChannelAvailableMessages.cons,
      UpdateChannelAvailableMessages
    );
    map.put(UpdateDialogUnreadMark.cons, UpdateDialogUnreadMark);
    map.put(UpdateMessagePoll.cons, UpdateMessagePoll);
    map.put(UpdateChatDefaultBannedRights.cons, UpdateChatDefaultBannedRights);
    map.put(UpdateFolderPeers.cons, UpdateFolderPeers);
    map.put(UpdatePeerSettings.cons, UpdatePeerSettings);
    map.put(UpdatePeerLocated.cons, UpdatePeerLocated);
    map.put(UpdateNewScheduledMessage.cons, UpdateNewScheduledMessage);
    map.put(UpdateDeleteScheduledMessages.cons, UpdateDeleteScheduledMessages);
    map.put(UpdateTheme.cons, UpdateTheme);
    map.put(UpdateGeoLiveViewed.cons, UpdateGeoLiveViewed);
    map.put(UpdateLoginToken.cons, UpdateLoginToken);
    map.put(UpdateMessagePollVote.cons, UpdateMessagePollVote);
    map.put(UpdateDialogFilter.cons, UpdateDialogFilter);
    map.put(UpdateDialogFilterOrder.cons, UpdateDialogFilterOrder);
    map.put(UpdateDialogFilters.cons, UpdateDialogFilters);
    map.put(UpdatePhoneCallSignalingData.cons, UpdatePhoneCallSignalingData);
    map.put(UpdateChannelMessageForwards.cons, UpdateChannelMessageForwards);
    map.put(
      UpdateReadChannelDiscussionInbox.cons,
      UpdateReadChannelDiscussionInbox
    );
    map.put(
      UpdateReadChannelDiscussionOutbox.cons,
      UpdateReadChannelDiscussionOutbox
    );
    map.put(UpdatePeerBlocked.cons, UpdatePeerBlocked);
    map.put(UpdateChannelUserTyping.cons, UpdateChannelUserTyping);
    map.put(UpdatePinnedMessages.cons, UpdatePinnedMessages);
    map.put(UpdatePinnedChannelMessages.cons, UpdatePinnedChannelMessages);
    map.put(UpdateChat.cons, UpdateChat);
    map.put(UpdateGroupCallParticipants.cons, UpdateGroupCallParticipants);
    map.put(UpdateGroupCall.cons, UpdateGroupCall);
    map.put(UpdatePeerHistoryTTL.cons, UpdatePeerHistoryTTL);
    map.put(UpdateChatParticipant.cons, UpdateChatParticipant);
    map.put(UpdateChannelParticipant.cons, UpdateChannelParticipant);
    map.put(UpdateBotStopped.cons, UpdateBotStopped);
    map.put(UpdateGroupCallConnection.cons, UpdateGroupCallConnection);
    map.put(UpdateBotCommands.cons, UpdateBotCommands);
    map.put(UpdatePendingJoinRequests.cons, UpdatePendingJoinRequests);
    map.put(UpdateBotChatInviteRequester.cons, UpdateBotChatInviteRequester);
    map.put(UpdateMessageReactions.cons, UpdateMessageReactions);
    map.put(UpdateAttachMenuBots.cons, UpdateAttachMenuBots);
    map.put(UpdateWebViewResultSent.cons, UpdateWebViewResultSent);
    map.put(UpdateBotMenuButton.cons, UpdateBotMenuButton);
    map.put(UpdateSavedRingtones.cons, UpdateSavedRingtones);
    map.put(UpdateTranscribedAudio.cons, UpdateTranscribedAudio);
    map.put(
      UpdateReadFeaturedEmojiStickers.cons,
      UpdateReadFeaturedEmojiStickers
    );
    map.put(UpdateUserEmojiStatus.cons, UpdateUserEmojiStatus);
    map.put(UpdateRecentEmojiStatuses.cons, UpdateRecentEmojiStatuses);
    map.put(UpdateRecentReactions.cons, UpdateRecentReactions);
    map.put(UpdateMoveStickerSetToTop.cons, UpdateMoveStickerSetToTop);
    map.put(UpdateMessageExtendedMedia.cons, UpdateMessageExtendedMedia);
    map.put(UpdateChannelPinnedTopic.cons, UpdateChannelPinnedTopic);
    map.put(UpdateChannelPinnedTopics.cons, UpdateChannelPinnedTopics);
    map.put(UpdateUser.cons, UpdateUser);
    map.put(UpdateAutoSaveSettings.cons, UpdateAutoSaveSettings);
    map.put(
      UpdateGroupInvitePrivacyForbidden.cons,
      UpdateGroupInvitePrivacyForbidden
    );
    map.put(UpdateStory.cons, UpdateStory);
    map.put(UpdateReadStories.cons, UpdateReadStories);
    map.put(UpdateStoryID.cons, UpdateStoryID);
    map.put(UpdateStoriesStealthMode.cons, UpdateStoriesStealthMode);
    map.put(UpdateSentStoryReaction.cons, UpdateSentStoryReaction);
    map.put(updates.State.cons, updates.State);
    map.put(updates.DifferenceEmpty.cons, updates.DifferenceEmpty);
    map.put(updates.Difference.cons, updates.Difference);
    map.put(updates.DifferenceSlice.cons, updates.DifferenceSlice);
    map.put(updates.DifferenceTooLong.cons, updates.DifferenceTooLong);
    map.put(UpdatesTooLong.cons, UpdatesTooLong);
    map.put(UpdateShortMessage.cons, UpdateShortMessage);
    map.put(UpdateShortChatMessage.cons, UpdateShortChatMessage);
    map.put(UpdateShort.cons, UpdateShort);
    map.put(UpdatesCombined.cons, UpdatesCombined);
    map.put(Updates.cons, Updates);
    map.put(UpdateShortSentMessage.cons, UpdateShortSentMessage);
    map.put(photos.Photos.cons, photos.Photos);
    map.put(photos.PhotosSlice.cons, photos.PhotosSlice);
    map.put(photos.Photo.cons, photos.Photo);
    map.put(upload.File.cons, upload.File);
    map.put(upload.FileCdnRedirect.cons, upload.FileCdnRedirect);
    map.put(DcOption.cons, DcOption);
    map.put(Config.cons, Config);
    map.put(NearestDc.cons, NearestDc);
    map.put(help.AppUpdate.cons, help.AppUpdate);
    map.put(help.NoAppUpdate.cons, help.NoAppUpdate);
    map.put(help.InviteText.cons, help.InviteText);
    map.put(EncryptedChatEmpty.cons, EncryptedChatEmpty);
    map.put(EncryptedChatWaiting.cons, EncryptedChatWaiting);
    map.put(EncryptedChatRequested.cons, EncryptedChatRequested);
    map.put(EncryptedChat.cons, EncryptedChat);
    map.put(EncryptedChatDiscarded.cons, EncryptedChatDiscarded);
    map.put(InputEncryptedChat.cons, InputEncryptedChat);
    map.put(EncryptedFileEmpty.cons, EncryptedFileEmpty);
    map.put(EncryptedFile.cons, EncryptedFile);
    map.put(InputEncryptedFileEmpty.cons, InputEncryptedFileEmpty);
    map.put(InputEncryptedFileUploaded.cons, InputEncryptedFileUploaded);
    map.put(InputEncryptedFile.cons, InputEncryptedFile);
    map.put(InputEncryptedFileBigUploaded.cons, InputEncryptedFileBigUploaded);
    map.put(EncryptedMessage.cons, EncryptedMessage);
    map.put(EncryptedMessageService.cons, EncryptedMessageService);
    map.put(messages.DhConfigNotModified.cons, messages.DhConfigNotModified);
    map.put(messages.DhConfig.cons, messages.DhConfig);
    map.put(messages.SentEncryptedMessage.cons, messages.SentEncryptedMessage);
    map.put(messages.SentEncryptedFile.cons, messages.SentEncryptedFile);
    map.put(InputDocumentEmpty.cons, InputDocumentEmpty);
    map.put(InputDocument.cons, InputDocument);
    map.put(DocumentEmpty.cons, DocumentEmpty);
    map.put(Document.cons, Document);
    map.put(help.Support.cons, help.Support);
    map.put(NotifyPeer.cons, NotifyPeer);
    map.put(NotifyUsers.cons, NotifyUsers);
    map.put(NotifyChats.cons, NotifyChats);
    map.put(NotifyBroadcasts.cons, NotifyBroadcasts);
    map.put(NotifyForumTopic.cons, NotifyForumTopic);
    map.put(SendMessageTypingAction.cons, SendMessageTypingAction);
    map.put(SendMessageCancelAction.cons, SendMessageCancelAction);
    map.put(SendMessageRecordVideoAction.cons, SendMessageRecordVideoAction);
    map.put(SendMessageUploadVideoAction.cons, SendMessageUploadVideoAction);
    map.put(SendMessageRecordAudioAction.cons, SendMessageRecordAudioAction);
    map.put(SendMessageUploadAudioAction.cons, SendMessageUploadAudioAction);
    map.put(SendMessageUploadPhotoAction.cons, SendMessageUploadPhotoAction);
    map.put(
      SendMessageUploadDocumentAction.cons,
      SendMessageUploadDocumentAction
    );
    map.put(SendMessageGeoLocationAction.cons, SendMessageGeoLocationAction);
    map.put(
      SendMessageChooseContactAction.cons,
      SendMessageChooseContactAction
    );
    map.put(SendMessageGamePlayAction.cons, SendMessageGamePlayAction);
    map.put(SendMessageRecordRoundAction.cons, SendMessageRecordRoundAction);
    map.put(SendMessageUploadRoundAction.cons, SendMessageUploadRoundAction);
    map.put(SpeakingInGroupCallAction.cons, SpeakingInGroupCallAction);
    map.put(
      SendMessageHistoryImportAction.cons,
      SendMessageHistoryImportAction
    );
    map.put(
      SendMessageChooseStickerAction.cons,
      SendMessageChooseStickerAction
    );
    map.put(SendMessageEmojiInteraction.cons, SendMessageEmojiInteraction);
    map.put(
      SendMessageEmojiInteractionSeen.cons,
      SendMessageEmojiInteractionSeen
    );
    map.put(contacts.Found.cons, contacts.Found);
    map.put(
      InputPrivacyKeyStatusTimestamp.cons,
      InputPrivacyKeyStatusTimestamp
    );
    map.put(InputPrivacyKeyChatInvite.cons, InputPrivacyKeyChatInvite);
    map.put(InputPrivacyKeyPhoneCall.cons, InputPrivacyKeyPhoneCall);
    map.put(InputPrivacyKeyPhoneP2P.cons, InputPrivacyKeyPhoneP2P);
    map.put(InputPrivacyKeyForwards.cons, InputPrivacyKeyForwards);
    map.put(InputPrivacyKeyProfilePhoto.cons, InputPrivacyKeyProfilePhoto);
    map.put(InputPrivacyKeyPhoneNumber.cons, InputPrivacyKeyPhoneNumber);
    map.put(InputPrivacyKeyAddedByPhone.cons, InputPrivacyKeyAddedByPhone);
    map.put(InputPrivacyKeyVoiceMessages.cons, InputPrivacyKeyVoiceMessages);
    map.put(InputPrivacyKeyAbout.cons, InputPrivacyKeyAbout);
    map.put(PrivacyKeyStatusTimestamp.cons, PrivacyKeyStatusTimestamp);
    map.put(PrivacyKeyChatInvite.cons, PrivacyKeyChatInvite);
    map.put(PrivacyKeyPhoneCall.cons, PrivacyKeyPhoneCall);
    map.put(PrivacyKeyPhoneP2P.cons, PrivacyKeyPhoneP2P);
    map.put(PrivacyKeyForwards.cons, PrivacyKeyForwards);
    map.put(PrivacyKeyProfilePhoto.cons, PrivacyKeyProfilePhoto);
    map.put(PrivacyKeyPhoneNumber.cons, PrivacyKeyPhoneNumber);
    map.put(PrivacyKeyAddedByPhone.cons, PrivacyKeyAddedByPhone);
    map.put(PrivacyKeyVoiceMessages.cons, PrivacyKeyVoiceMessages);
    map.put(PrivacyKeyAbout.cons, PrivacyKeyAbout);
    map.put(
      InputPrivacyValueAllowContacts.cons,
      InputPrivacyValueAllowContacts
    );
    map.put(InputPrivacyValueAllowAll.cons, InputPrivacyValueAllowAll);
    map.put(InputPrivacyValueAllowUsers.cons, InputPrivacyValueAllowUsers);
    map.put(
      InputPrivacyValueDisallowContacts.cons,
      InputPrivacyValueDisallowContacts
    );
    map.put(InputPrivacyValueDisallowAll.cons, InputPrivacyValueDisallowAll);
    map.put(
      InputPrivacyValueDisallowUsers.cons,
      InputPrivacyValueDisallowUsers
    );
    map.put(
      InputPrivacyValueAllowChatParticipants.cons,
      InputPrivacyValueAllowChatParticipants
    );
    map.put(
      InputPrivacyValueDisallowChatParticipants.cons,
      InputPrivacyValueDisallowChatParticipants
    );
    map.put(
      InputPrivacyValueAllowCloseFriends.cons,
      InputPrivacyValueAllowCloseFriends
    );
    map.put(PrivacyValueAllowContacts.cons, PrivacyValueAllowContacts);
    map.put(PrivacyValueAllowAll.cons, PrivacyValueAllowAll);
    map.put(PrivacyValueAllowUsers.cons, PrivacyValueAllowUsers);
    map.put(PrivacyValueDisallowContacts.cons, PrivacyValueDisallowContacts);
    map.put(PrivacyValueDisallowAll.cons, PrivacyValueDisallowAll);
    map.put(PrivacyValueDisallowUsers.cons, PrivacyValueDisallowUsers);
    map.put(
      PrivacyValueAllowChatParticipants.cons,
      PrivacyValueAllowChatParticipants
    );
    map.put(
      PrivacyValueDisallowChatParticipants.cons,
      PrivacyValueDisallowChatParticipants
    );
    map.put(PrivacyValueAllowCloseFriends.cons, PrivacyValueAllowCloseFriends);
    map.put(account.PrivacyRules.cons, account.PrivacyRules);
    map.put(AccountDaysTTL.cons, AccountDaysTTL);
    map.put(DocumentAttributeImageSize.cons, DocumentAttributeImageSize);
    map.put(DocumentAttributeAnimated.cons, DocumentAttributeAnimated);
    map.put(DocumentAttributeSticker.cons, DocumentAttributeSticker);
    map.put(DocumentAttributeVideo.cons, DocumentAttributeVideo);
    map.put(DocumentAttributeAudio.cons, DocumentAttributeAudio);
    map.put(DocumentAttributeFilename.cons, DocumentAttributeFilename);
    map.put(DocumentAttributeHasStickers.cons, DocumentAttributeHasStickers);
    map.put(DocumentAttributeCustomEmoji.cons, DocumentAttributeCustomEmoji);
    map.put(messages.StickersNotModified.cons, messages.StickersNotModified);
    map.put(messages.Stickers.cons, messages.Stickers);
    map.put(StickerPack.cons, StickerPack);
    map.put(
      messages.AllStickersNotModified.cons,
      messages.AllStickersNotModified
    );
    map.put(messages.AllStickers.cons, messages.AllStickers);
    map.put(messages.AffectedMessages.cons, messages.AffectedMessages);
    map.put(WebPageEmpty.cons, WebPageEmpty);
    map.put(WebPagePending.cons, WebPagePending);
    map.put(WebPage.cons, WebPage);
    map.put(WebPageNotModified.cons, WebPageNotModified);
    map.put(Authorization.cons, Authorization);
    map.put(account.Authorizations.cons, account.Authorizations);
    map.put(account.Password.cons, account.Password);
    map.put(account.PasswordSettings.cons, account.PasswordSettings);
    map.put(account.PasswordInputSettings.cons, account.PasswordInputSettings);
    map.put(auth.PasswordRecovery.cons, auth.PasswordRecovery);
    map.put(ReceivedNotifyMessage.cons, ReceivedNotifyMessage);
    map.put(ChatInviteExported.cons, ChatInviteExported);
    map.put(ChatInvitePublicJoinRequests.cons, ChatInvitePublicJoinRequests);
    map.put(ChatInviteAlready.cons, ChatInviteAlready);
    map.put(ChatInvite.cons, ChatInvite);
    map.put(ChatInvitePeek.cons, ChatInvitePeek);
    map.put(InputStickerSetEmpty.cons, InputStickerSetEmpty);
    map.put(InputStickerSetID.cons, InputStickerSetID);
    map.put(InputStickerSetShortName.cons, InputStickerSetShortName);
    map.put(InputStickerSetAnimatedEmoji.cons, InputStickerSetAnimatedEmoji);
    map.put(InputStickerSetDice.cons, InputStickerSetDice);
    map.put(
      InputStickerSetAnimatedEmojiAnimations.cons,
      InputStickerSetAnimatedEmojiAnimations
    );
    map.put(InputStickerSetPremiumGifts.cons, InputStickerSetPremiumGifts);
    map.put(
      InputStickerSetEmojiGenericAnimations.cons,
      InputStickerSetEmojiGenericAnimations
    );
    map.put(
      InputStickerSetEmojiDefaultStatuses.cons,
      InputStickerSetEmojiDefaultStatuses
    );
    map.put(
      InputStickerSetEmojiDefaultTopicIcons.cons,
      InputStickerSetEmojiDefaultTopicIcons
    );
    map.put(StickerSet.cons, StickerSet);
    map.put(messages.StickerSet.cons, messages.StickerSet);
    map.put(
      messages.StickerSetNotModified.cons,
      messages.StickerSetNotModified
    );
    map.put(BotCommand.cons, BotCommand);
    map.put(BotInfo.cons, BotInfo);
    map.put(KeyboardButton.cons, KeyboardButton);
    map.put(KeyboardButtonUrl.cons, KeyboardButtonUrl);
    map.put(KeyboardButtonCallback.cons, KeyboardButtonCallback);
    map.put(KeyboardButtonRequestPhone.cons, KeyboardButtonRequestPhone);
    map.put(
      KeyboardButtonRequestGeoLocation.cons,
      KeyboardButtonRequestGeoLocation
    );
    map.put(KeyboardButtonSwitchInline.cons, KeyboardButtonSwitchInline);
    map.put(KeyboardButtonGame.cons, KeyboardButtonGame);
    map.put(KeyboardButtonBuy.cons, KeyboardButtonBuy);
    map.put(KeyboardButtonUrlAuth.cons, KeyboardButtonUrlAuth);
    map.put(InputKeyboardButtonUrlAuth.cons, InputKeyboardButtonUrlAuth);
    map.put(KeyboardButtonRequestPoll.cons, KeyboardButtonRequestPoll);
    map.put(
      InputKeyboardButtonUserProfile.cons,
      InputKeyboardButtonUserProfile
    );
    map.put(KeyboardButtonUserProfile.cons, KeyboardButtonUserProfile);
    map.put(KeyboardButtonWebView.cons, KeyboardButtonWebView);
    map.put(KeyboardButtonSimpleWebView.cons, KeyboardButtonSimpleWebView);
    map.put(KeyboardButtonRequestPeer.cons, KeyboardButtonRequestPeer);
    map.put(KeyboardButtonRow.cons, KeyboardButtonRow);
    map.put(ReplyKeyboardHide.cons, ReplyKeyboardHide);
    map.put(ReplyKeyboardForceReply.cons, ReplyKeyboardForceReply);
    map.put(ReplyKeyboardMarkup.cons, ReplyKeyboardMarkup);
    map.put(ReplyInlineMarkup.cons, ReplyInlineMarkup);
    map.put(MessageEntityUnknown.cons, MessageEntityUnknown);
    map.put(MessageEntityMention.cons, MessageEntityMention);
    map.put(MessageEntityHashtag.cons, MessageEntityHashtag);
    map.put(MessageEntityBotCommand.cons, MessageEntityBotCommand);
    map.put(MessageEntityUrl.cons, MessageEntityUrl);
    map.put(MessageEntityEmail.cons, MessageEntityEmail);
    map.put(MessageEntityBold.cons, MessageEntityBold);
    map.put(MessageEntityItalic.cons, MessageEntityItalic);
    map.put(MessageEntityCode.cons, MessageEntityCode);
    map.put(MessageEntityPre.cons, MessageEntityPre);
    map.put(MessageEntityTextUrl.cons, MessageEntityTextUrl);
    map.put(MessageEntityMentionName.cons, MessageEntityMentionName);
    map.put(InputMessageEntityMentionName.cons, InputMessageEntityMentionName);
    map.put(MessageEntityPhone.cons, MessageEntityPhone);
    map.put(MessageEntityCashtag.cons, MessageEntityCashtag);
    map.put(MessageEntityUnderline.cons, MessageEntityUnderline);
    map.put(MessageEntityStrike.cons, MessageEntityStrike);
    map.put(MessageEntityBlockquote.cons, MessageEntityBlockquote);
    map.put(MessageEntityBankCard.cons, MessageEntityBankCard);
    map.put(MessageEntitySpoiler.cons, MessageEntitySpoiler);
    map.put(MessageEntityCustomEmoji.cons, MessageEntityCustomEmoji);
    map.put(InputChannelEmpty.cons, InputChannelEmpty);
    map.put(InputChannel.cons, InputChannel);
    map.put(InputChannelFromMessage.cons, InputChannelFromMessage);
    map.put(contacts.ResolvedPeer.cons, contacts.ResolvedPeer);
    map.put(MessageRange.cons, MessageRange);
    map.put(
      updates.ChannelDifferenceEmpty.cons,
      updates.ChannelDifferenceEmpty
    );
    map.put(
      updates.ChannelDifferenceTooLong.cons,
      updates.ChannelDifferenceTooLong
    );
    map.put(updates.ChannelDifference.cons, updates.ChannelDifference);
    map.put(ChannelMessagesFilterEmpty.cons, ChannelMessagesFilterEmpty);
    map.put(ChannelMessagesFilter.cons, ChannelMessagesFilter);
    map.put(ChannelParticipant.cons, ChannelParticipant);
    map.put(ChannelParticipantSelf.cons, ChannelParticipantSelf);
    map.put(ChannelParticipantCreator.cons, ChannelParticipantCreator);
    map.put(ChannelParticipantAdmin.cons, ChannelParticipantAdmin);
    map.put(ChannelParticipantBanned.cons, ChannelParticipantBanned);
    map.put(ChannelParticipantLeft.cons, ChannelParticipantLeft);
    map.put(ChannelParticipantsRecent.cons, ChannelParticipantsRecent);
    map.put(ChannelParticipantsAdmins.cons, ChannelParticipantsAdmins);
    map.put(ChannelParticipantsKicked.cons, ChannelParticipantsKicked);
    map.put(ChannelParticipantsBots.cons, ChannelParticipantsBots);
    map.put(ChannelParticipantsBanned.cons, ChannelParticipantsBanned);
    map.put(ChannelParticipantsSearch.cons, ChannelParticipantsSearch);
    map.put(ChannelParticipantsContacts.cons, ChannelParticipantsContacts);
    map.put(ChannelParticipantsMentions.cons, ChannelParticipantsMentions);
    map.put(channels.ChannelParticipants.cons, channels.ChannelParticipants);
    map.put(
      channels.ChannelParticipantsNotModified.cons,
      channels.ChannelParticipantsNotModified
    );
    map.put(channels.ChannelParticipant.cons, channels.ChannelParticipant);
    map.put(help.TermsOfService.cons, help.TermsOfService);
    map.put(messages.SavedGifsNotModified.cons, messages.SavedGifsNotModified);
    map.put(messages.SavedGifs.cons, messages.SavedGifs);
    map.put(
      InputBotInlineMessageMediaAuto.cons,
      InputBotInlineMessageMediaAuto
    );
    map.put(InputBotInlineMessageText.cons, InputBotInlineMessageText);
    map.put(InputBotInlineMessageMediaGeo.cons, InputBotInlineMessageMediaGeo);
    map.put(
      InputBotInlineMessageMediaVenue.cons,
      InputBotInlineMessageMediaVenue
    );
    map.put(
      InputBotInlineMessageMediaContact.cons,
      InputBotInlineMessageMediaContact
    );
    map.put(InputBotInlineMessageGame.cons, InputBotInlineMessageGame);
    map.put(
      InputBotInlineMessageMediaInvoice.cons,
      InputBotInlineMessageMediaInvoice
    );
    map.put(InputBotInlineResult.cons, InputBotInlineResult);
    map.put(InputBotInlineResultPhoto.cons, InputBotInlineResultPhoto);
    map.put(InputBotInlineResultDocument.cons, InputBotInlineResultDocument);
    map.put(InputBotInlineResultGame.cons, InputBotInlineResultGame);
    map.put(BotInlineMessageMediaAuto.cons, BotInlineMessageMediaAuto);
    map.put(BotInlineMessageText.cons, BotInlineMessageText);
    map.put(BotInlineMessageMediaGeo.cons, BotInlineMessageMediaGeo);
    map.put(BotInlineMessageMediaVenue.cons, BotInlineMessageMediaVenue);
    map.put(BotInlineMessageMediaContact.cons, BotInlineMessageMediaContact);
    map.put(BotInlineMessageMediaInvoice.cons, BotInlineMessageMediaInvoice);
    map.put(BotInlineResult.cons, BotInlineResult);
    map.put(BotInlineMediaResult.cons, BotInlineMediaResult);
    map.put(messages.BotResults.cons, messages.BotResults);
    map.put(ExportedMessageLink.cons, ExportedMessageLink);
    map.put(MessageFwdHeader.cons, MessageFwdHeader);
    map.put(auth.CodeTypeSms.cons, auth.CodeTypeSms);
    map.put(auth.CodeTypeCall.cons, auth.CodeTypeCall);
    map.put(auth.CodeTypeFlashCall.cons, auth.CodeTypeFlashCall);
    map.put(auth.CodeTypeMissedCall.cons, auth.CodeTypeMissedCall);
    map.put(auth.CodeTypeFragmentSms.cons, auth.CodeTypeFragmentSms);
    map.put(auth.SentCodeTypeApp.cons, auth.SentCodeTypeApp);
    map.put(auth.SentCodeTypeSms.cons, auth.SentCodeTypeSms);
    map.put(auth.SentCodeTypeCall.cons, auth.SentCodeTypeCall);
    map.put(auth.SentCodeTypeFlashCall.cons, auth.SentCodeTypeFlashCall);
    map.put(auth.SentCodeTypeMissedCall.cons, auth.SentCodeTypeMissedCall);
    map.put(auth.SentCodeTypeEmailCode.cons, auth.SentCodeTypeEmailCode);
    map.put(
      auth.SentCodeTypeSetUpEmailRequired.cons,
      auth.SentCodeTypeSetUpEmailRequired
    );
    map.put(auth.SentCodeTypeFragmentSms.cons, auth.SentCodeTypeFragmentSms);
    map.put(auth.SentCodeTypeFirebaseSms.cons, auth.SentCodeTypeFirebaseSms);
    map.put(messages.BotCallbackAnswer.cons, messages.BotCallbackAnswer);
    map.put(messages.MessageEditData.cons, messages.MessageEditData);
    map.put(InputBotInlineMessageID.cons, InputBotInlineMessageID);
    map.put(InputBotInlineMessageID64.cons, InputBotInlineMessageID64);
    map.put(InlineBotSwitchPM.cons, InlineBotSwitchPM);
    map.put(messages.PeerDialogs.cons, messages.PeerDialogs);
    map.put(TopPeer.cons, TopPeer);
    map.put(TopPeerCategoryBotsPM.cons, TopPeerCategoryBotsPM);
    map.put(TopPeerCategoryBotsInline.cons, TopPeerCategoryBotsInline);
    map.put(TopPeerCategoryCorrespondents.cons, TopPeerCategoryCorrespondents);
    map.put(TopPeerCategoryGroups.cons, TopPeerCategoryGroups);
    map.put(TopPeerCategoryChannels.cons, TopPeerCategoryChannels);
    map.put(TopPeerCategoryPhoneCalls.cons, TopPeerCategoryPhoneCalls);
    map.put(TopPeerCategoryForwardUsers.cons, TopPeerCategoryForwardUsers);
    map.put(TopPeerCategoryForwardChats.cons, TopPeerCategoryForwardChats);
    map.put(TopPeerCategoryPeers.cons, TopPeerCategoryPeers);
    map.put(contacts.TopPeersNotModified.cons, contacts.TopPeersNotModified);
    map.put(contacts.TopPeers.cons, contacts.TopPeers);
    map.put(contacts.TopPeersDisabled.cons, contacts.TopPeersDisabled);
    map.put(DraftMessageEmpty.cons, DraftMessageEmpty);
    map.put(DraftMessage.cons, DraftMessage);
    map.put(
      messages.FeaturedStickersNotModified.cons,
      messages.FeaturedStickersNotModified
    );
    map.put(messages.FeaturedStickers.cons, messages.FeaturedStickers);
    map.put(
      messages.RecentStickersNotModified.cons,
      messages.RecentStickersNotModified
    );
    map.put(messages.RecentStickers.cons, messages.RecentStickers);
    map.put(messages.ArchivedStickers.cons, messages.ArchivedStickers);
    map.put(
      messages.StickerSetInstallResultSuccess.cons,
      messages.StickerSetInstallResultSuccess
    );
    map.put(
      messages.StickerSetInstallResultArchive.cons,
      messages.StickerSetInstallResultArchive
    );
    map.put(StickerSetCovered.cons, StickerSetCovered);
    map.put(StickerSetMultiCovered.cons, StickerSetMultiCovered);
    map.put(StickerSetFullCovered.cons, StickerSetFullCovered);
    map.put(StickerSetNoCovered.cons, StickerSetNoCovered);
    map.put(MaskCoords.cons, MaskCoords);
    map.put(InputStickeredMediaPhoto.cons, InputStickeredMediaPhoto);
    map.put(InputStickeredMediaDocument.cons, InputStickeredMediaDocument);
    map.put(Game.cons, Game);
    map.put(InputGameID.cons, InputGameID);
    map.put(InputGameShortName.cons, InputGameShortName);
    map.put(HighScore.cons, HighScore);
    map.put(messages.HighScores.cons, messages.HighScores);
    map.put(TextEmpty.cons, TextEmpty);
    map.put(TextPlain.cons, TextPlain);
    map.put(TextBold.cons, TextBold);
    map.put(TextItalic.cons, TextItalic);
    map.put(TextUnderline.cons, TextUnderline);
    map.put(TextStrike.cons, TextStrike);
    map.put(TextFixed.cons, TextFixed);
    map.put(TextUrl.cons, TextUrl);
    map.put(TextEmail.cons, TextEmail);
    map.put(TextConcat.cons, TextConcat);
    map.put(TextSubscript.cons, TextSubscript);
    map.put(TextSuperscript.cons, TextSuperscript);
    map.put(TextMarked.cons, TextMarked);
    map.put(TextPhone.cons, TextPhone);
    map.put(TextImage.cons, TextImage);
    map.put(TextAnchor.cons, TextAnchor);
    map.put(PageBlockUnsupported.cons, PageBlockUnsupported);
    map.put(PageBlockTitle.cons, PageBlockTitle);
    map.put(PageBlockSubtitle.cons, PageBlockSubtitle);
    map.put(PageBlockAuthorDate.cons, PageBlockAuthorDate);
    map.put(PageBlockHeader.cons, PageBlockHeader);
    map.put(PageBlockSubheader.cons, PageBlockSubheader);
    map.put(PageBlockParagraph.cons, PageBlockParagraph);
    map.put(PageBlockPreformatted.cons, PageBlockPreformatted);
    map.put(PageBlockFooter.cons, PageBlockFooter);
    map.put(PageBlockDivider.cons, PageBlockDivider);
    map.put(PageBlockAnchor.cons, PageBlockAnchor);
    map.put(PageBlockList.cons, PageBlockList);
    map.put(PageBlockBlockquote.cons, PageBlockBlockquote);
    map.put(PageBlockPullquote.cons, PageBlockPullquote);
    map.put(PageBlockPhoto.cons, PageBlockPhoto);
    map.put(PageBlockVideo.cons, PageBlockVideo);
    map.put(PageBlockCover.cons, PageBlockCover);
    map.put(PageBlockEmbed.cons, PageBlockEmbed);
    map.put(PageBlockEmbedPost.cons, PageBlockEmbedPost);
    map.put(PageBlockCollage.cons, PageBlockCollage);
    map.put(PageBlockSlideshow.cons, PageBlockSlideshow);
    map.put(PageBlockChannel.cons, PageBlockChannel);
    map.put(PageBlockAudio.cons, PageBlockAudio);
    map.put(PageBlockKicker.cons, PageBlockKicker);
    map.put(PageBlockTable.cons, PageBlockTable);
    map.put(PageBlockOrderedList.cons, PageBlockOrderedList);
    map.put(PageBlockDetails.cons, PageBlockDetails);
    map.put(PageBlockRelatedArticles.cons, PageBlockRelatedArticles);
    map.put(PageBlockMap.cons, PageBlockMap);
    map.put(PhoneCallDiscardReasonMissed.cons, PhoneCallDiscardReasonMissed);
    map.put(
      PhoneCallDiscardReasonDisconnect.cons,
      PhoneCallDiscardReasonDisconnect
    );
    map.put(PhoneCallDiscardReasonHangup.cons, PhoneCallDiscardReasonHangup);
    map.put(PhoneCallDiscardReasonBusy.cons, PhoneCallDiscardReasonBusy);
    map.put(DataJSON.cons, DataJSON);
    map.put(LabeledPrice.cons, LabeledPrice);
    map.put(Invoice.cons, Invoice);
    map.put(PaymentCharge.cons, PaymentCharge);
    map.put(PostAddress.cons, PostAddress);
    map.put(PaymentRequestedInfo.cons, PaymentRequestedInfo);
    map.put(PaymentSavedCredentialsCard.cons, PaymentSavedCredentialsCard);
    map.put(WebDocument.cons, WebDocument);
    map.put(WebDocumentNoProxy.cons, WebDocumentNoProxy);
    map.put(InputWebDocument.cons, InputWebDocument);
    map.put(InputWebFileLocation.cons, InputWebFileLocation);
    map.put(InputWebFileGeoPointLocation.cons, InputWebFileGeoPointLocation);
    map.put(
      InputWebFileAudioAlbumThumbLocation.cons,
      InputWebFileAudioAlbumThumbLocation
    );
    map.put(upload.WebFile.cons, upload.WebFile);
    map.put(payments.PaymentForm.cons, payments.PaymentForm);
    map.put(
      payments.ValidatedRequestedInfo.cons,
      payments.ValidatedRequestedInfo
    );
    map.put(payments.PaymentResult.cons, payments.PaymentResult);
    map.put(
      payments.PaymentVerificationNeeded.cons,
      payments.PaymentVerificationNeeded
    );
    map.put(payments.PaymentReceipt.cons, payments.PaymentReceipt);
    map.put(payments.SavedInfo.cons, payments.SavedInfo);
    map.put(InputPaymentCredentialsSaved.cons, InputPaymentCredentialsSaved);
    map.put(InputPaymentCredentials.cons, InputPaymentCredentials);
    map.put(
      InputPaymentCredentialsApplePay.cons,
      InputPaymentCredentialsApplePay
    );
    map.put(
      InputPaymentCredentialsGooglePay.cons,
      InputPaymentCredentialsGooglePay
    );
    map.put(account.TmpPassword.cons, account.TmpPassword);
    map.put(ShippingOption.cons, ShippingOption);
    map.put(InputStickerSetItem.cons, InputStickerSetItem);
    map.put(InputPhoneCall.cons, InputPhoneCall);
    map.put(PhoneCallEmpty.cons, PhoneCallEmpty);
    map.put(PhoneCallWaiting.cons, PhoneCallWaiting);
    map.put(PhoneCallRequested.cons, PhoneCallRequested);
    map.put(PhoneCallAccepted.cons, PhoneCallAccepted);
    map.put(PhoneCall.cons, PhoneCall);
    map.put(PhoneCallDiscarded.cons, PhoneCallDiscarded);
    map.put(PhoneConnection.cons, PhoneConnection);
    map.put(PhoneConnectionWebrtc.cons, PhoneConnectionWebrtc);
    map.put(PhoneCallProtocol.cons, PhoneCallProtocol);
    map.put(phone.PhoneCall.cons, phone.PhoneCall);
    map.put(upload.CdnFileReuploadNeeded.cons, upload.CdnFileReuploadNeeded);
    map.put(upload.CdnFile.cons, upload.CdnFile);
    map.put(CdnPublicKey.cons, CdnPublicKey);
    map.put(CdnConfig.cons, CdnConfig);
    map.put(LangPackString.cons, LangPackString);
    map.put(LangPackStringPluralized.cons, LangPackStringPluralized);
    map.put(LangPackStringDeleted.cons, LangPackStringDeleted);
    map.put(LangPackDifference.cons, LangPackDifference);
    map.put(LangPackLanguage.cons, LangPackLanguage);
    map.put(
      ChannelAdminLogEventActionChangeTitle.cons,
      ChannelAdminLogEventActionChangeTitle
    );
    map.put(
      ChannelAdminLogEventActionChangeAbout.cons,
      ChannelAdminLogEventActionChangeAbout
    );
    map.put(
      ChannelAdminLogEventActionChangeUsername.cons,
      ChannelAdminLogEventActionChangeUsername
    );
    map.put(
      ChannelAdminLogEventActionChangePhoto.cons,
      ChannelAdminLogEventActionChangePhoto
    );
    map.put(
      ChannelAdminLogEventActionToggleInvites.cons,
      ChannelAdminLogEventActionToggleInvites
    );
    map.put(
      ChannelAdminLogEventActionToggleSignatures.cons,
      ChannelAdminLogEventActionToggleSignatures
    );
    map.put(
      ChannelAdminLogEventActionUpdatePinned.cons,
      ChannelAdminLogEventActionUpdatePinned
    );
    map.put(
      ChannelAdminLogEventActionEditMessage.cons,
      ChannelAdminLogEventActionEditMessage
    );
    map.put(
      ChannelAdminLogEventActionDeleteMessage.cons,
      ChannelAdminLogEventActionDeleteMessage
    );
    map.put(
      ChannelAdminLogEventActionParticipantJoin.cons,
      ChannelAdminLogEventActionParticipantJoin
    );
    map.put(
      ChannelAdminLogEventActionParticipantLeave.cons,
      ChannelAdminLogEventActionParticipantLeave
    );
    map.put(
      ChannelAdminLogEventActionParticipantInvite.cons,
      ChannelAdminLogEventActionParticipantInvite
    );
    map.put(
      ChannelAdminLogEventActionParticipantToggleBan.cons,
      ChannelAdminLogEventActionParticipantToggleBan
    );
    map.put(
      ChannelAdminLogEventActionParticipantToggleAdmin.cons,
      ChannelAdminLogEventActionParticipantToggleAdmin
    );
    map.put(
      ChannelAdminLogEventActionChangeStickerSet.cons,
      ChannelAdminLogEventActionChangeStickerSet
    );
    map.put(
      ChannelAdminLogEventActionTogglePreHistoryHidden.cons,
      ChannelAdminLogEventActionTogglePreHistoryHidden
    );
    map.put(
      ChannelAdminLogEventActionDefaultBannedRights.cons,
      ChannelAdminLogEventActionDefaultBannedRights
    );
    map.put(
      ChannelAdminLogEventActionStopPoll.cons,
      ChannelAdminLogEventActionStopPoll
    );
    map.put(
      ChannelAdminLogEventActionChangeLinkedChat.cons,
      ChannelAdminLogEventActionChangeLinkedChat
    );
    map.put(
      ChannelAdminLogEventActionChangeLocation.cons,
      ChannelAdminLogEventActionChangeLocation
    );
    map.put(
      ChannelAdminLogEventActionToggleSlowMode.cons,
      ChannelAdminLogEventActionToggleSlowMode
    );
    map.put(
      ChannelAdminLogEventActionStartGroupCall.cons,
      ChannelAdminLogEventActionStartGroupCall
    );
    map.put(
      ChannelAdminLogEventActionDiscardGroupCall.cons,
      ChannelAdminLogEventActionDiscardGroupCall
    );
    map.put(
      ChannelAdminLogEventActionParticipantMute.cons,
      ChannelAdminLogEventActionParticipantMute
    );
    map.put(
      ChannelAdminLogEventActionParticipantUnmute.cons,
      ChannelAdminLogEventActionParticipantUnmute
    );
    map.put(
      ChannelAdminLogEventActionToggleGroupCallSetting.cons,
      ChannelAdminLogEventActionToggleGroupCallSetting
    );
    map.put(
      ChannelAdminLogEventActionParticipantJoinByInvite.cons,
      ChannelAdminLogEventActionParticipantJoinByInvite
    );
    map.put(
      ChannelAdminLogEventActionExportedInviteDelete.cons,
      ChannelAdminLogEventActionExportedInviteDelete
    );
    map.put(
      ChannelAdminLogEventActionExportedInviteRevoke.cons,
      ChannelAdminLogEventActionExportedInviteRevoke
    );
    map.put(
      ChannelAdminLogEventActionExportedInviteEdit.cons,
      ChannelAdminLogEventActionExportedInviteEdit
    );
    map.put(
      ChannelAdminLogEventActionParticipantVolume.cons,
      ChannelAdminLogEventActionParticipantVolume
    );
    map.put(
      ChannelAdminLogEventActionChangeHistoryTTL.cons,
      ChannelAdminLogEventActionChangeHistoryTTL
    );
    map.put(
      ChannelAdminLogEventActionParticipantJoinByRequest.cons,
      ChannelAdminLogEventActionParticipantJoinByRequest
    );
    map.put(
      ChannelAdminLogEventActionToggleNoForwards.cons,
      ChannelAdminLogEventActionToggleNoForwards
    );
    map.put(
      ChannelAdminLogEventActionSendMessage.cons,
      ChannelAdminLogEventActionSendMessage
    );
    map.put(
      ChannelAdminLogEventActionChangeAvailableReactions.cons,
      ChannelAdminLogEventActionChangeAvailableReactions
    );
    map.put(
      ChannelAdminLogEventActionChangeUsernames.cons,
      ChannelAdminLogEventActionChangeUsernames
    );
    map.put(
      ChannelAdminLogEventActionToggleForum.cons,
      ChannelAdminLogEventActionToggleForum
    );
    map.put(
      ChannelAdminLogEventActionCreateTopic.cons,
      ChannelAdminLogEventActionCreateTopic
    );
    map.put(
      ChannelAdminLogEventActionEditTopic.cons,
      ChannelAdminLogEventActionEditTopic
    );
    map.put(
      ChannelAdminLogEventActionDeleteTopic.cons,
      ChannelAdminLogEventActionDeleteTopic
    );
    map.put(
      ChannelAdminLogEventActionPinTopic.cons,
      ChannelAdminLogEventActionPinTopic
    );
    map.put(
      ChannelAdminLogEventActionToggleAntiSpam.cons,
      ChannelAdminLogEventActionToggleAntiSpam
    );
    map.put(ChannelAdminLogEvent.cons, ChannelAdminLogEvent);
    map.put(channels.AdminLogResults.cons, channels.AdminLogResults);
    map.put(ChannelAdminLogEventsFilter.cons, ChannelAdminLogEventsFilter);
    map.put(PopularContact.cons, PopularContact);
    map.put(
      messages.FavedStickersNotModified.cons,
      messages.FavedStickersNotModified
    );
    map.put(messages.FavedStickers.cons, messages.FavedStickers);
    map.put(RecentMeUrlUnknown.cons, RecentMeUrlUnknown);
    map.put(RecentMeUrlUser.cons, RecentMeUrlUser);
    map.put(RecentMeUrlChat.cons, RecentMeUrlChat);
    map.put(RecentMeUrlChatInvite.cons, RecentMeUrlChatInvite);
    map.put(RecentMeUrlStickerSet.cons, RecentMeUrlStickerSet);
    map.put(help.RecentMeUrls.cons, help.RecentMeUrls);
    map.put(InputSingleMedia.cons, InputSingleMedia);
    map.put(WebAuthorization.cons, WebAuthorization);
    map.put(account.WebAuthorizations.cons, account.WebAuthorizations);
    map.put(InputMessageID.cons, InputMessageID);
    map.put(InputMessageReplyTo.cons, InputMessageReplyTo);
    map.put(InputMessagePinned.cons, InputMessagePinned);
    map.put(InputMessageCallbackQuery.cons, InputMessageCallbackQuery);
    map.put(InputDialogPeer.cons, InputDialogPeer);
    map.put(InputDialogPeerFolder.cons, InputDialogPeerFolder);
    map.put(DialogPeer.cons, DialogPeer);
    map.put(DialogPeerFolder.cons, DialogPeerFolder);
    map.put(
      messages.FoundStickerSetsNotModified.cons,
      messages.FoundStickerSetsNotModified
    );
    map.put(messages.FoundStickerSets.cons, messages.FoundStickerSets);
    map.put(FileHash.cons, FileHash);
    map.put(InputClientProxy.cons, InputClientProxy);
    map.put(
      help.TermsOfServiceUpdateEmpty.cons,
      help.TermsOfServiceUpdateEmpty
    );
    map.put(help.TermsOfServiceUpdate.cons, help.TermsOfServiceUpdate);
    map.put(InputSecureFileUploaded.cons, InputSecureFileUploaded);
    map.put(InputSecureFile.cons, InputSecureFile);
    map.put(SecureFileEmpty.cons, SecureFileEmpty);
    map.put(SecureFile.cons, SecureFile);
    map.put(SecureData.cons, SecureData);
    map.put(SecurePlainPhone.cons, SecurePlainPhone);
    map.put(SecurePlainEmail.cons, SecurePlainEmail);
    map.put(
      SecureValueTypePersonalDetails.cons,
      SecureValueTypePersonalDetails
    );
    map.put(SecureValueTypePassport.cons, SecureValueTypePassport);
    map.put(SecureValueTypeDriverLicense.cons, SecureValueTypeDriverLicense);
    map.put(SecureValueTypeIdentityCard.cons, SecureValueTypeIdentityCard);
    map.put(
      SecureValueTypeInternalPassport.cons,
      SecureValueTypeInternalPassport
    );
    map.put(SecureValueTypeAddress.cons, SecureValueTypeAddress);
    map.put(SecureValueTypeUtilityBill.cons, SecureValueTypeUtilityBill);
    map.put(SecureValueTypeBankStatement.cons, SecureValueTypeBankStatement);
    map.put(
      SecureValueTypeRentalAgreement.cons,
      SecureValueTypeRentalAgreement
    );
    map.put(
      SecureValueTypePassportRegistration.cons,
      SecureValueTypePassportRegistration
    );
    map.put(
      SecureValueTypeTemporaryRegistration.cons,
      SecureValueTypeTemporaryRegistration
    );
    map.put(SecureValueTypePhone.cons, SecureValueTypePhone);
    map.put(SecureValueTypeEmail.cons, SecureValueTypeEmail);
    map.put(SecureValue.cons, SecureValue);
    map.put(InputSecureValue.cons, InputSecureValue);
    map.put(SecureValueHash.cons, SecureValueHash);
    map.put(SecureValueErrorData.cons, SecureValueErrorData);
    map.put(SecureValueErrorFrontSide.cons, SecureValueErrorFrontSide);
    map.put(SecureValueErrorReverseSide.cons, SecureValueErrorReverseSide);
    map.put(SecureValueErrorSelfie.cons, SecureValueErrorSelfie);
    map.put(SecureValueErrorFile.cons, SecureValueErrorFile);
    map.put(SecureValueErrorFiles.cons, SecureValueErrorFiles);
    map.put(SecureValueError.cons, SecureValueError);
    map.put(
      SecureValueErrorTranslationFile.cons,
      SecureValueErrorTranslationFile
    );
    map.put(
      SecureValueErrorTranslationFiles.cons,
      SecureValueErrorTranslationFiles
    );
    map.put(SecureCredentialsEncrypted.cons, SecureCredentialsEncrypted);
    map.put(account.AuthorizationForm.cons, account.AuthorizationForm);
    map.put(account.SentEmailCode.cons, account.SentEmailCode);
    map.put(help.DeepLinkInfoEmpty.cons, help.DeepLinkInfoEmpty);
    map.put(help.DeepLinkInfo.cons, help.DeepLinkInfo);
    map.put(SavedPhoneContact.cons, SavedPhoneContact);
    map.put(account.Takeout.cons, account.Takeout);
    map.put(PasswordKdfAlgoUnknown.cons, PasswordKdfAlgoUnknown);
    map.put(
      PasswordKdfAlgoSHA256SHA256PBKDF2HMACSHA512iter100000SHA256ModPow.cons,
      PasswordKdfAlgoSHA256SHA256PBKDF2HMACSHA512iter100000SHA256ModPow
    );
    map.put(SecurePasswordKdfAlgoUnknown.cons, SecurePasswordKdfAlgoUnknown);
    map.put(
      SecurePasswordKdfAlgoPBKDF2HMACSHA512iter100000.cons,
      SecurePasswordKdfAlgoPBKDF2HMACSHA512iter100000
    );
    map.put(SecurePasswordKdfAlgoSHA512.cons, SecurePasswordKdfAlgoSHA512);
    map.put(SecureSecretSettings.cons, SecureSecretSettings);
    map.put(InputCheckPasswordEmpty.cons, InputCheckPasswordEmpty);
    map.put(InputCheckPasswordSRP.cons, InputCheckPasswordSRP);
    map.put(SecureRequiredType.cons, SecureRequiredType);
    map.put(SecureRequiredTypeOneOf.cons, SecureRequiredTypeOneOf);
    map.put(
      help.PassportConfigNotModified.cons,
      help.PassportConfigNotModified
    );
    map.put(help.PassportConfig.cons, help.PassportConfig);
    map.put(InputAppEvent.cons, InputAppEvent);
    map.put(JsonObjectValue.cons, JsonObjectValue);
    map.put(JsonNull.cons, JsonNull);
    map.put(JsonBool.cons, JsonBool);
    map.put(JsonNumber.cons, JsonNumber);
    map.put(JsonString.cons, JsonString);
    map.put(JsonArray.cons, JsonArray);
    map.put(JsonObject.cons, JsonObject);
    map.put(PageTableCell.cons, PageTableCell);
    map.put(PageTableRow.cons, PageTableRow);
    map.put(PageCaption.cons, PageCaption);
    map.put(PageListItemText.cons, PageListItemText);
    map.put(PageListItemBlocks.cons, PageListItemBlocks);
    map.put(PageListOrderedItemText.cons, PageListOrderedItemText);
    map.put(PageListOrderedItemBlocks.cons, PageListOrderedItemBlocks);
    map.put(PageRelatedArticle.cons, PageRelatedArticle);
    map.put(Page.cons, Page);
    map.put(help.SupportName.cons, help.SupportName);
    map.put(help.UserInfoEmpty.cons, help.UserInfoEmpty);
    map.put(help.UserInfo.cons, help.UserInfo);
    map.put(PollAnswer.cons, PollAnswer);
    map.put(Poll.cons, Poll);
    map.put(PollAnswerVoters.cons, PollAnswerVoters);
    map.put(PollResults.cons, PollResults);
    map.put(ChatOnlines.cons, ChatOnlines);
    map.put(StatsURL.cons, StatsURL);
    map.put(ChatAdminRights.cons, ChatAdminRights);
    map.put(ChatBannedRights.cons, ChatBannedRights);
    map.put(InputWallPaper.cons, InputWallPaper);
    map.put(InputWallPaperSlug.cons, InputWallPaperSlug);
    map.put(InputWallPaperNoFile.cons, InputWallPaperNoFile);
    map.put(account.WallPapersNotModified.cons, account.WallPapersNotModified);
    map.put(account.WallPapers.cons, account.WallPapers);
    map.put(CodeSettings.cons, CodeSettings);
    map.put(WallPaperSettings.cons, WallPaperSettings);
    map.put(AutoDownloadSettings.cons, AutoDownloadSettings);
    map.put(account.AutoDownloadSettings.cons, account.AutoDownloadSettings);
    map.put(EmojiKeyword.cons, EmojiKeyword);
    map.put(EmojiKeywordDeleted.cons, EmojiKeywordDeleted);
    map.put(EmojiKeywordsDifference.cons, EmojiKeywordsDifference);
    map.put(EmojiURL.cons, EmojiURL);
    map.put(EmojiLanguage.cons, EmojiLanguage);
    map.put(Folder.cons, Folder);
    map.put(InputFolderPeer.cons, InputFolderPeer);
    map.put(FolderPeer.cons, FolderPeer);
    map.put(messages.SearchCounter.cons, messages.SearchCounter);
    map.put(UrlAuthResultRequest.cons, UrlAuthResultRequest);
    map.put(UrlAuthResultAccepted.cons, UrlAuthResultAccepted);
    map.put(UrlAuthResultDefault.cons, UrlAuthResultDefault);
    map.put(ChannelLocationEmpty.cons, ChannelLocationEmpty);
    map.put(ChannelLocation.cons, ChannelLocation);
    map.put(PeerLocated.cons, PeerLocated);
    map.put(PeerSelfLocated.cons, PeerSelfLocated);
    map.put(RestrictionReason.cons, RestrictionReason);
    map.put(InputTheme.cons, InputTheme);
    map.put(InputThemeSlug.cons, InputThemeSlug);
    map.put(Theme.cons, Theme);
    map.put(account.ThemesNotModified.cons, account.ThemesNotModified);
    map.put(account.Themes.cons, account.Themes);
    map.put(auth.LoginToken.cons, auth.LoginToken);
    map.put(auth.LoginTokenMigrateTo.cons, auth.LoginTokenMigrateTo);
    map.put(auth.LoginTokenSuccess.cons, auth.LoginTokenSuccess);
    map.put(account.ContentSettings.cons, account.ContentSettings);
    map.put(messages.InactiveChats.cons, messages.InactiveChats);
    map.put(BaseThemeClassic.cons, BaseThemeClassic);
    map.put(BaseThemeDay.cons, BaseThemeDay);
    map.put(BaseThemeNight.cons, BaseThemeNight);
    map.put(BaseThemeTinted.cons, BaseThemeTinted);
    map.put(BaseThemeArctic.cons, BaseThemeArctic);
    map.put(InputThemeSettings.cons, InputThemeSettings);
    map.put(ThemeSettings.cons, ThemeSettings);
    map.put(WebPageAttributeTheme.cons, WebPageAttributeTheme);
    map.put(WebPageAttributeStory.cons, WebPageAttributeStory);
    map.put(messages.VotesList.cons, messages.VotesList);
    map.put(BankCardOpenUrl.cons, BankCardOpenUrl);
    map.put(payments.BankCardData.cons, payments.BankCardData);
    map.put(DialogFilter.cons, DialogFilter);
    map.put(DialogFilterDefault.cons, DialogFilterDefault);
    map.put(DialogFilterChatlist.cons, DialogFilterChatlist);
    map.put(DialogFilterSuggested.cons, DialogFilterSuggested);
    map.put(StatsDateRangeDays.cons, StatsDateRangeDays);
    map.put(StatsAbsValueAndPrev.cons, StatsAbsValueAndPrev);
    map.put(StatsPercentValue.cons, StatsPercentValue);
    map.put(StatsGraphAsync.cons, StatsGraphAsync);
    map.put(StatsGraphError.cons, StatsGraphError);
    map.put(StatsGraph.cons, StatsGraph);
    map.put(MessageInteractionCounters.cons, MessageInteractionCounters);
    map.put(stats.BroadcastStats.cons, stats.BroadcastStats);
    map.put(help.PromoDataEmpty.cons, help.PromoDataEmpty);
    map.put(help.PromoData.cons, help.PromoData);
    map.put(VideoSize.cons, VideoSize);
    map.put(VideoSizeEmojiMarkup.cons, VideoSizeEmojiMarkup);
    map.put(VideoSizeStickerMarkup.cons, VideoSizeStickerMarkup);
    map.put(StatsGroupTopPoster.cons, StatsGroupTopPoster);
    map.put(StatsGroupTopAdmin.cons, StatsGroupTopAdmin);
    map.put(StatsGroupTopInviter.cons, StatsGroupTopInviter);
    map.put(stats.MegagroupStats.cons, stats.MegagroupStats);
    map.put(GlobalPrivacySettings.cons, GlobalPrivacySettings);
    map.put(help.CountryCode.cons, help.CountryCode);
    map.put(help.Country.cons, help.Country);
    map.put(help.CountriesListNotModified.cons, help.CountriesListNotModified);
    map.put(help.CountriesList.cons, help.CountriesList);
    map.put(MessageViews.cons, MessageViews);
    map.put(messages.MessageViews.cons, messages.MessageViews);
    map.put(messages.DiscussionMessage.cons, messages.DiscussionMessage);
    map.put(MessageReplyHeader.cons, MessageReplyHeader);
    map.put(MessageReplyStoryHeader.cons, MessageReplyStoryHeader);
    map.put(MessageReplies.cons, MessageReplies);
    map.put(PeerBlocked.cons, PeerBlocked);
    map.put(stats.MessageStats.cons, stats.MessageStats);
    map.put(GroupCallDiscarded.cons, GroupCallDiscarded);
    map.put(GroupCall.cons, GroupCall);
    map.put(InputGroupCall.cons, InputGroupCall);
    map.put(GroupCallParticipant.cons, GroupCallParticipant);
    map.put(phone.GroupCall.cons, phone.GroupCall);
    map.put(phone.GroupParticipants.cons, phone.GroupParticipants);
    map.put(InlineQueryPeerTypeSameBotPM.cons, InlineQueryPeerTypeSameBotPM);
    map.put(InlineQueryPeerTypePM.cons, InlineQueryPeerTypePM);
    map.put(InlineQueryPeerTypeChat.cons, InlineQueryPeerTypeChat);
    map.put(InlineQueryPeerTypeMegagroup.cons, InlineQueryPeerTypeMegagroup);
    map.put(InlineQueryPeerTypeBroadcast.cons, InlineQueryPeerTypeBroadcast);
    map.put(InlineQueryPeerTypeBotPM.cons, InlineQueryPeerTypeBotPM);
    map.put(messages.HistoryImport.cons, messages.HistoryImport);
    map.put(messages.HistoryImportParsed.cons, messages.HistoryImportParsed);
    map.put(
      messages.AffectedFoundMessages.cons,
      messages.AffectedFoundMessages
    );
    map.put(ChatInviteImporter.cons, ChatInviteImporter);
    map.put(messages.ExportedChatInvites.cons, messages.ExportedChatInvites);
    map.put(messages.ExportedChatInvite.cons, messages.ExportedChatInvite);
    map.put(
      messages.ExportedChatInviteReplaced.cons,
      messages.ExportedChatInviteReplaced
    );
    map.put(messages.ChatInviteImporters.cons, messages.ChatInviteImporters);
    map.put(ChatAdminWithInvites.cons, ChatAdminWithInvites);
    map.put(
      messages.ChatAdminsWithInvites.cons,
      messages.ChatAdminsWithInvites
    );
    map.put(
      messages.CheckedHistoryImportPeer.cons,
      messages.CheckedHistoryImportPeer
    );
    map.put(phone.JoinAsPeers.cons, phone.JoinAsPeers);
    map.put(phone.ExportedGroupCallInvite.cons, phone.ExportedGroupCallInvite);
    map.put(
      GroupCallParticipantVideoSourceGroup.cons,
      GroupCallParticipantVideoSourceGroup
    );
    map.put(GroupCallParticipantVideo.cons, GroupCallParticipantVideo);
    map.put(stickers.SuggestedShortName.cons, stickers.SuggestedShortName);
    map.put(BotCommandScopeDefault.cons, BotCommandScopeDefault);
    map.put(BotCommandScopeUsers.cons, BotCommandScopeUsers);
    map.put(BotCommandScopeChats.cons, BotCommandScopeChats);
    map.put(BotCommandScopeChatAdmins.cons, BotCommandScopeChatAdmins);
    map.put(BotCommandScopePeer.cons, BotCommandScopePeer);
    map.put(BotCommandScopePeerAdmins.cons, BotCommandScopePeerAdmins);
    map.put(BotCommandScopePeerUser.cons, BotCommandScopePeerUser);
    map.put(
      account.ResetPasswordFailedWait.cons,
      account.ResetPasswordFailedWait
    );
    map.put(
      account.ResetPasswordRequestedWait.cons,
      account.ResetPasswordRequestedWait
    );
    map.put(account.ResetPasswordOk.cons, account.ResetPasswordOk);
    map.put(SponsoredMessage.cons, SponsoredMessage);
    map.put(messages.SponsoredMessages.cons, messages.SponsoredMessages);
    map.put(
      messages.SponsoredMessagesEmpty.cons,
      messages.SponsoredMessagesEmpty
    );
    map.put(SearchResultsCalendarPeriod.cons, SearchResultsCalendarPeriod);
    map.put(
      messages.SearchResultsCalendar.cons,
      messages.SearchResultsCalendar
    );
    map.put(SearchResultPosition.cons, SearchResultPosition);
    map.put(
      messages.SearchResultsPositions.cons,
      messages.SearchResultsPositions
    );
    map.put(channels.SendAsPeers.cons, channels.SendAsPeers);
    map.put(users.UserFull.cons, users.UserFull);
    map.put(messages.PeerSettings.cons, messages.PeerSettings);
    map.put(auth.LoggedOut.cons, auth.LoggedOut);
    map.put(ReactionCount.cons, ReactionCount);
    map.put(MessageReactions.cons, MessageReactions);
    map.put(messages.MessageReactionsList.cons, messages.MessageReactionsList);
    map.put(AvailableReaction.cons, AvailableReaction);
    map.put(
      messages.AvailableReactionsNotModified.cons,
      messages.AvailableReactionsNotModified
    );
    map.put(messages.AvailableReactions.cons, messages.AvailableReactions);
    map.put(MessagePeerReaction.cons, MessagePeerReaction);
    map.put(GroupCallStreamChannel.cons, GroupCallStreamChannel);
    map.put(phone.GroupCallStreamChannels.cons, phone.GroupCallStreamChannels);
    map.put(phone.GroupCallStreamRtmpUrl.cons, phone.GroupCallStreamRtmpUrl);
    map.put(AttachMenuBotIconColor.cons, AttachMenuBotIconColor);
    map.put(AttachMenuBotIcon.cons, AttachMenuBotIcon);
    map.put(AttachMenuBot.cons, AttachMenuBot);
    map.put(AttachMenuBotsNotModified.cons, AttachMenuBotsNotModified);
    map.put(AttachMenuBots.cons, AttachMenuBots);
    map.put(AttachMenuBotsBot.cons, AttachMenuBotsBot);
    map.put(WebViewResultUrl.cons, WebViewResultUrl);
    map.put(SimpleWebViewResultUrl.cons, SimpleWebViewResultUrl);
    map.put(WebViewMessageSent.cons, WebViewMessageSent);
    map.put(BotMenuButtonDefault.cons, BotMenuButtonDefault);
    map.put(BotMenuButtonCommands.cons, BotMenuButtonCommands);
    map.put(BotMenuButton.cons, BotMenuButton);
    map.put(
      account.SavedRingtonesNotModified.cons,
      account.SavedRingtonesNotModified
    );
    map.put(account.SavedRingtones.cons, account.SavedRingtones);
    map.put(NotificationSoundDefault.cons, NotificationSoundDefault);
    map.put(NotificationSoundNone.cons, NotificationSoundNone);
    map.put(NotificationSoundLocal.cons, NotificationSoundLocal);
    map.put(NotificationSoundRingtone.cons, NotificationSoundRingtone);
    map.put(account.SavedRingtone.cons, account.SavedRingtone);
    map.put(
      account.SavedRingtoneConverted.cons,
      account.SavedRingtoneConverted
    );
    map.put(AttachMenuPeerTypeSameBotPM.cons, AttachMenuPeerTypeSameBotPM);
    map.put(AttachMenuPeerTypeBotPM.cons, AttachMenuPeerTypeBotPM);
    map.put(AttachMenuPeerTypePM.cons, AttachMenuPeerTypePM);
    map.put(AttachMenuPeerTypeChat.cons, AttachMenuPeerTypeChat);
    map.put(AttachMenuPeerTypeBroadcast.cons, AttachMenuPeerTypeBroadcast);
    map.put(InputInvoiceMessage.cons, InputInvoiceMessage);
    map.put(InputInvoiceSlug.cons, InputInvoiceSlug);
    map.put(payments.ExportedInvoice.cons, payments.ExportedInvoice);
    map.put(messages.TranscribedAudio.cons, messages.TranscribedAudio);
    map.put(help.PremiumPromo.cons, help.PremiumPromo);
    map.put(
      InputStorePaymentPremiumSubscription.cons,
      InputStorePaymentPremiumSubscription
    );
    map.put(InputStorePaymentGiftPremium.cons, InputStorePaymentGiftPremium);
    map.put(PremiumGiftOption.cons, PremiumGiftOption);
    map.put(PaymentFormMethod.cons, PaymentFormMethod);
    map.put(EmojiStatusEmpty.cons, EmojiStatusEmpty);
    map.put(EmojiStatus.cons, EmojiStatus);
    map.put(EmojiStatusUntil.cons, EmojiStatusUntil);
    map.put(
      account.EmojiStatusesNotModified.cons,
      account.EmojiStatusesNotModified
    );
    map.put(account.EmojiStatuses.cons, account.EmojiStatuses);
    map.put(ReactionEmpty.cons, ReactionEmpty);
    map.put(ReactionEmoji.cons, ReactionEmoji);
    map.put(ReactionCustomEmoji.cons, ReactionCustomEmoji);
    map.put(ChatReactionsNone.cons, ChatReactionsNone);
    map.put(ChatReactionsAll.cons, ChatReactionsAll);
    map.put(ChatReactionsSome.cons, ChatReactionsSome);
    map.put(messages.ReactionsNotModified.cons, messages.ReactionsNotModified);
    map.put(messages.Reactions.cons, messages.Reactions);
    map.put(EmailVerifyPurposeLoginSetup.cons, EmailVerifyPurposeLoginSetup);
    map.put(EmailVerifyPurposeLoginChange.cons, EmailVerifyPurposeLoginChange);
    map.put(EmailVerifyPurposePassport.cons, EmailVerifyPurposePassport);
    map.put(EmailVerificationCode.cons, EmailVerificationCode);
    map.put(EmailVerificationGoogle.cons, EmailVerificationGoogle);
    map.put(EmailVerificationApple.cons, EmailVerificationApple);
    map.put(account.EmailVerified.cons, account.EmailVerified);
    map.put(account.EmailVerifiedLogin.cons, account.EmailVerifiedLogin);
    map.put(PremiumSubscriptionOption.cons, PremiumSubscriptionOption);
    map.put(SendAsPeer.cons, SendAsPeer);
    map.put(MessageExtendedMediaPreview.cons, MessageExtendedMediaPreview);
    map.put(MessageExtendedMedia.cons, MessageExtendedMedia);
    map.put(StickerKeyword.cons, StickerKeyword);
    map.put(Username.cons, Username);
    map.put(ForumTopicDeleted.cons, ForumTopicDeleted);
    map.put(ForumTopic.cons, ForumTopic);
    map.put(messages.ForumTopics.cons, messages.ForumTopics);
    map.put(DefaultHistoryTTL.cons, DefaultHistoryTTL);
    map.put(ExportedContactToken.cons, ExportedContactToken);
    map.put(RequestPeerTypeUser.cons, RequestPeerTypeUser);
    map.put(RequestPeerTypeChat.cons, RequestPeerTypeChat);
    map.put(RequestPeerTypeBroadcast.cons, RequestPeerTypeBroadcast);
    map.put(EmojiListNotModified.cons, EmojiListNotModified);
    map.put(EmojiList.cons, EmojiList);
    map.put(EmojiGroup.cons, EmojiGroup);
    map.put(
      messages.EmojiGroupsNotModified.cons,
      messages.EmojiGroupsNotModified
    );
    map.put(messages.EmojiGroups.cons, messages.EmojiGroups);
    map.put(TextWithEntities.cons, TextWithEntities);
    map.put(messages.TranslateResult.cons, messages.TranslateResult);
    map.put(AutoSaveSettings.cons, AutoSaveSettings);
    map.put(AutoSaveException.cons, AutoSaveException);
    map.put(account.AutoSaveSettings.cons, account.AutoSaveSettings);
    map.put(help.AppConfigNotModified.cons, help.AppConfigNotModified);
    map.put(help.AppConfig.cons, help.AppConfig);
    map.put(InputBotAppID.cons, InputBotAppID);
    map.put(InputBotAppShortName.cons, InputBotAppShortName);
    map.put(BotAppNotModified.cons, BotAppNotModified);
    map.put(BotApp.cons, BotApp);
    map.put(messages.BotApp.cons, messages.BotApp);
    map.put(AppWebViewResultUrl.cons, AppWebViewResultUrl);
    map.put(InlineBotWebView.cons, InlineBotWebView);
    map.put(ReadParticipantDate.cons, ReadParticipantDate);
    map.put(InputChatlistDialogFilter.cons, InputChatlistDialogFilter);
    map.put(ExportedChatlistInvite.cons, ExportedChatlistInvite);
    map.put(
      chatlists.ExportedChatlistInvite.cons,
      chatlists.ExportedChatlistInvite
    );
    map.put(chatlists.ExportedInvites.cons, chatlists.ExportedInvites);
    map.put(
      chatlists.ChatlistInviteAlready.cons,
      chatlists.ChatlistInviteAlready
    );
    map.put(chatlists.ChatlistInvite.cons, chatlists.ChatlistInvite);
    map.put(chatlists.ChatlistUpdates.cons, chatlists.ChatlistUpdates);
    map.put(bots.BotInfo.cons, bots.BotInfo);
    map.put(MessagePeerVote.cons, MessagePeerVote);
    map.put(MessagePeerVoteInputOption.cons, MessagePeerVoteInputOption);
    map.put(MessagePeerVoteMultiple.cons, MessagePeerVoteMultiple);
    map.put(SponsoredWebPage.cons, SponsoredWebPage);
    map.put(StoryViews.cons, StoryViews);
    map.put(StoryItemDeleted.cons, StoryItemDeleted);
    map.put(StoryItemSkipped.cons, StoryItemSkipped);
    map.put(StoryItem.cons, StoryItem);
    map.put(stories.AllStoriesNotModified.cons, stories.AllStoriesNotModified);
    map.put(stories.AllStories.cons, stories.AllStories);
    map.put(stories.Stories.cons, stories.Stories);
    map.put(StoryView.cons, StoryView);
    map.put(stories.StoryViewsList.cons, stories.StoryViewsList);
    map.put(stories.StoryViews.cons, stories.StoryViews);
    map.put(InputReplyToMessage.cons, InputReplyToMessage);
    map.put(InputReplyToStory.cons, InputReplyToStory);
    map.put(ExportedStoryLink.cons, ExportedStoryLink);
    map.put(StoriesStealthMode.cons, StoriesStealthMode);
    map.put(MediaAreaCoordinates.cons, MediaAreaCoordinates);
    map.put(MediaAreaVenue.cons, MediaAreaVenue);
    map.put(InputMediaAreaVenue.cons, InputMediaAreaVenue);
    map.put(MediaAreaGeoPoint.cons, MediaAreaGeoPoint);
    map.put(MediaAreaSuggestedReaction.cons, MediaAreaSuggestedReaction);
    map.put(PeerStories.cons, PeerStories);
    map.put(stories.PeerStories.cons, stories.PeerStories);
    map.put(stories.BoostsStatus.cons, stories.BoostsStatus);
    map.put(stories.CanApplyBoostOk.cons, stories.CanApplyBoostOk);
    map.put(stories.CanApplyBoostReplace.cons, stories.CanApplyBoostReplace);
    map.put(Booster.cons, Booster);
    map.put(stories.BoostersList.cons, stories.BoostersList);
    map.put(messages.WebPage.cons, messages.WebPage);
    map.put(InvokeAfterMsg.cons, InvokeAfterMsg);
    map.put(InvokeAfterMsgs.cons, InvokeAfterMsgs);
    map.put(InitConnection.cons, InitConnection);
    map.put(InvokeWithLayer.cons, InvokeWithLayer);
    map.put(InvokeWithoutUpdates.cons, InvokeWithoutUpdates);
    map.put(InvokeWithMessagesRange.cons, InvokeWithMessagesRange);
    map.put(InvokeWithTakeout.cons, InvokeWithTakeout);
    map.put(auth.SendCode.cons, auth.SendCode);
    map.put(auth.SignUp.cons, auth.SignUp);
    map.put(auth.SignIn.cons, auth.SignIn);
    map.put(auth.LogOut.cons, auth.LogOut);
    map.put(auth.ResetAuthorizations.cons, auth.ResetAuthorizations);
    map.put(auth.ExportAuthorization.cons, auth.ExportAuthorization);
    map.put(auth.ImportAuthorization.cons, auth.ImportAuthorization);
    map.put(auth.BindTempAuthKey.cons, auth.BindTempAuthKey);
    map.put(auth.ImportBotAuthorization.cons, auth.ImportBotAuthorization);
    map.put(auth.CheckPassword.cons, auth.CheckPassword);
    map.put(auth.RequestPasswordRecovery.cons, auth.RequestPasswordRecovery);
    map.put(auth.RecoverPassword.cons, auth.RecoverPassword);
    map.put(auth.ResendCode.cons, auth.ResendCode);
    map.put(auth.CancelCode.cons, auth.CancelCode);
    map.put(auth.DropTempAuthKeys.cons, auth.DropTempAuthKeys);
    map.put(auth.ExportLoginToken.cons, auth.ExportLoginToken);
    map.put(auth.ImportLoginToken.cons, auth.ImportLoginToken);
    map.put(auth.AcceptLoginToken.cons, auth.AcceptLoginToken);
    map.put(auth.CheckRecoveryPassword.cons, auth.CheckRecoveryPassword);
    map.put(
      auth.ImportWebTokenAuthorization.cons,
      auth.ImportWebTokenAuthorization
    );
    map.put(auth.RequestFirebaseSms.cons, auth.RequestFirebaseSms);
    map.put(auth.ResetLoginEmail.cons, auth.ResetLoginEmail);
    map.put(account.RegisterDevice.cons, account.RegisterDevice);
    map.put(account.UnregisterDevice.cons, account.UnregisterDevice);
    map.put(account.UpdateNotifySettings.cons, account.UpdateNotifySettings);
    map.put(account.GetNotifySettings.cons, account.GetNotifySettings);
    map.put(account.ResetNotifySettings.cons, account.ResetNotifySettings);
    map.put(account.UpdateProfile.cons, account.UpdateProfile);
    map.put(account.UpdateStatus.cons, account.UpdateStatus);
    map.put(account.GetWallPapers.cons, account.GetWallPapers);
    map.put(account.ReportPeer.cons, account.ReportPeer);
    map.put(account.CheckUsername.cons, account.CheckUsername);
    map.put(account.UpdateUsername.cons, account.UpdateUsername);
    map.put(account.GetPrivacy.cons, account.GetPrivacy);
    map.put(account.SetPrivacy.cons, account.SetPrivacy);
    map.put(account.DeleteAccount.cons, account.DeleteAccount);
    map.put(account.GetAccountTTL.cons, account.GetAccountTTL);
    map.put(account.SetAccountTTL.cons, account.SetAccountTTL);
    map.put(account.SendChangePhoneCode.cons, account.SendChangePhoneCode);
    map.put(account.ChangePhone.cons, account.ChangePhone);
    map.put(account.UpdateDeviceLocked.cons, account.UpdateDeviceLocked);
    map.put(account.GetAuthorizations.cons, account.GetAuthorizations);
    map.put(account.ResetAuthorization.cons, account.ResetAuthorization);
    map.put(account.GetPassword.cons, account.GetPassword);
    map.put(account.GetPasswordSettings.cons, account.GetPasswordSettings);
    map.put(
      account.UpdatePasswordSettings.cons,
      account.UpdatePasswordSettings
    );
    map.put(account.SendConfirmPhoneCode.cons, account.SendConfirmPhoneCode);
    map.put(account.ConfirmPhone.cons, account.ConfirmPhone);
    map.put(account.GetTmpPassword.cons, account.GetTmpPassword);
    map.put(account.GetWebAuthorizations.cons, account.GetWebAuthorizations);
    map.put(account.ResetWebAuthorization.cons, account.ResetWebAuthorization);
    map.put(
      account.ResetWebAuthorizations.cons,
      account.ResetWebAuthorizations
    );
    map.put(account.GetAllSecureValues.cons, account.GetAllSecureValues);
    map.put(account.GetSecureValue.cons, account.GetSecureValue);
    map.put(account.SaveSecureValue.cons, account.SaveSecureValue);
    map.put(account.DeleteSecureValue.cons, account.DeleteSecureValue);
    map.put(account.GetAuthorizationForm.cons, account.GetAuthorizationForm);
    map.put(account.AcceptAuthorization.cons, account.AcceptAuthorization);
    map.put(account.SendVerifyPhoneCode.cons, account.SendVerifyPhoneCode);
    map.put(account.VerifyPhone.cons, account.VerifyPhone);
    map.put(account.SendVerifyEmailCode.cons, account.SendVerifyEmailCode);
    map.put(account.VerifyEmail.cons, account.VerifyEmail);
    map.put(account.InitTakeoutSession.cons, account.InitTakeoutSession);
    map.put(account.FinishTakeoutSession.cons, account.FinishTakeoutSession);
    map.put(account.ConfirmPasswordEmail.cons, account.ConfirmPasswordEmail);
    map.put(account.ResendPasswordEmail.cons, account.ResendPasswordEmail);
    map.put(account.CancelPasswordEmail.cons, account.CancelPasswordEmail);
    map.put(
      account.GetContactSignUpNotification.cons,
      account.GetContactSignUpNotification
    );
    map.put(
      account.SetContactSignUpNotification.cons,
      account.SetContactSignUpNotification
    );
    map.put(account.GetNotifyExceptions.cons, account.GetNotifyExceptions);
    map.put(account.GetWallPaper.cons, account.GetWallPaper);
    map.put(account.UploadWallPaper.cons, account.UploadWallPaper);
    map.put(account.SaveWallPaper.cons, account.SaveWallPaper);
    map.put(account.InstallWallPaper.cons, account.InstallWallPaper);
    map.put(account.ResetWallPapers.cons, account.ResetWallPapers);
    map.put(
      account.GetAutoDownloadSettings.cons,
      account.GetAutoDownloadSettings
    );
    map.put(
      account.SaveAutoDownloadSettings.cons,
      account.SaveAutoDownloadSettings
    );
    map.put(account.UploadTheme.cons, account.UploadTheme);
    map.put(account.CreateTheme.cons, account.CreateTheme);
    map.put(account.UpdateTheme.cons, account.UpdateTheme);
    map.put(account.SaveTheme.cons, account.SaveTheme);
    map.put(account.InstallTheme.cons, account.InstallTheme);
    map.put(account.GetTheme.cons, account.GetTheme);
    map.put(account.GetThemes.cons, account.GetThemes);
    map.put(account.SetContentSettings.cons, account.SetContentSettings);
    map.put(account.GetContentSettings.cons, account.GetContentSettings);
    map.put(account.GetMultiWallPapers.cons, account.GetMultiWallPapers);
    map.put(
      account.GetGlobalPrivacySettings.cons,
      account.GetGlobalPrivacySettings
    );
    map.put(
      account.SetGlobalPrivacySettings.cons,
      account.SetGlobalPrivacySettings
    );
    map.put(account.ReportProfilePhoto.cons, account.ReportProfilePhoto);
    map.put(account.ResetPassword.cons, account.ResetPassword);
    map.put(account.DeclinePasswordReset.cons, account.DeclinePasswordReset);
    map.put(account.GetChatThemes.cons, account.GetChatThemes);
    map.put(account.SetAuthorizationTTL.cons, account.SetAuthorizationTTL);
    map.put(
      account.ChangeAuthorizationSettings.cons,
      account.ChangeAuthorizationSettings
    );
    map.put(account.GetSavedRingtones.cons, account.GetSavedRingtones);
    map.put(account.SaveRingtone.cons, account.SaveRingtone);
    map.put(account.UploadRingtone.cons, account.UploadRingtone);
    map.put(account.UpdateEmojiStatus.cons, account.UpdateEmojiStatus);
    map.put(
      account.GetDefaultEmojiStatuses.cons,
      account.GetDefaultEmojiStatuses
    );
    map.put(
      account.GetRecentEmojiStatuses.cons,
      account.GetRecentEmojiStatuses
    );
    map.put(
      account.ClearRecentEmojiStatuses.cons,
      account.ClearRecentEmojiStatuses
    );
    map.put(account.ReorderUsernames.cons, account.ReorderUsernames);
    map.put(account.ToggleUsername.cons, account.ToggleUsername);
    map.put(
      account.GetDefaultProfilePhotoEmojis.cons,
      account.GetDefaultProfilePhotoEmojis
    );
    map.put(
      account.GetDefaultGroupPhotoEmojis.cons,
      account.GetDefaultGroupPhotoEmojis
    );
    map.put(account.GetAutoSaveSettings.cons, account.GetAutoSaveSettings);
    map.put(account.SaveAutoSaveSettings.cons, account.SaveAutoSaveSettings);
    map.put(
      account.DeleteAutoSaveExceptions.cons,
      account.DeleteAutoSaveExceptions
    );
    map.put(account.InvalidateSignInCodes.cons, account.InvalidateSignInCodes);
    map.put(users.GetUsers.cons, users.GetUsers);
    map.put(users.GetFullUser.cons, users.GetFullUser);
    map.put(users.SetSecureValueErrors.cons, users.SetSecureValueErrors);
    map.put(contacts.GetContactIDs.cons, contacts.GetContactIDs);
    map.put(contacts.GetStatuses.cons, contacts.GetStatuses);
    map.put(contacts.GetContacts.cons, contacts.GetContacts);
    map.put(contacts.ImportContacts.cons, contacts.ImportContacts);
    map.put(contacts.DeleteContacts.cons, contacts.DeleteContacts);
    map.put(contacts.DeleteByPhones.cons, contacts.DeleteByPhones);
    map.put(contacts.Block.cons, contacts.Block);
    map.put(contacts.Unblock.cons, contacts.Unblock);
    map.put(contacts.GetBlocked.cons, contacts.GetBlocked);
    map.put(contacts.Search.cons, contacts.Search);
    map.put(contacts.ResolveUsername.cons, contacts.ResolveUsername);
    map.put(contacts.GetTopPeers.cons, contacts.GetTopPeers);
    map.put(contacts.ResetTopPeerRating.cons, contacts.ResetTopPeerRating);
    map.put(contacts.ResetSaved.cons, contacts.ResetSaved);
    map.put(contacts.GetSaved.cons, contacts.GetSaved);
    map.put(contacts.ToggleTopPeers.cons, contacts.ToggleTopPeers);
    map.put(contacts.AddContact.cons, contacts.AddContact);
    map.put(contacts.AcceptContact.cons, contacts.AcceptContact);
    map.put(contacts.GetLocated.cons, contacts.GetLocated);
    map.put(contacts.BlockFromReplies.cons, contacts.BlockFromReplies);
    map.put(contacts.ResolvePhone.cons, contacts.ResolvePhone);
    map.put(contacts.ExportContactToken.cons, contacts.ExportContactToken);
    map.put(contacts.ImportContactToken.cons, contacts.ImportContactToken);
    map.put(contacts.EditCloseFriends.cons, contacts.EditCloseFriends);
    map.put(contacts.SetBlocked.cons, contacts.SetBlocked);
    map.put(messages.GetMessages.cons, messages.GetMessages);
    map.put(messages.GetDialogs.cons, messages.GetDialogs);
    map.put(messages.GetHistory.cons, messages.GetHistory);
    map.put(messages.Search.cons, messages.Search);
    map.put(messages.ReadHistory.cons, messages.ReadHistory);
    map.put(messages.DeleteHistory.cons, messages.DeleteHistory);
    map.put(messages.DeleteMessages.cons, messages.DeleteMessages);
    map.put(messages.ReceivedMessages.cons, messages.ReceivedMessages);
    map.put(messages.SetTyping.cons, messages.SetTyping);
    map.put(messages.SendMessage.cons, messages.SendMessage);
    map.put(messages.SendMedia.cons, messages.SendMedia);
    map.put(messages.ForwardMessages.cons, messages.ForwardMessages);
    map.put(messages.ReportSpam.cons, messages.ReportSpam);
    map.put(messages.GetPeerSettings.cons, messages.GetPeerSettings);
    map.put(messages.Report.cons, messages.Report);
    map.put(messages.GetChats.cons, messages.GetChats);
    map.put(messages.GetFullChat.cons, messages.GetFullChat);
    map.put(messages.EditChatTitle.cons, messages.EditChatTitle);
    map.put(messages.EditChatPhoto.cons, messages.EditChatPhoto);
    map.put(messages.AddChatUser.cons, messages.AddChatUser);
    map.put(messages.DeleteChatUser.cons, messages.DeleteChatUser);
    map.put(messages.CreateChat.cons, messages.CreateChat);
    map.put(messages.GetDhConfig.cons, messages.GetDhConfig);
    map.put(messages.RequestEncryption.cons, messages.RequestEncryption);
    map.put(messages.AcceptEncryption.cons, messages.AcceptEncryption);
    map.put(messages.DiscardEncryption.cons, messages.DiscardEncryption);
    map.put(messages.SetEncryptedTyping.cons, messages.SetEncryptedTyping);
    map.put(messages.ReadEncryptedHistory.cons, messages.ReadEncryptedHistory);
    map.put(messages.SendEncrypted.cons, messages.SendEncrypted);
    map.put(messages.SendEncryptedFile.cons, messages.SendEncryptedFile);
    map.put(messages.SendEncryptedService.cons, messages.SendEncryptedService);
    map.put(messages.ReceivedQueue.cons, messages.ReceivedQueue);
    map.put(messages.ReportEncryptedSpam.cons, messages.ReportEncryptedSpam);
    map.put(messages.ReadMessageContents.cons, messages.ReadMessageContents);
    map.put(messages.GetStickers.cons, messages.GetStickers);
    map.put(messages.GetAllStickers.cons, messages.GetAllStickers);
    map.put(messages.GetWebPagePreview.cons, messages.GetWebPagePreview);
    map.put(messages.ExportChatInvite.cons, messages.ExportChatInvite);
    map.put(messages.CheckChatInvite.cons, messages.CheckChatInvite);
    map.put(messages.ImportChatInvite.cons, messages.ImportChatInvite);
    map.put(messages.GetStickerSet.cons, messages.GetStickerSet);
    map.put(messages.InstallStickerSet.cons, messages.InstallStickerSet);
    map.put(messages.UninstallStickerSet.cons, messages.UninstallStickerSet);
    map.put(messages.StartBot.cons, messages.StartBot);
    map.put(messages.GetMessagesViews.cons, messages.GetMessagesViews);
    map.put(messages.EditChatAdmin.cons, messages.EditChatAdmin);
    map.put(messages.MigrateChat.cons, messages.MigrateChat);
    map.put(messages.SearchGlobal.cons, messages.SearchGlobal);
    map.put(messages.ReorderStickerSets.cons, messages.ReorderStickerSets);
    map.put(messages.GetDocumentByHash.cons, messages.GetDocumentByHash);
    map.put(messages.GetSavedGifs.cons, messages.GetSavedGifs);
    map.put(messages.SaveGif.cons, messages.SaveGif);
    map.put(messages.GetInlineBotResults.cons, messages.GetInlineBotResults);
    map.put(messages.SetInlineBotResults.cons, messages.SetInlineBotResults);
    map.put(messages.SendInlineBotResult.cons, messages.SendInlineBotResult);
    map.put(messages.GetMessageEditData.cons, messages.GetMessageEditData);
    map.put(messages.EditMessage.cons, messages.EditMessage);
    map.put(messages.EditInlineBotMessage.cons, messages.EditInlineBotMessage);
    map.put(messages.GetBotCallbackAnswer.cons, messages.GetBotCallbackAnswer);
    map.put(messages.SetBotCallbackAnswer.cons, messages.SetBotCallbackAnswer);
    map.put(messages.GetPeerDialogs.cons, messages.GetPeerDialogs);
    map.put(messages.SaveDraft.cons, messages.SaveDraft);
    map.put(messages.GetAllDrafts.cons, messages.GetAllDrafts);
    map.put(messages.GetFeaturedStickers.cons, messages.GetFeaturedStickers);
    map.put(messages.ReadFeaturedStickers.cons, messages.ReadFeaturedStickers);
    map.put(messages.GetRecentStickers.cons, messages.GetRecentStickers);
    map.put(messages.SaveRecentSticker.cons, messages.SaveRecentSticker);
    map.put(messages.ClearRecentStickers.cons, messages.ClearRecentStickers);
    map.put(messages.GetArchivedStickers.cons, messages.GetArchivedStickers);
    map.put(messages.GetMaskStickers.cons, messages.GetMaskStickers);
    map.put(messages.GetAttachedStickers.cons, messages.GetAttachedStickers);
    map.put(messages.SetGameScore.cons, messages.SetGameScore);
    map.put(messages.SetInlineGameScore.cons, messages.SetInlineGameScore);
    map.put(messages.GetGameHighScores.cons, messages.GetGameHighScores);
    map.put(
      messages.GetInlineGameHighScores.cons,
      messages.GetInlineGameHighScores
    );
    map.put(messages.GetCommonChats.cons, messages.GetCommonChats);
    map.put(messages.GetWebPage.cons, messages.GetWebPage);
    map.put(messages.ToggleDialogPin.cons, messages.ToggleDialogPin);
    map.put(messages.ReorderPinnedDialogs.cons, messages.ReorderPinnedDialogs);
    map.put(messages.GetPinnedDialogs.cons, messages.GetPinnedDialogs);
    map.put(
      messages.SetBotShippingResults.cons,
      messages.SetBotShippingResults
    );
    map.put(
      messages.SetBotPrecheckoutResults.cons,
      messages.SetBotPrecheckoutResults
    );
    map.put(messages.UploadMedia.cons, messages.UploadMedia);
    map.put(
      messages.SendScreenshotNotification.cons,
      messages.SendScreenshotNotification
    );
    map.put(messages.GetFavedStickers.cons, messages.GetFavedStickers);
    map.put(messages.FaveSticker.cons, messages.FaveSticker);
    map.put(messages.GetUnreadMentions.cons, messages.GetUnreadMentions);
    map.put(messages.ReadMentions.cons, messages.ReadMentions);
    map.put(messages.GetRecentLocations.cons, messages.GetRecentLocations);
    map.put(messages.SendMultiMedia.cons, messages.SendMultiMedia);
    map.put(messages.UploadEncryptedFile.cons, messages.UploadEncryptedFile);
    map.put(messages.SearchStickerSets.cons, messages.SearchStickerSets);
    map.put(messages.GetSplitRanges.cons, messages.GetSplitRanges);
    map.put(messages.MarkDialogUnread.cons, messages.MarkDialogUnread);
    map.put(messages.GetDialogUnreadMarks.cons, messages.GetDialogUnreadMarks);
    map.put(messages.ClearAllDrafts.cons, messages.ClearAllDrafts);
    map.put(messages.UpdatePinnedMessage.cons, messages.UpdatePinnedMessage);
    map.put(messages.SendVote.cons, messages.SendVote);
    map.put(messages.GetPollResults.cons, messages.GetPollResults);
    map.put(messages.GetOnlines.cons, messages.GetOnlines);
    map.put(messages.EditChatAbout.cons, messages.EditChatAbout);
    map.put(
      messages.EditChatDefaultBannedRights.cons,
      messages.EditChatDefaultBannedRights
    );
    map.put(messages.GetEmojiKeywords.cons, messages.GetEmojiKeywords);
    map.put(
      messages.GetEmojiKeywordsDifference.cons,
      messages.GetEmojiKeywordsDifference
    );
    map.put(
      messages.GetEmojiKeywordsLanguages.cons,
      messages.GetEmojiKeywordsLanguages
    );
    map.put(messages.GetEmojiURL.cons, messages.GetEmojiURL);
    map.put(messages.GetSearchCounters.cons, messages.GetSearchCounters);
    map.put(messages.RequestUrlAuth.cons, messages.RequestUrlAuth);
    map.put(messages.AcceptUrlAuth.cons, messages.AcceptUrlAuth);
    map.put(messages.HidePeerSettingsBar.cons, messages.HidePeerSettingsBar);
    map.put(messages.GetScheduledHistory.cons, messages.GetScheduledHistory);
    map.put(messages.GetScheduledMessages.cons, messages.GetScheduledMessages);
    map.put(
      messages.SendScheduledMessages.cons,
      messages.SendScheduledMessages
    );
    map.put(
      messages.DeleteScheduledMessages.cons,
      messages.DeleteScheduledMessages
    );
    map.put(messages.GetPollVotes.cons, messages.GetPollVotes);
    map.put(messages.ToggleStickerSets.cons, messages.ToggleStickerSets);
    map.put(messages.GetDialogFilters.cons, messages.GetDialogFilters);
    map.put(
      messages.GetSuggestedDialogFilters.cons,
      messages.GetSuggestedDialogFilters
    );
    map.put(messages.UpdateDialogFilter.cons, messages.UpdateDialogFilter);
    map.put(
      messages.UpdateDialogFiltersOrder.cons,
      messages.UpdateDialogFiltersOrder
    );
    map.put(
      messages.GetOldFeaturedStickers.cons,
      messages.GetOldFeaturedStickers
    );
    map.put(messages.GetReplies.cons, messages.GetReplies);
    map.put(messages.GetDiscussionMessage.cons, messages.GetDiscussionMessage);
    map.put(messages.ReadDiscussion.cons, messages.ReadDiscussion);
    map.put(messages.UnpinAllMessages.cons, messages.UnpinAllMessages);
    map.put(messages.DeleteChat.cons, messages.DeleteChat);
    map.put(
      messages.DeletePhoneCallHistory.cons,
      messages.DeletePhoneCallHistory
    );
    map.put(messages.CheckHistoryImport.cons, messages.CheckHistoryImport);
    map.put(messages.InitHistoryImport.cons, messages.InitHistoryImport);
    map.put(messages.UploadImportedMedia.cons, messages.UploadImportedMedia);
    map.put(messages.StartHistoryImport.cons, messages.StartHistoryImport);
    map.put(
      messages.GetExportedChatInvites.cons,
      messages.GetExportedChatInvites
    );
    map.put(
      messages.GetExportedChatInvite.cons,
      messages.GetExportedChatInvite
    );
    map.put(
      messages.EditExportedChatInvite.cons,
      messages.EditExportedChatInvite
    );
    map.put(
      messages.DeleteRevokedExportedChatInvites.cons,
      messages.DeleteRevokedExportedChatInvites
    );
    map.put(
      messages.DeleteExportedChatInvite.cons,
      messages.DeleteExportedChatInvite
    );
    map.put(messages.GetAdminsWithInvites.cons, messages.GetAdminsWithInvites);
    map.put(
      messages.GetChatInviteImporters.cons,
      messages.GetChatInviteImporters
    );
    map.put(messages.SetHistoryTTL.cons, messages.SetHistoryTTL);
    map.put(
      messages.CheckHistoryImportPeer.cons,
      messages.CheckHistoryImportPeer
    );
    map.put(messages.SetChatTheme.cons, messages.SetChatTheme);
    map.put(
      messages.GetMessageReadParticipants.cons,
      messages.GetMessageReadParticipants
    );
    map.put(
      messages.GetSearchResultsCalendar.cons,
      messages.GetSearchResultsCalendar
    );
    map.put(
      messages.GetSearchResultsPositions.cons,
      messages.GetSearchResultsPositions
    );
    map.put(messages.HideChatJoinRequest.cons, messages.HideChatJoinRequest);
    map.put(
      messages.HideAllChatJoinRequests.cons,
      messages.HideAllChatJoinRequests
    );
    map.put(messages.ToggleNoForwards.cons, messages.ToggleNoForwards);
    map.put(messages.SaveDefaultSendAs.cons, messages.SaveDefaultSendAs);
    map.put(messages.SendReaction.cons, messages.SendReaction);
    map.put(messages.GetMessagesReactions.cons, messages.GetMessagesReactions);
    map.put(
      messages.GetMessageReactionsList.cons,
      messages.GetMessageReactionsList
    );
    map.put(
      messages.SetChatAvailableReactions.cons,
      messages.SetChatAvailableReactions
    );
    map.put(
      messages.GetAvailableReactions.cons,
      messages.GetAvailableReactions
    );
    map.put(messages.SetDefaultReaction.cons, messages.SetDefaultReaction);
    map.put(messages.TranslateText.cons, messages.TranslateText);
    map.put(messages.GetUnreadReactions.cons, messages.GetUnreadReactions);
    map.put(messages.ReadReactions.cons, messages.ReadReactions);
    map.put(messages.SearchSentMedia.cons, messages.SearchSentMedia);
    map.put(messages.GetAttachMenuBots.cons, messages.GetAttachMenuBots);
    map.put(messages.GetAttachMenuBot.cons, messages.GetAttachMenuBot);
    map.put(
      messages.ToggleBotInAttachMenu.cons,
      messages.ToggleBotInAttachMenu
    );
    map.put(messages.RequestWebView.cons, messages.RequestWebView);
    map.put(messages.ProlongWebView.cons, messages.ProlongWebView);
    map.put(messages.RequestSimpleWebView.cons, messages.RequestSimpleWebView);
    map.put(
      messages.SendWebViewResultMessage.cons,
      messages.SendWebViewResultMessage
    );
    map.put(messages.SendWebViewData.cons, messages.SendWebViewData);
    map.put(messages.TranscribeAudio.cons, messages.TranscribeAudio);
    map.put(messages.RateTranscribedAudio.cons, messages.RateTranscribedAudio);
    map.put(
      messages.GetCustomEmojiDocuments.cons,
      messages.GetCustomEmojiDocuments
    );
    map.put(messages.GetEmojiStickers.cons, messages.GetEmojiStickers);
    map.put(
      messages.GetFeaturedEmojiStickers.cons,
      messages.GetFeaturedEmojiStickers
    );
    map.put(messages.ReportReaction.cons, messages.ReportReaction);
    map.put(messages.GetTopReactions.cons, messages.GetTopReactions);
    map.put(messages.GetRecentReactions.cons, messages.GetRecentReactions);
    map.put(messages.ClearRecentReactions.cons, messages.ClearRecentReactions);
    map.put(messages.GetExtendedMedia.cons, messages.GetExtendedMedia);
    map.put(messages.SetDefaultHistoryTTL.cons, messages.SetDefaultHistoryTTL);
    map.put(messages.GetDefaultHistoryTTL.cons, messages.GetDefaultHistoryTTL);
    map.put(messages.SendBotRequestedPeer.cons, messages.SendBotRequestedPeer);
    map.put(messages.GetEmojiGroups.cons, messages.GetEmojiGroups);
    map.put(messages.GetEmojiStatusGroups.cons, messages.GetEmojiStatusGroups);
    map.put(
      messages.GetEmojiProfilePhotoGroups.cons,
      messages.GetEmojiProfilePhotoGroups
    );
    map.put(messages.SearchCustomEmoji.cons, messages.SearchCustomEmoji);
    map.put(
      messages.TogglePeerTranslations.cons,
      messages.TogglePeerTranslations
    );
    map.put(messages.GetBotApp.cons, messages.GetBotApp);
    map.put(messages.RequestAppWebView.cons, messages.RequestAppWebView);
    map.put(messages.SetChatWallPaper.cons, messages.SetChatWallPaper);
    map.put(updates.GetState.cons, updates.GetState);
    map.put(updates.GetDifference.cons, updates.GetDifference);
    map.put(updates.GetChannelDifference.cons, updates.GetChannelDifference);
    map.put(photos.UpdateProfilePhoto.cons, photos.UpdateProfilePhoto);
    map.put(photos.UploadProfilePhoto.cons, photos.UploadProfilePhoto);
    map.put(photos.DeletePhotos.cons, photos.DeletePhotos);
    map.put(photos.GetUserPhotos.cons, photos.GetUserPhotos);
    map.put(
      photos.UploadContactProfilePhoto.cons,
      photos.UploadContactProfilePhoto
    );
    map.put(upload.SaveFilePart.cons, upload.SaveFilePart);
    map.put(upload.GetFile.cons, upload.GetFile);
    map.put(upload.SaveBigFilePart.cons, upload.SaveBigFilePart);
    map.put(upload.GetWebFile.cons, upload.GetWebFile);
    map.put(upload.GetCdnFile.cons, upload.GetCdnFile);
    map.put(upload.ReuploadCdnFile.cons, upload.ReuploadCdnFile);
    map.put(upload.GetCdnFileHashes.cons, upload.GetCdnFileHashes);
    map.put(upload.GetFileHashes.cons, upload.GetFileHashes);
    map.put(help.GetConfig.cons, help.GetConfig);
    map.put(help.GetNearestDc.cons, help.GetNearestDc);
    map.put(help.GetAppUpdate.cons, help.GetAppUpdate);
    map.put(help.GetInviteText.cons, help.GetInviteText);
    map.put(help.GetSupport.cons, help.GetSupport);
    map.put(help.GetAppChangelog.cons, help.GetAppChangelog);
    map.put(help.SetBotUpdatesStatus.cons, help.SetBotUpdatesStatus);
    map.put(help.GetCdnConfig.cons, help.GetCdnConfig);
    map.put(help.GetRecentMeUrls.cons, help.GetRecentMeUrls);
    map.put(help.GetTermsOfServiceUpdate.cons, help.GetTermsOfServiceUpdate);
    map.put(help.AcceptTermsOfService.cons, help.AcceptTermsOfService);
    map.put(help.GetDeepLinkInfo.cons, help.GetDeepLinkInfo);
    map.put(help.GetAppConfig.cons, help.GetAppConfig);
    map.put(help.SaveAppLog.cons, help.SaveAppLog);
    map.put(help.GetPassportConfig.cons, help.GetPassportConfig);
    map.put(help.GetSupportName.cons, help.GetSupportName);
    map.put(help.GetUserInfo.cons, help.GetUserInfo);
    map.put(help.EditUserInfo.cons, help.EditUserInfo);
    map.put(help.GetPromoData.cons, help.GetPromoData);
    map.put(help.HidePromoData.cons, help.HidePromoData);
    map.put(help.DismissSuggestion.cons, help.DismissSuggestion);
    map.put(help.GetCountriesList.cons, help.GetCountriesList);
    map.put(help.GetPremiumPromo.cons, help.GetPremiumPromo);
    map.put(channels.ReadHistory.cons, channels.ReadHistory);
    map.put(channels.DeleteMessages.cons, channels.DeleteMessages);
    map.put(channels.ReportSpam.cons, channels.ReportSpam);
    map.put(channels.GetMessages.cons, channels.GetMessages);
    map.put(channels.GetParticipants.cons, channels.GetParticipants);
    map.put(channels.GetParticipant.cons, channels.GetParticipant);
    map.put(channels.GetChannels.cons, channels.GetChannels);
    map.put(channels.GetFullChannel.cons, channels.GetFullChannel);
    map.put(channels.CreateChannel.cons, channels.CreateChannel);
    map.put(channels.EditAdmin.cons, channels.EditAdmin);
    map.put(channels.EditTitle.cons, channels.EditTitle);
    map.put(channels.EditPhoto.cons, channels.EditPhoto);
    map.put(channels.CheckUsername.cons, channels.CheckUsername);
    map.put(channels.UpdateUsername.cons, channels.UpdateUsername);
    map.put(channels.JoinChannel.cons, channels.JoinChannel);
    map.put(channels.LeaveChannel.cons, channels.LeaveChannel);
    map.put(channels.InviteToChannel.cons, channels.InviteToChannel);
    map.put(channels.DeleteChannel.cons, channels.DeleteChannel);
    map.put(channels.ExportMessageLink.cons, channels.ExportMessageLink);
    map.put(channels.ToggleSignatures.cons, channels.ToggleSignatures);
    map.put(
      channels.GetAdminedPublicChannels.cons,
      channels.GetAdminedPublicChannels
    );
    map.put(channels.EditBanned.cons, channels.EditBanned);
    map.put(channels.GetAdminLog.cons, channels.GetAdminLog);
    map.put(channels.SetStickers.cons, channels.SetStickers);
    map.put(channels.ReadMessageContents.cons, channels.ReadMessageContents);
    map.put(channels.DeleteHistory.cons, channels.DeleteHistory);
    map.put(
      channels.TogglePreHistoryHidden.cons,
      channels.TogglePreHistoryHidden
    );
    map.put(channels.GetLeftChannels.cons, channels.GetLeftChannels);
    map.put(
      channels.GetGroupsForDiscussion.cons,
      channels.GetGroupsForDiscussion
    );
    map.put(channels.SetDiscussionGroup.cons, channels.SetDiscussionGroup);
    map.put(channels.EditCreator.cons, channels.EditCreator);
    map.put(channels.EditLocation.cons, channels.EditLocation);
    map.put(channels.ToggleSlowMode.cons, channels.ToggleSlowMode);
    map.put(channels.GetInactiveChannels.cons, channels.GetInactiveChannels);
    map.put(channels.ConvertToGigagroup.cons, channels.ConvertToGigagroup);
    map.put(channels.ViewSponsoredMessage.cons, channels.ViewSponsoredMessage);
    map.put(channels.GetSponsoredMessages.cons, channels.GetSponsoredMessages);
    map.put(channels.GetSendAs.cons, channels.GetSendAs);
    map.put(
      channels.DeleteParticipantHistory.cons,
      channels.DeleteParticipantHistory
    );
    map.put(channels.ToggleJoinToSend.cons, channels.ToggleJoinToSend);
    map.put(channels.ToggleJoinRequest.cons, channels.ToggleJoinRequest);
    map.put(channels.ReorderUsernames.cons, channels.ReorderUsernames);
    map.put(channels.ToggleUsername.cons, channels.ToggleUsername);
    map.put(
      channels.DeactivateAllUsernames.cons,
      channels.DeactivateAllUsernames
    );
    map.put(channels.ToggleForum.cons, channels.ToggleForum);
    map.put(channels.CreateForumTopic.cons, channels.CreateForumTopic);
    map.put(channels.GetForumTopics.cons, channels.GetForumTopics);
    map.put(channels.GetForumTopicsByID.cons, channels.GetForumTopicsByID);
    map.put(channels.EditForumTopic.cons, channels.EditForumTopic);
    map.put(
      channels.UpdatePinnedForumTopic.cons,
      channels.UpdatePinnedForumTopic
    );
    map.put(channels.DeleteTopicHistory.cons, channels.DeleteTopicHistory);
    map.put(
      channels.ReorderPinnedForumTopics.cons,
      channels.ReorderPinnedForumTopics
    );
    map.put(channels.ToggleAntiSpam.cons, channels.ToggleAntiSpam);
    map.put(
      channels.ReportAntiSpamFalsePositive.cons,
      channels.ReportAntiSpamFalsePositive
    );
    map.put(
      channels.ToggleParticipantsHidden.cons,
      channels.ToggleParticipantsHidden
    );
    map.put(
      channels.ClickSponsoredMessage.cons,
      channels.ClickSponsoredMessage
    );
    map.put(bots.SendCustomRequest.cons, bots.SendCustomRequest);
    map.put(bots.AnswerWebhookJSONQuery.cons, bots.AnswerWebhookJSONQuery);
    map.put(bots.SetBotCommands.cons, bots.SetBotCommands);
    map.put(bots.ResetBotCommands.cons, bots.ResetBotCommands);
    map.put(bots.GetBotCommands.cons, bots.GetBotCommands);
    map.put(bots.SetBotMenuButton.cons, bots.SetBotMenuButton);
    map.put(bots.GetBotMenuButton.cons, bots.GetBotMenuButton);
    map.put(
      bots.SetBotBroadcastDefaultAdminRights.cons,
      bots.SetBotBroadcastDefaultAdminRights
    );
    map.put(
      bots.SetBotGroupDefaultAdminRights.cons,
      bots.SetBotGroupDefaultAdminRights
    );
    map.put(bots.SetBotInfo.cons, bots.SetBotInfo);
    map.put(bots.GetBotInfo.cons, bots.GetBotInfo);
    map.put(bots.ReorderUsernames.cons, bots.ReorderUsernames);
    map.put(bots.ToggleUsername.cons, bots.ToggleUsername);
    map.put(bots.CanSendMessage.cons, bots.CanSendMessage);
    map.put(bots.AllowSendMessage.cons, bots.AllowSendMessage);
    map.put(
      bots.InvokeWebViewCustomMethod.cons,
      bots.InvokeWebViewCustomMethod
    );
    map.put(payments.GetPaymentForm.cons, payments.GetPaymentForm);
    map.put(payments.GetPaymentReceipt.cons, payments.GetPaymentReceipt);
    map.put(
      payments.ValidateRequestedInfo.cons,
      payments.ValidateRequestedInfo
    );
    map.put(payments.SendPaymentForm.cons, payments.SendPaymentForm);
    map.put(payments.GetSavedInfo.cons, payments.GetSavedInfo);
    map.put(payments.ClearSavedInfo.cons, payments.ClearSavedInfo);
    map.put(payments.GetBankCardData.cons, payments.GetBankCardData);
    map.put(payments.ExportInvoice.cons, payments.ExportInvoice);
    map.put(
      payments.AssignAppStoreTransaction.cons,
      payments.AssignAppStoreTransaction
    );
    map.put(
      payments.AssignPlayMarketTransaction.cons,
      payments.AssignPlayMarketTransaction
    );
    map.put(payments.CanPurchasePremium.cons, payments.CanPurchasePremium);
    map.put(stickers.CreateStickerSet.cons, stickers.CreateStickerSet);
    map.put(stickers.RemoveStickerFromSet.cons, stickers.RemoveStickerFromSet);
    map.put(
      stickers.ChangeStickerPosition.cons,
      stickers.ChangeStickerPosition
    );
    map.put(stickers.AddStickerToSet.cons, stickers.AddStickerToSet);
    map.put(stickers.SetStickerSetThumb.cons, stickers.SetStickerSetThumb);
    map.put(stickers.CheckShortName.cons, stickers.CheckShortName);
    map.put(stickers.SuggestShortName.cons, stickers.SuggestShortName);
    map.put(stickers.ChangeSticker.cons, stickers.ChangeSticker);
    map.put(stickers.RenameStickerSet.cons, stickers.RenameStickerSet);
    map.put(stickers.DeleteStickerSet.cons, stickers.DeleteStickerSet);
    map.put(phone.GetCallConfig.cons, phone.GetCallConfig);
    map.put(phone.RequestCall.cons, phone.RequestCall);
    map.put(phone.AcceptCall.cons, phone.AcceptCall);
    map.put(phone.ConfirmCall.cons, phone.ConfirmCall);
    map.put(phone.ReceivedCall.cons, phone.ReceivedCall);
    map.put(phone.DiscardCall.cons, phone.DiscardCall);
    map.put(phone.SetCallRating.cons, phone.SetCallRating);
    map.put(phone.SaveCallDebug.cons, phone.SaveCallDebug);
    map.put(phone.SendSignalingData.cons, phone.SendSignalingData);
    map.put(phone.CreateGroupCall.cons, phone.CreateGroupCall);
    map.put(phone.JoinGroupCall.cons, phone.JoinGroupCall);
    map.put(phone.LeaveGroupCall.cons, phone.LeaveGroupCall);
    map.put(phone.InviteToGroupCall.cons, phone.InviteToGroupCall);
    map.put(phone.DiscardGroupCall.cons, phone.DiscardGroupCall);
    map.put(phone.ToggleGroupCallSettings.cons, phone.ToggleGroupCallSettings);
    map.put(phone.GetGroupCall.cons, phone.GetGroupCall);
    map.put(phone.GetGroupParticipants.cons, phone.GetGroupParticipants);
    map.put(phone.CheckGroupCall.cons, phone.CheckGroupCall);
    map.put(phone.ToggleGroupCallRecord.cons, phone.ToggleGroupCallRecord);
    map.put(
      phone.EditGroupCallParticipant.cons,
      phone.EditGroupCallParticipant
    );
    map.put(phone.EditGroupCallTitle.cons, phone.EditGroupCallTitle);
    map.put(phone.GetGroupCallJoinAs.cons, phone.GetGroupCallJoinAs);
    map.put(phone.ExportGroupCallInvite.cons, phone.ExportGroupCallInvite);
    map.put(
      phone.ToggleGroupCallStartSubscription.cons,
      phone.ToggleGroupCallStartSubscription
    );
    map.put(phone.StartScheduledGroupCall.cons, phone.StartScheduledGroupCall);
    map.put(
      phone.SaveDefaultGroupCallJoinAs.cons,
      phone.SaveDefaultGroupCallJoinAs
    );
    map.put(
      phone.JoinGroupCallPresentation.cons,
      phone.JoinGroupCallPresentation
    );
    map.put(
      phone.LeaveGroupCallPresentation.cons,
      phone.LeaveGroupCallPresentation
    );
    map.put(
      phone.GetGroupCallStreamChannels.cons,
      phone.GetGroupCallStreamChannels
    );
    map.put(
      phone.GetGroupCallStreamRtmpUrl.cons,
      phone.GetGroupCallStreamRtmpUrl
    );
    map.put(phone.SaveCallLog.cons, phone.SaveCallLog);
    map.put(langpack.GetLangPack.cons, langpack.GetLangPack);
    map.put(langpack.GetStrings.cons, langpack.GetStrings);
    map.put(langpack.GetDifference.cons, langpack.GetDifference);
    map.put(langpack.GetLanguages.cons, langpack.GetLanguages);
    map.put(langpack.GetLanguage.cons, langpack.GetLanguage);
    map.put(folders.EditPeerFolders.cons, folders.EditPeerFolders);
    map.put(stats.GetBroadcastStats.cons, stats.GetBroadcastStats);
    map.put(stats.LoadAsyncGraph.cons, stats.LoadAsyncGraph);
    map.put(stats.GetMegagroupStats.cons, stats.GetMegagroupStats);
    map.put(
      stats.GetMessagePublicForwards.cons,
      stats.GetMessagePublicForwards
    );
    map.put(stats.GetMessageStats.cons, stats.GetMessageStats);
    map.put(
      chatlists.ExportChatlistInvite.cons,
      chatlists.ExportChatlistInvite
    );
    map.put(
      chatlists.DeleteExportedInvite.cons,
      chatlists.DeleteExportedInvite
    );
    map.put(chatlists.EditExportedInvite.cons, chatlists.EditExportedInvite);
    map.put(chatlists.GetExportedInvites.cons, chatlists.GetExportedInvites);
    map.put(chatlists.CheckChatlistInvite.cons, chatlists.CheckChatlistInvite);
    map.put(chatlists.JoinChatlistInvite.cons, chatlists.JoinChatlistInvite);
    map.put(chatlists.GetChatlistUpdates.cons, chatlists.GetChatlistUpdates);
    map.put(chatlists.JoinChatlistUpdates.cons, chatlists.JoinChatlistUpdates);
    map.put(chatlists.HideChatlistUpdates.cons, chatlists.HideChatlistUpdates);
    map.put(
      chatlists.GetLeaveChatlistSuggestions.cons,
      chatlists.GetLeaveChatlistSuggestions
    );
    map.put(chatlists.LeaveChatlist.cons, chatlists.LeaveChatlist);
    map.put(stories.CanSendStory.cons, stories.CanSendStory);
    map.put(stories.SendStory.cons, stories.SendStory);
    map.put(stories.EditStory.cons, stories.EditStory);
    map.put(stories.DeleteStories.cons, stories.DeleteStories);
    map.put(stories.TogglePinned.cons, stories.TogglePinned);
    map.put(stories.GetAllStories.cons, stories.GetAllStories);
    map.put(stories.GetPinnedStories.cons, stories.GetPinnedStories);
    map.put(stories.GetStoriesArchive.cons, stories.GetStoriesArchive);
    map.put(stories.GetStoriesByID.cons, stories.GetStoriesByID);
    map.put(
      stories.ToggleAllStoriesHidden.cons,
      stories.ToggleAllStoriesHidden
    );
    map.put(stories.ReadStories.cons, stories.ReadStories);
    map.put(stories.IncrementStoryViews.cons, stories.IncrementStoryViews);
    map.put(stories.GetStoryViewsList.cons, stories.GetStoryViewsList);
    map.put(stories.GetStoriesViews.cons, stories.GetStoriesViews);
    map.put(stories.ExportStoryLink.cons, stories.ExportStoryLink);
    map.put(stories.Report.cons, stories.Report);
    map.put(stories.ActivateStealthMode.cons, stories.ActivateStealthMode);
    map.put(stories.SendReaction.cons, stories.SendReaction);
    map.put(stories.GetPeerStories.cons, stories.GetPeerStories);
    map.put(stories.GetAllReadPeerStories.cons, stories.GetAllReadPeerStories);
    map.put(stories.GetPeerMaxIDs.cons, stories.GetPeerMaxIDs);
    map.put(stories.GetChatsToSend.cons, stories.GetChatsToSend);
    map.put(
      stories.TogglePeerStoriesHidden.cons,
      stories.TogglePeerStoriesHidden
    );
    map.put(stories.GetBoostsStatus.cons, stories.GetBoostsStatus);
    map.put(stories.GetBoostersList.cons, stories.GetBoostersList);
    map.put(stories.CanApplyBoost.cons, stories.CanApplyBoost);
    map.put(stories.ApplyBoost.cons, stories.ApplyBoost);

    return map;
  })();
} // namespace API
