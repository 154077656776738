import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

interface Props {
    open: boolean;
    anchorEl: HTMLElement | null;
    onRequestClose: () => void;
    origin: Origin;

    children: ReactNode;
}

interface State {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;

    mountChildren: boolean;
}

export class Popover extends React.Component<Props, State> {
    private timeout: number | null = null;

    state: State = {
        mountChildren: false,
    };

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.open && !prevProps.open) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.setState({
                mountChildren: true,
            });
        } else if (!this.props.open && prevProps.open) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = window.setTimeout(() => {
                this.setState(
                    {
                        mountChildren: false,
                    },
                    () => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        clearTimeout(this.timeout!);
                    }
                );
            }, 350);
        }

        if (this.props.anchorEl) {
            let top: number | undefined = undefined;
            let right: number | undefined = undefined;
            let bottom: number | undefined = undefined;
            let left: number | undefined = undefined;

            if (this.props.origin[0] === "left") {
                left = this.props.anchorEl.getBoundingClientRect().left;
            } else if (this.props.origin[0] === "right") {
                right =
                    document.body.clientWidth -
                    this.props.anchorEl.getBoundingClientRect().right;
            }

            if (this.props.origin[1] === "top") {
                top = this.props.anchorEl.getBoundingClientRect().top;
            } else if (this.props.origin[1] === "bottom") {
                bottom =
                    document.body.clientHeight -
                    this.props.anchorEl.getBoundingClientRect().bottom;
            }

            this.setState({
                top: top,
                right: right,
                bottom: bottom,
                left: left,
            });
        }
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        return (
            nextProps.open !== this.props.open ||
            nextProps.anchorEl !== this.props.anchorEl ||
            nextProps.onRequestClose !== this.props.onRequestClose ||
            nextProps.origin !== this.props.origin ||
            nextState.top !== this.state.top ||
            nextState.right !== this.state.right ||
            nextState.bottom !== this.state.bottom ||
            nextState.left !== this.state.left ||
            nextState.mountChildren !== this.state.mountChildren
        );
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    render() {
        const popover = (
            <Paper
                style={{
                    position: "fixed",
                    zIndex: 2100,

                    pointerEvents: (!this.props.open && "none") || undefined,
                    opacity: this.props.open ? 1 : 0,
                    transform: this.props.open ? "scale(1,1)" : "scale(0,0)",
                    transformOrigin: this.props.origin.join(" "),
                    transition: "transform 200ms ease, opacity 150ms ease",

                    top: this.state.top,
                    right: this.state.right,
                    bottom: this.state.bottom,
                    left: this.state.left,
                }}
            >
                <div
                    style={{
                        transform: this.props.open ? "scaleX(1)" : "scaleX(0)",
                        transformOrigin: this.props.origin[0],
                        transition: "transform 200ms ease-out",
                    }}
                >
                    <div
                        style={{
                            transform: this.props.open
                                ? "scaleY(1)"
                                : "scaleY(0)",
                            transformOrigin: this.props.origin[1],
                            transition: "transform 300ms ease-out",
                        }}
                    >
                        {this.state.mountChildren && this.props.children}
                    </div>
                </div>
            </Paper>
        );

        return ReactDOM.createPortal(popover, document.body);
    }
}

type Origin = ["left" | "right", "top" | "bottom"];

const Paper = styled.div`
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    background: #fff;
    margin: 0 auto;
    border-radius: 6px;
    box-sizing: border-box;
    overflow: hidden;
`;
