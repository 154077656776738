import moment from "moment";
import { API } from "../Codegen/API/APISchema";
import styled from "styled-components";
import { Peer, PeerChannel, PeerChat, PeerUser } from "./Peer";
import { ReactNode, useEffect, useState } from "react";

export function ReadableStatus(props: { of: Peer }) {
    let element: ReactNode;
    if (props.of instanceof PeerUser) {
        const status = readableStatus({ user: props.of.user });
        element = <>{status}</>;
    } else if (props.of instanceof PeerChat) {
        element = <>{props.of.chat.participantsCount.value} members</>;
    } else if (props.of instanceof PeerChannel) {
        const count = new Intl.NumberFormat().format(
            props.of.channel.participantsCount?.value || 0
        );
        element = <>{count} subscribers</>;
    } else {
        throw new Error();
    }

    return element;
}

const Online = styled.span`
    color: rgba(61, 129, 161, 1);
`;

export function readableStatus(props: { user: API.User }) {
    if (props.user.id.value.toNumber() === 777000) {
        return "service notifications";
    } else if (props.user.self) {
        return "you";
    } else if (props.user.bot) {
        return "bot";
    }

    if (props.user.status instanceof API.UserStatusOnline) {
        const now = moment();
        if (now.isBefore(props.user.status.expires.value * 1000)) {
            return <Online>online</Online>;
        } else {
            return statusOffline({
                wasOnline: props.user.status.expires.value,
            });
        }
    } else if (props.user.status instanceof API.UserStatusOffline) {
        return statusOffline({ wasOnline: props.user.status.wasOnline.value });
    } else if (props.user.status instanceof API.UserStatusRecently) {
        return "last seen recently";
    } else if (props.user.status instanceof API.UserStatusLastWeek) {
        return "last seen within a week";
    } else if (props.user.status instanceof API.UserStatusLastMonth) {
        return "last seen within a month";
    }

    return "last seen a long time ago";
}

const statusOffline = (props: { wasOnline: number }) => {
    const [status, setStatus] = useState(
        readableOfflineStatus(props.wasOnline)
    );

    useEffect(() => {
        const interval = setInterval(
            () => setStatus(readableOfflineStatus(props.wasOnline)),
            1000
        );
        return () => {
            clearInterval(interval);
        };
    }, [props.wasOnline]);

    return status;
};

const readableOfflineStatus = (wasOnline: number): string => {
    const wasOnlineMoment = moment.unix(wasOnline);
    const minutesDiff = moment().diff(wasOnlineMoment, "minutes");
    if (minutesDiff === 0) {
        return "last seen just now";
    } else if (minutesDiff === 1) {
        return "last seen one minute ago";
    } else if (minutesDiff <= 59) {
        return `last seen ${minutesDiff} minutes ago`;
    } else {
        const daysDiff = moment().diff(wasOnlineMoment, "days");
        if (daysDiff === 0) {
            const hoursDiff = moment().diff(wasOnlineMoment, "hours");
            if (hoursDiff === 1) {
                return "last seen one hour ago";
            } else {
                return `last seen ${hoursDiff} hours ago`;
            }
        } else {
            return `last seen ${wasOnlineMoment.format("L")}`;
        }
    }
};
