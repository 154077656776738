import moment from "moment";
import { ConvenienceMessage } from "./Message";
import { Peer, PeerChannel, PeerChat, PeerUser } from "./Peer";
import { API } from "../Codegen/API/APISchema";

export class ConvenienceChat {
    constructor(
        readonly peer: Peer | undefined,
        readonly readInboxMaxId: number,
        readonly readOutboxMaxId: number,
        readonly unreadCount: number,
        readonly topMessage: ConvenienceMessage
    ) {}

    get id() {
        if (this.peer instanceof PeerUser) {
            return this.peer.userId;
        } else if (this.peer instanceof PeerChat) {
            return this.peer.chatId;
        } else if (this.peer instanceof PeerChannel) {
            return this.peer.channelId;
        }

        return 0;
    }

    get title(): string {
        let title;
        if (this.peer instanceof PeerUser) {
            if (this.peer.user?.deleted) {
                return NAME_DELETED;
            }
            title = [
                this.peer.user?.firstName?.string,
                this.peer.user?.lastName?.string,
            ]
                .join(" ")
                .trim();
        } else if (this.peer instanceof PeerChat) {
            title = this.peer.chat?.title.string;
        } else if (this.peer instanceof PeerChannel) {
            title = this.peer.channel?.title.string;
        }

        return title || "";
    }

    get deleted(): boolean {
        if (this.peer instanceof PeerUser) {
            return !!this.peer.user?.deleted;
        }

        return false;
    }

    /**
     * undefined === inbound
     * true === seen
     * false === sent
     */
    get topRead(): boolean | undefined {
        if (!this.topMessage.out || this.deleted) {
            return undefined;
        }

        return this.topMessage.id === this.readOutboxMaxId;
    }

    get topReadableDate(): string {
        const timestamp = this.topMessage.date;

        const now = moment();
        const date = moment.unix(timestamp);
        const duration = moment.duration(now.diff(date));

        if (duration.asHours() <= 6 || now.isSame(date, "day")) {
            return date.format("LT");
        } else if (duration.asDays() <= 7) {
            return date.format("ddd");
        } else if (duration.asMonths() <= 3 || now.isSame(date, "year")) {
            return date.format("D MMM");
        } else {
            return date.format("L");
        }
    }

    peerEquals(to: API.PeerType | undefined) {
        if (this.peer instanceof PeerUser && to instanceof API.PeerUser) {
            return this.peer.userId === to.userId.value.toNumber();
        } else if (
            this.peer instanceof PeerChat &&
            to instanceof API.PeerChat
        ) {
            return this.peer.chatId === to.chatId.value.toNumber();
        } else if (
            this.peer instanceof PeerChannel &&
            to instanceof API.PeerChannel
        ) {
            return this.peer.channelId === to.channelId.value.toNumber();
        }

        return false;
    }

    setPeer(peer: Peer) {
        return new ConvenienceChat(
            peer,
            this.readInboxMaxId,
            this.readOutboxMaxId,
            this.unreadCount,
            this.topMessage
        );
    }

    setReadInboxMaxId(id: number): ConvenienceChat {
        let unreadCount = this.unreadCount;
        if (id >= this.topMessage.id) {
            unreadCount = 0;
        }

        return new ConvenienceChat(
            this.peer,
            id,
            this.readOutboxMaxId,
            unreadCount,
            this.topMessage
        );
    }

    setReadOutboxMaxId(id: number): ConvenienceChat {
        return new ConvenienceChat(
            this.peer,
            this.readInboxMaxId,
            id,
            this.unreadCount,
            this.topMessage
        );
    }

    setTopMessage(message: ConvenienceMessage): ConvenienceChat {
        let unreadCount = this.unreadCount;
        if (!message.out && this.readInboxMaxId < message.id) {
            unreadCount += 1;
        } else if (this.readInboxMaxId >= message.id) {
            unreadCount = 0;
        } else if (this.readOutboxMaxId >= message.id) {
            unreadCount = 0;
        }

        return new ConvenienceChat(
            this.peer,
            this.readInboxMaxId,
            this.readOutboxMaxId,
            unreadCount,
            message
        );
    }
}

const NAME_DELETED = "Deleted Account";
