import React, { memo } from "react";
import styled, { css } from "styled-components";
import { FixedSizeList, areEqual } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { tg } from "../../App";
import { TG } from "../../tg/TG";
import { ChatsListItem } from "./ChatsListItem";
import { Subject, Subscription } from "rxjs";
import { Update } from "../../tg/Updates/Update";
import {
    Peer,
    PeerChannel,
    PeerChat,
    PeerUser,
} from "../../tg/Convenience/Peer";
import { API } from "../../tg/Codegen/API/APISchema";
import { ImmutableArray } from "../../misc/ImmutableArray";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
    onChat: (chat: TG.Chat) => void;
    onTypings: (typings: ImmutableArray<Update.UserTyping>) => void;
}

interface State {
    chats: ImmutableArray<TG.Chat>;
    selected: Peer | undefined;
    typings: ImmutableArray<Update.UserTyping>;
}

export class ChatsList extends React.Component<Props, State> {
    state: State = {
        chats: new ImmutableArray(),
        selected: undefined,
        typings: new ImmutableArray(),
    };

    updateSignal$ = new Subject<boolean>();
    loading = false;
    full = false;
    signalSubscription: Subscription | undefined;
    updatesSubscription: Subscription | undefined;
    typingsInterval: number | undefined;

    loadChats(offset?: TG.Chat) {
        if (this.loading || this.full) return;
        this.loading = true;

        tg.getChatsList(50, offset).subscribe((chats) => {
            this.setState({
                chats: this.state.chats.push(...chats),
            });

            this.loading = false;
            this.full = chats.length === 0;
        });
    }

    handleUpdate(update: Update) {
        if (update instanceof Update.NewMessage) {
            const index = this.state.chats.findIndex((chat) =>
                chat.peerEquals(update.message.peer)
            );
            if (index === -1) {
                return;
            }
            const chat = this.state.chats.at(index) as TG.Chat;
            if (chat.topMessage.id >= update.message.id) {
                return;
            }

            const list = this.state.chats
                .set(index, chat.setTopMessage(update.message))
                .sorted((a, b) => a.topMessage.date - b.topMessage.date)
                .reversed();

            this.setState({
                chats: list,
            });
        } else if (update instanceof Update.User) {
            const index = this.state.chats.findIndex(
                (chat) =>
                    chat.peer instanceof PeerUser &&
                    chat.peer.userId === update.user.id.value.toNumber()
            );
            if (index === -1) {
                return;
            }
            const chat = this.state.chats.at(index) as TG.Chat;
            const list = this.state.chats.set(
                index,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                chat.setPeer(chat.peer!.set(update.user))
            );

            this.setState({
                chats: list,
            });
        } else if (update instanceof Update.Chat) {
            const index = this.state.chats.findIndex(
                (chat) =>
                    chat.peer instanceof PeerChat &&
                    chat.peer.chatId === update.chat.id.value.toNumber()
            );
            if (index === -1) {
                return;
            }
            const chat = this.state.chats.at(index) as TG.Chat;
            const list = this.state.chats.set(
                index,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                chat.setPeer(chat.peer!.set(update.chat as API.Chat))
            );

            this.setState({
                chats: list,
            });
        } else if (update instanceof Update.Channel) {
            const index = this.state.chats.findIndex(
                (chat) =>
                    chat.peer instanceof PeerChannel &&
                    chat.peer.channelId === update.channel.id.value.toNumber()
            );
            if (index === -1) {
                return;
            }
            const chat = this.state.chats.at(index) as TG.Chat;
            const list = this.state.chats.set(
                index,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                chat.setPeer(chat.peer!.set(update.channel as API.Channel))
            );

            this.setState({
                chats: list,
            });
        } else if (update instanceof Update.ReadHistoryInbox) {
            const index = this.state.chats.findIndex((chat) =>
                chat.peerEquals(update.peer)
            );
            if (index === -1) {
                return;
            }
            const chat = this.state.chats.at(index) as TG.Chat;
            const list = this.state.chats.set(
                index,
                chat.setReadInboxMaxId(update.maxId)
            );

            this.setState({
                chats: list,
            });
        } else if (update instanceof Update.ReadHistoryOutbox) {
            const index = this.state.chats.findIndex((chat) =>
                chat.peerEquals(update.peer)
            );
            if (index === -1) {
                return;
            }

            const chat = this.state.chats.at(index) as TG.Chat;
            const list = this.state.chats.set(
                index,
                chat.setReadOutboxMaxId(update.maxId)
            );

            this.setState({
                chats: list,
            });
        } else if (update instanceof Update.UserTyping) {
            const typings = this.state.typings.copy();
            // Remove existings typings for the same peer and user
            const index = typings.findIndex(
                (value) =>
                    value.peer.equals(update.peer) &&
                    value.user.id.equals(update.user.id)
            );
            const filteredTypings = typings.filter((_, idx) => idx !== index);

            this.setState({
                typings: filteredTypings.push(update),
            });
        }
    }

    onRowClick(index: number) {
        const item = this.state.chats.at(index) as TG.Chat;
        const selected = item.peer as Peer;
        this.setState({
            selected: selected,
        });
    }

    isItemSelected(index: number) {
        const item = this.state.chats.at(index)?.peer;
        const selected = this.state.selected;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return item?.equals(selected!) as boolean;
    }

    selectedIndex() {
        const selected = this.state.selected;

        return this.state.chats.findIndex((chat) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return chat.peer?.equals(selected!);
        });
    }

    updateTypings() {
        const now = performance.now();
        const typings = this.state.typings.filter(
            (value) => now <= value.expires
        );
        if (typings.length != this.state.typings.length) {
            this.setState({
                typings: typings,
            });
        }
    }

    componentDidMount() {
        this.loadChats();
        this.signalSubscription = this.updateSignal$.subscribe(() => {
            this.loadChats(this.state.chats.at(-1));
        });
        this.updatesSubscription = tg.updates.subscribe((update) =>
            this.handleUpdate(update)
        );

        this.typingsInterval = setInterval(
            () => this.updateTypings(),
            1000
        ) as unknown as number;
    }

    componentWillUnmount() {
        this.signalSubscription?.unsubscribe();
        this.updatesSubscription?.unsubscribe();

        clearInterval(this.typingsInterval);
    }

    shouldComponentUpdate(
        nextProps: Readonly<Props>,
        nextState: Readonly<State>
    ) {
        if (nextState.chats !== this.state.chats) {
            return true;
        }
        if (nextState.selected !== this.state.selected) {
            return true;
        }
        if (nextState.typings !== this.state.typings) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (
            (this.state.selected !== prevState.selected &&
                this.state.selected) ||
            (this.state.chats !== prevState.chats && this.state.selected)
        ) {
            const peer = this.state.selected;
            const chat = this.state.chats
                .filter((chat) => chat.peer?.equals(peer) ?? false)
                .at(0) as TG.Chat;
            this.props.onChat(chat);
        }

        if (this.state.typings !== prevState.typings && this.state.selected) {
            const typings = this.state.typings.filter((value) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                value.peer.equals(this.state.selected!)
            );

            this.props.onTypings(typings);
        }
    }

    render() {
        return (
            <RootPaper>
                <AutoSizer>
                    {(params: { width: number; height: number }) => (
                        <FixedSizeList
                            width={params.width}
                            height={params.height}
                            overscanCount={1}
                            itemCount={this.state.chats.length}
                            itemSize={90}
                            itemKey={(index, data) =>
                                itemKey(index, data && data.topMessage)
                            }
                        >
                            {(params: {
                                index: number;
                                style: React.CSSProperties;
                            }) => (
                                <FixedRow
                                    index={params.index}
                                    style={params.style}
                                    chats={this.state.chats}
                                    updateSignal$={this.updateSignal$}
                                    onClick={this.onRowClick.bind(this)}
                                    selected={{
                                        index: this.selectedIndex(),
                                        is: this.isItemSelected(params.index),
                                    }}
                                    last={
                                        params.index ===
                                        this.state.chats.length - 1
                                    }
                                    typings={this.state.typings}
                                />
                            )}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </RootPaper>
        );
    }
}

type FixedSizeRowType = {
    index: number;
    style: React.CSSProperties;
    chats: ImmutableArray<TG.Chat>;
    updateSignal$: Subject<boolean>;
    onClick: (index: number) => void;
    selected: { index: number; is: boolean };
    typings: ImmutableArray<Update.UserTyping>;
};

const FixedRow = memo((props: FixedSizeRowType & { last: boolean }) => {
    if (props.index >= props.chats.length - 5) {
        props.updateSignal$.next(true);
    }

    return (
        <Row
            style={props.style}
            onClick={() => props.onClick(props.index)}
            $first={props.index === 0}
            $last={props.last}
        >
            <ListItem
                chat={props.chats.at(props.index) as TG.Chat}
                last={props.last}
                selected={props.selected.is}
                aboveSelected={props.selected.index === props.index + 1}
                typings={props.typings}
            />
        </Row>
    );
}, areEqual);

type ListItemProps = {
    chat: TG.Chat;
    last: boolean;
    selected: boolean;
    aboveSelected: boolean;
    typings: ImmutableArray<Update.UserTyping>;
};

const ListItem = memo((props: ListItemProps) => {
    const typings = props.typings.filter((value) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value.peer.equals(props.chat.peer!)
    );

    return (
        <ChatsListItem
            chat={props.chat}
            last={props.last}
            selected={props.selected}
            aboveSelected={props.aboveSelected}
            typings={typings}
        />
    );
}, areEqual);

const itemKey = (index: number, message?: TG.Message): number => {
    return message?.id || index;
};

const RootPaper = (props: { children: React.ReactNode }) => {
    return (
        <Root>
            <Paper>{props.children}</Paper>
        </Root>
    );
};

const Root = styled.div`
    width: 320px;
    height: 100vh;
    padding: 38px 0;
    box-sizing: border-box;
`;

const Paper = styled.div`
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
`;

const Row = styled.div<{ $first: boolean; $last: boolean }>`
    ${(props) =>
        props.$first &&
        css`
            padding-top: 4px;
        `}

    ${(props) =>
        props.$last &&
        css`
            padding-bottom: 4px;
        `}
`;
