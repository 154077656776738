import Long from "long";
import { API } from "../Codegen/API/APISchema";
import { TLLong } from "../TL/Types/TLLong";
import { Hashable } from "../DataStructures/HashMap/Hashable";

export class PeerUser implements Hashable {
    constructor(readonly userId: number, readonly user: API.User) {}

    get hashValue(): number {
        return this.userId;
    }

    equals(to: Peer): boolean {
        if (to instanceof PeerUser) {
            return this.userId === to.userId;
        }
        return false;
    }

    set(peer: API.User | API.Chat | API.Channel) {
        if (peer instanceof API.User) {
            return new PeerUser(this.userId, peer);
        } else {
            throw new Error();
        }
    }
}

export class PeerChat {
    constructor(readonly chatId: number, readonly chat: API.Chat) {}

    get hashValue(): number {
        return this.chatId;
    }

    equals(to: Peer): boolean {
        if (to instanceof PeerChat) {
            return this.chatId === to.chatId;
        }
        return false;
    }

    set(peer: API.User | API.Chat | API.Channel) {
        if (peer instanceof API.Chat) {
            return new PeerChat(this.chatId, peer);
        } else {
            throw new Error();
        }
    }
}

export class PeerChannel {
    constructor(readonly channelId: number, readonly channel: API.Channel) {}

    get hashValue(): number {
        return this.channelId;
    }

    equals(to: Peer): boolean {
        if (to instanceof PeerChannel) {
            return this.channelId === to.channelId;
        }
        return false;
    }

    set(peer: API.User | API.Chat | API.Channel) {
        if (peer instanceof API.Channel) {
            return new PeerChannel(this.channelId, peer);
        } else {
            throw new Error();
        }
    }
}

export type Peer = PeerUser | PeerChat | PeerChannel;

export function conveniencePeerFor(
    apiPeer?: API.PeerType,
    kind?: API.User | API.Chat | API.Channel
): Peer | undefined {
    let peer: Peer | undefined;
    if (apiPeer instanceof API.PeerUser && kind instanceof API.User) {
        peer = new PeerUser(apiPeer.userId.value.toNumber(), kind);
    } else if (apiPeer instanceof API.PeerChat && kind instanceof API.Chat) {
        peer = new PeerChat(apiPeer.chatId.value.toNumber(), kind);
    } else if (
        apiPeer instanceof API.PeerChannel &&
        kind instanceof API.Channel
    ) {
        peer = new PeerChannel(apiPeer.channelId.value.toNumber(), kind);
    } else {
        peer = undefined;
    }

    return peer;
}

export function conveniencePeerEqualsApiPeer(peer: Peer, api: API.PeerType) {
    if (api instanceof API.PeerUser && peer instanceof PeerUser) {
        return api.userId.equals(peer.user.id);
    } else if (api instanceof API.PeerChat && peer instanceof PeerChat) {
        return api.chatId.equals(peer.chat.id);
    } else if (api instanceof API.PeerChannel && peer instanceof PeerChannel) {
        return api.channelId.equals(peer.channel.id);
    }

    return false;
}

export function apiPeerForConvenience(peer: Peer) {
    let apiPeer: API.PeerUser | API.PeerChat | API.PeerChannel;
    if (peer instanceof PeerUser) {
        apiPeer = new API.PeerUser(new TLLong(Long.fromNumber(peer.userId)));
    } else if (peer instanceof PeerChat) {
        apiPeer = new API.PeerChat(new TLLong(Long.fromNumber(peer.chatId)));
    } else if (peer instanceof PeerChannel) {
        apiPeer = new API.PeerChannel(
            new TLLong(Long.fromNumber(peer.channelId))
        );
    } else {
        throw new Error();
    }

    return apiPeer;
}

export function apiInputPeerForConvenience(peer: Peer) {
    let apiPeer: API.InputPeerUser | API.InputPeerChat | API.InputPeerChannel;
    if (peer instanceof PeerUser) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        apiPeer = new API.InputPeerUser(peer.user!.id, peer.user!.accessHash!);
    } else if (peer instanceof PeerChat) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        apiPeer = new API.InputPeerChat(peer.chat!.id);
    } else if (peer instanceof PeerChannel) {
        apiPeer = new API.InputPeerChannel(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            peer.channel!.id,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            peer.channel!.accessHash!
        );
    } else {
        throw new Error();
    }

    return apiPeer;
}
