import Long from "long";
import { API } from "../Codegen/API/APISchema";
import { DataCenter } from "../Session/DataCenter";
import { TLLong } from "../TL/Types/TLLong";
import { TLInt } from "../TL/Types/TLInt";
import { Observable, Subscriber } from "rxjs";

export class FileDownloader {
    static readonly chunkSize = 512 * 1024;
    private offset = 0;

    constructor(
        private readonly location: API.InputFileLocationType,
        private readonly dc: DataCenter,
        public subscribers: Array<Subscriber<API.upload.File>>
    ) {}

    download() {
        this.downloadChunk().subscribe((file) => {
            if (file.type instanceof API.storage.FilePartial) {
                const size = file.bytes.bytes.byteLength;
                if (size === FileDownloader.chunkSize) {
                    this.offset = file.bytes.bytes.byteLength;
                    this.download();
                }
            }

            this.subscribers.forEach((subscriber) => {
                subscriber.next(file);
            });
        });
    }

    private downloadChunk() {
        const getFile = new API.upload.GetFile(
            undefined,
            undefined,
            this.location,
            new TLLong(Long.fromNumber(this.offset)),
            new TLInt(FileDownloader.chunkSize)
        );

        return this.dc.call(getFile) as Observable<API.upload.File>;
    }
}
