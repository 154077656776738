import React from "react";
import { ImmutableArray } from "../../misc/ImmutableArray";
import { Update } from "../../tg/Updates/Update";
import styled from "styled-components";
import { ChatHeader } from "./ChatHeader";
import { TG } from "../../tg/TG";
import { ChatMessagesList } from "./ChatMessagesList";
import { ChatFooter } from "./ChatFooter";

interface Props {
    chat: TG.Chat | undefined;
    typings: ImmutableArray<Update.UserTyping> | undefined;
}

interface State {
    paddingBottom: number;
}

export class Chat extends React.Component<Props, State> {
    state: State = {
        paddingBottom: 0,
    };

    shouldComponentUpdate(
        nextProps: Readonly<Props>,
        nextState: Readonly<State>
    ): boolean {
        if (nextProps.chat !== this.props.chat) {
            return true;
        }
        if (nextProps.typings !== this.props.typings) {
            return true;
        }
        if (nextState.paddingBottom !== this.state.paddingBottom) {
            return true;
        }

        return false;
    }

    render() {
        return (
            <Root>
                <ChatHeader
                    chat={this.props.chat}
                    typings={this.props.typings}
                ></ChatHeader>
                <ChatMessageRoot>
                    <ChatMessagesList
                        peer={this.props.chat?.peer}
                        paddingBottom={this.state.paddingBottom}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        chat={this.props.chat!}
                    ></ChatMessagesList>
                </ChatMessageRoot>
                <ChatFooter
                    onHeightChange={onFooterHeightChange.bind(this, this)}
                ></ChatFooter>
            </Root>
        );
    }
}

const onFooterHeightChange = (
    component: React.Component<Props, State>,
    height?: number
) => {
    component.setState({
        paddingBottom: height || 0,
    });
};

const Root = styled.div`
    width: 640px;
    padding: 38px 19px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
`;

const ChatMessageRoot = styled.div`
    width: calc(100% - 38px);
    height: calc(100% - 76px);
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    position: absolute;
`;
