import styled, { keyframes } from "styled-components";

export function DotsSpinner() {
    return (
        <Root>
            <Dot></Dot>
            <SecondDot></SecondDot>
            <ThirdDot></ThirdDot>
        </Root>
    );
}

const Root = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 34px;
`;

const typingAnimation = keyframes`
    0% {
        opacity: 0.1;
        transform: scale(0.67);
    }
    20% {
        opacity: 1.0;
        transform: scale(1);
    }
    100% {
        opacity: 0.1;
        transform: scale(0.67);
    }
`;

const Dot = styled.div`
    animation-name: ${typingAnimation};
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-timing-function: steps(16, end);

    width: 8px;
    height: 8px;
    background: #fff;
    margin-right: 5px;
    border-radius: 50%;
    float: left;

    &:last-child {
        margin-right: 0;
    }
`;

const SecondDot = styled(Dot)`
    animation-delay: 150ms;
`;

const ThirdDot = styled(Dot)`
    animation-delay: 300ms;
`;
