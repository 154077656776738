import styled, { css } from "styled-components";
import { API } from "../../../../tg/Codegen/API/APISchema";
import { ReactionIcon } from "./ReactionIcon";
import { Avatar } from "../../../ui/Avatar";
import { useEffect, useState } from "react";
import { tg } from "../../../../App";
import { Peer, PeerUser } from "../../../../tg/Convenience/Peer";
import { TLLong } from "../../../../tg/TL/Types/TLLong";
import { Subscription } from "rxjs";

export const Reactions = (props: {
    reactions: API.MessageReactions;
    peer: Peer;
    id: number;
}) => {
    const [reactions, setReactions] = useState(props.reactions);
    const [myUserdId, setMyUserId] = useState<number | undefined>();

    useEffect(() => {
        tg.myUserId.subscribe((id) => setMyUserId(id));
    }, []);

    useEffect(() => {
        let subscription: Subscription | undefined;
        const interval = setInterval(() => {
            subscription = tg
                .getMessagesReactions(props.peer, props.id)
                .subscribe((result) => {
                    if (result) {
                        setReactions(result);
                    }
                });
        }, 20000);

        return () => {
            subscription?.unsubscribe();
            clearInterval(interval);
        };
    });

    const groups = groupByReaction(reactions, myUserdId);
    return (
        <Root>
            {groups.map((reaction, index) => {
                return (
                    <Reaction
                        key={index}
                        emoji={reaction.emoji}
                        userId={reaction.recentUserId}
                        count={reaction.count}
                        my={reaction.my}
                    ></Reaction>
                );
            })}
        </Root>
    );
};

const groupByReaction = (
    reactions: API.MessageReactions,
    myUserId: number | undefined
) => {
    const groups = reactions.results.items.map((count) => {
        const recentReaction = reactions.recentReactions?.items.find(
            (item) =>
                (item.reaction as API.ReactionEmoji).emoticon.string ===
                (count.reaction as API.ReactionEmoji).emoticon.string
        );
        let recentUserId;
        if (recentReaction?.peerId instanceof API.PeerUser) {
            recentUserId = recentReaction.peerId.userId;
        }

        return {
            emoji: (count.reaction as API.ReactionEmoji).emoticon.string,
            count: count.count.value,
            recentUserId: count.count.value > 1 ? undefined : recentUserId,
            my:
                typeof myUserId !== "undefined" &&
                recentUserId?.value.toNumber() === myUserId,
        };
    });

    // Set everyting to count if one reaction is more than by one user
    const one = groups.find((item) => item.count > 1);
    groups.forEach((item) => {
        if (one) {
            item.recentUserId = undefined;
        }
    });

    return groups;
};

const formatCount = (count: number) => {
    const parts = new Intl.NumberFormat()
        .formatToParts(parseInt(count.toFixed(1)))
        .map((item) => item.value);
    let postfix = "";

    if (parts.length === 3) {
        parts[2] = parts[2].charAt(0);
        postfix = "K";
    }

    return parts.join("") + postfix;
};

const Reaction = (props: {
    emoji: string;
    userId?: TLLong;
    count: number;
    my: boolean;
}) => {
    const [image, setImage] = useState<Uint8Array | undefined>();
    const [user, setUser] = useState<API.User | undefined>();

    useEffect(() => {
        if (!props.userId) {
            return;
        }

        tg.getUser(props.userId).subscribe((user) => {
            if (user instanceof API.User) {
                setUser(user);
            }
        });
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }

        if (user.photo instanceof API.UserProfilePhoto) {
            tg.getPeerPhoto(
                new PeerUser(user.id.value.toNumber(), user),
                user.photo.photoId,
                user.photo.dcId
            ).subscribe((photo) => {
                setImage(photo.bytes.bytes);
            });
        }
    }, [user]);

    let element;
    if (user) {
        const name = [user?.firstName?.string, user?.lastName?.string]
            .join(" ")
            .trim();

        element = (
            <Avatar
                id={user?.id.value.toNumber() ?? 0}
                name={name}
                deleted={!!user?.deleted}
                user={true}
                size={20}
                image={image}
            ></Avatar>
        );
    } else {
        element = (
            <ReactionCount $my={props.my}>
                {formatCount(props.count)}
            </ReactionCount>
        );
    }

    return (
        <ReactionRoot $my={props.my}>
            <ReactionIcon emoji={props.emoji}></ReactionIcon>
            {element}
        </ReactionRoot>
    );
};

const Root = styled.div`
    display: flex;
    margin-top: 5px;
    margin-bottom: 3px;
    min-height: 28px;
    flex-wrap: wrap;
`;

const ReactionRoot = styled.div<{ $my: boolean }>`
    display: flex;
    height: 28px;
    border-radius: 14px;
    background: rgba(61, 129, 161, 0.2);
    padding: 4px 8px;
    box-sizing: border-box;
    margin-right: 5px;
    margin-top: 5px;
    align-items: flex-end;

    &:last-child {
        margin-right: 0;
    }

    ${(props) =>
        props.$my &&
        css`
            background: rgba(61, 129, 161, 0.7);
        `}
`;

const ReactionCount = styled.div<{ $my: boolean }>`
    height: 20px;
    min-width: 20px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
    color: rgba(41, 86, 107, 1);

    ${(props) =>
        props.$my &&
        css`
            color: #fff;
        `}
`;
