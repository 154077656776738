import { Observable } from "rxjs";
import { API } from "../Codegen/API/APISchema";
import { HashMap } from "../DataStructures/HashMap/HashMap";
import { TLInt } from "../TL/Types/TLInt";
import { TLObject } from "../TL/Interfaces/TLObject";
import { ByteStream } from "../DataStructures/ByteStream";

export class PasswordManager {
    private readonly worker = new Worker(
        new URL("./PasswordWorker.ts", import.meta.url)
    );

    private reqId = 0;
    private requests = new HashMap<
        TLInt,
        (result: API.InputCheckPasswordSRP) => void
    >();

    constructor() {
        const messageEventListener = (event: MessageEvent) => {
            switch (event.data.type) {
                case "passwordCheckReady":
                    {
                        const params = TLObject.deserialized(
                            new ByteStream(event.data.obj)
                        ) as API.InputCheckPasswordSRP;

                        const id = event.data.id;
                        const onResult = this.requests.get(new TLInt(id));
                        this.requests.remove(new TLInt(id));
                        if (onResult) {
                            onResult(params);
                        }
                    }
                    break;
            }
        };

        this.worker.addEventListener("message", messageEventListener);
    }

    checkPassword(password: API.account.Password, input: string) {
        return new Observable<API.InputCheckPasswordSRP>((subscriber) => {
            const onResult = (result: API.InputCheckPasswordSRP) => {
                subscriber.next(result);
            };

            const id = this.reqId++;
            this.requests.put(new TLInt(id), onResult);
            this.worker.postMessage({
                type: "checkPassword",
                obj: {
                    password: password.serialized(),
                    input: input,
                },
                id: id,
            });

            return () => subscriber.unsubscribe();
        });
    }
}
