import { API } from "../tg/Codegen/API/APISchema";

export const getPhotoSize = (
    sizes: Array<API.PhotoSizeType>,
    view: { width: number; height: number }
) => {
    const realView = {
        width: devicePixelRatio * view.width,
        height: devicePixelRatio * view.height,
    };

    let size = sizes.find((size) => {
        if (!("w" in size && "h" in size)) {
            return;
        }

        return (
            size.w.value >= realView.width || size.h.value >= realView.height
        );
    });

    if (typeof size === "undefined") {
        size = sizes.find((size) => size instanceof API.PhotoSizeProgressive);
    }

    if (typeof size === "undefined") {
        return sizes.at(0);
    }

    return size;
};
