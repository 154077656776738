import { useEffect, useState } from "react";
import { MessageType } from "../../tg/Convenience/Message";
import { TG } from "../../tg/TG";
import { photoMessage } from "./MessageTypes/PhotoMessage";
import { stickerMessage } from "./MessageTypes/StickerMessage";
import { textMessage } from "./MessageTypes/TextMessage";
import { API } from "../../tg/Codegen/API/APISchema";
import { tg } from "../../App";
import { Avatar } from "../ui/Avatar";
import styled, { css } from "styled-components";
import { previewStrippedJpeg } from "../../tg/Files/Preview";
import { PeerChat, PeerUser } from "../../tg/Convenience/Peer";

export function ChatMessagesListItem(props: {
    message: TG.Message;
    chat: TG.Chat;
}) {
    let msgElement;
    if (props.message.type === MessageType.Text) {
        msgElement = textMessage(props.message, props.chat);
    } else if (props.message.type === MessageType.Sticker) {
        msgElement = stickerMessage(props.message, props.chat, true);
    } else if (props.message.type === MessageType.Photo) {
        msgElement = photoMessage(props.message, props.chat);
    }

    return (
        <Root $out={props.message.out}>
            {!props.message.out && props.chat.peer instanceof PeerChat && (
                <AvatarWrapper>
                    <UserAvatar msg={props.message} chat={props.chat} />
                </AvatarWrapper>
            )}
            {msgElement}
        </Root>
    );
}

const Root = styled.div<{ $out: boolean }>`
    display: flex;
    gap: 8px;

    ${(props) =>
        props.$out &&
        css`
            justify-content: flex-end;
        `}
`;

const AvatarWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

const UserAvatar = (props: { msg: TG.Message; chat: TG.Chat }) => {
    const [user, setUser] = useState<API.User | undefined>();
    const [preview, setPreview] = useState<Uint8Array>();
    const [pic, setPic] = useState<Uint8Array>();

    useEffect(() => {
        if (!user || !props.chat.peer) {
            return;
        }

        const photo = user.photo;
        if (!(photo instanceof API.UserProfilePhoto)) {
            return;
        }
        if (photo.strippedThumb) {
            setPreview(previewStrippedJpeg(photo.strippedThumb.bytes));
        }

        tg.getPeerPhoto(
            new PeerUser(user.id.value.toNumber(), user),
            photo.photoId,
            photo.dcId
        ).subscribe((file) => {
            setPic(file.bytes.bytes);
        });
    }, [user]);

    useEffect(() => {
        if (props.msg.from instanceof API.User) {
            const id = props.msg.from?.id;
            tg.getUser(id).subscribe((usr) => {
                if (usr instanceof API.User) {
                    setUser(usr);
                }
            });
        }
    }, []);

    const id = user?.id.value.toNumber() || 0;
    const name = [user?.firstName?.string, user?.lastName?.string]
        .join(" ")
        .trim();
    return (
        <Avatar
            id={id}
            name={name}
            deleted={user?.deleted ?? false}
            user={true}
            size={28}
            preview={preview}
            image={pic}
        />
    );
};
