import moment from "moment";
import { TG } from "../../../tg/TG";
import styled, { css } from "styled-components";
import { API } from "../../../tg/Codegen/API/APISchema";
import { Sticker } from "../../ui/Sticker";
import { SentCheckmark } from "./misc/SentCheckmark";

export const stickerMessage = (
    message: TG.Message,
    chat: TG.Chat,
    loop?: boolean,
    sticker?: API.Document
) => {
    const date = moment.unix(message.date);
    const time = date.format("LT");

    let document;
    if (sticker) {
        document = sticker;
    } else {
        document = (message.media as API.MessageMediaDocument).document;
    }
    const stickerElement = (
        <Sticker
            size={136}
            sticker={document as API.Document}
            previewDelay={200}
            cacheCurrentFrame={true}
            loop={loop}
        ></Sticker>
    );

    const read = chat.readOutboxMaxId >= message.id;
    return (
        <Root $out={message.out}>
            {stickerElement}
            <Time $out={message.out}>{time}</Time>
            {message.out && (
                <CheckmarkWrapper>
                    <SentCheckmark double={read} white={true} />
                </CheckmarkWrapper>
            )}
        </Root>
    );
};

const Root = styled.div<{ $out?: boolean }>`
    display: grid;
    flex-direction: row;
    width: 160px;
    height: 160px;
    position: relative;
    margin-top: 8px;

    ${(props) =>
        props.$out &&
        css`
            place-self: flex-end;
        `}
`;

const Time = styled.div<{ $out?: boolean }>`
    color: rgba(255, 255, 255, 0.87);
    place-self: flex-end;
    font-size: 11px;
    margin-left: 8px;
    margin-top: 4px;
    padding: 3px ${(props) => (props.$out ? "24px" : "5px")} 3px 5px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.42);
    position: absolute;
    bottom: 0;
    right: 0;
`;

const CheckmarkWrapper = styled.div`
    position: absolute;
    right: 4px;
    bottom: 0px;
    opacity: 0.7;
`;
