import { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";

export function ClockAppSpinner() {
    const [init, setInit] = useState(false);

    useEffect(() => {
        setInterval(() => {
            setInit(true);
        }, 500);
    }, []);

    return (
        <Root>
            <First $init={init} />
            <Second $init={init} />
        </Root>
    );
}

const Root = styled.div`
    position: absolute;
    width: 32px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const rotate = keyframes`
    0%, 100% {
        left: 18px;
    }

    25% {
        transform: scale(0.67);
    }

    50% { 
        left: 0;
    }

    75% {
        transform: scale(1);
    }
`;

const First = styled.div<{ $init?: boolean }>`
    width: 16px;
    height: 16px;
    background: rgba(181, 65, 161, 0.5);
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    animation: ${rotate} 900ms ease-in-out infinite;
    mix-blend-mode: multiply;

    opacity: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$init &&
        css`
            opacity: 1;
        `};
`;

const Second = styled.div<{ $init?: boolean }>`
    width: 16px;
    height: 16px;
    background: rgba(61, 200, 161, 0.5);
    position: absolute;
    border-radius: 50%;
    right: 0;
    top: 0;
    animation: ${rotate} 900ms ease-in-out infinite;
    mix-blend-mode: multiply;
    animation-delay: 450ms;
    opacity: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$init &&
        css`
            opacity: 1;
        `};
`;
