import React from "react";
import styled from "styled-components";
import { ThemeContext, tg } from "../../App";
import { QRCode } from "./QRCode";
import { Update } from "../../tg/Updates/Update";
import { TG } from "../../tg/TG";
import { Subscription } from "rxjs";

interface Props {
    nextStep: () => void;
}

interface State {
    token?: ArrayBuffer;
}

export class AuthSlideQR extends React.Component<Props, State> {
    private done?: boolean;
    private updatesSubscription?: Subscription;

    state: State = {};

    private renewToken() {
        if (this.done) {
            return;
        }

        tg.getAuthToken().subscribe(({ token, expiresIn }) => {
            setTimeout(() => {
                this.renewToken();
            }, expiresIn - 1000);
            this.setState({
                token: token,
            });
        });
    }

    componentDidMount(): void {
        this.renewToken();

        this.updatesSubscription = tg.updates.subscribe((update) => {
            if (update instanceof Update.LoginToken) {
                this.done = true;
                if (!this.state.token) {
                    throw new Error();
                }

                tg.getAuthToken().subscribe({
                    error: (error: TG.Error) => {
                        if (error.type === TG.ErrorType.unauthorized) {
                            this.props.nextStep();
                        }
                    },
                });
            }
        });
    }

    componentWillUnmount(): void {
        this.updatesSubscription?.unsubscribe();
    }

    render() {
        return (
            <Root>
                <Paper>
                    <ThemeContext.Consumer>
                        {(theme) => (
                            <QRCode
                                data={this.state.token}
                                size={400}
                                foreground={theme.primaryColorDark}
                            />
                        )}
                    </ThemeContext.Consumer>
                </Paper>
                <List>
                    <ListItem>Open Telegram on your phone</ListItem>
                    <ListItem>
                        Go to <strong>Settings</strong> &rarr;{" "}
                        <strong>Devices</strong> &rarr; <br />
                        <strong>Link Desktop Device</strong>
                    </ListItem>
                    <ListItem>Point your phone at the QR code above</ListItem>
                </List>
            </Root>
        );
    }
}

const Root = styled.div`
    display: flex;
    flex-direction: column;
`;

const Paper = styled.div`
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    background: #fff;
    margin: 0 auto;
    border-radius: 4px;
    padding: 24px 32px;
    width: 264px;
    box-sizing: border-box;
`;

const List = styled.ol`
    margin: 32px 0 0 0;
    color: white;
    width: 265px;
    list-style-type: none;
    position: relative;
    font-weight: 500;
    line-height: 1.15;
    font-size: 16px;
`;

const ListItem = styled.li`
    counter-increment: step-counter;
    margin-bottom: 8px;
    padding: 7px 0;
    display: block;
    position: relative;

    &:before {
        content: counter(step-counter);
        top: 5px;
        left: -34px;
        position: absolute;
        font-size: 14px;
        display: inline-flex;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid white;
        justify-content: center;
        align-items: center;
    }
`;
