import React from "react";
import styled, { css } from "styled-components";
import { AuthSlideQR } from "./AuthSlideQR";
import { AuthSlidePassword } from "./AuthSlidePassword";

interface State {
    step: "qr" | "pass";
}

export class Auth extends React.Component<Record<string, never>, State> {
    state: State = {
        step: "qr",
    };

    setStep(step: "qr" | "pass") {
        this.setState({
            step: step,
        });
    }

    render() {
        return (
            <Root $pass={this.state.step === "pass"}>
                <SlideContainer $active={this.state.step === "qr"}>
                    <AuthSlideQR
                        nextStep={() => this.setStep("pass")}
                    ></AuthSlideQR>
                </SlideContainer>
                <SlideContainer $active={this.state.step === "pass"}>
                    <AuthSlidePassword
                        focus={this.state.step === "pass"}
                        required={this.state.step === "pass"}
                    ></AuthSlidePassword>
                </SlideContainer>
            </Root>
        );
    }
}

const Root = styled.div<{ $pass: boolean }>`
    display: flex;
    flex-direction: row;
    position: absolute;
    left: calc(50% - 160px);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$pass &&
        css`
            left: calc(50% - 160px - 320px);
        `};
`;

const SlideContainer = styled.div<{ $active: boolean }>`
    width: 320px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${(props) =>
        props.$active &&
        css`
            opacity: 1;
            visibility: visible;
        `};
`;
